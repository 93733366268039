import {
    fetchRedditData
} from '../../../actions/Channel';

import {
    showLoader,
    hideLoader
} from '../../../actions/GlobalAction'

import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';

import {
    CREATE_REDDIT_SERVER
} from '../../../constants/ActionTypes'
import {
    REDDIT_POSTS_URL,
    REDDIT_SUBREDDITS_URL,
} from '../../../constants/Url'

import api from '../../../util/api';

export function* createRedditDataWatcher() {
    yield takeLatest(CREATE_REDDIT_SERVER, createRedditDataWorker);
}

function* createRedditDataWorker(action) {
    try {
        yield put(showLoader());
        // data is obtained after axios call is resolved
        let response = yield call(createRedditDataApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(fetchRedditData())
        } else {
            throw (response)
        }
    } catch (e) {
        console.log(e)
    }
}

function createRedditDataApi(payload) {
    switch (payload.type) {
        case "submission": {
            return (
                api.post(REDDIT_POSTS_URL, {
                    ...payload,
                })
            );
        };
        case "subreddit": {
            return (
                api.post(REDDIT_SUBREDDITS_URL, {
                    ...payload,
                })
            );
        };
        default: {
            return null;
        };
    };
};
