import * as React from "react";
import PropTypes from "prop-types";

import LpIcon from "../../LpIcon";

function LpIconHtmlLinkRemove({ className, title, alt, variant }) {
	return (
		<LpIcon
			src={require(`./svg/icon-html-link-remove${variant ? "-" + variant : ""}.svg`)}
			className={className}
			title={title}
			alt={alt}
			arialabel={title}
			useCanvas
		/>
	);
}

LpIconHtmlLinkRemove.defaultProps = {
	variant: "black",
};

LpIconHtmlLinkRemove.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	variant: PropTypes.string,
};

export default LpIconHtmlLinkRemove;
