import { all } from "redux-saga/effects";
import { fetchUserWatcher } from "./LoginSagas";
import { refreshUserWatcher } from "./RefreshSaga";
import { rewardsSagas } from "./RewardsSagas";
import { ordersSagas } from "./OrderSagas";
import { userSagas } from "./UserSagas";
import { usersSagas } from "./UsersSagas";
import { forgotPasswordWatcher } from "./ForgotPasswordSaga";
import { resetPasswordWatcher } from "./ResetPasswordSaga";
import { discordSagas } from "./Channels/DiscordSagas";
import { twitchSagas } from "./Channels/TwitchSagas";
import { TwitterSagas } from "./Channels/TwitterSagas";
import { redditSagas } from "./Channels/RedditSagas";

import { channelCountSagas } from "./Channels/ChannelCountSagas";
import { eventsSagas } from "./EventsSagas";
import { achievementsSagas } from "./AchievementsSagas";
import { userAdminPanelSagas } from "./UserAdminSaga";
import { usersAchievementsSagas } from "./UsersAchievementsSagas";

import { pointsSagas } from "./PointsSagas";
import { disconnectAccountLinkerWatcher } from "./AccountLinkerSagas";
import { questsSagas } from "./QuestsSagas";
import { questProgressionSagas } from "./QuestProgressionsSagas";
import { questTranslationsSagas } from "./QuestTranslationsSagas";
import { campaignTranslationsSagas } from "./CampaignTranslationsSagas";
import { campaignsSagas } from "./CampaignSagas";
import { campaignTranslationsStatusSagas } from "./CampaignTranslationsStatusSagas";
import { gamesSagas } from "./GamesSagas";
import { playersSagas } from "./PlayersSagas";
import { tiersSagas } from "./TiersSagas";
import { perksSagas } from "./PerksSagas";
import { socialLinkingSagas } from "./SocialLinkingSagas";
import { fetchSeasonsWatcher } from "./SeasonsSagas/FetchSeasonSaga";
import { usersSeasonReviewSagas } from "./UsersSeasonReviewSagas";
import { countriesSagas } from "./CountriesSaga";
import { gendersSagas } from "./GendersSagas";
import { rumStatusSagas } from "./RumStatusSagas";
import { rewardFaqsSagas } from "./RewardFaqsSagas";
import { liquipediaSagas } from "./LiquipediaSagas";

import { v2QuestsSagas } from "./V2QuestsSagas";

export default function* rootSaga(getState) {
	yield all([
		fetchUserWatcher(),
		refreshUserWatcher(),
		rewardsSagas(),
		ordersSagas(),
		userSagas(),
		usersSagas(),
		forgotPasswordWatcher(),
		resetPasswordWatcher(),
		discordSagas(),
		twitchSagas(),
		TwitterSagas(),
		redditSagas(),
		channelCountSagas(),
		eventsSagas(),
		achievementsSagas(),
		usersAchievementsSagas(),
		userAdminPanelSagas(),
		pointsSagas(),
		disconnectAccountLinkerWatcher(),
		questsSagas(),
		questProgressionSagas(),
		campaignsSagas(),
		gamesSagas(),
		playersSagas(),
		tiersSagas(),
		perksSagas(),
		socialLinkingSagas(),
		fetchSeasonsWatcher(),
		rumStatusSagas(),
		questTranslationsSagas(),
		campaignTranslationsSagas(),
		campaignTranslationsStatusSagas(),
		usersSeasonReviewSagas(),
		countriesSagas(),
		gendersSagas(),
		rewardFaqsSagas(),
		v2QuestsSagas(),
		liquipediaSagas(),
	]);
}
