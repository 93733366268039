import {
    setUsernameUpdateStatus
} from '../../actions/User'

import {
    showLoader,
    hideLoader
} from '../../actions/GlobalAction'

import {
    takeLatest,
    call,
    put,
    select
} from 'redux-saga/effects';

import {
    CHECK_DUPLICATE_USER_NAME
} from '../../constants/ActionTypes'
import {
    CHECK_DUPLICATE_USERNAME_URL
} from '../../constants/Url'

import api from '../../util/api'; 


function checkDuplicateUsernameApi(payload) {
    return (
        api
            .post(CHECK_DUPLICATE_USERNAME_URL, {
                name: payload
            }).catch(err => {
                return err.response.data
            })
    )
}

export function* checkDuplicateUsernameWatcher() {
    yield takeLatest(CHECK_DUPLICATE_USER_NAME, checkDuplicateUsernameWorker);
}

function* checkDuplicateUsernameWorker(action) {
    let response = null
    try {
        yield put(showLoader());
        response = yield call(checkDuplicateUsernameApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(setUsernameUpdateStatus({
                isExists: false,
                usernameErrorMessage: null
            }))
        } else {
            throw (response)
        }

        yield put(hideLoader());
    } catch (e) {
        console.log(e)
        yield put(setUsernameUpdateStatus({
            isExists: true,
            msg: e.msg ? e.msg : null
        }))
        yield put(hideLoader());
    }
}
