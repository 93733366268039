import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Paper from "@material-ui/core/Paper";

/*
	DOCUMENT INTRODUCTION
 	Custom form field components
	Author: Luuk Barten, Max Martyn
*/

/*
 	> FormFieldInput		//  Default single line input field, type can be "text" or "number".
*/

/*
    USAGE

    Component must reside in an (grand)parent element with class "admin".

    Most components take the following arguments
    > intro                 //  Introduction text rendered before the label.
    > label                 //  Label above the FormField.
    > value                 //  The current value (usually set to the value in state).
    > id                    //  Will be rendered as the label "for" attribute and the input element's "id".
    > placeholder           //  Placeholder with example value.
    > pattern               //  HTML patter attribute to make user of pattern detection.
    > maxlength             //  Max number of characters that can be entered.
    > onChange              //  Will be called with an event object as payload.
*/

/*
  HANDLING ONCHANGE
  See /src/app/routes/AdminQuests/QuestCreate or /src/app/routes/AdminQuests/QuestView pages for examples how to handle onChange.
*/

class PasswordInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			type: "password",
		};
	}

	revealPassword = () => {
		this.setState((state) => ({
			type: state.type === "password" ? "text" : "password",
		}));
	};

	render() {
		const {
			wide,
			intro,
			name,
			label,
			required,
			form,
			value,
			placeholder,
			pattern,
			maxlength,
			onChange,
			help,
			hint,
		} = this.props;

		const { type } = this.state;

		return (
			<div
				className={classnames("form-field", {
					"form-field--wide": wide,
				})}
			>
				{intro && <div className="form-field__intro">{intro}</div>}

				<label className="form-field__label" htmlFor={name}>
					{label}
				</label>

				<div className="form-field__password">
					<input
						required={required}
						className="form-field__input"
						form={form}
						type={type}
						name={name}
						value={value === null ? "" : value}
						id={name}
						placeholder={placeholder}
						pattern={pattern}
						maxLength={maxlength}
						onChange={onChange}
					/>

					<button
						type="button"
						className="visibility-icon"
						onClick={this.revealPassword}
						style={{
							backgroundImage: `url(${require(type === "password"
								? "../../svg/visibility-icon.svg"
								: "../../svg/visibility-off-icon.svg")})`,
						}}
					></button>
				</div>

				{hint && <span className="form-field__hint">{hint}</span>}

				{help && (
					<span className="form-field__help">
						<Paper className="form-field__help-message">
							{help}
						</Paper>
					</span>
				)}
			</div>
		);
	}
}

PasswordInput.propTypes = {
	wide: PropTypes.bool,
	intro: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	form: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	maxlength: PropTypes.number,
	hint: PropTypes.string,
	pattern: PropTypes.string,
	help: PropTypes.string,
};

export default PasswordInput;
