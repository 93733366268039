import React, { Component } from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { connect } from "react-redux";
import { fetchRewards } from "../../../actions/Rewards";

import LpHeading from "../../../components/LpHeading";
import RewardsCurrent from "./RewardsCurrent";
import RewardsArchived from "./RewardsArchived";

import { COLLECTIONS } from "../../../constants/PaginationData";

import "./styles.scss";

class Rewards extends Component {
	state = {
		component: 0,
		currentRewardsPage: COLLECTIONS.page,
		archivedRewardsPage: COLLECTIONS.page,
		rowsPerPage: COLLECTIONS.defaultRowPerPage,
	};

	componentDidMount() {
		const { fetchRewards } = this.props;
		fetchRewards({ detailed: true });
	}

	handleChange = (event, component) => {
		this.setState({ component });
	};

	renderTab() {
		const { component, rowsPerPage, archivedRewardsPage, currentRewardsPage } = this.state;
		switch (component) {
			case 1:
				return (
					<RewardsArchived
						rowsPerPage={rowsPerPage}
						onChangeRowsPerPage={(e) => this.setState({ rowsPerPage: e.target.value })}
						page={archivedRewardsPage}
						onChangePage={(e, page) => this.setState({ archivedRewardsPage: page })}
						rowsPerPageOptions={COLLECTIONS.ROW_PER_PAGE_OPTIONS}
					/>
				);
			case 0:
			default:
				return (
					<RewardsCurrent
						rowsPerPage={rowsPerPage}
						onChangeRowsPerPage={(e) => this.setState({ rowsPerPage: e.target.value })}
						page={currentRewardsPage}
						onChangePage={(e, page) => this.setState({ currentRewardsPage: page })}
						rowsPerPageOptions={COLLECTIONS.ROW_PER_PAGE_OPTIONS}
					/>
				);
		}
	}

	render() {
		const { component } = this.state;
		return (
			<div className="m-3 p-3 Rewards admin">
				<div>
					<LpHeading>Rewards</LpHeading>
				</div>
				<div className="mt-4">
					<Tabs
						className="tabs"
						value={component}
						onChange={this.handleChange}
						indicatorColor="primary"
						textColor="primary"
					>
						<Tab className="tab" label="Current" />
						<Tab className="tab" label="Archived" />
					</Tabs>
					{this.renderTab()}
				</div>
			</div>
		);
	}
}

Rewards.propTypes = {
	rewards: PropTypes.arrayOf(PropTypes.object),
	fetchRewards: PropTypes.func,
};

const mapStateToProps = (state) => ({
	rewards: state.rewards.bank,
});

const mapDispatchToProps = (dispatch) => ({
	fetchRewards: (params, options) => dispatch(fetchRewards(params, options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
