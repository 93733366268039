import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { appendNotifications } from "../../actions/Notifications";

// // Util
import api from "../../util/api";

// Constants
import { ERROR, SUCCESS } from "../../constants/Notifications";
import { PATCH_QUEST_TRANSLATIONS_PUBLISHED_STATUS_WATCHER } from "../../constants/ActionTypes";
import { PATCH_QUEST_TRANSLATIONS_PUBLISHED_STATUS_URL } from "../../constants/Url";
import { updateQuestTranslationPublishedStatus } from "../../actions/QuestTranslations";

export function* patchQuestTranslationsPublishedStatusWatcher() {
	yield takeLatest(
		PATCH_QUEST_TRANSLATIONS_PUBLISHED_STATUS_WATCHER,
		patchQuestTranslationsPublishedStatusWorker
	);
}

function* patchQuestTranslationsPublishedStatusWorker(action) {
	try {
		const response = yield call(patchQuestTranslationsPublishedStatusApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;

		if (isSuccess) {
			yield put(updateQuestTranslationPublishedStatus(action.payload));
			yield put(
				appendNotifications({
					type: SUCCESS,
					message: "Successfully updated",
				})
			);
		} else {
			throw response;
		}
	} catch (e) {
		const { error } = e.response.data;
		yield put(
			appendNotifications({
				type: ERROR,
				message: error,
			})
		);
	}
}

function patchQuestTranslationsPublishedStatusApi({ questId, params }) {
	return api.patch(PATCH_QUEST_TRANSLATIONS_PUBLISHED_STATUS_URL(questId), {
		[params.languageCode]: params.publishedStatus,
	});
}
