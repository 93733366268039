import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { DEFAULT_VARIANT, LP_BLOCK_VARIANT } from "../../constants";
import LpBlock from "../../../LpBlock";

import "./styles/styles.scss";

function LpDraggableItem({
	variant,
	className,
	children,
	selected,
	onClick,
	dataPosition,
	onDragStart,
	onDragOver,
	onDragLeave,
	onDrop,
	showDropArea,
}) {
	const Wrapper = variant === LP_BLOCK_VARIANT ? LpBlock : "div";

	return (
		<Wrapper
			className={classnames(
				"lp_draggable_item",
				{ "lp_draggable_item--selected": selected },
				{ "lp_draggable_item--drop_area": showDropArea },
				className
			)}
			draggable
			data-position={dataPosition}
			onClick={onClick}
			onDragStart={onDragStart}
			onDragOver={onDragOver}
			onDragLeave={onDragLeave}
			onDrop={onDrop}
		>
			<img
				className={"lp_draggable_item__drag_handle"}
				src={require("../../../../svg/drag-handle.svg")}
				draggable={false}
			/>
			<div className={"lp_draggable_item__content"}>{children}</div>
		</Wrapper>
	);
}

LpDraggableItem.propTypes = {
	variant: PropTypes.oneOf([DEFAULT_VARIANT, LP_BLOCK_VARIANT]),
	className: PropTypes.string,
	children: PropTypes.node,
	selected: PropTypes.bool,
	onClick: PropTypes.func,
	dataPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onDragStart: PropTypes.func,
	onDragOver: PropTypes.func,
	onDragLeave: PropTypes.func,
	onDrop: PropTypes.func,
	showDropArea: PropTypes.bool,
};

LpDraggableItem.defaultProps = {
	variant: DEFAULT_VARIANT,
};

export default LpDraggableItem;
