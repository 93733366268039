import {
    SET_USER_PROFILE,
    UPDATE_USER_PROFILE,
    CLEAR_USER_PROFILE,
    SET_USER_PROFILE_PARAM,
    SET_FORGOT_PASSWORD_EMAIL_STATUS,
    RESET_PASSWORD_STATUS,
    SET_USER_EMAIL,
    SET_REFERRAL_LINK,
    SET_USER_AVATARS,
    SET_USER_LIFETIME_POINTS,
    SET_USER_MEMBERSHIP_POINTS,
    SET_USER_SPENDING_POINTS,
    SET_USER_RANK_UP_PROGRESS,
    SET_EVENT_CHECK_IN_MESSAGE,
    SET_USERNAME_UPDATE_STATUS,
    SET_POINT_HISTORIES,
	CLEAR_POINT_HISTORIES,
    SET_USER_PERKS,
	SET_QUEST_PROGRESSIONS_BANK,
	SET_BUCKET_QUEST_PROGRESSIONS_BANK_TOP_USERS,
	SET_BUCKET_QUEST_PROGRESSIONS_BANK_TOP_CHANNELS,
	SET_USERNAMES_AND_AVATARS,
    SET_USERS_ACHIEVEMENTS,
	SET_USERS_SEASON_REVIEW,
    SET_PROFILE_OVERVIEW,
    SET_USER_PREFERRED_GAMES_WATCHER,
    SET_USER_PREFERRED_PLAYERS_WATCHER
} from '../constants/ActionTypes'
import {
    GENERATING_REFERRAL_LINK
} from '../constants/Label';
import _ from 'lodash';

const sandboxInitialState = {
    profile: {},
    forgot_password_status: false,
    reset_password_status: false,
    user_email: null,
    referral_link: GENERATING_REFERRAL_LINK,
    avatars: null,
    event_check_in: {
        status: null,
        eventDetails: null
    },
    isUsernameExists: null,
    usernameErrorMessage: null,
    achievements: null,
    public_profile_overview: {},
    games: [],
    players: [],
    quest_progressions: [],
    rank_up_progress: null,
    points_to_next_tier: null,
};

export default function userReducer(state = sandboxInitialState, action) {
    switch (action.type) {
        case SET_USER_PROFILE: {
            return {
                ...state,
                profile: action.payload
            }
        }
        case UPDATE_USER_PROFILE: {
            return {
                ...state,
                profile: _.merge({}, state.profile, action.payload), // Do not mutate state.profile!
            }
        }
        case CLEAR_USER_PROFILE: {
            return {
                ...state,
                profile: {}
            }
        }
        case SET_USER_PROFILE_PARAM: {
            return {
                ...state,
                profile: _.set({...state.profile}, action.payload.arg, action.payload.value),
            }
        }
        case SET_FORGOT_PASSWORD_EMAIL_STATUS: {
            return {
                ...state,
                forgot_password_status: action.payload
            }
        }
        case RESET_PASSWORD_STATUS: {
            return {
                ...state,
                reset_password_status: action.payload
            }
        }
        case SET_USER_EMAIL: {
            return {
                ...state,
                user_email: action.payload
            }
        }
        case SET_REFERRAL_LINK: {
            return {
                ...state,
                referral_link: action.payload
            }
        }
        case SET_USER_AVATARS: {
            return {
                ...state,
                avatars: action.payload
            }
        }
        case SET_USER_LIFETIME_POINTS: {
            return {
                ...state,
                lifetime_points: action.payload,
            }
        }
        case SET_USER_MEMBERSHIP_POINTS: {
            return {
                ...state,
                membership_points: action.payload,
            }
        }
        case SET_USER_SPENDING_POINTS: {
            return {
                ...state,
                spending_points: action.payload,
            }
        }
        case SET_USER_RANK_UP_PROGRESS: {
            return {
                ...state,
                rank_up_progress: action.payload.rank_up_progress,
                points_to_next_tier: action.payload.required_point_for_progress
            }
        }
        case SET_EVENT_CHECK_IN_MESSAGE: {
            return {
                ...state,
                event_check_in: action.payload
            }
        }
        case SET_USERNAME_UPDATE_STATUS: {
            return {
                ...state,
                isUsernameExists: action.payload.isExists,
                usernameErrorMessage: action.payload.msg
            }
        }
        case SET_USER_PERKS: {
            return {
                ...state,
                perks: action.payload
            }
        }
        case SET_POINT_HISTORIES: {
            return {
                ...state,
                point_histories: action.payload,
            }
        }
		case CLEAR_POINT_HISTORIES: {
			return {
                ...state,
                point_histories: undefined,
            }
        }
        case SET_USER_PERKS: {
            return {
                ...state,
                perks: action.payload
            }
        }
        case SET_USERS_ACHIEVEMENTS: {
            return {
                ...state,
                achievements: action.payload
            }
        }
		case SET_USERS_SEASON_REVIEW: {
            return {
                ...state,
                year_review_data: action.payload
            }
        }
        case SET_QUEST_PROGRESSIONS_BANK: {
            return {
                ...state,
                quest_progressions: action.payload
            }
		}
		case SET_BUCKET_QUEST_PROGRESSIONS_BANK_TOP_USERS: {
            return {
                ...state,
                bucket_quest_progressions_top_users: action.payload
            }
		}
		case SET_BUCKET_QUEST_PROGRESSIONS_BANK_TOP_CHANNELS: {
            return {
                ...state,
                bucket_quest_progressions_top_channels: action.payload
            }
		}
		case SET_USERNAMES_AND_AVATARS: {
            return {
                ...state,
                bucket_usernames_and_avatars: action.payload
            }
        }
        case SET_PROFILE_OVERVIEW: {
            return {
                ...state,
                public_profile_overview: action.payload
            }
        }
        case SET_USER_PREFERRED_GAMES_WATCHER: {
            return {
                ...state,
                games: action.payload
            };
        }
        case SET_USER_PREFERRED_PLAYERS_WATCHER: {
            return {
                ...state,
                players: action.payload
            };
        }
        default: {
            return state;
        }
    }
}