import { setRewardsBank } from "../../actions/Rewards";
import { takeLatest, call, put } from "redux-saga/effects";
import { FETCH_REWARDS_WATCHER } from "../../constants/ActionTypes";
import { REWARD_SERVICE } from "../../constants/Url";
import { SUCCESS, ERROR } from "../../constants/Notifications";
import { appendNotifications } from "../../actions/Notifications";
import { push } from "react-router-redux";

import api from "../../util/api";

export function* fetchRewardsWatcher() {
	yield takeLatest(FETCH_REWARDS_WATCHER, fetchRewardsWorker);
}

function* fetchRewardsWorker(action) {
	try {
		const response = yield call(fetchRewardsApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (isSuccess) {
			yield put(setRewardsBank(response.data));
		}

		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, isSuccess ? response.data : undefined);
		}
		if (options?.redirect) {
			yield put(push(options.redirect));
		}
	} catch (e) {
		console.log(e);
	}
}

function fetchRewardsApi(params) {
	return api
		.get(REWARD_SERVICE, {
			params,
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
