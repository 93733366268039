
import {
	setUserProfileParam,
	getUserMembershipPoints,
	getUserSpendingPoints,
} from "../../actions/User";
import {
	appendNotifications,
} from "../../actions/Notifications";
import {
	addMessage,
} from "../../actions/Messages";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import {
    push,
} from "react-router-redux";
import {
	SUCCESS,
	ERROR,
} from "../../constants/Notifications";
import {
    CLAIM_PARTNER_BONUS
} from "../../constants/ActionTypes";
import {
    USER_SERVICE_CLAIM_PARTNER_BONUS_URL
} from "../../constants/Url";
import api from '../../util/api';

export function* claimPartnerBonusWatcher() {
    yield takeLatest(CLAIM_PARTNER_BONUS, claimPartnerBonusWorker);
};

function* claimPartnerBonusWorker(action) {
    try {
        let response = yield call(claimPartnerBonusWorkerApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		
		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (options?.notification) {
			yield put(appendNotifications({
				type: isSuccess ? SUCCESS : ERROR,
				message: options.notification,
			}));
		};
		if (options?.message) {
			yield put(addMessage({
				source: CLAIM_PARTNER_BONUS,
				message: typeof options.message === "string" ? options.message : response?.data,
				isError: isSuccess,
			}));
		};
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		};
		if (options?.redirect) {
			yield put(push(options.redirect));
		};
		if (isSuccess) {
			yield put(setUserProfileParam("received_partner_points", response?.data?.data?.received_partner_points));
			yield put(getUserMembershipPoints(response?.data?.data?.userId));
			yield put(getUserSpendingPoints(response?.data?.data?.userId));
        };
    } catch (e) {
        console.error(e);
    };
};

function claimPartnerBonusWorkerApi(payload) {
	return api
        .post(USER_SERVICE_CLAIM_PARTNER_BONUS_URL(payload.userId))
        .then(response => { return response })
        .catch(error => { return error.response });
};
