import { all } from "redux-saga/effects";

import { fetchTotalUsersWatcher } from "./FetchTotalUsersSaga";

import { fetchSearchedUsersWatcher } from "./FetchSearchedUsersSaga";

import { fetchAllStaffWatcher } from "./FetchAllStaffSaga";

import { fetchRolesWatcher } from "./FetchRolesSaga";

import { fetchUserAccountInfoWatcher } from "./FetchUserAccountInfoSaga";

import { fetchUserInfoByUserNameWatcher } from "./FetchUserInfoByUserNameSaga";

import {
	fetchUserLifetimePointsWatcher,
	fetchUserMembershipPointsWatcher,
	fetchUserSpendingPointsWatcher,
} from "./PointsFetchSagas";

import { fetchUpdateUsersInfoWatcher } from "./UpdateUsersInfoSaga";

import {
	adminPointsChangeWatcher,
} from "./PointsChangeSagas";

import { banUsersWatcher } from "./BanUserSagas";

import { unBanUsersWatcher } from "./BanUserUpdateSagas";

import { fetchUserInteractionsWatcher } from "./FetchUserInteractionsSaga";

import { fetchUserFriendReferralsWatcher } from "./FetchUserReferralsSaga";

import { fetchAdminUserOrdersWatcher } from "./FetchAdminUserOrdersSagas";

import { fetchPointsHistoryWatcher } from "./FetchPointsHistorySaga";

import { fetchBanUsersWatcher } from "./FetchBanUsersSaga";

import { fetchInteractionsWatcher } from "./FetchInteractionsSaga";

import { fetchPermissionListWatcher } from "./fetchPermissionListSagas";

import { updateReferralLinkWatcher } from "./UpdateReferralLinkSaga";

import deleteUserWatcher from "./DeleteUser";

export function* userAdminPanelSagas() {
	yield all([
		fetchTotalUsersWatcher(),
		fetchSearchedUsersWatcher(),
		fetchAllStaffWatcher(),
		fetchRolesWatcher(),
		fetchUserAccountInfoWatcher(),
		fetchUserInfoByUserNameWatcher(),
		fetchUserLifetimePointsWatcher(),
		fetchUserMembershipPointsWatcher(),
		fetchUserSpendingPointsWatcher(),
		fetchUpdateUsersInfoWatcher(),
		adminPointsChangeWatcher(),
		banUsersWatcher(),
		unBanUsersWatcher(),
		fetchUserInteractionsWatcher(),
		fetchUserFriendReferralsWatcher(),
		fetchAdminUserOrdersWatcher(),
		fetchInteractionsWatcher(),
		fetchPointsHistoryWatcher(),
		fetchBanUsersWatcher(),
		fetchPermissionListWatcher(),
		updateReferralLinkWatcher(),
		deleteUserWatcher(),
	]);
}
