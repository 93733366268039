import {
    setUserAvatars
} from '../../actions/User';

import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';

import {
    GET_USER_AVATARS
} from '../../constants/ActionTypes';

import {
    USER_SERVICE_ALL_AVATARS_URL
} from '../../constants/Url';

import api from '../../util/api';

function fetchUserAvatars() {    
    return (
        api
        .get(USER_SERVICE_ALL_AVATARS_URL)
        .then(response => {
            return {
                status: response.status,
                avatars: response.data.data.map(data => {
                    return {
                        enum: data.enum,
                        small_icon_url: data.small_icon_path,
                        large_icon_url: data.large_icon_path
                    }
                }),
            };
        })
    );
};

export function* fetchUserAvatarsWatcher() {
    yield takeLatest(GET_USER_AVATARS, fetchUserAvatarsWorker);
}

function* fetchUserAvatarsWorker(action) {
    try {
        let response = yield call(fetchUserAvatars);
        if (response.status >= 200 && response.status < 300){
            yield put(setUserAvatars(response.avatars));
        } else {
            throw(response);
        };
    } catch (e) {
        console.error(e);
    }
}