import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./styles.scss";

// Custom Components
import { FormFieldInput, FormFieldTextarea } from "../../../../../components/FormFields/FormFields";

function FaqForm({ className, faq, form, formRef, onChange, onSubmit }) {
	const { question, answer } = faq;
	const handleSubmit = function (e) {
		e.preventDefault();
		e.stopPropagation();
		onSubmit(faq);
	};

	return (
		<form
			className={classnames("faq_form", className)}
			id={form}
			ref={formRef}
			onSubmit={handleSubmit}
		>
			<FormFieldInput
				label="Question"
				type={"text"}
				value={question}
				onChange={(e) => onChange({ question: e.target.value })}
			/>
			<FormFieldTextarea
				required
				allow_formatting
				label="Answer"
				name="answer"
				value={answer}
				onChange={(e) => onChange({ answer: e })}
			/>
		</form>
	);
}

FaqForm.propTypes = {
	className: PropTypes.string,
	form: PropTypes.string,
	formRef: PropTypes.node,
	onChange: PropTypes.func,
	onSubmit: PropTypes.func,
	faq: PropTypes.shape({
		question: PropTypes.string,
		answer: PropTypes.string,
	}),
};

export default FaqForm;
