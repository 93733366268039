import React from "react";
import PropTypes from "prop-types";
import LpIconEllipsis from "../../../icons/LpIconEllipsis";
import "./styles.scss";

const DropdownButton = ({ onClick, variant }) => (
	<button type="button" className={"button button--borderless dropdown_button"} onClick={onClick}>
		<LpIconEllipsis
			title="Options"
			aria-label="Dropdown toggle button for options"
			alt="Options icon"
			variant={variant}
		/>
	</button>
);

DropdownButton.propTypes = {
	onClick: PropTypes.func,
	variant: PropTypes.oneOf(["black", "white"]),
};

export default DropdownButton;
