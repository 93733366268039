import React from "react";
import PropTypes from "prop-types";

import Modal from "../../../../../components/Modal";

function SetDefaultModeratorDialog({ handleAccept }) {
	return (
		<Modal
			triggerBtnText="Set Default"
			triggerBtnClassName="button"
			cancelBtnText="Cancel"
			submitBtnText="Yes"
			onSubmit={handleAccept}
		>
			<p>
				Are you sure you want to set this Twitch moderator as the default moderator for all
				events and channels?
			</p>
		</Modal>
	);
}

SetDefaultModeratorDialog.propTypes = {
	handleAccept: PropTypes.func.isRequired,
};

export default SetDefaultModeratorDialog;
