import { setCampaignsBank } from "../../actions/Campaigns";

import { takeLatest, call, put } from "redux-saga/effects";

import { FETCH_CAMPAIGNS_WATCHER } from "../../constants/ActionTypes";

import { ADMIN_FETCH_CAMPAIGNS_URL } from "../../constants/Url";

import api from "../../util/api";

export function* fetchCampaignsWatcher() {
	yield takeLatest(FETCH_CAMPAIGNS_WATCHER, fetchCampaignsWorker);
}

function* fetchCampaignsWorker(action) {
	try {
		let response = yield call(fetchCampaignsApi, action.payload);
		if (response.status >= 200 && response.status < 300) {
			yield put(
				setCampaignsBank({
					bank: response.data.data,
					totalCount: response.data.totalCount,
				})
			);
		} else {
			throw response;
		}
	} catch (e) {
		console.error(e);
	}
}

function fetchCampaignsApi(payload) {
	return api.get(ADMIN_FETCH_CAMPAIGNS_URL, {
		params: payload,
	});
}
