import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';
import {
    push
} from 'react-router-redux';
import {
    CREATE_EVENT_WATCHER
} from '../../constants/ActionTypes'
import {
    CREATE_EVENT_URL,
} from '../../constants/Url'

import api from '../../util/api';

function createEventApi(params) {
    return (
        api
        .post(CREATE_EVENT_URL, {
            ...params
        })
        .catch(err => {
            return err.response.data
        })
    )
}


export function* createEventWatcher() {
    yield takeLatest(CREATE_EVENT_WATCHER, createEventWorker);
}

function* createEventWorker(action) {
    try {
        /// data is obtained after axios call is resolved
        const response = yield call(createEventApi, action.params);
        const isSuccess = response.status >= 200 && response.status < 300;
        const options = isSuccess
			? action?.options?.success
			: action?.options?.failure;

        if (options?.redirect) {
            yield put(push(options.redirect))
        }
        if (options?.callback) {
            yield call(options.callback, response);
        }
        else {
            throw (response)
        }
    } catch (e) {
        console.log(e);
    }
}