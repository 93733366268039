import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.scss";

// Components
import Modal from "../../../../../components/Modal/Modal";
import LpHeading from "../../../../../components/LpHeading";
import { FormFieldInput, FormFieldSelect } from "../../../../../components/FormFields/FormFields";
import { ErrorNotification } from "../../../../../containers/NotificationWrapper";
import { SingleDateTimePicker } from "../../../../../components/DatePickers/DatePickers";
import GamesEntitiesInput from "../../../../../components/EsportsEntitiesInputs/GameEntitiesInput";
import PlayerEntitiesInput from "../../../../../components/EsportsEntitiesInputs/PlayerEntitiesInput";

// Utils
import { localeToUTC, utcToLocale } from "../../../../../util/DateUtil";

// Constants
import { GAME_ENTITIE_TYPE } from "../../../../../constants/EsportsEntities";
import { broadcasPlatformOptions, broadcastPlatforms } from "../../../../../constants/Platforms";

class CreateBroadCastDialog extends Component {
	getDefaultUTCDate() {
		const presentDateUTC = utcToLocale(new Date());
		const futureDateUTC = new Date(
			presentDateUTC.getFullYear(),
			presentDateUTC.getMonth() + 1,
			presentDateUTC.getDate()
		);
		return { presentDateUTC, futureDateUTC };
	}

	state = {
		eventName: "",
		startDate: this.getDefaultUTCDate().presentDateUTC,
		endDate: this.getDefaultUTCDate().futureDateUTC,
		channelName: "",
		opponent: "",
		broadcastPlatform: broadcastPlatforms.TWITCH,
		broadcastUrl: "",
		esportEntities: [],
	};

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	clearState = () => {
		const { presentDateUTC, futureDateUTC } = this.getDefaultUTCDate();

		this.setState({
			eventName: "",
			startDate: presentDateUTC,
			endDate: futureDateUTC,
			channelName: "",
			opponent: "",
			broadcastPlatform: broadcastPlatforms.TWITCH,
			broadcastUrl: "",
			esportEntities: [],
		});
	};

	handleBroadcastSubmit = () => {
		const {
			startDate,
			endDate,
			channelName,
			eventName,
			esportEntities,
			opponent,
			broadcastPlatform,
			broadcastUrl,
		} = this.state;
		const { handleSubmit } = this.props;

		const currentBroadcastPlatformLabel = broadcasPlatformOptions.find(
			(bpo) => broadcastPlatform === bpo.value
		)?.label;

		let errors = [];

		try {
			this.assertCondition(Date.parse(startDate), "Missing start date.", errors);
			this.assertCondition(Date.parse(endDate), "Missing end date.", errors);
			this.assertCondition(
				new Date(startDate).getTime() <= new Date(endDate).getTime(),
				"Start date cannot be after end date.",
				errors
			);

			const channelNameRegExp = RegExp(/^\S+$/g);
			this.assertCondition(
				channelNameRegExp.test(channelName),
				`${currentBroadcastPlatformLabel} channel name shouldn't contain spaces`,
				errors
			);

			const urlRegExp = RegExp(/^(http|https):\/\/[^ "]+$/);
			if (broadcastPlatform !== broadcastPlatforms.TWITCH) {
				this.assertCondition(
					urlRegExp.test(broadcastUrl),
					`Invalid ${currentBroadcastPlatformLabel} URL.`,
					errors
				);
			}

			if (!opponent) {
				errors.push("Missing opponent.");
			}

			if (errors.length) {
				throw errors;
			}

			handleSubmit({
				event_name: eventName,
				start_date: localeToUTC(startDate),
				end_date: localeToUTC(endDate),
				channel_name: channelName,
				broadcast_platform: broadcastPlatform,
				broadcast_url: broadcastUrl,
				opponent,
				esport_entities: esportEntities,
			});

			this.clearState();
		} catch (e) {
			if (Array.isArray(e)) {
				for (let errorMessage of e) {
					ErrorNotification(errorMessage);
				}
			} else {
				ErrorNotification("Unknown error.");
			}
		}
	};

	assertCondition(condition, errorMessage, errors) {
		if (!condition) {
			errors.push(errorMessage);
		}
	}

	handleDateStartChange = (date) => {
		this.setState({
			startDate: date.format(),
		});
	};

	handleDateEndChange = (date) => {
		this.setState({
			endDate: date.format(),
		});
	};

	addGame = (game) => {
		this.setState((state) => {
			const esportEntities = [...state.esportEntities];
			const gameEntityIndex = esportEntities.findIndex((entity) => entity.id === game.name);

			esportEntities.splice(gameEntityIndex, 1, {
				id: game.name,
				name: game.display_name,
				type: GAME_ENTITIE_TYPE,
			});

			return {
				game: game.name,
				esportEntities,
			};
		});
	};

	addEntity = (entity) => {
		this.setState((state) => ({
			esportEntities: [...state.esportEntities, entity],
		}));
	};

	removeEntity = (entity) => {
		this.setState((state) => ({
			esportEntities: state.esportEntities.filter((e) => e.name !== entity.name),
		}));
	};

	changeGame = (entity) => {
		const { state } = this;
		const esportEntities = state.esportEntities.filter(
			(entity) => entity.type !== GAME_ENTITIE_TYPE
		);
		esportEntities.push(entity);
		this.setState({ esportEntities });
	};

	render() {
		const {
			eventName,
			startDate,
			endDate,
			channelName,
			esportEntities,
			opponent,
			broadcastPlatform,
			broadcastUrl,
		} = this.state;

		const currentBroadcastPlatformLabel = broadcasPlatformOptions.find(
			(bpo) => broadcastPlatform === bpo.value
		)?.label;

		return (
			<Modal
				className="admin TwitchDialog channel-modal"
				triggerBtnText="Broadcast Channel"
				triggerBtnClassName="button button--borderless"
				cancelBtnText="Cancel"
				onClose={this.clearState}
				submitBtnText="Save"
				onSubmit={this.handleBroadcastSubmit}
			>
				<LpHeading>New Broadcast Channel</LpHeading>

				<FormFieldInput
					required
					label="Event Name"
					type="text"
					name="eventName"
					value={eventName}
					onChange={this.handleChange}
				/>

				<div className="form-field__intro">
					Enter the dates when this event will run for.
				</div>

				<div className="form-field">
					<label className="form-field__label" htmlFor="broadcast-date-start">
						Start Date and Time (UTC)
					</label>
					<SingleDateTimePicker
						name="start_date"
						datePicked={startDate}
						maxDate={endDate}
						id="broadcast-date-start"
						onChange={this.handleDateStartChange}
						format="YYYY.MM.DD HH:mm"
					/>
				</div>

				<div className="form-field">
					<label className="form-field__label" htmlFor="broadcast-date-end">
						End Date and Time (UTC)
					</label>
					<SingleDateTimePicker
						name="end_date"
						datePicked={endDate}
						minDate={startDate}
						id="broadcast-date-end"
						onChange={this.handleDateEndChange}
						format="YYYY.MM.DD HH:mm"
					/>
				</div>

				<FormFieldSelect
					intro="Select the platform where the broadcast will be streamed."
					label="Broadcast Platform"
					name="broadcastPlatform"
					options={broadcasPlatformOptions}
					value={broadcastPlatform}
					onChange={this.handleChange}
				/>

				{broadcastPlatform === broadcastPlatforms.YOUTUBE && (
					<FormFieldInput
						intro={`Enter the ${currentBroadcastPlatformLabel} URL for this event.`}
						label={`${currentBroadcastPlatformLabel} Broadcast URL`}
						type="text"
						name="broadcastUrl"
						value={broadcastUrl}
						onChange={this.handleChange}
					/>
				)}

				<FormFieldInput
					required
					intro={`Enter the ${currentBroadcastPlatformLabel} channel name for this event (e.g. beyondthesummit, esl_csgo, etc…).`}
					label={`${currentBroadcastPlatformLabel} Channel`}
					type="text"
					name="channelName"
					value={channelName}
					onChange={this.handleChange}
				/>

				<FormFieldInput
					required
					intro="Enter the Opponent (team or player)."
					label="Opponent"
					type="text"
					name="opponent"
					value={opponent}
					onChange={this.handleChange}
				/>

				<PlayerEntitiesInput
					label="Player(s)"
					intro="Enter the players or games that relate to this channel."
					entities={esportEntities}
					onChange={this.addEntity}
					onRemove={this.removeEntity}
				/>

				<GamesEntitiesInput
					label="Game"
					entities={esportEntities}
					onChange={this.changeGame}
				/>
			</Modal>
		);
	}
}

CreateBroadCastDialog.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
};

export default CreateBroadCastDialog;
