import axios from "axios";
import processAccessToken from "./token";

const api = axios.create();

api.interceptors.request.use(
	async (config) => {
		const token = await processAccessToken();

		if (token) {
			config.headers["Authorization"] = token;

			// Only used during the logout process
			if (config.headers["Refresh-Token"]) {
				config.headers["Refresh-Token"] = window.browserStorage.get("refresh_token");
			}
		}
		const currentLang = localStorage.getItem("language");
		config.headers["Accept-Language"] = currentLang;

		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

export default api;
