import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./styles.scss";

function LpIcon({ src, title, alt, arialabel, size, useCanvas, className }) {

	const imageOutput = (
		<img
			className={classnames("lp_icon", "lp_icon--" + size, className)}
			src={src}
			aria-label={arialabel}
			alt={alt}
			title={title}
		/>
	);
	return useCanvas === true ? <div className="icon_canvas">{imageOutput}</div> : imageOutput;
}

LpIcon.defaultProps = {
	size: "small",
	useCanvas: false,
};

LpIcon.propTypes = {
	className: PropTypes.string,
	src: PropTypes.string,
	arialabel: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	size: PropTypes.oneOf(["small", "medium"]),
	useCanvas: PropTypes.bool,
};

export default LpIcon;
