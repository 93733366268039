import React, { Component } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import "./styles.scss";

export default class ChannelRow extends Component {
	getLogo = (channelName) => {
		switch (channelName) {
			case "Twitter":
				return (
					<Button
						variant="fab"
						className="jr-fab-btn jr-btn-fab-sm bg-light-blue"
					>
						<i>
							<img
								src={require("../../../svg/service-twitter-w.svg")}
							/>
						</i>
					</Button>
				);
			case "Twitch":
				return (
					<Button
						variant="fab"
						className="jr-fab-btn jr-btn-fab-sm bg-deep-purple"
					>
						<i>
							<img
								src={require("../../../svg/service-twitch-w.svg")}
							/>
						</i>
					</Button>
				);
			case "Reddit":
				return (
					<Button
						variant="fab"
						className="jr-fab-btn jr-btn-fab-sm bg-red"
					>
						<i>
							<img
								src={require("../../../svg/service-reddit-w.svg")}
							/>
						</i>
					</Button>
				);
			case "Discord":
				return (
					<Button
						variant="fab"
						className="jr-fab-btn jr-btn-fab-sm  discord_btn"
					>
						<i>
							<img
								className="discord_btn__image"
								src={require("../../../svg/service-discord.svg")}
							/>
						</i>
					</Button>
				);
			default:
				return (
					<Button
						variant="fab"
						className="jr-fab-btn jr-btn-fab-sm bg-black"
					>
						<i>
							<img
								src={require("../../../svg/service-Team-Liquid-w.svg")}
							/>
						</i>
					</Button>
				);
		}
	};

	getChannelCount = () => {
		if (this.props.data.total < 0) {
			return (
				<span>
					<i class="zmdi zmdi-rotate-right zmdi-hc-spin"></i> Updating
					data
				</span>
			);
		} else {
			switch (this.props.channelName) {
				case "Twitter":
					return (
						<span>
							{this.props.data.profileCount} Profiles |{" "}
							{this.props.data.hashTagCount} Hash Tags
						</span>
					);
				case "Reddit":
					return (
						<span>
							{this.props.data.subRedditCount} Sub-Reddit |{" "}
							{this.props.data.postCount} Posts
						</span>
					);
				case "Team Liquid Store":
					return <span>{this.props.data.total} Products</span>;
				case "Discord":
					return <span>{this.props.data.total} Bots</span>;
				default:
					return <span>{this.props.data.total} Channels</span>;
			}
		}
	};

	render() {
		return (
			<TableRow>
				<TableCell className="data-table__data-cell">
					<div className="d-flex flex-row align-items-center">
						{this.getLogo(this.props.channelName)}

						<div className="user-detail">
							<div className="channelName">
								{this.props.channelName}
							</div>

							{this.getChannelCount()}
						</div>
					</div>
				</TableCell>

				<TableCell
					style={{ textAlign: "right" }}
					className="data-table__data-cell"
				>
					<Link to={this.props.url}>
						<Button size="small" variant="text" mini={true}>
							<span>
								<i className="zmdi zmdi-chevron-right zmdi-hc-lg" />
							</span>
						</Button>
					</Link>
				</TableCell>
			</TableRow>
		);
	}
}
