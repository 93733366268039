import { takeLatest, call, put } from "redux-saga/effects";

// Constants
import { ERROR } from "../../../constants/Notifications";
import { CREATE_TWITCH_SERVER } from "../../../constants/ActionTypes";
import { CREATE_TWITCH_CHANNELS } from "../../../constants/Url";

// Actions
import { fetchTwitchData } from "../../../actions/Channel";
import { showLoader, hideLoader } from "../../../actions/GlobalAction";
import { appendNotifications } from "../../../actions/Notifications";

// Utils
import api from "../../../util/api";

export function* createTwitchDataWatcher() {
	yield takeLatest(CREATE_TWITCH_SERVER, createTwitchDataWorker);
}

function* createTwitchDataWorker(action) {
	try {
		yield put(showLoader());
		// data is obtained after axios call is resolved
		const response = yield call(createTwitchDataApi, action.payload);
		if (response.status >= 200 && response.status < 300) {
			yield put(fetchTwitchData());
		} else {
			throw response;
		}
	} catch (err) {
		const { error } = err.response.data;
		yield put(
			appendNotifications({
				type: ERROR,
				message: error,
			})
		);
	} finally {
		yield put(hideLoader());
	}
}

function createTwitchDataApi(payload) {
	return api.put(CREATE_TWITCH_CHANNELS, {
		params: {
			name: payload.serverName,
			streamlabs_token: payload.streamlabsToken,
			esport_entities: payload.esportEntities,
		},
	});
}
