import {
    setAdminUserOrders
} from '../../actions/UserAdminPanel'

import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';

import {
    FETCH_ADMIN_USER_ORDERS_WATCHER
} from '../../constants/ActionTypes'
import {
    REWARD_SERVICE_ADMIN_USER_ORDERS
} from '../../constants/Url'

import api from '../../util/api'; 

function fetchUserOrdersApi(payload) {
    return (
        api
        .get(REWARD_SERVICE_ADMIN_USER_ORDERS+`/${payload.userId}?page=${payload.page}&limit=${payload.limit}`)
    )
}

export function* fetchAdminUserOrdersWatcher() {
    yield takeLatest(FETCH_ADMIN_USER_ORDERS_WATCHER, fetchAdminUserOrdersWorker);
}

function* fetchAdminUserOrdersWorker(action) {
    try {
        let response = yield call(fetchUserOrdersApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(setAdminUserOrders(response.data.data))
        } else {
            throw (response)
        }
    } catch (e) {
        console.log(e)
    }
}