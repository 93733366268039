import {
	FETCH_QUEST_PROGRESSIONS_WATCHER,
	FETCH_QUEST_PROGRESSION_BUCKET_TOP_CHANNELS_WATCHER,
	FETCH_QUEST_PROGRESSION_BUCKET_TOP_USERS_WATCHER,
	SET_QUEST_PROGRESSIONS_BANK,
	SET_BUCKET_QUEST_PROGRESSIONS_BANK_TOP_USERS,
	SET_BUCKET_QUEST_PROGRESSIONS_BANK_TOP_CHANNELS,
	CLAIM_REWARDS_FROM_QUEST_PROGRESSION,
	ACCEPT_TERMS_FROM_QUEST_PROGRESSION,
} from "../constants/ActionTypes";

export const fetchQuestProgressions = () => {
	return {
		type: FETCH_QUEST_PROGRESSIONS_WATCHER,
	};
};

export const fetchQuestProgressionBucketTopChannels = (params) => {
	return {
		type: FETCH_QUEST_PROGRESSION_BUCKET_TOP_CHANNELS_WATCHER,
		params: params,
	};
};

export const fetchQuestProgressionBucketTopUsers = (params, options) => {
	return {
		type: FETCH_QUEST_PROGRESSION_BUCKET_TOP_USERS_WATCHER,
		params: params,
		options: options,
	};
};

export const setQuestProgressionsBank = (questProgressions) => {
	return {
		type: SET_QUEST_PROGRESSIONS_BANK,
		payload: questProgressions,
	};
};

export const setBucketQuestProgressionsTopUsersBank = (payload, options) => {
	return {
		type: SET_BUCKET_QUEST_PROGRESSIONS_BANK_TOP_USERS,
		payload: payload,
	};
};

export const setBucketQuestProgressionsTopChannelsBank = (payload) => {
	return {
		type: SET_BUCKET_QUEST_PROGRESSIONS_BANK_TOP_CHANNELS,
		payload: payload,
	};
};

/**
 * @param {Object} params API request parameters.
 * @param {String} params.progression The ID of the quest progression to claim rewards from.
 * @param {[{tier: Number, reward_id: String, reward_type: String}]} params.rewards The rewards to claim from the quest progression, eg. [{tier: 1, reward_id: "abc-123", reward_type: "SHOPIFY"}]
 *
 * @param {Object} [options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [options.success] Behavior to execute when API call and action is successful.
 * @param {String} [options.success.redirect] Redirect to this URL on success.
 * @param {String} [options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [options.success.notification] Display notification with passed text on success.
 * @param {Function} [options.success.callback] Callback function on success.
 * @param {*} [options.success.callbackArgs] Callback function args on success.
 *
 * @param {Object} [options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [options.failure.callback] Callback function on failure.
 * @param {*} [options.failure.callbackArgs] Callback function args on failure.
 */
export const claimRewardsFromQuestProgression = (params, options) => {
	return {
		type: CLAIM_REWARDS_FROM_QUEST_PROGRESSION,
		params: params,
		options: options,
	};
};

export const acceptTermsFromQuestProgression = (params, options) => {
	return {
		type: ACCEPT_TERMS_FROM_QUEST_PROGRESSION,
		params: params,
		options: options,
	};
};
