import {
    setSearchedUsers
} from "../../actions/UserAdminPanel";
import {
    showLoader,
    hideLoader
} from "../../actions/GlobalAction";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import { // if you want to redirect post saga action
    push
} from "react-router-redux";
import {
    FETCH_SEARCHED_USERS_WATCHER
} from "../../constants/ActionTypes";
import {
    SEARCH_USERS_URL
} from "../../constants/Url";

import api from '../../util/api'; 

export function* fetchSearchedUsersWatcher() {
    yield takeLatest(FETCH_SEARCHED_USERS_WATCHER, fetchSearchedUsersWorker);
};

function* fetchSearchedUsersWorker(action) {
    try {
        yield put(showLoader());
        let response = yield call(fetchSearchedUsersApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(setSearchedUsers(response.data.data));
        } else {
            throw (response);
        }
        yield put(hideLoader());
    } catch (e) {
        yield put(hideLoader());
    };
};

function fetchSearchedUsersApi(payload) {
    return (
        api
        .get(SEARCH_USERS_URL, {
            params: {
                searchField: payload
            }
        })
    );
};