import { call, put, takeLatest } from "redux-saga/effects";
import { appendNotifications } from "../../../actions/Notifications";
import { UPDATE_TWITCH_BROADCASTS_MODERATOR } from "../../../constants/ActionTypes";
import { TWITCH_BROADCASTS_ASSIGN_MODERATOR } from "../../../constants/Url";
import { ERROR, SUCCESS } from "../../../constants/Notifications";

import api from "../../../util/api";

export function* updateTwitchBroadcastsModeratorWatcher() {
	yield takeLatest(UPDATE_TWITCH_BROADCASTS_MODERATOR, updateTwitchBroadcastsModeratorWorker);
}

function* updateTwitchBroadcastsModeratorWorker(action) {
	try {
		const response = yield call(updateTwitchBroadcastsModeratorApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, response.data);
		}
	} catch (err) {
		console.error(err);
	}
}

async function updateTwitchBroadcastsModeratorApi(payload) {
	try {
		return await api.post(TWITCH_BROADCASTS_ASSIGN_MODERATOR, payload);
	} catch (error) {
		return error.response;
	}
}
