import React from "react";
import PropTypes from "prop-types";

import Modal from "../../../../../../components/Modal/Modal";

function DeleteDialog({ handleDelete }) {
	return (
		<Modal
			triggerBtnText="Delete"
			triggerBtnClassName="button"
			cancelBtnText="Cancel"
			submitBtnText="Delete"
			onSubmit={handleDelete}
		>
			<p>
				Are you sure you want to delete this keyword? This team keyword will no longer be
				included in match schedule query from Liquipedia
			</p>
		</Modal>
	);
}

DeleteDialog.propTypes = {
	handleDelete: PropTypes.func.isRequired,
};

export default DeleteDialog;
