import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { refreshProfile, setUserProfileParam } from "../../actions/User";
import { appendNotifications } from "../../actions/Notifications";

// Constants
import { CHANGE_LANGUAGE } from "../../constants/ActionTypes";
import { USER_LANGUAGE_CHANGE_URL } from "../../constants/Url";
import { USER_UPDATE, ERROR, SUCCESS } from "../../constants/Notifications";

// Utils
import api from "../../util/api";
import { getActiveLanguage, setLanguage } from "../../util/Language";

export function* onUserLanguageChangedWorker({ payload: { lang } }) {
	try {
		if (lang !== getActiveLanguage()) {
			yield call(setLanguage, lang);
		}
	} catch (e) {
		console.error(e);
	}
}

export function* changeLanguageWatcher() {
	yield takeLatest(CHANGE_LANGUAGE, changeLanguageWorker);
}

function* changeLanguageWorker(action) {
	try {
		const response = yield call(changeLanguageApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		if (isSuccess) {
			// fetch user data to render latest data (based on language)
			localStorage.setItem("language", action.payload);
			yield put(refreshProfile());
			yield put(setUserProfileParam("lang", action.payload));
			yield put(
				appendNotifications(USER_UPDATE(isSuccess ? SUCCESS : ERROR, null, action.payload))
			);
		}
	} catch (e) {
		console.error(e);
		yield put(appendNotifications(USER_UPDATE(ERROR, null)));
	}
}

function changeLanguageApi(lang) {
	return api.put(USER_LANGUAGE_CHANGE_URL, { lang: lang });
}
