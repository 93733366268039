import { all } from "redux-saga/effects";

import { fetchCampaignTranslationsStatusWatcher } from "./FetchCampaignTranslationsStatusSagas";
import { patchCampaignTranslationsPublishedStatusWatcher } from "./PatchCampaignTranslationsPublishedStatusSagas";

export function* campaignTranslationsStatusSagas() {
	yield all([
		fetchCampaignTranslationsStatusWatcher(),
		patchCampaignTranslationsPublishedStatusWatcher(),
	]);
}
