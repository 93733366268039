// Redux
import { store } from "../MainApp";

// Utils
import { getUserAge } from "./DateUtil";

// Constants
import { ADMIN } from "../constants/UserRoles";

export function canViewPrivateContent() {
	const state = store.getState();

	const userInfo = {
		user_id: state.user.profile.id,
		birthday: state.user.profile?.userInfo?.birthday,
		parental_consent: state.user.profile?.parental_consent_provided,
	};

	const parentConfirmationRequired = () => {
		const { birthday } = userInfo;
		const user_age = getUserAge(birthday);
		return user_age >= 13 && user_age < 18;
	};

	const checkParentalConsent = () => {
		const { parental_consent } = userInfo;

		return !parentConfirmationRequired() || (parentConfirmationRequired() && parental_consent);
	};

	const jwtFound = () => {
		return window.browserStorage.getJWT() !== null;
	};

	return jwtFound() && userInfo.user_id && checkParentalConsent();
}

export function canViewAdminOnlyContent() {
	let permissionList = window.browserStorage.getPermission();

	if (typeof permissionList === "undefined" || permissionList === null) return false;

	return permissionList.type === ADMIN;
}
