import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { FormFieldSelect } from '../../../../components/FormFields';
import { CUSTOMER_SERVICE_TYPE } from "../../../../constants/Rewards";
import { updateRewards } from '../../../../actions/Rewards';
import "../styles.scss";

class CustomerServiceLevelDialog extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.setState({
            fulfillment: this.props.fulfillment
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            fulfillment: nextProps.fulfillment,
        })
    }

    handleSubmit = () => {
        this.props.updateRewards({
            id: this.props._id,
            params: {
                fulfillment: this.state.fulfillment
            }
        });
    };
    
    handleClose() {
        this.setState({
            fulfillment: this.props.fulfillment
        });
    }
    
    checkValidation() {
        return (
            this.state.fulfillment == this.props.fulfillment
        )
    }

    handleInputChange(event) {
        this.setState({ fulfillment: event.target.value });
    }

    render() {
        return (
            <Modal
                className="admin"
                triggerBtnText="Change"
                triggerBtnClassName="button button--secondary button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Save"
                submitDisabled={this.checkValidation()}
                onSubmit={() => this.handleSubmit()}
                onClose={() => this.handleClose()}
            >
                <FormFieldSelect
                    intro={
                        <React.Fragment>
                            Select the customer service level of this reward: < br />
                            < br />
                            1. Automatic Fulfillment< br />
                            2. Manual Fulfillment
                        </React.Fragment>
                    }
                    label={"Customer Service Level"}
                    name={"customer_service_level"}
                    value={this.state.fulfillment}
                    options={CUSTOMER_SERVICE_TYPE}
                    onChange={(event) => this.handleInputChange(event)}
                />
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateRewards: (payload) => dispatch(updateRewards(payload))
    }
}

const mapStateToProps = state => {
    return {

    }
}

CustomerServiceLevelDialog = connect(mapStateToProps, mapDispatchToProps)(CustomerServiceLevelDialog);

export default CustomerServiceLevelDialog