import React from "react";
import "./styles.scss";
import MomentUtils from "@date-io/moment";
import { Paper } from "@material-ui/core";
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import { formatDateInternational, formatDateTimeInternational } from "../../util/DateUtil";

//  Needed to account for a version mismatch of Moment and material ui pickers.
//  Material UI pickers can't easily be updated due to the need of upgrading a whole lot other main libraries.
MomentUtils.prototype.getStartOfMonth = MomentUtils.prototype.startOfMonth;

class SingleDatePicker extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div className="SingleDatePicker">
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<DatePicker
						value={this.props.datePicked || new Date()}
						format="YYYY.MM.DD"
						onChange={this.props.onChange}
						onClose={this.props.onClose}
						leftArrowIcon={<i className="custom-date-icon zmdi zmdi-chevron-left"></i>}
						rightArrowIcon={<i className="custom-date-icon zmdi zmdi-chevron-right"></i>}
						minDate={this.props.minDate}
						maxDate={this.props.maxDate}
					/>
				</MuiPickersUtilsProvider>
			</div>
		);
	}
}

export { SingleDatePicker };

class SingleDateTimePicker extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="SingleDatePicker">
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<DateTimePicker
						value={this.props.datePicked || new Date()}
						format={this.props.format || "YYYY.MM.DD hh:mm A"}
						onChange={this.props.onChange}
						onClose={this.props.onClose}
						leftArrowIcon={<i className="custom-date-icon zmdi zmdi-chevron-left"></i>}
						rightArrowIcon={<i className="custom-date-icon zmdi zmdi-chevron-right"></i>}
						minDate={this.props.minDate}
						maxDate={this.props.maxDate}
						hideTabs={true}
						DialogProps={{
							className: "DateTimeDialog",
							hideTabs: true,
						}}
					/>
				</MuiPickersUtilsProvider>
			</div>
		);
	}
}

export { SingleDateTimePicker };

// class TimezoneSelector extends React.Component {
//     constructor(props) {
//         super(props)

//         this.state = {
//             timezone: undefined
//         }

//         this.moment = require('moment-timezone');

//         this.handleChange = this.handleChange.bind(this);
//     }

//     componentWillReceiveProps(nextProps) {
//         this.setState({ timezone: nextProps.value });
//     }

//     handleChange(value) {
//         this.setState({ timezone: value });

//         if (this.props.onChange) {
//             this.props.onChange(value[0].value);
//         }
//     }

//     render() {
//         let {
//             className,
//             label,
//             placeholder
//         } = this.props;

//         return (
//             <SelectField
//                 className={className}
//                 label={label}
//                 placeholder={placeholder}
//                 options={this.moment.tz.names()}
//                 values={this.state.timezone}
//                 clearable={this.state.timezone?.length > 0}
//                 onChange={this.handleChange}
//             />
//         );
//     }
// }

// export { TimezoneSelector }

class RangeDatePicker extends React.Component {
	constructor(props) {
		super(props);
		this.dateSelectorRef = React.createRef();
		this.datePickerARef = React.createRef();
		this.datePickerBRef = React.createRef();
		this.state = {
			hideDateInputs: true,
			dateEnd: this.props.dateEnd,
			dateStart: this.props.dateStart,
		};
	}

	setOutsideClickListener(newstate) {
		if (newstate == true) {
			document.addEventListener("click", this.catchOutsideClick, false);
		} else if (newstate == false) {
			document.removeEventListener("click", this.catchOutsideClick, false);
		}
	}

	catchOutsideClick = (e) => {
		// Stoplistening once a SingleDatePicker has been activated, otherwise selecting a date will close the range modal.
		if (
			this.datePickerARef.current.contains(e.target) ||
			this.datePickerBRef.current.contains(e.target)
		) {
			this.setOutsideClickListener(false);
			return;
		}

		// If the user clicks outside of the range modal, hide it.
		if (!this.dateSelectorRef.current.contains(e.target)) {
			this.setState({
				hideDateInputs: true,
			});

			this.setOutsideClickListener(false);
		}
	};

	handleDateRangeClick() {
		// Start or stop listening for clicks outside of the modal.
		if (this.state.hideDateInputs) {
			this.setOutsideClickListener(true);
		} else {
			this.setOutsideClickListener(false);
		}

		this.setState({
			hideDateInputs: !this.state.hideDateInputs,
		});
	}

	handleDateStartPickerChange = (event) => {
		let dates = [];
		dates.dateStart = event.format();
		dates.dateEnd = this.props.dateEnd;

		// Return start and end dates
		this.props.onChange(dates);

		this.setState({
			dateStart: event,
		});
	};

	handleDateEndPickerChange = (event) => {
		let dates = [];
		dates.dateStart = this.props.dateStart;
		dates.dateEnd = event.format();

		// Return start and end dates
		this.props.onChange(dates);

		this.setState({
			dateEnd: event,
		});
	};

	handleDatePickerClose() {
		// Start listening once a SingleDatePicker has been changed
		this.setOutsideClickListener(true);
	}

	renderDateRange() {
		if (this.props.includeTime) {
			return (
				formatDateTimeInternational(this.props.dateStart) +
				" - " +
				formatDateTimeInternational(this.props.dateEnd)
			);
		}
		return (
			formatDateInternational(this.props.dateStart) +
			" - " +
			formatDateInternational(this.props.dateEnd)
		);
	}

	render() {
		return (
			<div className="RangeDatePicker" ref={this.dateSelectorRef}>
				<div className="date-field" ref={this.datePickerARef}>
					{this.props.includeTime ? (
						<React.Fragment>
							<label className="level-text">Start Date and Time (UTC)</label>
							<SingleDateTimePicker
								datePicked={this.state.dateStart}
								maxDate={this.state.dateEnd}
								onChange={this.handleDateStartPickerChange}
								onClose={this.handleDatePickerClose.bind(this)}
							/>
						</React.Fragment>
					) : (
						<React.Fragment>
							<label className="level-text">Start Date</label>
							<SingleDatePicker
								datePicked={this.state.dateStart}
								maxDate={this.state.dateEnd}
								onChange={this.handleDateStartPickerChange}
								onClose={this.handleDatePickerClose.bind(this)}
							/>
						</React.Fragment>
					)}
				</div>
				<div className="date-field" ref={this.datePickerBRef}>
					{this.props.includeTime ? (
						<React.Fragment>
							<label className="level-text">End Date and Time (UTC)</label>
							<SingleDateTimePicker
								datePicked={this.state.dateEnd}
								minDate={this.state.dateStart}
								onChange={this.handleDateEndPickerChange}
								onClose={this.handleDatePickerClose.bind(this)}
							/>
						</React.Fragment>
					) : (
						<React.Fragment>
							<label className="level-text">End Date</label>
							<SingleDatePicker
								datePicked={this.state.dateEnd}
								minDate={this.state.dateStart}
								onChange={this.handleDateEndPickerChange}
								onClose={this.handleDatePickerClose.bind(this)}
							/>
						</React.Fragment>
					)}
				</div>
			</div>
		);
	}
}

export { RangeDatePicker };
