import {
    setPermissionList
} from 
'../../actions/UserAdminPanel' 
import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';

import {
    FETCH_PERMISSION_LIST
} from '../../constants/ActionTypes'
import {
    PERMISSION_LIST_URL
} from '../../constants/Url'

import api from '../../util/api'; 

function fetchPermissionListAPI() {
    return (
        api
        .get(PERMISSION_LIST_URL)
    )
}

export function* fetchPermissionListWatcher() {
    yield takeLatest(FETCH_PERMISSION_LIST, fetchPermissionListWorker);
}

function* fetchPermissionListWorker() {
    try {
        // data is obtained after axios call is resolved
        let response = yield call(fetchPermissionListAPI);
        if (response.status >= 200 && response.status < 300){
            // do something with response
            yield put(setPermissionList(response.data.data));
        } else {
            throw(response)
        }
        
    } catch (e) {
        console.log(e)
    }
}