import React from "react";
import { connect } from "react-redux";
import Modal from "../../../../components/Modal";
import { RangeDatePicker } from "../../../../components/DatePickers";
import { updateEvent } from "../../../../actions/Events";
import { localeToUTC, utcToLocale } from "../../../../util/DateUtil";
import "../styles.scss";

class ChangeDateModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			start_date: "",
			end_date: "",
			date_changed: false,
		};
	}

	handleRangeDatePickerChange = (dates) => {
		this.setState({
			start_date: new Date(dates.dateStart),
			end_date: new Date(dates.dateEnd),
			date_changed: true,
		});
	};

	handleSubmit = () => {
		if (this.state.start_date && this.state.end_date) {
			this.setState({ date_changed: false });
			this.props.updateEvent(
				{
					start_date: localeToUTC(this.state.start_date),
					end_date: localeToUTC(this.state.end_date),
				},
				this.props.eventId
			);
		}
	};

	render() {
		return (
			<Modal
				className="admin"
				triggerBtnText="Change"
				triggerBtnClassName="button button--borderless"
				cancelBtnText="Cancel"
				submitBtnText="Save"
				backdrop={false}
				submitDisabled={
					!this.state.date_changed ||
					(this.state.start_date === this.props.start_date &&
						this.state.end_date === this.props.end_date)
				}
				onSubmit={() => this.handleSubmit()}
				onClose={() =>
					this.setState({
						start_date: utcToLocale(this.props.start_date),
						end_date: utcToLocale(this.props.end_date),
						date_changed: false,
					})
				}
			>
				<div disabled className="form-field form-field--dual">
					<div className="form-field__intro">Select the time period this event will be active.</div>
					<RangeDatePicker
						includeTime
						dateStart={this.state.start_date || utcToLocale(this.props.start_date)}
						dateEnd={this.state.end_date || utcToLocale(this.props.end_date)}
						onChange={(dates) => this.handleRangeDatePickerChange(dates)}
					/>
				</div>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateEvent: (params, id) => dispatch(updateEvent(params, id)),
	};
};

ChangeDateModal = connect(null, mapDispatchToProps)(ChangeDateModal);

export { ChangeDateModal };
