import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./styles.scss";

// Actions
import { fetchValidAchievements } from "../../../actions/Achievements";
import { createEvent } from "../../../actions/Events";
import { appendNotifications } from "../../../actions/Notifications";

// Constants
import { BUTTON_TEXT, EVENT_TYPES, EVENT_SUBTYPES } from "../../../constants/Events";
import { ERROR } from "../../../constants/Notifications";
import { ADMIN } from "../../../constants/RoutesURLs";

// Utils
import { localeToUTC, utcToLocale } from "../../../util/DateUtil";
import { validateNumeric } from "../../../util/stringUtils";

// Components
import { SingleDateTimePicker } from "../../../components/DatePickers";
import { FormFieldInput, FormFieldRadio, FormFieldSelect } from "../../../components/FormFields";
import Modal from "../../../components/Modal";
import LpHeading from "../../../components/LpHeading";
import RadioField from "../../../components/RadioField";

import { MockSerializedEvents } from "./MockData";

class EventCreate extends Component {
	constructor(props) {
		super(props);

		const presentDateUTC = utcToLocale(new Date());
		const futureDateUTC = new Date(
			presentDateUTC.getFullYear(),
			presentDateUTC.getMonth() + 1,
			presentDateUTC.getDate()
		);

		const { fetchValidAchievements } = this.props;

		this.serializedEvents = MockSerializedEvents;

		this.state = {
			creating: false,
			type: EVENT_TYPES.OFFLINE, // OFFLINE || DIGITAL
			subtype: EVENT_SUBTYPES.STANDALONE, // STANDALONE || SERIALIZED
			serial_event: 24,
			achievement_id: null,
			date_start: presentDateUTC,
			date_end: futureDateUTC,
			is_external_url: false,
		};

		this.handleDateStartChange = this.handleDateStartChange.bind(this);
		this.handleDateEndChange = this.handleDateEndChange.bind(this);

		fetchValidAchievements();
	}

	handleInputChange(event) {
		const target = event.target;
		let value;
		const name = target.name;

		switch (target.type) {
			case "number":
				value =
					this.validateNumeric(target.value) || target.value === "" ? target.value : 0;
				break;
			case "checkbox":
				value = target.checked;
				break;
			default:
				value = validateNumeric(target.value) ? Number(target.value) : target.value;
				value = value === "true" ? true : value;
				value = value === "false" ? false : value;
				break;
		}
		this.setState({
			[name]: value,
		});
	}

	handleDateStartChange(event) {
		this.setState({
			date_start: event.format(),
		});
	}

	handleDateEndChange(event) {
		this.setState({
			date_end: event.format(),
		});
	}

	validateNumeric(value) {
		const regex = /^\d+$/;
		return regex.test(value);
	}

	createEvent = (e) => {
		e.preventDefault();

		this.setState({
			creating: true,
		});

		const { createEvent, appendNotifications } = this.props;

		const {
			name,
			type,
			image,
			subtype,
			date_start,
			date_end,
			location,
			is_external_url,
			url_button_text,
			redirect_url,
			points,
			achievement_id,
		} = this.state;

		const params = {
			name: name,
			event_type: type,
			icon_url: image,
			subtype: subtype,
			start_date: localeToUTC(date_start),
			end_date: localeToUTC(date_end),
			location: location,
			is_external_url: is_external_url,
			url_button_text: url_button_text,
			redirect_url: redirect_url,
			points: points || 0,
			achievement_id: achievement_id,
		};

		createEvent(params, {
			success: {
				redirect: `${ADMIN}/events`,
			},
			failure: {
				callback: (response) => {
					this.setState({
						creating: false,
					});
					appendNotifications({
						type: ERROR,
						message: response.msg.errors[0].message,
					});
				},
			},
		});
	};

	updateEvent = () => {
		const { preview_image } = this.state;
		this.setState({
			image: preview_image,
			preview_image: null,
		});
	};

	backToEvents = () => {
		const { history } = this.props;
		history.push(`${ADMIN}/events`);
	};

	renderSerializedEvents() {
		const options = [];

		this.serializedEvents.forEach((event) => {
			options.push(event);
		});

		return options;
	}

	renderAchievements() {
		const { achievements } = this.props;
		const options = [{ label: "** None **", value: null }];
		achievements.forEach((achievement) => {
			options.push({ label: achievement.name, value: achievement.id });
		});

		return options;
	}

	render() {
		const {
			image,
			name,
			type,
			points,
			achievement_id,
			subtype,
			serial_event,
			date_start,
			date_end,
			location,
			is_external_url,
			redirect_url,
			url_button_text,
			creating,
			preview_image,
		} = this.state;

		return (
			<div className="m-3 p-3 Events admin">
				<LpHeading>New Event</LpHeading>
				<form onSubmit={(e) => this.createEvent(e)}>
					<div className="form-field form-field--input-image">
						<figure className="icon-placeholder">
							{preview_image ? (
								<img src={preview_image} alt="icon-placeholder" />
							) : !image ? (
								<img
									alt="None chosen, placeholder"
									src={require("../../../svg/icon-placeholder.svg")}
								/>
							) : (
								<img alt="Event logo" src={image} />
							)}
							<figcaption className="icon-placeholder__caption">
								Event Icon
							</figcaption>
							<Modal
								className="admin"
								triggerBtnText="Change"
								triggerBtnClassName="button button--borderless"
								cancelBtnText="Cancel"
								submitBtnText="Save"
								onSubmit={this.updateEvent}
								onClose={() => this.setState({ preview_image: null })}
							>
								<FormFieldInput
									intro="Paste the url for the image that exists in the CDN."
									label="URL"
									type="text"
									name="preview_image"
									value={preview_image}
									placeholder="cdn.teamliquid.com/uri-that-matches"
									onChange={(event) => this.handleInputChange(event)}
								/>
							</Modal>
						</figure>
					</div>
					<FormFieldInput
						label="Event Name"
						type="text"
						name="name"
						value={name}
						placeholder="its name"
						onChange={(event) => this.handleInputChange(event)}
					/>
					<FormFieldSelect
						label="Event Type"
						name="type"
						value={type}
						options={[
							{ label: "Offline", value: EVENT_TYPES.OFFLINE },
							{ label: "Digital", value: EVENT_TYPES.DIGITAL },
						]}
						onChange={(event) => this.handleInputChange(event)}
					/>
					<FormFieldInput
						label="Points"
						type="number"
						name="points"
						value={points}
						placeholder="Enter the points for this event"
						onChange={(event) => this.handleInputChange(event)}
					/>
					<FormFieldSelect
						label="Achievement"
						name="achievement_id"
						value={achievement_id}
						options={this.renderAchievements()}
						onChange={(event) => this.handleInputChange(event)}
					/>
					<FormFieldSelect
						label="Event SubType"
						name="subtype"
						value={subtype}
						options={[
							{
								label: "Standalone",
								value: EVENT_SUBTYPES.STANDALONE,
							},
							{
								label: "Serialized",
								value: EVENT_SUBTYPES.SERIALIZED,
							},
						]}
						onChange={(event) => this.handleInputChange(event)}
					/>
					{subtype === EVENT_SUBTYPES.SERIALIZED && (
						<FormFieldSelect
							label="Serial event Name"
							name="serial_event"
							value={serial_event}
							options={this.renderSerializedEvents()}
							onChange={(event) => this.handleInputChange(event)}
						/>
					)}
					<div disabled className="form-field">
						<div className="form-field__intro">
							Select the time period this event will run.
						</div>
						<div className="form-field">
							<label className="form-field__label" htmlFor="event-date-start">
								Start Date
							</label>
							<SingleDateTimePicker
								name="date_start"
								datePicked={date_start}
								maxDate={date_end}
								id="event-date-start"
								onChange={this.handleDateStartChange}
							/>
						</div>
						<div className="form-field">
							<label className="form-field__label" htmlFor="event-date-end">
								End Date (final day)
							</label>
							<SingleDateTimePicker
								name="date_start"
								datePicked={date_end}
								minDate={date_start}
								id="event-date-end"
								onChange={this.handleDateEndChange}
							/>
						</div>
					</div>
					<FormFieldInput
						intro="Where does this event take place? This can be a service like Discord, or an offline event like Comic-Con."
						label="Location"
						type="text"
						name="location"
						value={location}
						placeholder="Location name"
						onChange={(event) => this.handleInputChange(event)}
					/>
					{type === EVENT_TYPES.DIGITAL && (
						<React.Fragment>
							<FormFieldRadio
								intro="Is the link for checking into this event a Team Liquid URL, or a URL outside of Team Liquid?"
								name="is_external_url"
								value={is_external_url}
								options={[
									{ label: "Team Liquid URL", value: false },
									{ label: "External URL", value: true },
								]}
								onChange={(event) => this.handleInputChange(event)}
							/>
							{is_external_url && (
								<React.Fragment>
									<FormFieldInput
										required
										intro="Enter the external URL."
										label="Link URL"
										type="text"
										name="redirect_url"
										value={redirect_url}
										onChange={(event) => this.handleInputChange(event)}
									/>
									<div className="form-field">
										<div className="form-field__intro">
											On Team Liquid this URL will sometimes be presented as a
											button with a single word as text. Select the verb you
											would like to use for the URL text.
										</div>
										{BUTTON_TEXT.map((bt) => {
											let bt_lower = bt.toLowerCase();
											let bt_label =
												bt_lower.charAt(0).toUpperCase() +
												bt_lower.slice(1);
											return (
												<RadioField
													id={"url_button_text_" + bt_lower}
													name="url_button_text"
													value={bt}
													label={bt_label}
													onChange={(e) => this.handleInputChange(e)}
													checked={url_button_text === bt}
												/>
											);
										})}
									</div>
								</React.Fragment>
							)}
						</React.Fragment>
					)}

					<div disabled className="form-options">
						<button className="button" type="button" onClick={this.backToEvents}>
							Cancel
						</button>
						<button
							disabled={creating}
							type="submit"
							className="button button--colored-admin"
						>
							Add event
						</button>
					</div>
				</form>
			</div>
		);
	}
}

EventCreate.propTypes = {
	achievements: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			id: PropTypes.string.isRequired,
		})
	),
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	createEvent: PropTypes.func.isRequired,
	fetchValidAchievements: PropTypes.func.isRequired,
	appendNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	achievements: state.achievements.valid_achievements,
});

const mapDispatchToProps = (dispatch) => ({
	createEvent: (params, options) => dispatch(createEvent(params, options)),
	fetchValidAchievements: () => dispatch(fetchValidAchievements()),
	appendNotifications: (params) => dispatch(appendNotifications(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventCreate);
