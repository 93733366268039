import {
    FETCH_TIERS_WATCHER,
    SET_ALL_TIERS,
    UPDATE_TIER_WATCHER

} from "../constants/ActionTypes";

export const fetchTiers = () => {
    return {
        type: FETCH_TIERS_WATCHER,
    };
};

export const setTiers = (data) => {
    return {
        type: SET_ALL_TIERS,
        payload: data
    };
}

export const updateTier = (params, id) => {
    if (id) {
        return {
            type: UPDATE_TIER_WATCHER,
            payload: {
                params,
                id
            }
        };
    }
};