import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import "./styles.scss";

// Utils
import { splitLines } from "../../util/stringUtils";

function Error({ t, error }) {
	return (
		<div className="lp_error">
			<img
				className="lp_error__image"
				src={require("../../svg/alert.svg")}
				alt={t("errorMessageAlt")}
			/>
			<div className="lp_error__message">{splitLines(error)}</div>
		</div>
	);
}

Error.propTypes = {
	t: PropTypes.func,
	error: PropTypes.string.isRequired,
};

export default withTranslation()(Error);
