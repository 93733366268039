import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import Modal from "../../../../../Modal/Modal";
import { FormFieldInput } from "../../../../../FormFields/FormFields";
import CheckBox from "../../../../../CheckBox/CheckBox";
import EditLinkButton from "../EditLinkButton";

function EditLinkDialog({ children, element, updateLink, startedEditing }) {
	const { url, target } = element;

	const linkText = children.map((child) => child.props.text.text).join("");
	const linkUrl = url;

	const [stateLinkText, setLinkText] = useState(linkText);
	const [stateLinkUrl, setLinkUrl] = useState(linkUrl);
	const [stateLinkExternal, setLinkExternal] = useState(target === "_blank");

	function clearFields() {
		setLinkText("");
		setLinkUrl("");
		setLinkExternal(false);
	}

	return (
		<Modal
			className="admin"
			triggerBtn={EditLinkButton}
			cancelBtnText="Close"
			onOpen={() => {
				startedEditing(true);
			}}
			onClose={() => {
				clearFields();
				startedEditing(false);
			}}
			submitBtnText="Apply"
			onSubmit={() => {
				updateLink({ stateLinkText, stateLinkUrl, stateLinkExternal });
				clearFields();
			}}
		>
			<FormFieldInput
				label="Link text"
				value={stateLinkText}
				onChange={(event) => setLinkText(event.target.value)}
			/>
			<FormFieldInput
				label="Link URL"
				value={stateLinkUrl}
				onChange={(event) => setLinkUrl(event.target.value)}
			/>
			<CheckBox
				id={"link_external"}
				label={"Open in New Window"}
				value={stateLinkExternal}
				onChange={(event) => setLinkExternal(event.target.checked)}
				checked={stateLinkExternal === true}
			/>
		</Modal>
	);
}

EditLinkDialog.propTypes = {
	editor: PropTypes.object,
	element: PropTypes.object,
	children: PropTypes.array,
	url: PropTypes.string,
	linkText: PropTypes.string,
	linkExternal: PropTypes.bool,
	updateLink: PropTypes.func.isRequired,
	startedEditing: PropTypes.func,
};

export default EditLinkDialog;
