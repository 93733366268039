import {
	SET_QUESTS_BANK,
	SET_OBJECTIVE_ALGORITHMS_BANK,
} from "../constants/ActionTypes";

const questsInitialState = {
	bank: [],
	algorithms: [],
};

export default function questsReducer(state = questsInitialState, action) {
	switch (action.type) {
		case SET_QUESTS_BANK:
			return {
				...state,
				bank: [...action.payload],
			};
		case SET_OBJECTIVE_ALGORITHMS_BANK:
			return {
				...state,
				algorithms: action.payload,
			};
		default:
			return state;
	}
}
