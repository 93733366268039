import {all} from 'redux-saga/effects';

import {
    fetchTwitterDataWatcher
} from './FetchTwitterChannelSagas'
import {
    createTwitterDataWatcher
} from './CreateTwitterChannelSagas'
import {
    removeTwitterDataWatcher
} from './RemoveTwitterChannelSagas'

export function *TwitterSagas() {
    yield all([
        fetchTwitterDataWatcher(),
        createTwitterDataWatcher(),
        removeTwitterDataWatcher()
    ]);
}