import {
    fetchOrders
} from '../../actions/Orders'

import {
    showLoader,
    hideLoader
} from '../../actions/GlobalAction'

import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';

import { // if you want to redirect post saga action
    push
} from 'react-router-redux';

import {
    MODIFY_ORDER_WATCHER
} from '../../constants/ActionTypes'
import {
    REWARD_SERVICE_ORDERS
} from '../../constants/Url'
import {
    appendNotifications
} from '../../actions/Notifications'
import {
    REWARDS_ORDER_UPDATE,
    ERROR,
    SUCCESS
} from '../../constants/Notifications'

import api from '../../util/api';

/*
    Sample API request body:
    {
        type: 'SHOPIFY',
        params: {
            method: 'update_order',
            id: 123456-abcde-7890-fghi,
            update_params: {
                has_been_contacted: true
            }
    }
*/

function updateOrderApi(update) {
    const url = REWARD_SERVICE_ORDERS + '/' + update.id
    return (
        api
        .put(url, {
            type: update.type,
            params: {
                method: 'update_order',
                update_params: update.params
            }
        })

    )
}

export function* updateOrderWatcher() {
    yield takeLatest(MODIFY_ORDER_WATCHER, modifyOrderWorker);
}

function* modifyOrderWorker(action) {
    try {
        yield put(showLoader());
        // data is obtained after axios call is resolved
        let response = yield call(updateOrderApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(fetchOrders(action.payload.orderState, 1, 20))
            yield put(appendNotifications(REWARDS_ORDER_UPDATE(SUCCESS)))
        } else {
            throw (response)
        }
        yield put(hideLoader());
    } catch (e) {
        yield put(appendNotifications(REWARDS_ORDER_UPDATE(ERROR)))        
        yield put(hideLoader());
    }
}