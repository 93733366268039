import {
	ADD_LIQUIPEDIA_KEYWORD,
	DELETE_LIQUIPEDIA_KEYWORD,
	FETCH_LIQUIPEDIA_KEYWORDS,
	SET_LIQUIPEDIA_KEYWORDS,
} from "../constants/ActionTypes";

export const fetchLiquipediaKeywords = () => ({ type: FETCH_LIQUIPEDIA_KEYWORDS });

export const createLiquipediaKeyword = (payload, options) => ({
	type: ADD_LIQUIPEDIA_KEYWORD,
	payload,
	options,
});

export const deleteLiquipediaKeyword = (payload, options) => ({
	type: DELETE_LIQUIPEDIA_KEYWORD,
	payload,
	options,
});

export const setLiquipediaKeywords = (payload) => ({ type: SET_LIQUIPEDIA_KEYWORDS, payload });
