export const GENDER_OPTIONS = [
	{ code: "he_him", label: "He/Him" },
	{ code: "she_her", label: "She/Her" },
	{ code: "they_them", label: "They/Them" },
	{ code: "same_as_blue", label: "Same as Blue" },
	{ code: "not_listed", label: "Not listed here" },
	{ code: "not_disclosed", label: "Prefer not to disclose" },
];

export const EMAIL_PREFERENCE_OPTIONS = {
	COMMUNITY: "Team Liquid Newsletter",
};

export const TIER_THRESHHOLD_OPTIONS = [
	{ label: "Point Value", value: "POINT_VALUE" },
	{ label: "Custom", value: "CUSTOM" },
];
