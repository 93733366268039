import React, { Component } from "react";
import PropTypes from "prop-types";

// Components
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";

import LpIcon from "../../../../components/LpIcon";
import DeleteDialog from "./atoms/DeleteDialog";
import EditBroadcast from "./atoms/EditBroadcast";

// Utilities
import { utcToLocale, formatDateTimeInternational } from "../../../../util/DateUtil";
import { broadcastPlatforms } from "../../../../constants/Platforms";

class BroadcastChannelRow extends Component {
	renderStatus(started, finished) {
		if (finished) {
			return <span className="broadcast__status broadcast__status--ended">Ended</span>;
		} else if (started) {
			return <span className="broadcast__status broadcast__status--ongoing">Ongoing</span>;
		} else {
			return <span className="broadcast__status broadcast__status--planned">Planned</span>;
		}
	}

	renderParticipants(participants) {
		let output_participants = new Array();
		let teams = new Array();
		let players = new Array();

		participants.map((participant) => {
			if (participant["team"] !== undefined && teams.indexOf(participant["team"]) == -1) {
				teams.push(participant["team"]);
			} else if (
				participant["player"] !== undefined &&
				players.indexOf(participant["player"]) == -1
			) {
				players.push(participant["player"]);
			}
		});

		output_participants = teams.length !== 0 ? teams : players;

		return output_participants.join(" vs ");
	}

	handleDelete = () => this.props.onDelete(this.props.id);

	render() {
		const {
			id,
			start_date,
			end_date,
			event_name,
			started,
			finished,
			channel_name,
			participants,
			managed,
			onUpdate,
			game_icon,
			isSelected,
			onClick,
			moderator,
			broadcastPlatform,
		} = this.props;

		return (
			<TableRow className="broadcast">
				<TableCell padding="checkbox">
					{broadcastPlatform === broadcastPlatforms.TWITCH && (
						<Checkbox checked={isSelected} color="primary" onClick={onClick} />
					)}
				</TableCell>
				<TableCell className="broadcast__details">
					<span className="broadcast__game">
						<LpIcon
							src={game_icon}
							alt={event_name}
							title={event_name}
							arialabel={`${event_name} icon`}
						/>
					</span>
					<span className="broadcast__event-name">{event_name}</span>
					<span className="broadcast__event-status">
						{formatDateTimeInternational(utcToLocale(start_date))}
						{end_date ? " - " + formatDateTimeInternational(utcToLocale(end_date)) : ""}
						{" | "} {this.renderStatus(started, finished)}
					</span>
				</TableCell>
				<TableCell>
					<span>{channel_name}</span>
				</TableCell>
				<TableCell>
					<span>{this.renderParticipants(participants)}</span>
				</TableCell>
				<TableCell>
					<span>{moderator && `${moderator.name} - ${moderator.moderator_id}`}</span>
				</TableCell>
				<TableCell>
					{managed ? (
						<div className="broadcast__options">
							<EditBroadcast
								id={id}
								event_name={event_name}
								start_date={utcToLocale(start_date)}
								end_date={utcToLocale(end_date)}
								channel_name={channel_name}
								handleSubmit={onUpdate}
							/>

							<DeleteDialog handleDelete={this.handleDelete} />
						</div>
					) : null}
				</TableCell>
			</TableRow>
		);
	}
}

BroadcastChannelRow.propTypes = {
	id: PropTypes.string.isRequired,
	start_date: PropTypes.string.isRequired,
	end_date: PropTypes.string,
	game_icon: PropTypes.string,
	event_name: PropTypes.string.isRequired,
	started: PropTypes.bool,
	finished: PropTypes.bool,
	channel_name: PropTypes.string.isRequired,
	managed: PropTypes.bool.isRequired,
	participants: PropTypes.array.isRequired,
	onDelete: PropTypes.func.isRequired,
	onUpdate: PropTypes.func.isRequired,
	isSelected: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
	moderator: PropTypes.shape({
		name: PropTypes.string.isRequired,
		moderator_id: PropTypes.string.isRequired,
	}),
	broadcastPlatform: PropTypes.string.isRequired,
};

export default BroadcastChannelRow;
