
import {
    takeLatest,
    call,
    put,
} from "redux-saga/effects";
import {
    push,
} from "react-router-redux";
import {
	UPDATE_REFERRAL_LINK_WATCHER,
} from "../../constants/ActionTypes";
import {
	UPDATE_REFERRAL_LINK_URL,
} from "../../constants/Url";
import {
	SUCCESS,
	ERROR,
} from "../../constants/Notifications";
import {
	appendNotifications,
} from "../../actions/Notifications";
import {
	addMessage,
} from "../../actions/Messages";

import api from '../../util/api';

export function* updateReferralLinkWatcher() {
	yield takeLatest(UPDATE_REFERRAL_LINK_WATCHER, updateReferralLinkWorker);
};


function* updateReferralLinkWorker(action) {
	try {
		const response = yield call(updateReferralLinkApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;
		
		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (options?.notification) {
			yield put(appendNotifications({
				type: isSuccess ? SUCCESS : ERROR,
				message: typeof options.notification === "string" ? options.message :  response?.data?.error,
			}));
		};
		if (options?.message) {
			yield put(addMessage({
				source: UPDATE_REFERRAL_LINK_WATCHER,
				message: options.message,
				isError: isSuccess,
			}));
		};
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		};
		if (options?.redirect) {
			yield put(push(options.redirect));
		};
    } catch (e) {
        console.error(e);
    };
};

function updateReferralLinkApi(params) {
    return api
		.put(UPDATE_REFERRAL_LINK_URL, params)
        .then(response => { return response })
        .catch(error => { return error.response });
};