import React from "react";
import { connect } from "react-redux";
import { clearNotifications } from "../../actions/Notifications";
import { NotificationContainer, NotificationManager } from "react-notifications";
import TLToast from "../../components/TLToast";
import PropTypes from "prop-types";

class NotificationHub extends React.PureComponent {
	constructor(props) {
		super(props);

		this.timeout = 4800;
		this.baseText = "Team Liquid Notification";
	}

	notify() {
		let { notifications } = this.props;
		if (notifications.length) {
			notifications.forEach((notification) => {
				if (!this.restrictedRoutes().includes(this.location())) {
					this.renderNotification(notification);
				}
			});
			this.props.clearNotifications();
		}
	}

	restrictedRoutes() {
		return [];
	}

	location() {
		return window.location.pathname;
	}

	renderNotification(notification) {
		let { service, timeout, message, type, title } = notification;

		if (!service) {
			service = this.baseText;
		}
		if (!timeout) {
			timeout = this.timeout;
		}

		switch (type) {
			case "info":
				NotificationManager.info(
					<TLToast title={title} type={type} message={message} />,
					service,
					timeout
				);
				break;
			case "success":
				NotificationManager.success(
					<TLToast title={title} type={type} message={message} />,
					service,
					timeout
				);
				break;
			case "warning":
				NotificationManager.warning(
					<TLToast title={title} type={type} message={message} />,
					service,
					timeout
				);
				break;
			case "error":
				if (!message) message = "Oops, that didn't work. Can you try again?";
				NotificationManager.error(
					<TLToast title={title} type={type} message={message} />,
					service,
					timeout
				);
				break;
			default:
			// Limbo
		}
	}

	render() {
		this.notify();
		return (
			<React.Fragment>
				<NotificationContainer enterTimeout={5000} leaveTimeout={0} />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		notifications: state.notifications.bank,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		clearNotifications: () => dispatch(clearNotifications()),
	};
};

NotificationHub.propTypes = {
	notifications: PropTypes.array.isRequired,
	clearNotifications: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationHub);
