import {
    takeLatest,
    call,
    put,
} from "redux-saga/effects";
import {
    push,
} from "react-router-redux";
import {
	UPDATE_USER_EMAIL,
} from "../../../constants/ActionTypes";
import {
	USER_EMAIL_URL,
} from "../../../constants/Url";
import {
	SUCCESS,
	ERROR,
} from "../../../constants/Notifications";
import {
	appendNotifications,
} from "../../../actions/Notifications";
import {
	addMessage,
} from "../../../actions/Messages";

import api from '../../../util/api'

export function* updateUserEmailWatcher() {
    yield takeLatest(UPDATE_USER_EMAIL, updateUserEmailWorker);
};


/**
 * @param {String} action.params.user The ID of the user to update.
 * @param {Boolean | Number} action.params.email The value to update the user's ```email``` to.
 * 
 * @param {Object} [action.options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 * 
 * @param {Object} [action.options.success] Behavior to execute when API call and action is successful.
 * @param {String} [action.options.success.redirect] Redirect to this URL on success.
 * @param {String | Boolean} [action.options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [action.options.success.notification] Display notification with passed text on success. If ```message``` is ```true```, push API response message instead of passed string.
 * @param {Function} [action.options.success.callback] Callback function on success.
 * @param {*} [action.options.success.callbackArgs] Callback function args on success.
 * 
 * @param {Object} [action.options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [action.options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [action.options.failure.message] Push message with passed text to ```state.messages.bank``` on failure. If ```message``` is ```true```, push API response message instead of passed string.
 * @param {String} [action.options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [action.options.failure.callback] Callback function on failure.
 * @param {*} [action.options.failure.callbackArgs] Callback function args on failure.
 */
function* updateUserEmailWorker(action) {
    try {
        const response = yield call(updateUserEmailApi, action.params);
        const isSuccess = response.status >= 200 && response.status < 300;
        const options = isSuccess ? action?.options?.success : action?.options?.failure;
        if (options?.notification) {
            yield put(appendNotifications({
                type: isSuccess ? SUCCESS : ERROR,
                message: options.notification,
            }));
        };
        if (options?.message) {
            yield put(addMessage({
				source: UPDATE_USER_EMAIL,
				message: typeof options.message === "string" ? options.message : response?.data?.data?.email || response?.data?.error,
				isError: isSuccess,
            }));
        };
        if (options?.callback) {
            yield call(options.callback, options.callbackArgs);
        };
        if (options?.redirect) {
            yield put(push(options.redirect));
        };
    } catch (e) {
        console.error(e);
    };
}


/**
 * Sends an API request to update a user's ```email``` property.
 * @param {String} params.user The ID of the user to update.
 * @param {String} params.email The value to update ```email``` to.
 * @return {Object} The response object from the API call.
 */
function updateUserEmailApi(params) {
    return api
        .put(USER_EMAIL_URL(params.user), {
            email: params.email,
        })
        .then(response => { return response })
        .catch(error => { return error.response });
};