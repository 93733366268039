import { takeLatest, call, put } from "redux-saga/effects";

import { SUCCESS, ERROR } from "../../../constants/Notifications";
import { appendNotifications } from "../../../actions/Notifications";

import { fetchDiscordData } from "../../../actions/Channel";
import { showLoader, hideLoader } from "../../../actions/GlobalAction";
import { CREATE_DISCORD_SERVER } from "../../../constants/ActionTypes";
import { CREATE_DISCORD_CHANNELS } from "../../../constants/Url";
import api from "../../../util/api";

export function* createDiscordDataWatcher() {
	yield takeLatest(CREATE_DISCORD_SERVER, createDiscordDataWorker);
}

/**
 * @param {String} action.params.serverName Name for Discord bot.
 * @param {String} action.params.token Discord bot token, required to track bot activity.
 * @param {String} action.params.type Type of Discord bot.
 * @param {Array} action.params.esport_entities List of esport entities related to this bot.
 *
 * @param {Object} [action.options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [action.options.success] Behavior to execute when API call and action is successful.
 * @param {String} [action.options.success.notification] Display notification with passed text on success.
 * @param {Function} [action.options.success.callback] Callback function on success.
 *
 * @param {Object} [action.options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [action.options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [action.options.failure.callback] Callback function on failure.
 */

function* createDiscordDataWorker(action) {
	try {
		yield put(showLoader());
		// data is obtained after axios call is resolved
		let response = yield call(createDiscordDataApi, action.params);

		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (isSuccess) {
			yield put(fetchDiscordData());
		}

		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}

		if (options?.callback) {
			yield call(options.callback, response);
		}
	} catch (e) {
		console.log(e);
	} finally {
		yield put(hideLoader());
	}
}

function createDiscordDataApi(params) {
	return api.post(CREATE_DISCORD_CHANNELS, {
		serverName: params.serverName,
		token: params.token,
		type: params.type,
		esport_entities: params.esport_entities,
	});
}
