import { takeLatest, call, put } from "redux-saga/effects";

import { fetchCampaigns } from "../../actions/Campaigns";

import { UPDATE_CAMPAIGN_WATCHER } from "../../constants/ActionTypes";

import { UPDATE_CAMPAIGN_URL } from "../../constants/Url";

import api from "../../util/api";

export function* updateCampaignsWatcher() {
	yield takeLatest(UPDATE_CAMPAIGN_WATCHER, updateCampaignsWorker);
}

function* updateCampaignsWorker(action) {
	try {
		let response = yield call(updateCampaignsApi, action.payload);
		if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
			yield put(fetchCampaigns());
		} else {
			throw response;
		}
	} catch (e) {
		console.error(e);
	}
}

function updateCampaignsApi(payload) {
	return api.put(UPDATE_CAMPAIGN_URL(payload._id), payload);
}
