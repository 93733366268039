import {
    setRoles
} from "../../actions/UserAdminPanel"
import {
    showLoader,
    hideLoader
} from "../../actions/GlobalAction";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import { // if you want to redirect post saga action
    push
} from "react-router-redux";
import {
    GET_ROLES_WATCHER
} from "../../constants/ActionTypes";
import {
    GET_ROLES_URL
} from "../../constants/Url";
import api from '../../util/api';

export function* fetchRolesWatcher() {
    yield takeLatest(GET_ROLES_WATCHER, fetchRolesWorker);
};

function* fetchRolesWorker(action) {
    try {
        yield put(showLoader());
        let response = yield call(fetchRolesApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(setRoles(response.data.data));
        } else {
            throw (response);
        }
        yield put(hideLoader());
    } catch (e) {
        yield put(hideLoader());
    };
};

function fetchRolesApi() {
    return (
        api
        .get(GET_ROLES_URL)
    );
};