import {
    FETCH_ACHIEVEMENTS_WATCHER,
    SET_ACHIEVEMENTS,
    CREATE_ACHIEVEMENT_WATCHER,
    SEARCH_ACHIEVEMENTS_WATCHER,
    SET_SEARCHED_ACHIEVEMENTS,
    FETCH_ACHIEVEMENT_WATCHER,
    SET_ACHIEVEMENT,
    UPDATE_ACHIEVEMENT_WATCHER,
    FETCH_VALID_ACHIEVEMENTS_WATCHER,
    SET_VALID_ACHIEVEMENTS,
} from "../constants/ActionTypes";

export const fetchAchievements = () => {
    return {
        type: FETCH_ACHIEVEMENTS_WATCHER
    }
}

export const setAchievements = (achievements) => {
    return {
        type: SET_ACHIEVEMENTS,
        payload: achievements
    }
};

export const createAchievement = (achievement) => {
    return {
        type: CREATE_ACHIEVEMENT_WATCHER,
        payload: achievement
    };
};

export const searchAchievements = (params) => {
    return {
        type: SEARCH_ACHIEVEMENTS_WATCHER,
        payload: params
    }
}

export const setSearchedAchievements = (achievements) => {
    return {
        type: SET_SEARCHED_ACHIEVEMENTS,
        payload: achievements
    }
};

export const fetchAchievement = (eventId) => {
    if (eventId) {
        return {
            type: FETCH_ACHIEVEMENT_WATCHER,
            payload: eventId
        };
    }
};

export const setAchievement = (achievement) => {
    return {
        type: SET_ACHIEVEMENT,
        payload: achievement
    };
};

export const updateAchievement = (params,id, options) => {
    return {
        type: UPDATE_ACHIEVEMENT_WATCHER,
        options: options,
        payload: {
            params,
            id
        }
    };
};

export const fetchValidAchievements = () => {
    return {
        type: FETCH_VALID_ACHIEVEMENTS_WATCHER
    }
}

export const setValidAchievements = (achievements) => {
    return {
        type: SET_VALID_ACHIEVEMENTS,
        payload: achievements
    }
};