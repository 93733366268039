import { call, put, takeLatest } from "redux-saga/effects";
import { FETCH_TWITCH_APP_MODERATORS } from "../../../constants/ActionTypes";
import { TWITCH_APP_MODERATORS } from "../../../constants/Url";
import { setTwitchAppModerators } from "../../../actions/Channel";

import api from "../../../util/api";

export function* fetchModeratorsWatcher() {
	yield takeLatest(FETCH_TWITCH_APP_MODERATORS, fetchModeratorsWorker);
}

function* fetchModeratorsWorker() {
	try {
		const response = yield call(fetchModeratorsApi);
		const isSuccess = response.status >= 200 && response.status < 300;
		if (isSuccess) {
			yield put(setTwitchAppModerators(response.data.data));
		}
	} catch (err) {
		console.error(err);
	}
}

async function fetchModeratorsApi() {
	try {
		return await api.get(TWITCH_APP_MODERATORS);
	} catch (error) {
		return error.response;
	}
}
