import {
	CREATE_QUESTS_WATCHER,
	FETCH_QUESTS_WATCHER,
	ADMIN_FETCH_QUESTS_WATCHER,
	UPDATE_QUEST_WATCHER,
	DELETE_QUEST_WATCHER,
	SET_QUESTS_BANK,
	UPDATE_MULTIPLE_QUESTS_WATCHER,
	FETCH_OBJECTIVE_ALGORITHMS_WATCHER,
	SET_OBJECTIVE_ALGORITHMS_BANK,
} from "../constants/ActionTypes";

export const createQuests = (quests, options) => {
	return {
		type: CREATE_QUESTS_WATCHER,
		payload: quests,
		options,
	};
};

export const fetchQuests = (options = { force: false }) => {
	return {
		type: FETCH_QUESTS_WATCHER,
		options: options,
	};
};

export const adminFetchQuests = (params, options) => {
	return {
		type: ADMIN_FETCH_QUESTS_WATCHER,
		params: params,
		options: options,
	};
};

export const fetchObjectiveAlgorithms = () => {
	return {
		type: FETCH_OBJECTIVE_ALGORITHMS_WATCHER,
	};
};

export const updateQuest = (update) => {
	return {
		type: UPDATE_QUEST_WATCHER,
		payload: update,
	};
};

/**
 * @param {String} payload.id The ID of the quest to delete.
 * @param {Boolean} payload.displayNotifications Optional. Whether to display slideout notifications on success or error.
 * @param {Boolean} payload.successRedirect Optional. URL to redirect to on success.
 * @param {Boolean} payload.failureRedirect Optional. URL to redirect to on failure.
 */
export const deleteQuest = (payload) => {
	return {
		type: DELETE_QUEST_WATCHER,
		payload: payload,
	};
};

export const setQuestsBank = (quests) => {
	return {
		type: SET_QUESTS_BANK,
		payload: quests,
	};
};

/**
 * @param {Object[]} quests Array of quests to update.
 */
export const updateMultipleQuests = (quests) => {
	return {
		type: UPDATE_MULTIPLE_QUESTS_WATCHER,
		payload: quests,
	};
};

export const setObjectiveAlgorithmsBank = (algorithms) => {
	return {
		type: SET_OBJECTIVE_ALGORITHMS_BANK,
		payload: algorithms,
	};
};
