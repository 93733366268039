import { call, put, takeLatest } from "redux-saga/effects";
import { appendNotifications } from "../../../actions/Notifications";
import { UPDATE_TWITCH_APP_MODERATOR } from "../../../constants/ActionTypes";
import { UPDATE_TWITCH_APP_MODERATOR as updateUrl } from "../../../constants/Url";
import { ERROR, SUCCESS } from "../../../constants/Notifications";

import api from "../../../util/api";

export function* updateModeratorWatcher() {
	yield takeLatest(UPDATE_TWITCH_APP_MODERATOR, updateModeratorWorker);
}

function* updateModeratorWorker(action) {
	try {
		const response = yield call(updateModeratorApi, {
			id: action.id,
			payload: action.payload,
		});
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: isSuccess ? "Moderator successfully updated." : response?.data?.error,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, response.data);
		}
	} catch (err) {
		console.error(err);
	}
}

async function updateModeratorApi({ id, payload }) {
	try {
		return await api.patch(updateUrl(id), payload);
	} catch (error) {
		return error.response;
	}
}
