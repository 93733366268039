import React, { Component } from "react";
import { connect } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import LpHeading from "../../../components/LpHeading";
import EventsCurrent from "./EventsCurrent";
import EventsCompleted from "./EventsCompleted";
import { Link } from "react-router-dom";

class Events extends Component {
	constructor(props) {
		super(props);

		this.state = {
			component: 0,
		};
	}

	handleChange = (event, component) => {
		this.setState({ component });
	};

	loadPendingTabContainer() {
		return this.state.component === 0 && <EventsCurrent />;
	}

	loadCompletedTabContainer() {
		return this.state.component === 1 && <EventsCompleted />;
	}

	render() {
		return (
			<div className="m-3 p-3 Events admin">
				<div>
					<LpHeading>Events</LpHeading>
				</div>
				<div className="options-bar">
					<Link to={`/app/events/create/`} className="sub-heading">
						<button className="button">Add Event</button>
					</Link>
				</div>
				<div className="mt-4">
					<Tabs
						className="event-tabs"
						value={this.state.component}
						onChange={this.handleChange}
						indicatorColor="primary"
						textColor="primary"
					>
						<Tab className="tab" label="Active/Pending" />
						<Tab className="tab" label="Completed" />
					</Tabs>
					{this.loadPendingTabContainer()}
					{this.loadCompletedTabContainer()}
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {};
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);
