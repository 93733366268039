import React, { useState } from "react";
import PropTypes from "prop-types";

import Modal from "../../../../../../components/Modal/Modal";
import LpHeading from "../../../../../../components/LpHeading";
import TextField from "../../../../../../components/TextField/TextField";

function CreateDialog({ handleCreate }) {
	const [keyword, setKeyword] = useState();

	return (
		<Modal
			triggerBtnText="Add Keyword"
			triggerBtnClassName="button"
			cancelBtnText="Cancel"
			submitBtnText="Add"
			onSubmit={(e) => {
				handleCreate(keyword);
				setKeyword();
			}}
			submitDisabled={!keyword}
		>
			<LpHeading>New Team Keyword</LpHeading>
			<p>
				Adding a new keyword enables the application to request match schedules from
				Liquipedia for this specific team name or keyword.
			</p>
			<TextField
				type="text"
				label="Keyword or Team Name"
				onChange={(e) => setKeyword(e.target.value)}
				value={keyword}
			/>
		</Modal>
	);
}

CreateDialog.propTypes = {
	handleCreate: PropTypes.func.isRequired,
};

export default CreateDialog;
