import {
    takeLatest,
    call,
    put,
} from "redux-saga/effects";
import {
    push,
} from "react-router-redux";
import {
	UPDATE_USER_MEMBERSHIP,
} from "../../../constants/ActionTypes";
import {
	USER_MEMBERSHIP_URL,
} from "../../../constants/Url";
import {
	SUCCESS,
	ERROR,
} from "../../../constants/Notifications";
import {
	appendNotifications,
} from "../../../actions/Notifications";
import {
	addMessage,
} from "../../../actions/Messages";
import {
	setUserProfileParam,
} from "../../../actions/User";
import {
    fetchUserAccountInfo,
} from "../../../actions/UserAdminPanel";

import api from '../../../util/api';

export function* updateUserMembershipWatcher() {
	yield takeLatest(UPDATE_USER_MEMBERSHIP, updateUserMembershipWorker);
};

function* updateUserMembershipWorker(action) {
	try {
		const response = yield call(updateUserUserMembershiApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;
		
		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (isSuccess && options?.updateUserProfile) {
			yield put(setUserProfileParam("custom_tier_name", response?.data?.data?.custom_tier_name));
		};
		if (isSuccess && options?.fetchUserProfileForAdminUi) {
			yield put(fetchUserAccountInfo(action.params.user));
		};
		if (options?.notification) {
			yield put(appendNotifications({
				type: isSuccess ? SUCCESS : ERROR,
				message: typeof options.notification === "string" ? options.notification : isSuccess ? "Profile updated." : response?.data?.error,
			}));
		};
		if (options?.message) {
			yield put(addMessage({
				source: UPDATE_USER_MEMBERSHIP,
				message: typeof options.message === "string" ? options.message : response?.data?.data?.gender || response?.data?.error,
				isError: !isSuccess,
			}));
		};
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		};
		if (options?.redirect) {
			yield put(push(options.redirect));
		};
    } catch (e) {
        console.error(e);
    };
};


function updateUserUserMembershiApi(params) {
    return api
        .put(USER_MEMBERSHIP_URL(params.user), {
			custom_tier_name: params.custom_tier_name,
		})
        .then(response => { return response })
        .catch(error => { return error.response });
};