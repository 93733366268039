import {
    setBanUsers
} from // actions location
'../../actions/UserAdminPanel' // sample for syntax correctness

import{
    showLoader,
    hideLoader
  } from '../../actions/GlobalAction'

import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';
import {
    FETCH_BAN_USERS_WATCHER
} from '../../constants/ActionTypes'
import {
    ALL_BAN_USERS_URL
} from '../../constants/Url'

import api from '../../util/api';

function fetchBanUsersAPI(params) {
    return (
        api
        .post(ALL_BAN_USERS_URL, {
            page: params.page,
            limit: params.limit,
            search: params.search,
            dateRange: params.dateRange
        })

    )
}

export function* fetchBanUsersWatcher() {
    yield takeLatest(FETCH_BAN_USERS_WATCHER, fetchBanUsersWorker);
}

function* fetchBanUsersWorker(action) {
    try {
        yield put(showLoader());
        // data is obtained after axios call is resolved
        let response = yield call(fetchBanUsersAPI, action.payload);
        if (response.status >= 200 && response.status < 300){
            yield put(setBanUsers(response.data.data))
        } else {
            throw(response)
        }
        
        yield put(hideLoader());
    } catch (e) {
        console.log(e)
        yield put(hideLoader());
    }
}