import { call, put, takeLatest } from "redux-saga/effects";
import { appendNotifications } from "../../../actions/Notifications";
import { DELETE_TWITCH_APP_MODERATOR } from "../../../constants/ActionTypes";
import { DELETE_TWITCH_APP_MODERATOR as deleteUrl } from "../../../constants/Url";
import { ERROR, SUCCESS } from "../../../constants/Notifications";

import api from "../../../util/api";

export function* deleteModeratorWatcher() {
	yield takeLatest(DELETE_TWITCH_APP_MODERATOR, deleteModeratorWorker);
}

function* deleteModeratorWorker(action) {
	try {
		const response = yield call(deleteModeratorApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: isSuccess ? "Moderator successfully deleted." : response?.data?.error,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, response.data);
		}
	} catch (err) {
		console.error(err);
	}
}

async function deleteModeratorApi(id) {
	try {
		return await api.delete(deleteUrl(id));
	} catch (error) {
		return error.response;
	}
}
