import {
   fetchEvent
} from "../../actions/Events";
import {
    showLoader,
    hideLoader
} from "../../actions/GlobalAction";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import { // if you want to redirect post saga action
    push
} from "react-router-redux";
import {
    UPDATE_EVENT_WATCHER
} from "../../constants/ActionTypes";
import {
    UPDATE_EVENT_URL
} from "../../constants/Url";

import api from '../../util/api';

function eventUpdateApi(payload) {
    const {
        id
    } = payload;
    let token = window.browserStorage.getJWT();
    return (
        api
        .post(UPDATE_EVENT_URL(id), {
            ...payload.params,
            id
        }, {
            token
        })
    );
};

export function* updateEventWatcher() {
    yield takeLatest(UPDATE_EVENT_WATCHER, updateEventWorker);
};

function* updateEventWorker(action) {
    try {
        yield put(showLoader());
        let response = yield call(eventUpdateApi, action.payload);
        if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
            yield put(fetchEvent(response.data.data));
        } else {
            throw (response);
        }
        yield put(hideLoader());
    } catch (e) {
        yield put(hideLoader());
    };
};