import countries from "countries-list";
import { call, put, takeLatest } from "redux-saga/effects";
import { setCountries } from "../../actions/Countries";
import { FETCH_COUNTRIES_WATCHER } from "../../constants/ActionTypes";
import { FETCH_COUNTRIES_URL } from "../../constants/Url";

import api from "../../util/api";

export function* fetchCountriesWatcher() {
	yield takeLatest(FETCH_COUNTRIES_WATCHER, fetchCountriesWorker);
}

function* fetchCountriesWorker() {
	try {
		const response = yield call(fetchCountriesApi);
		const isSuccess = response.status >= 200 && response.status < 300;
		if (isSuccess) {
			yield put(setCountries(response.data.data));
		} else {
			// set fallback
			yield setCountriesWithFallbackCountries();
		}
	} catch (error) {
		console.warn(error);
		// set fallback
		yield setCountriesWithFallbackCountries();
	}
}

/**
 * This function is created as a fallback of countries data
 * this will set countries reducer with countries that coming from country-list library
 * country-list project: https://github.com/annexare/Countries
 * that library using ISO 3166-1 alpha-2 for code and name, which exactly the same like countries data that coming from TL backend
 */
function* setCountriesWithFallbackCountries() {
	const countryCodes = Object.keys(countries.countries);
	const fallbackCountries = [];
	countryCodes.forEach((code) => {
		fallbackCountries.push({ code, label: countries.countries[code].name });
	});
	yield put(setCountries(fallbackCountries));
}

async function fetchCountriesApi() {
	try {
		return await api.get(FETCH_COUNTRIES_URL);
	} catch (error) {
		return error.response;
	}
}
