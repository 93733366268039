import {
	FETCH_V2_QUESTS_OUT_OF_EXPERIENCES_WATCHER,
	FETCH_V2_QUESTS_COMPLETED_WATCHER,
	FETCH_V2_QUEST_WATCHER,
	SET_V2_QUESTS_BANK,
	FETCH_V2_EXPERIENCES_WATCHER,
	FETCH_V2_EXPERIENCE_DETAILS_WATCHER,
	SET_V2_EXPERIENCES_BANK,
} from "../constants/ActionTypes";

export const fetchV2QuestsOutOfExperiences = (options) => {
	return {
		type: FETCH_V2_QUESTS_OUT_OF_EXPERIENCES_WATCHER,
		options,
	};
};

export const fetchV2QuestsCompleted = (options) => {
	return {
		type: FETCH_V2_QUESTS_COMPLETED_WATCHER,
		options,
	};
};

export const fetchV2Quest = (urlSlug, options) => {
	return {
		type: FETCH_V2_QUEST_WATCHER,
		urlSlug,
		options,
	};
};

export const setV2QuestsBank = (quests) => {
	return {
		type: SET_V2_QUESTS_BANK,
		payload: quests,
	};
};

export const fetchV2Experiences = (options) => {
	return {
		type: FETCH_V2_EXPERIENCES_WATCHER,
		options,
	};
};

export const fetchV2ExperienceDetails = (urlSlug, options) => {
	return {
		type: FETCH_V2_EXPERIENCE_DETAILS_WATCHER,
		urlSlug,
		options,
	};
};

export const setV2ExperiencesBank = (quests) => {
	return {
		type: SET_V2_EXPERIENCES_BANK,
		payload: quests,
	};
};
