import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import uuid from "uuid/v4";

const Input = forwardRef((props, ref) => {
	const _id = uuid();
	const {
		className,
		autoFocus,
		label,
		value,
		name,
		onChange,
		onBlur,
		onFocus,
	} = props;
	return (
		<div className={className}>
			{label ? <label htmlFor={_id}>{label}</label> : null}
			<input
				ref={ref}
				id={_id}
				autoFocus={autoFocus}
				onChange={(event) => onChange(event)}
				onBlur={(event) => onBlur(event)}
				onFocus={(event) => onFocus(event)}
				name={name}
				value={value}
				pattern="[0-9]*"
				inputMode="numeric"
			/>
		</div>
	);
});

Input.propTypes = {
	props: PropTypes.shape({
		ref: PropTypes.node,
		autoFocus: PropTypes.bool,
		className: PropTypes.string,
		label: PropTypes.string,
		value: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
		onBlur: PropTypes.func,
		onFocus: PropTypes.func,
	}),
};

Input.defaultProps = {
	autoFocus: false,
};

export default Input;
