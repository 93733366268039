import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "../../../../components/Modal";
import { FormFieldRadio } from "../../../../components/FormFields";
import { updateEvent } from "../../../../actions/Events";
import "../styles.scss";

class ChangeLinkTypeModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			is_external_url: "",
		};
	}

	componentWillMount() {
		let { is_external_url } = this.props;

		this.setState({
			is_external_url: is_external_url,
		});
	}

	componentWillReceiveProps(nextProps) {
		let { is_external_url } = this.state;

		if (is_external_url !== nextProps.is_external_url) {
			this.setState({
				is_external_url: nextProps.is_external_url,
			});
		}
	}

	handleSubmit = () => {
		let { is_external_url } = this.state;
		let { updateEvent, eventId } = this.props;

		if (is_external_url !== undefined) {
			updateEvent(
				{
					is_external_url: is_external_url,
				},
				eventId
			);
		}
	};

	render() {
		let { is_external_url } = this.state;

		return (
			<Modal
				className="admin"
				triggerBtnText="Change"
				triggerBtnClassName="button button--borderless"
				cancelBtnText="Cancel"
				submitBtnText="Save"
				submitDisabled={is_external_url === this.props.is_external_url}
				onSubmit={() => this.handleSubmit()}
				onClose={(event) =>
					this.setState({
						is_external_url: this.props.is_external_url,
					})
				}
			>
				<FormFieldRadio
					intro={
						"Is the link for checking into this event a Team Liquid URL, or a URL outside of Team Liquid?"
					}
					name={"is_external_url"}
					value={is_external_url}
					options={[
						{ label: "Team Liquid URL", value: false },
						{ label: "External URL", value: true },
					]}
					onChange={(event) =>
						this.setState({
							is_external_url:
								event.target.value === "true" ? true : false,
						})
					}
				/>
			</Modal>
		);
	}
}

ChangeLinkTypeModal.propTypes = {
	is_external_url: PropTypes.bool,
	updateEvent: PropTypes.func.isRequired,
	eventId: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateEvent: (params, id) => dispatch(updateEvent(params, id)),
	};
};

export default connect(null, mapDispatchToProps)(ChangeLinkTypeModal);
