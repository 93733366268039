import {
	FETCH_USER_PREFERRED_GAMES_WATCHER,
	SET_USER_PREFERRED_GAMES_WATCHER,
	SET_GAMES_PUBLIC_INFO_WATCHER,
	FETCH_GAMES,
	ADD_GAME_WATCHER,
	SET_GAMES,
	ARCHIVE_RESTORE_GAME_WATCHER,
	UPDATE_GAME_WATCHER,
	DELETE_GAME_WATCHER,
	FETCH_GAMES_PUBLIC_INFO_WATCHER,
} from "../constants/ActionTypes";

export const fetchUserPreferredGames = () => {
	return {
		type: FETCH_USER_PREFERRED_GAMES_WATCHER,
	};
};

export const setUserPreferredGames = (games) => {
	return {
		type: SET_USER_PREFERRED_GAMES_WATCHER,
		payload: games,
	};
};

export const fetchGamesPublicInfo = () => {
	return {
		type: FETCH_GAMES_PUBLIC_INFO_WATCHER,
	};
};

export const setGamesPublicInfo = (games) => {
	return {
		type: SET_GAMES_PUBLIC_INFO_WATCHER	,
		payload: games,
	};
};

export const fetchGames = (params) => {
	return {
		type: FETCH_GAMES,
		payload: params,
	};
};

export function setGames(games) {
	return {
		type: SET_GAMES,
		payload: games,
	};
}

export const addGame = (game, options) => {
	return {
		type: ADD_GAME_WATCHER,
		payload: game,
		options: options,
	};
};

export const archieveOrRestoreGame = (game, options) => {
	return {
		type: ARCHIVE_RESTORE_GAME_WATCHER,
		payload: game,
		options: options,
	};
};

export const updateGame = (game, options) => {
	return {
		type: UPDATE_GAME_WATCHER,
		payload: game,
		options: options,
	};
};

export const deleteGame = (game, options) => {
	return {
		type: DELETE_GAME_WATCHER,
		payload: game,
		options: options,
	};
};