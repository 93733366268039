import { SET_GENDERS } from "../constants/ActionTypes";

const gendersInitialState = [];

export default function gendersReducer(state = gendersInitialState, action) {
	switch (action.type) {
		case SET_GENDERS:
			return action.payload;
		default:
			return state;
	}
}
