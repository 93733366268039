import { RUM_STATUS_WATCHER, SET_RUM_STATUS } from "../constants/ActionTypes";

export const getRumStatus = (options) => ({
	type: RUM_STATUS_WATCHER,
	options,
});

export const setRumStatus = (payload) => ({
	type: SET_RUM_STATUS,
	payload,
});
