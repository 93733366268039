import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactEditor, useSelected, useFocused, useSlateStatic } from "slate-react";

// Components
import LpIconHtmlLinkRemove from "../../../icons/LpIconHtmlLinkRemove";
import LpTooltip from "../../../LpTooltip";
import EditLinkDialog from "../Toolbar/atoms/EditLinkDialog";

export default function LinkElement(props) {
	const { attributes, element, children, customEditor } = props;
	const { url, target } = element;

	const editor = useSlateStatic();
	const selected = useSelected();
	const focused = useFocused();

	const [editing, setEditing] = useState(false);
	const [showOptions, setShowOptions] = useState(false);
	const path = ReactEditor.findPath(editor, element);

	return (
		<a
			onMouseDown={() => {
				setShowOptions(true);
			}}
			{...attributes}
			href={url}
			target={target}
		>
			{children}
			{((selected && focused && showOptions) || editing) && (
				<span className="link-element__popup" contentEditable={false}>
					<EditLinkDialog
						children={children}
						element={element}
						startedEditing={(value) => setEditing(value)}
						updateLink={(payload) => {
							setEditing(false);
							customEditor.editLink(editor, payload, path);
						}}
					/>
					<button
						className="lp-rich-text-editor__button"
						onClick={(event) => {
							event.preventDefault();
							customEditor.removeLink(editor);
						}}
					>
						<LpTooltip text="Remove Link">
							<LpIconHtmlLinkRemove
								alt="Broken link chain icon"
								title="Remove link"
							/>
						</LpTooltip>
					</button>
				</span>
			)}
		</a>
	);
}

LinkElement.propTypes = {
	attributes: PropTypes.any,
	element: PropTypes.object,
	children: PropTypes.array,
	editor: PropTypes.object,
	customEditor: PropTypes.object,
};
