import { all } from "redux-saga/effects";
import { fetchEventsWatcher } from "./FetchEventsSaga";
import { processEventsWatcher } from "./ProcessEventsSaga";
import { fetchPointHistoriesWatcher } from "./FetchPointHistoriesSaga";
import { claimPartnerBonusWatcher } from "./ClaimPartnerBonusSaga";

export function* pointsSagas() {
	yield all([
		fetchEventsWatcher(),
		processEventsWatcher(),
		fetchPointHistoriesWatcher(),
		claimPartnerBonusWatcher(),
	]);
}
