import { call, put, takeLatest } from "redux-saga/effects";
import { FETCH_LIQUIPEDIA_KEYWORDS } from "../../constants/ActionTypes";
import { GET_LIQUIPEDIA_KEYWORDS } from "../../constants/Url";
import { setLiquipediaKeywords } from "../../actions/Liquipedia";

import api from "../../util/api";

export function* fetchKeywordsWatcher() {
	yield takeLatest(FETCH_LIQUIPEDIA_KEYWORDS, fetchKeywordsWorker);
}

function* fetchKeywordsWorker() {
	try {
		const response = yield call(fetchKeywordsApi);
		const isSuccess = response.status >= 200 && response.status < 300;
		if (isSuccess) {
			yield put(setLiquipediaKeywords(response.data.data));
		}
	} catch (err) {
		console.error(err);
	}
}

async function fetchKeywordsApi() {
	try {
		return await api.get(GET_LIQUIPEDIA_KEYWORDS);
	} catch (error) {
		return error.response;
	}
}
