import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { setCampaignsBank } from "../../actions/Campaigns";

// Utils
import api from "../../util/api";
import { isThrottled, setThrottledTimespan } from "../../util/SagaUtils";

// Constants
import { FETCH_PUBLIC_CAMPAIGNS_WATCHER } from "../../constants/ActionTypes";
import { PUBLIC_FETCH_CAMPAIGNS_URL } from "../../constants/Url";
import { ENDPOINT_CACHING_TIMESPANS } from "../../constants/CachingTimespans";

export function* fetchPublicCampaignsWatcher() {
	yield takeLatest(FETCH_PUBLIC_CAMPAIGNS_WATCHER, fetchPublicCampaignsWorker);
}

function* fetchPublicCampaignsWorker(action) {
	const endpointIsThrottled = yield isThrottled(PUBLIC_FETCH_CAMPAIGNS_URL);
	if (!endpointIsThrottled) {
		try {
			const response = yield call(fetchPublicCampaignsApi, action.payload);
			const isSuccess = response.status >= 200 && response.status < 300;
			const options = isSuccess ? action?.options?.success : action?.options?.failure;

			if (isSuccess) {
				yield put(
					setCampaignsBank({
						bank: response.data.data,
						totalCount: response.data.totalCount,
					})
				);
				yield setThrottledTimespan(
					PUBLIC_FETCH_CAMPAIGNS_URL,
					ENDPOINT_CACHING_TIMESPANS.FETCH_PUBLIC_CAMPAIGNS
				);
			}
			if (options?.callback) {
				yield call(options.callback, options.callbackArgs);
			}
		} catch (e) {
			console.error(e);
		}
	}
}

function fetchPublicCampaignsApi(payload) {
	return api.get(PUBLIC_FETCH_CAMPAIGNS_URL, {
		params: payload,
	});
}
