import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Actions
import { showLoader, hideLoader, unsetRights, unsetUserInfo } from "../../actions/GlobalAction";
import { logoutUser } from "../../actions/User";

// Components
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ACCOUNT_INFORMATION } from "../../constants/RoutesURLs";

class UserInfo extends Component {
	constructor(props) {
		super(props);

		this.state = {
			anchorEl: null,
			open: false,
		};
	}

	handleClick = (event) => {
		this.setState({ open: true, anchorEl: event.currentTarget });
	};

	handleRequestClose = () => {
		this.setState({ open: false });
	};

	handleRedirectionToPublicUI = () => {
		this.handleRequestClose();
		window.location.assign("/");
	};

	handleRedirectionToAccount = () => {
		this.handleRequestClose();
		window.location.assign(ACCOUNT_INFORMATION);
	};

	render() {
		const { logoutUser, user } = this.props;
		const { anchorEl, open } = this.state;

		return (
			<div className="user-profile d-flex flex-row align-items-center">
				<Avatar
					alt="..."
					src={user.userInfo ? user.userInfo.avatar.large_icon_url : ""}
					className="user-avatar"
				/>
				<div className="user-detail">
					<span className="user-name" onClick={this.handleClick}>
						{user.userInfo && user.userInfo.username}
						<i className="zmdi zmdi-chevron-down zmdi-hc-fw align-middle" />
					</span>
				</div>
				<Menu
					className="user-info"
					id="simple-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={this.handleRequestClose}
					PaperProps={{
						style: {
							width: 240,
							paddingTop: 0,
							paddingBottom: 0,
							marginLeft: "-60px",
							marginTop: "3rem",
						},
					}}
				>
					<MenuItem onClick={this.handleRedirectionToPublicUI}>
						Back to Team Liquid
					</MenuItem>
					<MenuItem onClick={this.handleRedirectionToAccount}>My Account</MenuItem>
					<MenuItem onClick={() => logoutUser()}>Sign Out</MenuItem>
				</Menu>
			</div>
		);
	}
}

UserInfo.propTypes = {
	logoutUser: PropTypes.func,
	user: PropTypes.shape({
		userInfo: PropTypes.shape({
			avatar: PropTypes.shape({
				large_icon_url: PropTypes.string,
			}),
			username: PropTypes.string,
		}),
	}),
};

const mapDispatchToProps = (dispatch) => ({
	showLoader: () => dispatch(showLoader()),
	hideLoader: () => dispatch(hideLoader()),
	unsetRights: () => dispatch(unsetRights()),
	unsetUserInfo: () => dispatch(unsetUserInfo()),
	logoutUser: () => dispatch(logoutUser()),
});

const mapStateToProps = (state) => ({
	locale: state.settings.locale,
	loader: state.global.loader,
	user: state.user.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfo);
