import {all} from 'redux-saga/effects';

import {
    fetchRedditDataWatcher
} from './FetchRedditSagas'
import {
    createRedditDataWatcher
} from './CreateRedditChannelSagas'
import {
    removeRedditDataWatcher
} from './RemoveRedditChannelSagas'

export function *redditSagas() {
    yield all([
        fetchRedditDataWatcher(),
        createRedditDataWatcher(),
        removeRedditDataWatcher()
    ]);
}