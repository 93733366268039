import React from "react";
import propTypes from "prop-types";
import "./styles.scss";

import GameEntitiesInput from "../../../../../components/EsportsEntitiesInputs/GameEntitiesInput";
import PlayerEntitiesInput from "../../../../../components/EsportsEntitiesInputs/PlayerEntitiesInput";
import { FormFieldInput } from "../../../../../components/FormFields/FormFields";

const TwitterDialogContent = ({
	type,
	identifier,
	entities,
	changeUrl,
	changeEntity,
	removeEntity,
}) => {
	const capitalizeFirstLetter = (string) =>
		string?.charAt(0).toUpperCase() + string?.slice(1);
	return (
		<div className="admin">
			<FormFieldInput
				intro={`Enter the URL for this ${type}.`}
				label={capitalizeFirstLetter(type)}
				type="text"
				name="identifier"
				value={identifier}
				onChange={changeUrl}
			/>
			<PlayerEntitiesInput
				intro="Enter the players or games that relate to this channel."
				label="Player(s)"
				entities={entities}
				onChange={changeEntity}
				onRemove={removeEntity}
			/>
			<GameEntitiesInput
				label="Game(s)"
				entities={entities}
				onChange={changeEntity}
			/>
		</div>
	);
};

TwitterDialogContent.propTypes = {
	identifier: propTypes.string.isRequired,
	type: propTypes.string.isRequired,
	open: propTypes.bool.isRequired,
	entities: propTypes.array.isRequired,
	handleSubmit: propTypes.func.isRequired,
	handleClose: propTypes.func.isRequired,
	changeUrl: propTypes.func.isRequired,
	changeEntity: propTypes.func.isRequired,
	removeEntity: propTypes.func.isRequired,
};

export default TwitterDialogContent;
