import { setProfileOverview } from "../../actions/User";

import { takeLatest, call, put } from "redux-saga/effects";

import { GET_PROFILE_OVERVIEW } from "../../constants/ActionTypes";
import { GET_PUBLIC_PROFILE_URL } from "../../constants/Url";
import { push } from "react-router-redux";

import api from "../../util/api";

function fetchUserProfileApi(shareId) {
	return api.get(GET_PUBLIC_PROFILE_URL(shareId));
}

export function* fetchUserProfileWatcher() {
	yield takeLatest(GET_PROFILE_OVERVIEW, fetchUserProfileWorker);
}

function* fetchUserProfileWorker(action) {
	try {
		// data is obtained after axios call is resolved
		let response = yield call(fetchUserProfileApi, action.payload);
		if (response.status >= 200 && response.status < 300) {
			yield put(setProfileOverview(response.data.data));
		} else {
			throw response;
		}
	} catch (e) {
		console.log(e);
		yield put(push("/error-404"));
	}
}
