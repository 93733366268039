import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { setRumStatus } from "../../actions/RumStatus";

// Constants
import { RUM_STATUS_WATCHER } from "../../constants/ActionTypes";
import { RUM_STATUS } from "../../constants/Url";

// Utils
import api from "../../util/api";

function rumStatusApi() {
	return api.get(RUM_STATUS);
}

export function* rumStatusWatcher() {
	yield takeLatest(RUM_STATUS_WATCHER, rumStatusWorker);
}

function* rumStatusWorker(action) {
	try {
		const response = yield call(rumStatusApi);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (isSuccess) {
			yield put(setRumStatus(response.data.status));
			if (options?.callback) yield call(options.callback);
		} else {
			if (options?.callback) yield call(options.callback);
			throw response;
		}
	} catch (e) {
		console.log(e);
	}
}
