import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";

import { DELETE_USER } from "../../constants/ActionTypes";
import api from "../../util/api";
import { DELETE_USER as DELETE_USER_URL } from "../../constants/Url";
import { appendNotifications } from "../../actions/Notifications";
import { SUCCESS, ERROR } from "../../constants/Notifications";

export default function* deleteUserWatcher() {
	yield takeLatest(DELETE_USER, deleteUserWorker);
}

/**
 * @param {String} action.params.id The ID of user to be deleted.
 * @param {Boolean} action.params.noArchieve Type of deletion.
 *
 * @param {Object} [action.options] Adds additional behavior of the action on success/failure including redirects, notifications.
 *
 * @param {Object} [action.options.success] Behavior to execute when API call and action is successful.
 * @param {String} [action.options.success.redirect] Redirect to this URL on success.
 * @param {String} [action.options.success.notification] Display notification with passed text on success.
 *
 * @param {Object} [action.options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [action.options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [action.options.failure.notification] Display notification with passed text on failure.
 */
function* deleteUserWorker(action) {
	try {
		const response = yield call(deleteUserAPI, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;

		const options = isSuccess
			? action?.options?.success
			: action?.options?.failure;

		if (options.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}

		if (options.redirect) {
			yield put(push(options.redirect));
		}
	} catch (error) {
		yield put(
			appendNotifications({
				type: ERROR,
				message: "Whoops! Something went wrong.",
			})
		);
	}
}

/**
 * Sends an API request to delete user.
 * @param {String} params.id The ID of user to be deleted.
 * @param {String} params.noArchieve Type of deletion.
 * @return {Object} The response object from the API call.
 */
function deleteUserAPI(params) {
	return api.delete(DELETE_USER_URL(params.id), {
		data: {
			no_archive: params.noArchive,
		},
	});
}
