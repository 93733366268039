import api from "../util/api";
import { takeLatest, call, put } from "redux-saga/effects";
import { setResetPasswordStatus } from "../actions/User";
import { addMessage } from "../actions/Messages";
import { SEND_RESET_PASSWORD } from "../constants/ActionTypes";
import { RESET_PASSWORD_URL } from "../constants/Url";
import { appendNotifications } from "../actions/Notifications";
import { PASSWORD_UPDATE, ERROR, SUCCESS } from "../constants/Notifications";

function sendResetPasswordApi(payload) {
	return api
		.put(RESET_PASSWORD_URL, {
			email: payload.email,
			token: payload.token,
			password: payload.password,
			confirmPassword: payload.confirmPassword,
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}

export function* resetPasswordWatcher() {
	yield takeLatest(SEND_RESET_PASSWORD, resetPasswordWorker);
}

function* resetPasswordWorker(action) {
	try {
		// data is obtained after axios call is resolved
		let response = yield call(sendResetPasswordApi, action.payload);

		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (isSuccess) {
			yield put(setResetPasswordStatus(true));
			yield put(appendNotifications(PASSWORD_UPDATE(SUCCESS)));
		} else if (response.status === 412) {
			yield put(
				addMessage({
					source: SEND_RESET_PASSWORD,
					message: response.msg || "Unknown error.",
					isError: true,
				})
			);
		} else {
			yield put(setResetPasswordStatus(false));
			yield put(appendNotifications(PASSWORD_UPDATE(ERROR)));
		}

		if (options?.callback) {
			yield call(options.callback, !isSuccess ? response?.data?.error : undefined);
		}
	} catch (e) {
		console.error(e);
		yield put(appendNotifications(PASSWORD_UPDATE(ERROR)));
	}
}
