import jwt_decode from "jwt-decode";

import { REQUEST_ACCESS_TOKEN_URL } from "../constants/Url";

import { THRESHOLD_SECONDS } from "../constants/Time";
import { EMAIL_VERIFICATION, SIGNIN } from "../constants/RoutesURLs";

import Mutex from "./Mutex";

const Axios = require("axios");
const Locker = new Mutex();

async function refreshAccessToken(refreshToken) {
	const res = await Axios.post(REQUEST_ACCESS_TOKEN_URL, { refreshToken });
	if (res?.data?.data?.accessToken && res.data.data.refreshToken) {
		window.browserStorage.storeJWT("Bearer " + res.data.data.accessToken);
		window.browserStorage.storeRefreshToken(res.data.data.refreshToken);
	} else if (res?.data?.data?.tokenExpired) {
		if (
			window.location.pathname !== SIGNIN &&
			window.location.pathname !== EMAIL_VERIFICATION
		) {
			LogoutProcess();
		}
	}
}

async function sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

function compareExpirationTime(decoded_jwt) {
	let current = new Date();
	let exp = new Date(decoded_jwt.exp * 1000);

	exp.setSeconds(exp.getSeconds() - THRESHOLD_SECONDS);

	if (Date.parse(exp) > Date.parse(current)) return false;
	else return true;
}

function LogoutProcess() {
	window.browserStorage.setRecentPath("recent_path", window.location.pathname);
	window.browserStorage.removeJWT();
	window.browserStorage.remove("refresh_token");
	window.browserStorage.removePermission();
	window.location.href = SIGNIN;
}

export default async function processAccessToken() {
	while (Locker.isLocked()) {
		await sleep(100);
	}
	if (window.browserStorage.getJWT()) {
		try {
			const refToken = window.browserStorage.get("refresh_token");
			const decode = jwt_decode(window.browserStorage.getJWT());
			const isExpired = compareExpirationTime(decode);

			if (isExpired) {
				Locker.lock();
				await refreshAccessToken(refToken);
			}

			Locker.releaseLock();
			return window.browserStorage.getJWT();
		} catch (e) {
			Locker.releaseLock();
			console.error(e);
		}
	}
}
