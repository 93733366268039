import { FETCH_COUNTRIES_WATCHER, SET_COUNTRIES } from "../constants/ActionTypes";

export const fetchCountries = () => {
	return {
		type: FETCH_COUNTRIES_WATCHER
	};
};

export const setCountries = (payload) => {
	return {
		type: SET_COUNTRIES,
		payload,
	};
};
