import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { isMobile } from "react-device-detect";
import Select from "react-dropdown-select";
import classnames from "classnames";

/* Example Usage:
    <SelectField
        label="Gender"
        options={GENDER_OPTIONS}
        clearable={this.state.topic?.length > 0}
        values={this.state.gender}
        onChange={(value) => this.setState({ gender: value })}
    />
*/

class SelectField extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			focus: props.autoFocus ?? false,
			hover: false,
			opened: false,
		};
	}

	componentDidMount() {
		const { autoFocus } = this.props;
		if (autoFocus === true && this.selectRef !== undefined) {
			this.selectRef.getSelectRef().focus();
		}
	}

	blur = () => {
		const { onBlur } = this.props;

		this.setState({ focus: false }, () => {
			if (onBlur) {
				onBlur();
			}
		});
	};

	focus = () => {
		const { onFocus } = this.props;

		this.setState({ focus: true }, () => {
			if (onFocus) {
				onFocus();
			}
		});
	};

	options() {
		let { options } = this.props;
		var processed = [];

		if (options && options[0].label && options[0].value) {
			return options;
		}

		for (let index in options) {
			processed.push({ label: options[index], value: options[index] });
		}

		return processed;
	}

	onMouseEnter = () => this.setState({ hover: true });

	onMouseLeave = () => this.setState({ hover: false });

	onDropdownOpen = () => this.setState({ opened: true });

	onDropdownClose = () => this.setState({ opened: false, hover: false });

	render() {
		const {
			label,
			id,
			className,
			fieldId,
			fieldClassName,
			searchable,
			clearable,
			noDataRenderer,
		} = this.props;
		const { focus, hover, opened } = this.state;

		return (
			<div id={id} className={classnames("SelectField", "container-fluid", className)}>
				{label && (
					<label htmlFor={fieldId} className={classnames("label", { focus })}>
						{label}
					</label>
				)}

				<div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
					<Select
						placeholder=""
						{...this.props}
						id={fieldId}
						ref={(el) => (this.selectRef = el)}
						className={classnames(
							"field",
							{
								"select-field--mobile": isMobile,
								"not-searchable": !searchable,
								"option-selected": clearable,
								clearable: clearable && hover,
								"not-clearable": !(clearable && hover),
								opened,
							},
							fieldClassName
						)}
						onFocus={this.focus}
						onBlur={this.blur}
						options={this.options()}
						onDropdownOpen={this.onDropdownOpen}
						onDropdownClose={this.onDropdownClose}
						{...(noDataRenderer && { noDataRenderer })}
					/>
				</div>
			</div>
		);
	}
}

SelectField.propTypes = {
	options: PropTypes.array.isRequired,
	id: PropTypes.string,
	label: PropTypes.string,
	className: PropTypes.string,
	fieldId: PropTypes.string,
	fieldClassName: PropTypes.string,
	searchable: PropTypes.bool,
	clearable: PropTypes.bool,
	autoFocus: PropTypes.bool,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	noDataRenderer: PropTypes.func,
};

export default SelectField;
