import React from "react";
import { connect } from "react-redux";
import Modal from "../../../../components/Modal";
import TextField from "../../../../components/TextField";
import { updateUserEmail } from "../../../../actions/UserUpdate";
import { UPDATE_USER_EMAIL } from "../../../../constants/ActionTypes";
import { fetchUserAccountInfo } from "../../../../actions/UserAdminPanel";
import { clearMessages } from "../../../../actions/Messages";

class EmailDialog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: props.email,
			error: "",
			show_modal: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			email: nextProps.email,
		});
	}

	renderError() {
		if (this.state.error) {
			return (
				<div className="error">
					<img
						className="error_image"
						src={require("../../../../svg/alert.svg")}
					/>
					<div className="error_message"></div>
					{this.state.error}
				</div>
			);
		}
	}

	updateEmail(email) {
		const {
			updateUserEmail,
			userId,
			fetchUserAccountInfo,
			errorMessage,
		} = this.props;

		updateUserEmail(
			{
				user: userId,
				email,
			},
			{
				success: {
					callback: () => {
						fetchUserAccountInfo(userId);
						this.setState({ show_modal: false });
					},
				},
				failure: {
					message: true,
					callback: () => {
						this.setState({
							error: errorMessage,
						});
					},
				},
			}
		);
	}

	handleSubmit = () => {
		this.props.clearMessages(UPDATE_USER_EMAIL);
		this.updateEmail(this.state.email);
	};

	handleOpen = () => {
		this.setState({ show_modal: true });
	};

	handleClose = () => {
		this.setState({
			show_modal: false,
			error: "",
		});
		this.props.clearMessages(UPDATE_USER_EMAIL);
	};

	onChange = (e) => this.setState({ email: e.target.value });

	render() {
		return (
			<Modal
				form="email-form"
				className="email-modal"
				show={this.state.show_modal}
				onTrigger={this.handleOpen}
				triggerBtnText="Change"
				triggerBtnClassName="button"
				cancelBtnText="Cancel"
				submitBtnText="Save"
				submitDisabled={this.state.email === this.props.email}
				onSubmit={this.handleSubmit}
				onClose={this.handleClose}
			>
				<TextField
					type="email"
					label="Email"
					value={this.state.email}
					onChange={this.onChange}
				/>
				{this.renderError()}
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		errorMessage: state.messages.bank.find(
			(message) => message.source === UPDATE_USER_EMAIL
		)?.message,
	};
};

const mapDispatchToProps = {
	updateUserEmail,
	clearMessages,
	fetchUserAccountInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailDialog);
