import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

import { DISCORD_DELETE_DIALOG_TEXT } from "../../../../../constants/Label";
import Modal from "../../../../../components/Modal/Modal";

function DeleteDialog({ handleDelete }) {
	return (
		<Modal
			className="admin delete-discord-bot-dialog"
			triggerBtnText="Delete"
			triggerBtnClassName="button"
			cancelBtnText="Cancel"
			submitBtnText="Delete"
			onSubmit={handleDelete}
		>
			<p>{DISCORD_DELETE_DIALOG_TEXT}</p>
		</Modal>
	);
}

DeleteDialog.propTypes = {
	handleDelete: PropTypes.func.isRequired,
};

export default DeleteDialog;
