import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./styles/styles.scss";

// Components
import Modal from "../Modal";

const LpStagingDialog = ({
	className,
	stagedHeading,
	stagingHeading,
	stagedContent,
	stagingContent,
	saveButtonForm,
	onSave,
	disableSave,
	cancelButtonText,
	onCancel,
	disableCancel,
	onRemove,
	disableRemove,
	onClose,
	dark,
	triggerButtonText,
	triggerButtonClassName,
	showWarning,
	warningText,
}) => {
	const [showDialog, toggleDialog] = useState(false);
	const [showWarningDialog, toggleWarningDialog] = useState(false);

	function handleClose() {
		if (showWarning) {
			toggleWarningDialog(true);
		} else {
			toggleDialog(false);
			if (onClose) {
				onClose();
			}
		}
	}

	return (
		<Modal
			dark={dark}
			show={showDialog}
			className={classnames("admin", "lp_staging_dialog", className, {
				"lp_staging_dialog--theme-light": !dark,
			})}
			triggerBtnText={triggerButtonText}
			triggerBtnClassName={classnames("button", triggerButtonClassName)}
			cancelBtnText="Close"
			onTrigger={() => toggleDialog(true)}
			onClose={handleClose}
		>
			<div className="staged">
				<h3 className="staged__heading">{stagedHeading}</h3>
				<div className="staged__content">{stagedContent}</div>
				<div className="staged__controls">
					<button
						type="button"
						className="button"
						onClick={onRemove}
						disabled={disableRemove}
					>
						Remove
					</button>
				</div>
			</div>
			<div className="staging">
				<h3 className="staging__heading">{stagingHeading}</h3>
				<div className="staging__content">
					{stagingContent}
					<Modal
						className="lp_staging_dialog__warning"
						show={showWarningDialog}
						cancelBtnText="Cancel"
						onClose={() => toggleWarningDialog(false)}
						submitBtnText="Close"
						onSubmit={() => {
							toggleWarningDialog(false);
							toggleDialog(false);
							if (onClose) {
								onClose();
							}
						}}
					>
						{warningText}
					</Modal>
				</div>
				<div className="staging__controls">
					{cancelButtonText ? (
						<button
							type="button"
							className={"button"}
							onClick={onCancel}
							disabled={disableCancel}
						>
							{cancelButtonText}
						</button>
					) : null}
					<button
						type="submit"
						form={saveButtonForm}
						className={classnames(
							"button",
							dark ? "button--colored" : "button--colored-admin"
						)}
						onClick={onSave}
						disabled={disableSave}
					>
						Save
					</button>
				</div>
			</div>
		</Modal>
	);
};

LpStagingDialog.propTypes = {
	className: PropTypes.string,
	stagedHeading: PropTypes.string,
	stagingHeading: PropTypes.string,
	stagedContent: PropTypes.node,
	stagingContent: PropTypes.node,
	saveButtonForm: PropTypes.string,
	onSave: PropTypes.func,
	disableSave: PropTypes.bool,
	cancelButtonText: PropTypes.string.isRequired,
	onCancel: PropTypes.func,
	disableCancel: PropTypes.bool,
	onRemove: PropTypes.func,
	disableRemove: PropTypes.bool,
	onClose: PropTypes.func,
	dark: PropTypes.bool,
	show: PropTypes.bool,
	onTrigger: PropTypes.func,
	triggerButtonText: PropTypes.string,
	triggerButtonClassName: PropTypes.string,
	warningText: PropTypes.string,
	showWarning: PropTypes.bool,
};

export default LpStagingDialog;
