import { all } from "redux-saga/effects";

import { fetchUserParamWatcher } from "./FetchUserParamSagas";
import { userLogoutWatcher } from "./LogoutSagas";
import { verifyUserWatcher } from "./VerifyUserSaga";
import { generateTokenWatcher } from "./AutoLoginSaga";
import { userUpdateWatcher } from "./UpdateSagas";
import { userUpdateWithoutFetchWatcher } from "./UpdateWithoutFetchSagas";
import { fetchReferralLinkWatcher } from "./FetchReferralLink";
import { changeLanguageWatcher } from "./ChangeLanguageSaga";

import { RegisterUserWatcher } from "./RegistrationSagas";
import { ResendRegistrationEmailWatcher } from "./ResendRegistrationEmailSaga";
import { signupNotifyWatcher } from "./SignupNotifySagas";
import { fetchUserAvatarsWatcher } from "./UserAvatarSagas";
import {
	fetchUserLifetimePointsWatcher,
	fetchUserMembershipPointsWatcher,
	fetchUserSpendingPointsWatcher,
	fetchUserRankUpProgressWatcher,
} from "./PointsSagas";
import { checkDuplicateUsernameWatcher } from "./FindDuplicateNameSaga";
import { sendHelpRequestWatcher } from "./MailerSagas";
import { fetchUserProfileWatcher } from "./FetchUserProfileSaga";
import { requestDeleteAccountWatcher, deleteAccountWatcher } from "./DeleteUserAccountSagas";

import { passwordAuthenticationWatcher } from "./PasswordAuthenticationSaga";

import { emailChangeWatcher } from "./EmailChangeSaga";
import { fetchUsernamesAndAvatarsWatcher } from "./UsernamesAndAvatarsSagas";

export function* userSagas() {
	yield all([
		fetchUserParamWatcher(),
		userLogoutWatcher(),
		verifyUserWatcher(),
		generateTokenWatcher(),
		userUpdateWatcher(),
		userUpdateWithoutFetchWatcher(),
		fetchReferralLinkWatcher(),
		RegisterUserWatcher(),
		ResendRegistrationEmailWatcher(),
		signupNotifyWatcher(),
		fetchUserAvatarsWatcher(),
		fetchUserLifetimePointsWatcher(),
		fetchUserMembershipPointsWatcher(),
		fetchUserSpendingPointsWatcher(),
		fetchUserRankUpProgressWatcher(),
		checkDuplicateUsernameWatcher(),
		sendHelpRequestWatcher(),
		fetchUserProfileWatcher(),
		requestDeleteAccountWatcher(),
		deleteAccountWatcher(),
		passwordAuthenticationWatcher(),
		emailChangeWatcher(),
		fetchUsernamesAndAvatarsWatcher(),
		changeLanguageWatcher(),
	]);
}
