import {
    setPointsProcessingEvents
} from '../../actions/AdminPanel';

import {
    showLoader,
    hideLoader
} from '../../actions/GlobalAction'
import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';

import {
    FETCH_POINTS_PROCESSING_EVENTS_WATCHER
} from '../../constants/ActionTypes'
import {
    LOYALTY_PROCESS_EVENTS
} from '../../constants/Url'

import api from '../../util/api';

function fetchEventsApi() {
    return (
        api
        .get(LOYALTY_PROCESS_EVENTS)

    )
}

export function* fetchEventsWatcher() {
    yield takeLatest(FETCH_POINTS_PROCESSING_EVENTS_WATCHER, fetchEventsWorker);
}

function* fetchEventsWorker(action) {
    try {
        yield put(showLoader());
        // data is obtained after axios call is resolved
        let response = yield call(fetchEventsApi);
        if (response.status >= 200 && response.status < 300) {
            yield put(setPointsProcessingEvents(response.data.data))
        } else {
            throw (response)
        }
        yield put(hideLoader());
    } catch (e) {
        console.log(e)
        yield put(hideLoader());
    }
}