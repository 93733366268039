import React, { Component } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import "./styles.scss";
import classnames from "classnames";
import { Paper } from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TwitterCreateDialog from "./TwitterDialogs/TwitterCreateDialog";
import TwitterChannel from "./TwitterChannel";
import LpHeading from "../../../../components/LpHeading";
import { NO_TWITTER_CHANNEL_TO_SHOW } from "../../../../constants/Label";

import {
	fetchTwitterData,
	createTwitterServer,
} from "../../../../actions/Channel";

const typePrefixes = {
	hashtag: "#",
	account: "@",
};
const rowsPerPage = 24;
class TwitterChannels extends Component {
	state = {
		page: 0,
		hideContext: true,
	};

	componentDidMount() {
		this.fetchChannels();
	}

	backToChannels = () => {
		this.props.history.push("/app/servicesettings/channels");
	};

	fetchChannels = (skip, limit) => {
		this.props.fetchTwitterData(
			{
				skip: skip,
				limit: limit,
			},
			{
				success: {
					setTwitterData: true,
				},
				failure: {
					notification: "Failed to retrieve Twitter channels.",
				},
			}
		);
	};

	createNewChannel = (params) => {
		this.props.createTwitterServer(
			{
				...params,
			},
			{
				success: {
					notification: "Added Twitter channel.",
					fetchTwitterData: true,
				},
				failure: {
					notification: "Failed to add Twitter channel.",
				},
			}
		);
	};

	handleChangePage = (event, newPage) => {
		this.setState({ page: newPage });
	};

	getSortedChannels = () => {
		const channels = [...this.props.channels];
		return channels.sort((a, b) => {
			const name_a = a.identifier.toLowerCase();
			const name_b = b.identifier.toLowerCase();
			return name_a < name_b ? -1 : name_b > name_a ? 1 : 0;
		});
	};

	render() {
		const channels = this.getSortedChannels();
		const { page } = this.state;
		return (
			<div className="channel">
				<div className="options-header">
					<div className="options options--left">
						<button
							className="button button--borderless"
							onClick={this.backToChannels}
						>
							<span>
								<i className="zmdi zmdi-chevron-left zmdi-hc-lg pr-1" />
								&nbsp;Channels
							</span>
						</button>
					</div>
					<div className="options options--right">
						<button
							className="button"
							onClick={() =>
								this.setState({
									hideContext: !this.state.hideContext,
								})
							}
						>
							Add New
						</button>
						<Paper
							className={classnames("context", {
								"context--hidden": this.state.hideContext,
							})}
							onClick={() =>
								this.setState({
									hideContext: true,
								})
							}
						>
							<TwitterCreateDialog
								triggerBtnText="Profile"
								type="account"
								handleSubmit={this.createNewChannel}
							/>
							<TwitterCreateDialog
								triggerBtnText="Hashtag"
								type="hashtag"
								handleSubmit={this.createNewChannel}
							/>
						</Paper>
					</div>
				</div>
				<div className="twitter-container">
					<div className="twitter-container__title">
						<LpHeading
							type="h2"
							variant="section"
							className="twitter-container__heading"
						>
							Twitter
						</LpHeading>
					</div>
					<TablePagination
						component="div"
						count={this.props.channels.length}
						rowsPerPageOptions={rowsPerPage}
						rowsPerPage={rowsPerPage}
						page={this.state.page}
						onChangePage={this.handleChangePage}
					/>
					<div className="twitter-container__list">
						<Table>
							<TableBody>
								{channels?.length ? (
									(rowsPerPage > 0
										? channels.slice(
												page * rowsPerPage,
												page * rowsPerPage + rowsPerPage
										  )
										: channels
									).map((item) => (
										<TwitterChannel
											typePrefixes={typePrefixes}
											item={item}
										/>
									))
								) : (
									<TableRow className="twitter-container__item">
										<TableCell className="twitter-container__no-values">
											{NO_TWITTER_CHANNEL_TO_SHOW}
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</div>
					<TablePagination
						component="div"
						count={this.props.channels.length}
						rowsPerPageOptions={rowsPerPage}
						rowsPerPage={rowsPerPage}
						page={this.state.page}
						onChangePage={this.handleChangePage}
					/>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = {
	fetchTwitterData,
	createTwitterServer,
};

const mapStateToProps = ({ channel }) => {
	return {
		channels: channel?.twitterData?.identifiers || [],
	};
};

TwitterChannels.propTypes = {
	channels: propTypes.arrayOf({}).isRequired,
	history: propTypes.object.isRequired,
	fetchTwitterData: propTypes.func.isRequired,
	createTwitterServer: propTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(TwitterChannels);
