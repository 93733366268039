import { setUserProfileParam } from "../../actions/User";

import { showLoader, hideLoader } from "../../actions/GlobalAction";

import { takeLatest, call, put, select } from "redux-saga/effects";

import { FETCH_USER_PROFILE_PARAM_WATCHER } from "../../constants/ActionTypes";
import { USERS_SERVICE_API } from "../../constants/Url";

import api from "../../util/api";

const fetchUserProfile = (state) => state.user.profile;

function fetchUserParamApi(arg, user) {
	return api.get(USERS_SERVICE_API + `/user/${user.id}`);
}

export function* fetchUserParamWatcher() {
	yield takeLatest(FETCH_USER_PROFILE_PARAM_WATCHER, fetchUserParamWorker);
}

function* fetchUserParamWorker(action) {
	try {
		yield put(showLoader());
		// data is obtained after axios call is resolved
		let user = yield select(fetchUserProfile);
		let response = yield call(fetchUserParamApi, action.payload, user);
		if (response.status >= 200 && response.status < 300) {
			yield put(setUserProfileParam("userInfo.gender", response.data.data.userInfo.gender));
		} else {
			throw response;
		}

		yield put(hideLoader());
	} catch (e) {
		console.log(e);
		yield put(hideLoader());
	}
}
