import {all} from 'redux-saga/effects';

import {
    fetchDiscordChannelCountWatcher
} from './FetchDiscordCountSagas'
import {
    fetchTwitchChannelCountWatcher
} from './FetchTwitchCountSagas';
import {
    fetchShopifyChannelCountWatcher
} from './FetchShopifyCountSagas';
import {
    fetchTwitterChannelCountWatcher
} from './FetchTwitterCountSagas';
import {
    fetchRedditChannelCountWatcher
} from './FetchRedditCountSagas';
export function *channelCountSagas() {
    yield all([
        fetchDiscordChannelCountWatcher(),
        fetchTwitchChannelCountWatcher(),
        fetchShopifyChannelCountWatcher(),
        fetchTwitterChannelCountWatcher(),
        fetchRedditChannelCountWatcher()
    ]);
}