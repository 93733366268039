import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { isIOS, isMobile } from "react-device-detect";

import { COLLAPSED_DRAWER, FIXED_DRAWER } from "../constants/ActionTypes";

import {
	ENTITIES,
	GAMES,
	PLAYERS,
	ADMIN_QUESTS,
	ADMIN_QUESTS_NEW,
	ADMIN_QUESTS_QUEST,
	ADMIN_QUESTS_QUEST_TRANSLATIONS,
	ADMIN_EXPERIENCES,
	ADMIN_EXPERIENCES_NEW,
	ADMIN_EXPERIENCES_EXPERIENCE,
	ADMIN_EXPERIENCES_EXPERIENCE_TRANSLATIONS,
} from "../constants/RoutesURLs";

import asyncComponent from "../util/asyncComponent";
import Sidebar from "../containers/SideNav/index";
// import { isAllowed } from "../auth/Authorizer";
import Interactions from "./routes/Interactions/Interactions";
import Channels from "./routes/channels/Channels";
import DiscordChannels from "./routes/channels/discord/DiscordChannels";
import TwitchChannels from "./routes/channels/twitch/TwitchChannels";
import TwitterChannels from "./routes/channels/twitter/TwitterChannels";
import LiquidStore from "./routes/channels/liquidStore/LiquidStore";
import events from "./routes/events/Events";
import EventCreate from "./routes/events/EventCreate";
import EventView from "./routes/events/EventView";
import EventQrCode from "./routes/events/EventQrCode";
import AchievementView from "./routes/achievements/AchievementView";
import Staff from "./routes/users/Staff";
import PointsHistory from "./routes/logs_menu/Points";
import Bans from "./routes/logs_menu/Bans";
import UserSearch from "./routes/users/Users";
import UserDetail from "./routes/users/UserDetail";
import PointsProcessing from "./routes/pointProcessing";
import Orders from "./routes/orders/Orders";
// import Hashtags from "./routes/hashtags";
import OrderDetails from "./routes/orders/OrderDetails";
import Rewards from "./routes/reward/Rewards";
import Permissions from "./routes/Permissions";
import RewardsDetails from "./routes/reward/RewardsDetails";
import Tiers from "./routes/tiers/Tiers";
import Error404 from "../containers/pages/errors/Error404";
import LiquipediaTeamKeywords from "./routes/Liquipedia/TeamKeywords";

function AdminApp({ match, drawerType, location }) {
	const drawerStyle = drawerType.includes(FIXED_DRAWER)
		? "fixed-drawer"
		: drawerType.includes(COLLAPSED_DRAWER)
		? "collapsible-drawer"
		: "mini-drawer";

	//set default height and overflow for iOS mobile Safari 10+ support.
	if (isIOS && isMobile) {
		document.body.classList.add("ios-mobile-view-height");
	} else if (document.body.classList.contains("ios-mobile-view-height")) {
		document.body.classList.remove("ios-mobile-view-height");
	}

	return (
		<div className={`app-container ${drawerStyle}`}>
			<Sidebar />
			<div className="app-main-container">
				<main className="app-main-content-wrapper">
					<div className="app-main-content">
						<Switch>
							<Route
								path={`${match.url}/home`}
								component={asyncComponent(() => import("./routes/home"))}
							/>
							<Route
								path={`${match.url}/admin`}
								component={asyncComponent(() => import("./routes/admin"))}
							/>
							<Route
								path={`${match.url}/moderator`}
								component={asyncComponent(() =>
									import("./routes/moderator/Moderator")
								)}
							/>

							{/* Content (It was Service Settings) */}

							<Route
								exact
								path={`${match.url}/servicesettings/rewards/:id`}
								component={RewardsDetails}
							/>

							<Route
								exact
								path={`/app/servicesettings/rewards`}
								component={Rewards}
							/>

							<Route
								exact
								path={ADMIN_EXPERIENCES}
								component={asyncComponent(() =>
									import("./routes/campaigns/Campaigns")
								)}
							/>

							<Route
								exact
								path={ADMIN_EXPERIENCES_NEW}
								component={asyncComponent(() =>
									import("./routes/campaigns/CampaignCreate")
								)}
							/>

							<Route
								path={ADMIN_EXPERIENCES_EXPERIENCE_TRANSLATIONS}
								component={asyncComponent(() =>
									import("./routes/campaigns/CampaignView/Translations")
								)}
							/>

							<Route
								path={ADMIN_EXPERIENCES_EXPERIENCE}
								component={asyncComponent(() =>
									import("./routes/campaigns/CampaignView")
								)}
							/>

							<Route
								exact
								path={ADMIN_QUESTS}
								component={asyncComponent(() => import("./routes/AdminQuests"))}
							/>

							<Route
								exact
								path={ADMIN_QUESTS_NEW}
								component={asyncComponent(() => import("./routes/AdminQuests/New"))}
							/>

							<Route
								path={ADMIN_QUESTS_QUEST_TRANSLATIONS}
								component={asyncComponent(() =>
									import("./routes/AdminQuests/Quest/Translations")
								)}
							/>

							<Route
								path={ADMIN_QUESTS_QUEST}
								component={asyncComponent(() =>
									import("./routes/AdminQuests/Quest")
								)}
							/>

							<Route
								exact
								path={`${match.url}/servicesettings/achievements`}
								component={asyncComponent(() =>
									import("./routes/achievements/Achievements")
								)}
							/>

							<Route
								exact
								path={`${match.url}/servicesettings/achievements/create/`}
								component={asyncComponent(() =>
									import("./routes/achievements/AchievementCreate")
								)}
							/>

							<Route
								path={`${match.url}/servicesettings/achievements/:id`}
								component={AchievementView}
							/>

							<Route
								path={`${match.url}/rewards/Orders/orderDetails/:id`}
								component={asyncComponent(() =>
									import("./routes/orders/OrderDetails")
								)}
							/>

							{/* Settings */}

							<Route exact path={`${match.url}/settings/tiers`} component={Tiers} />

							<Route
								path={`${match.url}/settings/tiers/:id`}
								component={asyncComponent(() => import("./routes/tiers/TierView"))}
							/>

							<Route
								exact
								path={`/app/servicesettings/interactions`}
								component={Interactions}
							/>

							<Route
								exact
								path={`/app/servicesettings/channels`}
								component={Channels}
							/>

							<Route
								exact
								path={`/app/servicesettings/channels/twitch`}
								component={TwitchChannels}
							/>

							<Route
								exact
								path={`/app/servicesettings/channels/twitter`}
								component={TwitterChannels}
							/>

							<Route
								exact
								path={`/app/servicesettings/channels/liquidStore`}
								component={LiquidStore}
							/>

							<Route
								exact
								path={`${match.url}/servicesettings/channels/reddit`}
								component={asyncComponent(() =>
									import("./routes/channels/reddit/RedditChannels")
								)}
							/>

							<Route
								exact
								path={`/app/servicesettings/channels/discord`}
								component={DiscordChannels}
							/>

							<Route
								exact
								path={`/app/servicesettings/liquipedia/keywords`}
								component={LiquipediaTeamKeywords}
							/>

							{/* LIQ-2645 */}
							{/* <Route
							exact
							path={`${match.url}/settings/hashtags`}
							component={Hashtags}
							/> */}

							{/* Events */}
							<Route exact path={`${match.url}/events`} component={events} />

							<Route
								exact
								path={`${match.url}/events/create/`}
								component={EventCreate}
							/>

							<Route
								exact
								path={`${match.url}/events/qr/:id`}
								component={EventQrCode}
							/>

							<Route path={`${match.url}/events/:id`} component={EventView} />

							{/* Orders */}
							<Route
								exact
								path={`${match.url}/fulfillment/orders/:id/:orderState`}
								component={OrderDetails}
							/>
							<Route
								exact
								path={`${match.url}/fulfillment/orders`}
								component={Orders}
							/>

							{/* Accounts */}
							<Route
								exact
								path={`${match.url}/accounts/users/:id`}
								component={UserDetail}
							/>

							<Route path={`${match.url}/accounts/users`} component={UserSearch} />

							<Route path={`${match.url}/accounts/staff`} component={Staff} />

							<Route
								path={`${match.url}/accounts/permissions`}
								component={Permissions}
							/>

							{/* Entities */}

							<Route
								exact
								path={match.url + ENTITIES + PLAYERS}
								component={asyncComponent(() =>
									import("./routes/Entities/Players")
								)}
							/>

							<Route
								path={`${match.url}${ENTITIES}${GAMES}`}
								component={asyncComponent(() => import("./routes/Entities/Games"))}
							/>

							{/* Logs */}
							<Route
								exact
								path={`${match.url}/logs/points`}
								component={PointsHistory}
							/>
							<Route exact path={`${match.url}/logs/bans`} component={Bans} />

							{/* Service Status */}
							<Route
								exact
								path={`${match.url}/ServiceStatus`}
								component={asyncComponent(() => import("./routes/ServiceStatus"))}
							/>

							<Route
								exact
								path={`${match.url}/points-processing`}
								component={PointsProcessing}
							/>

							<Route component={Error404} />
						</Switch>
					</div>
				</main>
			</div>
		</div>
	);
}

AdminApp.propTypes = {
	drawerType: PropTypes.string.isRequired,
	horizontalNavPosition: PropTypes.string.isRequired,
	match: PropTypes.shape({
		url: PropTypes.string.isRequired,
	}).isRequired,
	navigationStyle: PropTypes.string.isRequired,
	location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	const { drawerType, navigationStyle, horizontalNavPosition } = state.settings;

	return {
		drawerType,
		navigationStyle,
		horizontalNavPosition,
		userRole: state.user.profile?.role?.name,
	};
};

export default withRouter(connect(mapStateToProps)(AdminApp));
