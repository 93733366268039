import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./styles.scss";

// Constants
import { ADMIN } from "../../../constants/UserRoles";

// Utils
import moment from "moment";

// Tabs
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

// Tab contents
import UserAccount from "./UserAccount";
import UserInteractions from "./UserInteractions";
import UserOrders from "./UserOrders";
import UserReferrals from "./UserReferrals";

// Dialogs
import BanDialog from "./UserDialog/BanDialog";
import IdentityDialog from "./UserDialog/IdentityDialog";
import DeleteDialog from "./UserDialog/DeleteDialog";

// Structure components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Paper } from "@material-ui/core";

// Actions
import { fetchUserAccountInfo, deleteUser } from "../../../actions/UserAdminPanel";

// Authorize
import Authorize from "../../../auth/Authorize";
import { ADMIN_BANS_ALL } from "../../../constants/Api";

class UserDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			component: 0,
			hideContext: true,
		};
	}

	componentDidMount() {
		const { match, fetchUserAccountInfo } = this.props;
		const { id } = match.params;

		if (id) {
			fetchUserAccountInfo(id, {
				success: {
					refreshPoints: true,
				},
				failure: {
					refreshPoints: false,
				},
			});
		}
	}

	handleChange = (event, component) => {
		this.setState({ component });
	};

	handleContextClick = () => {
		this.setState((state) => ({
			hideContext: !state.hideContext,
		}));
	};

	renderTab(user_id) {
		const { component } = this.state;
		switch (component) {
			case 0:
				return <UserAccount />;
			case 1:
				return <UserInteractions userId={user_id} />;
			case 2:
				return <UserOrders userId={user_id} />;
			case 3:
				return <UserReferrals userId={user_id} />;
			default:
				return <UserAccount />;
		}
	}

	render() {
		const { user_account_info, deleteUser, loggedInUserId } = this.props;
		const { component, hideContext } = this.state;

		if (user_account_info) {
			const { id, created_at, is_banned, userInfo, role } = user_account_info;

			return (
				<div className="user-details admin">
					<div className="user-details__header">
						<button
							className="button button--borderless"
							onClick={() => window.history.go(-1)}
						>
							<span className="button__icon zmdi zmdi-chevron-left zmdi-hc-lg" />
							{" Back"}
						</button>
					</div>
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableRow>
								<TableCell
									className="data-table__data-cell data-table__data-cell--main"
									width="90%"
								>
									<div className="data-table__multi-line">
										<img
											decode={false}
											className="rounded-circle data-table__inline-image"
											id="staff-img"
											alt={userInfo?.username || "User avatar"}
											src={userInfo?.avatar.small_icon_url}
										/>
										<div>
											<span className="data-table__item-title">
												{userInfo && userInfo.username}
											</span>
											<br />
											<span className="data-table__item-sub-title">
												Registered:{" "}
												{created_at
													? moment(created_at).format("MMM DD, YYYY")
													: ""}
											</span>
										</div>
									</div>
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<IdentityDialog
										avatar={userInfo?.avatar.small_icon_url}
										username={userInfo?.username}
										userId={id}
									/>
								</TableCell>
								<Authorize allow={[ADMIN_BANS_ALL]}>
									{id !== loggedInUserId && role && role.type !== ADMIN && (
										<TableCell className="data-table__data-cell" width="5%">
											<button
												className="button button--circle button--borderless"
												onClick={this.handleContextClick}
											>
												<img
													className="button__image"
													src={require("../../../svg/ellipsis-vertical.svg")}
													alt="User avatar"
												/>
											</button>
											{!hideContext && (
												<Paper className="context">
													{userInfo ? (
														<BanDialog
															userId={id}
															userName={userInfo.username}
															isBanned={is_banned}
														/>
													) : null}
													<DeleteDialog id={id} deleteUser={deleteUser} />
												</Paper>
											)}
										</TableCell>
									)}
								</Authorize>
							</TableRow>
						</TableBody>
					</Table>
					<div>
						<Tabs
							className="pb-4"
							value={component}
							onChange={this.handleChange}
							classes={{
								root: "tabs-root",
								indicator: "tab-indicator",
							}}
						>
							<Tab classes={{ root: "tab-label" }} label="Account" />
							<Tab classes={{ root: "tab-label" }} label="Interactions" />
							<Tab classes={{ root: "tab-label" }} label="Orders" />
							<Tab classes={{ root: "tab-label" }} label="Referrals" />
						</Tabs>
						{this.renderTab(id)}
					</div>
				</div>
			);
		} else {
			return null;
		}
	}
}

UserDetail.propTypes = {
	loggedInUserId: PropTypes.bool.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
	user_account_info: PropTypes.shape({
		created_at: PropTypes.string.isRequired,
		id: PropTypes.string.isRequired,
		is_banned: PropTypes.bool.isRequired,
		role: PropTypes.string.isRequired,
		userInfo: PropTypes.shape({
			avatar: PropTypes.string,
			username: PropTypes.string.isRequired,
		}).isRequired,
	}),
	deleteUser: PropTypes.func.isRequired,
	fetchUserAccountInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	loggedInUserId: state.user.profile.id,
	user_account_info: state.user_admin.user_account_info,
});

const mapDispatchToProps = (dispatch) => ({
	deleteUser: (params, options) => dispatch(deleteUser(params, options)),
	fetchUserAccountInfo: (user_id, options) => dispatch(fetchUserAccountInfo(user_id, options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
