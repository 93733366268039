import {all} from 'redux-saga/effects';

import {
    fetchDiscordDataWatcher
} from './FetchDiscordSagas'
import {
    createDiscordDataWatcher
} from './CreateDiscordChannelSagas'
import {
    removeDiscordDataWatcher
} from './RemoveDiscordChannelSagas'

export function *discordSagas() {
    yield all([
        fetchDiscordDataWatcher(),
        createDiscordDataWatcher(),
        removeDiscordDataWatcher()
    ]);
}