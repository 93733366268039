import { appendNotifications } from "../../actions/Notifications.js";
import { addMessage } from "../../actions/Messages.js";

import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";

import { SIGNUP_USER } from "../../constants/ActionTypes.js";
import { REGISTER_USER_URL } from "../../constants/Url.js";
import { ERROR, SUCCESS } from "../../constants/Notifications.js";
import api from "../../util/api.js";

export function* RegisterUserWatcher() {
	yield takeLatest(SIGNUP_USER, RegisterUserWorker);
}

function* RegisterUserWorker(action) {
	try {
		const response = yield call(RegisterUserApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.message) {
			yield put(
				addMessage({
					source: SIGNUP_USER,
					message: response?.error,
					isError: isSuccess,
					status: response.status,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		}
		if (options?.redirect) {
			yield put(push(options.redirect));
		}

		if (!isSuccess) {
			yield put(appendNotifications({ type: ERROR, message: response?.error }));
		}
	} catch (e) {
		console.log(e);
	}
}

function RegisterUserApi(params) {
	return api
		.post(REGISTER_USER_URL, {
			...params,
		})
		.catch((err) => {
			return err.response.data;
		});
}
