import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import {
	fetchDiscordData,
	createDiscordServer,
	deleteDiscordServer,
} from "../../../../actions/Channel";

import CreateBot from "./atoms/CreateBot";
import BotsList from "./atoms/BotsList";
import LpHeading from "../../../../components/LpHeading";

class DiscordChannels extends Component {
	componentDidMount() {
		this.props.fetchDiscordData();
	}

	backToChannels = () => {
		this.props.history.push("/app/servicesettings/channels");
	};

	handleDelete = (serverName) => {
		const payload = {
			serverName,
		};
		this.props.deleteDiscordServer(payload);
	};

	render() {
		return (
			<div className="channel">
				<div className="options-header">
					<div className="options options--left">
						<button
							className="button button--borderless"
							onClick={this.backToChannels}
						>
							<span className="zmdi zmdi-chevron-left zmdi-hc-lg pr-1"></span>
							&nbsp;Channels
						</button>
					</div>
					<div className="options options--right">
						<CreateBot
							handleCreate={this.props.createDiscordServer}
						/>
					</div>
				</div>
				<div className="m-3 p-3">
					<div>
						<LpHeading>Discord Bots</LpHeading>
					</div>
					<div className="table-responsive-material shadow border-0 card">
						<Table>
							<TableBody>
								<BotsList
									bots={this.props.data}
									handleDelete={this.handleDelete}
								/>
							</TableBody>
						</Table>
					</div>
				</div>
			</div>
		);
	}
}

DiscordChannels.propTypes = {
	data: PropTypes.array.isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	fetchDiscordData: PropTypes.func.isRequired,
	deleteDiscordServer: PropTypes.func.isRequired,
	createDiscordServer: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	data: state.channel.discordData,
});

const mapDispatchToProps = {
	fetchDiscordData,
	createDiscordServer,
	deleteDiscordServer,
};

export default connect(mapStateToProps, mapDispatchToProps)(DiscordChannels);
