import {
	SET_CAMPAIGN_TRANSLATIONS,
	SET_CAMPAIGN_TRANSLATIONS_STATUS,
	UPDATE_CAMPAIGN_TRANSLATIONS_PUBLISHED_STATUS,
} from "../constants/ActionTypes";

const campaignTranslationsInitialState = {
	status: [],
	translations: [],
};

export default function campaignTranslationsReducer(
	state = campaignTranslationsInitialState,
	action
) {
	switch (action.type) {
		case SET_CAMPAIGN_TRANSLATIONS:
			return {
				...state,
				translations: [...action.payload],
			};
		case SET_CAMPAIGN_TRANSLATIONS_STATUS:
			return {
				...state,
				status: action.payload,
			};
		case UPDATE_CAMPAIGN_TRANSLATIONS_PUBLISHED_STATUS: {
			const { params } = action.payload;
			return {
				...state,
				status: state.status.map((item) =>
					item.languageCode === params.languageCode
						? { ...item, isTranslationPublished: params.publishedStatus }
						: item
				),
			};
		}
		default:
			return state;
	}
}
