import { setTwitchData } from "../../../actions/Channel";

import { takeLatest, call, put } from "redux-saga/effects";

import { FETCH_TWITCH_CHANNEL_DATA } from "../../../constants/ActionTypes";
import { FETCH_TWITCH_CHANNELS } from "../../../constants/Url";

import api from "../../../util/api";

function fetchTwitchDataApi() {
	return api.get(FETCH_TWITCH_CHANNELS);
}

export function* fetchTwitchDataWatcher() {
	yield takeLatest(FETCH_TWITCH_CHANNEL_DATA, fetchTwitchDataWorker);
}

function* fetchTwitchDataWorker() {
	try {
		// yield put(showLoader());
		// data is obtained after axios call is resolved
		let response = yield call(fetchTwitchDataApi);
		if (response.status >= 200 && response.status < 300) {
			yield put(setTwitchData(response.data.data));
		} else {
			throw response;
		}
		// yield put(hideLoader());
	} catch (e) {
		console.log(e.response);
		// yield put(hideLoader());
	}
}
