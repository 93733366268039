import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./styles.scss";

const LpInputValidationError = ({ message, className }) => (
	<div className={classnames("lp_input_validation_error", className)}>
		<img src={require("../../svg/alert.svg")} />
		<span>{message}</span>
	</div>
);

LpInputValidationError.defaultProps = {
	message: PropTypes.string,
	className: PropTypes.string,
};

export default LpInputValidationError;
