import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { modifyOrder } from "../../../../actions/Orders";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LpDropdown from "../../../LpDropdown";

const _ = require("lodash");

class OrderOptions extends Component {
	state = {
		order: null,
		orderState: "",
	};

	componentWillMount() {
		const { order, orderState } = this.props;
		this.setState({
			order,
			orderState,
		});
	}

	componentWillReceiveProps(nextProps) {
		const { state } = this;
		let { order } = nextProps;
		if (!_.isEqual(order, state.order)) {
			this.setState({ order });
			this.generateDropdownOptions();
		}
	}

	updateOrder(id, type, arg, value) {
		const { modifyOrder } = this.props;
		const { orderState } = this.state;
		modifyOrder(id, type, { [arg]: value }, orderState);
	}

	render() {
		const { order } = this.state;

		return (
			<LpDropdown position="bottom-left">
				<CopyToClipboard text={order.email}>
					<button className="dropdown-item">Copy Email</button>
				</CopyToClipboard>
				<CopyToClipboard text={JSON.stringify(order)}>
					<button className="dropdown-item">
						Copy Order Information
					</button>
				</CopyToClipboard>
				<button
					onClick={() =>
						this.updateOrder(
							order.id,
							order.type,
							"has_been_fulfilled",
							!order.has_been_fulfilled
						)
					}
					className="dropdown-item"
				>
					{!order.has_been_fulfilled
						? "Mark as Fulfilled"
						: "Mark as not Fulfilled"}
				</button>
				<button
					onClick={() =>
						this.updateOrder(
							order.id,
							order.type,
							"has_been_contacted",
							!order.has_been_contacted
						)
					}
					className="dropdown-item"
					href="#"
				>
					{!order.has_been_contacted
						? "Mark as Contacted"
						: "Mark as not Contacted"}
				</button>
			</LpDropdown>
		);
	}
}

OrderOptions.propTypes = {
	order: PropTypes.object,
	orderState: PropTypes.string,
	modifyOrder: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
	return {
		modifyOrder: (id, type, params, orderState) =>
			dispatch(modifyOrder(id, type, params, orderState)),
	};
};

const mapStateToProps = (state) => {
	return {
		orders: state.orders.bank,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderOptions);
