import React from "react";
import PropTypes from "prop-types";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { NO_BOT_TO_SHOW } from "../../../../../constants/Label";

import DeleteBot from "./DeleteBot";

function BotsList({ bots, handleDelete }) {
	if (bots.length) {
		return bots.map((bot) => {
			return (
				<TableRow key={bot.id}>
					<TableCell>
						<span>{bot.name}</span>
					</TableCell>

					<TableCell style={{ textAlign: "right" }}>
						<DeleteBot
							handleDelete={() => handleDelete(bot.name)}
						/>
					</TableCell>
				</TableRow>
			);
		});
	} else {
		return (
			<TableRow>
				<TableCell>{NO_BOT_TO_SHOW}</TableCell>
			</TableRow>
		);
	}
}

BotsList.propTypes = {
	bots: PropTypes.array.isRequired,
	handleDelete: PropTypes.func.isRequired,
};

export default BotsList;
