import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./styles/index.scss";

const LpBlock = forwardRef((props, ref) => {
	const {
		type,
		variant,
		children,
		onClick,
		value,
		autolayout,
		className,
		...rest
	} = props;
	const Element = type;
	return (
		<Element
			ref={ref}
			className={classnames(
				"lp_block",
				`lp_block--${variant}`,
				{ "lp_block--clickable": onClick !== null },
				{ "lp_block--autolayout": autolayout },
				className
			)}
			{...rest}
			onClick={(event) => {
				return onClick
					? (onClick(event, value), event.stopPropagation())
					: null;
			}}
		>
			{children}
		</Element>
	);
});

LpBlock.defaultProps = {
	type: "div",
	variant: "small",
	onClick: null,
	autolayout: false,
};

LpBlock.propTypes = {
	type: PropTypes.string,
	variant: PropTypes.oneOf(["small", "medium", "padded"]),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	className: PropTypes.string,
	onClick: PropTypes.func,
	value: PropTypes.any,
	autolayout: PropTypes.bool,
};

export default LpBlock;
