const PROTOCOL_HOST = (function (env) {
	switch (env) {
		case "mock":
			return "http://localhost:6100";
		default:
			return `${window.location.protocol}//${window.location.hostname}`;
	}
})(process.env.REACT_APP_BACKEND_ENV);

// Front End Routes
const ADMIN = "/app";
const SERVICE_SETTINGS = "/servicesettings";

// User related routes
export const USERS_SERVICE_API = "/users/api";
export const USERS = "/users";
export const USER = "/user";
export const USER_INFO = "/user-info";
export const FRIEND_REFERRAL_API = "/referral";
export const FRIEND_REFERRAL_URL = PROTOCOL_HOST + "/refer";
export const USER_LANGUAGE_CHANGE_URL = PROTOCOL_HOST + USERS_SERVICE_API + USER + "/language";

export const USER_SERVICE_LOGIN = PROTOCOL_HOST + USERS_SERVICE_API + "/auth/login";
export const USER_SERVICE_LOGOUT = PROTOCOL_HOST + USERS_SERVICE_API + "/auth/logout";
export const FORGOT_PASSWORD_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/account/forgot-password";
export const RESET_PASSWORD_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/account/reset-password";
export const USER_SERVICE_PROFILE = PROTOCOL_HOST + "/users/profile";
export const CHECK_DUPLICATE_USERNAME_URL =
	PROTOCOL_HOST + USERS_SERVICE_API + USER + "/find-duplicate-username";
export const USER_EMAIL_CHANGE_URL =
	PROTOCOL_HOST + USERS_SERVICE_API + USER + "/request-for-email-id-change";
export const REQUEST_ACCESS_TOKEN_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/auth/request-token";

export const REGISTER_USER_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/auth/register";
export const SIGNUP_NOTIFY_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/notify-sign-up";
export const RESEND_REGISTRATION_EMAIL_URL =
	PROTOCOL_HOST + USERS_SERVICE_API + "/account/resend-verification";

export const GET_PUBLIC_PROFILE_URL = (shareId) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}/profile/fetchProfile/${shareId}`;
};
export const USER_SERVICE_UPDATE_URL = (user_id) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USER}/${user_id}`;
};
export const VERIFICATION_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/auth/verify";
export const SOCIAL_REGISTER_INITIATE = PROTOCOL_HOST + USERS_SERVICE_API + "/social-register";
export const GET_SOCIAL_TOKEN = PROTOCOL_HOST + USERS_SERVICE_API + "/auth/social-token";
export const PASSWORD_AUTHENTICATION_URL =
	PROTOCOL_HOST + USERS_SERVICE_API + "/auth/password-authentication";

export const CREATE_REFERRAL_LINK_URL =
	PROTOCOL_HOST + USERS_SERVICE_API + FRIEND_REFERRAL_API + "/create";
export const PERMISSION_LIST_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/admin/permission/all";

export const USER_SERVICE_POINTS_LIFETIME_URL = (user_id) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USER}/${user_id}/lifetime`;
};
export const USER_SERVICE_POINTS_MEMBERSHIP_URL = (user_id, season_name) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USER}/${user_id}/membership${
		season_name ? `/${season_name}` : ``
	}`;
};
export const USER_SERVICE_POINTS_SPENDING_URL = (user_id, season_name) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USER}/${user_id}/spending${
		season_name ? `/${season_name}` : ``
	}`;
};
export const ALL_SEASONS_URL = `${PROTOCOL_HOST}${USERS_SERVICE_API}/seasons/`;

export const USER_SERVICE_RANK_UP_PROGRESS_URL = (user_id) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USER}/${user_id}/rankup-progress`;
};
export const USER_SERVICE_CLAIM_PARTNER_BONUS_URL = (user_id) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USER}/${user_id}/partner-bonus`;
};
export const USER_SERVICE_USER_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/user";
export const USER_SERVICE_AVATAR_URL = (user_id) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}/${user_id}/avatar`;
};

// export const FETCH_USERNAMES_AND_AVATARS_URL = (user_ids) => {
// 	return `${PROTOCOL_HOST}${USERS_SERVICE_API}/users/usernames-and-avatars/?${user_ids.user_ids}`;
// };

export const FETCH_USERNAMES_AND_AVATARS_URL = `${PROTOCOL_HOST}${USERS_SERVICE_API}${USERS}/usernames-and-avatars`;

export const USER_SERVICE_ALL_AVATARS_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/avatars/all";

export const USER_SERVICE_AUTH_ALL_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/auth/all";
export const USER_SERVICE_AUTH_CREATE_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/auth/create";

export const MAILER = "/mailer";
export const USER_SERVICE_SEND_HELP_REQUEST =
	PROTOCOL_HOST + USERS_SERVICE_API + MAILER + "/help-request";
export const FETCH_USER_PREFERRED_GAMES_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/games/user";
export const FETCH_GAMES_PUBLIC_INFO_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/games/public/all";
export const FETCH_USER_PREFERRED_PLAYERS_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/players/user";

export const USER_ROLE_URL = (userId) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USERS}/${userId}/role`;
};
export const USER_ONBOARDED_URL = (userId) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USERS}/${userId}/onboarded`;
};
export const USER_USERNAME_URL = (userId) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USERS}/${userId}${USER_INFO}/username`;
};
export const USER_MEMBERSHIP_URL = (userId) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USERS}/${userId}${USER_INFO}/custom-tier-name`;
};
export const USER_EMAIL_URL = (userId) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USERS}/${userId}/email`;
};
export const USER_COUNTRY_URL = (userId) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USERS}/${userId}${USER_INFO}`;
};

export const USER_COUNTRY_CODE_URL = (userId) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USERS}/${userId}${USER_INFO}/country-code`;
};
export const USER_GENDER_URL = (userId) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USERS}/${userId}${USER_INFO}`;
};
export const USER_BIRTHDAY_URL = (userId) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USERS}/${userId}${USER_INFO}/birthday`;
};
export const VERIFY_AND_UPDATE_EMAIL_CHANGE_REQUEST_URL =
	PROTOCOL_HOST + USERS_SERVICE_API + "/user/change-email";

export const USER_CLAIMED_PERK_URL = (userId) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}${USERS}/${userId}/claimed-perks`;
};

export const USER_REQUEST_DELETE_ACCOUNT_URL =
	PROTOCOL_HOST + USERS_SERVICE_API + "/account/request-delete-account";
export const USER_DELETE_ACCOUNT_URL =
	PROTOCOL_HOST + USERS_SERVICE_API + "/account/delete-account";

export const REGISTRATION_STATUS = PROTOCOL_HOST + USERS_SERVICE_API + "/registration-status";

// User related routes for Admin Panel
export const COUNT_USERS_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/admin/users/count";
export const SEARCH_USERS_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/admin/users/search";
export const ALL_STAFF_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/admin/staff/all";
export const GET_ROLES_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/admin/roles/all";
export const USER_ACCOUNT_INFO_URL = function (id) {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/admin/user/${id}/account`;
};
export const USER_DETAILS_INFO_URL = function (user_name) {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/admin/user/${user_name}/details`;
};
export const BAN_USER_URL = function (id) {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/admin/user/${id}/ban`;
};
export const UNBAN_USER_URL = function (id) {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/admin/user/${id}/unban`;
};
export const FETCH_POINTS_HISTORY_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/admin/points/all";
export const FETCH_USER_RECENT_INTERACTIONS_URL = (user_id) => {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/admin/points/${user_id}/recent-interactions`;
};
export const ADMIN_POINTS_CHANGE_URL = function (user_id) {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/admin/points/${user_id}/change-admin-points`;
};
export const ALL_BAN_USERS_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/admin/bans/all";
export const FETCH_INTERACTIONS_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/admin/interactions";
export const REWARD_SERVICE_ADMIN_USER_ORDERS = PROTOCOL_HOST + "/rewards/orders/admin-user-orders";

export const UPDATE_REFERRAL_LINK_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/admin/refer/update";
export const FETCH_USER_FRIEND_REFERRAL_URL =
	PROTOCOL_HOST + USERS_SERVICE_API + "/admin/refer/user-referrals";

export function DELETE_USER(id) {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}/admin${USER}/${id}/account`;
}

// Events related routes
export const CREATE_EVENT_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/events/create";
export const RECENT_EVENTS_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/events/all";
export const FETCH_EVENT_URL = function (id) {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/events/${id}`;
};
export const UPDATE_EVENT_URL = (id) => {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/events/update/${id}`;
};
export const EMAIL_EVENT_DATA_URL = (id) => {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/events/email/${id}`;
};
export const EVENT_CHECK_IN_LINK = PROTOCOL_HOST + "/events/check-in";
export const EVENT_CHECK_IN_API_LINK = PROTOCOL_HOST + USERS_SERVICE_API + "/events/check-in";
export const DELETE_EVENT_URL = (id) => {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/events/${id}`;
};

// Achievements related routes
export const FETCH_ACHIEVEMENTS_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/achievements/all";
export const CREATE_ACHIEVEMENT_URL =
	PROTOCOL_HOST + USERS_SERVICE_API + "/admin/achievements/create";
export const FETCH_VALID_ACHIEVEMENTS_URL =
	PROTOCOL_HOST + USERS_SERVICE_API + "/admin/achievements/valid";
export const SEARCH_ACHIEVEMENTS_URL =
	PROTOCOL_HOST + USERS_SERVICE_API + "/admin/achievements/search";
export const FETCH_ACHIEVEMENT_URL = function (id) {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/admin/achievements/${id}`;
};
export const UPDATE_ACHIEVEMENT_URL = (id) => {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/admin/achievements/update/${id}`;
};

// Users Achievements related routes
export const CREATE_USERS_ACHIEVEMENT_URL =
	PROTOCOL_HOST + USERS_SERVICE_API + "/users-achievements/create";
export const FETCH_USERS_ACHIEVEMENTS_URL = function (id) {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/users-achievements/${id}`;
};
export const FETCH_ACHIEVEMENTS_DISPLAY_URL = function (id) {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/users-achievements/${id}/display`;
};
export const UPDATE_ACHIEVEMENTS_DISPLAY_URL =
	PROTOCOL_HOST + USERS_SERVICE_API + `/users-achievements/display`;

// Users Season Review related routes
export const FETCH_USERS_SEASON_REVIEW_URL =
	PROTOCOL_HOST + USERS_SERVICE_API + `/year_review/fetchYearReview/`;
export const FETCH_USERS_SEASON_REVIEW_BY_NAME_URL = function (name) {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/year_review/fetchYearReview/${name}`;
};

// Rewards related routes
export const ADMIN_REWARDS = ADMIN + SERVICE_SETTINGS + "/rewards";
export const REWARD_SERVICE_CREATE = PROTOCOL_HOST + "/rewards/api/exchange";
export const REWARD_SERVICE = PROTOCOL_HOST + "/rewards/api";
export const REWARD_SERVICE_SEARCH_URL = PROTOCOL_HOST + "/rewards/api/search";
export const SHOPIFY_SERVICE_FETCH_ITEMS = PROTOCOL_HOST + "/shopify/store/items";
export const UPDATE_REWARDS_DATA_API = PROTOCOL_HOST + "/rewards/api/update";

// Orders related routes
export const REWARD_SERVICE_ORDERS = PROTOCOL_HOST + "/rewards/orders";
export const REWARD_SERVICE_USER_ORDERS = REWARD_SERVICE_ORDERS + "/user-orders";

// Reward FAQS related routes
export const REWARD_SERVICE_CREATE_FAQS = PROTOCOL_HOST + "/rewards/faq/";
export const REWARD_SERVICE_FAQS = (reward_id) => {
	return `${PROTOCOL_HOST}/rewards/faq/${reward_id}`;
};

// Discord related routes
export const DISCORD_API = "/discord/api";

export const FETCH_DISCORD_CHANNELS = PROTOCOL_HOST + DISCORD_API + "/channel/all";
export const CREATE_DISCORD_CHANNELS = PROTOCOL_HOST + DISCORD_API + "/channel/add";
export const DELETE_DISCORD_CHANNEL = PROTOCOL_HOST + DISCORD_API + "/channel/remove";

// Twitch related routes
export const TWITCH_API = "/twitch/api";

export const FETCH_TWITCH_CHANNELS = PROTOCOL_HOST + TWITCH_API + "/channel/all";
export const CREATE_TWITCH_CHANNELS = PROTOCOL_HOST + TWITCH_API + "/channel/add";
export const DELETE_TWITCH_CHANNEL = PROTOCOL_HOST + TWITCH_API + "/channel/remove";
export const TWITCH_BROADCASTS = PROTOCOL_HOST + TWITCH_API + "/events";
export const TWITCH_BROADCAST = (id) => {
	return `${PROTOCOL_HOST}${TWITCH_API}/events/${id}`;
};
export const TWITCH_BROADCASTS_ASSIGN_MODERATOR = TWITCH_BROADCASTS + "/assign-moderators";
export const TWITCH_EVENTS_SCHEDULE = PROTOCOL_HOST + "/twitch/events/schedule";
export const TWITCH_LIVE_STREAMERS = PROTOCOL_HOST + "/twitch/streamers/live";
export const TWITCH_AUTHORIZE_OAUTH = PROTOCOL_HOST + "/twitch/chatters-oauth/authorize";

export const TWITCH_APP_MODERATORS = PROTOCOL_HOST + TWITCH_API + "/moderators";
export const DELETE_TWITCH_APP_MODERATOR = (id) => TWITCH_APP_MODERATORS + `/${id}`;
export const UPDATE_TWITCH_APP_MODERATOR = (id) => TWITCH_APP_MODERATORS + `/${id}`;

export const GET_LIQUIPEDIA_KEYWORDS = PROTOCOL_HOST + TWITCH_API + "/liquipedia/keywords";
export const POST_LIQUIPEDIA_KEYWORD = PROTOCOL_HOST + TWITCH_API + "/liquipedia/keywords";
export const DELETE_LIQUIPEDIA_KEYWORD = (id) =>
	PROTOCOL_HOST + TWITCH_API + `/liquipedia/keywords/${id}`;

// Twitter related routes
export const TWITTER_API = "/twitter/api";

export const TWITTER_CHANNELS_URL = PROTOCOL_HOST + TWITTER_API + "/tracked-identifiers";

// Shopify related routes
export const SHOPIFY_API = "/shopify/api";

// Reddit related routes
export const REDDIT_API = "/reddit/api";

export const REDDIT_POSTS_URL = PROTOCOL_HOST + REDDIT_API + "/posts";
export const REDDIT_SUBREDDITS_URL = PROTOCOL_HOST + REDDIT_API + "/subreddits";

// Channel count related routes
export const GET_DISCORD_CHANNEL_COUNT = PROTOCOL_HOST + DISCORD_API + "/channel/count";
export const GET_TWITCH_CHANNEL_COUNT = PROTOCOL_HOST + TWITCH_API + "/channel/count";
export const GET_SHOPIFY_CHANNEL_COUNT = PROTOCOL_HOST + SHOPIFY_API + "/channel/count";
export const GET_TWITTER_CHANNEL_COUNT = PROTOCOL_HOST + TWITTER_API + "/tracked-identifiers/count";
export const GET_REDDIT_CHANNEL_COUNT = PROTOCOL_HOST + REDDIT_API + "/channel/count";

// Loyalty related routes
export const LOYALTY_BASE = PROTOCOL_HOST + "/loyalty";
export const LOYALTY_PROCESS_EVENTS = LOYALTY_BASE + "/process";

// Quests related routes
export const QUESTS_API = `/v1/quests/api`;
export const QUESTS = `/quests`;
export const CAMPAIGNS = `/campaigns`;
export const QUEST_PROGRESSIONS = `/quest-progressions`;
export const FETCH_QUESTS_URL = `${PROTOCOL_HOST}${QUESTS_API}${QUESTS}`;
export const FETCH_QUEST_TRANSLATIONS_URL = (questId) =>
	`${PROTOCOL_HOST}${QUESTS_API}/admin/quests/${questId}/translation`;
export const PATCH_QUEST_TRANSLATIONS_URL = (questId) =>
	`${PROTOCOL_HOST}${QUESTS_API}/admin/quests/${questId}/translation`;
export const PATCH_QUEST_TRANSLATIONS_PUBLISHED_STATUS_URL = (questId) =>
	`${PROTOCOL_HOST}${QUESTS_API}/admin/quests/${questId}/translation/published`;
export const FETCH_QUEST_TRANSLATIONS_STATUS_URL = (questId) =>
	`${FETCH_QUEST_TRANSLATIONS_URL(questId)}/status`;
export const FETCH_CAMPAIGN_TRANSLATIONS_URL = (campaignID) =>
	`${PROTOCOL_HOST}${QUESTS_API}/admin/campaigns/${campaignID}/translation`;
export const PATCH_CAMPAIGN_TRANSLATIONS_URL = (campaignID) =>
	`${PROTOCOL_HOST}${QUESTS_API}/admin/campaigns/${campaignID}/translation`;
export const PATCH_CAMPAIGN_TRANSLATIONS_PUBLISHED_STATUS_URL = (campaignID) =>
	`${PROTOCOL_HOST}${QUESTS_API}/admin/campaigns/${campaignID}/translation/published`;

export const ADMIN_FETCH_QUESTS_URL = `${PROTOCOL_HOST}${QUESTS_API}/admin${QUESTS}`;
export const FETCH_OBJECTIVE_ALGORITHMS_URL = `${PROTOCOL_HOST}${QUESTS_API}/admin${QUESTS}/help`;
export const CREATE_QUESTS_URL = `${PROTOCOL_HOST}${QUESTS_API}${QUESTS}`;
export const UPDATE_QUEST_URL = (questId) => {
	return `${PROTOCOL_HOST}${QUESTS_API}${QUESTS}/${questId}`;
};
export const DELETE_QUEST_URL = (questId) => {
	return `${PROTOCOL_HOST}${QUESTS_API}${QUESTS}/${questId}`;
};
export const UPDATE_MULTIPLE_QUESTS_URL = `${PROTOCOL_HOST}${QUESTS_API}${QUESTS}`;
export const CREATE_CAMPAIGNS_URL = `${PROTOCOL_HOST}${QUESTS_API}/admin${CAMPAIGNS}`;
export const ADMIN_FETCH_CAMPAIGNS_URL = `${PROTOCOL_HOST}${QUESTS_API}/admin${CAMPAIGNS}`;
export const FETCH_CAMPAIGN_TRANSLATIONS_STATUS_URL = (campaignId) =>
	`${PROTOCOL_HOST}${QUESTS_API}/admin${CAMPAIGNS}/${campaignId}/translation/status`;
export const PUBLIC_FETCH_CAMPAIGNS_URL = `${PROTOCOL_HOST}${QUESTS_API}${CAMPAIGNS}`;
export const UPDATE_CAMPAIGN_URL = (campaignId) => {
	return `${PROTOCOL_HOST}${QUESTS_API}/admin${CAMPAIGNS}/${campaignId}`;
};
export const FETCH_QUEST_PROGRESSIONS_URL = `${PROTOCOL_HOST}${QUESTS_API}${QUEST_PROGRESSIONS}/user`;
export const FETCH_QUEST_PROGRESSION_BUCKET_TOP_USERS_URL = (quest_id, objective_id) => {
	return `${PROTOCOL_HOST}${QUESTS_API}${QUEST_PROGRESSIONS}/top-users/?quest=${quest_id}&objective=${objective_id}`;
};
export const FETCH_QUEST_PROGRESSION_BUCKET_TOP_CHANNELS_URL = (quest_id, objective_id) => {
	return `${PROTOCOL_HOST}${QUESTS_API}${QUEST_PROGRESSIONS}/top-channels/?quest=${quest_id}&objective=${objective_id}`;
};
export const PUBLIC_FETCH_CAMPAIGN_URL = (campaignId) => {
	return `${PROTOCOL_HOST}${QUESTS_API}${CAMPAIGNS}/${campaignId}`;
};
export const ENTER_CAMPAIGN_URL = (campaignId) => {
	return `${PROTOCOL_HOST}${QUESTS_API}${CAMPAIGNS}/${campaignId}/enter`;
};
export const CLAIM_REWARDS_FROM_QUEST_PROGRESSION_URL = (questProgressionId) => {
	return `${PROTOCOL_HOST}${QUESTS_API}${QUEST_PROGRESSIONS}/${questProgressionId}/claim-rewards`;
};
export const ACCEPT_TERMS_FROM_QUEST_PROGRESSION_URL = (questProgressionId) => {
	return `${PROTOCOL_HOST}${QUESTS_API}${QUEST_PROGRESSIONS}/${questProgressionId}/accept-terms`;
};

// Quests & Experiences v2
export const V2_QUESTS = "/v2/quests/quests";
export const V2_QUESTS_HIGHLIGHTED = V2_QUESTS + "/highlight";
export const V2_QUESTS_OUT_OF_EXPERIENCES = V2_QUESTS + "/out-of-experiences";
export const V2_QUESTS_COMPLETED = V2_QUESTS + "/user";

export const V2_EXPERIENCES = "/v2/quests/experiences";

// Platform connect
export const AUTHORIZE_PLATFORM_URL = PROTOCOL_HOST + "/users/api/link";
export const DISCONNECT_PLATFORM_URL = AUTHORIZE_PLATFORM_URL + "/disconnect-platform";
export const LINK_SHOPIFY_ACCOUNT_URL = PROTOCOL_HOST + "/users/api/shopify/link";
export const VERIFY_SHOPIFY_ACCOUNT_URL = PROTOCOL_HOST + "/users/api/shopify/verify";
// Platform visibility
export const SOCIAL_PLATFORM_VISIBILITY_URL =
	PROTOCOL_HOST + "/users/api/link/toggle-platform-visibility";

//Tiers
export const FETCH_ALL_TIERS_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/admin/tiers/all";
export const UPDATE_TIER_URL = (id) => {
	return PROTOCOL_HOST + USERS_SERVICE_API + `/admin/tiers/update/${id}`;
};

//Perks
export const FETCH_ALL_PERKS_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/perks/all";
export const FETCH_USER_PERKS_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/tiers/current-user";

//Point Histories
export const POINT_HISTORIES_API = `/point-histories`;
export const FETCH_POINT_HISTORIES_URL = (userId, seasonName, options) => {
	const baseEndpoint = `${PROTOCOL_HOST}${USERS_SERVICE_API}${POINT_HISTORIES_API}${USER}/${userId}`;

	if (seasonName) {
		const queryParams = [];
		if (options?.page) queryParams.push(`page=${options.page}`);
		if (options?.limit) queryParams.push(`limit=${options.limit}`);
		queryParams.push(`season_name=${seasonName}`);

		return `${baseEndpoint}?${queryParams.join("&")}`;
	}

	return baseEndpoint;
};

export const ALL_GAMES_URL = `${PROTOCOL_HOST}${USERS_SERVICE_API}/admin/game/all`;
export const ADD_GAME_URL = `${PROTOCOL_HOST}${USERS_SERVICE_API}/admin/game/add`;
export const ARCHIVE_RESTORE_GAME_URL = (name) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}/admin/game/archive_restore/${name}`;
};
export const UPDATE_GAME_URL = (name) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}/admin/game/update/${name}`;
};
export const DELETE_GAME_URL = (name) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}/admin/game/delete/${name}`;
};
export const ALL_PLAYERS_URL = `${PROTOCOL_HOST}${USERS_SERVICE_API}/admin/player/all`;
export const ADD_PLAYER_URL = `${PROTOCOL_HOST}${USERS_SERVICE_API}/admin/player/add`;
export const ARCHIVE_RESTORE_PLAYER_URL = (name) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}/admin/player/archive_restore/${name}`;
};
export const UPDATE_PLAYER_URL = (name) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}/admin/player/update/${name}`;
};
export const DELETE_PLAYER_URL = (name) => {
	return `${PROTOCOL_HOST}${USERS_SERVICE_API}/admin/player/delete/${name}`;
};

// Countries
export const FETCH_COUNTRIES_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/country";

// Genders
export const FETCH_GENDERS_URL = PROTOCOL_HOST + USERS_SERVICE_API + "/gender";

// Datadog Rum Status Route
export const RUM_STATUS = "/users/api/rum-status";

// Webflow
export const WEBFLOW_HOME = "/";
