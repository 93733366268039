import { all } from "redux-saga/effects";

import { fetchQuestsWatcher } from "./FetchQuestsSagas";
import { adminFetchQuestsWatcher } from "./AdminFetchQuestsSagas";
import { createQuestsWatcher } from "./CreateQuestsSagas";
import { updateQuestWatcher } from "./UpdateQuestSagas";
import { deleteQuestsWatcher } from "./DeleteQuestSagas";
import { updateMultipleQuestsWatcher } from "./UpdateMultipleQuests";
import { fetchObjectiveAlgorithmsWatcher } from "./FetchObjectiveAlgorithmsSaga";

export function* questsSagas() {
	yield all([
		fetchQuestsWatcher(),
		adminFetchQuestsWatcher(),
		createQuestsWatcher(),
		updateQuestWatcher(),
		deleteQuestsWatcher(),
		updateMultipleQuestsWatcher(),
		fetchObjectiveAlgorithmsWatcher(),
	]);
}
