import React from "react";
import PropTypes from "prop-types";

export default function ModalFooter({
	className,
	cancelBtnText,
	submitBtnText,
	form,
	onClose,
	onSubmit,
	submitDisabled,
}) {
	return (
		<div className={className}>
			{cancelBtnText && (
				<button className="button" type="button" onClick={onClose}>
					{cancelBtnText}
				</button>
			)}

			{submitBtnText && (
				<button
					className="button button--colored"
					type="submit"
					form={form}
					onClick={onSubmit}
					disabled={submitDisabled}
				>
					{submitBtnText}
				</button>
			)}
		</div>
	);
}

ModalFooter.propTypes = {
	className: PropTypes.string,
	cancelBtnText: PropTypes.string,
	submitBtnText: PropTypes.string,
	form: PropTypes.string,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	submitDisabled: PropTypes.bool,
};
