import { takeLatest, call, put, select } from "redux-saga/effects";
import { push } from "react-router-redux";

// Actions
import { appendNotifications } from "../../../actions/Notifications";
import { refreshProfile, setUserProfileParam } from "../../../actions/User";

// Constants
import { USERS_SERVICE_API, USER } from "../../../constants/Url";
import { UPDATE_USER_PARENT_CONSENT } from "../../../constants/ActionTypes";

// Utils
import api from "../../../util/api";
import { redirectRoute } from "../../../util/SagaUtils";

function updateParentConsentApi(params) {
	return api
		.post(USERS_SERVICE_API + USER + "/parental-consent", params)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}

export function* updateParentConsentWatcher() {
	yield takeLatest(UPDATE_USER_PARENT_CONSENT, updateParentConsentWorker);
}

function* updateParentConsentWorker(action) {
	let response;
	try {
		response = yield call(updateParentConsentApi, action.params);
		const isSuccess =
			(response.status >= 200 && response.status < 300) || response.status === 422;
		if (isSuccess) {
			yield put(setUserProfileParam("parental_consent_provided", true));
			yield put(refreshProfile("onboarded"));

			const userProfile = yield select((state) => state.user.profile);
			const nextPage = redirectRoute(userProfile);
			yield put(push(nextPage));
		} else {
			yield put(appendNotifications("An error occured"));
			throw response;
		}
	} catch (e) {
		console.error(e);
		if (response) {
			yield put(appendNotifications(response));
			yield put(push("/"));
		}
	}
}
