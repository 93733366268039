import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { FormFieldSelect } from '../../../../components/FormFields';
import { ACTIVE_PERIODS } from "../../../../constants/Rewards";
import { updateRewards } from '../../../../actions/Rewards';
import "../styles.scss";

class ActivePeriodDialog extends React.Component {
    constructor(props) {
        super(props);
    }   

    componentWillMount() {
        this.setState({
            active_period: this.props.active_period
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            active_period: nextProps.active_period,
        })
    }

    handleSubmit = () => {
        this.props.updateRewards({
            id: this.props._id,
            params: {
                active_period: this.state.active_period
            }
        });
    };

    handleClose() {
        this.setState({
            active_period: this.props.active_period
        });
    }

    checkValidation() {
        return (
            this.state.active_period == this.props.active_period
        )
    }

    handleInputChange(event) {
        this.setState({ active_period: event.target.value });
    }

    render() {
        return (
            <Modal
                className="admin"
                triggerBtnText="Change"
                triggerBtnClassName="button button--secondary button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Save"
                submitDisabled={this.checkValidation()}
                onSubmit={() => this.handleSubmit()}
                onClose={() => this.handleClose()}
            >
                <FormFieldSelect
                    label={"Active Period"}
                    name={"active_period"}
                    value={this.state.active_period}
                    options={ACTIVE_PERIODS}
                    onChange={(event) => this.handleInputChange(event)}
                />                
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateRewards: (payload) => dispatch(updateRewards(payload))
    }
}

const mapStateToProps = state => {
    return {

    }
}

ActivePeriodDialog = connect(mapStateToProps, mapDispatchToProps)(ActivePeriodDialog);

export default ActivePeriodDialog