import { setPotentialRewards } from "../../actions/Rewards";

import { showLoader, hideLoader } from "../../actions/GlobalAction";

import { takeLatest, call, put } from "redux-saga/effects";

import {
	// if you want to redirect post saga action
	push,
} from "react-router-redux";

import { FETCH_SHOPIFY_ITEMS_WATCHER } from "../../constants/ActionTypes";
import { SHOPIFY_SERVICE_FETCH_ITEMS } from "../../constants/Url";

import api from "../../util/api";

function fetchShopifyItemsApi(conditions = []) {
	return api.get(SHOPIFY_SERVICE_FETCH_ITEMS, {
		conditions,
	});
}

export function* fetchShopifyItemsWatcher() {
	yield takeLatest(FETCH_SHOPIFY_ITEMS_WATCHER, fetchShopifyItemsWorker);
}

function* fetchShopifyItemsWorker(action) {
	try {
		yield put(showLoader());
		// data is obtained after axios call is resolved
		let response = yield call(fetchShopifyItemsApi, action.payload);
		if (response.status >= 200 && response.status < 300) {
			yield put(setPotentialRewards(response.data.docs));
		} else {
			throw response;
		}

		yield put(hideLoader());
	} catch (e) {
		console.log(e);
		yield put(hideLoader());
	}
}
