import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Paper } from "@material-ui/core";
import { formatDate } from "../../../util/DateUtil";
import { getIdFromUrl } from "../../../util/WindowUtils";
import { titleCase, formatNumber, sanitizeHtml } from "../../../util/stringUtils";
import Loader from "../../../components/Spinner";
import { FULFILMENT_OPTIONS, MARKETPLACES, ACTIVE_PERIOD } from "../../../constants/Rewards";

// Dialogs
import RewardSummaryDialog from "./RewardsDialogs/RewardSummaryDialog";
import ArchiveDialog from "./RewardsDialogs/ArchiveDialog";
import RestoreDialog from "./RewardsDialogs/RestoreDialog";
import DescriptionDialog from "./RewardsDialogs/DescriptionDialog";
import CategoryModal from "./RewardsDialogs/CategoryModal";
import AcquisitionMethodDialog from "./RewardsDialogs/AcquisitionMethodDialog";
import TriggerDialog from "./RewardsDialogs/TriggerDialog";
import StatusDialog from "./RewardsDialogs/StatusDialog";
import ActivePeriodDialog from "./RewardsDialogs/ActivePeriodDialog";
import MarketPlaceDialog from "./RewardsDialogs/MarketPlaceDialog";
import StockDialog from "./RewardsDialogs/StockDialog";
import PurchaseCapDialog from "./RewardsDialogs/PurchaseCapDialog";
import AgeRestrictionDialog from "./RewardsDialogs/AgeRestrictionDialog";
import CustomerServiceLevelDialog from "./RewardsDialogs/CustomerServiceLevelDialog";
import SkuDialog from "./RewardsDialogs/SkuDialog";
import UrlSlugModal from "./RewardsDialogs/UrlSlugModal";
import FaqDialog from "./atoms/FaqDialog";

import "./styles.scss";
import { fetchRewards } from "../../../actions/Rewards";
import { createRewardFaqs, fetchRewardFaqs } from "../../../actions/RewardFaqs";

const NOFAQRESULT = { reward: null };

class RewardsDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hideContext: true,
			rewardFaqs: undefined,
		};
	}

	componentDidMount() {
		const { reward, fetchRewards, fetchRewardFaqs } = this.props;
		if (!reward) {
			fetchRewards({ detailed: true });
		}

		fetchRewardFaqs(
			{
				reward_id: getIdFromUrl(),
			},
			{
				success: {
					callback: (rewardFaqsResult) => {
						this.setState({ rewardFaqs: rewardFaqsResult });
					},
				},
				failure: {
					callback: (rewardFaqsResult) => {
						if (rewardFaqsResult.status === 404) {
							this.setState({ rewardFaqs: NOFAQRESULT });
						}
					},
				},
			}
		);
	}

	handleFaqCreate = () => {
		const { createRewardFaqs } = this.props;
		const actionParams = {
			reward: getIdFromUrl(),
			questions: [],
		};

		createRewardFaqs(actionParams, {
			success: {
				callback: (rewardFaqsResult) => {
					this.setState({ rewardFaqs: rewardFaqsResult });
				},
			},
			failure: {
				callback: () => null,
			},
		});
	};

	onCloseFaqDialog = (dialogRewardFaqs) => {
		this.setState((prevState) => ({
			rewardFaqs: {
				...prevState.rewardFaqs,
				questions: dialogRewardFaqs,
			},
		}));
	};

	backToRewardsList = () => {
		this.props.history.push("/app/servicesettings/rewards");
	};

	timeRemaining = (futureDate) => {
		let remaining_period = "";
		if (futureDate) {
			let date_future = new Date(futureDate);
			let date_now = new Date();
			let seconds = Math.floor((date_future - date_now) / 1000);
			let minutes = Math.floor(seconds / 60);
			let hours = Math.floor(minutes / 60);
			let days = Math.floor(hours / 24);

			hours = hours - days * 24;
			minutes = minutes - days * 24 * 60 - hours * 60;

			remaining_period = "Days: " + days + " Hours: " + hours + " Minutes: " + minutes;
		} else {
			remaining_period = "INFINITE";
		}
		return remaining_period;
	};

	handleContextClick() {
		this.setState({
			hideContext: !this.state.hideContext,
		});
	}

	// Passing HTML string as object as layed out here: https://reactjs.org/docs/dom-elements.html
	renderSanitizedHtml(htmlString) {
		return { __html: sanitizeHtml(htmlString) };
	}

	render() {
		if (this.props.reward) {
			return (
				<div className="m-3 RewardsDetails admin">
					<div className="options-bar">
						<span className="options-back" onClick={() => this.backToRewardsList()}>
							<button className="button button--borderless">
								<i className="button__icon zmdi zmdi-chevron-left zmdi-hc-lg" />{" "}
								Back
							</button>
						</span>
					</div>
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableRow>
								<TableCell
									className="data-table__data-cell data-table__data-cell--main"
									width="90%"
								>
									<div className="data-table__multi-line">
										<img
											className="data-table__inline-image"
											src={
												this.props.reward.images !== undefined
													? this.props.reward.images["cover"]
													: require("../../../svg/icon-placeholder.svg")
											}
											alt="Placeholder Icon"
										/>
										<div>
											<span className="data-table__item-title">
												{this.props.reward.name}
											</span>
											<br />
											<span className="data-table__item-sub-title">
												{formatNumber(this.props.reward.point_count)} Points
											</span>
										</div>
									</div>
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<RewardSummaryDialog
										_id={this.props.reward._id}
										name={this.props.reward.name}
										point_count={this.props.reward.point_count}
										cover_image_url={this.props.reward.images["cover"]}
									/>
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<button
										className="button button--circle button--borderless"
										onClick={() => this.handleContextClick()}
									>
										<img
											className="button__image"
											src={require("../../../svg/ellipsis-vertical.svg")}
											alt="Vertical Ellipsis Icon"
										/>
									</button>
									{!this.state.hideContext && (
										<Paper className="context">
											{this.props.reward.archive_date !== null &&
											this.props.reward.archive_date !== undefined ? (
												<RestoreDialog _id={this.props.reward._id} />
											) : (
												<ArchiveDialog _id={this.props.reward._id} />
											)}
										</Paper>
									)}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<span className="data-table__note">
						Added on {formatDate(this.props.reward.date_created)}
					</span>
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableRow>
								<TableCell
									className="data-table__data-cell data-table__data-cell--long"
									width="30%"
								>
									Url Slug
								</TableCell>
								<TableCell
									className="data-table__data-cell description"
									width="65%"
								>
									{this.props.reward.url_slug}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<UrlSlugModal
										rewardId={this.props.reward._id}
										currentSlug={this.props.reward.url_slug}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableRow>
								<TableCell
									className="data-table__data-cell data-table__data-cell--long"
									width="30%"
								>
									Description
								</TableCell>
								<TableCell
									className="data-table__data-cell description"
									width="65%"
								>
									<div
										dangerouslySetInnerHTML={this.renderSanitizedHtml(
											this.props.reward.description
										)}
									></div>
								</TableCell>
								<TableCell className="data-table__data-cell data-table__data-cell--long" width="5%">
									<DescriptionDialog
										_id={this.props.reward._id}
										description={this.props.reward.description}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Item Type
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{this.props.reward.category
										? titleCase(this.props.reward.category)
										: "Not Set"}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<CategoryModal
										rewardId={this.props.reward._id}
										rewardType={this.props.reward.type}
										currentCategory={this.props.reward.category}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="data-table__data-cell data-table__data-cell--long" width="30%">
									FAQs
								</TableCell>
								<TableCell className="data-table__data-cell faqs" width="65%">
									{(this.state.rewardFaqs !== undefined && this.state.rewardFaqs !== NOFAQRESULT)
										? this.state.rewardFaqs.questions.map((faq) => {
												return (
													<React.Fragment key={faq._id}>
														<h3>{faq.question}</h3>
														<div
														dangerouslySetInnerHTML={{
															__html: sanitizeHtml(faq.answer),
														}}/>
													</React.Fragment>
												);
										  })
										: "Not Set"}
								</TableCell>
								<TableCell className="data-table__data-cell data-table__data-cell--long" width="5%">
									{this.state.rewardFaqs === undefined
										? "Not Set"
										: this.state.rewardFaqs === NOFAQRESULT
											?<button
													type="button"
													className={"button button--secondary button--borderless"}
													onClick={this.handleFaqCreate}
											>
												Create
											</button>
											: <FaqDialog
												onClose={this.onCloseFaqDialog}
												reward_id={this.props.reward._id}
												rewardFaqs={this.state.rewardFaqs}
												triggerButtonText="Manage"
												triggerButtonClassName="faqs_button button button--secondary button--borderless"
											/>
									}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Acquisition Method
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{titleCase(this.props.reward.acquisition_method)}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<AcquisitionMethodDialog
										_id={this.props.reward._id}
										acquisition_method={this.props.reward.acquisition_method}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Trigger
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{titleCase(this.props.reward.trigger_method)}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<TriggerDialog
										_id={this.props.reward._id}
										trigger_methods={[this.props.reward.trigger_method]}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Status
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{this.props.reward.is_active
										? `Active (${this.timeRemaining(
												this.props.reward.expiration
										  )})`
										: "Inactive"}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<StatusDialog
										_id={this.props.reward._id}
										is_active={this.props.reward.is_active}
										expiration={this.props.reward.expiration}
										active_period={this.props.reward.active_period}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Active Period
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{ACTIVE_PERIOD[this.props.reward.active_period]}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<ActivePeriodDialog
										_id={this.props.reward._id}
										active_period={this.props.reward.active_period}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Marketplace
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{MARKETPLACES[this.props.reward.market_place]}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<MarketPlaceDialog
										_id={this.props.reward._id}
										market_place={this.props.reward.market_place}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Available Stock
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{this.props.reward.stock != null
										? this.props.reward.stock
										: "Infinite"}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<StockDialog
										_id={this.props.reward._id}
										stock={this.props.reward.stock}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Purchase Cap
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{this.props.reward.purchase_cap}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<PurchaseCapDialog
										_id={this.props.reward._id}
										purchase_cap={this.props.reward.purchase_cap}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Age Restriction
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{titleCase(this.props.reward.age_registration)}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<AgeRestrictionDialog
										_id={this.props.reward._id}
										age_registration={this.props.reward.age_registration}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Customer Service Level
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{this.props.reward.fulfillment === FULFILMENT_OPTIONS.SHOPIFY
										? "1. Automatic Fulfillment"
										: "2. Manual Fulfillment"}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<CustomerServiceLevelDialog
										_id={this.props.reward._id}
										fulfillment={this.props.reward.fulfillment}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Item SKU/Reference
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{this.props.reward.itemSku}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<SkuDialog
										_id={this.props.reward._id}
										itemSku={this.props.reward.itemSku}
									/>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</div>
			);
		} else {
			return <Loader />;
		}
	}
}

RewardsDetails.propTypes = {
	reward: PropTypes.object,
	history: PropTypes.object,
	fetchRewards: PropTypes.func,
	fetchRewardFaqs: PropTypes.func,
	createRewardFaqs: PropTypes.func,
};

const mapStateToProps = (state) => ({
	reward: state.rewards.bank.find((r) => r._id === getIdFromUrl()),
});

const mapDispatchToProps = (dispatch) => ({
	fetchRewards: (params, options) => dispatch(fetchRewards(params, options)),
	fetchRewardFaqs: (params, options) => dispatch(fetchRewardFaqs(params, options)),
	createRewardFaqs: (params, options) => dispatch(createRewardFaqs(params, options)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RewardsDetails);
