import api from '../../util/api';
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import {
    push,
} from "react-router-redux";

import {
    UPDATE_ACHIEVEMENT_WATCHER
} from "../../constants/ActionTypes";
import {
    UPDATE_ACHIEVEMENT_URL
} from "../../constants/Url";

import {
    fetchAchievement
} from "../../actions/Achievements";
import {
	appendNotifications,
} from "../../actions/Notifications";
import {
	addMessage,
} from "../../actions/Messages";

import {
	SUCCESS,
	ERROR,
} from "../../constants/Notifications";;


function achievementUpdateApi(payload) {
    const {
        id
    } = payload;
    let token = window.browserStorage.getJWT();
    return (
        api
        .post(UPDATE_ACHIEVEMENT_URL(id), {
            ...payload.params,
            id
        }, {
            token
        })
    );
};

export function* updateAchievementWatcher() {
    yield takeLatest(UPDATE_ACHIEVEMENT_WATCHER, updateAchievementWorker);
};

function* updateAchievementWorker(action) {
    try {
        let response = yield call(achievementUpdateApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		
		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (options?.notification) {
			yield put(appendNotifications({
				type: isSuccess ? SUCCESS : ERROR,
				message: options.notification,
			}));
		};
		if (options?.message) {
			yield put(addMessage({
				source: UPDATE_ACHIEVEMENT_WATCHER,
				message: typeof options.message === "string" ? options.message : response?.data,
				isError: isSuccess,
			}));
		};
		if (options?.callback) {
            yield call(options.callback, response);
		};
		if (options?.redirect) {
			yield put(push(options.redirect));
		};
		if (isSuccess) {
            yield put(fetchAchievement(response.data.data));
        };
    } catch (e) {
        console.error(e);
    };
};