import { all } from "redux-saga/effects";

import { fetchRewardsWatcher } from "./FetchRewardsSagas";
import { fetchRewardDetailsWatcher } from "./FetchRewardDetailsSagas";
import { createRewardWatcher } from "./CreateRewardSagas";
import { fetchShopifyItemsWatcher } from "./FetchShopifyItemsSagas";
import { purchaseRewardWatcher } from "./PurchaseRewardSagas";
import { searchRewardsWatcher } from "./SearchRewardSaga";
import { updateRewardsWatcher } from "./updateRewardSagas";
import { fetchRelatedRewardsWatcher } from "./FetchRelatedRewadsSagas";

export function* rewardsSagas() {
	yield all([
		fetchRewardsWatcher(),
		fetchRewardDetailsWatcher(),
		fetchRelatedRewardsWatcher(),
		createRewardWatcher(),
		fetchShopifyItemsWatcher(),
		purchaseRewardWatcher(),
		searchRewardsWatcher(),
		updateRewardsWatcher(),
	]);
}
