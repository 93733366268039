import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import LpHeading from "../../../components/LpHeading";
import { RangeDatePicker } from "../../../components/DatePickers";
import { COLLECTIONS } from "../../../constants/PaginationData";

import "./styles.scss";

import { fetchBanUsers } from "../../../actions/UserAdminPanel";

class Bans extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			rowsPerPageOptions: COLLECTIONS.ROW_PER_PAGE_OPTIONS,
			page: COLLECTIONS.page,
			limit: COLLECTIONS.defaultRowPerPage,
			count: COLLECTIONS.count,
			dateStart: new Date() - 1000 * 60 * 60 * 24 * 30,
			dateEnd: new Date(),
			searchQuery: "",
		};
	}
	componentDidMount() {
		this.fetchBanUsers();
	}

	fetchBanUsers = () => {
		const { page, limit, searchQuery, dateEnd, dateStart } = this.state;

		this.props.fetchBanUsers({
			page: page + 1,
			limit: limit,
			search: searchQuery,
			dateRange: {
				to: dateEnd,
				from: dateStart,
			},
		});
	};

	handleChangePage = (event, newPage) => {
		if (this.state.page !== newPage) {
			this.setState(
				{
					page: newPage,
				},
				this.fetchBanUsers
			);
		}
	};

	handleChangeRowsPerPage = (event) => {
		if (event.target.value) {
			this.setState(
				{
					page: 0,
					limit: event.target.value,
				},
				this.fetchBanUsers
			);
		}
	};

	handleRangeDatePickerChange = (dates) => {
		this.setState(
			{
				dateStart: new Date(dates.dateStart),
				dateEnd: new Date(dates.dateEnd),
			},
			this.fetchBanUsers
		);
	};

	RedirectToUserPage = (event) => {
		if (event.target.value) {
			console.log("RedirectToUserPage");
		}
	};

	searchTextChanged = (e) => {
		this.setState(
			{
				searchQuery: e.target.value,
			},
			this.fetchBanUsers
		);
	};

	tablePagination() {
		const { banCount } = this.props;
		const { limit, rowsPerPageOptions, page } = this.state;

		if (banCount > limit)
			return (
				<TablePagination
					className="float-right"
					component="div"
					count={banCount}
					rowsPerPage={limit}
					rowsPerPageOptions={rowsPerPageOptions}
					page={page}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
					classes={{
						select: "pagination-select",
					}}
				/>
			);
	}

	getDate = (isoDate) => {
		let date = new Date(isoDate).toLocaleDateString().split("/");
		let month = date[0].padStart(2, "0");
		let day = date[1].padStart(2, "0");
		let year = date[2];
		return `${year}-${month}-${day}`;
	};

	getTime = (isoDate) => {
		let time = new Date(isoDate).toLocaleTimeString().split(":");
		let hour = time[0].padStart(2, "0");
		let minute = time[1].padStart(2, "0");
		let second = time[2].padStart(5, "0");
		return `${hour}:${minute}:${second}`;
	};

	showStaffUser = (is_active, banned_by_user, unbanned_by_user) => {
		return is_active ? banned_by_user : unbanned_by_user;
	};

	showCurrentBanStatusIcon = (isActive) => {
		let icon = isActive ? "fa fa-lock" : "fa fa-unlock";
		return <i className={icon + " pr-2"}></i>;
	};

	render() {
		const { dateStart, dateEnd } = this.state;

		return (
			<React.Fragment>
				<div className="container m-3 p-3 logs admin">
					<div>
						<LpHeading>Bans</LpHeading>
					</div>
					<div className="d-flex">
						<div className="form-group col-4 p-0">
							<label htmlFor="name" className="level-text">
								Search by User, Staff member, or IP{" "}
							</label>
							<input
								className="form-control"
								type="text"
								placeholder="Search"
								aria-label="Search"
								onChange={this.searchTextChanged}
							></input>
						</div>
						<div className="col-4 dates-div">
							<label htmlFor="name" className="level-text">
								Dates
							</label>
							<RangeDatePicker
								dateStart={dateStart}
								dateEnd={dateEnd}
								onChange={this.handleRangeDatePickerChange}
							/>
						</div>
						<div className="col-4 pt-2">
							{this.tablePagination()}
						</div>
					</div>
					<table className="data-table">
						<thead>
							<tr>
								<th className="data-table__header" width="25%">
									Date
								</th>
								<th className="data-table__header" width="25%">
									Email or IP
								</th>
								<th className="data-table__header" width="50%">
									Reporter/Reason
								</th>
							</tr>
						</thead>
						<tbody>
							{this.props.ban_history.map((item) => {
								return (
									<tr key={item.id}>
										<td
											className="data-table__data-cell"
											width="25%"
										>
											{`${this.getDate(
												item.banned_at
											)} | ${this.getTime(
												item.banned_at
											)}`}
										</td>
										<td
											className="data-table__data-cell"
											width="25%"
										>
											{item.banned_user}
											{item.associatedUser ? (
												<a
													className="primary-link"
													href=""
												>
													{" "}
													({item.banned_user})
												</a>
											) : null}
										</td>
										<td
											className="data-table__data-cell reporter-reason"
											width="50%"
										>
											{this.showCurrentBanStatusIcon(
												item.is_active
											)}{" "}
											{item.ban_reason} -{" "}
											{this.showStaffUser(
												item.is_active,
												item.banned_by_user,
												item.unbanned_by_user
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>

					<div>{this.tablePagination()}</div>
					<div className="data-table__legend">
						<span className="fa fa-lock pr-2" />
						{"/ "}
						<span className="fa fa-unlock pr-2" />
						User or IP was banned / unbanned
					</div>
				</div>
			</React.Fragment>
		);
	}
}

Bans.propTypes = {
	banCount: PropTypes.number,
	ban_history: PropTypes.arrayOf(PropTypes.object).isRequired,
	fetchBanUsers: PropTypes.func.isRequired,
};

Bans.defaultProps = {
	ban_history: [],
};

const mapStateToProps = (state) => {
	return {
		ban_history: state.user_admin.ban_history,
		banCount: state.user_admin.banCount,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchBanUsers: (params) => dispatch(fetchBanUsers(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Bans);
