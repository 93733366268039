import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "../../../../components/Modal";
import RadioField from "../../../../components/RadioField";
import { BUTTON_TEXT } from "../../../../constants/Events";
import { updateEvent } from "../../../../actions/Events";
import "../styles.scss";

class ChangeUrlButtonTextModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			url_button_text: "",
		};
	}

	componentWillMount() {
		let { url_button_text } = this.props;

		this.setState({
			url_button_text: url_button_text,
		});
	}

	componentWillReceiveProps(nextProps) {
		let { url_button_text } = this.state;

		if (url_button_text !== nextProps.url_button_text) {
			this.setState({
				url_button_text: nextProps.url_button_text,
			});
		}
	}

	handleSubmit = () => {
		let { url_button_text } = this.state;
		let { updateEvent, eventId } = this.props;

		if (url_button_text) {
			updateEvent(
				{
					url_button_text: url_button_text,
				},
				eventId
			);
		}
	};

	render() {
		let { url_button_text } = this.state;

		return (
			<Modal
				className="admin"
				triggerBtnText="Change"
				triggerBtnClassName="button button--borderless"
				cancelBtnText="Cancel"
				submitBtnText="Save"
				submitDisabled={url_button_text === this.props.url_button_text}
				onSubmit={() => this.handleSubmit()}
				onClose={(event) =>
					this.setState({
						url_button_text: this.props.url_button_text,
					})
				}
			>
				<div className="form-field__intro">
					On Team Liquid this URL will sometimes be presented as a button
					with a single word as text. Select the verb you would like
					to use for the URL text.
				</div>
				{BUTTON_TEXT.map((bt) => {
					let bt_lower = bt.toLowerCase();
					let bt_label =
						bt_lower.charAt(0).toUpperCase() + bt_lower.slice(1);
					return (
						<RadioField
							key={"url_button_text_" + bt_lower}
							id={"url_button_text_" + bt_lower}
							name="url_button_text"
							value={bt}
							label={bt_label}
							onChange={(event) =>
								this.setState({
									url_button_text: event.target.value,
								})
							}
							checked={url_button_text === bt}
						/>
					);
				})}
			</Modal>
		);
	}
}

ChangeUrlButtonTextModal.propTypes = {
	url_button_text: PropTypes.oneOf(BUTTON_TEXT),
	updateEvent: PropTypes.func.isRequired,
	eventId: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateEvent: (params, id) => dispatch(updateEvent(params, id)),
	};
};

export default connect(null, mapDispatchToProps)(ChangeUrlButtonTextModal);
