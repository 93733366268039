import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { setQuestTranslationStatus, setQuestTranslation } from "../../actions/QuestTranslations";

// Util
import api from "../../util/api";

// Constants
import { FETCH_QUEST_TRANSLATIONS_STATUS_WATCHER } from "../../constants/ActionTypes";
import { FETCH_QUEST_TRANSLATIONS_STATUS_URL } from "../../constants/Url";

export function* fetchQuestTranslationsStatusWatcher() {
	yield takeLatest(FETCH_QUEST_TRANSLATIONS_STATUS_WATCHER, fetchQuestTranslationsStatusWorker);
}

function* fetchQuestTranslationsStatusWorker(action) {
	try {
		const response = yield call(fetchQuestTranslationsStatusApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (isSuccess) {
			yield put(setQuestTranslation([]));
			yield put(setQuestTranslationStatus(response.data.data));
			if (options?.callback) yield call(options.callback, response);
		} else {
			throw response;
		}
	} catch (e) {
		console.error(e);
	}
}

function fetchQuestTranslationsStatusApi(questId) {
	return api.get(FETCH_QUEST_TRANSLATIONS_STATUS_URL(questId));
}
