import {
    SET_EVENT,
    SET_RECENT_EVENTS,
} from "../constants/ActionTypes";

const INIT_STATE = {
    event: {},
    recent_events: [],
    events_count: 0
};

export default function EventsReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case SET_EVENT: {
            return {
                ...state,
                event: action.payload
            }
        }
        case SET_RECENT_EVENTS: {
            return {
                ...state,
                recent_events: action.payload.events,
                events_count: action.payload.count
            }
        }
        default:
            return state;
    }
}