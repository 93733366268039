import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isMobile } from "react-device-detect";
import "./styles/index.scss";

// Components
import Modal from "./atoms/Modal";
import ModalFooter from "./atoms/ModalFooter";

class LpModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			fadeOut: false,
			isHidden: false,
			isBodyScrollbarPresent: false,
		};
	}

	componentDidMount() {
		this.documentBody = document.getElementById("body");
		this.appBackgroundOverlay = document.getElementById("background-overlay");
		const { open } = this.props;
		if (open) this.open();
	}

	componentDidUpdate(prevProps) {
		const { open, hidden } = this.props;
		const { isBodyScrollbarPresent, isOpen } = this.state;
		const bodyScrollbarWidth = this.bodyRef?.clientWidth < this.bodyRef?.offsetWidth;

		if (open !== prevProps.open) {
			if (open) this.open();
			else if (isOpen) this.close();
		}

		if (hidden !== prevProps.hidden) {
			if (hidden) {
				this.fadeOut(() => this.setState({ isHidden: hidden, fadeOut: false }));
			} else {
				this.disableBackgroundScrolling();
				this.setState({ isHidden: hidden });
			}
		}

		if (!bodyScrollbarWidth && isBodyScrollbarPresent) {
			this.setState({ isBodyScrollbarPresent: false });
		}
	}

	disableBackgroundScrolling() {
		if (this.documentBody) {
			if (this.documentBody?.style.paddingRight === "") {
				this.documentBody.style = `overflow: hidden;`;
			}
		}

		if (isMobile && this.appBackgroundOverlay?.style.top === "") {
			this.appBackgroundOverlay.style.top = `-${window.scrollY}px`;
		}
	}

	enableBackgroundScrolling() {
		if (this.documentBody) this.documentBody.style = "";

		if (isMobile && this.appBackgroundOverlay?.style.top) {
			const scrollY = this.appBackgroundOverlay.style.top;
			this.appBackgroundOverlay.style.position = "";
			window.scroll(0, parseInt(scrollY || "0") * -1);
			this.appBackgroundOverlay.style.top = "";
		}
	}

	open() {
		const { onOpen } = this.props;
		this.disableBackgroundScrolling();
		this.setState({ isOpen: true });
		if (onOpen) onOpen();
	}

	fadeOut(callback) {
		this.setState({ fadeOut: true }, () => setTimeout(callback, 200));
	}

	close(callback) {
		this.fadeOut(() => {
			if (document.getElementsByClassName("lp_modal").length <= 1)
				this.enableBackgroundScrolling();
			this.setState({ isOpen: false, fadeOut: false }, callback);
		});
	}

	setBodyScrollbarState(node) {
		this.bodyRef = node;
		const { isBodyScrollbarPresent } = this.state;
		const bodyScrollbarWidth = this.bodyRef?.clientWidth < this.bodyRef?.offsetWidth;

		if (bodyScrollbarWidth && !isBodyScrollbarPresent) {
			this.setState({ isBodyScrollbarPresent: true });
		}
	}

	handleTriggerPush = (e) => {
		e.preventDefault();
		const { onTrigger } = this.props;

		if (onTrigger) onTrigger();
		else this.open();
	};

	handleClose(event, callback) {
		event.preventDefault();
		event.stopPropagation();
		const { open } = this.props;

		if (!open) {
			this.close(() => {
				if (callback) callback(event);
			});
		} else if (callback) {
			callback(event);
		}
	}

	render() {
		const {
			id,
			className,
			triggerCustom,
			triggerBtnText,
			triggerBtnClassName,
			triggerDisabled,
			backdrop,
			backdropClassName,
			form,
			formRef,
			children,
			cancelBtnText,
			submitBtnText,
			submitDisabled,
			onOpen,
			wide,
			onClose,
			onSubmit,
		} = this.props;
		const { isOpen, fadeOut, isHidden, isBodyScrollbarPresent } = this.state;

		return (
			<>
				{(triggerCustom || triggerBtnText) && (
					<button
						className={triggerBtnClassName}
						type="button"
						onClick={this.handleTriggerPush}
						disabled={triggerDisabled}
					>
						{triggerCustom || triggerBtnText}
					</button>
				)}

				{isOpen
					? ReactDOM.createPortal(
							<Modal
								id={id}
								containerClassName={classnames({
									"lp_modal__container--wide": wide,
									[className]: className,
								})}
								backdrop={backdrop === false ? false : true}
								backdropClassName={backdropClassName}
								onOpen={onOpen}
								fadeOut={fadeOut}
								hidden={isHidden}
								onClose={(e) => this.handleClose(e, onClose)}
							>
								<div
									className={classnames("lp_modal__body", {
										"lp_modal__body--scrollbar-present": isBodyScrollbarPresent,
									})}
									ref={(ref) => this.setBodyScrollbarState(ref)}
								>
									{form ? (
										<form
											id={form}
											className={"lp_modal__form"}
											ref={formRef}
											onSubmit={(e) => this.handleClose(e, onSubmit)}
										>
											{children}
										</form>
									) : (
										<>{children}</>
									)}
								</div>

								{(cancelBtnText || submitBtnText) && (
									<ModalFooter
										className="lp_modal__footer"
										cancelBtnText={cancelBtnText}
										submitBtnText={submitBtnText}
										form={form}
										onClose={(e) => this.handleClose(e, onClose)}
										onSubmit={
											form ? null : (e) => this.handleClose(e, onSubmit)
										}
										submitDisabled={submitDisabled}
									/>
								)}
							</Modal>,
							document.getElementById("lp_theme")
					  )
					: null}
			</>
		);
	}
}

LpModal.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	form: PropTypes.string, // Wraps children with a from element when used
	formRef: PropTypes.node,
	children: PropTypes.node,
	backdrop: PropTypes.bool, // Set to false to remove backdrop
	backdropClassName: PropTypes.string, //Classname applied to backdrop
	// Trigger button not rendered if triggerCustom and triggerBtnText not set
	triggerCustom: PropTypes.elementType, // Custom trigger; gets wrapped in button element
	triggerBtnText: PropTypes.string, // Text used on default trigger button
	triggerBtnClassName: PropTypes.string, // className applied to default trigger button
	triggerDisabled: PropTypes.bool, // Disables the trigger button
	// Footer not rendered if cancelBtnText and submitBtnText not set
	cancelBtnText: PropTypes.string, // Text used for default cancel button
	// Cancel button not rendered if cancelBtnText not set
	submitBtnText: PropTypes.string, // Text used for default submit button
	// Submit button not rendered if submitBtnText not set
	submitDisabled: PropTypes.bool, // Disables the default submit button
	wide: PropTypes.bool, // Widen to render wide formfields

	// Used for manual control of modal functionality:
	open: PropTypes.bool, // Manual control of modal display
	hidden: PropTypes.bool, // Used to hide an open modal (does not close)
	onTrigger: PropTypes.func, // Executed when the trigger button is pressed
	onOpen: PropTypes.func, // Executed when the modal is opened
	onClose: PropTypes.func, // Executed when the modal is closed any way other than pushing the submit button
	onSubmit: PropTypes.func, // Executed when the submit button is pushed
};

export default LpModal;
