export const LANGUAGES = [
	{
		defaultLanguage: true,
		label: "English",
		value: "en-US",
		abbrLabel: "EN",
	},
	{
		label: "Português - Brasil",
		value: "pt-BR",
		englishLabel: "Portuguese",
		abbrLabel: "PT-BR",
	},
	{
		label: "Bahasa Indonesia - Indonesia",
		value: "id",
		englishLabel: "Indonesian",
		abbrLabel: "ID-ID"
	}
];

export const COMMON_NAMESPACES = [
	"buttons",
	"common",
	"pagination",
	"formFields",
	"icons",
	"dateTime",
	"labels",
	"notifications",
];
