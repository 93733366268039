import { setShopifyChannelCount } from "../../../actions/Channel";

import { showLoader, hideLoader } from "../../../actions/GlobalAction";

import { takeLatest, call, put } from "redux-saga/effects";

import { FETCH_SHOPIFY_CHANNEL_COUNT } from "../../../constants/ActionTypes";
import { GET_SHOPIFY_CHANNEL_COUNT } from "../../../constants/Url";

import api from "../../../util/api";

export function* fetchShopifyChannelCountWatcher() {
	yield takeLatest(FETCH_SHOPIFY_CHANNEL_COUNT, fetchShopifyCountWorker);
}

function* fetchShopifyCountWorker() {
	try {
		yield put(showLoader());
		// data is obtained after axios call is resolved
		let response = yield call(fetchShopifyChannelCountApi);
		if (response.status >= 200 && response.status < 300) {
			const payload = {
				total: response.data.data,
			};
			yield put(setShopifyChannelCount(payload));
		} else {
			throw response;
		}
		yield put(hideLoader());
	} catch (e) {
		console.log(e);
		yield put(hideLoader());
	}
}

function fetchShopifyChannelCountApi() {
	return api.get(GET_SHOPIFY_CHANNEL_COUNT);
}
