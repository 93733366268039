import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { appendNotifications } from "../../actions/Notifications";
import { setCampaignTranslation } from "../../actions/CampaignTranslations";

// Util
import api from "../../util/api";

// Constants
import { ERROR } from "../../constants/Notifications";
import { FETCH_CAMPAIGN_TRANSLATIONS_WATCHER } from "../../constants/ActionTypes";
import { FETCH_CAMPAIGN_TRANSLATIONS_URL } from "../../constants/Url";

export function* fetchCampaignTranslationsWatcher() {
	yield takeLatest(FETCH_CAMPAIGN_TRANSLATIONS_WATCHER, fetchCampaignTranslationsWorker);
}

function* fetchCampaignTranslationsWorker(action) {
	try {
		const response = yield call(fetchCampaignTranslationsApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (isSuccess) {
			yield put(setCampaignTranslation(response.data.data));
			if (options?.callback) yield call(options.callback, response);
		} else {
			throw response;
		}
	} catch (e) {
		const { error } = e.response.data;
		yield put(
			appendNotifications({
				type: ERROR,
				message: error,
			})
		);
	}
}

function fetchCampaignTranslationsApi({ experienceId, lang }) {
	return api.get(FETCH_CAMPAIGN_TRANSLATIONS_URL(experienceId), { params: { lang } });
}
