// Redux
import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";

// Actions
import { appendNotifications } from "../../actions/Notifications.js";
import { addMessage } from "../../actions/Messages.js";

// Constants
import { RESEND_REGISTRATION_EMAIL } from "../../constants/ActionTypes.js";
import { RESEND_REGISTRATION_EMAIL_URL } from "../../constants/Url.js";
import { ERROR, SUCCESS } from "../../constants/Notifications.js";

// Axios API
import api from "../../util/api.js";

export function* ResendRegistrationEmailWatcher() {
	yield takeLatest(RESEND_REGISTRATION_EMAIL, ResendRegistrationEmailWorker);
}

function checkStatus(response) {
	return response.status >= 200 && response.status < 300;
}

function* ResendRegistrationEmailWorker(action) {
	try {
		const response = yield call(ResendRegistrationEmailApi, action.params);
		const isSuccess = checkStatus(response);
		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		console.dir(response);
		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: response.status === 400 ? response.data.message : options.notification,
				})
			);
		}
		if (options?.message) {
			yield put(
				addMessage({
					source: RESEND_REGISTRATION_EMAIL,
					message: response?.error,
					isError: isSuccess,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, response);
		}
		if (options?.redirect) {
			yield put(push(options.redirect));
		}
	} catch (e) {
		console.log(e);
	}
}

function ResendRegistrationEmailApi(params) {
	return api
		.post(RESEND_REGISTRATION_EMAIL_URL, {
			...params,
		})
		.catch((err) => {
			return err.response;
		});
}
