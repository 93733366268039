import {
	CREATE_CAMPAIGNS_WATCHER,
	FETCH_CAMPAIGNS_WATCHER,
	FETCH_PUBLIC_CAMPAIGNS_WATCHER,
	UPDATE_CAMPAIGN_WATCHER,
	SET_CAMPAIGNS_BANK,
	SET_UNAUTHORIZED_CAMPAIGN,
	ENTER_CAMPAIGN_WATCHER,
	FETCH_PUBLIC_CAMPAIGN_WATCHER,
	SET_PUBLIC_CAMPAIGN,
} from "../constants/ActionTypes";

export const createCampaigns = (campaigns) => {
	return {
		type: CREATE_CAMPAIGNS_WATCHER,
		payload: campaigns,
	};
};

export const fetchCampaigns = (searchFields) => {
	return {
		type: FETCH_CAMPAIGNS_WATCHER,
		payload: searchFields,
	};
};

export const fetchPublicCampaigns = (options) => {
	return {
		type: FETCH_PUBLIC_CAMPAIGNS_WATCHER,
		options: options,
	};
};

export const updateCampaign = (update) => {
	return {
		type: UPDATE_CAMPAIGN_WATCHER,
		payload: update,
	};
};

export const setCampaignsBank = (campaigns) => {
	return {
		type: SET_CAMPAIGNS_BANK,
		payload: campaigns,
	};
};

export const setUnauthorizedCampaign = (campaign) => {
	return {
		type: SET_UNAUTHORIZED_CAMPAIGN,
		payload: campaign,
	};
};

export const fetchPublicCampaign = (campaignId) => {
	if (campaignId) {
		return {
			type: FETCH_PUBLIC_CAMPAIGN_WATCHER,
			payload: campaignId,
		};
	}
};

export const enterCampaign = (campaignId, options) => {
	if (campaignId) {
		return {
			type: ENTER_CAMPAIGN_WATCHER,
			payload: campaignId,
			options,
		};
	}
};

export const setPublicCampaign = (campaign) => {
	return {
		type: SET_PUBLIC_CAMPAIGN,
		payload: campaign,
	};
};
