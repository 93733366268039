import {
	FETCH_USERS_ACHIEVEMENTS_WATCHER,
	FETCH_ACHIEVEMENTS_DISPLAY_WATCHER,
	UPDATE_ACHIEVEMENTS_DISPLAY_WATCHER,
	SET_ACHIEVEMENTS_DISPLAY_BANK,
	SET_USERS_ACHIEVEMENTS,
} from "../constants/ActionTypes";

export const fetchUsersAchievements = (userId, options) => {
	if (userId) {
		return {
			type: FETCH_USERS_ACHIEVEMENTS_WATCHER,
			payload: userId,
			options,
		};
	}
};

export const fetchAchievementsDisplay = (userId, options) => {
	return {
		type: FETCH_ACHIEVEMENTS_DISPLAY_WATCHER,
		userId,
		options,
	};
};

export const updateAchievementsDisplay = (achievementIds, options) => {
	return {
		type: UPDATE_ACHIEVEMENTS_DISPLAY_WATCHER,
		payload: { achievements: achievementIds },
		options,
	};
};

export const setAchievementsDisplayBank = (achievements) => {
	return {
		type: SET_ACHIEVEMENTS_DISPLAY_BANK,
		payload: achievements,
	};
};

export const setUsersAchievements = (users_achievements) => {
	return {
		type: SET_USERS_ACHIEVEMENTS,
		payload: users_achievements,
	};
};
