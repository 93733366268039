import { all } from "redux-saga/effects";
import { fetchUserPreferredGamesWatcher } from "./FetchUserGameSagas";
import { fetchGamesWatcher } from "./FetchGamesSaga";
import { addGameWatcher } from "./AddGameSaga";
import { archieveOrRestoreGameWatcher } from "./ArchiveRestoreGameSaga";
import { updateGameWatcher } from "./UpdateGameSaga";
import { deleteGameWatcher } from "./DeleteGameSaga";
import { fetchGamesPublicInfoWatcher } from "./FetchGamesPublicInfo";

export function* gamesSagas() {
	yield all([
		fetchUserPreferredGamesWatcher(),
		fetchGamesWatcher(),
		addGameWatcher(),
		archieveOrRestoreGameWatcher(),
		updateGameWatcher(),
		deleteGameWatcher(),
		fetchGamesPublicInfoWatcher(),
	]);
}
