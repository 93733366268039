import React from "react";
import PropTypes from "prop-types";

import AssignBroadcastModeratorDialog from "./AssignBroadcastModeratorDialog";

import { TAB_STATE } from "../constants/Tabs";

const TableActions = ({ disabled = false, tabState, onAssignModerators, ...props }) => {
	return (
		<div>
			{tabState === TAB_STATE.BROADCASTS && (
				<AssignBroadcastModeratorDialog
					disabled={disabled}
					className="button button--colored-admin"
					onConfirm={onAssignModerators}
					{...props}
				/>
			)}
		</div>
	);
};

TableActions.propTypes = {
	disabled: PropTypes.bool,
	tabState: PropTypes.number.isRequired,
	onAssignModerators: PropTypes.func.isRequired,
};

export default TableActions;
