import React from "react";
import PropTypes from "prop-types";
import TLIcon from "../../TLIcon";

function TLIconExclamation({ variant, ...props }) {
	return (
		<TLIcon
			src={require("./svg/icon-exclamation" + (variant ? "-" + variant : "") + ".svg")}
			{...props}
		/>
	);
}

TLIconExclamation.defaultProps = {
	variant: "foundation-11",
	alt: "Exclamation Icon",
	size: "medium",
};

TLIconExclamation.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	alt: PropTypes.string,
	ariaLabel: PropTypes.string,
	role: PropTypes.string,
	variant: PropTypes.oneOf(["foundation-11"]),
	size: PropTypes.oneOf(["small", "medium"]),
};

export default TLIconExclamation;
