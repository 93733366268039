import { setUsersSeasonReview } from "../../actions/UsersSeasonReview";
import { takeLatest, call, put } from "redux-saga/effects";
import { FETCH_USERS_SEASON_REVIEW_BY_NAME_WATCHER } from "../../constants/ActionTypes";
import { FETCH_USERS_SEASON_REVIEW_BY_NAME_URL } from "../../constants/Url";
import api from "../../util/api";

function fetchUsersSeasonReviewByNameApi(payload) {
	return api.post(FETCH_USERS_SEASON_REVIEW_BY_NAME_URL(payload));
}

export function* fetchUsersSeasonReviewByNameWatcher() {
	yield takeLatest(FETCH_USERS_SEASON_REVIEW_BY_NAME_WATCHER, fetchUsersSeasonReviewByNameWorker);
}

function* fetchUsersSeasonReviewByNameWorker(action) {
	try {
		let response = yield call(fetchUsersSeasonReviewByNameApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;

		if (isSuccess) {
			yield put(setUsersSeasonReview(response.data.data));
		}
	} catch (e) {
		if (e.response.status === 404) {
			yield put(setUsersSeasonReview([]));
		} else {
			console.error(e);
		}
	}
}
