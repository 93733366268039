import {
    SET_DISCORD_CHANNEL,
    SET_TWITCH_CHANNEL,
    SET_TWITCH_BROADCASTS,
    SET_TWITCH_EVENTS_SCHEDULE,
    SET_TWITCH_LIVE_STREAMERS,
    SET_TWITTER_CHANNEL,
    SET_REDDIT_CHANNEL,
    SET_DISCORD_CHANNEL_COUNT,
    SET_TWITCH_CHANNEL_COUNT,
    SET_SHOPIFY_CHANNEL_COUNT,
    SET_TWITTER_CHANNEL_COUNT,
    SET_REDDIT_CHANNEL_COUNT
} from "../constants/ActionTypes";

const initialState = {
    data: [{
            id: 1,
            channelName: "Twitch",
            nofChannel: 67,
            url: "/app/servicesettings/channels/twitch"
        },
        {
            id: 2,
            channelName: "Twitter",
            nofChannel: 70,
            url: "/app/servicesettings/channels/twitter"
        },
        {
            id: 3,
            channelName: "Reddit",
            nofChannel: 20,
            url: "/app/servicesettings/channels/reddit"
        },
        {
            id: 4,
            channelName: "Discord",
            nofChannel: 16,
            url: "/app/servicesettings/channels/discord"
        },
        {
            id: 5,
            channelName: "Team Liquid Store",
            nofChannel: 3,
            url: "/app/servicesettings/channels/teamLiquidStore"
        }
    ],

    twitchChannel: {
        total: -1
    },
    twitterChannel: {
        total: -1,
        profileCount: -1,
        hashTagCount: -1
    },
    discordChannel: {
        total: -1
    },
    redditChannel: {
        total: -1,
        subRedditCount: -1,
        postCount: -1
    },
    liquidStoreChannel: {
        total: -1
    },
    twitchData: [],
    twitchBroadcasts: [],
    twitchSchedule: [],
    twitchLive: [],
    twitterData: [],
    discordData: [],
    redditData: {
        subreddits: [],
        posts: []
    }
};

export default function ChannelReducer(state = initialState, action) {
    switch (action.type) {
        case SET_DISCORD_CHANNEL:
            return {
                ...state,
                discordData: action.payload
            };
        case SET_TWITCH_CHANNEL:
            return {
                ...state,
                twitchData: action.payload
            };
        case SET_TWITCH_BROADCASTS:
            return {
                ...state,
                twitchBroadcasts: action.broadcasts,
            };
        case SET_TWITCH_EVENTS_SCHEDULE:
        return {
            ...state,
            twitchSchedule: action.broadcasts,
        };
        case SET_TWITCH_LIVE_STREAMERS:
            return {
                ...state,
                twitchLive: action.broadcasts,
            };
        case SET_TWITTER_CHANNEL:
            return {
                ...state,
                twitterData: action.payload
            };
        case SET_REDDIT_CHANNEL:
            let subreddits = action.payload.filter((entity) => {
                return entity.type === "subreddit";
            });
            let posts = action.payload.filter((entity) => {
                return entity.type === "submission";
            });
            return {
                ...state,
                redditData: {
                    subreddits,
                    posts
                }
            };
        case SET_DISCORD_CHANNEL_COUNT:
            return {
                ...state,
                discordChannel: action.payload
            };
        case SET_TWITCH_CHANNEL_COUNT:
            return {
                ...state,
                twitchChannel: action.payload
            };
        case SET_TWITTER_CHANNEL_COUNT:
            return {
                ...state,
                twitterChannel: action.payload
            };
        case SET_REDDIT_CHANNEL_COUNT:
            return {
                ...state,
                redditChannel: action.payload
            };
        case SET_SHOPIFY_CHANNEL_COUNT:
            return {
                ...state,
                liquidStoreChannel: action.payload
            };

        default:
            return state;
    }
}