import { fetchTwitchData } from "../../../actions/Channel";

import { showLoader, hideLoader } from "../../../actions/GlobalAction";

import { takeLatest, call, put } from "redux-saga/effects";

import { DELETE_TWITCH_SERVER } from "../../../constants/ActionTypes";
import { DELETE_TWITCH_CHANNEL } from "../../../constants/Url";

import api from "../../../util/api";

export function* removeTwitchDataWatcher() {
	yield takeLatest(DELETE_TWITCH_SERVER, removeTwitchDataWorker);
}

function* removeTwitchDataWorker(action) {
	try {
		yield put(showLoader());
		// data is obtained after axios call is resolved
		let response = yield call(removeTwitchDataApi, action.payload);
		if (response.status >= 200 && response.status < 300) {
			yield put(fetchTwitchData());
		} else {
			throw response;
		}
		yield put(hideLoader());
	} catch (e) {
		console.log(e.response);
		yield put(hideLoader());
	}
}

function removeTwitchDataApi(payload) {
	return api.post(DELETE_TWITCH_CHANNEL, {
		name: payload.serverName,
	});
}
