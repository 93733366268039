import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LpModal from "../../../../components/LpModal";
import LpBirthDateInput from "../../../../components/LpBirthDateInput";
import { localeToUTC } from "../../../../util/DateUtil";
import { updateUserBirthday } from "../../../../actions/UserUpdate";

class BirthdayDialog extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			birth_month: "",
			birth_day: "",
			birth_year: "",
		};
	}

	updateBirthDate() {
		let { birth_day, birth_month, birth_year } = this.state;

		let birthDate = localeToUTC(new Date(birth_year, birth_month - 1, birth_day));
		this.props.updateUserBirthday(
			{
				user: this.props.userId,
				birthday: birthDate.toISOString(),
			},
			{
				success: {
					notification: "Successfully updated user birthday.",
					fetchUserProfileForAdminUi: true,
					updateUserProfile: true,
				},
				failure: {
					notification: true,
				},
			}
		);
	}

	fieldsFilled() {
		const { birth_month, birth_day, birth_year } = this.state;
		return (
			birth_month.toString().length === 2 &&
			birth_day.toString().length === 2 &&
			birth_year.toString().length === 4
		);
	}

	birthdayDidNotChange() {
		const { birthday } = this.props;
		const { birth_month, birth_day, birth_year } = this.state;
		const birth_date = new Date(birthday);
		return birth_month === String(birth_date.getMonth() + 1).padStart(2, "0") &&
			birth_day === String(birth_date.getDate()).padStart(2, "0") &&
			parseInt(birth_year) === birth_date.getFullYear()
			? true
			: false;
	}

	handleChangeYear = (year) => {
		this.setState({
			birth_year: year,
		});
	};

	handleChangeMonth = (month) => {
		this.setState({
			birth_month: month,
		});
	};

	handleChangeDay = (day) => {
		this.setState({
			birth_day: day,
		});
	};

	resetBirthDate = () => {
		const { birth_month, birth_day, birth_year } = this.state;
		if (birth_month || birth_day || birth_year) {
			this.setState({
				birth_month: "",
				birth_day: "",
				birth_year: "",
			});
		} else {
			return;
		}
	};

	render() {
		const { birthday, disableChange } = this.props;

		if (!disableChange) {
			return (
				<LpModal
					className="birthday-modal"
					triggerBtnText="Change"
					triggerBtnClassName="button"
					triggerDisabled={disableChange}
					cancelBtnText="Cancel"
					submitBtnText="Save"
					submitDisabled={
						disableChange
							? true
							: this.birthdayDidNotChange() || !this.fieldsFilled()
							? true
							: false
					}
					onSubmit={() => this.updateBirthDate()}
					onClose={() => this.resetBirthDate()}
				>
					<LpBirthDateInput
						initial_value={birthday}
						className="birthdate_input"
						handleChangeYear={this.handleChangeYear}
						handleChangeMonth={this.handleChangeMonth}
						handleChangeDay={this.handleChangeDay}
					/>
				</LpModal>
			);
		}
		return <></>;
	}
}

BirthdayDialog.propTypes = {
	birthday: PropTypes.string,
	disableChange: PropTypes.bool,
};

BirthdayDialog.defaultProps = {
	disableChange: false,
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateUserBirthday: (params, options) => dispatch(updateUserBirthday(params, options)),
	};
};

BirthdayDialog = connect(null, mapDispatchToProps)(BirthdayDialog);

export default BirthdayDialog;
