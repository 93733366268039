import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./styles/styles.scss";

const LpHeading = ({ type, variant, className, children }) => {
	const Title = type;

	return (
		<Title
			className={classnames(
				"lpheading",
				`lpheading--${variant}`,
				className
			)}
		>
			{children}
		</Title>
	);
};

LpHeading.defaultProps = {
	type: "h1",
	variant: "section",
};

LpHeading.propTypes = {
	type: PropTypes.string,
	variant: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default LpHeading;
