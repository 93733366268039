import {
    setValidAchievements
} from "../../actions/Achievements";
import {
    showLoader,
    hideLoader
} from "../../actions/GlobalAction";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import { // if you want to redirect post saga action
    push
} from "react-router-redux";
import {
    FETCH_VALID_ACHIEVEMENTS_WATCHER
} from "../../constants/ActionTypes";
import {
    FETCH_VALID_ACHIEVEMENTS_URL
} from "../../constants/Url";
import api from '../../util/api';

export function* fetchValidAchievementsWatcher() {
    yield takeLatest(FETCH_VALID_ACHIEVEMENTS_WATCHER, fetchValidAchievementsWorker);
};

function* fetchValidAchievementsWorker(action) {
    try {
        let response = yield call(fetchValidAchievementsApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(setValidAchievements(response.data.data));
        } else {
            throw (response);
        }
    } catch (e) {
        console.log(e)
    };
};

function fetchValidAchievementsApi(payload) {
    return (
        api
        .get(FETCH_VALID_ACHIEVEMENTS_URL)
    );
};