import {
	FETCH_USERS_SEASON_REVIEW_WATCHER,
	FETCH_USERS_SEASON_REVIEW_BY_NAME_WATCHER,
	SET_USERS_SEASON_REVIEW,
} from "../constants/ActionTypes";

export const fetchUsersSeasonReview = () => {
	return {
		type: FETCH_USERS_SEASON_REVIEW_WATCHER,
	};
};

export const fetchUsersSeasonReviewByName = (username) => {
	return {
		type: FETCH_USERS_SEASON_REVIEW_BY_NAME_WATCHER,
		payload: username,
	};
};

export const setUsersSeasonReview = (users_season_review) => {
	return {
		type: SET_USERS_SEASON_REVIEW,
		payload: users_season_review,
	};
};
