// Customizer const
export const TOGGLE_COLLAPSED_NAV = "toggle_collapse_menu";
export const DRAWER_TYPE = "drawer_type";
export const FIXED_DRAWER = "fixed_drawer";
export const COLLAPSED_DRAWER = "collapsible";
export const MINI_DRAWER = "mini_drawer";
export const THEME_COLOR = "theme_color";
export const DARK_THEME = "dark_theme";
export const WINDOW_WIDTH = "window-width";
export const SWITCH_LANGUAGE = "switch-language";
export const CHANGE_DIRECTION = "change-direction";

export const CHANGE_NAVIGATION_STYLE = "change-navigation-style";
export const HORIZONTAL_NAVIGATION = "horizontal_navigation";
export const VERTICAL_NAVIGATION = "vertical_navigation";

export const HORIZONTAL_MENU_POSITION = "horizontal_menu_position";
export const ABOVE_THE_HEADER = "above_the_header";
export const INSIDE_THE_HEADER = "inside_the_header";
export const BELOW_THE_HEADER = "below_the_header";

//Contact Module const

export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";

export const FETCH_ALL_CONTACT = "fetch_all_contact";
export const FETCH_ALL_CONTACT_SUCCESS = "fetch_all_contact_success";
export const ADD_FAVOURITE = "add_favourite";
export const ON_CONTACT_SELECT = "on_contact_select";
export const ON_ADD_CONTACT = "on_add_contact";
export const ON_CONTACT_CLOSE = "on_contact_close";
export const ON_FILTER_OPTION_SELECT = "on_filter_option_select";
export const ON_SAVE_CONTACT = "on_save_contact";
export const ON_DELETE_CONTACT = "on_delete_contact";
export const ON_DELETE_SELECTED_CONTACT = "on_delete_selected_contact";
export const FILTER_CONTACT = "filter_contact";
export const GET_ALL_CONTACT = "get_all_contact";
export const GET_UNSELECTED_ALL_CONTACT = "get_unselected_all_contact";
export const ON_ALL_CONTACT_SELECT = "on_all_contact_select";
export const UPDATE_SEARCH_USER = "update_search_user";
export const ON_TOGGLE_DRAWER = "on_toggle_drawer";
export const HANDLE_REQUEST_CLOSE = "handle_request_close";
export const HIDE_CONTACT_LOADER = "hide_contact_loader";

//Auth const

export const SIGNUP_USER_SUCCESS = "signup_user_success";
export const SIGNIN_GOOGLE_USER = "signin_google_user";
export const SIGNIN_GOOGLE_USER_SUCCESS = "signin_google_user_success";
export const SIGNIN_FACEBOOK_USER = "signin_facebook_user";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "signin_facebook_user_success";
export const SIGNIN_TWITTER_USER = "signin_twitter_user";
export const SIGNIN_TWITTER_USER_SUCCESS = "signin_twitter_user_success";
export const SIGNIN_GITHUB_USER = "signin_github_user";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_SUCCESS";
export const SIGNIN_USER = "signin_user";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNOUT_USER = "signout_user";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";
export const INIT_URL = "init_url";
export const NOTIFICATION_TYPE = "notification_type";
export const PASSWORD_AUTHENTICATION = "password_authentication";
export const CHANGE_USER_EMAIL = "email_user_change";

// Chat Module const

export const FETCH_ALL_CHAT_USER = "fetch_all_chat_user";
export const FETCH_ALL_CHAT_USER_CONVERSATION = "fetch_all_chat_user_conversation";
export const FETCH_ALL_CHAT_USER_SUCCESS = "fetch_all_chat_user_success";
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = "fetch_all_chat_user_conversation_success";
export const FILTER_USERS = "filter_users";
export const ON_SELECT_USER = "on_select_user";
export const ON_SHOW_LOADER = "on_show_loader";
export const ON_HIDE_LOADER = "on_hide_loader";
export const USER_RIGHTS = "user_rights";
export const USER_INFO_STATE = "user_info_state";
export const SUBMIT_COMMENT = "submit_comment";
export const UPDATE_MESSAGE_VALUE = "update_message_value";
export const UPDATE_SEARCH_CHAT_USER = "update_search_chat_user";

//// Mail Module const

export const ADD_LABEL = "ADD_LABEL";
export const GET_ALL_MAIL = "get_all_mail";
export const FETCH_ALL_MAIL = "fetch_all_mail";
export const FETCH_ALL_MAIL_SUCCESS = "fetch_all_mail_success";
export const GET_IMPORTANT_MAIL = "get_important_mail";
export const GET_NAV_FILTERS = "get_nav_filters";
export const GET_NAV_LABELS = "get_nav_labels";
export const GET_NAV_FOLDER = "get_nav_folder";
export const GET_READ_MAIL = "get_read_mail";
export const GET_STARRED_MAIL = "get_starred_mail";
export const GET_UNIMPORTANT_MAIL = "get_unimportant_mail";
export const GET_UNREAD_MAIL = "get_unread_mail";
export const GET_UNSELECTED_ALL_MAIL = "get_unselected_all_mail";
export const GET_UNSTARRED_MAIL = "get_unstarred_mail";
export const ON_ALL_MAIL_SELECT = "on_all_mail_select";
export const ON_FOLDER_MENU_ITEM_SELECT = "on_folder_menu_item_select";
export const ON_FOLDER_SELECT = "on_folder_select";
export const ON_IMPORTANT_SELECT = "on_important_select";
export const ON_LABEL_MENU_ITEM_SELECT = "on_label_menu_item_select";
export const GET_MARK_AS_START = "get_mark_as_start";
export const ON_LABEL_SELECT = "on_label_select";
export const ON_MAIL_CHECKED = "on_mail_checked";
export const ON_MAIL_SELECT = "on_mail_select";
export const ON_MAIL_SEND = "on_mail_send";
export const ON_OPTION_MENU_ITEM_SELECT = "on_option_menu_item_select";
export const ON_OPTION_MENU_SELECT = "on_option_menu_select";
export const ON_START_SELECT = "on_start_select";
export const SEARCH_MAIL = "search_mail";
export const ON_DELETE_MAIL = "on_delete_mail";
export const SET_CURRENT_MAIL_NULL = "set_current_mail_null";
export const ON_COMPOSE_MAIL = "on_compose_mail";

//// TO-DO Module const

export const ON_SORTEND = "on_sortend";
export const FETCH_ALL_TODO = "fetch_all_todo";
export const FETCH_ALL_TODO_SUCCESS = "fetch_all_todo-success";
export const FETCH_ALL_TODO_CONVERSATION = "fetch_all_todo_conversation";
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = "fetch_all_todo_conversation_success";
export const SELECT_ALL_TODO = "select_all_todo";
export const GET_ALL_TODO = "get_all_todo";
export const GET_UNSELECTED_ALL_TODO = "get_unselected_all_todo";
export const GET_STARRED_TODO = "get_starred_todo";
export const GET_UNSTARRED_TODO = "get_unstarred_todo";
export const GET_IMPORTANT_TODO = "get_important_todo";
export const GET_UNIMPORTANT_TODO = "get_unimportant_todo";
export const ON_LABEL_UPDATE = "on_label_update";
export const ON_TODO_UPDATE = "on_todo_update";
export const ON_DELETE_TODO = "on_delete_todo";
export const SEARCH_TODO = "search_todo";
export const SHOW_TODOS = "show_todos";
export const GET_TODO_CONVERSATION = "get_todo_conversation";
export const ON_TODO_CHECKED = "on_todo_checked";
export const ON_TODO_ADD = "on_todo_add";
export const ON_TODO_SELECT = "on_todo_select";
export const SET_CURRENT_TODO_NULL = "set_current_todo_null";
export const REMOVE_LABEL = "remove_label";
export const UPDATE_SEARCH = "update_search";

// User Action Types
export const SIGNUP_USER = "signup_user";
export const SIGNUP_NOTIFY = "signup_notify";
export const RESEND_REGISTRATION_EMAIL = "resend_registration_email";
export const FETCH_USER_WATCHER = "fetch_user_watcher";
export const REFRESH_PROFILE_WATCHER = "refresh_profile_watcher";
export const SET_USER_PROFILE = "set_user_profile";
export const UPDATE_USER_PROFILE = "update_user_profile"; // Does not replace the entirety of state.user.profile as opposed to SET_USER_PROFILE. Only mutates a subset of user profile state.
export const SET_USER_PROFILE_PARAM = "set_user_profile_param";
export const FETCH_USER_PROFILE_PARAM_WATCHER = "fetch_user_profile_param_watcher";
export const REFRESH_PROFILE = "refresh_profile";
export const LOGOUT_USER_WATCHER = "logout_user_watcher";
export const CLEAR_USER_PROFILE = "clear_user_profile";
export const UPDATE_USER_WATCHER = "update_user_watcher";
export const UPDATE_USER_WITHOUT_FETCH_WATCHER = "update_user_without_fetch_watcher";
export const VERIFY_USER = "verify_user";
export const GENERATE_TOKEN = "generate_token";
export const GET_REFERRAL_LINK = "get_referral_link";
export const SET_REFERRAL_LINK = "set_referral_link";
export const GET_USER_AVATARS = "get_user_avatars";
export const SET_USER_AVATARS = "set_user_avatars";
export const GET_USER_LIFETIME_POINTS = "get_user_lifetime_points";
export const SET_USER_LIFETIME_POINTS = "set_user_lifetime_points";
export const GET_USER_MEMBERSHIP_POINTS = "get_user_membership_points";
export const SET_USER_MEMBERSHIP_POINTS = "set_user_membership_points";
export const GET_USER_SPENDING_POINTS = "get_user_spending_points";
export const SET_USER_SPENDING_POINTS = "set_user_spending_points";
export const CLAIM_PARTNER_BONUS = "claim_partner_bonus";
export const GET_USER_RANK_UP_PROGRESS = "get_user_rank_up_progress";
export const SET_USER_RANK_UP_PROGRESS = "set_user_rank_up_progress";
export const CHECK_DUPLICATE_USER_NAME = "check_duplicate_user_name";
export const SET_USERNAME_UPDATE_STATUS = "set_username_update_status";
export const SEND_EMAIL_HELP_REQUEST = "send_email_help_request";
export const GET_PROFILE_OVERVIEW = "get_profile_overview";
export const SET_PROFILE_OVERVIEW = "set_profile_overview";
export const SET_EVENT_CHECK_IN_MESSAGE = "set_event_check_in_message";
export const FETCH_USER_PREFERRED_GAMES_WATCHER = "fetch_user_preferred_games_watcher";
export const SET_USER_PREFERRED_GAMES_WATCHER = "set_user_preferred_games_watcher";
export const FETCH_USER_PREFERRED_PLAYERS_WATCHER = "fetch_user_preferred_players_watcher";
export const SET_USER_PREFERRED_PLAYERS_WATCHER = "set_user_preferred_players_watcher";
export const FETCH_SEASONS = "fetch_seasons";
export const SET_SEASONS = "set_seasons";
export const REQUEST_DELETE_ACCOUNT = "request_delete_account";
export const DELETE_ACCOUNT = "delete_account";
export const VERIFY_AND_UPDATE_EMAIL_ID_CHANGE_REQUEST =
	"verify_and_update_email_id_change_request";
export const CHANGE_LANGUAGE = "change_language";

// User Action Types for Admin Panel
export const FETCH_TOTAL_USERS_WATCHER = "fetch_total_users_watcher";
export const SET_TOTAL_USERS = "set_total_user";
export const FETCH_SEARCHED_USERS_WATCHER = "fetch_searched_users_watcher";
export const SET_SEARCHED_USERS = "set_searched_users";
export const FETCH_ALL_STAFF_WATCHER = "fetch_all_staff_watcher";
export const SET_ALL_STAFF = "set_all_staff";
export const GET_ROLES_WATCHER = "get_roles";
export const SET_ROLES = "set_roles";
export const FETCH_USER_ACCOUNT_INFO_WATCHER = "fetch_user_account_info_watcher";
export const FETCH_USER_INFO_BY_USER_NAME_WATCHER = "fetch_user_info_by_username_watcher";
export const SET_USER_ACCOUNT_INFO = "set_user_account_info";
export const GET_SELECTED_USER_LIFETIME_POINTS = "get_selected_user_lifetime_points";
export const SET_SELECTED_USER_LIFETIME_POINTS = "set_selected_user_lifetime_points";
export const GET_SELECTED_USER_MEMBERSHIP_POINTS = "get_selected_user_membership_points";
export const SET_SELECTED_USER_MEMBERSHIP_POINTS = "set_selected_user_membership_points";
export const GET_SELECTED_USER_SPENDING_POINTS = "get_selected_user_spending_points";
export const SET_SELECTED_USER_SPENDING_POINTS = "set_selected_user_spending_points";
export const UPDATE_USERS_INFO_WATCHER = "update_users_info_watcher";
export const BAN_USER_WATCHER = "ban_user";
export const UNBAN_USER_WATCHER = "unban_user";
export const UPDATE_REFERRAL_LINK_WATCHER = "update_referral_link";
export const ADMIN_POINTS_CHANGE_WATCHER = "admin_points_change_watcher";
export const FETCH_ADMIN_USER_ORDERS_WATCHER = "fetch_admin_user_orders_watcher";
export const SET_ADMIN_USER_ORDERS = "set_admin_user_orders";
export const FETCH_SELECTED_USER_INTERACTIONS_WATCHER = "fetch_selected_users_interactions_watcher";
export const SET_SELECTED_USER_INTERACTIONS = "set_selected_users_interactions";
export const FETCH_SELECTED_USER_REFERRALS_WATCHER = "fetch_selected_users_referrals_watcher";
export const SET_SELECTED_USER_REFERRALS = "set_selected_users_referral";
export const FETCH_INTERACTIONS_WATCHER = "fetch_interaction_watcher";
export const SET_INTERACTIONS = "set_interactions";
export const FETCH_POINTS_HISTORY_WATCHER = "fetch_points_history_watcher";
export const SET_POINTS_HISTORY = "set_points_history";
export const CLEAR_POINT_HISTORIES = "clear_points_history";
export const FETCH_BAN_USERS_WATCHER = "fetch_ban_users_watcher";
export const SET_BAN_USERS = "set_ban_users";
export const FETCH_PERMISSION_LIST = "fetch_permission_list";
export const SET_PERMISSION_LIST = "set_permission_list";
export const DELETE_USER = "delete_user";

// Action Types for Event
export const CREATE_EVENT_WATCHER = "create_event_watcher";
export const RECENT_EVENT_WATCHER = "recent_event_watcher";
export const SET_RECENT_EVENTS = "set_recent_event";
export const FETCH_EVENT_WATCHER = "fetch_event_watcher";
export const SET_EVENT = "set_event";
export const UPDATE_EVENT_WATCHER = "update_event_watcher";
export const CHECK_IN_EVENT_WATCHER = "check_in_event_watcher";
export const DELETE_EVENT_WATCHER = "delete_event_watcher";
export const EMAIL_EVENT_DATA = "email_event_data";

// Action Types for Achievements
export const FETCH_ACHIEVEMENTS_WATCHER = "fetch_achievements_watcher";
export const SET_ACHIEVEMENTS = "set_achievements";
export const CREATE_ACHIEVEMENT_WATCHER = "create_achievement_watcher";
export const SEARCH_ACHIEVEMENTS_WATCHER = "search_achievements_watcher";
export const SET_SEARCHED_ACHIEVEMENTS = "set_searched_achievements";
export const FETCH_ACHIEVEMENT_WATCHER = "fetch_achievement_watcher";
export const SET_ACHIEVEMENT = "set_achievement";
export const UPDATE_ACHIEVEMENT_WATCHER = "update_achievement_watcher";
export const FETCH_VALID_ACHIEVEMENTS_WATCHER = "fetch_valid_achievements_watcher";
export const SET_VALID_ACHIEVEMENTS = "set_valid_achievements";

// Action Types for Users Achievements
export const FETCH_USERS_ACHIEVEMENTS_WATCHER = "fetch_users_achievements_watcher";
export const FETCH_ACHIEVEMENTS_DISPLAY_WATCHER = "fetch_achievements_display_watcher";
export const UPDATE_ACHIEVEMENTS_DISPLAY_WATCHER = "update_achievements_display_watcher";
export const SET_ACHIEVEMENTS_DISPLAY_BANK = "set_achievements_display_bank";
export const SET_USERS_ACHIEVEMENTS = "set_users_achievements";

// Action Types for Users Season Review
export const FETCH_USERS_SEASON_REVIEW_WATCHER = "fetch_users_season_review_watcher";
export const FETCH_USERS_SEASON_REVIEW_BY_NAME_WATCHER =
	"fetch_users_season_review_by_name_watcher";
export const SET_USERS_SEASON_REVIEW = "set_users_season_review";

// User Password Action Types
export const SEND_FORGOT_PASSWORD = "send_forgot_password";
export const SET_FORGOT_PASSWORD_EMAIL_STATUS = "set_forgot_password_email_status";
export const SEND_RESET_PASSWORD = "send_reset_password";
export const RESET_PASSWORD_STATUS = "reset_password_status";
export const SET_USER_EMAIL = "set_user_email";

// Rewards Action Types
export const SET_NEW_REWARD = "set_new_reward";
export const PUSH_NEW_REWARD = "push_new_reward";
export const REPLACE_NEW_REWARD = "replace_new_reward";
export const SET_REWARDS_BANK = "set_rewards_bank";
export const SELECT_REWARD = "select_reward";
export const FETCH_REWARDS_WATCHER = "fetch_rewards_watcher";
export const FETCH_REWARD_DETAILS_WATCHER = "fetch_rewards_details_watcher";
export const FETCH_RELATED_REWARDS_WATCHER = "fetch_related_rewards_watcher";
export const UPDATE_REWARDS_WATCHER = "update_rewards_watcher";
export const CREATE_REWARD_WATCHER = "create_reward_watcher";
export const FETCH_SHOPIFY_ITEMS_WATCHER = "fetch_shopify_items_watcher";
export const SET_POTENTIAL_REWARDS = "set_potential_rewards";
export const APPEND_POTENTIAL_REWARDS = "append_potential_rewards";
export const SEARCH_REWARDS_BY_ITEM = "search_rewards_by_item";

export const PURCHASE_REWARD_WATCHER = "purchase_reward_watcher";
export const SET_SUCCESFUL_ORDER = "set_succesful_order";
export const REMOVE_TEMPORARY_CONFIRMATION = "remove_temporary_confirmation";

// Orders Action Types
export const SET_ORDERS_BANK = "set_order_bank";
export const SET_USER_ORDERS_BANK = "set_user_order_bank";
export const APPEND_ORDERS_BANK = "append_orders_bank";
export const FETCH_ORDERS_WATCHER = "fetch_orders_watcher";
export const MODIFY_ORDER_WATCHER = "modify_reward_watcher";
export const FETCH_USER_ORDERS_WATCHER = "fetch_user_orders_watcher";

// Admin Panel Action Types
export const SET_ADMIN_REWARD_TAB = "set_reward_tab";
export const FETCH_POINTS_PROCESSING_EVENTS_WATCHER = "fetch_points_processing_events_watcher";
export const SET_POINTS_PROCESSING_EVENTS = "set_points_processing_events";
export const INSTANT_PROCESS_POINTS_WATCHER = "process_points_watcher";

// Notification Action Types
export const APPEND_NOTIFICATIONS = "append_notifications";
export const POP_NOTIFICATION = "pop_notification";
export const UNSHIFT_NOTIFICATION = "unshift_notification";
export const REMOVE_NOTIFICATION = "remove_notification";
export const CLEAR_NOTIFICATIONS = "clear_notifications";

// Message Action Types
export const ADD_MESSAGE = "add_message";
export const REMOVE_MESSAGE = "remove_message";
export const CLEAR_MESSAGES = "clear_messages";

// channel action types
export const FETCH_DISCORD_CHANNEL_DATA = "fetch_discord_channel_data";
export const SET_DISCORD_CHANNEL = "set_discord_channel";
export const CREATE_DISCORD_SERVER = "create_discord_server";
export const DELETE_DISCORD_SERVER = "delete_discord_server";

export const FETCH_TWITCH_CHANNEL_DATA = "fetch_twitch_channel_data";
export const SET_TWITCH_CHANNEL = "set_twitch_channel";
export const CREATE_TWITCH_SERVER = "create_twitch_server";
export const DELETE_TWITCH_SERVER = "delete_twitch_server";
export const FETCH_TWITCH_BROADCASTS = "fetch_twitch_broadcasts";
export const SET_TWITCH_BROADCASTS = "set_twitch_broadcasts";
export const FETCH_TWITCH_EVENTS_SCHEDULE = "fetch_twitch_events_schedule";
export const SET_TWITCH_EVENTS_SCHEDULE = "set_twitch_events_schedule";
export const FETCH_TWITCH_LIVE_STREAMERS = "fetch_twitch_live_streamers";
export const SET_TWITCH_LIVE_STREAMERS = "set_twitch_live_streamers";
export const CREATE_TWITCH_BROADCAST = "create_twitch_broadcast";
export const DELETE_TWITCH_BROADCAST = "delete_twitch_broadcast";
export const UPDATE_TWITCH_BROADCAST = "update_twitch_broadcast";
export const UPDATE_TWITCH_BROADCASTS_MODERATOR = "update_twitch_broadcasts_moderator";
export const AUTHORIZE_TWITCH_APP = "authorize_twitch_app";

export const FETCH_TWITCH_APP_MODERATORS = "fetch_twitch_app_moderators";
export const SET_TWITCH_APP_MODERATORS = "set_twitch_app_moderators";
export const DELETE_TWITCH_APP_MODERATOR = "delete_twitch_app_moderators";
export const UPDATE_TWITCH_APP_MODERATOR = "update_twitch_app_moderators";

export const FETCH_TWITTER_CHANNEL_DATA = "fetch_twitter_channel_data";
export const SET_TWITTER_CHANNEL = "set_twitter_channel";
export const CREATE_TWITTER_SERVER = "create_twitter_server";
export const DELETE_TWITTER_SERVER = "delete_twitter_server";

export const FETCH_REDDIT_CHANNEL_DATA = "fetch_reddit_channel_data";
export const SET_REDDIT_CHANNEL = "set_reddit_channel";
export const CREATE_REDDIT_SERVER = "create_reddit_server";
export const DELETE_REDDIT_SERVER = "delete_reddit_server";

// channel count action type
export const FETCH_TWITCH_CHANNEL_COUNT = "fetch_twitch_channel_count";
export const SET_TWITCH_CHANNEL_COUNT = "set_twitch_channel_count";

export const FETCH_SHOPIFY_CHANNEL_COUNT = "fetch_shopify_channel_count";
export const SET_SHOPIFY_CHANNEL_COUNT = "set_shopify_channel_count";

export const FETCH_DISCORD_CHANNEL_COUNT = "fetch_discord_channel_count";
export const SET_DISCORD_CHANNEL_COUNT = "set_discord_channel_count";

export const FETCH_TWITTER_CHANNEL_COUNT = "fetch_twitter_channel_count";
export const SET_TWITTER_CHANNEL_COUNT = "set_twitter_channel_count";

export const FETCH_REDDIT_CHANNEL_COUNT = "fetch_reddit_channel_count";
export const SET_REDDIT_CHANNEL_COUNT = "set_reddit_channel_count";

// Account Linking Platform
export const DISCONNECT_ACCOUNT_LINK_WATCHER = "disconnect_account_link_watcher";
export const FETCH_ACCOUNT_LINK_WATCHER_ASYNC = "fetch_account_link_watcher_async";
export const LINK_SHOPIFY_ACCOUNT = "link_shopify_account";
export const VERIFY_SHOPIFY_ACCOUNT = "verify_shopify_account";

// Social platform visibility
export const SET_SOCIAL_PLATFORM_VISIBILITY_WATCHER = "set_social_platform_visibility_watcher";
export const SET_SOCIAL_PLATFORM_VISIBILITY = "set_social_platform_visibility";

// V2 Quests
export const FETCH_V2_QUESTS_OUT_OF_EXPERIENCES_WATCHER =
	"fetch_v2_quests_out_of_experiences_watcher";
export const FETCH_V2_QUESTS_COMPLETED_WATCHER = "fetch_v2_quests_completed_watcher";
export const FETCH_V2_QUEST_WATCHER = "fetch_v2_quest_watcher";
export const SET_V2_QUESTS_BANK = "set_v2_quests_bank";
export const FETCH_V2_EXPERIENCES_WATCHER = "fetch_v2_experiences_watcher";
export const FETCH_V2_EXPERIENCE_DETAILS_WATCHER = "fetch_v2_experience_watcher";
export const SET_V2_EXPERIENCES_BANK = "fetch_v2_experience_watcher";

// Quests
export const FETCH_QUESTS_WATCHER = "fetch_quests_watcher";
export const ADMIN_FETCH_QUESTS_WATCHER = "admin_fetch_quests_watcher";
export const FETCH_OBJECTIVE_ALGORITHMS_WATCHER = "fetch_objective_algorithms_watcher";
export const CREATE_QUESTS_WATCHER = "create_quests_watcher";
export const UPDATE_QUEST_WATCHER = "update_quest_watcher";
export const DELETE_QUEST_WATCHER = "delete_quest_watcher";
export const UPDATE_MULTIPLE_QUESTS_WATCHER = "update_multiple_quests_watcher";
export const SET_QUESTS_BANK = "set_quests_bank";
export const SET_OBJECTIVE_ALGORITHMS_BANK = "set_objective_algorithms_bank";

export const FETCH_QUEST_TRANSLATIONS_WATCHER = "fetch_quest_translations_watcher";
export const SET_QUEST_TRANSLATIONS = "set_quest_translations";
export const FETCH_QUEST_TRANSLATIONS_STATUS_WATCHER = "fetch_quest_translations_status_watcher";
export const SET_QUEST_TRANSLATIONS_STATUS = "set_quest_translations_status";
export const PATCH_QUEST_TRANSLATIONS_WATCHER = "patch_quest_translations_watcher";
export const PATCH_QUEST_TRANSLATIONS_PUBLISHED_STATUS_WATCHER =
	"patch_quest_translations_published_status_watcher";
export const UPDATE_QUEST_TRANSLATIONS_PUBLISHED_STATUS =
	"update_quest_translations_published_status";

export const FETCH_CAMPAIGN_TRANSLATIONS_WATCHER = "fetch_campaign_translations_watcher";
export const SET_CAMPAIGN_TRANSLATIONS = "set_campaign_translations";
export const PATCH_CAMPAIGN_TRANSLATIONS_WATCHER = "patch_campaign_translations_watcher";

export const FETCH_QUEST_PROGRESSIONS_WATCHER = "fetch_quest_progressions_watcher";
export const FETCH_QUEST_PROGRESSION_BUCKET_TOP_CHANNELS_WATCHER =
	"fetch_quest_progression_bucket_top_channels_watcher";
export const FETCH_QUEST_PROGRESSION_BUCKET_TOP_USERS_WATCHER =
	"fetch_quest_progression_bucket_top_users_watcher";
export const FETCH_USERNAMES_AND_AVATARS_WATCHER = "fetch_usernames_and_avatars";
export const SET_USERNAMES_AND_AVATARS = "set_usernames_and_avatars";
export const SET_QUEST_PROGRESSIONS_BANK = "set_quest_progressions_bank";
export const SET_BUCKET_QUEST_PROGRESSIONS_BANK_TOP_USERS =
	"set_bucket_quest_progressions_bank_top_users";
export const SET_BUCKET_QUEST_PROGRESSIONS_BANK_TOP_CHANNELS =
	"set_bucket_quest_progressions_bank_top_channels";
export const CLAIM_REWARDS_FROM_QUEST_PROGRESSION = "claim_rewards_from_quest_progression";
export const ACCEPT_TERMS_FROM_QUEST_PROGRESSION = "accept_terms_from_quest_progression";

// Campaigns
export const CREATE_CAMPAIGNS_WATCHER = "create_campaigns_watcher";
export const FETCH_CAMPAIGNS_WATCHER = "fetch_campaigns_watcher";
export const FETCH_PUBLIC_CAMPAIGNS_WATCHER = "fetch_public_campaigns_watcher";
export const UPDATE_CAMPAIGN_WATCHER = "update_campaign_watcher";
export const SET_CAMPAIGNS_BANK = "set_campaigns_bank";
export const FETCH_UNAUTHORIZED_PUBLIC_CAMPAIGN_WATCHER =
	"fetch_unauthorized_public_campaign_watcher";
export const SET_UNAUTHORIZED_CAMPAIGN = "set_unauthorized_campaign";
export const ENTER_CAMPAIGN_WATCHER = "enter_campaign_watcher";
export const FETCH_PUBLIC_CAMPAIGN_WATCHER = "fetch_public_campaign_watcher";
export const SET_PUBLIC_CAMPAIGN = "set_public_campaign";
export const FETCH_CAMPAIGN_TRANSLATIONS_STATUS_WATCHER =
	"fetch_campaign_translations_status_watcher";
export const SET_CAMPAIGN_TRANSLATIONS_STATUS = "set_campaign_translations_status";
export const PATCH_CAMPAIGN_TRANSLATIONS_PUBLISHED_STATUS_WATCHER =
	"patch_campaign_translations_published_status_watcher";
export const UPDATE_CAMPAIGN_TRANSLATIONS_PUBLISHED_STATUS =
	"update_campaign_translations_published_status";

//Tiers
export const FETCH_TIERS_WATCHER = "fetch_tiers_watcher";
export const SET_ALL_TIERS = "set_all_tiers";
export const UPDATE_TIER_WATCHER = "update_tier_watcher";

//Perks
export const FETCH_PERKS_WATCHER = "fetch_perks_watcher";
export const SET_ALL_PERKS = "set_all_perks";
export const FETCH_USER_PERKS_WATCHER = "fetch_user_perks_watcher";
export const SET_USER_PERKS = "set_user_perks";

//Point Histories
export const FETCH_POINT_HISTORIES_WATCHER = "fetch_point_histories_watcher";
export const SET_POINT_HISTORIES = "set_point_histories";

// Update User
export const UPDATE_USER_ROLE = "update_user_role";
export const UPDATE_USER_ONBOARDED = "update_user_onboarded";
export const UPDATE_USER_USERNAME = "update_user_username";
export const UPDATE_USER_MEMBERSHIP = "update_user_membership";
export const UPDATE_USER_EMAIL = "update_user_email";
export const UPDATE_USER_COUNTRY = "update_user_country";
export const UPDATE_USER_GENDER = "update_user_gender";
export const UPDATE_USER_BIRTHDAY = "update_user_birthday";
export const UPDATE_USER_PARENT_CONSENT = "update_user_parent_consent";

// Claimed Perks
export const ADD_TO_CLAIMED_PERKS = "add_to_claimed_perks";

// Games and Players
export const FETCH_GAMES = "fetch_games";
export const SET_GAMES = "set_games";
export const ADD_GAME_WATCHER = "add_game";
export const UPDATE_GAME_WATCHER = "update_game";
export const DELETE_GAME_WATCHER = "delete_game";
export const ARCHIVE_RESTORE_GAME_WATCHER = "archieve_restore_game";
export const FETCH_PLAYERS = "fetch_players";
export const SET_PLAYERS = "set_players";
export const ADD_PLAYER_WATCHER = "add_player";
export const UPDATE_PLAYER_WATCHER = "update_player";
export const DELETE_PLAYER_WATCHER = "delete_player";
export const ARCHIVE_RESTORE_PLAYER_WATCHER = "archieve_restore_player";

// Endpoint Caching
export const SET_ENDPOINT_CACHE = "set_endpoint_cache";
export const CLEAR_ENDPOINT_CACHE = "clear_endpoint_cache";

// Rum status
export const RUM_STATUS_WATCHER = "rum_status_watcher";
export const SET_RUM_STATUS = "set_rum_status";

// Countries
export const FETCH_COUNTRIES_WATCHER = "fetch_countries_watcher";
export const SET_COUNTRIES = "set_countries";

// Genders
export const FETCH_GENDERS_WATCHER = "fetch_genders_watcher";
export const SET_GENDERS = "set_genders";

// Games
export const FETCH_GAMES_PUBLIC_INFO_WATCHER = "fetch_games_public_info_watcher";
export const SET_GAMES_PUBLIC_INFO_WATCHER = "set_games_public_info_watcher";

// Reward FAQs
export const CREATE_REWARD_FAQS = "create_reward_faqs_watcher";
export const UPDATE_REWARD_FAQS = "update_reward_faqs_watcher";
export const FETCH_REWARD_FAQS = "fetch_reward_faqs_watcher";

// Liquipedia API
export const FETCH_LIQUIPEDIA_KEYWORDS = "fetch_liquipedia_keywords";
export const ADD_LIQUIPEDIA_KEYWORD = "add_liquipedia_keyword";
export const DELETE_LIQUIPEDIA_KEYWORD = "delete_liquipedia_keyword";
export const SET_LIQUIPEDIA_KEYWORDS = "set_liquipedia_keywords";
