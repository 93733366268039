import React, { useState } from "react";
import PropTypes from "prop-types";

import Modal from "../../../../../components/Modal/Modal";
import LpHeading from "../../../../../components/LpHeading";
import { FormFieldSelect } from "../../../../../components/FormFields/FormFields";

function AssignBroadcastModeratorDialog({
	className,
	disabled,
	selectedBroadcasts = [],
	moderators = [],
	onConfirm,
}) {
	const [moderator, setModerator] = useState();

	const getOptionLabel = (moderator) => {
		const isDefault = moderator.default_mod ? " (Default Moderator)" : "";
		return `${moderator.name} - ${moderator.moderator_id}${isDefault}`;
	};

	return (
		<Modal
			className="admin assign-broadcast-dialog"
			triggerBtnText="Assign Moderators"
			triggerBtnClassName={className}
			triggerDisabled={disabled}
			cancelBtnText="Cancel"
			submitBtnText="Assign"
			onSubmit={(e) => {
				onConfirm({ moderator, broadcasts: selectedBroadcasts });
				setModerator();
			}}
			submitDisabled={!moderator}
		>
			<LpHeading>Assign Twitch Moderators</LpHeading>
			<p>
				Twitch Moderators are accounts that will be used to track Fan Hub (Liquid+) users in
				Twitch streams and reward them with points.
			</p>
			<p>
				Make sure that the moderators being assigned to these broadcasts have the moderator
				permission to the selected Twitch channels.
			</p>
			<p>
				The broadcasts being selected have the following channels:{" "}
				<b>{selectedBroadcasts.map((b) => b.channel_name).join(", ")}</b>
			</p>
			<form>
				<FormFieldSelect
					label="Select Moderator"
					name="moderator"
					options={moderators.map((mod) => ({
						label: getOptionLabel(mod),
						value: mod.moderator_id,
					}))}
					value={moderator}
					onChange={(e) => setModerator(e.target.value)}
				/>
			</form>
		</Modal>
	);
}

AssignBroadcastModeratorDialog.propTypes = {
	className: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	selectedBroadcasts: PropTypes.array.isRequired,
	moderators: PropTypes.array.isRequired,
	onConfirm: PropTypes.func.isRequired,
};

export default AssignBroadcastModeratorDialog;
