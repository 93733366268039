import React from "react";
import { connect } from "react-redux";
import Modal from "../../../../components/Modal";
import { FormFieldSelect } from "../../../../components/FormFields/FormFields";
import {
	getRoles,
	fetchUserAccountInfo,
} from "../../../../actions/UserAdminPanel";
import { updateUserRole } from "../../../../actions/UserUpdate";

class RoleDialog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			permissions: "",
			selectedRoleId: this.props.userRoleId,
		};
	}

	componentDidMount() {
		this.props.getRoles();
	}

	handleSubmit = () => {
		if (this.state.selectedRoleId) {
			this.props.updateUserRole(
				{
					user: this.props.userId,
					role_id: this.state.selectedRoleId,
				},
				{
					success: {
						notification: "Successfully updated user role.",
						callback: this.props.fetchUserAccountInfo,
						callbackArgs: this.props.userId,
					},
					failure: {
						notification: "Error updating user role.",
					},
				}
			);
		}
	};

	renderOptions(roles) {
		if (roles && roles.length) {
			return roles.map((role) => {
				return { value: role.id, label: role.name };
			});
		}
	}

	handleRoleChange = (e) => {
		let value = e.target.value;
		this.setState({
			selectedRoleId: value,
		});
	};

	render() {
		const { roles } = this.props.user_admin;

		return (
			<Modal
				className="admin"
				triggerBtnText="Change"
				triggerBtnClassName="button"
				cancelBtnText="Cancel"
				submitBtnText="Save"
				onSubmit={() => this.handleSubmit()}
			>
				<FormFieldSelect
					label={"Change role for this user"}
					name={"role"}
					value={this.state.selectedRoleId}
					options={this.renderOptions(roles)}
					onChange={this.handleRoleChange}
				/>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getRoles: () => dispatch(getRoles()),
		updateUserRole: (params, options) =>
			dispatch(updateUserRole(params, options)),
		fetchUserAccountInfo: (userId) =>
			dispatch(fetchUserAccountInfo(userId)),
	};
};

const mapStateToProps = (state) => {
	return {
		user_admin: state.user_admin,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleDialog);
