import { setUsernamesAndAvatarsBank } from "../../actions/User";
import { takeLatest, call, put } from "redux-saga/effects";
import { FETCH_USERNAMES_AND_AVATARS_WATCHER } from "../../constants/ActionTypes";
import { FETCH_USERNAMES_AND_AVATARS_URL } from "../../constants/Url";

import api from "../../util/api";

export function* fetchUsernamesAndAvatarsWatcher() {
	yield takeLatest(
		FETCH_USERNAMES_AND_AVATARS_WATCHER,
		fetchUsernamesAndAvatarsWorker
	);
}

function* fetchUsernamesAndAvatarsWorker(action) {
	try {
		const response = yield call(
			fetchUsernamesAndAvatarsApi,
			action.payload
		);
		const isSuccess = response.status >= 200 && response.status < 300;

		if (isSuccess) {
			yield put(setUsernamesAndAvatarsBank(response.data.users));
		}
	} catch (e) {
		console.error(e);
	}
}

function fetchUsernamesAndAvatarsApi(params) {
	return api
		.post(FETCH_USERNAMES_AND_AVATARS_URL, params)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
