import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";

// Utils
import {
	getInitialLanguage,
	getAllLanguagesValue,
	loadLocales,
	updateMomentLocale,
} from "../util/Language";

// Translations
import { loadCommonTranslations } from "../translations";

// Load languages and moment lib locales for languages
const supportedLanguages = getAllLanguagesValue();

// Determine user language
const language = getInitialLanguage();

// Load moment datetime library locales for languages
loadLocales().finally(() => {
	updateMomentLocale(language);
});

localStorage.setItem("language", language);

i18n.use(ChainedBackend)
	.use(initReactI18next)
	.init({
		lng: language,
		supportedLngs: supportedLanguages,
		interpolation: {
			escapeValue: false,
		},
		react: {
			wait: true,
			useSuspense: false,
		},
		saveMissing: true,
	});

loadCommonTranslations();
