import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { FormFieldInput } from '../../../../components/FormFields';
import { updateAchievement } from "../../../../actions/Achievements";
import "../styles.scss";

class ChangeLimitModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            attachment_limit: "",
        }
    }

    componentWillMount() {
        this.setState({
            attachment_limit: this.props.attachment_limit,
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            attachment_limit: nextProps.attachment_limit
        })
    }

    handleSubmit = () => {
        this.props.updateAchievement({
          attachment_limit: this.state.attachment_limit ? this.state.attachment_limit : 0,
        },this.props.achievementId,
         {
          success: {
            notification: "Achievement limit updated.",
          },
          failure: {
            notification: "Oops, that didn't work. Can you try again?",
          }
        });
      };

    handleClose() {
        this.setState({
            attachment_limit: this.props.attachment_limit,
        });
    }

    checkValidation = () => {
        if (this.state.attachment_limit) {
            if (this.state.attachment_limit == this.props.attachment_limit) {
                return true
            } else if (this.state.attachment_limit < this.props.total_attachments) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    };

    render() {
        return (
            <Modal className="admin"
                triggerBtnText="Change"
                triggerBtnClassName="button button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Save"
                submitDisabled={this.checkValidation()}
                onSubmit={() => this.handleSubmit()}
                onClose={() => this.handleClose()}
            >
                <FormFieldInput
                    intro={"Select the number of times that this achievements can be used. Leaving it blank or inputting 0 means it can be used an infinite number of times."}
                    label={"Attachment Limit"}
                    type="number"
                    name={"attachment_limit"}
                    value={this.state.attachment_limit}
                    placeholder={"0"}
                    pattern={"[0-9]*"}
                    onChange={(event) => this.setState({ attachment_limit: event.target.value })}
                />
            </Modal>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
       updateAchievement: (params, id, options) => dispatch(updateAchievement(params, id, options)),
    }
}

const mapStateToProps = state => {
    return {

    }
}

ChangeLimitModal = connect(mapStateToProps, mapDispatchToProps)(ChangeLimitModal);

export { ChangeLimitModal }
