import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { setQuestProgressionsBank } from "../../actions/QuestProgressions";

// Utils
import api from "../../util/api";
import { isThrottled, setThrottledTimespan } from "../../util/SagaUtils";

// Constants
import { FETCH_QUEST_PROGRESSIONS_WATCHER } from "../../constants/ActionTypes";
import { FETCH_QUEST_PROGRESSIONS_URL } from "../../constants/Url";
import { ENDPOINT_CACHING_TIMESPANS } from "../../constants/CachingTimespans";

export function* fetchQuestProgressionsWatcher() {
	yield takeLatest(FETCH_QUEST_PROGRESSIONS_WATCHER, fetchQuestProgressionsWorker);
}

function* fetchQuestProgressionsWorker() {
	const endpointIsThrottled = yield isThrottled(FETCH_QUEST_PROGRESSIONS_URL);
	if (!endpointIsThrottled) {
		try {
			const response = yield call(fetchQuestProgressionsApi);
			const isSuccess = response.status >= 200 && response.status < 300;

			if (isSuccess) {
				yield put(setQuestProgressionsBank(response.data.data));
				yield setThrottledTimespan(
					FETCH_QUEST_PROGRESSIONS_URL,
					ENDPOINT_CACHING_TIMESPANS.FETCH_QUEST_PROGRESSIONS
				);
			} else {
				throw response;
			}
		} catch (e) {
			console.error(e);
		}
	}
}

function fetchQuestProgressionsApi() {
	return api.get(FETCH_QUEST_PROGRESSIONS_URL);
}
