import i18n from "i18next";

// Notification Types

export const ERROR = "error";
export const SUCCESS = "success";
export const WARNING = "warning";
export const INFO = "info";

// Notification Services
export const USER_LOGIN = (status, message) => {
	const MESSAGES = {
		success: i18n.t("authorizedHeader:signOut.refreshSuccess"),
		error: i18n.t("authorizedHeader:signIn.issue"),
	};
	return {
		type: status,
		service: "User Login",
		message: message ? message : MESSAGES[status],
	};
};

export const USER_LOGOUT = (status) => {
	const MESSAGES = {
		success: i18n.t("authorizedHeader:signOut.message"),
		error: i18n.t("authorizedHeader:signOut.issue"),
		warning: i18n.t("authorizedHeader:signOut.expired"),
	};
	return {
		type: status,
		service: "User Logout",
		message: MESSAGES[status],
	};
};

export const USER_REFRESH = (status, user) => {
	const MESSAGES = {
		success: i18n.t("authorizedHeader:signOut.refreshSuccess"),
		error: i18n.t("authorizedHeader:signOut.refreshError"),
	};
	return {
		type: status,
		service: "User Login Refresh",
		message: MESSAGES[status],
	};
};

export const USER_REGISTRATION = (status, user) => {
	const MESSAGES = {
		success: "Succesfully registered " + user + ".",
		error: "There was an issue registering " + user + ".",
		warning: user + " already exists.",
	};
	return {
		type: status,
		service: "User Registration",
		message: MESSAGES[status],
	};
};

export const SOCIAL_REGISTRATION = (status, message) => {
	const MESSAGES = {
		success: "Successfully registered",
		error: "Registration failed",
		warning: message,
	};
	return {
		type: status,
		service: "User Registration",
		message: MESSAGES[status],
	};
};

export const PLATFORM_LINK = (status, message) => {
	const MESSAGES = {
		success: message,
		error: message,
	};
	return {
		type: status,
		service: "Platform Connection",
		message: MESSAGES[status],
	};
};

export const REWARDS_PURCHASE = (status, quantity) => {
	const MESSAGES = {
		success: quantity > 1 ? "Items redeemed." : "Item redeemed.",
		error: "There was an issue with your purchase.",
	};
	return {
		type: status,
		service: "Reward Purchase",
		message: MESSAGES[status],
	};
};

export const REWARDS_ORDER_UPDATE = (status) => {
	const MESSAGES = {
		success: "Order updated successfully.",
		error: "Order update process failed.",
	};
	return {
		type: status,
		service: "Reward Order Update",
		message: MESSAGES[status],
	};
};

export const USER_UPDATE = (status, message, lang) => {
	const options = {};
	if (lang) {
		options.lng = lang;
	}
	const MESSAGES = {
		success: i18n.t("notifications:userUpdateSuccess", options),
		error: i18n.t("notifications:userUpdateError", options),
	};
	return {
		type: status,
		service: "User Update",
		message: message?.length > 0 ? message : MESSAGES[status],
	};
};

export const USER_UPDATE_ADMIN = (status, errMsg) => {
	const MESSAGES = {
		success: "Got it!",
		error: errMsg ? errMsg : "Whoops, looks like the changes didn’t stick.",
	};
	return {
		type: status,
		service: "User Update",
		message: MESSAGES[status],
	};
};

export const PASSWORD_UPDATE = (status) => {
	const MESSAGES = {
		success: i18n.t("notifications:passwordUpdateSuccess"),
		error: i18n.t("notifications:passwordUpdateFailed"),
	};
	return {
		type: status,
		service: "Account Security",
		message: MESSAGES[status],
	};
};

export const LOYALTY_EVENTS = (status, events) => {
	const MESSAGES = {
		success: "Succesfully started processing event/s: " + events + ".",
		error: "There was an error starting the processing of event/s requested.",
	};
	return {
		type: status,
		service: "Points Proccesing",
		message: MESSAGES[status],
	};
};

export const USER_VERIFICATION = (status, errMsg) => {
	const MESSAGES = {
		warning: "We were not able to successfully verify your account.",
		error: errMsg,
	};
	return {
		type: status,
		service: "User Verification",
		message: MESSAGES[status],
	};
};

export const EVENT_CHECK_IN = (status, message) => {
	const MESSAGES = {
		success: "Event checkin succesful",
		error: "Event checkin failed",
		warning: message,
	};
	return {
		type: status,
		service: "Event checkin",
		message: MESSAGES[status],
	};
};

export const QUEST_CREATE = (status, questTitle, error) => {
	const MESSAGES = {
		success: 'Successfully created quest: "' + questTitle + '"',
		error: "Couldn't create quest: \"" + questTitle + '". ' + (error ? "(" + error + ")" : ""),
	};
	return {
		type: status,
		service: "Quest creation",
		message: MESSAGES[status],
	};
};

export const SEND_HELP_REQUEST = (status, notification) => {
	return {
		type: status,
		service: "Help Request",
		message: notification,
	};
};

export const DELETE_QUEST = (status) => {
	const MESSAGES = {
		success: "Deleted quest.",
		error: "Error deleting quest.",
	};
	return {
		type: status,
		service: "Delete Quest",
		message: MESSAGES[status],
	};
};

export const ORDERS_FULFILLMENT = "Orders Fulfillment";
export const REWARDS = "Rewards";
