import React from "react";
import PropTypes from "prop-types";

import { DISCORD_CREATE_DIALOG_TEXT } from "../../../../../constants/Label";
import Modal from "../../../../../components/Modal/Modal";

import {
	FormFieldInput,
	FormFieldSelect,
} from "../../../../../components/FormFields/FormFields";
import GameEntitiesInput from "../../../../../components/EsportsEntitiesInputs/GameEntitiesInput";
import PlayerEntitiesInput from "../../../../../components/EsportsEntitiesInputs/PlayerEntitiesInput";

class DiscordDialog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			serverName: "",
			token: "",
			type: "",
			esport_entities: [],
		};
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	addEntity = (entity) => {
		this.setState((state) => ({
			esport_entities: [...state.esport_entities, entity],
		}));
	};

	removeEntity = (entity) => {
		this.setState((state) => {
			return {
				esport_entities: state.esport_entities.filter(
					(e) => e.id !== entity.id
				),
			};
		});
	};

	clearState = () => {
		this.setState({
			serverName: "",
			token: "",
			type: "",
			esport_entities: [],
		});
	};

	handleSubmit = () => {
		const { serverName, token, type, esport_entities } = this.state;

		this.props.handleCreate({
			params: {
				serverName,
				token,
				type,
				esport_entities,
			},
			options: {
				success: {
					notification: "Successfuly created Discord Bot",
					callback: this.clearState,
				},
				failure: {
					notification: "Failed to create Discord Bot",
				},
			},
		});
	};

	render() {
		return (
			<Modal
				className="admin channel-modal"
				triggerBtnText="Add Bot"
				triggerBtnClassName="button"
				cancelBtnText="Cancel"
				onClose={this.clearState}
				submitBtnText="Add"
				submitDisabled={
					!this.state.serverName ||
					!this.state.token ||
					!this.state.type
				}
				onSubmit={this.handleSubmit}
			>
				<FormFieldInput
					intro={DISCORD_CREATE_DIALOG_TEXT}
					label={"Server Name"}
					type={"text"}
					name={"serverName"}
					value={this.state.serverName}
					onChange={this.handleChange}
				/>
				<FormFieldInput
					label={"Token (required)"}
					type={"text"}
					name={"token"}
					value={this.state.token}
					onChange={this.handleChange}
					maxLength={100}
				/>
				<FormFieldSelect
					label={"Type"}
					name={"type"}
					options={[
						{ label: "Official", value: "OFFICIAL" },
						{ label: "Player", value: "PLAYER" },
					]}
					value={this.state.type}
					onChange={this.handleChange}
				/>

				<PlayerEntitiesInput
					label="Player(s)"
					intro="Enter the players or games that relate to this server."
					entities={this.state.esport_entities}
					onChange={this.addEntity}
					onRemove={this.removeEntity}
				/>

				<GameEntitiesInput
					label="Game(s)"
					entities={this.state.esport_entities}
					onChange={this.addEntity}
				/>
			</Modal>
		);
	}
}

DiscordDialog.propTypes = {
	handleCreate: PropTypes.func.isRequired,
};

export default DiscordDialog;
