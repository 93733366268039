import {
    setTiers
} from "../../actions/Tiers";
import {
    showLoader,
    hideLoader
} from "../../actions/GlobalAction";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import { // if you want to redirect post saga action
    push
} from "react-router-redux";
import {
    FETCH_TIERS_WATCHER
} from "../../constants/ActionTypes";
import {
    FETCH_ALL_TIERS_URL
} from "../../constants/Url";

import api from '../../util/api';

function fetchTiersApi() {
    return (
        api
        .get(FETCH_ALL_TIERS_URL)
    );
};

export function* fetchTiersWatcher() {
    yield takeLatest(FETCH_TIERS_WATCHER, fetchTiersWorker);
};

function* fetchTiersWorker(action) {
    try {
        let response = yield call(fetchTiersApi);
        if (response.status >= 200 && response.status < 300) {
            yield put(setTiers(response.data.data));
        } else {
            throw (response);
        };
    } catch (e) {

    };
};