import React from "react";
import PropTypes from "prop-types";
import LpIconHtmlLinkAdd from "../../../../../icons/LpIconHtmlLinkAdd";
import LpTooltip from "../../../../../LpTooltip";

export default function AddLinkButton({ onClick }) {
	return (
		<button onClick={onClick} className="lp-rich-text-editor__button">
			<LpTooltip text="Link">
				<LpIconHtmlLinkAdd alt="Chain icon" title="Add link" />
			</LpTooltip>
		</button>
	);
}

AddLinkButton.propTypes = {
	onClick: PropTypes.func,
};
