import {
  fetchUserAccountInfo
} from "../../actions/UserAdminPanel";

import {
  showLoader,
  hideLoader
} from '../../actions/GlobalAction'

import {
  takeLatest,
  call,
  put
} from 'redux-saga/effects';

import {
  UNBAN_USER_WATCHER
} from '../../constants/ActionTypes'

import {
  UNBAN_USER_URL
} from '../../constants/Url'

import api from '../../util/api';

export function* unBanUsersWatcher() {
  yield takeLatest(UNBAN_USER_WATCHER, unBanUsersWorker);
}

function* unBanUsersWorker(action) {
  try {
    yield put(showLoader());
    // data is obtained after axios call is resolved
    // let response = yield call(unBanUsersAPI, action.payload, user.id);
    let response = yield call(unBanUsersAPI, action.payload);
    if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
      yield put(fetchUserAccountInfo(response.data.data.userId));
    } else {
      throw (response);
    }
    yield put(hideLoader());
  } catch (e) {
    console.log(e)
    yield put(hideLoader());
  }
}


function unBanUsersAPI(payload) {
  const {
    id
  } = payload;
  return (
    api
    .put(UNBAN_USER_URL(id), {
      ...payload
    })
    .catch(err => {
      return err.response.data
    })
  );
};