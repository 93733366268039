import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { adminFetchQuests } from "../../actions/Quests";
import { appendNotifications } from "../../actions/Notifications";

// Constants
import { UPDATE_QUEST_WATCHER } from "../../constants/ActionTypes";
import { UPDATE_QUEST_URL } from "../../constants/Url";
import { ERROR } from "../../constants/Notifications";

// Utils
import api from "../../util/api";

export function* updateQuestWatcher() {
	yield takeLatest(UPDATE_QUEST_WATCHER, updateQuestWorker);
}

function* updateQuestWorker(action) {
	try {
		// removing is_translation_published, so it will not sent when update unrelated data
		// we might need to refactor on how we update, some part (ex: mission), when we do update,
		// it will also bring all other unrelated data to the payload and sending as body for update quest api
		delete action.payload.is_translation_published;

		const response = yield call(updateQuestApi, action.payload);
		if (response.status >= 200 && response.status < 300) {
			yield put(adminFetchQuests());
		} else {
			yield put(
				appendNotifications({
					type: ERROR,
					message: "An error has occured.",
				})
			);
			throw response;
		}
	} catch (e) {
		const error = e.response.data.error;
		yield put(
			appendNotifications({
				type: ERROR,
				message: "An error has occured" + (error ? ". (" + error + ")" : "."),
			})
		);
		console.error(e);
	}
}

function updateQuestApi(payload) {
	return api.put(UPDATE_QUEST_URL(payload._id), payload);
}
