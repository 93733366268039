import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "../../../../components/Modal";
import CountrySelect from "../../../../components/CountrySelect";
import { updateUserCountry } from "../../../../actions/UserUpdate";

class CountryDialog extends React.Component {
	componentWillMount() {
		let { country, countryCode } = this.props;
		this.setCountryState(country, countryCode);
	}
	componentWillReceiveProps(nextProps) {
		this.setCountryState(nextProps.country);
	}
	setCountryState(country, countryCode) {
		this.setState({
			country: country
				? [{ label: country, value: countryCode ? countryCode : country }]
				: undefined,
		});
	}

	handleSubmit = () => {
		let { updateUserCountry, userId } = this.props;
		let { country } = this.state;

		updateUserCountry(
			{
				user: userId,
				country: country[0].value,
			},
			{
				success: {
					notification: "Successfully updated user country/region.",
					fetchUserProfileForAdminUi: true,
				},
				failure: {
					notification: "Failed to update user country/region. Please try again later.",
				},
			}
		);
	};

	handleClose() {
		let { country } = this.props;
		this.setCountryState(country);
	}

	checkValidation() {
		let { country } = this.props;
		return this.state.country === country;
	}

	render() {
		let { country } = this.state;
		return (
			<Modal
				className="admin"
				triggerBtnText="Change"
				triggerBtnClassName="button"
				cancelBtnText="Cancel"
				submitBtnText="Save"
				submitDisabled={this.checkValidation() || country?.length > 0 ? false : true}
				onSubmit={() => this.handleSubmit()}
				onClose={() => this.handleClose()}
			>
				<CountrySelect
					clearable={country?.length > 0 ? true : false}
					values={country}
					onChange={(value) => this.setState({ country: value })}
				/>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateUserCountry: (params, options) => dispatch(updateUserCountry(params, options)),
	};
};

CountryDialog.propTypes = {
	country: PropTypes.string,
	countryCode: PropTypes.string,
	updateUserCountry: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
};

export default connect(null, mapDispatchToProps)(CountryDialog);
