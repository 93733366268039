import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

// Utils
import moment from "moment";
import { utcToLocale } from "../../../util/DateUtil";
import { titleCase } from "../../../util/stringUtils";

// Structure
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

// Static data
import LpIcon from "../../../components/LpIcon";
import { FRIEND_REFERRAL_URL } from "../../../constants/Url";

// Dialogs
import RoleDialog from "./UserDialog/RoleDialog";
import PointsChangeDialog from "./UserDialog/PointsChangeDialog";
import MembershipDialog from "./UserDialog/MembershipDialog";
import CountryDialog from "./UserDialog/CountryDialog";
import BirthdayDialog from "./UserDialog/BirthdayDialog";
import GenderDialog from "./UserDialog/GenderDialog";
import EmailDialog from "./UserDialog/EmailDialog";
import EmailPreferencesDialog from "./UserDialog/EmailPreferencesDialog";

// Actions
import { updateUsersInfo } from "../../../actions/UserAdminPanel";
import { fetchGames } from "../../../actions/Games";

// Authorize
import Authorize from "../../../auth/Authorize";
import { ADMIN_BANS_ALL } from "../../../constants/Api";

// Constants
import { PLATFORMS } from "../../../constants/StaticCollections";
import { FAN } from "../../../constants/UserRoles";
import { EMAIL_PREFERENCE_OPTIONS } from "../../../constants/SelectionOptions";

class UserAccount extends Component {
	constructor(props) {
		super(props);

		this.state = {
			defaultValue: "No value provided",
		};
	}

	componentDidMount() {
		let { fetchGames } = this.props;
		fetchGames({ active: 1 });
	}

	getValue(data) {
		const { defaultValue } = this.state;
		let value = data || defaultValue;
		return value;
	}

	getUserGender() {
		const { user_account_info } = this.props;
		const { gender } = user_account_info.userInfo;

		return gender || "-";
	}

	getCustomMembershipTierInfo(data) {
		let value = data || "Not Set";
		return value;
	}

	getRoleType(type) {
		return type === FAN ? "User" : "Staff";
	}

	getDate(date) {
		const { defaultValue } = this.state;
		let value = defaultValue;
		if (date) {
			value = moment(date).format("MMM DD, YYYY");
		}
		return value;
	}

	getReferralLink(links) {
		if (links) {
			let link = links.find((record) => record.is_active);
			if (link) {
				return links ? FRIEND_REFERRAL_URL + "/" + link.code : "";
			}
		}
	}

	getPhoneNumber(country_code, phone_number) {
		let contact_info = "No phone number provided";
		if (country_code && phone_number) {
			contact_info = `${country_code}  ${phone_number}`;
		}
		return contact_info;
	}

	getPlatformCredentials(platform) {
		const { user_account_info } = this.props;
		return user_account_info.socialCredentials?.find(
			(item) => item.connection_type === "LINK" && item.platform === platform + "_link",
		);
	}

	setLinkStatusIcon(platform) {
		if (
			platform === "shopify" &&
			this.getPlatformCredentials(platform) &&
			!this.shopifyVerified()
		) {
			return "connected-platform-bg__unverified";
		}
		return this.getPlatformCredentials(platform)
			? "connected-platform-bg__verified"
			: "connected-platform-bg__not-connected";
	}

	renderPlatformTitle(platform) {
		if (platform === "shopify") {
			return "Team Liquid Store";
		}
		if (platform === "alienware") {
			return "Alienware Arena";
		}
		return titleCase(platform);
	}

	getUserPlatformName(platform) {
		let social = this.getPlatformCredentials(platform);
		if (social) {
			return social.social_platform_user_name;
		}
		return null;
	}

	getUserPlatformId(platform) {
		let social = this.getPlatformCredentials(platform);
		if (social) return social.social_id;
		return null;
	}

	shopifyVerified() {
		let shopifyCredentials = this.getPlatformCredentials("shopify");
		return shopifyCredentials?.verified;
	}

	renderPlatforms() {
		return Object.values(PLATFORMS).map((platform) => {
			return (
				<TableRow key={platform.toLowerCase()}>
					<TableCell className="data-table__data-cell" width="95%">
						<div
							className={
								"data-table__multi-line data-table__multi-line--widegap" +
								" " +
								platform.toLowerCase()
							}
						>
							<div className={"connected-platform-bg"}>
								<div className="connected-platform-bg__icon" />
								<div className={this.setLinkStatusIcon(platform)}></div>
							</div>
							<div className="connected-platform-text">
								<span className="data-table__item-title">
									{this.renderPlatformTitle(platform)}
								</span>
								<span className="data-table__item-sub-title">
									{this.getUserPlatformName(platform)}
								</span>
								<span className="data-table__item-sub-title">
									{this.getUserPlatformId(platform)}
								</span>
							</div>
						</div>
					</TableCell>
					<TableCell className="data-table__data-cell" width="5%">
						{/* Not mutable */}
					</TableCell>
				</TableRow>
			);
		});
	}

	renderGames() {
		let imgs = [];
		let { user_account_info, allgames } = this.props;

		if (user_account_info && user_account_info.preferredGames) {
			let prefgameswithicons = user_account_info.preferredGames.map((prefgame) => {
				prefgame.icon = allgames.find((game) => {
					return game.name === prefgame.game_name;
				})?.icon_path;
				return prefgame;
			});

			prefgameswithicons.forEach((game) => {
				imgs.push(
					<LpIcon
						className={"game-img"}
						title={game.game_name}
						arialabel={`${game.game_name} icon`}
						alt={game.game_name}
						src={game.icon}
					/>,
				);
			});
		}
		return imgs;
	}

	// Copy of Account Personalization
	renderEmailPreferences() {
		const { user_account_info } = this.props;

		const Checkmark = (
			<img
				className="checkmark"
				src={require("../../../svg/checkmark.svg")}
				alt="Checkmark"
			/>
		);

		const Xmark = (
			<img className="x-mark" src={require("../../../svg/close.svg")} alt="Close" />
		);

		return (
			<React.Fragment>
				<div>
					{user_account_info.userInfo
						? user_account_info.userInfo.newsletter_subscribed
							? Checkmark
							: Xmark
						: null}
					{EMAIL_PREFERENCE_OPTIONS.COMMUNITY}
				</div>
			</React.Fragment>
		);
	}

	render() {
		const { defaultValue } = this.state;
		const {
			user_account_info,
			userId,
			user_membership_points,
			user_spending_points,
			user_lifetime_points,
		} = this.props;
		let {
			role,
			roleId,
			email,
			is_banned,
			profile_is_public,
			password_updated_at,
			userInfo,
			current_tier,
		} = user_account_info;

		return (
			<React.Fragment>
				<div className="admin">
					{/* Permission */}
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Account Type
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{role && this.getRoleType(role.type)}{" "}
									{is_banned ? "(Banned)" : null}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									{/* Not mutable */}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									User Role
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{role && role.name ? role.name : null}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<Authorize allow={[ADMIN_BANS_ALL]}>
										{role && <RoleDialog userId={userId} userRoleId={roleId} />}
									</Authorize>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>

					{/* Points */}
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Points
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{`${user_membership_points ?? "-"}
                                   | ${user_spending_points ?? "-"}
                                   | ${user_lifetime_points ?? "-"}`}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<PointsChangeDialog
										userId={userId}
										membershipPoints={
											user_membership_points ? user_membership_points : 0
										}
										spendingPoints={
											user_spending_points ? user_spending_points : 0
										}
									/>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Membership
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{userInfo && userInfo.custom_tier_name
										? userInfo.custom_tier_name
										: current_tier
											? current_tier.name
											: ""}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									{userInfo ? (
										<MembershipDialog
											custom_tier_name={userInfo.custom_tier_name}
											userId={userId}
										/>
									) : null}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>

					{/* UserInfo */}
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Country/Region
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{userInfo && this.getValue(userInfo.country)}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									{userInfo ? (
										<CountryDialog
											country={userInfo.country}
											countryCode={userInfo.country_code}
											userId={userId}
										/>
									) : null}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Birth Date
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{userInfo && this.getDate(utcToLocale(userInfo.birthday))}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									{userInfo ? (
										<BirthdayDialog
											birthday={userInfo.birthday}
											userId={userId}
										/>
									) : null}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Identity
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{userInfo && this.getUserGender()}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									{userInfo ? (
										<GenderDialog user={user_account_info} userId={userId} />
									) : null}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>

					{/* email */}
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Email
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{email ? email : defaultValue}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<EmailDialog email={email} userId={userId} />
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>

					{/* password */}
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableCell className="data-table__data-cell" width="30%">
								Password
							</TableCell>
							<TableCell className="data-table__data-cell" width="65%">
								<span>{"*".repeat(20)}</span>
								<br />
								<span>
									Last Change:{" "}
									{moment(password_updated_at).format("MMM DD, YYYY")}
								</span>
							</TableCell>
						</TableBody>
					</Table>

					{/* Account Privacy */}
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Account Privacy
								</TableCell>
								<TableCell className="data-table__data-cell" width="70%">
									{profile_is_public ? "Public" : "Private"}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									<span></span>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>

					{/* Services */}
					<Table className="data-table data-table--page-part">
						<TableBody>{this.renderPlatforms()}</TableBody>
					</Table>

					{/* Games preferences */}
					<Table className="data-table data-table--page-part">
						<TableBody>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Preferred Games
								</TableCell>
								<TableCell
									className="data-table__data-cell preferred-games"
									width="65%"
								>
									{this.renderGames()}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%"></TableCell>
							</TableRow>
							<TableRow>
								<TableCell className="data-table__data-cell" width="30%">
									Email Preferencess
								</TableCell>
								<TableCell className="data-table__data-cell" width="65%">
									{this.renderEmailPreferences()}
								</TableCell>
								<TableCell className="data-table__data-cell" width="5%">
									{userInfo ? (
										<EmailPreferencesDialog
											newsletter_subscribed={userInfo.newsletter_subscribed}
											userId={userId}
										/>
									) : null}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</div>
			</React.Fragment>
		);
	}
}

UserAccount.propTypes = {
	allgames: PropTypes.arrayOf(PropTypes.object),
	user_account_info: PropTypes.shape({
		id: PropTypes.string.isRequired,
		preferredGames: PropTypes.arrayOf(PropTypes.object),
		userInfo: PropTypes.shape({
			newsletter_subscribed: PropTypes.bool,
			custom_tier_name: PropTypes.string,
			country: PropTypes.string,
			birthday: PropTypes.instanceOf(Date),
			gender: PropTypes.string,
			country_code: PropTypes.string,
			phone_number: PropTypes.string,
		}),
		role: PropTypes.string,
		roleId: PropTypes.string,
		email: PropTypes.string,
		is_banned: PropTypes.bool,
		profile_is_public: PropTypes.bool,
		password_updated_at: PropTypes.instanceOf(Date),
		referralLinks: PropTypes.arrayOf(PropTypes.object),
		current_tier: PropTypes.arrayOf(PropTypes.object),
		socialCredentials: PropTypes.arrayOf(PropTypes.object),
	}),
	userId: PropTypes.string.isRequired,
	updateUsersInfo: PropTypes.func.isRequired,
	user_membership_points: PropTypes.number,
	user_spending_points: PropTypes.number,
	user_lifetime_points: PropTypes.number,
	fetchGames: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateUsersInfo: (params, id) => dispatch(updateUsersInfo(params, id)),
		fetchGames: (params) => dispatch(fetchGames(params)),
	};
};

const mapStateToProps = (state) => {
	return {
		allgames: state.global.games,
		user_account_info: state.user_admin.user_account_info,
		userId: state.user_admin.user_account_info?.id,
		user_lifetime_points: state.user_admin.lifetime_points,
		user_spending_points: state.user_admin.spending_points,
		user_membership_points: state.user_admin.membership_points,
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserAccount));
