import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { setAchievementsDisplayBank } from "../../actions/UsersAchievements";

// Util
import api from "../../util/api";

// Constants
import { FETCH_ACHIEVEMENTS_DISPLAY_WATCHER } from "../../constants/ActionTypes";
import { FETCH_ACHIEVEMENTS_DISPLAY_URL } from "../../constants/Url";

export function* fetchAchievementsDisplayWatcher() {
	yield takeLatest(FETCH_ACHIEVEMENTS_DISPLAY_WATCHER, fetchAchievementsDisplayWorker);
}

function* fetchAchievementsDisplayWorker(action) {
	try {
		let options = action.options;
		const response = yield call(fetchAchievementsDisplayApi, action.userId);
		const isSuccess = response.status >= 200 && response.status < 300;

		if (isSuccess) {
			yield put(setAchievementsDisplayBank(response.data.data));
		}

		options = isSuccess ? action.options?.success : action.options?.failure;
		if (options?.callback) yield call(options.callback, response.data);
	} catch (e) {
		console.error(e);
	}
}

function fetchAchievementsDisplayApi(userId) {
	return api
		.get(FETCH_ACHIEVEMENTS_DISPLAY_URL(userId))
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
