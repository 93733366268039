import { setBucketQuestProgressionsTopChannelsBank } from "../../actions/QuestProgressions";
import { takeLatest, call, put } from "redux-saga/effects";
import { FETCH_QUEST_PROGRESSION_BUCKET_TOP_CHANNELS_WATCHER } from "../../constants/ActionTypes";
import { FETCH_QUEST_PROGRESSION_BUCKET_TOP_CHANNELS_URL } from "../../constants/Url";

import api from "../../util/api";

export function* fetchQuestProgressionBucketTopChannelsWatcher() {
	yield takeLatest(
		FETCH_QUEST_PROGRESSION_BUCKET_TOP_CHANNELS_WATCHER,
		fetchQuestProgressionBucketTopChannelsWorker
	);
}

function* fetchQuestProgressionBucketTopChannelsWorker(action) {
	try {
		const response = yield call(fetchQuestProgressionBucketTopChannelsApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;
		if (isSuccess) {
			yield put(setBucketQuestProgressionsTopChannelsBank(response.data.data));
		}
	} catch (e) {
		console.error(e);
	}
}

function fetchQuestProgressionBucketTopChannelsApi(params) {
	return api
		.get(FETCH_QUEST_PROGRESSION_BUCKET_TOP_CHANNELS_URL(params.quest, params.objective))
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
