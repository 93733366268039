import React from "react";
import PropTypes from "prop-types";
import TextStyleButton from "./atoms/TextStyleButton";
import LpIconHtmlBold from "../../../icons/LpIconHtmlBold";
import LpIconHtmlItalic from "../../../icons/LpIconHtmlItalic";
import LpIconHtmlOrderedList from "../../../icons/LpIconHtmlOrderedList";
import LpIconHtmlUnorderedList from "../../../icons/LpIconHtmlUnorderedList";
import LpIconHtmlBlockView from "../../../icons/LpIconHtmlBlockView";
import LpIconHtmlClearFormatting from "../../../icons/LpIconHtmlClearFormatting";
import LpIconHtmlLinkAdd from "../../../icons/LpIconHtmlLinkAdd";
import LpDropdown from "../../../LpDropdown";
import LpTooltip from "../../../LpTooltip";
import AddLinkDialog from "./atoms/AddLinkDialog";

export default function Toolbar(props) {
	const { customEditor, editor, setBlockView, blockViewActive } = props;

	return (
		<aside className="lp-rich-text-editor__toolbar">
			<div className="lp-rich-text-editor__button-group">
				<LpDropdown button={TextStyleButton} className="lp-rich-text-editor__text-styles">
					<button
						type="button"
						onMouseDown={(event) => {
							event.preventDefault();
							customEditor.toggleBlock(editor, "heading-one");
						}}
					>
						Heading 1
					</button>
					<button
						type="button"
						onMouseDown={(event) => {
							event.preventDefault();
							customEditor.toggleBlock(editor, "heading-two");
						}}
					>
						Heading 2
					</button>
					<button
						type="button"
						onMouseDown={(event) => {
							event.preventDefault();
							customEditor.toggleBlock(editor, "heading-three");
						}}
					>
						Heading 3
					</button>
					<button
						type="button"
						onMouseDown={(event) => {
							event.preventDefault();
							customEditor.toggleBlock(editor, "paragraph");
						}}
					>
						Paragraph
					</button>
				</LpDropdown>
			</div>
			<div className="lp-rich-text-editor__button-group">
				<button
					type="button"
					className="lp-rich-text-editor__button"
					active={customEditor.isBoldMarkActive(editor) ? "true" : "false"}
					onMouseDown={(event) => {
						event.preventDefault();
						customEditor.toggleBoldMark(editor);
					}}
				>
					<LpTooltip text="Bold">
						<LpIconHtmlBold alt="Bold text icon" title="Apply strong formatting" />
					</LpTooltip>
				</button>
				<button
					type="button"
					className="lp-rich-text-editor__button"
					active={customEditor.isItalicMarkActive(editor) ? "true" : "false"}
					onMouseDown={(event) => {
						event.preventDefault();
						customEditor.toggleItalicMark(editor);
					}}
				>
					<LpTooltip text="Italic">
						<LpIconHtmlItalic
							alt="Italic text icon"
							title="Apply emphasis formatting"
						/>
					</LpTooltip>
				</button>
			</div>
			<div className="lp-rich-text-editor__button-group">
				<button
					type="button"
					className="lp-rich-text-editor__button"
					active={customEditor.isBlockActive(editor, "unordered-list") ? "true" : "false"}
					onMouseDown={(event) => {
						event.preventDefault();
						customEditor.toggleBlock(editor, "unordered-list");
					}}
				>
					<LpTooltip text="Bulleted List">
						<LpIconHtmlUnorderedList
							alt="Unordered list icon"
							title="Transform element to unordered list item"
						/>
					</LpTooltip>
				</button>
				<button
					type="button"
					className="lp-rich-text-editor__button"
					active={customEditor.isBlockActive(editor, "ordered-list") ? "true" : "false"}
					onMouseDown={(event) => {
						event.preventDefault();
						customEditor.toggleBlock(editor, "ordered-list");
					}}
				>
					<LpTooltip text="Numbered List">
						<LpIconHtmlOrderedList
							alt="Ordered list icon"
							title="Transform element to ordered list item"
						/>
					</LpTooltip>
				</button>
			</div>
			<div className="lp-rich-text-editor__button-group">
				{customEditor.isBlockActive(editor, "link") ? (
					<button
						type="button"
						className="lp-rich-text-editor__button"
						active={customEditor.isBlockActive(editor, "link") ? "true" : "false"}
						onMouseDown={(event) => {
							event.preventDefault();
						}}
					>
						<LpTooltip text="Link">
							<LpIconHtmlLinkAdd alt="Chain icon" title="Add link" />
						</LpTooltip>
					</button>
				) : (
					<AddLinkDialog
						editor={editor}
						customEditor={customEditor}
						updateLink={(payload) => {
							customEditor.applyLink(editor, payload);
							setTimeout(() => customEditor.focusEditor(editor), 250);
						}}
					/>
				)}
			</div>
			<div className="lp-rich-text-editor__button-group">
				<button
					type="button"
					className="lp-rich-text-editor__button"
					onMouseDown={(event) => {
						event.preventDefault();
						customEditor.cleanFormatting(editor);
					}}
				>
					<LpTooltip text="Clear Formatting">
						<LpIconHtmlClearFormatting
							alt="Canceled letter 'T' icon"
							title="Clear formatting"
						/>
					</LpTooltip>
				</button>
			</div>
			<div className="lp-rich-text-editor__button-group">
				<button
					type="button"
					className="lp-rich-text-editor__button"
					onMouseDown={(event) => {
						event.preventDefault();
						setBlockView(!blockViewActive);
					}}
				>
					<LpTooltip text={`${blockViewActive ? "Hide" : "Show"} Blocks`}>
						<LpIconHtmlBlockView alt="Dotted schema icon" title="Toggle block view" />
					</LpTooltip>
				</button>
			</div>
		</aside>
	);
}

Toolbar.propTypes = {
	customEditor: PropTypes.object,
	editor: PropTypes.object,
	setBlockView: PropTypes.func,
	blockViewActive: PropTypes.bool,
};
