import {
  fetchUserAccountInfo
} from "../../actions/UserAdminPanel";

import {
  showLoader,
  hideLoader
} from '../../actions/GlobalAction'

import {
  takeLatest,
  call,
  put
} from 'redux-saga/effects';
import {
  BAN_USER_WATCHER
} from '../../constants/ActionTypes'
import {
  BAN_USER_URL
} from '../../constants/Url'

import api from '../../util/api'; 


export function* banUsersWatcher() {
  yield takeLatest(BAN_USER_WATCHER, banUsersWorker);
}

function* banUsersWorker(action) {
  try {
      yield put(showLoader());
      // data is obtained after axios call is resolved
      let response = yield call(banUsersAPI, action.payload);
      if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
        yield put(fetchUserAccountInfo(response.data.data.userId));
    } else {
        throw (response);
    }
      yield put(hideLoader());
  } catch (e) {
      console.log(e)
      yield put(hideLoader());
  }
}


function banUsersAPI(payload) {
  const {
    id
  } = payload;
  // Axios.defaults.headers.common["Authorization"] = window.browserStorage.getJWT();
  return (
    api
    .post(BAN_USER_URL(id), {
      ...payload
    })
    .catch(err => {
      return err.response.data
    })
  );
};