// admin
export const ADMIN_USERS_COUNT = "^/api/admin/users/count$";
export const ADMIN_USERS_SEARCH = "^/api/admin/users/search$";
export const ADMIN_STAFF_ALL = "^/api/admin/staff/all$";
export const ADMIN_USER_ACCOUNT = "^/api/admin/user/[a-f0-9-]+/account$";
export const ADMIN_BANS_ALL = "^/api/admin/bans/all$";
export const ADMIN_BAN_USER = "^/api/admin/user/[a-f0-9-]+/ban$";
export const ADMIN_UNBAN_USER = "^/api/admin/user/[a-f0-9-]+/unban$";
export const OPERATION_HEALTH = "^/api/operations/health$";

// events
export const ADMIN_EVENTS_CREATE = "^/api/events/create$";
export const ADMIN_EVENTS_UPDATE = "^/api/events/update/[a-f0-9-]+$";

//rewards
export const GET_REWARDS = "^/rewards/api$";
export const SEARCH_REWARDS = "^/rewards/api/search$";
export const GET_REWARDS_EXCHANGE = "^/rewards/api/exchange$";
export const UPDATE_REWARDS = "^/rewards/api/update/[a-f0-9-]+$";

//rewards-orders
export const FULLFILLED_ORDERS = "^/rewards/orders/FULFILLED$";
export const PENDING_ORDERS = "^/rewards/orders/PENDING$";
export const SHOPIFY_ORDERS = "^/rewards/orders/SHOPIFY$";
export const ORDER_DETAIL_BY_ID = "^/rewards/orders/[a-f0-9-]+$";
export const GET_USER_ORDER_BY_USER = "^/rewards/orders/user-orders/[a-f0-9-]+$";

export const ADMIN_ACCESS_GROUP = [
	ADMIN_USERS_COUNT,
	ADMIN_USERS_SEARCH,
	ADMIN_BANS_ALL,
	ADMIN_STAFF_ALL,
	ADMIN_USER_ACCOUNT,
	ADMIN_BAN_USER,
	ADMIN_UNBAN_USER,
	ADMIN_EVENTS_CREATE,
	ADMIN_EVENTS_UPDATE,
	SEARCH_REWARDS,
	GET_REWARDS,
	GET_REWARDS_EXCHANGE,
	UPDATE_REWARDS,
	FULLFILLED_ORDERS,
	PENDING_ORDERS,
	SHOPIFY_ORDERS,
	ORDER_DETAIL_BY_ID,
];
