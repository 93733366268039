import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./styles.scss";

// Actions
import { fetchTotalUsers, fetchSearchedUsers } from "../../../../actions/UserAdminPanel";

// Components
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

class UserSearch extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchField: "",
			searched: false,
		};
	}

	componentDidMount() {
		const { fetchTotalUsers } = this.props;
		fetchTotalUsers();
	}

	handleChange = (e) => {
		this.setState({ searchField: e.target.value || "" });
	};

	handleSubmit = (e) => {
		const { searchField } = this.state;
		const { fetchSearchedUsers } = this.props;

		if (searchField) {
			fetchSearchedUsers(searchField);
			this.setState({ searched: true });
		} else {
			this.setState({ searched: false });
		}
	};

	renderUserInfo() {
		const { searched } = this.state;
		const { count_users, users } = this.props;

		if (!searched) {
			return (
				<p>
					There {count_users === 1 ? "is" : "are"} currently {count_users}{" "}
					{count_users === 1 ? "account" : "accounts"} in the Team Liquid database. To search for users,
					you can type in their user id, username, first/last name, email, or role.
				</p>
			);
		} else {
			if (users) {
				return (
					<div>
						<div>
							<p>
								Found {users.length} {users.length === 1 ? "match" : "matches"}:
							</p>
						</div>
						<div>
							<Table className="data-table">
								<TableBody>{this.renderUserList(users)}</TableBody>
							</Table>
						</div>
					</div>
				);
			} else {
				return <p>Loading results...</p>;
			}
		}
	}

	renderUserList = (List) => {
		return List.map((item) => {
			return (
				<TableRow key={item.id}>
					<TableCell className="data-table__data-cell" width="50%">
						<div className="data-table__multi-line">
							{item.userInfo.avatar?.large_icon_url ? (
								<img
									className="data-table__inline-image users-list__avatar"
									alt={item.userInfo.username}
									src={item.userInfo.avatar.large_icon_url}
								/>
							) : (
								<div className="users-list__no-avatar" />
							)}
							<div>
								<span className="data-table__item-title">{item.userInfo.username}</span>
								<br />
								<span className="data-table__item-sub-title">{item.email}</span>
							</div>
						</div>
					</TableCell>
					<TableCell className="data-table__data-cell" width="45%">
						{item.role.name}
					</TableCell>

					<TableCell className="data-table__data-cell" width="5%">
						<Link to={`/app/accounts/users/${item.id}`}>
							<Button size="small" variant="text" mini={true}>
								<span>
									<i className="zmdi zmdi-chevron-right zmdi-hc-lg" />
								</span>
							</Button>
						</Link>
					</TableCell>
				</TableRow>
			);
		});
	};

	render() {
		const { searchField } = this.state;

		return (
			<div className="m-3 p-3 admin users-list">
				<FormControl>
					<InputLabel htmlFor="name-helper">Search </InputLabel>
					<Input
						id="name-helper"
						onChange={this.handleChange}
						onKeyPress={(event) => {
							if (event.key === "Enter") {
								this.handleSubmit();
							}
						}}
					/>
					<FormHelperText>{searchField ? "Press Enter to search" : "Search Users"}</FormHelperText>
				</FormControl>
				{this.renderUserInfo()}
			</div>
		);
	}
}

UserSearch.propTypes = {
	fetchTotalUsers: PropTypes.func,
	fetchSearchedUsers: PropTypes.func,
	count_users: PropTypes.number,
	users: PropTypes.array,
};

const mapDispatchToProps = (dispatch) => ({
	fetchTotalUsers: () => dispatch(fetchTotalUsers()),
	fetchSearchedUsers: (searchField) => dispatch(fetchSearchedUsers(searchField)),
});

const mapStateToProps = (state) => ({
	count_users: state.user_admin.count_users,
	users: state.user_admin.users,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSearch);
