import React, { Component } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { recentEvents } from "../../../actions/Events";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { COLLECTIONS } from "../../../constants/PaginationData";
import { formatDate, formatDateShort } from "../../../util/DateUtil";
import { TYPES,EVENT_STATUS } from "./MockData";

class EventsCurrent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowsPerPageOptions: COLLECTIONS.ROW_PER_PAGE_OPTIONS,
            page: COLLECTIONS.page,
            limit: COLLECTIONS.defaultRowPerPage,
        };

        this.fetchRecentEvents();
    }

    fetchRecentEvents = () => {
        this.props.recentEvents({
            page: this.state.page + 1,
            limit: this.state.limit,
            status:EVENT_STATUS.COMPLETED
        });
    }

    handleChangePage = (event, newPage) => {
        if (this.state.page !== newPage) {
            this.setState({
                page: newPage
            },
                () => {
                    this.fetchRecentEvents()
                });
        }
    };

    handleChangeRowsPerPage = event => {
        if (event.target.value) {
            this.setState({
                page: 0,
                limit: event.target.value
            },
                () => {
                    this.fetchRecentEvents()
                });
        }
    };

    renderEventStatus(dateStart, dateEnd) {
        let date_Start = new Date(dateStart);
        let date_End = new Date(dateEnd);
        return formatDateShort(date_Start) + " - " + formatDate(date_End);
    }

     mapEvents() {
        if (this.props.events) {
            return this.props.events.map(event => {
                return (
                    <TableRow key={event.item_id}>
                        <TableCell className="data-table__data-cell" width="30%">
                            <div className="data-table__multi-line data-table__multi-line--no-icon ">
                                <div>
                                    <span className="data-table__item-title">{event.name}</span><br />
                                    <span className="data-table__item-sub-title">{event.location} ({(event.event_type === TYPES.OFFLINE) ? "Offline" : "Digital"})</span>
                                </div>
                            </div>
                        </TableCell>
                        <TableCell className="data-table__data-cell" width="45%">
                            {event.is_active ?
                                <img className="data-table__trailing-icon" alt="Check in active" src={require("../../../svg/connect_1.svg")} />
                                : null
                            }
                        </TableCell>
                        <TableCell className="data-table__data-cell" width="20%">{this.renderEventStatus(event.start_date, event.end_date)}</TableCell>
                        <TableCell className="data-table__data-cell" width="5%" >
                        <Link to={`/app/events/${event.id}`}>
                                <button className="button button--circle button--borderless">
                                    <span>
                                        <i className="zmdi zmdi-chevron-right zmdi-hc-lg" />
                                    </span>
                                </button>
                            </Link>
                        </TableCell>
                    </TableRow>
                )
            })
        }
    }

    tablePagination() {
        if (this.props.count > this.state.limit)
            return (
                <TablePagination
                    className={`pagination`}
                    component="div"
                    count={this.props.count}
                    rowsPerPage={this.state.limit}
                    rowsPerPageOptions={this.state.rowsPerPageOptions}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    classes={{
                        select: 'pagination__select'
                    }}
                />
            )
    }

    render() {
        return (
            <div className="mt-4">
                <div className="d-flex">
                    <div className="col-12 pt-2">
                        {this.tablePagination()}
                    </div>
                </div>
                <Table className="data-table">
                    <TableHead>
                        <TableRow>
                            <TableCell class="data-table__header" colspan="2">Event</TableCell>
                            <TableCell class="data-table__header" >Date(s)</TableCell>
                            <TableCell class="data-table__header" r width="10%" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.mapEvents()}
                    </TableBody>
                </Table>
                <div>
                    {this.tablePagination()}
                </div>
                <span class="data-table__legend">
                    <img className="data-table__legend-icon" alt="Check in active" src={require("../../../svg/connect_1.svg")} /> Event Check-in is Enabled
                </span>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        recentEvents: params => dispatch(recentEvents(params))
    }
}

const mapStateToProps = state => {
    return {
        events: state.events.recent_events,
        count: state.events.events_count
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsCurrent)
