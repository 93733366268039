import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import { UPDATE_USER_ONBOARDED } from "../../../constants/ActionTypes";
import { USER_ONBOARDED_URL } from "../../../constants/Url";
import { SUCCESS, ERROR } from "../../../constants/Notifications";
import { appendNotifications } from "../../../actions/Notifications";
import { addMessage } from "../../../actions/Messages";
import { setUserProfileParam } from "../../../actions/User";

import api from "../../../util/api";

export function* updateUserOnboardedWatcher() {
	yield takeLatest(UPDATE_USER_ONBOARDED, updateUserOnboardedWorker);
}

/**
 * @param {String} action.params.user The ID of the user to update.
 * @param {String} action.params.onboarded The value to update the user's ```onboarded``` to.
 *
 * @param {Object} [action.options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [action.options.success] Behavior to execute when API call and action is successful. (2xx code)
 * @param {String} [action.options.success.redirect] Redirect to this URL on success.
 * @param {String} [action.options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [action.options.success.notification] Display notification with passed text on success.
 * @param {Function} [action.options.success.callback] Callback function on success.
 * @param {*} [action.options.success.callbackArgs] Callback function args on success.
 * @param {Boolean} [action.options.success.refreshUserOnboarded] Update the Redux store with the user's new ```onboarded``` value.
 *
 * @param {Object} [action.options.failure] Behavior to execute when API call and action is failed. (non-2xx and non-4xx code)
 * @param {String} [action.options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [action.options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [action.options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [action.options.failure.callback] Callback function on failure.
 * @param {*} [action.options.failure.callbackArgs] Callback function args on failure.
 *
 * @param {Object} [action.options.bad_request] Behavior to execute when API call and action is failed due to bad request. (4xx code)
 * @param {String} [action.options.bad_request.redirect] Redirect to this URL on bad request.
 * @param {String} [action.options.bad_request.message] Push message with passed text to ```state.messages.bank``` on bad request.
 * @param {String} [action.options.bad_request.notification] Display notification with passed text on bad request.
 * @param {Function} [action.options.bad_request.callback] Callback function on bad request.
 * @param {*} [action.options.bad_request.callbackArgs] Callback function args on bad request.
 */

// eslint-disable-next-line complexity
function* updateUserOnboardedWorker(action) {
	try {
		const response = yield call(updateUserOnboardedApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;

		if (action.params.onboarded === 1 && isSuccess) {
			const jwt = window.browserStorage.getJWT();
			window.browserStorage.storeJWT(jwt);
		}

		let options;
		switch (true) {
			case response.status >= 200 && response.status < 300: {
				options = action?.options?.success;
				break;
			}
			case response.status >= 400 && response.status < 500: {
				options = action?.options?.bad_request;
				break;
			}
			default: {
				options = action?.options?.failure;
				break;
			}
		}
		if (isSuccess && options?.refreshUserOnboarded) {
			yield put(setUserProfileParam("onboarded", response.data.data.onboarded));
			yield put(setUserProfileParam("current_tier", response.data.data.current_tier));
		}
		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.message) {
			yield put(
				addMessage({
					source: UPDATE_USER_ONBOARDED,
					message: options.message,
					isError: isSuccess,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		}
		if (options?.redirect) {
			yield put(push(options.redirect));
		}
	} catch (e) {
		console.error(e);
	}
}

/**
 * Sends an API request to update a user's ```onboarded``` property.
 * @param {String} params.user The ID of the user to update.
 * @param {String} params.onboarded The value to update the user's ```onboarded``` to.
 * @return {Object} The response object from the API call.
 */
function updateUserOnboardedApi(params) {
	return api
		.put(USER_ONBOARDED_URL(params.user), {
			onboarded: params.onboarded,
			awardPoints: params.awardPoints,
			updateTier: params.updateTier,
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
