import {
    SET_ALL_PERKS    
} from "../constants/ActionTypes";

const INIT_STATE = {
    bank: []    
};

export default function PerksReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case SET_ALL_PERKS: {
            return {
                ...state,
                bank: action.payload
            }
        }        
        default:
            return state;
    }
}