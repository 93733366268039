import {
    all,
} from "redux-saga/effects";
import {
    updateUserRoleWatcher,
} from "./UpdateUserRoleSagas";
import {
    updateUserOnboardedWatcher,
} from "./UpdateUserOnboardedSagas";
import {
    updateUserUsernameWatcher,
} from "./UpdateUserUsername";
import {
    updateUserMembershipWatcher,
} from "./UpdateUserMembership";
import {
    updateUserEmailWatcher,
} from "./UpdateUserEmail";
import {
    updateUserCountryWatcher,
} from "./UpdateUserCountry";
import {
    updateUserBirthdayWatcher,
} from "./UpdateUserBirthday";
import {
    updateUserGenderWatcher,
} from "./UpdateUserGender";

import {
    verifyAndUpdateChangeEmailRequestWatcher,
} from "./VerifyAndUpdateChangeEmailSagas";
import { updateParentConsentWatcher } from "./UpdateUserParentConsent";

export function* userUpdateSagas() {
    yield all([
        updateUserRoleWatcher(),
        updateUserOnboardedWatcher(),
        updateUserUsernameWatcher(),
        updateUserMembershipWatcher(),
        updateUserEmailWatcher(),
        updateUserCountryWatcher(),
        updateUserBirthdayWatcher(),
        updateUserGenderWatcher(),
        updateParentConsentWatcher(),
        verifyAndUpdateChangeEmailRequestWatcher(),
    ]);
};
