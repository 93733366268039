import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "../../../../components/Modal";
import RadioField from "../../../../components/RadioField";
import { ADMIN, ACCOUNTS, USERS } from "../../../../constants/RoutesURLs";

const ARCHIEVE = "ARCHIVE";
const NO_ARCHIVE = "NO_ARCHIVE";

class DeleteDialog extends Component {
	constructor(props) {
		super(props);

		this.state = {
			deleteType: ARCHIEVE,
		};
	}

	changeType = (event) => {
		this.setState({
			deleteType: event.target.value,
		});
	};

	onDelete = () => {
		const { id, deleteUser } = this.props;

		deleteUser(
			{
				id,
				noArchive: this.state.deleteType === NO_ARCHIVE,
			},
			{
				success: {
					notification: "User deleted.",
					redirect: `${ADMIN}${ACCOUNTS}${USERS}`,
				},
				failure: {
					notification: "Deletion failed.",
				},
			}
		);
	};

	render() {
		const { deleteType } = this.state;

		return (
			<Modal
				className="admin"
				triggerBtnText="Delete Account"
				triggerBtnClassName="button button--borderless"
				cancelBtnText="Cancel"
				submitBtnText="Delete"
				onSubmit={this.onDelete}
			>
				<p>
					Before you can delete this account, please select the method
					of deletion. Warning, deleting an account cannot be undone.
				</p>

				<RadioField
					checked={deleteType === ARCHIEVE}
					id="standart"
					label="Delete with Anonymization (standard)"
					onChange={this.changeType}
					name="deleteType"
					value={ARCHIEVE}
				/>
				<RadioField
					checked={deleteType === NO_ARCHIVE}
					id="full"
					name="deleteType"
					onChange={this.changeType}
					label="Delete Everything (user is under 16)"
					value={NO_ARCHIVE}
				/>
			</Modal>
		);
	}
}

DeleteDialog.propTypes = {
	id: PropTypes.string.isRequired,
	deleteUser: PropTypes.func.isRequired,
};

export default DeleteDialog;
