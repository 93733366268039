import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import LpHeading from "../../../components/LpHeading/index.jsx";
import { fetchPermissionList } from "../../../actions/UserAdminPanel.js";

class Permissions extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.fetchPermissionList();
	}

	render() {
		return (
			<div id="Permissions" className="container m-3 p-3 admin">
				<div>
					<LpHeading>Permissions</LpHeading>
				</div>
				<table className="data-table">
					<thead>
						<tr>
							<th className="data-table__header" width="60%">
								Permission URL
							</th>
							<th className="data-table__header" width="20%">
								Status
							</th>
							<th className="data-table__header" width="20%">
								Created At
							</th>
						</tr>
					</thead>
					<tbody>
						{this.props.permissions.map((permission) => {
							return (
								<tr key={permission.id}>
									<td
										className="data-table__data-cell"
										width="60%"
									>
										{permission.url}
									</td>
									<td
										className="data-table__data-cell"
										width="20%"
									>
										<span
											className={
												permission.is_active
													? "text-green"
													: "text-red"
											}
										>
											{permission.is_active
												? "Active"
												: "Inactive"}
										</span>
									</td>
									<td
										className="data-table__data-cell"
										width="20%"
									>
										{permission.createdAt.toLocaleString(
											"en-US"
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		);
	}
}

Permissions.propTypes = {
	permissions: PropTypes.arrayOf(PropTypes.shape({})),
	fetchPermissionList: PropTypes.func.isRequired,
};

Permissions.defaultProps = {
	permissions: [],
};

const mapStateToProps = (state) => {
	return {
		permissions: state.user_admin.permissions,
	};
};

const mapDispatchToProps = {
	fetchPermissionList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Permissions);
