import {
    all,
} from "redux-saga/effects";
import {
	userUpdateSagas,
} from "./UpdateUserSagas";
import {
	claimedPerksSagas,
} from "./ClaimedPerksSagas";

export function* usersSagas() {
    yield all([
        userUpdateSagas(),
        claimedPerksSagas(),
    ]);
};