import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "../styles.scss";

// Components
import Modal from "../../../../components/Modal";
import { FormFieldInput } from "../../../../components/FormFields";

// Actions
import { updateAchievement } from "../../../../actions/Achievements";

const ChangeAchievementModal = ({
	name: p_name,
	icon_path: p_icon_path,
	achievementId,
	updateAchievement,
}) => {
	const [name, setName] = useState(p_name);
	const [icon_path, setIcon_path] = useState(p_icon_path);
	const [previewImage, setPreviewImage] = useState(null);

	useEffect(() => {
		setIcon_path(p_icon_path);
		setPreviewImage(null);
		return () => {
			setIcon_path(null);
			setPreviewImage(null);
		};
	}, [p_icon_path]);

	function handleClose() {
		setName(p_name);
		setIcon_path(p_icon_path);
	}

	function handleSubmit() {
		updateAchievement(
			{
				name,
				icon_path: previewImage ? previewImage : icon_path,
			},
			achievementId,
			{
				success: {
					notification: "Achievement updated.",
				},
				failure: {
					notification: "Oops, that didn't work. Can you try again?",
				},
			}
		);
		setPreviewImage(null);
	}

	return (
		<Modal
			className="admin"
			triggerBtnText="Change"
			triggerBtnClassName="button"
			cancelBtnText="Cancel"
			submitBtnText="Save"
			onSubmit={() => handleSubmit()}
			onClose={() => handleClose()}
		>
			<div className="form-field form-field--input-image">
				<figure className="icon-placeholder">
					{previewImage ? (
						<img src={previewImage} alt="achievement_icon" />
					) : !icon_path ? (
						<img
							src={require("../../../../svg/icon-placeholder.svg")}
							alt="default_achiement_icon"
						/>
					) : (
						<img src={icon_path} alt="achievement_icon" />
					)}
					<figcaption className="icon-placeholder__caption">Achievement Image</figcaption>

					<Modal
						className="admin"
						triggerBtnText="Change"
						triggerBtnClassName="button button--borderless"
						cancelBtnText="Cancel"
						submitBtnText="Save"
						onClose={() => setPreviewImage(null)}
						onSubmit={() => {
							setIcon_path(previewImage);
							setPreviewImage(null);
						}}
					>
						<FormFieldInput
							intro="Paste the url for the image that exists in the CDN."
							label="URL"
							type="text"
							name="previewImage"
							value={previewImage}
							defaultValue={icon_path}
							placeholder="cdn.teamliquid.com/uri-that-matches"
							onChange={(event) => setPreviewImage(event.target.value)}
						/>
					</Modal>
				</figure>
			</div>
			<FormFieldInput
				label="Attachemnt name"
				type="text"
				name="name"
				value={name}
				placeholder="the description"
				maxLength="95"
				onChange={(event) => setName(event.target.value)}
			/>
		</Modal>
	);
};

ChangeAchievementModal.propTypes = {
	name: PropTypes.string,
	icon_path: PropTypes.string,
	achievementId: PropTypes.string,
	updateAchievement: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
	updateAchievement: (params, id, options) => dispatch(updateAchievement(params, id, options)),
});

export default connect(null, mapDispatchToProps)(ChangeAchievementModal);
