import React from "react";
import "./styles.scss";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class Authorize extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isAllowed: false,
		};
	}

	_checkPermissionExist(allowedURL) {
		let status = false;
		let permissionList = window.browserStorage.getPermission();
		if (typeof permissionList === "undefined" || permissionList === null) {
			return status;
		}
		allowedURL.find((url) => {
			for (let item in permissionList.role_permission) {
				let permissionURL =
					permissionList.role_permission[item].permission.url;
				if (permissionURL === url) {
					status = true;
					break;
				}
			}
			return;
		});
		return status;
	}

	renderRedirectRoute() {
		if (this.props.redirect) {
			return <Redirect to={this.props.redirect} />;
		}
		return null;
	}

	render() {
		return (
			<React.Fragment>
				{this._checkPermissionExist(this.props.allow)
					? this.props.children
					: this.renderRedirectRoute()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = ({ user }) => {
	return { profile: user.profile };
};

export default connect(mapStateToProps)(Authorize);
