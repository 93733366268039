import {
    SELECT_REWARD,
    SET_REWARDS_BANK,
    SET_POTENTIAL_REWARDS,
    SET_SUCCESFUL_ORDER,
    REMOVE_TEMPORARY_CONFIRMATION,
    APPEND_POTENTIAL_REWARDS,
    SET_NEW_REWARD,
    PUSH_NEW_REWARD,
    REPLACE_NEW_REWARD
} from '../constants/ActionTypes'

const rewardsInitialState = {
    bank: [],
    potential: [],
    selected: {},
    new: {},
    purchase: null
};

export default function rewardsReducer(state = rewardsInitialState, action) {

    switch (action.type) {
        case SET_NEW_REWARD:
            return {
                ...state,
                new: {
                    ...state.new,
                    [action.payload.arg]: action.payload.value
                }
            }
        case SET_SUCCESFUL_ORDER: {
            return {
                ...state,
                purchase: action.order
            }
        }
        case REMOVE_TEMPORARY_CONFIRMATION: {
            return {
                ...state,
                purchase: null
            }
        }
        case PUSH_NEW_REWARD:
            return {
                ...state,
                new: {
                    ...state.new,
                    [action.payload.arg]: [...state.new[action.payload.arg], action.payload.value]
                }
            }
        case REPLACE_NEW_REWARD:
            return {
                ...state,
                new: {
                    ...action.payload
                }
            }
        case SELECT_REWARD:
            return {
                ...state,
                selected: {
                    ...action.payload
                }
            }
        case SET_REWARDS_BANK:
            return {
                ...state,
                bank: [...action.payload]
            }
        case SET_POTENTIAL_REWARDS:
            return {
                ...state,
                potential: [...action.payload]
            }
        case APPEND_POTENTIAL_REWARDS:
            return {
                ...state,
                potential: [...state.potential, action.payload]
            }
        default:
            return state;
    }
}