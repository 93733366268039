import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import { DELETE_TWITTER_SERVER } from "../../../constants/ActionTypes";
import { TWITTER_CHANNELS_URL } from "../../../constants/Url";
import { SUCCESS, ERROR } from "../../../constants/Notifications";
import { appendNotifications } from "../../../actions/Notifications";
import { addMessage } from "../../../actions/Messages";
import { fetchTwitterData } from "../../../actions/Channel";

import api from "../../../util/api";

export function* removeTwitterDataWatcher() {
	yield takeLatest(DELETE_TWITTER_SERVER, removeTwitterDataWorker);
}

function* removeTwitterDataWorker(action) {
	try {
		const response = yield call(removeTwitterDataApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;

		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.message) {
			yield put(
				addMessage({
					source: DELETE_TWITTER_SERVER,
					message:
						typeof options.message === "string"
							? options.message
							: isSuccess
							? "Success."
							: response?.data?.error || "Error.",
					isError: !isSuccess,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		}
		if (options?.redirect) {
			yield put(push(options.redirect));
		}
		if (options?.fetchTwitterData && isSuccess) {
			yield put(
				fetchTwitterData(null, {
					success: {
						setTwitterData: true,
					},
				})
			);
		}
	} catch (e) {
		console.error(e);
	}
}

function removeTwitterDataApi(params) {
	return api
		.delete(TWITTER_CHANNELS_URL, {
			data: params,
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
