import { takeLatest, call, put } from "redux-saga/effects";
import { AUTHORIZE_TWITCH_APP } from "../../../constants/ActionTypes";
import { TWITCH_AUTHORIZE_OAUTH } from "../../../constants/Url";
import { SUCCESS, ERROR } from "../../../constants/Notifications";
import { appendNotifications } from "../../../actions/Notifications";
import { addMessage } from "../../../actions/Messages";

import api from "../../../util/api";

export function* authorizeTwitchOauthWatcher() {
	yield takeLatest(AUTHORIZE_TWITCH_APP, authorizeTwitchOauthWorker);
}

/**
 * Worker saga for starting the authorization process for Twitch APP
 */
function* authorizeTwitchOauthWorker(action) {
	try {
		const response = yield call(authorizeTwitchOauthApi, action.params);
		const status = response.status;
		let options;
		let isSuccess;
		if (status >= 200 && status < 400) {
			isSuccess = true;
			options = action?.options?.success;
		} else {
			isSuccess = false;
			options = action?.options?.failure;
		}

		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.message) {
			yield put(
				addMessage({
					source: TWITCH_AUTHORIZE_OAUTH,
					message: options.message,
					isError: !isSuccess,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, response);
		}
	} catch (e) {
		console.error(e);
	}
}

/** API CALL to start the authorization process for Twitch APP*/
function authorizeTwitchOauthApi(params) {
	const { defaultMod, name } = params;

	return api
		.post(TWITCH_AUTHORIZE_OAUTH, { defaultMod, name })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
