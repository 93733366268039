import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { getElapsedTime, dateCompare } from '../../../util/DateUtil';
import { fetchUserInteractions } from "../../../actions/UserAdminPanel";
import { COLLECTIONS } from "../../../constants/PaginationData";

class UserInteractions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPageOptions: COLLECTIONS.ROW_PER_PAGE_OPTIONS,
      page: COLLECTIONS.page,
      limit: COLLECTIONS.defaultRowPerPage,
    };
  }

  componentDidMount() {
    if (this.props.userId) {
      this.fetchUserInteractions();
    }
  }

  fetchUserInteractions = () => {
    this.props.fetchUserInteractions({
      userId: this.props.userId,
      page: this.state.page + 1,
      limit: this.state.limit,
    });
  }

  getPlatformLogo(platform) {
    let className = "mt-1 icon-postion social-icon social-icon--"
    switch (platform) {
      case 'twitch':
      case 'twitter':
      case 'reddit':
      case 'discord':
      case 'shopify':
        return className + platform
      default:
        return className + 'teamliquid'
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    },
      () => {
        this.fetchUserInteractions();
      });
  };

  handleChangeRowsPerPage = event => {
    if (event.target.value) {
      this.setState({
        page: 0,
        limit: event.target.value
      },
        () => {
          this.fetchUserInteractions();
        });
    }
  };

  tablePagination() {
    if (this.props.count > this.state.limit) {
      return (
        <TablePagination
          className={`float-right`}
          component="div"
          count={this.props.count}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={this.state.rowsPerPageOptions}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          classes={{
            select: 'pagination-select'
          }}
        />
      )
    }
  }

  loadTableBody() {
    if (this.props.interactions && this.props.interactions.length) {
      return this.props.interactions.map(item => {
        return (
          <TableRow key={item.id}>
            <TableCell width="50%">
              <span className={this.getPlatformLogo(item.pointsRecordType.record_name.toLowerCase())} />
              <span className="pl-2">{item.reason}</span>
            </TableCell>
            <TableCell width="25%">{moment.utc(item.createdAt).format('YYYY.MM.DD')}  |  {moment.utc(item.createdAt).format('HH:mm:ss')} </TableCell>
            <TableCell width="25%" className={`align-right`}>{item.points_added - item.points_removed}</TableCell>
          </TableRow>
        );
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="mt-2 mb-4 pb-4">
          {this.tablePagination()}
        </div>
        <div className="table-responsive-material shadow border-0 card table-width table-radious">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="50%">Interaction</TableCell>
                <TableCell width="25%">Time</TableCell>
                <TableCell width="25%" className={`align-right`}>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.loadTableBody()}
            </TableBody>
          </Table>
        </div>
        <div>
          {this.tablePagination()}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserInteractions: (params) => dispatch(fetchUserInteractions(params)),
  }
}

const mapStateToProps = (state) => {
  return {
    interactions: state.user_admin.user_interactions,
    count: state.user_admin.user_interactions_count,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInteractions)