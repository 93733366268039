export const setNestedObjectParam = (state, path, value, delimiter = '.', recursive = false) => {
    state = {...state}
    path = path.split(delimiter)
    let level = 0;
    
    path.reduce((parameter, key) => {
        level++;

        if (recursive && typeof parameter[key] === "undefined" && level !== path.length) {
            parameter[key] = {};
            return parameter[key];
        }

        if (level === path.length) {
            parameter[key] = value;
            return value;
        } else {
            return parameter[key];
        }
    }, state);

    return state
}