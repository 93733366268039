import {
    all,
} from "redux-saga/effects";

import {
    fetchCampaignsWatcher,
} from "./FetchCampaignsSagas";
import {
    fetchPublicCampaignsWatcher,
} from "./FetchPublicCampaignsSagas"
import {
    createCampaignsWatcher,
} from "./CreateCampaignsSagas";
import {
    updateCampaignsWatcher,
} from "./UpdateCampaignsSagas";

import {
    fetchPublicCampaignWatcher,
} from "./FetchPublicCampaignSagas"

import {
    enterCampaignsWatcher,
} from "./EnterCampaignsSagas"

export function *campaignsSagas() {
    yield all([
        fetchCampaignsWatcher(),
        fetchPublicCampaignsWatcher(),
        fetchPublicCampaignWatcher(),
        enterCampaignsWatcher(),
        createCampaignsWatcher(),
        updateCampaignsWatcher()
    ]);
};
