import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { setCampaignTranslationStatus } from "../../actions/CampaignTranslations";
import { setCampaignTranslation } from "../../actions/CampaignTranslations";

// Util
import api from "../../util/api";

// Constants
import { FETCH_CAMPAIGN_TRANSLATIONS_STATUS_WATCHER } from "../../constants/ActionTypes";
import { FETCH_CAMPAIGN_TRANSLATIONS_STATUS_URL } from "../../constants/Url";

export function* fetchCampaignTranslationsStatusWatcher() {
	yield takeLatest(
		FETCH_CAMPAIGN_TRANSLATIONS_STATUS_WATCHER,
		fetchCampaignTranslationsStatusWorker
	);
}

function* fetchCampaignTranslationsStatusWorker(action) {
	try {
		const response = yield call(fetchCampaignTranslationsStatusApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (isSuccess) {
			yield put(setCampaignTranslation([]));
			yield put(setCampaignTranslationStatus(response.data.data));
			if (options?.callback) yield call(options.callback, response);
		} else {
			throw response;
		}
	} catch (e) {
		console.error(e);
	}
}

function fetchCampaignTranslationsStatusApi(experienceId) {
	return api.get(FETCH_CAMPAIGN_TRANSLATIONS_STATUS_URL(experienceId));
}
