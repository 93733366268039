import {
	FETCH_CAMPAIGN_TRANSLATIONS_WATCHER,
	SET_CAMPAIGN_TRANSLATIONS,
	PATCH_CAMPAIGN_TRANSLATIONS_WATCHER,
	FETCH_CAMPAIGN_TRANSLATIONS_STATUS_WATCHER,
	SET_CAMPAIGN_TRANSLATIONS_STATUS,
	PATCH_CAMPAIGN_TRANSLATIONS_PUBLISHED_STATUS_WATCHER,
	UPDATE_CAMPAIGN_TRANSLATIONS_PUBLISHED_STATUS,
} from "../constants/ActionTypes";

export const fetchCampaignTranslations = (payload) => {
	return {
		type: FETCH_CAMPAIGN_TRANSLATIONS_WATCHER,
		payload,
	};
};

export const setCampaignTranslation = (payload) => {
	return {
		type: SET_CAMPAIGN_TRANSLATIONS,
		payload,
	};
};

export const patchCampaignTranslations = (payload) => {
	return {
		type: PATCH_CAMPAIGN_TRANSLATIONS_WATCHER,
		payload,
	};
};

export const fetchCampaignTranslationsStatus = (payload) => {
	return {
		type: FETCH_CAMPAIGN_TRANSLATIONS_STATUS_WATCHER,
		payload,
	};
};

export const setCampaignTranslationStatus = (payload) => {
	return {
		type: SET_CAMPAIGN_TRANSLATIONS_STATUS,
		payload,
	};
};

export const patchCampaignTranslationsPublishedStatus = (payload) => {
	return {
		type: PATCH_CAMPAIGN_TRANSLATIONS_PUBLISHED_STATUS_WATCHER,
		payload,
	};
};

export const updateCampaignTranslationPublishedStatus = (payload) => {
	return {
		type: UPDATE_CAMPAIGN_TRANSLATIONS_PUBLISHED_STATUS,
		payload,
	};
};
