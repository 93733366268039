import api from '../../util/api';
import { push } from "react-router-redux";
import { takeLatest, call, put, select } from "redux-saga/effects";
import { setUserProfile } from "../../actions/User";
import { GENERATE_TOKEN } from "../../constants/ActionTypes";
import { GET_SOCIAL_TOKEN } from "../../constants/Url";
import { addMessage } from "../../actions/Messages";
import { EMAIL_EXISTS_WARNING } from "../../constants/Label";
import { redirectRoute, fetchUserProfile } from "../../util/SagaUtils";
import { appendNotifications } from "../../actions/Notifications";
import {
    USER_LOGIN,
    SOCIAL_REGISTRATION,
    WARNING,
    ERROR,
    SUCCESS
} from "../../constants/Notifications";
import { SIGNUP } from "../../constants/RoutesURLs";

export function* generateTokenWatcher() {
    yield takeLatest(GENERATE_TOKEN, getTokenWorker);
};

function* getTokenWorker(action) {
    try {
        // email validation
        if (action.payload.error == "EMAIL_EXISTS_WARNING") {
            yield put(appendNotifications(SOCIAL_REGISTRATION(WARNING, EMAIL_EXISTS_WARNING)));
            yield put(push(SIGNUP));
        } else {
            // data is obtained after axios call is resolved
            let response = yield call(getJwtTokenAPI, action.payload);

            switch (true) {
                case response.status >= 200 && response.status < 300: {
					window.browserStorage.storeJWT(response.data.token);
					window.browserStorage.set('refresh_token', response.data.refresh_token);
					window.browserStorage.storePermission(response.data.data.role);
					yield put(setUserProfile(response.data.data));
					window.browserStorage.removecode();
					let user = yield select(fetchUserProfile);
					const route = redirectRoute(user);
					if (user.onboarded !== 0 && user.previous_tier.rank === user.current_tier.rank) {
						yield put(appendNotifications(USER_LOGIN(SUCCESS)));
					}
					yield put(push(route));
                    break;
                };
                case response.status >= 300 && response.status < 600: {
                    if (response?.data?.msg ) {
                        yield put(addMessage({
                            source: GENERATE_TOKEN,
                            message: response.data.msg,
                            isError: true,
                        }));
                    } else {
                        yield put(appendNotifications(USER_LOGIN(ERROR)));
                    }
                    break;
                };
                default: {
                    throw response;
                };
            }
        }
    } catch (e) {
        console.error(e);
    }
}

function getJwtTokenAPI(payload) {
    return (
        api
        .post(GET_SOCIAL_TOKEN, {
            token: payload.token,
            isSocial: payload.isSocial,
            code: payload.code
        }).then(response => {
            return response;
        }).catch(error => {
            return error.response;
        })
    );
};