import { setDiscordData } from "../../../actions/Channel";

import { takeLatest, call, put } from "redux-saga/effects";

import { FETCH_DISCORD_CHANNEL_DATA } from "../../../constants/ActionTypes";
import { FETCH_DISCORD_CHANNELS } from "../../../constants/Url";

import api from "../../../util/api";

function fetchDiscordDataApi() {
	return api.post(FETCH_DISCORD_CHANNELS);
}

export function* fetchDiscordDataWatcher() {
	yield takeLatest(FETCH_DISCORD_CHANNEL_DATA, fetchDiscordDataWorker);
}

function* fetchDiscordDataWorker() {
	try {
		// data is obtained after axios call is resolved
		let response = yield call(fetchDiscordDataApi);
		if (response.status >= 200 && response.status < 300) {
			yield put(setDiscordData(response.data.data));
		} else {
			throw response;
		}
	} catch (e) {
		console.log(e);
	}
}
