import { takeLatest, call, put, select } from "redux-saga/effects";

// Actions
import { fetchAchievementsDisplay } from "../../actions/UsersAchievements";
import { appendNotifications } from "../../actions/Notifications";

// Util
import api from "../../util/api";

// Constants
import { UPDATE_ACHIEVEMENTS_DISPLAY_WATCHER } from "../../constants/ActionTypes";
import { UPDATE_ACHIEVEMENTS_DISPLAY_URL } from "../../constants/Url";
import { ERROR, SUCCESS, USER_UPDATE } from "../../constants/Notifications";

export function* updateAchievementsDisplayWatcher() {
	yield takeLatest(UPDATE_ACHIEVEMENTS_DISPLAY_WATCHER, updateAchievementsDisplayWorker);
}

function* updateAchievementsDisplayWorker(action) {
	try {
		let options = action.options;
		const response = yield call(updateAchievementsDisplayApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;

		if (isSuccess) {
			const userId = yield select((state) => state.user.profile.id);
			yield put(fetchAchievementsDisplay(userId));
		}

		yield put(appendNotifications(USER_UPDATE(isSuccess ? SUCCESS : ERROR)));

		options = isSuccess ? action.options?.success : action.options?.failure;
		if (options?.callback) yield call(options.callback);
	} catch (e) {
		console.error(e);
	}
}

function updateAchievementsDisplayApi(payload) {
	return api
		.put(UPDATE_ACHIEVEMENTS_DISPLAY_URL, {
			...payload,
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
