import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { appendNotifications } from "../../actions/Notifications";

// // Util
import api from "../../util/api";

// Constants
import { ERROR, SUCCESS } from "../../constants/Notifications";
import { PATCH_CAMPAIGN_TRANSLATIONS_PUBLISHED_STATUS_WATCHER } from "../../constants/ActionTypes";
import { PATCH_CAMPAIGN_TRANSLATIONS_PUBLISHED_STATUS_URL } from "../../constants/Url";
import { updateCampaignTranslationPublishedStatus } from "../../actions/CampaignTranslations";

export function* patchCampaignTranslationsPublishedStatusWatcher() {
	yield takeLatest(
		PATCH_CAMPAIGN_TRANSLATIONS_PUBLISHED_STATUS_WATCHER,
		patchCampaignTranslationsPublishedStatusWorker
	);
}

function* patchCampaignTranslationsPublishedStatusWorker(action) {
	try {
		const response = yield call(patchCampaignTranslationsPublishedStatusApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		if (isSuccess) {
			yield put(updateCampaignTranslationPublishedStatus(action.payload));
			yield put(
				appendNotifications({
					type: SUCCESS,
					message: "Successfully updated",
				})
			);
		} else {
			throw response;
		}
	} catch (e) {
		const { error } = e.response.data;
		yield put(
			appendNotifications({
				type: ERROR,
				message: error,
			})
		);
	}
}

function patchCampaignTranslationsPublishedStatusApi({ campaignId, params }) {
	return api.patch(PATCH_CAMPAIGN_TRANSLATIONS_PUBLISHED_STATUS_URL(campaignId), {
		[params.languageCode]: params.publishedStatus,
	});
}
