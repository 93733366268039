import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { PLAYER_ENTITIE_TYPE } from "../../constants/EsportsEntities";
import { fetchPlayers } from "../../actions/Players";
import MultiSelectInput from "../MultiSelectInput";

class PlayerEntitesInput extends Component {
	componentDidMount() {
		this.props.fetchPlayers({
			is_active: 1
		})
	}

	mapPlayersToEsportEntities() {
		return this.props.players.map((player) => ({
			label: player.display_name,
			value: {
				name: player.name,
				type: PLAYER_ENTITIE_TYPE,
			},
		}));
	}

	filterPlayerEntities() {
		return this.props.entities.filter(
			(entity) => entity.type === PLAYER_ENTITIE_TYPE
		);
	}

	onChange = (event, value) => {
		this.props.onChange(value);
	};

	onRemove = (event, value) => {
		this.props.onRemove(value);
	};

	render() {
		const selected_player_entities = this.filterPlayerEntities();
		const players_entities = this.mapPlayersToEsportEntities();

		return (
			<MultiSelectInput
				label={this.props.label}
				intro={this.props.intro}
				selected_values={selected_player_entities}
				options={players_entities}
				onChange={this.onChange}
				onRemove={this.onRemove}
			/>
		);
	}
}

PlayerEntitesInput.propTypes = {
	players: PropTypes.array.isRequired,
	entities: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
	fetchPlayers: PropTypes.func.isRequired,
	label: PropTypes.string,
	intro: PropTypes.string,
};

const mapStateToProps = (state) => ({
	players: state.global.players,
});

const mapDispatchToProps = (dispatch) => {
	return {
		fetchPlayers: (params) => dispatch(fetchPlayers(params)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(PlayerEntitesInput);
