import api from '../../util/api.js'; 
import {
	takeLatest,
	call,
	put
} from "redux-saga/effects";

import {
    push,
} from "react-router-redux";

import {
	SUCCESS,
	ERROR,
} from "../../constants/Notifications.js";

import {
	appendNotifications
} from "../../actions/Notifications.js";

import {
    addMessage,
} from "../../actions/Messages.js";

import {
    setUserInteractions
} from "../../actions/UserAdminPanel.js";

import {
    FETCH_SELECTED_USER_INTERACTIONS_WATCHER
} from "../../constants/ActionTypes.js";

import {
    FETCH_USER_RECENT_INTERACTIONS_URL
} from "../../constants/Url.js";

export function* fetchUserInteractionsWatcher() {
    yield takeLatest(FETCH_SELECTED_USER_INTERACTIONS_WATCHER, fetchUserInteractionsWorker);
};

function* fetchUserInteractionsWorker(action) {
	try {
		const response = yield call(fetchUserInteractionsApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (options?.notification) {
			yield put(appendNotifications({
				type: isSuccess ? SUCCESS : ERROR,
				message: options.notification,
			}));
		};
		if (options?.message) {
			yield put(addMessage({
				source: FETCH_SELECTED_USER_INTERACTIONS_WATCHER,
				message: response?.data?.error,
				isError: isSuccess,
			}));
		};
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		};
		if (options?.redirect) {
			yield put(push(options.redirect));
        };
        if (isSuccess) {
            yield put(setUserInteractions(response.data));
        };
	} catch (e) {
		console.error(e);
	};
};

function fetchUserInteractionsApi(payload) {
    return (
        api.get(FETCH_USER_RECENT_INTERACTIONS_URL(payload.userId), {
			params:{
				page: payload.page,
				limit: payload.limit
			}
        })
    );
};