import React from "react";
import { connect } from "react-redux";
import moment from 'moment';
import Modal from '../../../../components/Modal';
import { FormFieldRadio } from '../../../../components/FormFields';
import { ACTIVE_STATUS } from "../../../../constants/Rewards";
import { updateRewards } from '../../../../actions/Rewards';
import "../styles.scss";

class StatusDialog extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.setState({
            is_active: this.props.is_active,
            expiration:this.props.expiration
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            is_active: nextProps.is_active,
            expiration: nextProps.expiration,
            active_period: nextProps.active_period
        })
    }

    handleSubmit = () => {
        this.props.updateRewards({
            id: this.props._id,
            params: {
                is_active: this.state.is_active,
                expiration: this.state.expiration
            }
        });
    };

    handleClose() {
        this.setState({
            is_active: this.props.is_active
        });
    }

    checkValidation() {
        return (
            this.state.is_active == this.props.is_active
        )
    }

    handleIsActiveChange(event) {
        let value = event.target.value;
        this.setState({ is_active: value === "true" ? true : false });

        if (value) {
            let date_now = new Date();
            let expiration_date = "";
            let active_period = this.props.active_period;

            if (active_period == '24H') {
                expiration_date = moment(date_now).add(1, 'days');
            } else if (active_period == '1WK') {
                expiration_date = moment(date_now).add(7, 'days');
            } else if (active_period == '1MO') {
                expiration_date = moment(date_now).add(30, 'days');
            }
            else if (active_period == '3MO') {
                expiration_date = moment(date_now).add(90, 'days');
            } else {
                expiration_date = null
            }

            this.setState({
                expiration: expiration_date
            });
        }
    }

    render() {
        return (
            <Modal
                className="admin"
                triggerBtnText="Change"
                triggerBtnClassName="button button--secondary button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Save"
                submitDisabled={null}
                onSubmit={() => this.handleSubmit()}
                onClose={() => this.handleClose()}
            >
                <FormFieldRadio
                    label={"Status"}
                    name={"expiration"}
                    value={this.state.is_active}
                    options={ACTIVE_STATUS}
                    onChange={(event) => this.handleIsActiveChange(event)}
                />
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateRewards: (payload) => dispatch(updateRewards(payload))
    }
}

const mapStateToProps = state => {
    return {

    }
}

StatusDialog = connect(mapStateToProps, mapDispatchToProps)(StatusDialog);

export default StatusDialog