import { setGamesPublicInfo } from "../../actions/Games";

import { takeLatest, call, put } from "redux-saga/effects";

import { FETCH_GAMES_PUBLIC_INFO_WATCHER } from "../../constants/ActionTypes";

import { FETCH_GAMES_PUBLIC_INFO_URL } from "../../constants/Url";

import api from "../../util/api";

export function* fetchGamesPublicInfoWatcher() {
	yield takeLatest(FETCH_GAMES_PUBLIC_INFO_WATCHER, fetchGamesPublicInfoWorker);
}

function* fetchGamesPublicInfoWorker() {
	try {
		let response = yield call(fetchGamespublicInfoApi);

		if (response.status >= 200 && response.status < 300) {
			yield put(setGamesPublicInfo(response.data.data));
		} else {
			throw response;
		}
	} catch (err) {
		console.error(err);
	}
}

function fetchGamespublicInfoApi() {
	return api
		.get(FETCH_GAMES_PUBLIC_INFO_URL)
		.then((response) => response)
		.catch((err) => err.response);
}
