import { all } from "redux-saga/effects";
import { fetchUserPreferredPlayersWatcher } from "./FetchUserPlayersSagas";
import { fetchPlayersWatcher } from "./FetchPlayersSaga";
import { addPlayerWatcher } from "./AddPlayerSaga";
import { archieveOrRestorePlayerWatcher } from "./ArchiveRestorePlayerSaga";
import { updatePlayerWatcher } from "./UpdatePlayerSaga";
import { deletePlayerWatcher } from "./DeletePlayerSaga";

export function* playersSagas() {
	yield all([
		fetchUserPreferredPlayersWatcher(),
		fetchPlayersWatcher(),
		addPlayerWatcher(),
		archieveOrRestorePlayerWatcher(),
		updatePlayerWatcher(),
		deletePlayerWatcher(),
	]);
}