import { FETCH_SEASONS, SET_SEASONS } from "../constants/ActionTypes";

export const fetchSeasons = (options) => {
	return {
		type: FETCH_SEASONS,
		options: options,
	};
};

export const setSeasons = (seasons) => {
	return {
		type: SET_SEASONS,
		payload: seasons,
	};
};
