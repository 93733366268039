import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { Modal as BootstrapModal } from "react-bootstrap";
import { isMobile, isMobileOnly } from "react-device-detect";
import classnames from "classnames";

/* Example Usage:

        <Modal
			wide 	// Widen to render wide formfields
            dark    // Enables dark theme (Public UI)
            show={this.state.show_username_modal}   //Manual control of modal display
            onTrigger={() => this.setState({ show_username_modal: true })}
            className="change-username-modal"
            triggerBtn={(props) => <button>text</button>} //Custom trigger button
            triggerBtnText="Change"
            triggerBtnClassName="button-secondary"
            triggerDisabled={false}
            cancelBtnText="Cancel"
            submitBtnText="Save"
			onOpen={() => this.setState(modalOpened: true)}
            onSubmit={() => this.updateUsername(this.state.username)}
            onClose={() => this.setState({ show_username_modal: false, error: "", username: this.props.user.userInfo.username })}
            submitDisabled={this.state.username === (this.props.user.userInfo ? this.props.user.userInfo.username || "" : "")}
        >
            {"Modal Contents Here"}
        </Modal>

*/

class Modal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			show: false,
		};
	}

	handleOpen = (e) => {
		e.preventDefault();
		const { onTrigger, onOpen } = this.props;
		this.disableBackgroundScrolling();

		if (onTrigger) onTrigger();
		else this.setState({ show: true });

		if (!onTrigger && onOpen) onOpen();
	};

	handleClose = () => {
		const { onClose } = this.props;

		this.enableBackgroundScrolling();
		this.setState({ show: false }, () => {
			if (onClose) {
				onClose();
			}
		});
	};

	handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const { onSubmit } = this.props;

		this.setState(
			{ show: false },
			() => {
				if (onSubmit) onSubmit(e);
			},
			this.enableBackgroundScrolling()
		);
	};

	handlePageScrollbarAdjustment() {
		let backgroundOverlay = document.getElementById("background-overlay");

		if (backgroundOverlay && window.innerWidth > document.body.clientWidth) {
			backgroundOverlay.className = "background-overlay background-overlay--with-scrollbar";
		}
	}

	disableBackgroundScrolling() {
		if (isMobile) {
			let background = document.getElementById("background-overlay");
			if (!background.style.top) {
				background.style.top = `-${window.scrollY}px`;
				background.style.position = "fixed";
			}
		}
	}

	enableBackgroundScrolling() {
		if (isMobile) {
			let background = document.getElementById("background-overlay");
			if (background.style.top) {
				const scrollY = background.style.top;
				background.style.position = "";
				window.scroll(0, parseInt(scrollY || "0") * -1);
				background.style.top = "";
			}
		}
	}

	renderTriggerButton() {
		const { triggerBtn, triggerBtnText, triggerBtnClassName, triggerDisabled } = this.props;

		if (triggerBtn) {
			const Button = triggerBtn;
			return <Button type="button" variant="primary" onClick={this.handleOpen} />;
		} else if (triggerBtnText) {
			return (
				<button
					className={triggerBtnClassName}
					type="button"
					variant="primary"
					onClick={this.handleOpen}
					disabled={triggerDisabled}
				>
					{triggerBtnText}
				</button>
			);
		}
	}

	renderCloseButton() {
		const { cancelBtnClassName, cancelBtnText } = this.props;
		return (
			<button
				className={`button ${cancelBtnClassName ? cancelBtnClassName : ""}`}
				type="button"
				onClick={this.handleClose}
			>
				{cancelBtnText}
			</button>
		);
	}

	renderSubmitButton() {
		const { form, dark, submitBtnClassName, submitDisabled, submitBtnText } = this.props;

		return (
			<button
				type="submit"
				form={form}
				className={
					"button " +
					(dark ? "button--colored " : "button--colored-admin ") +
					(submitBtnClassName ? submitBtnClassName : "")
				}
				onClick={form ? null : this.handleSubmit}
				disabled={submitDisabled}
			>
				{submitBtnText}
			</button>
		);
	}

	renderFooter() {
		const { customFooter, cancelBtnText, submitBtnText } = this.props;

		return (
			<BootstrapModal.Footer>
				{customFooter}
				{cancelBtnText && this.renderCloseButton()}
				{submitBtnText && this.renderSubmitButton()}
			</BootstrapModal.Footer>
		);
	}

	renderContent() {
		const { title, form, formRef, children } = this.props;

		return (
			<BootstrapModal.Body>
				{title && <div className="modal__title">{title}</div>}

				{form ? (
					<form id={form} ref={formRef} onSubmit={this.handleSubmit}>
						{children}
					</form>
				) : (
					<React.Fragment>{children}</React.Fragment>
				)}
			</BootstrapModal.Body>
		);
	}

	render() {
		const { componentClassName, id, show, backdrop, dark, backdropClassName, className, wide } =
			this.props;
		const { show: showState } = this.state;

		return (
			<span
				className={classnames("ModalDialog", {
					[componentClassName]: componentClassName,
				})}
			>
				{this.renderTriggerButton()}

				<BootstrapModal
					id={id}
					show={show ?? showState}
					onHide={this.handleClose}
					backdrop={backdrop === false ? false : true}
					dialogClassName={classnames({
						"modal-dialog--wide": wide,
						"modal-dialog--mobile": isMobileOnly,
						"modal-dialog--scrollbar-adjust":
							window.innerWidth > document.body.clientWidth,
						[dark ? "dark-theme --theme-dark" : "light-theme --theme-light"]: true,
						[className]: className,
					})}
					backdropClassName={backdropClassName}
					centered
					scrollable
				>
					{this.renderContent()}

					{this.renderFooter()}
				</BootstrapModal>
			</span>
		);
	}
}

Modal.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	componentClassName: PropTypes.string,
	show: PropTypes.bool,
	backdrop: PropTypes.bool,
	backdropClassName: PropTypes.string,
	wide: PropTypes.bool,
	dark: PropTypes.bool,
	triggerBtn: PropTypes.elementType,
	triggerBtnText: PropTypes.string,
	triggerBtnClassName: PropTypes.string,
	triggerDisabled: PropTypes.bool,
	onTrigger: PropTypes.func,
	onOpen: PropTypes.func,
	cancelBtnText: PropTypes.string,
	cancelBtnClassName: PropTypes.string,
	onClose: PropTypes.func,
	submitBtnText: PropTypes.string,
	submitBtnClassName: PropTypes.string,
	submitDisabled: PropTypes.bool,
	onSubmit: PropTypes.func,
	form: PropTypes.string,
	customFooter: PropTypes.node,
	title: PropTypes.string,
	formRef: PropTypes.node,
	children: PropTypes.node,
};

export default Modal;
