import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Constants
import { DEFAULT_GENDER } from "../../../../constants/Genders";

// Components
import Modal from "../../../../components/Modal";
import GenderSelect from "../../../../components/GenderSelect/GenderSelect";

// Actions
import { updateUserGender } from "../../../../actions/UserUpdate";

class GenderDialog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			gender: null,
		};
	}

	handleSubmit = () => {
		this.props.updateUserGender(
			{
				user: this.props.userId,
				gender: this.state.gender,
			},
			{
				success: {
					notification: "Successfully updated user gender.",
					fetchUserProfileForAdminUi: true,
				},
				failure: {
					notification: true,
				},
			}
		);
	};

	render() {
		const { user } = this.props;
		const { gender } = this.state;

		return (
			<Modal
				className="admin"
				triggerBtnText="Change"
				triggerBtnClassName="button"
				cancelBtnText="Cancel"
				submitBtnText="Save"
				submitDisabled={!gender}
				onSubmit={() => this.handleSubmit()}
			>
				<GenderSelect
					value={this.state.gender}
					onChange={(value) => this.setState({ gender: value })}
					clearable={!!this.state.gender}
					user={user}
					defaultGender={DEFAULT_GENDER}
				/>
			</Modal>
		);
	}
}

GenderDialog.propTypes = {
	user: PropTypes.shape({
		userInfo: PropTypes.shape({
			gender: PropTypes.string,
		}),
	}),
	userId: PropTypes.number,
};


const mapDispatchToProps = (dispatch) => {
	return {
		updateUserGender: (params, options) => dispatch(updateUserGender(params, options)),
	};
};

const mapStateToProps = (state) => {
	return {};
};

GenderDialog = connect(mapStateToProps, mapDispatchToProps)(GenderDialog);

export default GenderDialog;
