import { setTwitchChannelCount } from "../../../actions/Channel";

import { showLoader, hideLoader } from "../../../actions/GlobalAction";

import { takeLatest, call, put } from "redux-saga/effects";

import { FETCH_TWITCH_CHANNEL_COUNT } from "../../../constants/ActionTypes";
import { GET_TWITCH_CHANNEL_COUNT } from "../../../constants/Url";

import api from "../../../util/api";

export function* fetchTwitchChannelCountWatcher() {
	yield takeLatest(FETCH_TWITCH_CHANNEL_COUNT, fetchTwitchCountWorker);
}

function* fetchTwitchCountWorker() {
	try {
		yield put(showLoader());
		// data is obtained after axios call is resolved
		let response = yield call(fetchTwitchChannelCountApi);
		if (response.status >= 200 && response.status < 300) {
			const payload = {
				total: response.data.data,
			};
			yield put(setTwitchChannelCount(payload));
		} else {
			throw response;
		}
		yield put(hideLoader());
	} catch (e) {
		console.log(e.response);
		yield put(hideLoader());
	}
}

function fetchTwitchChannelCountApi() {
	return api.get(GET_TWITCH_CHANNEL_COUNT);
}
