import React from "react";
import PropTypes from "prop-types";

// Elements
import LinkElement from "../LinkElement";

// Renders the Slate nodes as HTML in the page
const EditorElement = (props) => {
	const { attributes, children, element } = props;

	switch (element.type) {
		default:
			return <p {...attributes}>{children}</p>;
		case "heading-one":
			return (
				<h1 className="lpheading lpheading--main-page" {...attributes}>
					{children}
				</h1>
			);
		case "heading-two":
			return (
				<h2 className="lpheading lpheading--section" {...attributes}>
					{children}
				</h2>
			);
		case "heading-three":
			return (
				<h3 className="lpheading lpheading--sub-section" {...attributes}>
					{children}
				</h3>
			);
		case "list-item":
			return <li {...attributes}>{children}</li>;
		case "ordered-list":
			return <ol {...attributes}>{children}</ol>;
		case "unordered-list":
			return <ul {...attributes}>{children}</ul>;
		case "link":
			return <LinkElement {...props} />;
	}
};

EditorElement.propTypes = {
	element: PropTypes.object,
	attributes: PropTypes.object,
	children: PropTypes.array,
};

export default EditorElement;
