import { FETCH_GENDERS_WATCHER, SET_GENDERS } from "../constants/ActionTypes";

export const fetchGenders = () => {
	return {
		type: FETCH_GENDERS_WATCHER,
	};
};

export const setGenders = (payload) => {
	return {
		type: SET_GENDERS,
		payload,
	};
};
