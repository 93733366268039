import React from "react";
import { PropTypes } from "prop-types";
import classnames from "classnames";
import "./styles.scss";

const TLParagraph = ({ children, size, className, responsive }) => {
	return (
		<p
			className={classnames(
				"tl_paragraph",
				`tl_paragraph--${size}`,
				{ "tl_paragraph--responsive": responsive },
				className
			)}
		>
			{children}
		</p>
	);
};

TLParagraph.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
		PropTypes.string,
	]).isRequired,
	size: PropTypes.oneOf(["small", "medium"]),
	className: PropTypes.string,
	responsive: PropTypes.bool,
};

TLParagraph.defaultProps = {
	size: "small",
};

export default TLParagraph;
