import {
	SET_ORDERS_BANK,
	APPEND_ORDERS_BANK,
	FETCH_ORDERS_WATCHER,
	MODIFY_ORDER_WATCHER,
	FETCH_USER_ORDERS_WATCHER,
	SET_USER_ORDERS_BANK,
} from "../constants/ActionTypes";

export const setOrdersBank = (orders) => {
	return {
		type: SET_ORDERS_BANK,
		payload: orders,
	};
};

export const setUserOrdersBank = (orders) => {
	return {
		type: SET_USER_ORDERS_BANK,
		payload: orders,
	};
};

export const appendOrdersBank = (orders) => {
	return {
		type: APPEND_ORDERS_BANK,
		payload: orders,
	};
};

export const fetchOrders = (orderState, page, limit, sortAttibute, sortDirection) => {
	return {
		type: FETCH_ORDERS_WATCHER,
		payload: { orderState, page, limit, sortAttibute, sortDirection },
	};
};

export const fetchUserOrders = (options) => {
	return {
		type: FETCH_USER_ORDERS_WATCHER,
		options,
	};
};

export const modifyOrder = (id, type, params, orderState) => {
	return {
		type: MODIFY_ORDER_WATCHER,
		payload: {
			id,
			type,
			params,
			orderState,
		},
	};
};
