import React, { Component } from "react";
import { connect } from "react-redux";
// Utils
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { COLLECTIONS } from "../../../constants/PaginationData";
import { fetchAdminUserOrders } from "../../../actions/UserAdminPanel";

class UserOrders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowsPerPageOptions: COLLECTIONS.ROW_PER_PAGE_OPTIONS,
      page: COLLECTIONS.page,
      limit: COLLECTIONS.defaultRowPerPage
    };
  }

  componentDidMount() {
    if (this.props.userId) {
      this.props.fetchAdminUserOrders(this.props.userId, this.state.page + 1, this.state.limit);
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    },
      () => {
        this.props.fetchAdminUserOrders(this.props.userId, this.state.page + 1, this.state.limit);
      });
  };

  handleChangeRowsPerPage = event => {
    if (event.target.value) {
      this.setState({
        page: 0,
        limit: event.target.value
      },
        () => {
          this.props.fetchAdminUserOrders(this.props.userId, this.state.page + 1, this.state.limit);
        });
    }
  };

  tablePagination() {
    if (this.props.count > this.state.limit) {
      return (
        <TablePagination
          className={`float-right`}
          component="div"
          count={this.props.count}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={this.state.rowsPerPageOptions}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          classes={{
            select: 'pagination-select'
          }}
        />
      )
    }
  }

  loadTableBody() {
    if (this.props.userOrders && this.props.userOrders.length) {
      return this.props.userOrders.map(data => {
        return (
          <TableRow key={data._id}>
            <TableCell width="20%" className="pt-2 pb-2">
              <span>
                <span className="mb-2">{data._id} </span>
                <span>{moment(data.createDate).format("YYYY.MM.DD")}</span>
              </span>
            </TableCell>

            <TableCell width="5%" className="pt-2 pb-2">
              {data.has_been_fulfilled &&
                <span id="verified" />
              }
            </TableCell>

            <TableCell width="50%" className="pt-2 pb-2">{data.items.length ? data.items[0].name : ""}</TableCell>
            <TableCell width="15%" padding="none" className={`className="pt-2 pb-2 align-right`}>{data.points_paid}</TableCell>
            <TableCell width="10%" padding="none" className={`className="pt-2 pb-2 align-right`}>
              <Link to={`/app/fulfillment/orders/${data._id}/${data.type}`}>
                <Button size="small" variant="text" mini={true}>
                  <span>
                    <i className="zmdi zmdi-chevron-right zmdi-hc-lg" />
                  </span>
                </Button>
              </Link>
            </TableCell>
          </TableRow>
        );
      })
    }
  }

  render() {
    return (
      <React.Fragment>

        <div className="mt-2 mb-4 pb-4">
          {this.tablePagination()}
        </div>

        <div className="table-responsive-material shadow border-0 card table-width table-radious">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="25%">Order/Date</TableCell>
                <TableCell width="5%"></TableCell>
                <TableCell width="45%">Reward</TableCell>
                <TableCell width="25%" padding="none" className={`align-right`}>Total Points</TableCell>
                <TableCell width="10%" padding="none" ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.loadTableBody()}
            </TableBody>
          </Table>
        </div>

        <div>
          {this.tablePagination()}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAdminUserOrders: (userId, page, limit) => dispatch(fetchAdminUserOrders(userId, page, limit)),
  }
}

const mapStateToProps = (state) => {
  return {
    userOrders: state.user_admin.userOrders,
    count: state.user_admin.userOrdersCount,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserOrders)