import {
    fetchRedditData
} from '../../../actions/Channel';

import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';

import {
    DELETE_REDDIT_SERVER
} from '../../../constants/ActionTypes'
import {
    REDDIT_POSTS_URL,
    REDDIT_SUBREDDITS_URL,
} from '../../../constants/Url'

import api from '../../../util/api';

export function* removeRedditDataWatcher() {
    yield takeLatest(DELETE_REDDIT_SERVER, removeRedditDataWorker);
}

function* removeRedditDataWorker(action) {
    try {
        // data is obtained after axios call is resolved
        let response = yield call(removeRedditDataApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(fetchRedditData())
        } else {
            throw (response)
        }
    } catch (e) {
        console.log(e)
    }
}

function removeRedditDataApi(payload) {
    switch (payload.type) {
        case "submission": {
            return (
                api.delete(`${REDDIT_POSTS_URL}/${payload.identifier}`)
            );
        };
        case "subreddit": {
            return (
                api.delete(`${REDDIT_SUBREDDITS_URL}/${payload.identifier}`)
            );
        };
        default: {
            return null;
        };
    };
};