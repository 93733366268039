import { setUsersSeasonReview } from "../../actions/UsersSeasonReview";
import { takeLatest, call, put } from "redux-saga/effects";
import { FETCH_USERS_SEASON_REVIEW_WATCHER } from "../../constants/ActionTypes";
import { FETCH_USERS_SEASON_REVIEW_URL } from "../../constants/Url";
import api from "../../util/api";

function fetchUsersSeasonReviewApi() {
	return api.post(FETCH_USERS_SEASON_REVIEW_URL);
}

export function* fetchUsersSeasonReviewWatcher() {
	yield takeLatest(FETCH_USERS_SEASON_REVIEW_WATCHER, fetchUsersSeasonReviewWorker);
}

function* fetchUsersSeasonReviewWorker() {
	try {
		let response = yield call(fetchUsersSeasonReviewApi);
		const isSuccess = response.status >= 200 && response.status < 300;

		if (isSuccess) {
			yield put(setUsersSeasonReview(response.data.data));
		}
	} catch (e) {
		if (e.response.status === 404) {
			yield put(setUsersSeasonReview([]));
		} else {
			console.error(e);
		}
	}
}
