import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./styles.scss";

function LpList({ type, variant, children, className }) {
	const List = type;
	return (
		<List className={classnames("lp_list", `lp_list--${variant}`, className)}>{children}</List>
	);
}

LpList.defaultProps = {
	type: "div",
	variant: "block",
};

LpList.propTypes = {
	type: PropTypes.string,
	variant: PropTypes.oneOf(["block"]),
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	className: PropTypes.string,
};

export default LpList;
