import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "../styles.scss";

// Constants
import { EventType } from "../MockData";

// Components
import Modal from "../../../../components/Modal";
import { FormFieldInput, FormFieldSelect } from "../../../../components/FormFields";

// Actions
import { updateEvent } from "../../../../actions/Events";

const ChangeEventModal = ({ icon_url, name: props_name, event_type, updateEvent, eventId }) => {
	const [iconUrl, setIconUrl] = useState(icon_url);
	const [name, setName] = useState(props_name);
	const [eventType, setEventType] = useState(event_type);
	const [previewImage, setPreviewImage] = useState(null);

	useEffect(() => {
		setIconUrl(icon_url);
		setName(props_name);
		setEventType(event_type);
	}, [event_type, icon_url, props_name]);

	const handleSubmit = () => {
		updateEvent(
			{
				name: name,
				event_type: eventType,
				icon_url: iconUrl,
			},
			eventId
		);
	};

	const handleClose = () => {
		setIconUrl(icon_url);
		setName(props_name);
		setEventType(event_type);
		setPreviewImage(null);
	};

	return (
		<Modal
			className="admin"
			triggerBtnText="Change"
			triggerBtnClassName="button button--borderless"
			cancelBtnText="Cancel"
			submitBtnText="Save"
			onSubmit={handleSubmit}
			onClose={handleClose}
		>
			<div className="form-field form-field--input-image">
				<figure className="icon-placeholder">
					{!iconUrl && !previewImage ? (
						<img
							className="data-table__inline-image"
							src={require("../../../../svg/icon-placeholder.svg")}
							alt="Event Icon default"
						/>
					) : previewImage ? (
						<img
							className="data-table__inline-image"
							src={previewImage}
							alt="event icon"
						/>
					) : (
						<img className="data-table__inline-image" src={iconUrl} alt="event icon" />
					)}
					<figcaption className="icon-placeholder__caption">Event Icon</figcaption>

					<Modal
						className="admin"
						triggerBtnText="Change"
						triggerBtnClassName="button button--borderless"
						cancelBtnText="Cancel"
						submitBtnText="Save"
						onClose={() => setPreviewImage(null)}
						onSubmit={() => {
							setIconUrl(previewImage);
							setPreviewImage(null);
						}}
					>
						<FormFieldInput
							intro="Paste the url for the image that exists in the CDN."
							label="URL"
							type="text"
							name="previewImage"
							value={previewImage}
							placeholder="cdn.teamliquid.com/uri-that-matches"
							onChange={(event) => setPreviewImage(event.target.value)}
						/>
					</Modal>
				</figure>
			</div>
			<FormFieldInput
				label="Event Name"
				type="text"
				name="name"
				value={name}
				placeholder="its name"
				onChange={(event) => setName(event.target.value)}
			/>
			<FormFieldSelect
				label="Event Type"
				name="eventType"
				value={eventType}
				options={EventType}
				onChange={(event) => setEventType(event.target.value)}
			/>
		</Modal>
	);
};

ChangeEventModal.propTypes = {
	icon_url: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	event_type: PropTypes.string.isRequired,
	updateEvent: PropTypes.func.isRequired,
	eventId: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
	updateEvent: (params, id) => dispatch(updateEvent(params, id)),
});

export default connect(null, mapDispatchToProps)(ChangeEventModal);
