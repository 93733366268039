import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';
import {
    push
} from 'react-router-redux';

import {
	appendNotifications,
} from "../../actions/Notifications";
import {
	addMessage,
} from "../../actions/Messages";
import {
	SUCCESS,
	ERROR,
} from "../../constants/Notifications";

import {
    ADD_GAME_WATCHER
} from '../../constants/ActionTypes'
import {
    ADD_GAME_URL,
} from '../../constants/Url'
import { setGames } from "../../actions/Games";

import api from '../../util/api';


export function* addGameWatcher() {
    yield takeLatest(ADD_GAME_WATCHER, addGameWorker);
}

function* addGameWorker(action) {
	try {
		let response = yield call(addGameApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
        const options = isSuccess ? action?.options?.success : action?.options?.failure;

        if (isSuccess) {
			yield put(setGames(response.data.data));
		};
		if (options?.notification) {
			yield put(appendNotifications({
				type: isSuccess ? SUCCESS : ERROR,
				message: options.notification,
			}));
		};
		if (options?.message) {
			yield put(addMessage({
				source: ADD_GAME_WATCHER,
				message: response?.data?.error,
				isError: true,
			}));
		};
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
        };
        if (options?.redirect) {
			yield put(push(options.redirect));
		};
	} catch (e) {
		console.error(e);
	};
};

function addGameApi(payload) {
	return api
		.post(ADD_GAME_URL, {
            ...payload
		})
		.then(response => {return response})
		.catch(error => {return error.response});
};

