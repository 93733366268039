import {
    FETCH_PERKS_WATCHER,
    FETCH_USER_PERKS_WATCHER,
    SET_ALL_PERKS,
    SET_USER_PERKS,
    ADD_TO_CLAIMED_PERKS,
} from "../constants/ActionTypes";

export const fetchPerks = () => {
    return {
        type: FETCH_PERKS_WATCHER,
    };
};

export const setPerks = (data) => {
    return {
        type: SET_ALL_PERKS,
        payload: data
    };
}

export const fetchUserPerks = () => {
    return {
        type: FETCH_USER_PERKS_WATCHER,
    };
};

export const setUserPerks = (data) => {
    return {
        type: SET_USER_PERKS,
        payload: data
    };
}

export const addToClaimedPerks = (params, options) => {
    return {
        type: ADD_TO_CLAIMED_PERKS,
        params: params,
        options: options,
    };
};