import {
    setRedditChannelCount,
} from '../../../actions/Channel';

import {
    showLoader,
    hideLoader
} from '../../../actions/GlobalAction'

import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';

import {
    FETCH_REDDIT_CHANNEL_COUNT,
} from '../../../constants/ActionTypes'
import {
    GET_REDDIT_CHANNEL_COUNT,
} from '../../../constants/Url'

import api from '../../../util/api';

export function* fetchRedditChannelCountWatcher() {
    yield takeLatest(FETCH_REDDIT_CHANNEL_COUNT, fetchRedditCountWorker);
}

function* fetchRedditCountWorker() {
    try {
        yield put(showLoader());
        // data is obtained after axios call is resolved
        let response = yield call(fetchRedditChannelCountApi);
        if (response.status >= 200 && response.status < 300) {
            const {total, subRedditCount, postCount} = response.data.data;
            const payload = {
                total: total,
                subRedditCount: subRedditCount,
                postCount: postCount
            };
            yield put(setRedditChannelCount(payload))
        } else {
            throw (response)
        }
        yield put(hideLoader());
    } catch (e) {
        console.log(e)
        yield put(hideLoader());
    }
}

function fetchRedditChannelCountApi() {
    return (
        api.get(GET_REDDIT_CHANNEL_COUNT)
    )
}