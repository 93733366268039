import { SET_ENDPOINT_CACHE, CLEAR_ENDPOINT_CACHE } from "../constants/ActionTypes";

const endpointCacheInitialState = {
	bank: [],
};

export default function EndpointCacheReducer(
	state = endpointCacheInitialState,
	action
) {
	switch (action.type) {
		case SET_ENDPOINT_CACHE: {
			return {
				...state,
				bank: action.payload,
			};
		}
		case CLEAR_ENDPOINT_CACHE: {
			return {
				...state,
				bank: [],
			};
		}
		default:
			return state;
	}
}
