import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";

// Actions
import { setUserProfile } from "../actions/User";
import { appendNotifications } from "../actions/Notifications";

// Sagas
import { onUserLanguageChangedWorker } from "./UserSagas/ChangeLanguageSaga";

// Constants
import { FETCH_USER_WATCHER } from "../constants/ActionTypes";
import { USER_SERVICE_LOGIN } from "../constants/Url";
import { USER_LOGIN, ERROR, SUCCESS } from "../constants/Notifications";

// Utils
import api from "../util/api";
import { redirectRoute } from "../util/SagaUtils";
import { getActiveLanguage } from "../util/Language";

function fetchUserApi(params) {
	return api
		.post(USER_SERVICE_LOGIN, {
			email: params.email,
			password: params.password,
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}

export function* fetchUserWatcher() {
	yield takeLatest(FETCH_USER_WATCHER, fetchUserWorker);
}

// eslint-disable-next-line complexity
function* fetchUserWorker(action) {
	try {
		// data is obtained after axios call is resolved
		let response = yield call(fetchUserApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (isSuccess) {
			const userProfile = response.data.data;

			window.browserStorage.storeJWT(response.data.token);
			window.browserStorage.set("refresh_token", response.data.refresh_token);
			window.browserStorage.storePermission(userProfile.role);

			if (userProfile?.lang !== getActiveLanguage()) {
				yield call(onUserLanguageChangedWorker, {
					payload: { lang: userProfile.lang },
				});
			}
			yield put(setUserProfile(userProfile));

			if (
				userProfile.onboarded !== 0 &&
				userProfile.previous_tier.rank === userProfile.current_tier.rank
			) {
				yield put(appendNotifications(USER_LOGIN(SUCCESS)));
			}

			const route = redirectRoute(userProfile);
			yield put(push(route));
		} else {
			if (response?.data?.msg) {
				yield put(appendNotifications(USER_LOGIN(ERROR, response?.data?.msg)));
			} else {
				throw response;
			}
		}

		if (options?.callback) {
			yield call(options.callback, response?.data);
		}
	} catch (e) {
		yield put(appendNotifications(USER_LOGIN(ERROR)));
		console.error(e);
	}
}
