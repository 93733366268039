import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./styles.scss";

const TLHeading = ({ className, type, variant, size, children }) => {
	const Heading = type;

	return (
		<Heading
			className={classnames(
				`tl_heading`,
				`tl_heading--${variant}`,
				`tl_heading--${size}`,
				className
			)}
		>
			{children}
		</Heading>
	);
};

TLHeading.defaultProps = {
	type: "h1",
	variant: "display",
	size: "xlarge",
};

TLHeading.propTypes = {
	className: PropTypes.string,
	type: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
	variant: PropTypes.oneOf(["display", "title"]),
	size: function (props, propName, componentName) {
		if (props[propName] !== undefined) {
			let sizes = ["small", "medium", "large", "xlarge"];

			if (
				props["variant"] === "display" &&
				(typeof props[propName] !== "string" || !sizes.includes(props[propName]))
			) {
				return new Error(
					"Invalid prop `size` of value `" +
						props[propName] +
						"` supplied to `" +
						componentName +
						'`, expected one of ["small", "medium", "large", "xlarge"].'
				);
			}

			sizes.push("xsmall");

			if (typeof props[propName] !== "string" || !sizes.includes(props[propName])) {
				return new Error(
					"Invalid prop `size` of value `" +
						props[propName] +
						"` supplied to `" +
						componentName +
						'`, expected one of ["xsmall", "small", "medium", "large", "xlarge"].'
				);
			}
		}
	},
	children: PropTypes.node,
};

export default TLHeading;
