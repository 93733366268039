import {
    setPointsHistory
} from '../../actions/UserAdminPanel'

import{
    showLoader,
    hideLoader
  } from '../../actions/GlobalAction'

import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';
import {
    FETCH_POINTS_HISTORY_WATCHER
} from '../../constants/ActionTypes'
import {
    FETCH_POINTS_HISTORY_URL
} from '../../constants/Url'

import api from '../../util/api'; 

function fetchPointsHistoryAPI(params) {
    return (
        api
        .post(FETCH_POINTS_HISTORY_URL, {
            page: params.page,
            limit: params.limit,
            search: params.search,
            dateRange: params.dateRange
        })
    )
}

export function* fetchPointsHistoryWatcher() {
    yield takeLatest(FETCH_POINTS_HISTORY_WATCHER, fetchPointsHistoryWorker);
}

function* fetchPointsHistoryWorker(action) {
    try {
        yield put(showLoader());
        // data is obtained after axios call is resolved
        let response = yield call(fetchPointsHistoryAPI, action.payload);
        if (response.status >= 200 && response.status < 300){
            yield put(setPointsHistory(response.data))
        } else {
            throw(response)
        }
        
        yield put(hideLoader());
    } catch (e) {
        console.log(e)
        yield put(hideLoader());
    }
}