import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

import { ErrorNotification } from "../../../../../containers/NotificationWrapper";

import { FormFieldInput } from "../../../../../components/FormFields/FormFields";
import { SingleDateTimePicker } from "../../../../../components/DatePickers/DatePickers";
import { localeToUTC } from "../../../../../util/DateUtil";

import Modal from "../../../../../components/Modal/Modal";

class EditBroadcast extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			event_name: props.event_name,
			start_date: props.start_date,
			end_date: props.end_date,
			channel_name: props.channel_name,
		};
	}

	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	handleBroadcastSubmit = () => {
		try {
			let errors = [];

			this.assertCondition(
				Date.parse(this.state.start_date),
				"Missing start date.",
				errors
			);
			this.assertCondition(
				Date.parse(this.state.end_date),
				"Missing end date.",
				errors
			);
			this.assertCondition(
				new Date(this.state.start_date).getTime() <=
					new Date(this.state.end_date).getTime(),
				"Start date cannot be after end date.",
				errors
			);

			if (errors.length) {
				throw errors;
			}

			this.props.handleSubmit({
				id: this.props.id,
				event_name: this.state.event_name,
				start_date: localeToUTC(this.state.start_date),
				end_date: localeToUTC(this.state.end_date),
				channel_name: this.state.channel_name,
			});
		} catch (e) {
			if (Array.isArray(e)) {
				for (let errorMessage of e) {
					ErrorNotification(errorMessage);
				}
			} else {
				ErrorNotification("Unknown error.");
			}
		}
	};

	assertCondition(condition, errorMessage, errors) {
		if (!condition) {
			errors.push(errorMessage);
		}
	}

	handleDateStartChange = (event) => {
		this.setState({
			start_date: event.format(),
		});
	};

	handleDateEndChange = (event) => {
		this.setState({
			end_date: event.format(),
		});
	};

	render() {
		return (
			<Modal
				componentClassName="TwitchDialog"
				className="admin"
				triggerBtnText="Edit"
				triggerBtnClassName="button"
				cancelBtnText="Cancel"
				submitBtnText="Save"
				onSubmit={this.handleBroadcastSubmit}
			>
				<FormFieldInput
					label={"Event Name"}
					type={"text"}
					name={"event_name"}
					value={this.state.event_name}
					onChange={this.handleChange}
				/>

				<div className="form-field__intro">
					Enter the dates when this event will run for.
				</div>

				<div className="form-field">
					<label
						className="form-field__label"
						htmlFor="broadcast-date-start"
					>
						Start Date
					</label>
					<SingleDateTimePicker
						name="start_date"
						datePicked={this.state.start_date}
						maxDate={this.state.end_date}
						id="broadcast-date-start"
						onChange={this.handleDateStartChange}
					/>
				</div>

				<div className="form-field">
					<label
						className="form-field__label"
						htmlFor="broadcast-date-end"
					>
						End Date (final day)
					</label>
					<SingleDateTimePicker
						name="end_date"
						datePicked={this.state.end_date}
						minDate={this.state.start_date}
						id="broadcast-date-end"
						onChange={this.handleDateEndChange}
					/>
				</div>

				<FormFieldInput
					intro="Enter the channel name for this event."
					label="Channel Name"
					type="text"
					name="channel_name"
					value={this.state.channel_name}
					onChange={this.handleChange}
				/>
			</Modal>
		);
	}
}

EditBroadcast.propTypes = {
	id: PropTypes.string.isRequired,
	event_name: PropTypes.string.isRequired,
	start_date: PropTypes.instanceOf(Date).isRequired,
	end_date: PropTypes.instanceOf(Date).isRequired,
	channel_name: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
};

export default EditBroadcast;
