import React, { Component } from "react";
import propTypes from "prop-types";
import "./styles.scss";

import Modal from "../../../../../components/Modal/Modal";

import { TWITTER_DELETE_DIALOG_TEXT } from "../../../../../constants/Label";

class TwitterDeleteDialog extends Component {
	state = {
		dialog_open: false,
	};

	handleOpen = () => {
		this.setState({ dialog_open: true });
	};

	handleClose = () => {
		this.setState({ dialog_open: false });
	};

	handleRemove = () => {
		const { deleteChannel, identifier, type } = this.props;
		deleteChannel({
			identifier: identifier,
			type: type,
		});
		this.handleClose();
	};

	render() {
		return (
			<Modal
				show={this.state.dialog_open}
				onClose={this.handleClose}
				onSubmit={this.handleRemove}
				onTrigger={this.handleOpen}
				cancelBtnText="Cancel"
				submitBtnText="Delete"
				triggerBtnText="Delete"
				triggerBtnClassName="button delete-button"
			>
				<p>{TWITTER_DELETE_DIALOG_TEXT}</p>
			</Modal>
		);
	}
}

TwitterDeleteDialog.propTypes = {
	deleteChannel: propTypes.func.isRequired,
	identifier: propTypes.string.isRequired,
	type: propTypes.string.isRequired,
};

export default TwitterDeleteDialog;
