import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from '../../../../components/Modal';
import "./styles.scss";
import { FormFieldTextarea } from '../../../../components/FormFields';
import { banUser, unBanUser } from "../../../../actions/UserAdminPanel";

class BanDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: "",
    };
  }

  handleSubmit = () => {

    if (this.state.comments) {
      if (this.props.isBanned) {
        this.props.unBanUser({
          unban_reason: this.state.comments,
        }, this.props.userId);
      } else {
        this.props.banUser({
          ban_reason: this.state.comments,
        }, this.props.userId);
      }
      this.handleClose();
    }
  };

  handleClose = () => {
    this.setState({
      comments: ""
    })
  }

  checkStatus = () => {
    return this.props.isBanned ? "Unban User" : "Ban User"
  };

  getDialogTitle = () => {
    if (this.props.isBanned) {
      return `Please enter a reason for unbanning ${this.props.userName}`
    } else {
      return `Please enter a reason for banning ${this.props.userName}`
    }
  };

  getLabelText = () => {
    return this.props.isBanned ? "Reason for Unban (required)" : "Reason for Ban (required)"
  };

  checkValidation = () => {
    return this.state.comments ? false : true;
  };

  render() {
    return (
      <Modal 
        className="admin ban-dialog"
        triggerBtnText={this.checkStatus()}
        triggerBtnClassName="button button--borderless"
        cancelBtnText="Cancel"
        submitBtnText="Save"
        submitDisabled={this.checkValidation()}
        onSubmit={this.handleSubmit}
        onClose={this.handleClose}
      >
        <FormFieldTextarea
          intro={this.getDialogTitle()}
          label={this.getLabelText()}
          allow_formatting={false}
          name={"comments"}
          value={this.state.comments}
          wide={false}
          onChange={(event) => this.setState({ comments: event.target.value })}
        />
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    banUser: (params, id) => dispatch(banUser(params, id)),
    unBanUser: (params, id) => dispatch(unBanUser(params, id)),
  };
};

const mapStateToProps = state => {
  return {
    loggedInUser: state.user.profile,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BanDialog)
);
