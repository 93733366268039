import { fetchDiscordData } from "../../../actions/Channel";

import { showLoader, hideLoader } from "../../../actions/GlobalAction";

import { takeLatest, call, put } from "redux-saga/effects";

import { DELETE_DISCORD_SERVER } from "../../../constants/ActionTypes";
import { DELETE_DISCORD_CHANNEL } from "../../../constants/Url";

import api from "../../../util/api";

export function* removeDiscordDataWatcher() {
	yield takeLatest(DELETE_DISCORD_SERVER, removeDiscordDataWorker);
}

function* removeDiscordDataWorker(action) {
	try {
		yield put(showLoader());
		// data is obtained after axios call is resolved
		let response = yield call(removeDiscordDataApi, action.payload);
		if (response.status >= 200 && response.status < 300) {
			yield put(fetchDiscordData());
		} else {
			throw response;
		}
		yield put(hideLoader());
	} catch (e) {
		console.log(e);
		yield put(hideLoader());
	}
}

function removeDiscordDataApi(payload) {
	return api.post(DELETE_DISCORD_CHANNEL, {
		serverName: payload.serverName,
	});
}
