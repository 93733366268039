import {
    setReferralLink,
} from '../../actions/User'
import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';
import {
    GET_REFERRAL_LINK
} from '../../constants/ActionTypes'
import {
    CREATE_REFERRAL_LINK_URL,
    FRIEND_REFERRAL_URL
} from '../../constants/Url'
import {
    FAILED_GENERATING_REFERRAL_LINK
} from '../../constants/Label'

import api from '../../util/api';

export function* fetchReferralLinkWatcher() {
    yield takeLatest(GET_REFERRAL_LINK, fetchReferralLinkWorker);
}

function* fetchReferralLinkWorker(action) {
    try {
        let response = yield call(fetchReferralLinkApi);
        if (response.status >= 200 && response.status < 300) {
            const referralObj = response.data;
            const code = referralObj.data.code;
            yield put(setReferralLink(FRIEND_REFERRAL_URL + '/' + code))
        } else {
            yield put(setReferralLink(FAILED_GENERATING_REFERRAL_LINK))
            throw (response)
        }
    } catch (e) {        
        yield put(setReferralLink(FAILED_GENERATING_REFERRAL_LINK))
    }
}

function fetchReferralLinkApi() {      
    return (
        api.post(CREATE_REFERRAL_LINK_URL)
    )
}