import React, { Component } from "react";
import PropTypes from "prop-types";

//Components
import Modal from "../../../../../components/Modal/Modal";
import LpHeading from "../../../../../components/LpHeading";
import { FormFieldInput } from "../../../../../components/FormFields/FormFields";
import GameEntitiesInput from "../../../../../components/EsportsEntitiesInputs/GameEntitiesInput";
import PlayerEntitiesInput from "../../../../../components/EsportsEntitiesInputs/PlayerEntitiesInput";

// Constants
import { TWITCH_CREATE_DIALOG_TEXT } from "../../../../../constants/Label";

class CreateStaticChannel extends Component {
	state = {
		channelName: "",
		streamlabsToken: "",
		esportEntities: [],
	};

	handleChange = (event) => {
		this.setState({
			[event?.target?.name]: event?.target?.value,
		});
	};

	addEntity = (entity) => {
		this.setState((state) => ({
			esportEntities: [...state.esportEntities, entity],
		}));
	};

	removeEntity = (entity) => {
		this.setState((state) => ({
			esportEntities: state.esportEntities.filter((e) => e.name !== entity.name),
		}));
	};

	createChannel = () => {
		const { handleCreate } = this.props;
		const { streamlabsToken, esportEntities, channelName } = this.state;

		handleCreate({
			serverName: channelName,
			streamlabsToken,
			esportEntities,
		});

		this.resetState();
	};

	resetState = () => {
		this.setState({
			channelName: "",
			streamlabsToken: "",
			esportEntities: [],
		});
	};

	render() {
		const { esportEntities, channelName, streamlabsToken } = this.state;

		return (
			<Modal
				className="admin channel-modal"
				triggerBtnText="Static Channel"
				triggerBtnClassName="button button--borderless"
				cancelBtnText="Cancel"
				onClose={this.resetState}
				submitBtnText="Add"
				onSubmit={this.createChannel}
			>
				<LpHeading>New Static Channel</LpHeading>
				<FormFieldInput
					intro={TWITCH_CREATE_DIALOG_TEXT}
					label="Channel Name"
					type="text"
					name="channelName"
					value={channelName}
					onChange={this.handleChange}
				/>
				<FormFieldInput
					intro="Please enter the stream token if you want to track donations."
					label="Stream Labs Token"
					type="text"
					name="streamlabsToken"
					value={streamlabsToken}
					onChange={this.handleChange}
				/>

				<PlayerEntitiesInput
					label="Player(s)"
					intro="Enter the players or games that relate to this channel."
					entities={esportEntities}
					onChange={this.addEntity}
					onRemove={this.removeEntity}
				/>

				<GameEntitiesInput label="Game(s)" entities={esportEntities} onChange={this.addEntity} />
			</Modal>
		);
	}
}

CreateStaticChannel.propTypes = {
	handleCreate: PropTypes.func.isRequired,
};

export default CreateStaticChannel;
