export const PLATFORMS = {
	TWITCH: "twitch",
	DISCORD: "discord",
	REDDIT: "reddit",
	ALIENWARE: "alienware",
	SHOPIFY: "shopify",
};

export const TIER_THRESHHOLD_TYPE = {
	POINT_VALUE: "POINT_VALUE",
	CUSTOM: "CUSTOM",
};
