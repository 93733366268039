import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { FormFieldSelect } from '../../../../components/FormFields';
import { updateEvent } from "../../../../actions/Events";
import { EventSubType } from "../MockData";
import "../styles.scss";

class ChangeSubTypeModal extends React.Component {
    constructor(props) {
        super(props);
        this.EventSubType = EventSubType;

        this.state = {
            sub_type: "",
        }
    }

    
    componentWillMount() {
        this.setState({
            sub_type: this.props.sub_type
        })
    }

    componentWillReceiveProps(nextProps) {
            this.setState({
                sub_type: nextProps.sub_type
            })
    }
    
    componentDidMount() {
        this.setState({
            sub_type: this.props.sub_type
        })
    }


    handleSubmit = () => {
        if (this.state.sub_type) {
            this.props.updateEvent({
                sub_type: this.state.sub_type
            }, this.props.eventId);
        }
    };
    
    render() {
        return (
            <Modal className="admin"
                triggerBtnText="Change"
                triggerBtnClassName="button button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Save"
                submitDisabled={this.state.sub_type === this.props.sub_type}
                onSubmit={() => this.handleSubmit()}
                onClose={(event) => this.setState({ sub_type: this.props.sub_type })}
            >
                <FormFieldSelect
                    label={"Event SubType"}
                    name={"subtype"}
                    value={this.state.sub_type}
                    options={this.EventSubType}
                    onChange={(event) => this.setState({ sub_type: event.target.value })}
                />
            </Modal>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateEvent: (params, id) => dispatch(updateEvent(params, id)),
    }
}

const mapStateToProps = state => {
    return {

    }
}

ChangeSubTypeModal = connect(mapStateToProps, mapDispatchToProps)(ChangeSubTypeModal);

export { ChangeSubTypeModal }
