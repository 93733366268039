import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from "../../../../components/Modal";
import LpHeading from "../../../../components/LpHeading";
import "./styles.scss";
import {
	FormFieldInput,
	FormFieldTextarea,
} from "../../../../components/FormFields";
import { changeUserAdminPoints } from "../../../../actions/UserAdminPanel";
import { truncateNumber } from "../../../../util/stringUtils";

class PointsChangeDialog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			reason: "",
			spending_point_change: "",
			membership_point_change: "",
			spending_point: 0,
			membership_point: 0,
			is_spending_added: false,
			is_spending_removed: false,
			is_membership_added: false,
			is_membership_removed: false,
		};
	}

	handleSubmit = () => {
		let points_history = {
			reason: this.state.reason,
			spending_point: this.state.spending_point,
			membership_point: this.state.membership_point,
			is_spending_added: this.state.is_spending_added,
			is_spending_removed: this.state.is_spending_removed,
			is_membership_added: this.state.is_membership_added,
			is_membership_removed: this.state.is_membership_removed,
			created_by: this.props.loggedInUserId,
		};

		this.props.changeUserAdminPoints(
			{
				points_history,
			},
			this.props.userId
		);

		this.handleClose();
	};

	handleClose = () => {
		this.setState({
			reason: "",
			membership_point_change: "",
			is_membership_added: false,
			is_membership_removed: false,
			spending_point_change: "",
			is_spending_added: false,
			is_spending_removed: false,
		});
	};

	validateNumeric(value) {
		let regex = /^[1-9]\d*$/;
		return regex.test(value);
	}

	handleSpendingPointChange = (e) => {
		let points_input = e.target.value;
		let negative = (points_input.charAt(0) === "-");

		let numbers = negative ? points_input.substring(1, points_input.length) : points_input;

		if((points_input === "") || (negative && !numbers) || (numbers && this.validateNumeric(numbers))){
			this.setState({
				spending_point_change: points_input,
				spending_point: numbers,
				is_spending_added: !negative,
				is_spending_removed: negative,
			});
		}
	};

	handleMembershipPointChange = (e) => {
		let points_input = e.target.value;
		let negative = (points_input.charAt(0) === "-");

		let numbers = negative ? points_input.substring(1, points_input.length) : points_input;

		if((points_input === "") || (negative && !numbers) || (numbers && this.validateNumeric(numbers))){
			this.setState({
				membership_point_change: points_input,
				membership_point: numbers,
				is_membership_added: !negative,
				is_membership_removed: negative,
			});
		}
	};

	checkValidation = () => {
		if (
			(this.state.spending_point || this.state.membership_point) &&
			this.state.reason
		) {
			return false;
		} else {
			return true;
		}
	};

	render() {
		const { spendingPoints, membershipPoints } = this.props;
		let { spending_point_change, membership_point_change, reason } = this.state;

		return (
			<Modal
				className="admin points-dialog"
				triggerBtnText="Change"
				triggerBtnClassName="button"
				cancelBtnText="Cancel"
				submitBtnText="Save"
				submitDisabled={this.checkValidation()}
				onSubmit={this.handleSubmit}
				onClose={this.handleClose}
			>
				<LpHeading
					type="h4"
					variant="sub-section"
					className="sub-title"
				>{`${truncateNumber(
					spendingPoints
				)} Spending Points`}</LpHeading>

				<FormFieldInput
					id="spending"
					name="spending"
					label="Add, or Remove (-)"
					onChange={this.handleSpendingPointChange}
					value={ spending_point_change }
				/>

				<LpHeading
					type="h4"
					variant="sub-section"
					className="sub-title"
				>{`${truncateNumber(
					membershipPoints
				)} Season Points`}</LpHeading>

				<FormFieldInput
					id="membership"
					name="membership"
					label="Add, or Remove (-)"
					onChange={this.handleMembershipPointChange}
					value={ membership_point_change }
				/>

				<p className="note">
					Note: when removing points, use a minus sign along with
					numerical value.
				</p>

				<FormFieldTextarea
					name="story"
					label="Staff comment (required)"
					maxLength={75}
					onChange={(event) =>
						this.setState({ reason: event.target.value })
					}
					hint="Users will see this this comment, so be brief and concise in
					your description."
					value={ reason }
				/>

				<span></span>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		changeUserAdminPoints: (params, userId) =>
			dispatch(changeUserAdminPoints(params, userId)),
	};
};

const mapStateToProps = (state) => {
	return {
		loggedInUserId: state.user.profile.id,
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(PointsChangeDialog)
);
