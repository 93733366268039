import { all } from "redux-saga/effects";

import { fetchQuestsOutOfExperiencesWatcher } from "./FetchQuestsOutOfExperiences";
import { fetchQuestsCompletedWatcher } from "./FetchQuestsCompleted";
import { fetchExperiencesWatcher } from "./FetchExperiences";
import { fetchQuestWatcher } from "./FetchQuest";
import { fetchExperienceDetailsWatcher } from "./FetchExperienceDetails";

export function* v2QuestsSagas() {
	yield all([
		fetchQuestsOutOfExperiencesWatcher(),
		fetchQuestsCompletedWatcher(),
		fetchExperiencesWatcher(),
		fetchQuestWatcher(),
		fetchExperienceDetailsWatcher(),
	]);
}
