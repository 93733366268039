import api from "../../util/api";
import { appendNotifications } from "../../actions/Notifications";
import { clearEndPointCache } from "../../actions/EndpointCache";
import { clearPointHistories } from "../../actions/PointHistories";
import { clearUserProfile } from "../../actions/User";
import { push } from "react-router-redux";
import { REQUEST_DELETE_ACCOUNT, DELETE_ACCOUNT } from "../../constants/ActionTypes";
import { SUCCESS, ERROR } from "../../constants/Notifications";
import { takeLatest, call, put } from "redux-saga/effects";
import { USER_REQUEST_DELETE_ACCOUNT_URL, USER_DELETE_ACCOUNT_URL } from "../../constants/Url";

export function* requestDeleteAccountWatcher() {
	yield takeLatest(REQUEST_DELETE_ACCOUNT, requestDeleteAccountWorker);
}
export function* deleteAccountWatcher() {
	yield takeLatest(DELETE_ACCOUNT, deleteAccountWorker);
}

/**
 * @param {String} action.params.reason The given reason for requesting account deletion.
 *
 * @param {Object} [action.options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [action.options.success] Behavior to execute when API call and action is successful.
 * @param {String} [action.options.success.notification] Display notification with passed text on success. If ```message``` is ```true```, push API response message instead of passed string.
 * @param {Function} [action.options.success.callback] Callback function on success.
 * @param {*} [action.options.success.callbackArgs] Callback function args on success.
 *
 * @param {Object} [action.options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [action.options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [action.options.failure.callback] Callback function on failure.
 * @param {*} [action.options.failure.callbackArgs] Callback function args on failure.
 */
function* requestDeleteAccountWorker(action) {
	try {
		const response = yield call(requestDeleteAccountApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}

		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		}

		if (options?.redirect) {
			yield put(push(options.redirect));
		}
	} catch (e) {
		console.error(e);
	}
}

/**
 * @param {String} action.params.token The encrypted account deletion token, received from the account deletion confirmation email.
 *
 * @param {Object} [action.options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [action.options.success] Behavior to execute when API call and action is successful.
 * @param {String} [action.options.success.notification] Display notification with passed text on success. If ```message``` is ```true```, push API response message instead of passed string.
 * @param {Function} [action.options.success.callback] Callback function on success.
 * @param {*} [action.options.success.callbackArgs] Callback function args on success.
 *
 * @param {Object} [action.options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [action.options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [action.options.failure.callback] Callback function on failure.
 * @param {*} [action.options.failure.callbackArgs] Callback function args on failure.
 */
function* deleteAccountWorker(action) {
	try {
		const response = yield call(deleteAccountApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (isSuccess) {
			yield put(push("/delete-account/deleted?success=true"));

			window.browserStorage.removeJWT();
			window.browserStorage.remove("refresh_token");
			window.browserStorage.removePermission();

			yield put(clearUserProfile());
			yield put(clearPointHistories());
			yield put(clearEndPointCache());
		}

		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}

		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		}
	} catch (e) {
		console.error(e);
	}
}

/**
 * Sends an API request to confirm an account deletion of the currently logged in account by sending an email to the logged in user.
 * @param {String} params.reason The given reason for requesting account deletion.
 *
 * @return {Object} The response object from the API call.
 */
function requestDeleteAccountApi(params) {
	return api
		.post(USER_REQUEST_DELETE_ACCOUNT_URL, {
			reason: params.reason,
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}

/**
 * Sends an API request to request customer support to delete the account associated with the encrypted token.
 * @param {String} params.token The account deletion token received in the account deletion confirmation email.
 *
 * @return {Object} The response object from the API call.
 */
function deleteAccountApi(params) {
	return api
		.post(USER_DELETE_ACCOUNT_URL, {
			token: params.token,
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
