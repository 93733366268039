import {
    setUserLifetimePoints,
    setUserMembershipPoints,
    setUserSpendingPoints,
} from '../../actions/UserAdminPanel';
import {
    takeLatest,
    call,
    put,
} from "redux-saga/effects";
import {
    GET_SELECTED_USER_LIFETIME_POINTS,
    GET_SELECTED_USER_MEMBERSHIP_POINTS,
    GET_SELECTED_USER_SPENDING_POINTS,
} from "../../constants/ActionTypes";
import {
    USER_SERVICE_POINTS_LIFETIME_URL,
    USER_SERVICE_POINTS_MEMBERSHIP_URL,
    USER_SERVICE_POINTS_SPENDING_URL,
} from "../../constants/Url";

import api from '../../util/api'; 

export function* fetchUserLifetimePointsWatcher() {
    yield takeLatest(GET_SELECTED_USER_LIFETIME_POINTS, fetchUserLifetimePointsWorker);
};
export function* fetchUserMembershipPointsWatcher() {
    yield takeLatest(GET_SELECTED_USER_MEMBERSHIP_POINTS, fetchUserMembershipPointsWorker);
};
export function* fetchUserSpendingPointsWatcher() {
    yield takeLatest(GET_SELECTED_USER_SPENDING_POINTS, fetchUserSpendingPointsWorker);
};

function* fetchUserLifetimePointsWorker(action) {
    try {
        let response = yield call(fetchUserLifetimePointsApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(setUserLifetimePoints(response.data.data.total_lifetime_points));
        } else {
            throw (response);
        };
    } catch (e) {
        console.error(e);
    };
};

function* fetchUserMembershipPointsWorker(action) {
    try {
        let response = yield call(fetchUserMembershipPointsApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(setUserMembershipPoints(response.data.data.total_membership_points));
        } else {
            throw (response);
        };
    } catch (e) {
        console.error(e);
    };
};

function* fetchUserSpendingPointsWorker(action) {
    try {
        let response = yield call(fetchUserSpendingPointsApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(setUserSpendingPoints(response.data.data.total_spending_points));
        } else {
            throw (response);
        };
    } catch (e) {
        console.error(e);
    };
};

function fetchUserLifetimePointsApi(userId) {
    let token = window.browserStorage.getJWT();
    return (
        api
        .get(USER_SERVICE_POINTS_LIFETIME_URL(userId), {
            token,
        })
    );
};

function fetchUserMembershipPointsApi(userId) {
    let token = window.browserStorage.getJWT();
    return (
        api
        .get(USER_SERVICE_POINTS_MEMBERSHIP_URL(userId), {
            token,
        })
    );
};

function fetchUserSpendingPointsApi(userId) {
    let token = window.browserStorage.getJWT();
    return (
        api
        .get(USER_SERVICE_POINTS_SPENDING_URL(userId), {
            token,
        })
    );
};
