import { call, put, takeLatest } from "redux-saga/effects";
import { appendNotifications } from "../../actions/Notifications";
import { ADD_LIQUIPEDIA_KEYWORD } from "../../constants/ActionTypes";
import { ERROR, SUCCESS } from "../../constants/Notifications";
import { POST_LIQUIPEDIA_KEYWORD } from "../../constants/Url";

import api from "../../util/api";

export function* createKeywordWatcher() {
	yield takeLatest(ADD_LIQUIPEDIA_KEYWORD, createKeywordWorker);
}

function* createKeywordWorker(action) {
	try {
		const response = yield call(createKeywordApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: response?.data?.error || options.notification,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, response.data);
		}
	} catch (err) {
		console.error(err);
	}
}

async function createKeywordApi(payload) {
	try {
		return await api.post(POST_LIQUIPEDIA_KEYWORD, { keyword: payload.keyword });
	} catch (error) {
		return error.response;
	}
}
