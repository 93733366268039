import { setUserPerks } from "../../actions/Perks";
import { takeLatest, call, put } from "redux-saga/effects";
import { FETCH_USER_PERKS_WATCHER } from "../../constants/ActionTypes";
import { FETCH_USER_PERKS_URL } from "../../constants/Url";

import api from "../../util/api";

export function* fetchUserPerksWatcher() {
	yield takeLatest(FETCH_USER_PERKS_WATCHER, fetchUserPerksWorker);
}

function* fetchUserPerksWorker(action) {
	try {
		let response = yield call(fetchUserPerksApi);
		if (response.status >= 200 && response.status < 300) {
			yield put(setUserPerks(response.data.data));
		} else {
			throw response;
		}
	} catch (e) {
		console.log(e);
	}
}

function fetchUserPerksApi() {
	return api.get(FETCH_USER_PERKS_URL);
}
