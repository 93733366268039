import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { setV2QuestsBank } from "../../actions/V2Quests";

// Util
import api from "../../util/api";

// Constants
import { FETCH_V2_QUEST_WATCHER } from "../../constants/ActionTypes";
import { V2_QUESTS } from "../../constants/Url";

export function* fetchQuestWatcher() {
	yield takeLatest(FETCH_V2_QUEST_WATCHER, fetchQuestWorker);
}

function* fetchQuestWorker(action) {
	try {
		const response = yield call(fetchQuestApi, action.urlSlug);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action.options?.success : action.options?.failure;

		if (isSuccess) {
			yield put(setV2QuestsBank({ quest: response.data.data }));
		}

		if (options?.callback) yield call(options.callback, response.data);
	} catch (e) {
		console.error(e);
	}
}

function fetchQuestApi(urlSlug) {
	return api
		.get(V2_QUESTS + "/" + urlSlug)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
