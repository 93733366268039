import React, { Component } from "react";
import { connect } from "react-redux";
import {
	fetchPointsProcessingEvents,
	instantProcessPoints,
} from "../../../actions/AdminPanel";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import LpHeading from "../../../components/LpHeading";

class PointProcessingPanel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			process: [],
		};
	}

	componentDidMount() {
		this.props.fetchLoyaltyEvents();
	}

	underscoreToTitleCase(event) {
		return event
			.replace(/_/g, " ")
			.split(" ")
			.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
			.join(" ");
	}

	versionedEvent(event) {
		return {
			raw: event,
			display: this.underscoreToTitleCase(event),
		};
	}

	addOrRemoveEvent(event, include) {
		let { process } = this.state;
		if (include) {
			process.push(this.versionedEvent(event));
		} else {
			process = process.filter((e) => e.raw !== event);
		}

		this.setState({ process });
	}

	selectAllEvents() {
		this.setState({
			process: [
				...this.props.points_processing.events.map((e) =>
					this.versionedEvent(e)
				),
			],
		});
	}

	process() {
		this.props.processEvents(this.state.process);
		this.setState({ process: [] });
	}

	eventIsChecked(event) {
		return this.state.process.findIndex((e) => e.raw === event) !== -1;
	}

	mapProcessableEvents() {
		return this.props.points_processing.events.map((event) => {
			return (
				<TableRow key={event}>
					<TableCell>{this.underscoreToTitleCase(event)}</TableCell>
					<input
						checked={this.eventIsChecked(event)}
						onChange={(e) =>
							this.addOrRemoveEvent(event, e.target.checked)
						}
						type="checkbox"
					/>
				</TableRow>
			);
		});
	}

	renderButtonPanel() {
		return (
			<div>
				<button onClick={() => this.selectAllEvents()}>
					Select All
				</button>
				<button onClick={() => this.process()}>Begin Processing</button>
			</div>
		);
	}

	render() {
		return (
			<React.Fragment>
				<div className="container">
					<div className="row">
						<div className="col-md-6 p-5">
							<LpHeading type="h3" variant="sub-section">
								Events
							</LpHeading>
							<div className="table-responsive-material shadow border-0 card">
								<Table>
									<TableBody>
										{this.mapProcessableEvents()}
									</TableBody>
								</Table>
							</div>
							{this.renderButtonPanel()}
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchLoyaltyEvents: () => dispatch(fetchPointsProcessingEvents()),
		processEvents: (events) => dispatch(instantProcessPoints(events)),
	};
};

const mapStateToProps = (state) => {
	return {
		points_processing: state.admin_panel.service_settings.points_processing,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PointProcessingPanel);
