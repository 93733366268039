import { all } from "redux-saga/effects";

import { fetchQuestProgressionsWatcher } from "./FetchQuestProgressionsSagas";
import { fetchQuestProgressionBucketTopUsersWatcher } from "./FetchQuestProgressionBucketTopUsersSagas";
import { fetchQuestProgressionBucketTopChannelsWatcher } from "./FetchQuestProgressionBucketTopChannelsSagas";
import { claimRewardsFromQuestProgressionWatcher } from "./ClaimRewardsFromQuestProgressionSagas";
import { acceptTermsFromQuestProgressionWatcher } from "./AcceptTermsFromQuestProgressionSagas";

export function* questProgressionSagas() {
	yield all([
		fetchQuestProgressionsWatcher(),
		fetchQuestProgressionBucketTopUsersWatcher(),
		fetchQuestProgressionBucketTopChannelsWatcher(),
		claimRewardsFromQuestProgressionWatcher(),
		acceptTermsFromQuestProgressionWatcher(),
	]);
}