
import {
    fetchEvent
 } from "../../actions/Events";
 import {
     showLoader,
     hideLoader
 } from "../../actions/GlobalAction";
 import {
     takeLatest,
     call,
     put
 } from "redux-saga/effects";
 import { // if you want to redirect post saga action
     push
 } from "react-router-redux";
 import {
    EMAIL_EVENT_DATA
 } from "../../constants/ActionTypes";
 import {
    EMAIL_EVENT_DATA_URL
 } from "../../constants/Url";
 
 import api from '../../util/api';
 
 function emailUpdateApi(payload) {
     const {
         id
     } = payload;
     let token = window.browserStorage.getJWT();
     return (
        api
         .post(EMAIL_EVENT_DATA_URL(id), {
             ...payload.params,
             id
         }, {
             token
         })
     );
 };
 
 export function* emailEventWatcher() {
     yield takeLatest(EMAIL_EVENT_DATA, emailEventWorker);
 };
 
 function* emailEventWorker(action) {
     try {
         yield put(showLoader());
         let response = yield call(emailUpdateApi, action.payload);
         if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
             yield put(fetchEvent(response.data.data));
         } else {
             throw (response);
         }
         yield put(hideLoader());
     } catch (e) {
         yield put(hideLoader());
     };
 };