import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./styles/index.scss";
import LpIcon from "../LpIcon";

const LpLabel = ({ variant, color, className, onRemove, value, children }) => {
	const handleClick = (event) => {
		onRemove(event, value);
		event.stopPropagation();
		event.preventDefault();
	};

	const withAction =
		onRemove && (variant === "default" || variant === "large");

	const Label = withAction ? "button" : "span";

	return (
		<Label
			className={classnames(
				"lp_label",
				`lp_label--${variant}`,
				`lp_label--${color}`,
				{
					"lp_label--with-action": withAction,
				},
				className
			)}
			onClick={(event) => {
				return withAction ? handleClick(event) : null;
			}}
		>
			{children}
			{withAction && (
				<span className="lp_label__action">
					<LpIcon
						src={require("../../svg/close.svg")}
						title="close"
						alt="Action icon"
						arialabel="close"
						className="lp_label__icon"
					/>
				</span>
			)}
		</Label>
	);
};

LpLabel.defaultProps = {
	variant: "default",
	color: "default",
};

LpLabel.propTypes = {
	color: PropTypes.oneOf(["default", "secondary", "success", "warning", "error"]),
	variant: PropTypes.oneOf(["default", "small", "large"]),
	className: PropTypes.string,
	onRemove: PropTypes.func,
	value: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default LpLabel;
