import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "../../../../components/Modal";
import { FormFieldInput } from "../../../../components/FormFields";
import { updateEvent } from "../../../../actions/Events";
import "../styles.scss";

class ChangeRedirectUrlModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			redirect_url: "",
		};
	}

	componentWillMount() {
		let { redirect_url } = this.props;

		this.setState({
			redirect_url: redirect_url,
		});
	}

	componentWillReceiveProps(nextProps) {
		let { redirect_url } = this.state;

		if (redirect_url !== nextProps.redirect_url) {
			this.setState({
				redirect_url: nextProps.redirect_url,
			});
		}
	}

	handleSubmit = () => {
		let { redirect_url } = this.state;
		let { updateEvent, eventId } = this.props;

		if (redirect_url !== undefined) {
			updateEvent(
				{
					redirect_url: redirect_url,
				},
				eventId
			);
		}
	};

	render() {
		let { redirect_url } = this.state;

		return (
			<Modal
				className="admin"
				triggerBtnText="Change"
				triggerBtnClassName="button button--borderless"
				cancelBtnText="Cancel"
				submitBtnText="Save"
				submitDisabled={redirect_url === this.props.redirect_url}
				onSubmit={() => this.handleSubmit()}
				onClose={(event) =>
					this.setState({
						redirect_url: this.props.redirect_url,
					})
				}
			>
				<FormFieldInput
					required
					intro={"Enter the external URL."}
					label={"Link URL"}
					type={"text"}
					name={"redirect_url"}
					value={redirect_url}
					onChange={(event) =>
						this.setState({ redirect_url: event.target.value })
					}
				/>
			</Modal>
		);
	}
}

ChangeRedirectUrlModal.propTypes = {
	redirect_url: PropTypes.string,
	updateEvent: PropTypes.func.isRequired,
	eventId: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateEvent: (params, id) => dispatch(updateEvent(params, id)),
	};
};

export default connect(null, mapDispatchToProps)(ChangeRedirectUrlModal);
