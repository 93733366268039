import {
	FETCH_TOTAL_USERS_WATCHER,
	SET_TOTAL_USERS,
	FETCH_SEARCHED_USERS_WATCHER,
	SET_SEARCHED_USERS,
	FETCH_ALL_STAFF_WATCHER,
	SET_ALL_STAFF,
	GET_ROLES_WATCHER,
	SET_ROLES,
	FETCH_USER_ACCOUNT_INFO_WATCHER,
	FETCH_USER_INFO_BY_USER_NAME_WATCHER,
	SET_USER_ACCOUNT_INFO,
	GET_SELECTED_USER_LIFETIME_POINTS,
	SET_SELECTED_USER_LIFETIME_POINTS,
	GET_SELECTED_USER_MEMBERSHIP_POINTS,
	SET_SELECTED_USER_MEMBERSHIP_POINTS,
	GET_SELECTED_USER_SPENDING_POINTS,
	SET_SELECTED_USER_SPENDING_POINTS,
	UPDATE_USERS_INFO_WATCHER,
	BAN_USER_WATCHER,
	UNBAN_USER_WATCHER,
	UPDATE_REFERRAL_LINK_WATCHER,
	ADMIN_POINTS_CHANGE_WATCHER,
	FETCH_ADMIN_USER_ORDERS_WATCHER,
	SET_ADMIN_USER_ORDERS,
	FETCH_SELECTED_USER_INTERACTIONS_WATCHER,
	SET_SELECTED_USER_INTERACTIONS,
	FETCH_SELECTED_USER_REFERRALS_WATCHER,
	SET_SELECTED_USER_REFERRALS,
	FETCH_INTERACTIONS_WATCHER,
	SET_INTERACTIONS,
	FETCH_POINTS_HISTORY_WATCHER,
	SET_POINTS_HISTORY,
	FETCH_BAN_USERS_WATCHER,
	SET_BAN_USERS,
	FETCH_PERMISSION_LIST,
	SET_PERMISSION_LIST,
	DELETE_USER,
} from "../constants/ActionTypes";

export const fetchInteractions = () => {
	return {
		type: FETCH_INTERACTIONS_WATCHER,
	};
};

export const setInteractions = (interactions) => {
	return {
		type: SET_INTERACTIONS,
		payload: interactions,
	};
};

export const fetchUserInteractions = (params) => {
	return {
		type: FETCH_SELECTED_USER_INTERACTIONS_WATCHER,
		payload: params,
	};
};

export const setUserInteractions = (interactions) => {
	return {
		type: SET_SELECTED_USER_INTERACTIONS,
		payload: interactions,
	};
};

export const fetchAdminUserOrders = (userId, page, limit) => {
	return {
		type: FETCH_ADMIN_USER_ORDERS_WATCHER,
		payload: {
			userId,
			page,
			limit,
		},
	};
};

export const setAdminUserOrders = (userOrders) => {
	return {
		type: SET_ADMIN_USER_ORDERS,
		payload: userOrders,
	};
};

export const fetchUserReferrals = (userId, page, limit) => {
	return {
		type: FETCH_SELECTED_USER_REFERRALS_WATCHER,
		payload: {
			userId,
			page,
			limit,
		},
	};
};

export const setUserReferrals = (referrals) => {
	return {
		type: SET_SELECTED_USER_REFERRALS,
		payload: referrals,
	};
};

export const fetchUserAccountInfo = (user_id, options) => {
	if (user_id) {
		return {
			type: FETCH_USER_ACCOUNT_INFO_WATCHER,
			payload: user_id,
			options: options,
		};
	}
};

export const fetchUserInfoByUserName = (user_name, options) => {
	if (user_name) {
		return {
			type: FETCH_USER_INFO_BY_USER_NAME_WATCHER,
			payload: user_name,
			options: options,
		};
	}
};

export const setUserAccountInfo = (userAccountsInfo) => {
	return {
		type: SET_USER_ACCOUNT_INFO,
		payload: userAccountsInfo,
	};
};

export const fetchTotalUsers = () => {
	return {
		type: FETCH_TOTAL_USERS_WATCHER,
	};
};

export const setTotalUsers = (total_users) => {
	return {
		type: SET_TOTAL_USERS,
		payload: total_users,
	};
};

export const fetchSearchedUsers = (search_fields) => {
	if (search_fields) {
		return {
			type: FETCH_SEARCHED_USERS_WATCHER,
			payload: search_fields,
		};
	}
};

export const setSearchedUsers = (users) => {
	return {
		type: SET_SEARCHED_USERS,
		payload: users,
	};
};

export const fetchPermissionList = () => {
	return {
		type: FETCH_PERMISSION_LIST,
	};
};

export const setPermissionList = (permissions) => {
	return {
		type: SET_PERMISSION_LIST,
		payload: permissions,
	};
};

export const fetchAllStaff = () => {
	return {
		type: FETCH_ALL_STAFF_WATCHER,
	};
};

export const setAllStaff = (staffs) => {
	return {
		type: SET_ALL_STAFF,
		payload: staffs,
	};
};

export const banUser = (params, id) => {
	return {
		type: BAN_USER_WATCHER,
		payload: {
			...params,
			id,
		},
	};
};

export const unBanUser = (params, id) => {
	if (id) {
		return {
			type: UNBAN_USER_WATCHER,
			payload: {
				...params,
				id,
			},
		};
	}
};

export const updateReferralLinkUser = (params, options) => {
	return {
		type: UPDATE_REFERRAL_LINK_WATCHER,
		params: params,
		options: options,
	};
};

export const fetchPointsHistory = (params) => {
	return {
		type: FETCH_POINTS_HISTORY_WATCHER,
		payload: params,
	};
};

export const setPointsHistory = (pointsHistory) => {
	return {
		type: SET_POINTS_HISTORY,
		payload: pointsHistory,
	};
};

export const fetchBanUsers = (params) => {
	return {
		type: FETCH_BAN_USERS_WATCHER,
		payload: params,
	};
};

export const setBanUsers = (banUsers) => {
	return {
		type: SET_BAN_USERS,
		payload: banUsers,
	};
};

export const getUserLifetimePoints = (userId) => {
	return {
		type: GET_SELECTED_USER_LIFETIME_POINTS,
		payload: userId,
	};
};

export const setUserLifetimePoints = (lifetimePoints) => {
	return {
		type: SET_SELECTED_USER_LIFETIME_POINTS,
		payload: lifetimePoints,
	};
};

export const getUserMembershipPoints = (userId) => {
	return {
		type: GET_SELECTED_USER_MEMBERSHIP_POINTS,
		payload: userId,
	};
};

export const setUserMembershipPoints = (membershipPoints) => {
	return {
		type: SET_SELECTED_USER_MEMBERSHIP_POINTS,
		payload: membershipPoints,
	};
};

export const getUserSpendingPoints = (userId) => {
	return {
		type: GET_SELECTED_USER_SPENDING_POINTS,
		payload: userId,
	};
};

export const setUserSpendingPoints = (spendingPoints) => {
	return {
		type: SET_SELECTED_USER_SPENDING_POINTS,
		payload: spendingPoints,
	};
};


export const updateUsersInfo = (params, id) => {
	if (id) {
		return {
			type: UPDATE_USERS_INFO_WATCHER,
			payload: {
				params,
				id,
			},
		};
	}
};

export const changeUserAdminPoints = (params, userId) => {
	if (userId) {
		return {
			type: ADMIN_POINTS_CHANGE_WATCHER,
			payload: {
				...params,
				userId,
			},
		};
	}
};


export const getRoles = () => {
	return {
		type: GET_ROLES_WATCHER,
	};
};

export const setRoles = (roles) => {
	return {
		type: SET_ROLES,
		payload: roles,
	};
};

export function deleteUser(params, options) {
	return {
		type: DELETE_USER,
		params,
		options,
	};
}
