import React from "react";
import PropTypes from "prop-types";

const Option = ({ option, onChange }) => {
	return (
		<li
			className="multi-select__option"
			onClick={(event) => {
				event.stopPropagation();
				onChange(event, option.value);
			}}
		>
			{option.label}
		</li>
	);
};

Option.propTypes = {
	option: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default Option;
