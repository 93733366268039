import { takeLatest, call, put, select} from "redux-saga/effects";

// Actions
import { setV2QuestsBank } from "../../actions/V2Quests";
import { appendNotifications } from "../../actions/Notifications";

// Constants
import { ENTER_CAMPAIGN_WATCHER } from "../../constants/ActionTypes";
import { ENTER_CAMPAIGN_URL } from "../../constants/Url";
import { ERROR } from "../../constants/Notifications";

// Utils
import api from "../../util/api";
import i18next from "i18next";

export function* enterCampaignsWatcher() {
	yield takeLatest(ENTER_CAMPAIGN_WATCHER, enterCampaignsWorker);
}

function* enterCampaignsWorker(action) {
	const callback = action?.options?.callback;
	try {
		const response = yield call(enterCampaignsApi, action.payload);
		const isSuccess = parseInt(response.status) >= 200 && parseInt(response.status) < 300;

		if (isSuccess) {
			const current = yield select((state) => state.v2Quests.experienceDetails) || [];
			yield put(
				setV2QuestsBank({ experienceDetails: { ...current, ...response.data.data } })
			);
		}

		if (callback) yield call(callback);
	} catch (e) {
		yield put(
			appendNotifications({
				type: ERROR,
				message: i18next.t("notifications:experienceJoinError"),
			})
		);
		if (callback) yield call(callback);
		console.error(e);
	}
}

function enterCampaignsApi(payload) {
	return api.put(ENTER_CAMPAIGN_URL(payload));
}
