import {
    setAllStaff
} from "../../actions/UserAdminPanel"
import {
    showLoader,
    hideLoader
} from "../../actions/GlobalAction";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import { // if you want to redirect post saga action
    push
} from "react-router-redux";
import {
    FETCH_ALL_STAFF_WATCHER
} from "../../constants/ActionTypes";
import {
    ALL_STAFF_URL
} from "../../constants/Url";

import api from '../../util/api'; 

export function* fetchAllStaffWatcher() {
    yield takeLatest(FETCH_ALL_STAFF_WATCHER, fetchAllStaffWorker);
};

function* fetchAllStaffWorker(action) {
    try {
        yield put(showLoader());
        let response = yield call(fetchAllStaffApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(setAllStaff(response.data.data));
        } else {
            throw (response);
        }
        yield put(hideLoader());
    } catch (e) {
        yield put(hideLoader());
    };
};

function fetchAllStaffApi(payload) {
    return (
        api
        .get(ALL_STAFF_URL)
    );
};