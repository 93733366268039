import { takeLatest, call, put } from "redux-saga/effects";

import { setUsersAchievements } from "../../actions/UsersAchievements";
import { appendNotifications } from "../../actions/Notifications";
import { dateCompare } from "../../util/DateUtil";

import { FETCH_USERS_ACHIEVEMENTS_WATCHER } from "../../constants/ActionTypes";
import { FETCH_USERS_ACHIEVEMENTS_URL } from "../../constants/Url";
import { SUCCESS, ERROR } from "../../constants/Notifications";

import api from "../../util/api";

function fetchUsersAchievementsApi(payload) {
	return api
		.get(FETCH_USERS_ACHIEVEMENTS_URL(payload))
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}

export function* fetchUsersAchievementsWatcher() {
	yield takeLatest(FETCH_USERS_ACHIEVEMENTS_WATCHER, fetchUsersAchievementWorker);
}

function* fetchUsersAchievementWorker(action) {
	try {
		let response = yield call(fetchUsersAchievementsApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action.options?.success : action.options?.failure;

		if (isSuccess) {
			yield put(
				setUsersAchievements(
					response.data.data?.sort((a, b) => dateCompare(b.createdAt, a.createdAt))
				)
			);
		}

		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}

		if (options?.callback) yield call(options.callback, response.data);
	} catch (e) {
		console.error(e);
	}
}
