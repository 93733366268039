import React, { useState, useCallback, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import { Transforms, Editor, createEditor } from "slate";
import { withHistory } from "slate-history";
import { Slate, Editable, withReact } from "slate-react";
import "./styles.scss";
import classnames from "classnames";

// Utils
import {
	customEditor,
	deserialize,
	serializeSlateToHtml,
	getSelectedNodes,
	customNormalize,
} from "./utils";

// Atoms
import Toolbar from "./atoms/Toolbar";
import EditorElement from "./atoms/EditorElement";
import Leaf from "./atoms/Leaf";

// Constants
import { HEADING_TYPES, INITIAL_VALUE } from "./constants";

const LpRichTextEditor = function (slateEditorProps) {
	const { onChange, allow_formatting_fullheight, disabled } = slateEditorProps;
	let { initialValue } = slateEditorProps;
	initialValue = initialValue === "" || !initialValue ? INITIAL_VALUE : initialValue;

	const parsed = new DOMParser().parseFromString(initialValue, "text/html");
	let HtmlToSlate = deserialize(parsed.body);
	// console.log(HtmlToSlate);

	const [blockViewActive, setBlockView] = useState(false);
	const [value, setValue] = useState(HtmlToSlate);

	const handleOnchange = (contents) => {
		setValue(contents);

		let htmlOutput = serializeSlateToHtml(contents);

		if (htmlOutput === INITIAL_VALUE) {
			onChange(null);
		} else {
			onChange(htmlOutput);
		}
	};

	const editorRef = useRef();
	if (!editorRef.current)
		editorRef.current = withHtml(withReact(withParagraphs(withHistory(createEditor()))), []);
	const editor = editorRef.current;

	const renderElement = useCallback(
		(props) => <EditorElement customEditor={customEditor} editor={editor} {...props} />,
		[editor]
	);
	const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

	// Normalize initial value to prevent erroneous element structures from being loaded.
	const slateValue = useMemo(() => {
		editor.children = value;
		Editor.normalize(editor, { force: true });
		return editor.children;
	}, [editor, value]);

	return (
		<div
			className={classnames("lp-rich-text-editor", {
				"lp-rich-text-editor--full-height": allow_formatting_fullheight,
			})}
		>
			<Slate editor={editor} value={slateValue} onChange={(value) => handleOnchange(value)}>
				{!disabled && (
					<Toolbar
						editor={editor}
						customEditor={customEditor}
						setBlockView={(value) => {
							setBlockView(value);
						}}
						blockViewActive={blockViewActive}
					/>
				)}
				<Editable
					className={classnames("lp-rich-text-editor__content", {
						"lp-rich-text-editor__content--block-view": blockViewActive,
						"lp-rich-text-editor__content--disabled": disabled,
					})}
					readOnly={disabled}
					renderElement={renderElement}
					renderLeaf={renderLeaf}
					autoFocus={false}
					onKeyDown={(event) => {
						if (event.shiftKey) {
							switch (event.key) {
								case "Enter": {
									event.preventDefault();
									Editor.insertText(editor, "\n");
									break;
								}
								default:
									break;
							}
							return;
						}

						if (event.ctrlKey || event.metaKey) {
							switch (event.key) {
								case "b": {
									event.preventDefault();
									customEditor.toggleBoldMark(editor);
									break;
								}
								case "i": {
									event.preventDefault();
									customEditor.toggleItalicMark(editor);
									break;
								}
								default:
									break;
							}
							return;
						}

						// Enter starts new paragraph after headings
						if (event.key === "Enter") {
							const { selection } = editor;
							if (selection) {
								const selectedNodes = getSelectedNodes(editor);
								if (HEADING_TYPES.includes(selectedNodes.type)) {
									event.preventDefault();
									Transforms.insertNodes(editor, {
										children: [{ text: "" }],
										type: "paragraph",
									});
								}
								return;
							}
						}
						return;
					}}
				/>
			</Slate>
		</div>
	);
};

const withParagraphs = (editor) => {
	const { normalizeNode } = editor;

	editor.normalizeNode = (entry) => {
		const [node, path] = entry;

		customNormalize.paragraphContainsValidChildren(node, path, editor);
		customNormalize.unorderedListHasOnlyListItems(node, path, editor);
		customNormalize.exitListItemWhenEmptyOnReturn(node, path, editor);
		customNormalize.dontAllowParagraphsInListItems(node, path, editor);
		customNormalize.dontAllowParagraphsInHeadings(node, path, editor);
		customNormalize.dontAllowListItemsInroot(node, path, editor);

		// Fall back to the original `normalizeNode` to enforce other constraints.
		normalizeNode(entry);
	};

	return editor;
};
const withHtml = (editor) => {
	const { insertData, isInline, isVoid } = editor;

	editor.isInline = (element) => {
		return element.type === "link" ? true : isInline(element);
	};

	editor.isVoid = (element) => {
		return element.type === "image" ? true : isVoid(element);
	};

	editor.insertData = (data) => {
		const html = data.getData("text/html");
		if (html) {
			const parsed = new DOMParser().parseFromString(html, "text/html");
			const fragment = deserialize(parsed.body);
			Transforms.insertFragment(editor, fragment, { at: editor.selection });
			return;
		}
		insertData(data);
	};

	return editor;
};

LpRichTextEditor.defaultProps = {
	initialValue: INITIAL_VALUE,
	allow_formatting_fullheight: false,
};

LpRichTextEditor.propTypes = {
	initialValue: PropTypes.string,
	allow_formatting_fullheight: PropTypes.bool,
};

export default LpRichTextEditor;
