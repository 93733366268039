import { setEvent } from "../../actions/Events";
import { takeLatest, call, put } from "redux-saga/effects";
import { FETCH_EVENT_WATCHER } from "../../constants/ActionTypes";
import { FETCH_EVENT_URL } from "../../constants/Url";

import api from "../../util/api";

export function* fetchEventWatcher() {
	yield takeLatest(FETCH_EVENT_WATCHER, fetchEventWorker);
}

/**
 *
 * @param {String} action.payload The ID of event to fetch
 */

function* fetchEventWorker(action) {
	try {
		const response = yield call(fetchEventApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;

		if (isSuccess) {
			yield put(setEvent(response.data.data));
		} else {
			throw response;
		}
	} catch (error) {
		console.error(error);
	}
}

function fetchEventApi(payload) {
	return api.get(FETCH_EVENT_URL(payload));
}
