import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "../../../../components/Modal";
import CheckBox from "../../../../components/CheckBox";
import { updateUsersInfo } from "../../../../actions/UserAdminPanel";
import { EMAIL_PREFERENCE_OPTIONS } from "../../../../constants/SelectionOptions";

class EmailPreferencesDialog extends React.Component {
	constructor(props) {
		super(props);
	}

	componentWillMount() {
		this.setState({
			newsletter_subscribed: this.props.newsletter_subscribed,
		});
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			newsletter_subscribed: nextProps.newsletter_subscribed,
		});
	}

	handleClose = () => {
		if (this.chekValidation()) {
			this.props.updateUsersInfo(
				{
					userInfo: {
						newsletter_subscribed: this.state.newsletter_subscribed,
					},
				},
				this.props.userId
			);
		}
	};

	chekValidation() {
		if (this.props.newsletter_subscribed !== this.state.newsletter_subscribed) {
			return true;
		} else {
			return false;
		}
	}

	render() {
		return (
			<Modal
				className="admin email-preferences-modal"
				triggerBtnText="Change"
				triggerBtnClassName="button"
				cancelBtnText="Close"
				onClose={this.handleClose}
			>
				<span className="email-modal_message">
					Check those below that you&apos;d like to stay updated via
					email.
				</span>
				<CheckBox
					id="email_pref_news"
					label={EMAIL_PREFERENCE_OPTIONS.COMMUNITY}
					onChange={(e) =>
						this.setState({
							newsletter_subscribed: e.target.checked,
						})
					}
					checked={this.state.newsletter_subscribed}
				/>
			</Modal>
		);
	}
}

EmailPreferencesDialog.propTypes = {
	newsletter_subscribed: PropTypes.bool,
	updateUsersInfo: PropTypes.func.isRequired,
	userId: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateUsersInfo: (params, id) => dispatch(updateUsersInfo(params, id)),
	};
};

export default connect(null, mapDispatchToProps)(EmailPreferencesDialog);
