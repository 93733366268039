import {
    setTotalUsers
} from "../../actions/UserAdminPanel";
import {
    showLoader,
    hideLoader
} from "../../actions/GlobalAction";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import { // if you want to redirect post saga action
    push
} from "react-router-redux";
import {
    FETCH_TOTAL_USERS_WATCHER
} from "../../constants/ActionTypes";
import {
    COUNT_USERS_URL
} from "../../constants/Url";

import api from '../../util/api'; 

export function* fetchTotalUsersWatcher() {
    yield takeLatest(FETCH_TOTAL_USERS_WATCHER, fetchTotalUsersWorker);
};

function* fetchTotalUsersWorker(action) {
    try {
        yield put(showLoader());
        let response = yield call(fetchTotalUsersApi);
        if (response.status >= 200 && response.status < 300) {
            yield put(setTotalUsers(response.data.count));
        } else {
            throw (response);
        };
        yield put(hideLoader());
    } catch (e) {
        yield put(hideLoader());
    };
};

function fetchTotalUsersApi() {
    return (
        api
        .get(COUNT_USERS_URL)
    );
};