import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./styles.scss";

// Actions
import { fetchGenders } from "../../actions/Genders";

// Components
import SelectField from "../SelectField";

// Translations
import { useTranslation } from "react-i18next";

/* Example Usage:
	<GenderSelect
		required
		value={this.state.gender}
		onChange={(value) => this.setState({ gender: value })}
		clearable={!!this.state.gender}
		user={user}
		defaultGender={DEFAULT_GENDER}
	/>
*/

function GenderSelect({
	label,
	name,
	value,
	defaultGender,
	user,
	genders,
	onChange,
	fetchGenders,
	...props
}) {
	const { t } = useTranslation();

	useEffect(() => {
		fetchGenders();
	}, []);

	useEffect(() => {
		if (genders && genders.length > 0) setCurrentOrDefault();
	}, [genders]);

	function setCurrentOrDefault() {
		let genderObject = null;
		if (user?.userInfo) {
			const genderCode = user.userInfo.gender_code;
			genderObject = genders.find((gender) => gender.code === genderCode);
		}

		// null if no default is specified
		if (!genderObject && defaultGender)
			genderObject = genders.find((gender) => gender.code === defaultGender);

		onChange(genderObject);
	}

	function onSelectionChanged(values) {
		if (values.length === 0) {
			onChange(null);
			return;
		}

		const value = values[0];
		if (!value.value) return;

		onChange({ code: value.value, label: value.label });
	}

	const genderLabels = useMemo(() => {
		if (!genders || genders.length === 0) return null;
		return genders.map((gender) => ({
			label: gender.label,
			value: gender.code,
		}));
	}, [genders]);

	return (
		<div className="GenderSelect">
			<SelectField
				{...props}
				onChange={onSelectionChanged}
				values={value ? [value] : []}
				label={label || t("formFields:identity.label")}
				name={name || "Gender"}
				options={genderLabels}
				searchable={false}
			/>
		</div>
	);
}

GenderSelect.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	defaultGender: PropTypes.string,
	user: PropTypes.shape({
		userInfo: PropTypes.shape({
			gender: PropTypes.string,
			gender_code: PropTypes.string,
		}),
	}),
	genders: PropTypes.arrayOf(
		PropTypes.shape({
			code: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})
	),
	onChange: PropTypes.func,
	fetchGenders: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchGenders: () => dispatch(fetchGenders()),
	};
};

const mapStateToProps = (state) => {
	return {
		genders: state.genders,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GenderSelect);
