import {
    fetchUserAccountInfo
} from "../../actions/UserAdminPanel";
import {
    showLoader,
    hideLoader
} from "../../actions/GlobalAction";
import {
    appendNotifications
} from "../../actions/Notifications";
import {
    USER_UPDATE_ADMIN,
    ERROR
} from "../../constants/Notifications";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import { // if you want to redirect post saga action
    push
} from "react-router-redux";
import {
    UPDATE_USERS_INFO_WATCHER
} from "../../constants/ActionTypes";
import {
    USER_SERVICE_UPDATE_URL
} from "../../constants/Url";
import api from '../../util/api';

export function* fetchUpdateUsersInfoWatcher() {
    yield takeLatest(UPDATE_USERS_INFO_WATCHER, updateUserInfoWorker);
};

function* updateUserInfoWorker(action) {
    try {
        let response = yield call(userUpdateApi, action.payload);
        if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
            yield put(fetchUserAccountInfo(response.data.data.id));
        } else {
            throw (response);
        }
    } catch (e) {
        yield put(appendNotifications(USER_UPDATE_ADMIN(ERROR, e.msg ? e.msg : "")));
    };
};

function userUpdateApi(payload) {
    const {
        id
    } = payload;
    let token = window.browserStorage.getJWT();
    return (
        api
        .post(USER_SERVICE_UPDATE_URL(id), {
            ...payload.params,
            id
        }, {
            token
        }).catch(err => {
            return err.response.data
        })
    );
};