import { takeLatest, call, put } from "redux-saga/effects";

import { FETCH_GAMES } from "../../constants/ActionTypes";
import { ALL_GAMES_URL } from "../../constants/Url";
import { setGames } from "../../actions/Games";
import api from "../../util/api";

export function* fetchGamesWatcher() {
	yield takeLatest(FETCH_GAMES, fetchGamesWorker);
}

function* fetchGamesWorker(action) {
	try {
		const response = yield call(fetchGamesApi,action.payload);

		if (response.status >= 200 && response.status < 300) {
			yield put(setGames(response.data.data));
		} else {
			throw response;
		}
	} catch (error) {
		console.error(error);
	}
}

function fetchGamesApi(payload) {
    return (
        api
        .get(ALL_GAMES_URL, {
            params: {
                is_active: payload.is_active
            }
        })
    );
};

