/* eslint-disable complexity */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import "./i18n";

// Style Files
import "../styles/bootstrap.scss";
import "../styles/app.scss";
import "../styles/app-rtl.scss";

// Actions
import { fetchCountries } from "../actions/Countries";
import { refreshProfile, updateUser } from "../actions/User";
import { appendNotifications } from "../actions/Notifications";

// Utils
import { canViewPrivateContent } from "../util/AccessUtils";

// Pages
import AdminRoutes from "../app/index";

// Components
import Authorize from "../auth/Authorize";

//Constants
import {
	CONTACT,
	COOKIE_POLICY,
	EMAIL_VERIFICATION_FAILED,
	EMAIL_VERIFICATION_RESEND,
	EMAIL_VERIFICATION_SUCCESS,
	EMAIL_VERIFICATION_TOKEN_INVALID,
	EMAIL_VERIFICATION,
	EXPERIENCES,
	FORGOT_PASSWORD,
	NOT_ELIGIBLE,
	NOTICE_TO_PARENT_OR_GUARDIAN,
	PARTNERS,
	POLICIES_OVERVIEW,
	PRIVACY_POLICY,
	REGISTRATION_CLOSED,
	REGISTRATION_NOTIFY,
	RESET_PASSWORD_SUCCESSFUL,
	RESET_PASSWORD,
	SCHEDULE,
	SHOP,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	TERMS_OF_SERVICE,
	ERROR403,
	ERROR404,
	ERROR500,
} from "../constants/RoutesURLs";
import { ADMIN_ACCESS_GROUP } from "../constants/Api";

class App extends Component {
	componentDidMount() {
		const { user_id, fetchCountries } = this.props;
		fetchCountries();

		if (this.jwtFound() && !user_id) {
			this.setupAuthorizedUser();
		} else {
			this.setupVisitor();
		}
	}

	componentDidUpdate(prevProps) {
		const { user_id } = this.props;
		if (prevProps.user_id && user_id !== prevProps.user_id) {
			if (this.jwtFound()) {
				this.setupAuthorizedUser();
			} else {
				this.setupVisitor();
			}
		}
	}

	jwtFound() {
		return window.browserStorage.getJWT();
	}

	isRootUrl = (url) => {
		const path = url.replace(window.location.protocol + "//", "");
		return path === window.location.hostname;
	};

	setupAuthorizedUser() {
		const { refreshProfile } = this.props;
		refreshProfile(null, {
			callback: this.autoRedirect,
		});
	}

	autoRedirect = () => {
		const { history } = this.props;
		const route = window.location.pathname;

		if (!canViewPrivateContent() && !this.authorizedRoute(route)) {
			history.push(NOTICE_TO_PARENT_OR_GUARDIAN);
		}
	};

	setupVisitor() {
		if (!this.authorizedRoute(window.location.pathname)) {
			const { history } = this.props;
			history.push("/error-404");
		}
	}

	authorizedRoute(path) {
		if (path[path.length - 1] === "/") path = path.slice(0, -1);

		const codeRegex = /(\/refer\/[A-Za-z0-9]+)/;
		if (codeRegex.test(path)) return true; // temporary fix, as regex inside switch not working!!

		const profileRegex = /(\/profile\/[A-Za-z0-9-]*)/;
		if (profileRegex.test(path)) {
			return true;
		}

		const experienceRegex = /(\/experiences\/[a-z0-9-]+)/;
		if (experienceRegex.test(path)) {
			return true;
		}

		const questRegex = /(\/experiences\/quests\/[a-z0-9-]+)/;
		if (questRegex.test(path)) {
			return true;
		}

		const shopItemRegex = /(\/shop\/[a-z0-9-]+)/;
		if (shopItemRegex.test(path)) return true;

		const accesstokenRegex = /(\/sign-up\/[A-Za-z0-9_]+)/;
		if (accesstokenRegex.test(path)) return true;

		switch (path) {
			case "":
			case "/":
			case "/delete-account/deleted":
			case "/new-email-confirmed":
			case "/shopify-account-link-verification":
			case "/social-login":
			case CONTACT:
			case COOKIE_POLICY:
			case EMAIL_VERIFICATION_FAILED:
			case EMAIL_VERIFICATION_RESEND:
			case EMAIL_VERIFICATION_SUCCESS:
			case EMAIL_VERIFICATION_TOKEN_INVALID:
			case EMAIL_VERIFICATION:
			case ERROR403:
			case ERROR404:
			case ERROR500:
			case EXPERIENCES:
			case FORGOT_PASSWORD:
			case NOT_ELIGIBLE:
			case NOTICE_TO_PARENT_OR_GUARDIAN:
			case PARTNERS:
			case POLICIES_OVERVIEW:
			case PRIVACY_POLICY:
			case REGISTRATION_CLOSED:
			case REGISTRATION_NOTIFY:
			case RESET_PASSWORD_SUCCESSFUL:
			case RESET_PASSWORD:
			case SCHEDULE:
			case SHOP:
			case SIGNIN:
			case SIGNOUT:
			case SIGNUP:
			case TERMS_OF_SERVICE:
				return true;
			default:
				return false;
		}
	}

	render() {
		const { tReady } = this.props;
		document.title = "Team Liquid CMS";

		return (
			<div className="app-main">
				{tReady && (
					<div id="lp_theme" className="--theme-light">
						<Authorize allow={ADMIN_ACCESS_GROUP} redirect="/error-404/">
							<AdminRoutes />
						</Authorize>
					</div>
				)}
			</div>
		);
	}
}

App.propTypes = {
	user_id: PropTypes.string,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	refreshProfile: PropTypes.func.isRequired,
	fetchCountries: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired,
	appendNotifications: PropTypes.func.isRequired,
	birthday: PropTypes.string,
	parental_consent: PropTypes.bool,
	tReady: PropTypes.bool,
};

const mapStateToProps = (state) => {
	return {
		user_id: state.user.profile.id,
		birthday: state.user.profile?.userInfo?.birthday,
		parental_consent: state.user.profile?.parental_consent_provided,
	};
};

const mapDispatchToProps = {
	refreshProfile,
	updateUser,
	appendNotifications,
	fetchCountries,
};

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(App);
