import React, { useRef, cloneElement, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./styles/index.scss";

// Components
import DropdownButton from "./atoms/DropdownButton";
import DropdownOption from "./atoms/DropdownOption";

const LpDropdown = ({ button, className, children, position, onClose, buttonVariant }) => {
	let childrenToArr = React.Children.toArray(children);
	const [show, setShow] = useState(false);
	const dropdownRef = useRef();
	const IconComponent = button;

	useEffect(() => {
		function handleClickOutside(event) {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setShow(false);
				if (onClose) onClose();
			}
		}

		const eventListener = show ? "addEventListener" : "removeEventListener";
		document[eventListener]("mousedown", handleClickOutside);
	}, [show, dropdownRef, onClose]);

	const handleOptionClick = () => setShow(false);

	const handleNodeClick = (event, callback) => {
		setShow(false);
		if (onClose) onClose();
		if (callback) callback(event);
	};

	const getElementNode = (node) => {
		return cloneElement(node, {
			onClick: (e) => handleNodeClick(e, node.props.onClick),
		});
	};

	return (
		<div className={classnames("lp_dropdown", className)} ref={dropdownRef}>
			{IconComponent ? (
				cloneElement(
					<IconComponent />,
					{
						onClick: () => {
							setShow(!show);
						},
					},
					null
				)
			) : (
				<DropdownButton onClick={() => setShow(!show)} variant={buttonVariant} />
			)}

			<nav>
				<ul
					className={classnames("lp_dropdown__menu", {
						[`lp_dropdown__menu--${position}`]: position !== undefined,
						[`lp_dropdown__menu--hidden`]: !show,
						[`lp_dropdown__menu--single`]: childrenToArr?.length === 1,
					})}
				>
					{childrenToArr.map((node) =>
						node ? (
							<DropdownOption onClick={handleOptionClick} key={node.props.children}>
								{getElementNode(node)}
							</DropdownOption>
						) : null
					)}
				</ul>
			</nav>
		</div>
	);
};

LpDropdown.propTypes = {
	button: PropTypes.func,
	buttonVariant: PropTypes.oneOf(["black", "white"]),
	className: PropTypes.string,
	children: PropTypes.any,
	onClose: PropTypes.func,
	position: PropTypes.oneOf([
		"top",
		"right",
		"bottom",
		"left",
		"top-left",
		"top-right",
		"bottom-right",
		"bottom-left",
	]),
};

export default LpDropdown;
