import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";

// Actions
import { fetchUserReferrals } from "../../../actions/UserAdminPanel";

// Constants
import { COLLECTIONS } from "../../../constants/PaginationData";

// Components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";

class UserReferrals extends Component {
	constructor(props) {
		super(props);

		this.state = {
			rowsPerPageOptions: COLLECTIONS.ROW_PER_PAGE_OPTIONS,
			page: COLLECTIONS.page,
			limit: COLLECTIONS.defaultRowPerPage,
		};
	}

	componentDidMount() {
		const { page, limit } = this.state;
		const { userId, fetchUserReferrals } = this.props;
		if (userId) fetchUserReferrals(userId, page + 1, limit);
	}

	handleChangePage = (event, newPage) => {
		const { page, limit } = this.state;
		const { userId, fetchUserReferrals } = this.props;
		this.setState(
			{
				page: newPage,
			},
			() => fetchUserReferrals(userId, page + 1, limit)
		);
	};

	handleChangeRowsPerPage = (event) => {
		const { page, limit } = this.state;
		const { userId, fetchUserReferrals } = this.props;

		if (event.target.value) {
			this.setState(
				{
					page: 0,
					limit: event.target.value,
				},
				() => fetchUserReferrals(userId, page + 1, limit)
			);
		}
	};

	getIcon(is_connected) {
		let icon = is_connected ? "social-icon--teamliquid teamliquid-custom" : null;
		return <span className={icon}> </span>;
	}

	tablePagination() {
		const { limit, rowsPerPageOptions, page } = this.state;
		const { count } = this.props;
		if (count > limit) {
			return (
				<TablePagination
					className={`float-right`}
					component="div"
					count={count}
					rowsPerPage={limit}
					rowsPerPageOptions={rowsPerPageOptions}
					page={page}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
					classes={{
						select: "pagination-select",
					}}
				/>
			);
		}
	}

	loadTableBody() {
		const { referrals } = this.props;
		if (referrals && referrals.length) {
			return referrals.map((item) => {
				return (
					<TableRow key={item.id}>
						<TableCell width="25%">
							<span className="user-profile d-flex flex-row align-items-center">
								<div className="users-list__no-avatar" />
								<span className="user-detail">
									<span className="user-name">{item.username} </span>
									<br></br>
									<span>{moment(item.createdAt).format("YYYY.MM.DD")}</span>
								</span>
							</span>
						</TableCell>

						<TableCell width="5%">{this.getIcon(item.is_connected)}</TableCell>

						<TableCell padding="none" className={`align-right`}>
							<Link
								to={`/app/accounts/users/${item.id}`}
								title={`Go to ${item.username}'s profile`}
							>
								<Button size="small" variant="text" mini={true}>
									<span>
										<i className="zmdi zmdi-chevron-right zmdi-hc-lg" />
									</span>
								</Button>
							</Link>
						</TableCell>
					</TableRow>
				);
			});
		}
	}

	render() {
		return (
			<>
				<div className="mt-2 mb-4 pb-4">{this.tablePagination()}</div>
				<div className="table-responsive-material shadow border-0 card table-width table-radious">
					<Table>
						<TableBody>{this.loadTableBody()}</TableBody>
					</Table>
				</div>
				<div>{this.tablePagination()}</div>
				<div className="d-flex legend-margin">
					<span className="social-icon--teamliquid teamliquid-custom default-filter"> </span>
					<span className="legend-text"> User received points for this referral.</span>
				</div>
			</>
		);
	}
}

UserReferrals.propTypes = {
	referrals: PropTypes.array,
	userId: PropTypes.string,
	count: PropTypes.number,
	fetchUserReferrals: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
	fetchUserReferrals: (user_id, page, limit) => dispatch(fetchUserReferrals(user_id, page, limit)),
});

const mapStateToProps = (state) => ({
	referrals: state.user_admin.referrals,
	count: state.user_admin.referralsCount,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserReferrals);
