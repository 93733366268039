import {
	FETCH_USER_PREFERRED_PLAYERS_WATCHER,
	SET_USER_PREFERRED_PLAYERS_WATCHER,
	FETCH_PLAYERS,
	SET_PLAYERS,
	ADD_PLAYER_WATCHER,
	ARCHIVE_RESTORE_PLAYER_WATCHER,
	UPDATE_PLAYER_WATCHER,
	DELETE_PLAYER_WATCHER,
} from "../constants/ActionTypes";

export const fetchUserPreferredPlayers = () => {
	return {
		type: FETCH_USER_PREFERRED_PLAYERS_WATCHER,
	};
};

export const setUserPreferredPlayers = (players) => {
	return {
		type: SET_USER_PREFERRED_PLAYERS_WATCHER,
		payload: players,
	};
};

export const fetchPlayers = (params) => {
	return {
		type: FETCH_PLAYERS,
		payload: params,
	};
};

export const setPlayers = (players) => {
	return {
		type: SET_PLAYERS,
		payload: players,
	};
};

export const addPlayer = (player, options) => {
	return {
		type: ADD_PLAYER_WATCHER,
		payload: player,
		options: options
	};
};

export const archieveOrRestorePlayer = (player, options) => {
	return {
		type: ARCHIVE_RESTORE_PLAYER_WATCHER,
		payload: player,
		options: options
	};
};

export const updatePlayer = (player, options) => {
	return {
		type: UPDATE_PLAYER_WATCHER,
		payload: player,
		options: options
	};
};

export const deletePlayer = (player, options) => {
	return {
		type: DELETE_PLAYER_WATCHER,
		payload: player,
		options: options,
	};
};