import {
    ADD_MESSAGE,
    REMOVE_MESSAGE,
    CLEAR_MESSAGES,
} from '../constants/ActionTypes';

export const addMessage = (message) => {
    return {
        type: ADD_MESSAGE,
        payload: message,
    };
};


export const removeMessage = (messageId) => {
    return {
        type: REMOVE_MESSAGE,
        payload: messageId,
    };
};

export const clearMessages = (source) => {
    return {
        type: CLEAR_MESSAGES,
        payload: source,
    };
};