export const TWITCH = "twitch";
export const TWITTER = "twitter";
export const REDDIT = "reddit";
export const DISCORD = "discord";
export const SHOPIFY = "shopify";
export const TEAM_LIQUID = "team-liquid";

export const broadcastPlatforms = {
	TWITCH: "TWITCH",
	YOUTUBE: "YOUTUBE",
};

export const broadcasPlatformOptions = [
	{
		label: "Twitch",
		value: broadcastPlatforms.TWITCH,
	},
	{
		label: "YouTube",
		value: broadcastPlatforms.YOUTUBE,
	},
];
