import {
	FETCH_ACCOUNT_LINK_WATCHER_ASYNC,
	DISCONNECT_ACCOUNT_LINK_WATCHER,
	LINK_SHOPIFY_ACCOUNT,
	VERIFY_SHOPIFY_ACCOUNT,
} from "../constants/ActionTypes";

export const disconnectAccountPlatform = (data) => {
	return {
		type: DISCONNECT_ACCOUNT_LINK_WATCHER,
		payload: data,
	};
};

export const setAccountLinkerInfo = (data) => {
	return {
		type: FETCH_ACCOUNT_LINK_WATCHER_ASYNC,
		payload: data,
	};
};

export const linkShopifyAccount = (data) => {
	return {
		type: LINK_SHOPIFY_ACCOUNT,
		payload: data.shopifyEmail,
		options: data.options,
	};
};

export const verifyShopifyAccount = (verificationToken) => {
	return {
		type: VERIFY_SHOPIFY_ACCOUNT,
		payload: verificationToken,
	};
};
