import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import ChannelRow from "./Atom";
import LpHeading from "../../../components/LpHeading";

import {
	fetchDiscordChannelCount,
	fetchTwitchChannelCount,
	fetchShopifyChannelCount,
	fetchTwitterChannelCount,
	fetchRedditChannelCount,
} from "../../../actions/Channel";
class Channels extends Component {
	componentDidMount() {
		this.props.fetchDiscordChannelCount();
		this.props.fetchTwitchChannelCount();
		this.props.fetchShopifyChannelCount();
		this.props.fetchTwitterChannelCount();
		this.props.fetchRedditChannelCount();
	}

	render() {
		return (
			<div className="channelsPage admin">
				<LpHeading type="h2">Channels</LpHeading>

				<div className="table-responsive-material shadow border-0 card">
					<Table className="data-table">
						<TableBody>
							<ChannelRow
								channelName={`Twitch`}
								data={this.props.twitch}
								url={`/app/servicesettings/channels/twitch`}
							/>
							<ChannelRow
								channelName={`Twitter`}
								data={this.props.twitter}
								url={`/app/servicesettings/channels/twitter`}
							/>
							<ChannelRow
								channelName={`Reddit`}
								data={this.props.reddit}
								url={`/app/servicesettings/channels/reddit`}
							/>
							<ChannelRow
								channelName={`Discord`}
								data={this.props.discord}
								url={`/app/servicesettings/channels/discord`}
							/>
							<ChannelRow
								channelName={`Team Liquid Store`}
								data={this.props.liquidStore}
								url={`/app/servicesettings/channels/liquidStore`}
							/>
						</TableBody>
					</Table>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchDiscordChannelCount: () => dispatch(fetchDiscordChannelCount()),
		fetchTwitchChannelCount: () => dispatch(fetchTwitchChannelCount()),
		fetchShopifyChannelCount: () => dispatch(fetchShopifyChannelCount()),
		fetchTwitterChannelCount: () => dispatch(fetchTwitterChannelCount()),
		fetchRedditChannelCount: () => dispatch(fetchRedditChannelCount()),
	};
};

const mapStateToProps = ({ channel }) => {
	return {
		data: channel.data,
		twitch: channel.twitchChannel,
		twitter: channel.twitterChannel,
		reddit: channel.redditChannel,
		discord: channel.discordChannel,
		liquidStore: channel.liquidStoreChannel,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Channels);
