import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./styles.scss";

// Components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Paper } from "@material-ui/core";
import ChangeAchievementModal from "./modals/change-achievement";
import { ChangeLimitModal } from "./modals/change-limit";

// Utils
import { formatDate } from "../../../util/DateUtil";

// Actions
import { fetchAchievement, updateAchievement } from "../../../actions/Achievements";

class AchievementView extends Component {
	state = {
		icon_path: require("../../../img/badge-example-3.jpg"),
		quest_msg: "Achievement is tied to a quest",
		event_msg: "Achievement is tied to an event",
		hideContext: true,
	};

	componentDidMount() {
		const { match, fetchAchievement } = this.props;
		const { id: achievementId } = match.params;

		fetchAchievement(achievementId);
	}

	disableAchievement(achievementId) {
		const { updateAchievement } = this.props;

		updateAchievement(
			{
				is_active: false,
			},
			achievementId,
			{
				success: {
					notification: "Achievement disabled.",
					callback: () => {
						this.setState({ hideContext: true });
					},
				},
				failure: {
					notification: "Oops, that didn't work. Can you try again?",
					callback: () => {
						this.setState({ hideContext: true });
					},
				},
			}
		);
	}

	enableAchievement(achievementId) {
		const { updateAchievement } = this.props;

		updateAchievement(
			{
				is_active: true,
			},
			achievementId,
			{
				success: {
					notification: "Achievement enabled.",
					callback: () => {
						this.setState({ hideContext: true });
					},
				},
				failure: {
					notification: "Oops, that didn't work. Can you try again?",
					callback: () => {
						this.setState({ hideContext: true });
					},
				},
			}
		);
	}

	backToAchievementsPage = () => {
		const { history } = this.props;

		history.push("/app/servicesettings/achievements");
	};

	render() {
		const { achievement } = this.props;
		let {
			id,
			name,
			icon_path,
			attachment_limit,
			createdAt,
			is_active,
			event_count,
			quest_count,
		} = achievement;

		const { hideContext, icon_path: state_icon_path, quest_msg, event_msg } = this.state;

		return (
			<div className="m-3 p-3 Achievement--view admin">
				<div className="table-responsive-material shadow border-0 card">
					<div className="container">
						<div className="row p-2">
							<div>
								<button
									variant="text"
									className="button button--borderless"
									onClick={() => this.backToAchievementsPage()}
								>
									<span>
										<i className="zmdi zmdi-chevron-left zmdi-hc-lg pr-1" />
									</span>
									Back
								</button>
							</div>
						</div>
					</div>
				</div>

				<Table className="data-table data-table--page-part">
					<TableBody>
						<tr>
							<td
								class="data-table__data-cell data-table__data-cell--main"
								width="90%"
							>
								<div class="data-table__multi-line data-table__multi-line--large-icon">
									<figure class="data-table__inline-image">
										<img
											src={icon_path ? icon_path : state_icon_path}
											alt="achievement_icon"
										/>
										{!is_active && (
											<img
												className="status-icon icon-disabled"
												src={require("../../../svg/slashed-circle.svg")}
												title="Achievement is disabled"
												alt="disabled"
											/>
										)}
									</figure>
									<div>
										<span class="data-table__item-title">{name}</span>
										<br />
									</div>
								</div>
							</td>
							<TableCell class="data-table__data-cell" width="5%">
								{icon_path && (
									<ChangeAchievementModal
										achievementId={id}
										icon_path={icon_path}
										name={name}
										form="chanage-achievement-form"
									/>
								)}
							</TableCell>
							<TableCell
								className="data-table__data-cell dropdown-button__cell"
								width="5%"
							>
								<button
									className="button button--circle button--borderless"
									onClick={() => this.setState({ hideContext: !hideContext })}
								>
									<img
										className="button__image"
										src={require("../../../svg/ellipsis-vertical.svg")}
										alt="options_menu_icon"
									/>
								</button>
								{!hideContext && (
									<Paper className="context">
										{is_active ? (
											<button
												className="button button--borderless"
												onClick={() => this.disableAchievement(id)}
											>
												Disable Achievement
											</button>
										) : (
											<button
												className="button button--borderless"
												onClick={() => this.enableAchievement(id)}
											>
												Enable Achievement
											</button>
										)}
									</Paper>
								)}
							</TableCell>
						</tr>
					</TableBody>
				</Table>

				<span class="data-table__note">Added on {formatDate(createdAt)}</span>

				<Table className="data-table data-table--page-part">
					<TableBody>
						<TableRow>
							<TableCell class="data-table__data-cell" width="30%">
								Attachment Limit
							</TableCell>
							<TableCell class="data-table__data-cell" width="65%">
								{attachment_limit > 0 ? attachment_limit : "Unlimited"}
							</TableCell>
							<TableCell
								class="data-table__data-cell data-table__data-cell--long"
								width="5%"
							>
								<ChangeLimitModal
									achievementId={id}
									attachment_limit={attachment_limit}
									total_attachments={quest_count + event_count}
									form="change-limit-form"
								/>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								class="data-table__data-cell data-table__data-cell--long"
								width="30%"
							>
								Attached To
							</TableCell>
							<TableCell class="data-table__data-cell" width="70%">
								<div className="attachment-align">
									{quest_count > 0 && (
										<div class="mt-2">
											<i
												class="fa fa-arrow-circle-down"
												aria-hidden="true"
											></i>
											<span class="pl-2">{quest_msg}</span>
										</div>
									)}
									{event_count > 0 && (
										<div class="mt-2">
											<i class="fa fa-star" aria-hidden="true"></i>
											<span class="pl-2">{event_msg}</span>
										</div>
									)}
								</div>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</div>
		);
	}
}

AchievementView.propTypes = {
	achievement: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	fetchAchievement: PropTypes.func.isRequired,
	updateAchievement: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
	fetchAchievement: (achievementId) => dispatch(fetchAchievement(achievementId)),
	updateAchievement: (params, id, options) => dispatch(updateAchievement(params, id, options)),
});

const mapStateToProps = (state) => ({
	achievement: state.achievements.achievement,
});

export default connect(mapStateToProps, mapDispatchToProps)(AchievementView);
