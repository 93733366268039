import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { setSeasons } from "../../actions/Seasons";

// Utils
import api from "../../util/api";
import { isThrottled, setThrottledTimespan } from "../../util/SagaUtils";

// Constants
import { FETCH_SEASONS } from "../../constants/ActionTypes";
import { ALL_SEASONS_URL } from "../../constants/Url";
import { ENDPOINT_CACHING_TIMESPANS } from "../../constants/CachingTimespans";

export function* fetchSeasonsWatcher() {
	yield takeLatest(FETCH_SEASONS, fetchSeasonsWorker);
}

function* fetchSeasonsWorker(action) {
	let endpointIsThrottled = yield isThrottled(ALL_SEASONS_URL);
	if (!endpointIsThrottled) {
		try {
			const response = yield call(fetchSeasonsApi);
			const isSuccess = response.status >= 200 && response.status < 300;
			const options = isSuccess
				? action?.options?.success
				: action?.options?.failure;

			if (isSuccess) {
				yield put(
					setSeasons({
						data: response.data.data,
					})
				);
				yield setThrottledTimespan(
					ALL_SEASONS_URL,
					ENDPOINT_CACHING_TIMESPANS.FETCH_SEASONS
				);
			}

			if (options?.callback) {
				yield call(options.callback, options.callbackArgs);
			}
		} catch (error) {
			console.error(error);
		}
	}
}

function fetchSeasonsApi() {
	return api
		.get(ALL_SEASONS_URL)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
