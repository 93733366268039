import React from "react";
import { NotificationManager } from "react-notifications";
import TLToast from "../components/TLToast";

export function InfoNotification(message) {
	NotificationManager.info(<TLToast type={"info"} message={message} />, "", 4800);
}

export function SuccessNotification(message) {
	NotificationManager.success(<TLToast type={"success"} message={message} />, "", 4800);
}

export function WarningNotification(message) {
	NotificationManager.warning(<TLToast type={"warning"} message={message} />, "Warning!", 4800);
}

export function ErrorNotification(message) {
	NotificationManager.error(<TLToast type={"error"} message={message} />, "Error!", 4800);
}
