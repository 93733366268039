import { setDiscordChannelCount } from "../../../actions/Channel";

import { showLoader, hideLoader } from "../../../actions/GlobalAction";

import { takeLatest, call, put } from "redux-saga/effects";

import { FETCH_DISCORD_CHANNEL_COUNT } from "../../../constants/ActionTypes";
import { GET_DISCORD_CHANNEL_COUNT } from "../../../constants/Url";

import api from "../../../util/api";

export function* fetchDiscordChannelCountWatcher() {
	yield takeLatest(FETCH_DISCORD_CHANNEL_COUNT, fetchDiscordCountWorker);
}

function* fetchDiscordCountWorker() {
	try {
		yield put(showLoader());
		// data is obtained after axios call is resolved
		let response = yield call(fetchDiscordChannelCountApi);
		if (response.status >= 200 && response.status < 300) {
			const payload = {
				total: response.data.data,
			};
			yield put(setDiscordChannelCount(payload));
		} else {
			throw response;
		}
		yield put(hideLoader());
	} catch (e) {
		console.log(e);
		yield put(hideLoader());
	}
}

function fetchDiscordChannelCountApi() {
	return api.get(GET_DISCORD_CHANNEL_COUNT);
}
