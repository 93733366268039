import React, { useState } from "react";
import PropTypes from "prop-types";
import "../styles.scss";

// Redux
import { connect } from "react-redux";
import { updateRewards } from "../../../../actions/Rewards";

// Components
import LpModal from "../../../../components/LpModal";
import { FormFieldInput } from "../../../../components/FormFields/FormFields";
import LpError from "../../../../components/LpError";

function UrlSlugModal({ currentSlug, rewardId, updateRewards }) {
	const [urlSlug, setUrlSlug] = useState(currentSlug);
	const [open, setOpen] = useState(false);
	const [error, setError] = useState("");

	const handleClose = () => {
		setOpen(false);
		setTimeout(() => setError(""), 200);
	};

	const handleSubmit = () => {
		updateRewards(
			{
				id: rewardId,
				params: {
					url_slug: urlSlug,
				},
			},
			{
				success: {
					callback: () => {
						handleClose();
					},
				},
				failure: {
					callback: (e) => {
						setError(e.err);
					},
				},
			}
		);
	};

	return (
		<LpModal
			className="admin url_slug_modal"
			triggerBtnText="Change"
			triggerBtnClassName="button button--secondary button--borderless"
			cancelBtnText="Cancel"
			submitBtnText="Save"
			form="editUrlSlug"
			open={open}
			submitDisabled={urlSlug === currentSlug}
			onTrigger={() => setOpen(true)}
			onOpen={() => setUrlSlug(currentSlug)}
			onClose={handleClose}
			onSubmit={handleSubmit}
		>
			<div>
				<FormFieldInput
					className={"url_slug_modal__input"}
					intro={
						"Set the url slug of this item. It can only contain lowercase letters, numbers, and hyphens. (url-slug-example-1)"
					}
					type={"text"}
					name={"urlSlug"}
					value={urlSlug}
					placeholder={"user-friendly-slug"}
					maxLength={"95"}
					onChange={(event) => setUrlSlug(event.target.value)}
				/>

				{error !== "" && <LpError error={error} />}
			</div>
		</LpModal>
	);
}

UrlSlugModal.propTypes = {
	currentSlug: PropTypes.string,
	rewardId: PropTypes.string.isRequired,
	updateRewards: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
	updateRewards: (payload, options) => dispatch(updateRewards(payload, options)),
});

export default connect(null, mapDispatchToProps)(UrlSlugModal);
