import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { FormFieldMultiInput } from '../../../../components/FormFields';
import { TRIGGER_TYPE } from "../../../../constants/Rewards";
import { updateRewards } from '../../../../actions/Rewards';
import "../styles.scss";

class TriggerDialog extends React.Component {
    constructor(props) {
        super(props);
    }   

    componentWillMount() {
        this.setState({
            trigger_methods: this.props.trigger_methods
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            trigger_methods: nextProps.trigger_methods,
        })
    }

    handleSubmit = () => {
        this.props.updateRewards({
            id: this.props._id,
            params: {
                trigger_methods: this.state.trigger_methods
            }
        });
    };

    handleClose() {
        this.setState({
            trigger_methods: this.props.trigger_methods
        });
    }
    
    checkValidation() {
        return (
            this.state.trigger_methods == this.props.trigger_methods
        )
    }

    handleTriggersChange(event) {        
        this.setState({ trigger_methods: event.target.value });
    }

    render() {        
        return (
            <Modal
                className="admin"
                triggerBtnText="Change"
                triggerBtnClassName="button button--secondary button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Save"
                submitDisabled={null}
                onSubmit={() => this.handleSubmit()}
                onClose={() => this.handleClose()}
            >
                <FormFieldMultiInput
                    label={"Trigger"}
                    name={"trigger_method"}
                    values={this.state.trigger_methods}
                    options={TRIGGER_TYPE}
                    onChange={(event) => this.handleTriggersChange(event)}
                /> 
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateRewards: (payload) => dispatch(updateRewards(payload))
    }
}

const mapStateToProps = state => {
    return {

    }
}

TriggerDialog = connect(mapStateToProps, mapDispatchToProps)(TriggerDialog);

export default TriggerDialog