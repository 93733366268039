import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import TablePagination from "@material-ui/core/TablePagination";
import LpHeading from "../../../components/LpHeading";
import { RangeDatePicker } from "../../../components/DatePickers";
import { FormFieldInput } from "../../../components/FormFields";
import { COLLECTIONS } from "../../../constants/PaginationData";
import { fetchPointsHistory } from "../../../actions/UserAdminPanel";

import "./styles.scss";

class Points extends Component {
	constructor(props) {
		super(props);

		this.state = {
			rowsPerPageOptions: COLLECTIONS.ROW_PER_PAGE_OPTIONS,
			page: COLLECTIONS.page,
			limit: COLLECTIONS.defaultRowPerPage,
			dateStart: new Date() - 1000 * 60 * 60 * 24 * 30,
			dateEnd: new Date(),
		};
	}

	componentDidMount() {
		this.getPointsHistory();
	}

	getPointsHistory = () => {
		const { page, limit, searchQuery, dateEnd, dateStart } = this.state;

		this.props.fetchPointsHistory({
			page: page + 1,
			limit: limit,
			search: searchQuery,
			dateRange: {
				to: dateEnd,
				from: dateStart,
			},
		});
	};

	handleRangeDatePickerChange = (dates) => {
		this.setState(
			{
				dateStart: new Date(dates.dateStart),
				dateEnd: new Date(dates.dateEnd),
			},
			this.getPointsHistory
		);
	};

	handleChangePage = (event, newPage) => {
		if (this.state.page !== newPage) {
			this.setState(
				{
					page: newPage,
				},
				this.getPointsHistory
			);
		}
	};

	handleChangeRowsPerPage = (event) => {
		if (event.target.value) {
			this.setState(
				{
					page: 0,
					limit: event.target.value,
				},
				this.getPointsHistory
			);
		}
	};

	getReason(created_by, reason) {
		if (created_by) {
			return `${created_by}: ${reason}`;
		} else {
			return `${reason}`;
		}
	}

	getSpendingPointsInfo(total_spending, points_added, points_removed) {
		if (points_added) {
			return `${total_spending} (+ ${points_added})`;
		} else {
			return `${total_spending} (- ${points_removed})`;
		}
	}

	getMembershipPointsInfo(total_membership, points_added, points_removed) {
		if (points_added) {
			return `${total_membership} (+ ${points_added})`;
		} else {
			return `${total_membership} (- ${points_removed})`;
		}
	}

	tablePagination() {
		const { count } = this.props;
		const { limit, rowsPerPageOptions, page } = this.state;

		if (count > limit)
			return (
				<TablePagination
					className="float-right"
					component="div"
					count={count}
					rowsPerPage={limit}
					rowsPerPageOptions={rowsPerPageOptions}
					page={page}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
					classes={{
						select: "pagination-select",
					}}
				/>
			);
	}

	render() {
		const { dateStart, dateEnd } = this.state;

		return (
			<div className="container m-3 p-3 logs logs--points admin">
				<div>
					<LpHeading>Points Log</LpHeading>
				</div>
				<div className="d-flex">
					<FormFieldInput
						className="col-4 p-0"
						label="Search by User, or Staff Member"
						placeholder="Search"
						onChange={(event) =>
							this.setState({
								searchQuery: event.target.value,
							})
						}
						onKeyPress={(event) => {
							if (event.key === "Enter") {
								this.getPointsHistory();
							}
						}}
					/>
					<div className="col-4 dates-div">
						<label htmlFor="name" className="form-field__label">
							Dates
						</label>
						<RangeDatePicker
							dateStart={dateStart}
							dateEnd={dateEnd}
							onChange={this.handleRangeDatePickerChange}
						/>
					</div>
					<div className="col-4 pt-2">{this.tablePagination()}</div>
				</div>
				<table className="data-table">
					<thead>
						<tr>
							<td className="data-table__header" width="20%">
								Date
							</td>
							<td className="data-table__header" width="25%">
								Username
							</td>
							<td
								className="data-table__header align-right"
								width="20%"
							>
								Spending
							</td>
							<td
								className="data-table__header align-right"
								width="20%"
							>
								Membership
							</td>
						</tr>
					</thead>
					<tbody>
						{this.props.points_history.map((item) => {
							return (
								<React.Fragment key={item.id}>
									<tr className="points-row">
										<td
											className="data-table__data-cell"
											width="20%"
										>
											{`${moment(item.createdAt).format(
												"YYYY.MM.DD"
											)} | ${moment(
												item.createdAt
											).format("HH:mm:ss")}`}
										</td>
										<td
											className="data-table__data-cell"
											width="25%"
										>
											{item.username}
										</td>
										<td
											className="data-table__data-cell align-right"
											width="20%"
										>
											{item.is_spending
												? this.getSpendingPointsInfo(
														item.total_spending,
														item.points_added,
														item.points_removed
												  )
												: ""}
										</td>
										<td
											className="data-table__data-cell align-right"
											width="20%"
										>
											{item.is_membership
												? this.getMembershipPointsInfo(
														item.total_membership,
														item.points_added,
														item.points_removed
												  )
												: ""}
										</td>
									</tr>
									<tr>
										<td
											className="data-table__data-cell reason"
											colSpan="4"
										>
											{this.getReason(
												item.created_by,
												item.reason
											)}
										</td>
									</tr>
								</React.Fragment>
							);
						})}
					</tbody>
				</table>

				<div>{this.tablePagination()}</div>
			</div>
		);
	}
}

Points.propTypes = {
	count: PropTypes.number,
	points_history: PropTypes.arrayOf(PropTypes.object),
	fetchPointsHistory: PropTypes.func.isRequired,
};

Points.defaultProps = {
	points_history: [],
};

const mapStateToProps = (state) => {
	return {
		points_history: state.user_admin.points_history,
		count: state.user_admin.points_history_count,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchPointsHistory: (params) => dispatch(fetchPointsHistory(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Points);
