import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Editor, Node } from "slate";

// Components
import Modal from "../../../../../Modal/Modal";
import { FormFieldInput } from "../../../../../FormFields/FormFields";
import CheckBox from "../../../../../CheckBox/CheckBox";
import AddLinkButton from "../AddLinkButton";

function AddLinkDialog({ editor, updateLink }) {
	let linkText = "";
	const linkUrl = "";
	const linkExternal = true;

	if (editor.selection) {
		let fragment = Editor.fragment(editor, editor.selection)[0];
		linkText = Node.string(fragment);
	}

	const [stateLinkText, setLinkText] = useState("");
	const [stateLinkUrl, setLinkUrl] = useState("");
	const [stateLinkExternal, setLinkExternal] = useState("");

	useEffect(() => {
		setLinkText(linkText);
	}, [linkText]);
	useEffect(() => {
		setLinkUrl(linkUrl);
	}, [linkUrl, editor.selection]);
	useEffect(() => {
		setLinkExternal(linkExternal);
	}, [linkExternal, editor.selection]);

	function clearFields() {
		setLinkText("");
		setLinkUrl("");
		setLinkExternal(false);
	}

	return (
		<Modal
			className="admin"
			triggerBtn={AddLinkButton}
			cancelBtnText="Close"
			submitBtnText="Apply"
			onSubmit={() => {
				updateLink({ stateLinkText, stateLinkUrl, stateLinkExternal });
				clearFields();
			}}
		>
			<FormFieldInput
				label="Link text"
				value={stateLinkText}
				onChange={(event) => setLinkText(event.target.value)}
			/>
			<FormFieldInput
				label="Link URL"
				value={stateLinkUrl}
				onChange={(event) => setLinkUrl(event.target.value)}
			/>
			<CheckBox
				id={"link_external"}
				label={"Open in New Window"}
				value={stateLinkExternal}
				onChange={(event) => setLinkExternal(event.target.checked)}
				checked={stateLinkExternal === true}
			/>
		</Modal>
	);
}

AddLinkDialog.propTypes = {
	editor: PropTypes.object,
	linkText: PropTypes.string,
	linkUrl: PropTypes.string,
	linkExternal: PropTypes.bool,
	updateLink: PropTypes.func.isRequired,
};

export default AddLinkDialog;
