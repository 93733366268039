import React, { Component } from "react";
import propTypes, { array } from "prop-types";
import { connect } from "react-redux";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TwitterDeleteDialog from "./TwitterDialogs/TwitterDeleteDialog";
// import TwitterEditDialog from "./TwitterDialogs/TwitterEditDialog";

import { deleteTwitterServer } from "../../../../actions/Channel";

import "./styles.scss";

class TwitterChannel extends Component {
	deleteChannel = (params) => {
		this.props.deleteTwitterServer(
			{
				...params,
			},
			{
				success: {
					notification: "Deleted Twitter channel.",
					fetchTwitterData: true,
				},
				failure: {
					notification: "Failed to delete Twitter channel.",
				},
			}
		);
	};

	render() {
		const { typePrefixes, item } = this.props;
		return (
			<TableRow key={item._id} className="twitter-container__item">
				<TableCell className="twitter-container__value">
					<div className="user-profile d-flex flex-row align-items-center">
						<div className="user-detail">
							<span>{`${typePrefixes[item.type]}${
								item.identifier
							}`}</span>
						</div>
					</div>
				</TableCell>
				<TableCell className="twitter-container__value"></TableCell>
				<TableCell className="twitter-container__value">
					{/* <TwitterEditDialog
						esport_entities={[]}
						identifier={item.identifier}
						type={item.type}
						handleSubmit={() => console.log(item)}
					/> */}
					<TwitterDeleteDialog
						identifier={item.identifier}
						type={item.type}
						deleteChannel={this.deleteChannel}
					/>
				</TableCell>
			</TableRow>
		);
	}
}

const mapDispatchToProps = {
	deleteTwitterServer,
};

TwitterDeleteDialog.propTypes = {
	deleteTwitterServer: propTypes.func.isRequired,
	typePrefixes: propTypes.shape({
		hashtag: propTypes.string.isRequired,
		account: propTypes.string.isRequired,
	}),
	item: propTypes.object.isRequired,
};

export default connect(null, mapDispatchToProps)(TwitterChannel);
