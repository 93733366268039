import React from "react";
import { connect } from "react-redux";
import Modal from "../../../../components/Modal";
import { FormFieldInput } from "../../../../components/FormFields";
import { updateUserMembership } from "../../../../actions/UserUpdate";

class MembershipDialog extends React.Component {
	constructor(props) {
		super(props);
	}

	componentWillMount() {
		this.setState({
			custom_tier_name: this.props.custom_tier_name,
		});
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			custom_tier_name: nextProps.custom_tier_name,
		});
	}

	handleSubmit = () => {
		this.props.updateUserMembership(
			{
				user: this.props.userId,
				custom_tier_name: this.state.custom_tier_name,
			},
			{
				success: {
					notification: "Successfully updated user membership.",
					fetchUserProfileForAdminUi: true,
				},
				failure: {
					notification: true,
				},
			}
		);
	};

	handleClose() {
		this.setState({
			custom_tier_name: this.props.custom_tier_name,
		});
	}

	checkValidation() {
		return this.state.custom_tier_name === this.props.custom_tier_name;
	}

	render() {
		return (
			<Modal
				className="admin"
				triggerBtnText="Change"
				triggerBtnClassName="button"
				cancelBtnText="Cancel"
				submitBtnText="Save"
				submitDisabled={this.checkValidation()}
				onSubmit={() => this.handleSubmit()}
				onClose={() => this.handleClose()}
			>
				<FormFieldInput
					intro={
						<React.Fragment>
							<p>
								Enter a custom membership title. Leaving this
								blank will use the default value.
							</p>
							<p>
								Note: This feature should only be used in very
								rare circumstances.
							</p>
						</React.Fragment>
					}
					label={"Membership Title"}
					type={"text"}
					name={"custom_tier_name"}
					value={this.state.custom_tier_name}
					onChange={(event) =>
						this.setState({ custom_tier_name: event.target.value })
					}
				/>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateUserMembership: (params, options) =>
			dispatch(updateUserMembership(params, options)),
	};
};

const mapStateToProps = (state) => {
	return {};
};

MembershipDialog = connect(
	mapStateToProps,
	mapDispatchToProps
)(MembershipDialog);

export default MembershipDialog;
