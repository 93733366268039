import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";

/* Example Usage:
<RadioField
    id="enhanced-background-yes"
    name="enhanced_background"
    label="Yes"
    onChange={(e) => this.setState({ parallax: true })}
    checked={this.state.parallax}
/>

<RadioField
    id="enhanced-background-no"
    name="enhanced_background"
    label="No"
    onChange={(e) => this.setState({ parallax: false })}
    checked={!this.state.parallax}
/>
*/

function RadioField(props) {
	return (
		<div className="RadioField">
			<input {...props} type="radio" className="RadioField__input" />
			<label htmlFor={props.id} className="RadioField__label">
				<span className="RadioField__radio"></span>
				{props.label}
			</label>
		</div>
	);
}

RadioField.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
};

export default RadioField;
