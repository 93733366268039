import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { GAME_ENTITIE_TYPE } from "../../constants/EsportsEntities";
import { fetchGames } from "../../actions/Games";
import DatalistInput from "../FormFields/DatalistInput";

class GameEntitiesInput extends Component {
	componentDidMount() {
		this.props.fetchGames({
			is_active: 1
		})
	}

	mapGamesToEsportEntities() {
		return this.props.games.map((game) => ({
			label: game.display_name,
			value: {
				name: game.name,
				type: GAME_ENTITIE_TYPE,
			},
		}));
	}

	findGameEntity() {
		return this.props.entities.find(
			(entity) => entity.type === GAME_ENTITIE_TYPE
		);
	}

	onChange = (event) => {
		this.props.onChange(event.target.value);
	};

	render() {
		const game_entities = this.mapGamesToEsportEntities();
		const selected_game_entity = this.findGameEntity();

		return (
			<DatalistInput
				label={this.props.label}
				intro={this.props.intro}
				value={selected_game_entity}
				options={game_entities}
				onChange={this.onChange}
			/>
		);
	}
}

GameEntitiesInput.propTypes = {
	games: PropTypes.array.isRequired,
	entities: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	fetchGames: PropTypes.func.isRequired,
	label: PropTypes.string,
	intro: PropTypes.string,
};

const mapStateToProps = (state) => ({
	games: state.global.games,
});

const mapDispatchToProps = {
	fetchGames,
};

export default connect(mapStateToProps, mapDispatchToProps)(GameEntitiesInput);
