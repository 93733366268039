import {
    takeLatest,
    call,
    put,
} from "redux-saga/effects";
import {
    push,
} from "react-router-redux";
import {
	UPDATE_USER_BIRTHDAY,
} from "../../../constants/ActionTypes";
import {
	USER_BIRTHDAY_URL,
} from "../../../constants/Url";
import {
	SUCCESS,
	ERROR,
	USER_VERIFICATION
} from "../../../constants/Notifications";
import {
	appendNotifications,
} from "../../../actions/Notifications";
import {
	addMessage,
} from "../../../actions/Messages";
import {
	setUserProfileParam,
} from "../../../actions/User";
import {
    fetchUserAccountInfo,
} from "../../../actions/UserAdminPanel";

import api from '../../../util/api'

export function* updateUserBirthdayWatcher() {
	yield takeLatest(UPDATE_USER_BIRTHDAY, updateUserBirthdayWorker);
};

/**
 * @param {String} action.params.user The ID of the user to update.
 * @param {Boolean | Number} action.params.birthday The value to update the user's ```birthday``` to.
 *
 * @param {Object} [action.options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [action.options.success] Behavior to execute when API call and action is successful.
 * @param {String} [action.options.success.redirect] Redirect to this URL on success.
 * @param {String | Boolean} [action.options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [action.options.success.notification] Display notification with passed text on success. If ```message``` is ```true```, push API response message instead of passed string.
 * @param {Function} [action.options.success.callback] Callback function on success.
 * @param {*} [action.options.success.callbackArgs] Callback function args on success.
 * @param {Boolean} [action.options.success.updateUserProfile] Whether to update the user's profile in the Redux state.
 * @param {Boolean} [action.options.success.fetchUserProfileForAdminUi] Whether to update the user's profile in the Redux state.
 *
 * @param {Object} [action.options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [action.options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [action.options.failure.message] Push message with passed text to ```state.messages.bank``` on failure. If ```message``` is ```true```, push API response message instead of passed string.
 * @param {String} [action.options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [action.options.failure.callback] Callback function on failure.
 * @param {*} [action.options.failure.callbackArgs] Callback function args on failure.
 */
function* updateUserBirthdayWorker(action) {
	try {
		const response = yield call(updateUserBirthdayApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;
		if (response.status === 451) {
			window.browserStorage.removeJWT();
			window.browserStorage.remove('refresh_token');
			window.browserStorage.removePermission();
            yield put(appendNotifications(USER_VERIFICATION(ERROR, response.data.e)));
			yield put(push("/not-eligible"));
			return;
		}
		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (isSuccess && options?.updateUserProfile) {
			yield put(setUserProfileParam("userInfo.birthday", response?.data?.data?.birthday));
		};
		if (isSuccess && options?.fetchUserProfileForAdminUi) {
			yield put(fetchUserAccountInfo(action.params.user));
		};
		if (options?.notification) {
			yield put(appendNotifications({
				type: isSuccess ? SUCCESS : ERROR,
				message: typeof options.notification === "string" ? options.notification : isSuccess ? "Profile updated." : response?.data?.error,
			}));
		};
		if (options?.message) {
			yield put(addMessage({
				source: UPDATE_USER_BIRTHDAY,
				message: typeof options.message === "string" ? options.message : response?.data?.data?.birthday || response?.data?.error,
				isError: !isSuccess,
			}));
		};
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		};
		if (options?.redirect) {
			yield put(push(options.redirect));
		};
    } catch (e) {
        console.error(e);
    };
};

/**
 * Sends an API request to update a user's ```birthday``` property.
 * @param {String} params.user The ID of the user to update.
 * @param {String} params.birthday The value to update ```birthday``` to.
 * @return {Object} The response object from the API call.
 */
function updateUserBirthdayApi(params) {
    return api
        .put(USER_BIRTHDAY_URL(params.user), {
			birthday: params.birthday,
		})
        .then(response => {
			return response
		}).catch(error => {
			return error.response;
		});
};