import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import { CREATE_QUESTS_WATCHER } from "../../constants/ActionTypes";
import { CREATE_QUESTS_URL } from "../../constants/Url";
import { QUEST_CREATE, ERROR, SUCCESS } from "../../constants/Notifications";
import { appendNotifications } from "../../actions/Notifications";

import api from "../../util/api";

export function* createQuestsWatcher() {
	yield takeLatest(CREATE_QUESTS_WATCHER, createQuestsWorker);
}

function* createQuestsWorker(action) {
	try {
		const response = yield call(createQuestsApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;

		if (isSuccess) {
			const options = action?.options?.success;
			if (options?.notification) {
				yield put(appendNotifications(QUEST_CREATE(SUCCESS, action.payload.title)));
			}
			if (options?.callback) {
				yield call(options.callback, response);
			}
			yield put(push("/app/servicesettings/quests"));
		} else {
			throw response;
		}
	} catch (e) {
		const options = action?.options?.failure;
		yield put(
			appendNotifications(QUEST_CREATE(ERROR, action.payload.title, e.response.data.error))
		);
		if (options?.callback) {
			yield call(options.callback, e.response);
		}
		console.error(e);
	}
}

function createQuestsApi(payload) {
	return api.post(CREATE_QUESTS_URL, payload);
}
