import {
    SET_ADMIN_REWARD_TAB,
    SET_POINTS_PROCESSING_EVENTS,
} from "../constants/ActionTypes";

const INIT_STATE = {
    service_settings: {
        rewards: {
            open: 0
        },
        points_processing: {
            events: []
        }
    }
};

export default function adminPanelReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case SET_ADMIN_REWARD_TAB: {
            return {
                ...state,
                service_settings: {
                    ...state.service_settings,
                    rewards: {
                        ...state.service_settings.rewards,
                        open: action.payload
                    }
                }
            }
        }
        case SET_POINTS_PROCESSING_EVENTS: {
            return {
                ...state,
                service_settings: {
                    ...state.service_settings,
                    points_processing: {
                        ...state.service_settings.points_processing,
                        events: action.payload
                    }
                }
            }
        }

        default:
            return state;
    }


}