import { showLoader, hideLoader } from "../../actions/GlobalAction";
import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import { VERIFY_USER } from "../../constants/ActionTypes";
import { VERIFICATION_URL } from "../../constants/Url";
import { appendNotifications } from "../../actions/Notifications";
import { USER_VERIFICATION, ERROR, WARNING } from "../../constants/Notifications";
import {
	EMAIL_VERIFICATION_FAILED,
	EMAIL_VERIFICATION_SUCCESS,
	EMAIL_VERIFICATION_TOKEN_INVALID,
} from "../../constants/RoutesURLs";
import api from "../../util/api";

function verifyUserApi(token) {
	return api
		.post(VERIFICATION_URL, {
			token: token,
		})
		.catch((err) => {
			console.error(err);
			return err;
		});
}

export function* verifyUserWatcher() {
	yield takeLatest(VERIFY_USER, verifyUserWorker);
}

function* verifyUserWorker(action) {
	let response;
	try {
		yield put(showLoader());
		response = yield call(verifyUserApi, action.payload);
		if (response.status >= 200 && response.status < 300) {
			yield put(push(EMAIL_VERIFICATION_SUCCESS));
		} else if (response.response.status === 401) {
			const { email } = response.response.data;
			yield put(push(`${EMAIL_VERIFICATION_FAILED}?email=${email}`));
		} else if (response.response.status === 403) {
			yield put(push(EMAIL_VERIFICATION_TOKEN_INVALID));
		} else {
			let type = null;
			let text = null;
			switch (response.response.status) {
				case 400: {
					type = ERROR;
					text = response.response.data.msg;
					break;
				}
				case 451: {
					type = ERROR;
					text = response.response.data.msg;
					break;
				}
				default: {
					type = WARNING;
					text = "Could not verify user";
					break;
				}
			}
			yield put(appendNotifications(USER_VERIFICATION(type, text)));
			throw response;
		}
		yield put(hideLoader());
	} catch (e) {
		yield put(hideLoader());
		if (response) {
			if (response.response.status === 451) {
				window.browserStorage.removeJWT();
				window.browserStorage.remove("refresh_token");
				window.browserStorage.removePermission();
				yield put(push("/not-eligible"));
			} else {
				yield put(push("/"));
			}
		}
	}
}
