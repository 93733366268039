export const ABOUT = "/about";
export const ACCOUNTS = "/accounts";
export const CONTACT = "/contact";
export const ENTITIES = "/entities";
export const EXPERIENCES = "/experiences";
export const FORGOT_PASSWORD = "/forgot-password";
export const GAMES = "/games";
export const TEAM_LIQUID_DISCORD = "https://discord.com/invite/teamliquid";
export const NEWS = "/news";
export const PARTNERS = "/partners";
export const PLAYERS = "/players";
export const PROFILE = "/profile";
export const ACCOUNT_SETUP = "/account-setup";
export const QUESTS = "/experiences/quests";
export const REGISTRATION_CLOSED = "/registration-closed";
export const RESET_PASSWORD = "/forgot-password/reset";
export const RESET_PASSWORD_SUCCESSFUL = "/forgot-password/reset-successful";
export const SCHEDULE = "/schedule";
export const SHOP = "/shop";
export const STATUS = "/status";
export const SUBMIT_A_BUG = "https://forms.gle/buk6hQV5qRhQmnPt9";
export const USERS = "/users";
export const WELCOME = "/welcome";

// Account Settings
export const ACCOUNT_SETTINGS = "/account-settings";
export const CONNECTED_PLATFORMS = `${ACCOUNT_SETTINGS}/connected-platforms`;
export const COMPLETED_QUESTS = `${ACCOUNT_SETTINGS}/complete-quests`;
export const ACHIEVEMENTS = `${ACCOUNT_SETTINGS}/achievements`;
export const REWARD = `${ACCOUNT_SETTINGS}/reward`;
export const REWARDS = `${ACCOUNT_SETTINGS}/rewards`;
export const RECENT_POINTS = `${ACCOUNT_SETTINGS}/recent-points`;
export const PREFERRED_GAMES = `${ACCOUNT_SETTINGS}/preferred-games`;
export const PREFERRED_PLAYERS = `${ACCOUNT_SETTINGS}/preferred-players`;
export const ACCOUNT_INFORMATION = `${ACCOUNT_SETTINGS}/account-information`;
export const ACCOUNT_LANGUAGE_MODAL = `${ACCOUNT_SETTINGS}/language`;
export const ACCOUNT_PRIVACY_MODAL = `${ACCOUNT_SETTINGS}/privacy`;

// Auth
export const NOT_ELIGIBLE = "/not-eligible";
export const NOTICE_TO_PARENT_OR_GUARDIAN = "/notice-to-parent-or-guardian";
export const REGISTRATION_NOTIFY = "/will-be-notified";
export const SIGNIN = "/sign-in";
export const SIGNUP = "/sign-up";
export const SIGNOUT = "/sign-out";

// Errors
export const ERROR403 = "/error-403";
export const ERROR404 = "/error-404";
export const ERROR500 = "/error-500";

// Policies
export const COOKIE_POLICY = "/policies/cookie-policy";
export const POLICIES_OVERVIEW = "/policies";
export const PRIVACY_POLICY = "/policies/privacy-policy";
export const TERMS_OF_SERVICE = "/policies/terms-of-service";

// CMS
export const ADMIN = "/app";
export const ADMIN_EXPERIENCES = "/app/servicesettings/experiences/";
export const ADMIN_EXPERIENCES_EXPERIENCE = "/app/servicesettings/experiences/:id";
export const ADMIN_EXPERIENCES_EXPERIENCE_TRANSLATIONS =
	"/app/servicesettings/experiences/:id/translations";
export const ADMIN_EXPERIENCES_NEW = "/app/servicesettings/experiences/create/";
export const ADMIN_QUESTS = "/app/servicesettings/quests/";
export const ADMIN_QUESTS_NEW = "/app/servicesettings/quests/create/";
export const ADMIN_QUESTS_QUEST = "/app/servicesettings/quests/:id";
export const ADMIN_QUESTS_QUEST_TRANSLATIONS = "/app/servicesettings/quests/:id/translations";

// Verification
export const EMAIL_VERIFICATION = "/verification";
export const EMAIL_VERIFICATION_FAILED = "/verification/failed";
export const EMAIL_VERIFICATION_SUCCESS = "/verification/success";
export const EMAIL_VERIFICATION_RESEND = "/verification/resend";
export const EMAIL_VERIFICATION_TOKEN_INVALID = "/verification/token-invalid";

// Team Liquid app URLS
export const APP_STORE = " https://apps.apple.com/us/app/liquid/id1497695012";
export const PLAY_STORE = "https://play.google.com/store/apps/details?id=com.liquidplus.beta";

// Social links
export const TL_ALIENWARE = "https://www.dell.com/en-us/gaming/alienware";
export const TL_FACEBOOK = "https://www.facebook.com/teamliquid";
export const TL_REDDIT = "https://www.reddit.com/r/teamliquid";
export const TL_TWITCH = "https://www.twitch.tv/team/teamliquid";
export const TL_TWITTER = "https://twitter.com/teamliquid";
export const TL_YOUTUBE = "https://www.youtube.com/channel/UCdtXkZSNEv1EW8UZJBAPYKQ";
