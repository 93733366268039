import { takeLatest, call, put } from "redux-saga/effects";

import { fetchRewards } from "../../actions/Rewards";

// Constants
import { UPDATE_REWARDS_WATCHER } from "../../constants/ActionTypes";
import { UPDATE_REWARDS_DATA_API } from "../../constants/Url";

import api from "../../util/api";

function updateRewardsApi(update) {
	return api
		.put(UPDATE_REWARDS_DATA_API + "/" + update.id, {
			params: {
				method: "update_reward",
				update_params: update.params,
			},
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}

function* updateRewardsWorker(action) {
	try {
		const response = yield call(updateRewardsApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (isSuccess) {
			yield put(fetchRewards({ detailed: true }));
		}

		if (options?.callback) {
			yield call(options.callback, response.data);
		}
	} catch (e) {
		console.error(e);
	}
}

export function* updateRewardsWatcher() {
	yield takeLatest(UPDATE_REWARDS_WATCHER, updateRewardsWorker);
}
