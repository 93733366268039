import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "../styles/styles.scss";

const LpLoadedItem = ({ className, children, selected, onClick }) => {
	return (
		<div
			className={classnames(
				"lp_loaded_item",
				{ "lp_loaded_item--selected": selected },
				className
			)}
			onClick={onClick}
		>
			{children}
		</div>
	);
};

LpLoadedItem.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	selected: PropTypes.bool,
	onClick: PropTypes.func,
};

export default LpLoadedItem;
