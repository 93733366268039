import { setUserPreferredGames } from "../../actions/Games";

import { takeLatest, call, put } from "redux-saga/effects";

import { FETCH_USER_PREFERRED_GAMES_WATCHER } from "../../constants/ActionTypes";

import { FETCH_USER_PREFERRED_GAMES_URL } from "../../constants/Url";

import api from "../../util/api";

export function* fetchUserPreferredGamesWatcher() {
	yield takeLatest(
		FETCH_USER_PREFERRED_GAMES_WATCHER,
		fetchUserPreferredGamesWorker
	);
}

function* fetchUserPreferredGamesWorker(action) {
	try {
		let response = yield call(fetchUserPreferredGamesApi, action.payload);

		if (response.status >= 200 && response.status < 300) {
			yield put(setUserPreferredGames(response.data.data));
		} else {
			throw response;
		}
	} catch (e) {
		console.error(e);
	}
}

function fetchUserPreferredGamesApi(payload) {
	return api.get(FETCH_USER_PREFERRED_GAMES_URL);
}
