import { select, put } from "redux-saga/effects";

// Utils
import { getUserAge } from "./DateUtil";

// Actions
import { setEndPointCache } from "../actions/EndpointCache";

// Constants
import {
	SIGNIN,
	RESET_PASSWORD_SUCCESSFUL,
	NOTICE_TO_PARENT_OR_GUARDIAN,
	EMAIL_VERIFICATION,
	EMAIL_VERIFICATION_SUCCESS,
	PROFILE,
} from "../constants/RoutesURLs";

export const fetchUser = (state) => state.user;

export const fetchUserProfile = (state) => state.user.profile;

/* eslint-disable complexity */
export function redirectRoute(user) {
	const { account_verified, userInfo, parental_consent_provided } = user;
	const user_age = getUserAge(userInfo?.birthday);

	const { search, pathname } = window.location;
	const urlParams = new URLSearchParams(search);
	const redirectRoute = urlParams.get("redirectRoute");

	if (!account_verified) {
		return EMAIL_VERIFICATION;
	} else if (user_age >= 13 && user_age < 18 && !parental_consent_provided) {
		if (redirectRoute) return NOTICE_TO_PARENT_OR_GUARDIAN + "?redirectRoute=" + redirectRoute;
		return NOTICE_TO_PARENT_OR_GUARDIAN;
	} else if (redirectRoute) {
		return redirectRoute;
	} else if (
		pathname === "/" ||
		pathname === SIGNIN ||
		pathname === RESET_PASSWORD_SUCCESSFUL ||
		pathname === EMAIL_VERIFICATION_SUCCESS ||
		pathname === NOTICE_TO_PARENT_OR_GUARDIAN ||
		!user
	) {
		return PROFILE;
	}
}

export function* isThrottled(endpoint_url) {
	let cached_endpoints = yield select((state) => state.endpoint_cache.bank);
	return cached_endpoints[endpoint_url]?.timespan_timeout > new Date().getTime();
}

export function* setThrottledTimespan(endpoint_url, ms) {
	let cached_endpoints = yield select((state) => state.endpoint_cache.bank);
	cached_endpoints[endpoint_url] = {
		timespan_timeout: parseInt(new Date().getTime()) + parseInt(ms),
	};
	yield put(setEndPointCache(cached_endpoints));
}
