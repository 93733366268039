import {
    setRedditData
} from '../../../actions/Channel';

import {
    showLoader,
    hideLoader
} from '../../../actions/GlobalAction'

import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';

import {
    FETCH_REDDIT_CHANNEL_DATA,
} from '../../../constants/ActionTypes'
import {
    REDDIT_POSTS_URL,
    REDDIT_SUBREDDITS_URL,
} from '../../../constants/Url'


import api from '../../../util/api';

function fetchRedditPostsApi() {
    return (
        api.get(REDDIT_POSTS_URL)
    )
}
function fetchRedditSubredditsApi() {
    return (
        api.get(REDDIT_SUBREDDITS_URL)
    )
}

export function* fetchRedditDataWatcher() {
    yield takeLatest(FETCH_REDDIT_CHANNEL_DATA, fetchRedditDataWorker);
}

function* fetchRedditDataWorker() {
    try {
        // data is obtained after axios call is resolved
        let posts = yield call(fetchRedditPostsApi);
        let subreddits = yield call(fetchRedditSubredditsApi);
        if (posts.status >= 200 && posts.status < 300 && subreddits.status >= 200 && subreddits.status < 300) {
            yield put(setRedditData([...posts.data.data, ...subreddits.data.data]));
        } else {
            throw ({posts, subreddits});
        }
    } catch (e) {
        console.log(e)
    }
}
