import api from "../../util/api";
import { clearUserProfile } from "../../actions/User";
import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import { LOGOUT_USER_WATCHER } from "../../constants/ActionTypes";
import { USER_SERVICE_LOGOUT } from "../../constants/Url";
import { appendNotifications } from "../../actions/Notifications";
import { clearEndPointCache } from "../../actions/EndpointCache";
import { clearPointHistories } from "../../actions/PointHistories";
import { USER_LOGOUT, ERROR, SUCCESS } from "../../constants/Notifications";

async function userLogoutApi() {
	const refreshToken = window.browserStorage.get("refresh_token");
	return api.post(USER_SERVICE_LOGOUT, {}, { headers: { "Refresh-Token": refreshToken } });
}

export function* userLogoutWatcher() {
	yield takeLatest(LOGOUT_USER_WATCHER, userLogoutWorker);
}

function* userLogoutWorker(action) {
	try {
		const response = yield call(userLogoutApi, action.payload);
		if (response.status >= 200 && response.status < 300) {
			if (!action?.params?.disable_notification) {
				yield put(appendNotifications(USER_LOGOUT(SUCCESS)));
			}
		} else {
			throw response;
		}

		window.browserStorage.removeJWT();
		window.browserStorage.remove("refresh_token");
		window.browserStorage.removePermission();
		if (!action?.params?.disable_clear_profile) {
			yield put(clearUserProfile());
			yield put(clearPointHistories());
			yield put(clearEndPointCache());
		}

		if (!action?.params?.disable_redirect) {
			window.location.assign("/");
		}
	} catch (e) {
		console.error(e);
		yield put(appendNotifications(USER_LOGOUT(ERROR)));
	}
}
