import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "../styles.scss";

// Components
import Modal from "../../../../components/Modal";
import { FormFieldInput } from "../../../../components/FormFields";
import LpError from "../../../../components/LpError";

// Actions
import { updateRewards } from "../../../../actions/Rewards";

class RewardSummaryDialog extends Component {
	state = {
		name: "",
		point_count: "",
		cover_image_url: "",
		new_cover_image_url: null,
		points_count_error: "",
	};

	componentWillMount() {
		const { name, point_count, cover_image_url } = this.props;
		this.setState({
			name,
			point_count,
			cover_image_url,
		});
	}

	componentWillReceiveProps(nextProps) {
		const { name, point_count, cover_image_url } = nextProps;
		this.setState({
			name,
			point_count,
			cover_image_url,
		});
	}

	handleSubmit = () => {
		const { _id, updateRewards } = this.props;
		const { name, point_count, cover_image_url } = this.state;
		updateRewards({
			id: _id,
			params: {
				name,
				point_count,
				images: {
					all: [cover_image_url],
					cover: cover_image_url,
				},
			},
		});
	};

	handleClose() {
		const { name, point_count, cover_image_url } = this.props;
		this.setState({
			name,
			point_count,
			cover_image_url,
			points_count_error: "",
		});
	}

	handleNameChange(event) {
		this.setState({ name: event.target.value });
	}

	handlePointCountChange(event) {
		const pointCount = event.target.value;

		if (!pointCount || pointCount <= 0 || !Number.isInteger(Number(pointCount))) {
			this.setState({
				points_count_error: "'Points Value' must be a positive integer",
				point_count: pointCount,
			});
		} else {
			this.setState({ points_count_error: null, point_count: pointCount });
		}
	}

	handleCoverImageUrlChange(event) {
		this.setState({ new_cover_image_url: event.target.value });
	}

	handleImageSubmit() {
		const { new_cover_image_url } = this.state;
		this.setState({
			cover_image_url: new_cover_image_url,
			new_cover_image_url: null,
		});
	}

	render() {
		const { name, point_count, cover_image_url, new_cover_image_url, points_count_error } =
			this.state;
		return (
			<Modal
				className="admin reward_summary_dialog"
				triggerBtnText="Change"
				triggerBtnClassName="button button--borderless"
				cancelBtnText="Cancel"
				submitBtnText="Save"
				submitDisabled={
					!point_count ||
					point_count <= 0 ||
					isNaN(point_count) ||
					!Number.isInteger(Number(point_count))
				}
				onSubmit={() => this.handleSubmit()}
				onClose={() => this.handleClose()}
			>
				<div className="form-field form-field--input-image">
					<figure className="icon-placeholder">
						{new_cover_image_url ? (
							<img src={new_cover_image_url} alt="default-reward" />
						) : (
							<img src={cover_image_url} alt={`${name}-reward`} />
						)}

						<figcaption className="icon-placeholder__caption">Reward Icon</figcaption>

						<Modal
							className="admin"
							triggerBtnText="Change"
							triggerBtnClassName="button button--borderless"
							cancelBtnText="Cancel"
							submitBtnText="Save"
							onClose={() => this.setState({ new_cover_image_url: null })}
							onSubmit={() => this.handleImageSubmit()}
						>
							<FormFieldInput
								intro="Paste the url for the image that exists in the CDN."
								label="URL"
								type="text"
								name="new_cover_image_url"
								value={new_cover_image_url}
								placeholder="cdn.teamliquid.com/uri-that-matches"
								onChange={(event) => this.handleCoverImageUrlChange(event)}
							/>
						</Modal>
					</figure>
				</div>
				<FormFieldInput
					label="Name"
					type="text"
					name="name"
					value={name}
					placeholder="Name of the reward item"
					maxLength="95"
					onChange={(event) => this.handleNameChange(event)}
				/>
				<FormFieldInput
					label="Point Value"
					type="number"
					name="point_count"
					value={point_count}
					maxLength="95"
					onChange={(event) => this.handlePointCountChange(event)}
				/>
				{points_count_error && <LpError error={points_count_error} />}
			</Modal>
		);
	}
}

RewardSummaryDialog.propTypes = {
	_id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	point_count: PropTypes.number.isRequired,
	cover_image_url: PropTypes.string.isRequired,
	updateRewards: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
	updateRewards: (payload) => dispatch(updateRewards(payload)),
});

export default connect(null, mapDispatchToProps)(RewardSummaryDialog);
