import React, { Component } from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import LpHeading from "../../../components/LpHeading";

import OrderList from "./OrderList";
import { ORDER_TYPES } from "../../../constants/Rewards";

function TabContainer({ children }) {
	return <div>{children}</div>;
}

class Orders extends Component {
	constructor(props) {
		super(props);
		this.state = {
			component:
				localStorage.getItem("ordercomponent") !== null
					? parseInt(localStorage.getItem("ordercomponent"))
					: 0,
			orderState: localStorage.getItem("ordertab") || ORDER_TYPES.PENDING,
		};
	}

	handleChange = (event, component) => {
		localStorage.setItem("ordercomponent", component);
		this.setState({ component });
	};

	loadPendingTabContainer() {
		return (
			this.state.component === 0 && (
				<TabContainer>
					<OrderList orderState={this.state.orderState} />
				</TabContainer>
			)
		);
	}

	loadFulfilledTabContainer() {
		return (
			this.state.component === 1 && (
				<TabContainer>
					<OrderList orderState={this.state.orderState} />
				</TabContainer>
			)
		);
	}

	setTab(tabname) {
		localStorage.setItem("ordertab", tabname);
		this.setState({ orderState: tabname });
	}

	render() {
		return (
			<div className="orders admin">
				<LpHeading>Orders</LpHeading>
				<Tabs
					value={this.state.component}
					onChange={this.handleChange}
					classes={{
						selected: "orders__tab--selected",
						indicator: "orders__tabs-indicator",
					}}
				>
					<Tab
						classes={{
							labelContainer: "orders__tab-labelContainer",
							root: "orders__tab",
						}}
						label="Pending"
						onClick={(e) => this.setTab(ORDER_TYPES.PENDING)}
					/>
					<Tab
						classes={{
							labelContainer: "orders__tab-labelContainer",

							root: "orders__tab",
						}}
						label="Fulfilled"
						onClick={(e) => this.setTab(ORDER_TYPES.FULFILLED)}
					/>
				</Tabs>
				{this.loadPendingTabContainer()}
				{this.loadFulfilledTabContainer()}
			</div>
		);
	}
}

export default Orders;
