import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { setQuestsBank } from "../../actions/Quests";

// Util
import api from "../../util/api";
import { isThrottled, setThrottledTimespan } from "../../util/SagaUtils";

// Constants
import { FETCH_QUESTS_WATCHER } from "../../constants/ActionTypes";
import { FETCH_QUESTS_URL } from "../../constants/Url";
import { ENDPOINT_CACHING_TIMESPANS } from "../../constants/CachingTimespans";

export function* fetchQuestsWatcher() {
	yield takeLatest(FETCH_QUESTS_WATCHER, fetchQuestsWorker);
}

function* fetchQuestsWorker(action) {
	const endpointIsThrottled = yield isThrottled(FETCH_QUESTS_URL);
	const forceRequest = action?.options?.force;

	if (!endpointIsThrottled || forceRequest) {
		try {
			const response = yield call(fetchQuestsApi);
			const isSuccess = response.status >= 200 && response.status < 300;
			const options = isSuccess ? action?.options?.success : action?.options?.failure;

			if (isSuccess) {
				yield put(setQuestsBank(response.data.data));
				yield setThrottledTimespan(
					FETCH_QUESTS_URL,
					ENDPOINT_CACHING_TIMESPANS.FETCH_QUESTS
				);
				if (options?.callback) yield call(options.callback, response);
			} else {
				throw response;
			}
		} catch (e) {
			console.error(e);
		}
	}
}

function fetchQuestsApi() {
	return api.get(FETCH_QUESTS_URL);
}
