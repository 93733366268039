import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Components
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";

// Actions
import { fetchAllStaff } from "../../../actions/UserAdminPanel";

class Staff extends Component {
	componentDidMount() {
		const { fetchAllStaff } = this.props;
		fetchAllStaff();
	}

	render() {
		const { staff } = this.props;

		return (
			<div className="container m-3 p-3 admin">
				<div className="row mr-3">
					<table className="data-table">
						<thead>
							<tr>
								<th className="data-table__header">Staff Member</th>
								<th className="data-table__header">Role</th>
								<th className="data-table__header" />
							</tr>
						</thead>
						<tbody>
							{staff
								? staff.map((user) => (
										<tr key={user.id}>
											<td className="data-table__data-cell">
												<div className="d-flex flex-row align-items-center">
													{user.userInfo.avatar?.large_icon_url ? (
														<Avatar className="user-avatar">
															<img
																className="rounded-circle"
																id="staff-img"
																src={
																	user.userInfo.avatar
																		?.large_icon_url
																}
															/>
														</Avatar>
													) : (
														<div className="users-list__no-avatar" />
													)}
													<div className="user-detail d-flex flex-column">
														<span>{user.email}</span>
													</div>
												</div>
											</td>
											<td className="data-table__data-cell">
												{user.role.name}
											</td>
											<td className="data-table__data-cell">
												<Link to={`/app/accounts/users/${user.id}`}>
													<Button size="small" variant="text" mini={true}>
														<span>
															<i className="zmdi zmdi-chevron-right zmdi-hc-lg" />
														</span>
													</Button>
												</Link>
											</td>
										</tr>
									))
								: null}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

Staff.propTypes = {
	staff: PropTypes.arrayOf(PropTypes.shape({})),
	fetchAllStaff: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	staff: state.user_admin.staff,
});

const mapDispatchToProps = (dispatch) => ({
	fetchAllStaff: () => dispatch(fetchAllStaff()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Staff);
