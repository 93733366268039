import {
    setTiers
} from "../../actions/Tiers";

import {
    appendNotifications
} from "../../actions/Notifications";
import {
    USER_UPDATE,
    ERROR
} from "../../constants/Notifications";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import { // if you want to redirect post saga action
    push
} from "react-router-redux";
import {
    UPDATE_TIER_WATCHER
} from "../../constants/ActionTypes";
import {
    UPDATE_TIER_URL
} from "../../constants/Url";
import api from '../../util/api';

export function* updateTierWatcher() {
    yield takeLatest(UPDATE_TIER_WATCHER, updateTierWorker);
};

function* updateTierWorker(action) {
    try {
        let response = yield call(tierUpdateApi, action.payload);
        if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
            yield put(setTiers(response.data.data));
        } else {
            throw (response);
        }
    } catch (e) {
        yield put(appendNotifications(USER_UPDATE(ERROR)));
    };
};

function tierUpdateApi(payload) {
    const {
        id
    } = payload;
    let token = window.browserStorage.getJWT();
    return (
        api
        .put(UPDATE_TIER_URL(id), {
            ...payload.params
        }, {
            token
        }).catch(err => {
            return err.response.data
        })
    );
};