import { fetchUsernamesAndAvatars } from "../../actions/User";
import { setBucketQuestProgressionsTopUsersBank } from "../../actions/QuestProgressions";
import { takeLatest, call, put } from "redux-saga/effects";
import { FETCH_QUEST_PROGRESSION_BUCKET_TOP_USERS_WATCHER } from "../../constants/ActionTypes";
import { FETCH_QUEST_PROGRESSION_BUCKET_TOP_USERS_URL } from "../../constants/Url";

import api from "../../util/api";

export function* fetchQuestProgressionBucketTopUsersWatcher() {
	yield takeLatest(
		FETCH_QUEST_PROGRESSION_BUCKET_TOP_USERS_WATCHER,
		fetchQuestProgressionBucketTopUsersWorker
	);
}

function* fetchQuestProgressionBucketTopUsersWorker(action) {
	try {
		const response = yield call(fetchQuestProgressionBucketTopUsersApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (isSuccess) {
			yield put(setBucketQuestProgressionsTopUsersBank(response.data.data));
			yield put(
				fetchUsernamesAndAvatars({
					user_ids: response.data.data.map((topuser) => {
						return topuser.user_id;
					}),
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback);
		}
	} catch (e) {
		console.error(e);
	}
}

function fetchQuestProgressionBucketTopUsersApi(params) {
	return api
		.get(FETCH_QUEST_PROGRESSION_BUCKET_TOP_USERS_URL(params.quest, params.objective))
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
