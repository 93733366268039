import {
    showLoader,
    hideLoader
} from '../../actions/GlobalAction';

import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';
import {
    push
} from 'react-router-redux';
import {
    CREATE_ACHIEVEMENT_WATCHER
} from '../../constants/ActionTypes'
import {
    CREATE_ACHIEVEMENT_URL,
} from '../../constants/Url'

import api from '../../util/api';

function createAchievementApi(payload) {
    return (
        api
        .post(CREATE_ACHIEVEMENT_URL, {
            ...payload
        })
        .catch(err => {
            return err.response.data
        })
    )
}

export function* createAchievementWatcher() {
    yield takeLatest(CREATE_ACHIEVEMENT_WATCHER, createAchievementWorker);
}

function* createAchievementWorker(action) {
    try {
        /// data is obtained after axios call is resolved
        let response = yield call(createAchievementApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(push('/app/servicesettings/achievements'))
        }
        else {
            throw (response)
        }
    } catch (e) {
        console.log(e)
    }
}
