import React from "react";
import PropTypes from "prop-types";

import "./styles/index.scss";

const DropdownOption = ({ children, onClick }) => (
	<li className="dropdown_option" onClick={onClick}>
		{children}
	</li>
);

DropdownOption.propTypes = {
	children: PropTypes.node.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default DropdownOption;
