import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import "./styles.scss";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import FormFieldInput from "../../../components/FormFields/FormFieldInput";

import { fetchOrders, modifyOrder } from "../../../actions/Orders";
import { COLLECTIONS } from "../../../constants/PaginationData";
import { calculateTotalPoints } from "../../../util/order";
import { formatDateTime } from "../../../util/DateUtil";

class OrderList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			orders: [],
			searchField: "",
			page: COLLECTIONS.page,
			limit: COLLECTIONS.defaultRowPerPage,
			count: 0,
			sortAttribute: "createDate",
			sortDirection: "desc",
		};
	}

	componentWillMount() {
		this.props.fetchOrders(
			this.props.orderState,
			this.state.page + 1,
			this.state.limit,
			this.state.sortAttribute,
			this.state.sortDirection
		);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			orders: nextProps.orders,
			count: nextProps.orderCount,
		});
	}

	handleChange = (e) => {
		this.setState({
			searchField: e.target.value,
		});
	};

	updateOrder(id, type, arg, value) {
		this.props.modifyOrder(
			id,
			type,
			{
				[arg]: value,
			},
			this.props.orderState
		);
	}

	handleSearchChange(orders) {
		this.setState({
			orders,
		});
	}

	handleChangePage = (event, newPage) => {
		this.setState(
			{
				page: newPage,
			},
			() => {
				this.props.fetchOrders(
					this.props.orderState,
					this.state.page + 1,
					this.state.limit,
					this.state.sortAttribute,
					this.state.sortDirection
				);
			}
		);
	};

	tablePagination() {
		const { limit } = this.state;
		return (
			<TablePagination
				component="div"
				count={this.state.count}
				labelDisplayedRows={({ count, page }) =>
					`Page ${page + 1} of ${Math.ceil(count / limit)}`
				}
				rowsPerPage={limit}
				rowsPerPageOptions={[limit]}
				page={this.state.page}
				onChangePage={this.handleChangePage}
				classes={{
					root: "order-list__pagination--root",
					caption: "order-list__pagination--caption",
				}}
			/>
		);
	}

	// setOrderAdminMailed(order) {
	// 	return (
	// 		<button
	// 			title="Click to mark as 'Has been contacted'"
	// 			onClick={() =>
	// 				this.updateOrder(
	// 					order.id,
	// 					order.type,
	// 					"has_been_contacted",
	// 					!order.has_been_contacted
	// 				)
	// 			}
	// 			className={`badge ${
	// 				order.has_been_contacted ? "badge-success" : "bg-grey"
	// 			}`}
	// 		>
	// 			<i
	// 				style={{
	// 					cursor: "pointer",
	// 				}}
	// 				className="zmdi zmdi-email zmdi-hc-lg"
	// 			/>
	// 		</button>
	// 	);
	// }

	// setOrderFulfilled(order) {
	// 	return (
	// 		<button
	// 			title="Click to mark as 'Has been fulfilled'"
	// 			onClick={() =>
	// 				this.updateOrder(
	// 					order.id,
	// 					order.type,
	// 					"has_been_fulfilled",
	// 					!order.has_been_fulfilled
	// 				)
	// 			}
	// 			className={`badge ${
	// 				order.has_been_fulfilled ? "badge-success" : "bg-grey"
	// 			}`}
	// 		>
	// 			<i
	// 				style={{
	// 					cursor: "pointer",
	// 				}}
	// 				className="zmdi zmdi-check-circle zmdi-hc-lg"
	// 			/>
	// 		</button>
	// 	);
	// }

	loadTableBody() {
		const { orders } = this.state;
		if (orders?.length) {
			return orders.map((order) => {
				return (
					<tr key={order.id} className="data-table__row">
						<td className="data-table__data-cell">
							{order.id} <br />
							<span>
								{formatDateTime(order.createDate)}
								{/* {order.createDate} | {order.orderTime} */}
							</span>
						</td>
						<td className="data-table__data-cell">{order.username}</td>
						<td className="data-table__data-cell data-table__total-points">
							<span>{calculateTotalPoints(order)}</span>
						</td>
						{/* <td className="data-table__data-cell">
							{this.setOrderAdminMailed(order)}
							{this.setOrderFulfilled(order)}
						</td> */}
						<td className="data-table__data-cell data-table__details-button">
							<Link
								to={`/app/fulfillment/orders/${order.id}/${this.props.orderState}`}
							>
								<Button size="small" variant="text" mini={true}>
									<span>
										<i className="zmdi zmdi-chevron-right zmdi-hc-lg" />
									</span>
								</Button>
							</Link>
						</td>
					</tr>
				);
			});
		}
	}

	render() {
		return (
			<div className="order__list">
				<div className="order-list__table-actions">
					<FormFieldInput
						label="Search Orders by User, or Order Number"
						type="text"
						name="orders-input"
						value={this.state.searchField}
						wide={true}
						onChange={this.handleChange}
					/>
					{this.tablePagination()}
				</div>

				<table className="data-table">
					<thead>
						<tr>
							<th className="data-table__header">Order</th>
							<th className="data-table__header">Redeemed By</th>
							<th className="data-table__header data-table__total-points">
								Total Points
							</th>
							<th className="data-table__header" />
						</tr>
					</thead>
					<tbody>{this.loadTableBody()}</tbody>
				</table>
				<div>{this.tablePagination()}</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchOrders: (orderState, page, limit) =>
			dispatch(fetchOrders(orderState, page, limit, "createDate", "desc")),
		modifyOrder: (id, type, params, orderState) =>
			dispatch(modifyOrder(id, type, params, orderState)),
	};
};

const mapStateToProps = (state) => {
	return {
		orders: state.orders.bank,
		orderCount: state.orders.orderCount,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
