import React, { Component } from "react";
import { connect } from 'react-redux';
import QRCode from 'qrcode.react';
import { Link } from "react-router-dom";
import "./styles.scss";

class EventQrCode extends Component {   

    render() {
        return (
            <div className="EventQrCode">
                <div className="options-bar">
                    <Link className="options-back" to={`/app/events/`}>
                        <i className="zmdi zmdi-chevron-left zmdi-hc-lg" />
                    </Link>
                </div>
                <div className="qr">
                    <figure className="qr-code">
                        <QRCode
                            className="image"
                            value={this.props.event.qr_code ? this.props.event.qr_code : 0}
                            size={480}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                            renderAs={"svg"}
                        />
                    </figure>
                    <p className="qr-name">{this.props.event.name}</p>
                    <p className="qr-location">{this.props.event.location}</p>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.events.event
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventQrCode);
