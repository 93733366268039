import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./styles.scss";

// Custom Components
import LpStagingDialog from "../../../../../components/LpStagingDialog";
import LpLoadedItem from "../../../../../components/LpStagingDialog/atoms/LpLoadedItem";
import LpDragAndDropList from "../../../../../components/LpDragAndDropList";
import FaqForm from "../FaqForm";

// Actions
import { updateRewardFaqs } from "../../../../../actions/RewardFaqs";
import { appendNotifications } from "../../../../../actions/Notifications";
import { ERROR } from "../../../../../constants/Notifications";

const MAXFAQS = 5;
const NEW_FAQ = "new";

function FaqDialog({
	appendNotifications,
	updateRewardFaqs,
	onClose,
	rewardFaqs,
	reward_id,
	triggerButtonText,
	triggerButtonClassName,
}) {
	const defaultFaq = {
		question: "",
		answer: "",
	};

	const deepCopy = function (struct) {
		return JSON.parse(JSON.stringify(struct));
	};

	const [current_reward_faqs, setCurrentRewardFaqs] = useState(
		rewardFaqs.questions.map((q) => {
			q.loaded = Math.floor(Date.now() / 1000);
			return q;
		})
	);
	const [unsavedFaqs, setUnsavedFaqs] = useState(current_reward_faqs);

	const initial_selection = function () {
		if (unsavedFaqs !== undefined && unsavedFaqs.length === MAXFAQS) {
			return MAXFAQS - 1;
		}
		return NEW_FAQ;
	};

	const [selected, setSelected] = useState(initial_selection);
	const [newFaq, setNewFaq] = useState(deepCopy(defaultFaq));

	useEffect(() => {
		setUnsavedFaqs(rewardFaqs?.questions);
	}, [reward_id, rewardFaqs]);

	const faqHasUnsavedChanges = function (item) {
		const unsavedFaq = current_reward_faqs.find((faq) => faq._id === item._id);
		return !areFaqsEqual(item, unsavedFaq);
	};

	const findUnsavedFaq = function () {
		return unsavedFaqs.find((faq) => faq._id === current_reward_faqs[selected]?._id);
	};

	const getIndexOfUnsavedFaq = function () {
		return unsavedFaqs.indexOf(findUnsavedFaq());
	};

	const handleFaqEdit = function (value) {
		if (selected === NEW_FAQ) {
			setNewFaq({ ...newFaq, ...value });
		} else {
			let updatedFaqs = JSON.parse(JSON.stringify(unsavedFaqs));
			const unsavedIndex = getIndexOfUnsavedFaq();
			updatedFaqs[unsavedIndex] = {
				...updatedFaqs[unsavedIndex],
				...value,
			};
			setUnsavedFaqs(updatedFaqs);
		}
	};

	const handleFaqSave = function (faq) {
		if (faq.question === null || faq.question === "") {
			appendNotifications({
				type: ERROR,
				message: "Please provide a question.",
			});

			return;
		}

		if (faq.answer === null || faq.answer === "") {
			appendNotifications({
				type: ERROR,
				message: "Please provide an answer.",
			});

			return;
		}

		if (selected === NEW_FAQ && faq._id === undefined) {
			unsavedFaqs.push(faq);
			resetFaq();
		}

		const actionParams = {
			reward: reward_id,
			questions: unsavedFaqs,
		};

		updateRewardFaqs(actionParams, {
			success: {
				callback: (rewardFaqsResult) => {
					setCurrentRewardFaqs(rewardFaqsResult.questions);
					setUnsavedFaqs(rewardFaqsResult.questions);
				},
			},
			failure: {
				callback: () => null,
			},
		});
	};

	const handleFaqMove = function (newOrder) {
		const actionParams = {
			reward: reward_id,
			questions: newOrder,
		};

		updateRewardFaqs(actionParams, {
			success: {
				callback: (rewardFaqsResult) => {
					setCurrentRewardFaqs(rewardFaqsResult.questions);
				},
			},
			failure: {
				callback: () => null,
			},
		});
	};

	const areFaqsEqual = function (faqa, faqb) {
		if (faqa && faqb) {
			return faqa.question === faqb.question && faqa.answer === faqb.answer;
		}
		return false;
	};

	const resetFaq = function () {
		if (selected === NEW_FAQ) {
			let deepFaqCopy = deepCopy(defaultFaq);
			deepFaqCopy.key = Math.floor(Date.now() / 1000);
			setNewFaq(deepFaqCopy);
		} else {
			const unsavedIndex = getIndexOfUnsavedFaq();
			let updatedUnsavedFaqs = [...unsavedFaqs];
			updatedUnsavedFaqs[unsavedIndex] = current_reward_faqs[selected]
				? deepCopy(current_reward_faqs[selected])
				: defaultFaq;
			setUnsavedFaqs(updatedUnsavedFaqs);
		}

		if (unsavedFaqs?.length === MAXFAQS) {
			setSelected(MAXFAQS - 1);
		}
	};

	const handleFaqRemove = function () {
		unsavedFaqs.splice(selected, 1);

		const actionParams = {
			reward: reward_id,
			questions: unsavedFaqs,
		};

		updateRewardFaqs(actionParams, {
			success: {
				callback: (rewardFaqsResult) => {
					setCurrentRewardFaqs(rewardFaqsResult.questions);
				},
			},
			failure: {
				callback: () => null,
			},
		});

		setSelected(NEW_FAQ);
	};

	const unsavedChangesDetected = function () {
		if (unsavedFaqs !== null) {
			for (let faq of unsavedFaqs) {
				if (faqHasUnsavedChanges(faq)) {
					return true;
				}
			}

			return !areFaqsEqual(newFaq, defaultFaq);
		}
		return false;
	};

	return (
		<LpStagingDialog
			stagedHeading="Reward FAQs"
			stagingHeading="FAQ information"
			stagedContent={
				<>
					<LpDragAndDropList
						items={unsavedFaqs}
						selected={selected}
						itemKey="_id"
						renderItem={(item) =>
							`${item.question} ${faqHasUnsavedChanges(item) ? "*" : ""}`
						}
						onChange={handleFaqMove}
						onClickItem={(index) => setSelected(index)}
					/>
					{unsavedFaqs.length < MAXFAQS && (
						<LpLoadedItem
							className="faqs_dialog__new_faq_item"
							onClick={() => setSelected(NEW_FAQ)}
							selected={selected === NEW_FAQ}
						>
							New FAQ
							{areFaqsEqual(newFaq, defaultFaq) ? "" : " *"}
						</LpLoadedItem>
					)}
				</>
			}
			stagingContent={
				<FaqForm
					key={selected === NEW_FAQ ? newFaq.key : unsavedFaqs[selected]?.loaded}
					form="faq_form"
					className="faq_dialog__faq_form"
					faq={
						selected === NEW_FAQ
							? newFaq
							: unsavedFaqs.find((faq) => faq._id === unsavedFaqs[selected]?._id)
					}
					onChange={handleFaqEdit}
					onSubmit={handleFaqSave}
				/>
			}
			saveButtonForm="faq_form"
			cancelButtonText={selected === NEW_FAQ ? "Clear" : "Cancel"}
			disableCancel={
				selected === NEW_FAQ
					? areFaqsEqual(newFaq, defaultFaq)
					: !faqHasUnsavedChanges(unsavedFaqs[selected])
			}
			onClose={() => onClose(current_reward_faqs)}
			onCancel={resetFaq}
			onRemove={handleFaqRemove}
			disableSave={
				selected === NEW_FAQ
					? areFaqsEqual(newFaq, defaultFaq)
					: !faqHasUnsavedChanges(unsavedFaqs[selected])
			}
			disableRemove={selected === NEW_FAQ}
			triggerButtonText={triggerButtonText}
			triggerButtonClassName={triggerButtonClassName}
			warningText="There are currently unsaved changes in one or more FAQ's.
										Do you want to close the dialog anyway?"
			showWarning={unsavedChangesDetected()}
		/>
	);
}

FaqDialog.propTypes = {
	appendNotifications: PropTypes.func,
	updateRewardFaqs: PropTypes.func,
	reward_id: PropTypes.string,
	rewardFaqs: PropTypes.object,
	onClose: PropTypes.func,
	triggerButtonText: PropTypes.string,
	triggerButtonClassName: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
	appendNotifications: (params) => dispatch(appendNotifications(params)),
	updateRewardFaqs: (params, options) => dispatch(updateRewardFaqs(params, options)),
});

export default connect(null, mapDispatchToProps)(FaqDialog);
