import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { appendNotifications } from "../../actions/Notifications";
import { setQuestTranslation } from "../../actions/QuestTranslations";

// Util
import api from "../../util/api";

// Constants
import { ERROR } from "../../constants/Notifications";
import { FETCH_QUEST_TRANSLATIONS_WATCHER } from "../../constants/ActionTypes";
import { FETCH_QUEST_TRANSLATIONS_URL } from "../../constants/Url";

export function* fetchQuestTranslationsWatcher() {
	yield takeLatest(FETCH_QUEST_TRANSLATIONS_WATCHER, fetchQuestTranslationsWorker);
}

function* fetchQuestTranslationsWorker(action) {
	try {
		const response = yield call(fetchQuestTranslationsApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (isSuccess) {
			yield put(setQuestTranslation(response.data.data));
			if (options?.callback) yield call(options.callback, response);
		} else {
			throw response;
		}
	} catch (e) {
		const { error } = e.response.data;
		yield put(
			appendNotifications({
				type: ERROR,
				message: error,
			})
		);
	}
}

function fetchQuestTranslationsApi({ questId, lang }) {
	return api.get(FETCH_QUEST_TRANSLATIONS_URL(questId), { params: { lang } });
}
