import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import { DELETE_QUEST_WATCHER } from "../../constants/ActionTypes";
import { DELETE_QUEST_URL } from "../../constants/Url";
import { appendNotifications } from "../../actions/Notifications";
import { DELETE_QUEST, SUCCESS, ERROR } from "../../constants/Notifications";

import api from "../../util/api";

export function* deleteQuestsWatcher() {
	yield takeLatest(DELETE_QUEST_WATCHER, deleteQuestsWorker);
}

/**
 * @param {String} action.payload.id The ID of the quest to delete.
 * @param {Boolean} action.payload.displayNotifications Optional. Whether to display slideout notifications on success or error.
 * @param {Boolean} action.payload.successRedirect Optional. URL to redirect to on success.
 * @param {Boolean} action.payload.failureRedirect Optional. URL to redirect to on failure.
 */
function* deleteQuestsWorker(action) {
	try {
		let response = yield call(deleteQuestsApi, action.payload.id);
		if (response.status >= 200 && response.status < 300) {
			if (action.payload.displayNotifications) {
				yield put(appendNotifications(DELETE_QUEST(SUCCESS)));
			}
			if (action.payload.successRedirect) {
				yield put(push(action.payload.successRedirect));
			}
		} else {
			if (action.payload.displayNotifications) {
				yield put(appendNotifications(DELETE_QUEST(ERROR)));
			}
			if (action.payload.failureRedirect) {
				yield put(push(action.payload.failureRedirect));
			}
		}
	} catch (e) {
		console.error(e);
	}
}

function deleteQuestsApi(questId) {
	return api
		.delete(DELETE_QUEST_URL(questId))
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
