import React from "react";

export function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function titleCase(str) {
	return str
		.toLowerCase()
		.replace(/^[^\s]/g, function ($1) {
			return $1.toUpperCase();
		})
		.replace(/\s(.)/g, function ($1) {
			return $1.toUpperCase();
		});
}

export function padStart(n, width, z) {
	z = z || "0";
	n = n + "";
	// return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	return n.length >= width ? n : n.padStart(width, z);
}

export const splitLines = (string) => {
	let lines = string.split("\n");
	return lines.map(function (item, key) {
		return (
			<React.Fragment>
				{item} {key == lines.length - 1 ? null : <br />}
			</React.Fragment>
		);
	});
};

export const validEmail = (string) => {
	const regex =
		/(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
	return regex.test(string.toLocaleLowerCase());
};

export const validateNumeric = (value) => {
	const regex = /^\d+$/;
	return regex.test(value);
};

export const trimNumber = (value) => {
	return String(value).replace(/[^0-9]+/g, "");
};

export function inputNumberLimit(val, max, min = 1) {
	let maxDigits = String(max).length;
	let valDigits = String(val).length;

	if (valDigits >= maxDigits && Number(val) < min) {
		val = padStart(min, maxDigits, "0");
	} else if (val > max) {
		val = max;
	} else if (valDigits > maxDigits) {
		val = Number(String(val).substr(0, valDigits));
	}

	return val;
}

export function numberLimit(val, max, min = 1) {
	if (Number(val) < min) {
		val = min;
	} else if (val > max) {
		val = max;
	}

	return val;
}

export const truncateNumber = (num) => {
	if (num >= 1000000000) {
		return (num / 1000000000).toFixed(2).replace(/\.0$/, "") + "B";
	}
	if (num >= 1000000) {
		return (num / 1000000).toFixed(2).replace(/\.0$/, "") + "M";
	} else {
		return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + ",");
	}
};

export function formatNumber(num, separator = null) {
	if (separator === null) return num.toLocaleString();
	return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1" + separator);
}

export function replaceDivsForParagraphs(htmlString) {
	const regex = new RegExp(/div>/, "g");
	const html = htmlString.replace(regex, "p>");
	return html;
}

export function wrapListItemAroundLists(htmlString) {
	const parser = new DOMParser();
	const html = parser.parseFromString(htmlString, "text/html");
	html.querySelectorAll("ul").forEach((item) => {
		const nestedLists = item.querySelectorAll("ul");
		if (nestedLists.length) {
			nestedLists.forEach((list) => {
				if (list.parentElement.tagName.toLowerCase() === "ul") {
					const li = document.createElement("li");
					li.innerHTML = list.outerHTML;
					li.setAttribute("class", "nested-list-item");
					item.removeChild(list);
					item.appendChild(li);
				}
			});
		}
	});
	const formattedHtmlString = html.querySelector("body").innerHTML;
	return formattedHtmlString;
}

export function cleanseTitleElements(string) {
	const headingTags = ["h1", "h2", "h3"];
	const disallowedTags = ["em", "strong", "br", "i", "b"];

	headingTags.forEach((tag) => {
		const regex = new RegExp(`<${tag}(.*?)</${tag}>`, "g");
		const titleTag = string.match(regex);
		if (titleTag) {
			titleTag.forEach((val) => {
				let noTagsHtml = val;
				disallowedTags.forEach((disallowedTag) => {
					const tagRegex = new RegExp(`</?${disallowedTag}>`, "g");
					noTagsHtml = noTagsHtml.replace(tagRegex, "");
				});
				string = string.replace(val, noTagsHtml);
			});
		}
	});
	return string;
}

export function removeEmptyParagraphsWithBrTags(string) {
	const regex = new RegExp("<p><br></p>", "g");
	const formattedString = string.replace(regex, "<br>");
	return formattedString;
}

export function sanitizeHtml(string) {
	if (string) {
		const sanitizeHtml = require("sanitize-html");
		let formattedString = cleanseTitleElements(string);
		formattedString = wrapListItemAroundLists(formattedString);
		formattedString = removeEmptyParagraphsWithBrTags(formattedString);
		formattedString = replaceDivsForParagraphs(formattedString);
		return sanitizeHtml(formattedString, {
			allowedTags: [
				"h1",
				"h2",
				"h3",
				"h4",
				"h5",
				"h6",
				"p",
				"ol",
				"ul",
				"li",
				"a",
				"strong",
				"em",
				"br",
			],
			selfClosing: ["br"],
			allowedAttributes: {
				a: ["href", "target"],
				ul: ["class"],
				li: ["class"],
				h1: ["class"],
				h2: ["class"],
				h3: ["class"],
			},
			allowedSchemes: ["http", "https"],
			allowedSchemesAppliedToAttributes: ["href"],
			disallowedTagsMode: "discard",
		});
	} else {
		return null;
	}
}

export const pluralize = (noun, count, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;
