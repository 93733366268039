import api from '../../util/api.js'; 
import {
	takeLatest,
	call,
	put,
} from "redux-saga/effects";
import {
	appendNotifications
} from "../../actions/Notifications.js";
import {
    addMessage,
} from "../../actions/Messages.js";
import {
	SUCCESS,
	ERROR,
} from "../../constants/Notifications.js";
import {
    CHANGE_USER_EMAIL
} from "../../constants/ActionTypes.js";
import {
    USER_EMAIL_CHANGE_URL
} from "../../constants/Url.js";

export function* emailChangeWatcher() {
	yield takeLatest(CHANGE_USER_EMAIL, emailChangeWorker);
};


function* emailChangeWorker(action) {
	try {
		const response = yield call(emailChangeApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		
		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (options?.notification) {
			yield put(appendNotifications({
				type: isSuccess ? SUCCESS : ERROR,
				message: options.notification,
			}));
		};
		if (options?.message) {
			yield put(addMessage({
				source: CHANGE_USER_EMAIL,
				message: response?.data?.error|| "Email change failed",
				isError: isSuccess,
			}));
		};
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		};
	} catch (e) {
		console.error(e);
	};
};

function emailChangeApi(params) {
	return api
		.post(USER_EMAIL_CHANGE_URL, {
			email: params.email
		})
		.then(response => {return response})
		.catch(error => {return error.response});
};
