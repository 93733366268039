import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import { FETCH_TWITCH_LIVE_STREAMERS } from "../../../constants/ActionTypes";
import { TWITCH_LIVE_STREAMERS } from "../../../constants/Url";
import { SUCCESS, ERROR } from "../../../constants/Notifications";
import { appendNotifications } from "../../../actions/Notifications";
import { addMessage } from "../../../actions/Messages";
import { setTwitchLiveStreamers } from "../../../actions/Channel";

import api from "../../../util/api";

export function* fetchTwitchLiveStreamersWatcher() {
	yield takeLatest(FETCH_TWITCH_LIVE_STREAMERS, fetchTwitchLiveStreamersWorker);
}

function* fetchTwitchLiveStreamersWorker(action) {
	try {
		const response = yield call(fetchTwitchLiveStreamersApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;
		if (isSuccess) {
			yield put(setTwitchLiveStreamers(response.data.data));
		}

		let options;
		switch (true) {
			case response.status >= 200 && response.status < 300: {
				options = action?.options?.success;
				break;
			}
			case response.status >= 400 && response.status < 500: {
				options = action?.options?.bad_request;
				break;
			}
			default: {
				options = action?.options?.failure;
				break;
			}
		}
		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.message) {
			yield put(
				addMessage({
					source: FETCH_TWITCH_LIVE_STREAMERS,
					message: options.message,
					isError: !isSuccess,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		}
		if (options?.redirect) {
			yield put(push(options.redirect));
		}
	} catch (e) {
		console.error(e);
	}
}

function fetchTwitchLiveStreamersApi(params) {
	return api
		.get(TWITCH_LIVE_STREAMERS)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
