import { takeLatest, call, put } from "redux-saga/effects";
import { FETCH_RELATED_REWARDS_WATCHER } from "../../constants/ActionTypes";
import { REWARD_SERVICE } from "../../constants/Url";
import { SUCCESS, ERROR } from "../../constants/Notifications";
import { appendNotifications } from "../../actions/Notifications";
import { push } from "react-router-redux";

import api from "../../util/api";

export function* fetchRelatedRewardsWatcher() {
	yield takeLatest(FETCH_RELATED_REWARDS_WATCHER, fetchRelatedRewardsWorker);
}

function* fetchRelatedRewardsWorker(action) {
	try {
		const response = yield call(fetchRelatedRewardsApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}

		if (options?.callback) {
			yield call(options.callback, response.data);
		}

		if (options?.redirect) {
			yield put(push(options.redirect));
		}
	} catch (e) {
		console.error(e);
	}
}

function fetchRelatedRewardsApi(params) {
	return api
		.get(REWARD_SERVICE + "/" + params.url_slug + "/related")
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
