import {
	UPDATE_USER_ROLE,
	UPDATE_USER_ONBOARDED,
	UPDATE_USER_USERNAME,
	UPDATE_USER_MEMBERSHIP,
	UPDATE_USER_COUNTRY,
	UPDATE_USER_GENDER,
	UPDATE_USER_BIRTHDAY,
	UPDATE_USER_PARENT_CONSENT,
	PASSWORD_AUTHENTICATION,
	CHANGE_USER_EMAIL,
	UPDATE_USER_EMAIL,
} from "../constants/ActionTypes";

/**
 * @param {Object} params API request parameters.
 * @param {String} params.user The ID of the user to update.
 * @param {String} [params.role_id] The ID of the role to update the user's ```role``` to. At least one of ```role_id``` or ```role_name``` must be present. ```role_id``` takes precedence over ```role_name```.
 * @param {String} [params.role_name] The name of the role to update the user's ```role``` to. At least one of ```role_id``` or ```role_name``` must be present. ```role_id``` takes precedence over ```role_name```.
 *
 * @param {Object} [options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [options.success] Behavior to execute when API call and action is successful.
 * @param {String} [options.success.redirect] Redirect to this URL on success.
 * @param {String} [options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [options.success.notification] Display notification with passed text on success.
 * @param {Function} [options.success.callback] Callback function on success.
 * @param {*} [options.success.callbackArgs] Callback function args on success.
 *
 * @param {Object} [options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [options.failure.callback] Callback function on failure.
 * @param {*} [options.failure.callbackArgs] Callback function args on failure.
 */
export const updateUserRole = (params, options) => {
	return {
		type: UPDATE_USER_ROLE,
		params: params,
		options: options,
	};
};

/**
 * @param {Object} params API request parameters.
 * @param {String} params.user The ID of the user to update.
 * @param {String} params.onboarded The value to update the user's ```onboarded``` to.
 *
 * @param {Object} [options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [options.success] Behavior to execute when API call and action is successful. (2xx code)
 * @param {String} [options.success.redirect] Redirect to this URL on success.
 * @param {String} [options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [options.success.notification] Display notification with passed text on success.
 * @param {Function} [options.success.callback] Callback function on success.
 * @param {*} [options.success.callbackArgs] Callback function args on success.
 * @param {Boolean} [options.success.refreshUserOnboarded] Update the Redux store with the user's new ```onboarded``` value.
 *
 * @param {Object} [options.failure] Behavior to execute when API call and action is failed. (non-2xx and non-4xx code)
 * @param {String} [options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [options.failure.callback] Callback function on failure.
 * @param {*} [options.failure.callbackArgs] Callback function args on failure.
 *
 * @param {Object} [options.bad_request] Behavior to execute when API call and action is failed due to bad request. (4xx code)
 * @param {String} [options.bad_request.redirect] Redirect to this URL on bad request.
 * @param {String} [options.bad_request.message] Push message with passed text to ```state.messages.bank``` on bad request.
 * @param {String} [options.bad_request.notification] Display notification with passed text on bad request.
 * @param {Function} [options.bad_request.callback] Callback function on bad request.
 * @param {*} [options.bad_request.callbackArgs] Callback function args on bad request.
 */
export const updateUserOnboarded = (params, options) => {
	return {
		type: UPDATE_USER_ONBOARDED,
		params: params,
		options: options,
	};
};

/**
 * @param {Object} params API request parameters.
 * @param {String} params.user The ID of the user to update.
 * @param {String} params.username The username to update the user's ```username``` to.
 *
 * @param {Object} [options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [options.success] Behavior to execute when API call and action is successful.
 * @param {String} [options.success.redirect] Redirect to this URL on success.
 * @param {String} [options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [options.success.notification] Display notification with passed text on success.
 * @param {Function} [options.success.callback] Callback function on success.
 * @param {*} [options.success.callbackArgs] Callback function args on success.
 *
 * @param {Object} [options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [options.failure.callback] Callback function on failure.
 * @param {*} [options.failure.callbackArgs] Callback function args on failure.
 */
export const updateUserUsername = (params, options) => {
	return {
		type: UPDATE_USER_USERNAME,
		params: params,
		options: options,
	};
};

export const updateUserMembership = (params, options) => {
	return {
		type: UPDATE_USER_MEMBERSHIP,
		params: params,
		options: options,
	};
};

/**
 * @param {Object} params API request parameters.
 * @param {String} params.user The ID of the user to update.
 * @param {String} params.email The email to update the user's ```email``` to.
 *
 * @param {Object} [options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [options.success] Behavior to execute when API call and action is successful.
 * @param {String} [options.success.redirect] Redirect to this URL on success.
 * @param {String} [options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [options.success.notification] Display notification with passed text on success.
 * @param {Function} [options.success.callback] Callback function on success.
 * @param {*} [options.success.callbackArgs] Callback function args on success.
 *
 * @param {Object} [options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [options.failure.callback] Callback function on failure.
 * @param {*} [options.failure.callbackArgs] Callback function args on failure.
 */
export const updateUserEmail = (params, options) => {
	return {
		type: UPDATE_USER_EMAIL,
		params: params,
		options: options,
	};
};

/**
 * @param {Object} params API request parameters.
 * @param {String} params.user The ID of the user to update.
 * @param {String} params.country The country to update the user's ```country``` to.
 *
 * @param {Object} [options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [options.success] Behavior to execute when API call and action is successful.
 * @param {String} [options.success.redirect] Redirect to this URL on success.
 * @param {String} [options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [options.success.notification] Display notification with passed text on success.
 * @param {Function} [options.success.callback] Callback function on success.
 * @param {*} [options.success.callbackArgs] Callback function args on success.
 * @param {Boolean} [options.success.updateUserProfile] Whether to update the user's profile in the Redux state.
 * @param {Boolean} [options.success.fetchUserProfileForAdminUi] Whether to update the user's profile in the Redux state.
 *
 * @param {Object} [options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [options.failure.callback] Callback function on failure.
 * @param {*} [options.failure.callbackArgs] Callback function args on failure.
 */
export const updateUserCountry = (params, options) => {
	return {
		type: UPDATE_USER_COUNTRY,
		params: params,
		options: options,
	};
};

/**
 * @param {Object} params API request parameters.
 * @param {String} params.user The ID of the user to update.
 * @param {String} params.country_code The country code to update the user's ```country_code``` to.
 *
 * @param {Object} [options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [options.success] Behavior to execute when API call and action is successful.
 * @param {String} [options.success.redirect] Redirect to this URL on success.
 * @param {String} [options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [options.success.notification] Display notification with passed text on success.
 * @param {Function} [options.success.callback] Callback function on success.
 * @param {*} [options.success.callbackArgs] Callback function args on success.
 * @param {Boolean} [options.success.updateUserProfile] Whether to update the user's profile in the Redux state.
 * @param {Boolean} [options.success.fetchUserProfileForAdminUi] Whether to update the user's profile in the Redux state.
 *
 * @param {Object} [options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [options.failure.callback] Callback function on failure.
 * @param {*} [options.failure.callbackArgs] Callback function args on failure.
 */

/**
 * @param {Object} params API request parameters.
 * @param {String} params.user The ID of the user to update.
 * @param {String} params.gender The gender to update the user's ```gender``` to.
 *
 * @param {Object} [options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [options.success] Behavior to execute when API call and action is successful.
 * @param {String} [options.success.redirect] Redirect to this URL on success.
 * @param {String} [options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [options.success.notification] Display notification with passed text on success.
 * @param {Function} [options.success.callback] Callback function on success.
 * @param {*} [options.success.callbackArgs] Callback function args on success.
 * @param {Boolean} [options.success.updateUserProfile] Whether to update the user's profile in the Redux state.
 * @param {Boolean} [options.success.fetchUserProfileForAdminUi] Whether to update the user's profile in the Redux state.
 *
 * @param {Object} [options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [options.failure.callback] Callback function on failure.
 * @param {*} [options.failure.callbackArgs] Callback function args on failure.
 */
export const updateUserGender = (params, options) => {
	return {
		type: UPDATE_USER_GENDER,
		params: params,
		options: options,
	};
};

/**
 * @param {Object} params API request parameters.
 * @param {String} params.user The ID of the user to update.
 * @param {String} params.phone_number The phone number to update the user's ```phone_number``` to.
 *
 * @param {Object} [options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [options.success] Behavior to execute when API call and action is successful.
 * @param {String} [options.success.redirect] Redirect to this URL on success.
 * @param {String} [options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [options.success.notification] Display notification with passed text on success.
 * @param {Function} [options.success.callback] Callback function on success.
 * @param {*} [options.success.callbackArgs] Callback function args on success.
 * @param {Boolean} [options.success.updateUserProfile] Whether to update the user's profile in the Redux state.
 * @param {Boolean} [options.success.fetchUserProfileForAdminUi] Whether to update the user's profile in the Redux state.
 *
 * @param {Object} [options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [options.failure.callback] Callback function on failure.
 * @param {*} [options.failure.callbackArgs] Callback function args on failure.
 */

/**
 * @param {Object} params API request parameters.
 * @param {String} params.user The ID of the user to update.
 * @param {String} params.birthday The phone number to update the user's ```birthday``` to.
 *
 * @param {Object} [options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [options.success] Behavior to execute when API call and action is successful.
 * @param {String} [options.success.redirect] Redirect to this URL on success.
 * @param {String} [options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [options.success.notification] Display notification with passed text on success.
 * @param {Function} [options.success.callback] Callback function on success.
 * @param {*} [options.success.callbackArgs] Callback function args on success.
 * @param {Boolean} [options.success.updateUserProfile] Whether to update the user's profile in the Redux state.
 * @param {Boolean} [options.success.fetchUserProfileForAdminUi] Whether to update the user's profile in the Redux state.
 *
 * @param {Object} [options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [options.failure.callback] Callback function on failure.
 * @param {*} [options.failure.callbackArgs] Callback function args on failure.
 */
export const updateUserBirthday = (params, options) => {
	return {
		type: UPDATE_USER_BIRTHDAY,
		params: params,
		options: options,
	};
};

export const updateUserParentConsent = (params) => {
	return {
		type: UPDATE_USER_PARENT_CONSENT,
		params: params,
	};
};

export const passwordAuthentication = (params, options) => {
	return {
		type: PASSWORD_AUTHENTICATION,
		payload: params,
		options: options,
	};
};

export const changeUserEmail = (params, options) => {
	return {
		type: CHANGE_USER_EMAIL,
		payload: params,
		options: options,
	};
};
