import { all } from "redux-saga/effects";

import { fetchUsersAchievementsWatcher } from "./FetchUsersAchievementsSaga";
import { fetchAchievementsDisplayWatcher } from "./FetchAchievementsDisplay";
import { updateAchievementsDisplayWatcher } from "./UpdateAchievementsDisplay";

export function* usersAchievementsSagas() {
	yield all([
		fetchUsersAchievementsWatcher(),
		fetchAchievementsDisplayWatcher(),
		updateAchievementsDisplayWatcher(),
	]);
}
