import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";

import { appendNotifications } from "../../actions/Notifications";
import { addMessage } from "../../actions/Messages";
import { SUCCESS, ERROR } from "../../constants/Notifications";

import { UPDATE_PLAYER_WATCHER } from "../../constants/ActionTypes";
import { UPDATE_PLAYER_URL } from "../../constants/Url";
import { fetchPlayers } from "../../actions/Players";
import api from "../../util/api";

export function* updatePlayerWatcher() {
	yield takeLatest(UPDATE_PLAYER_WATCHER, updatePlayerWorker);
}

function* updatePlayerWorker(action) {
	try {
		let response = yield call(updatePlayerApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess
			? action?.options?.success
			: action?.options?.failure;

		if (isSuccess) {
			yield put(fetchPlayers({ is_active: options.is_active }));
		}
		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.message) {
			yield put(
				addMessage({
					source: UPDATE_PLAYER_URL,
					message: response?.data?.error,
					isError: true,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		}
		if (options?.redirect) {
			yield put(push(options.redirect));
		}
	} catch (e) {
		console.error(e);
	}
}

function updatePlayerApi(payload) {
	return api
		.put(UPDATE_PLAYER_URL(payload.name), payload)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
