// Event types
export const EVENT_TYPES = {
	OFFLINE: "OFFLINE",
	DIGITAL: "DIGITAL",
};

// Event sub types
export const EVENT_SUBTYPES = {
	STANDALONE: "STANDALONE",
	SERIALIZED: "SERIALIZED",
};

// Quest objective event url button text
export const BUTTON_TEXT = ["ENTER", "LISTEN", "READ", "VIEW", "VISIT", "WATCH"];
