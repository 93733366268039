import {
    setUserPreferredPlayers
} from "../../actions/Players";

import {
    takeLatest,
    call,
    put,
} from "redux-saga/effects";

import {
    FETCH_USER_PREFERRED_PLAYERS_WATCHER
} from "../../constants/ActionTypes";

import {
    FETCH_USER_PREFERRED_PLAYERS_URL
} from "../../constants/Url";

import api from '../../util/api';

export function* fetchUserPreferredPlayersWatcher() {
    yield takeLatest(FETCH_USER_PREFERRED_PLAYERS_WATCHER, fetchUserPreferredPlayersWorker);
};

function* fetchUserPreferredPlayersWorker(action) {
    try {
        let response = yield call(fetchUserPreferredPlayersApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(setUserPreferredPlayers(response.data.data));
        } else {
            throw response;
        };
    } catch (e) {
        console.error(e);
    };
};

function fetchUserPreferredPlayersApi(payload) {
    return (
        api
        .get(FETCH_USER_PREFERRED_PLAYERS_URL)
    );
};