import {
    all
} from 'redux-saga/effects';

import {
    fetchAchievementsWatcher
} from './FetchAchievementsSaga'

import {
    createAchievementWatcher
} from './CreateAchievementSaga'

import {
    searchAchievementsWatcher
} from './SearchAchievementSaga'

import {
    fetchAchievementWatcher
} from './FetchAchievementSaga'

import {
    updateAchievementWatcher
} from './UpdateAchievementSaga'

import {
    fetchValidAchievementsWatcher
} from './FetchValidAchievementsSaga'


export function* achievementsSagas() {
    yield all([
        fetchAchievementsWatcher(),
        createAchievementWatcher(),
        searchAchievementsWatcher(),
        fetchAchievementWatcher(),
        updateAchievementWatcher(),
        fetchValidAchievementsWatcher(),
    ]);
}