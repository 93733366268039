import {
    setRecentEvents
} from "../../actions/Events";
import {
    showLoader,
    hideLoader
} from "../../actions/GlobalAction";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import { // if you want to redirect post saga action
    push
} from "react-router-redux";
import {
    RECENT_EVENT_WATCHER
} from "../../constants/ActionTypes";
import {
    RECENT_EVENTS_URL
} from "../../constants/Url";
import api from '../../util/api';

export function* recentEventsWatcher() {
    yield takeLatest(RECENT_EVENT_WATCHER, recentEventsWorker);
};

function* recentEventsWorker(action) {
    try {
        let response = yield call(recentEventsApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(setRecentEvents(response.data.data));
        } else {
            throw (response);
        }
    } catch (e) {
        console.log(e)
    };
};

function recentEventsApi(payload) {
    return (
        api
        .post(RECENT_EVENTS_URL, {
            page: payload.page,
            limit: payload.limit,
            status: payload.status,
        })
    );
};