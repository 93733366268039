import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LpHeading from "../../../components/LpHeading";
import TablePagination from "@material-ui/core/TablePagination";
import { COLLECTIONS } from "../../../constants/PaginationData";
import { fetchInteractions } from "../../../actions/UserAdminPanel";
import "./styles.scss";

class Interactions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			rowsPerPageOptions: COLLECTIONS.ROW_PER_PAGE_OPTIONS,
			page: COLLECTIONS.page,
			limit: COLLECTIONS.defaultRowPerPage,
			searchedBy: "all",
		};
	}

	componentDidMount() {
		const { page, limit } = this.state;

		this.props.fetchInteractions(page + 1, limit);
	}

	handleChangePage = () => {
		console.log("handleChangePage");
	};

	handleChangeRowsPerPage = (event) => {
		if (event.target.value) {
			this.setState(
				{
					page: 0,
					limit: event.target.value,
				},
				() => {
					//console.log("Will use for end point calling");
				}
			);
		}
	};

	handleOptionsButtonClick(service) {
		this.setState({ searchedBy: service });
	}

	getPlatformLogo(platform) {
		let className = "mt-1 icon-postion social-icon social-icon--";
		switch (platform) {
			case "twitch":
			case "twitter":
			case "reddit":
			case "discord":
			case "shopify":
				return className + platform;
			default:
				return className + "teamliquid";
		}
	}

	tablePagination() {
		const { count } = this.props;
		const { limit, rowsPerPageOptions, page } = this.state;

		if (count > limit)
			return (
				<TablePagination
					className="float-right"
					component="div"
					count={count}
					rowsPerPage={limit}
					rowsPerPageOptions={rowsPerPageOptions}
					page={page}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
					classes={{
						select: "pagination-select",
					}}
				/>
			);
	}

	render() {
		const { searchedBy } = this.state;

		return (
			<div className="container m-3 p-3 Interactions admin">
				<div>
					<LpHeading>Interactions</LpHeading>
				</div>
				<div className="d-flex mb-3">
					<div className="col-6 pt-1">
						<label htmlFor="name" className="level-text">
							Filter By
						</label>
						<br />
						<button
							variant="text"
							className={`button button--circle button--borderless ${
								searchedBy === "all" ? "button--active" : ""
							}`}
							onClick={() => this.handleOptionsButtonClick("all")}
						>
							All
						</button>

						<button
							className={` ml-3 button button--circle button--borderless ${
								searchedBy === "teamliquid" ? "button--active" : ""
							}`}
							onClick={() => this.handleOptionsButtonClick("teamliquid")}
						>
							<img
								className="button__image default-filter"
								src={require("../../../svg/service-teamliquid.svg")}
							/>
						</button>

						<button
							className={`ml-3 button button--circle button--borderless ${
								searchedBy === "discord" ? "button--active" : ""
							}`}
							onClick={() => this.handleOptionsButtonClick("discord")}
						>
							<img className="button__image " src={require("../../../svg/service-discord.svg")} />
						</button>

						<button
							className={`ml-3 button button--circle button--borderless ${
								searchedBy === "reddit" ? "button--active" : ""
							}`}
							onClick={() => this.handleOptionsButtonClick("reddit")}
						>
							<img
								className="button__image default-filter"
								src={require("../../../svg/service-reddit-w.svg")}
							/>
						</button>

						<button
							className={`ml-3 button button--circle button--borderless ${
								searchedBy === "twitch" ? "button--active" : ""
							}`}
							onClick={() => this.handleOptionsButtonClick("twitch")}
						>
							<img
								className="button__image default-filter"
								src={require("../../../svg/service-twitch-w.svg")}
							/>
						</button>

						<button
							className={` ml-3 button button--circle button--borderless ${
								searchedBy === "twitter" ? "button--active" : ""
							}`}
							onClick={() => this.handleOptionsButtonClick("twitter")}
						>
							<img
								className="button__image default-filter"
								src={require("../../../svg/service-twitter-w.svg")}
							/>
						</button>
					</div>
					<div className="col-6 pt-2">{this.tablePagination()}</div>
				</div>
				<div className="table-responsive-material">
					<table className="data-table">
						<thead>
							<tr>
								<th width="70%" className="data-table__header">
									Interaction
								</th>
								<th width="30%" className="align-right data-table__header">
									Value
								</th>
							</tr>
						</thead>
						<tbody>
							{this.props.interactions.map((item) => {
								return (
									<tr key={item.id}>
										<td width="70%" className="data-table__data-cell">
											<span className={this.getPlatformLogo(item.platform_type.toLowerCase())} />
											<span className="pl-2">{item.description}</span>
										</td>

										<td width="30%" className={`align-right data-table__data-cell`}>
											{item.value}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div>{this.tablePagination()}</div>
			</div>
		);
	}
}

Interactions.propTypes = {
	count: PropTypes.number,
	interactions: PropTypes.arrayOf(PropTypes.object).isRequired,
	fetchInteractions: PropTypes.func.isRequired,
};

Interactions.defaultProps = {
	interactions: [],
};

const mapStateToProps = (state) => {
	return {
		interactions: state.user_admin.interactions,
		count: 50, // static for testing purpose
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchInteractions: (page, limit) => dispatch(fetchInteractions(page, limit)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Interactions);
