import { applyMiddleware, createStore } from "redux";
import reducers from "../reducers/index";
// import createHistory from 'history/createHashHistory';
import { createBrowserHistory as createHistory } from "history";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/index";
import { routerMiddleware } from "react-router-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { compose } from "redux";

const history = createHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
// Use this to enable Redux call tracing
// const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
const composeEnhancers = compose();

const middlewares = [sagaMiddleware, routeMiddleware];

export default function configureStore(initialState) {
	const store = createStore(
		reducers,
		initialState,
		composeEnhancers(applyMiddleware(...middlewares))
	);

	sagaMiddleware.run(rootSaga);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept("../reducers/index", () => {
			const nextRootReducer = require("../reducers/index");
			store.replaceReducer(nextRootReducer);
		});
	}
	return store;
}
export { history };
