import {
    showLoader,
    hideLoader
} from "../../actions/GlobalAction";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import { // if you want to redirect post saga action
    push
} from "react-router-redux";
import {
    DELETE_EVENT_WATCHER
} from "../../constants/ActionTypes";
import {
    DELETE_EVENT_URL
} from "../../constants/Url";

import api from '../../util/api';

function deleteEventApi(payload) {
    // let token = window.browserStorage.getJWT();
    return (
        api
        .delete(DELETE_EVENT_URL(payload))
    );
};

export function* deleteEventWatcher() {
    yield takeLatest(DELETE_EVENT_WATCHER, deleteEventWorker);
};

function* deleteEventWorker(action) {
    try {
        let response = yield call(deleteEventApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(push('/app/events'))
        } else {
            throw (response);
        }
    } catch (e) {
        throw e;
    };
};