import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./styles.scss";

function CheckBox({ className, id, label, checked, onChange }) {
	return (
		<div className={classnames("CheckBox", className)}>
			<input
				id={id}
				className="CheckBox__input"
				type="checkbox"
				checked={checked}
				onChange={onChange}
			/>
			<label htmlFor={id} className="CheckBox__label">
				<span className="CheckBox__box"></span>
				{label}
			</label>
		</div>
	);
}

CheckBox.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	label: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
};

export default CheckBox;
