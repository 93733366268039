import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Actions
import { fetchUserAccountInfo } from "../../../../actions/UserAdminPanel";
import { updateUserUsername } from "../../../../actions/UserUpdate";
import { clearMessages } from "../../../../actions/Messages";

// Constants
import { UPDATE_USER_USERNAME } from "../../../../constants/ActionTypes";

// Components
import Modal from "../../../../components/Modal";
import { FormFieldInput } from "../../../../components/FormFields";

class IdentityDialog extends React.Component {
	constructor(props) {
		super(props);

		const { avatar, username } = props;

		this.state = {
			avatar,
			username,
			error: "",
			show_modal: false,
		};
	}

	componentWillReceiveProps(nextProps) {
		const { avatar, username } = nextProps;
		this.setState({ avatar, username });
	}

	renderError() {
		const { error } = this.state;
		if (error) {
			return (
				<div className="error">
					<img className="error_image" src={require("../../../../svg/alert.svg")} alt={error} />
					<div className="error_message"></div>
					{error}
				</div>
			);
		}
	}

	changeUsername = (newUsername) => {
		const { updateUserUsername, userId, fetchUserAccountInfo, errorMessage } = this.props;

		updateUserUsername(
			{
				user: userId,
				username: newUsername,
			},
			{
				success: {
					callback: () => {
						fetchUserAccountInfo(userId);
						this.setState({ show_modal: false });
					},
				},
				failure: {
					message: true,
					callback: () => {
						this.setState({
							show_modal: true,
							error: errorMessage,
						});
					},
				},
			}
		);
	};

	handleOpen = () => this.setState({ show_modal: true });

	handleSubmit = () => {
		const { username } = this.state;
		const { clearMessages } = this.props;
		this.setState({ show_modal: true, error: "" });
		this.changeUsername(username);
		clearMessages(UPDATE_USER_USERNAME);
	};

	handleClose = () => {
		const { clearMessages, username } = this.props;
		this.setState({
			username,
			show_modal: false,
			error: "",
		});
		clearMessages(UPDATE_USER_USERNAME);
	};

	checkValidation = () => {
		const { username } = this.state;
		const { username: usernameProp } = this.props;
		return username === usernameProp;
	};

	render() {
		const { show_modal, avatar, username } = this.state;

		return (
			<Modal
				className="admin"
				triggerBtnText="Change"
				triggerBtnClassName="button"
				cancelBtnText="Cancel"
				submitBtnText="Save"
				show={show_modal}
				onTrigger={this.handleOpen}
				submitDisabled={this.checkValidation()}
				onSubmit={this.handleSubmit}
				onClose={this.handleClose}
			>
				<div className="form-field form-field--input-image">
					<figure className="icon-placeholder">
						<img src={avatar} alt={username} />
						<figcaption className="icon-placeholder__caption">User Avatar</figcaption>
					</figure>
				</div>
				<FormFieldInput
					label="UserName"
					type="text"
					name="username"
					value={username}
					placeholder="22CharacterUserNameID"
					onChange={(event) => this.setState({ username: event.target.value })}
				/>
				{this.renderError()}
			</Modal>
		);
	}
}

IdentityDialog.propTypes = {
	avatar: PropTypes.string,
	username: PropTypes.string,
	userId: PropTypes.string,
	errorMessage: PropTypes.string,
	clearMessages: PropTypes.func,
	updateUserUsername: PropTypes.func,
	fetchUserAccountInfo: PropTypes.func,
};

const mapStateToProps = (state) => ({
	errorMessage: state.messages.bank.find((message) => message.source === UPDATE_USER_USERNAME)
		?.message,
});

const mapDispatchToProps = {
	updateUserUsername,
	fetchUserAccountInfo,
	clearMessages,
};

export default connect(mapStateToProps, mapDispatchToProps)(IdentityDialog);
