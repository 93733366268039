import {
    ADD_MESSAGE,
    REMOVE_MESSAGE,
    CLEAR_MESSAGES,
} from "../constants/ActionTypes";

const messagesInitialState = {
    bank: [],
};

const uuid = require("uuid/v4");

export default function messagesReducer(state = messagesInitialState, action) {
    switch (action.type) {
        case ADD_MESSAGE: {
            action.payload.id = uuid();
            return {
                ...state,
                bank: [
                    ...state.bank,
                    action.payload,
                ],
            };
        };
        case REMOVE_MESSAGE: {
            const newBank = [...state.bank].filter(message => message.id !== action.payload)
            return {
                ...state,
                bank: newBank,
            };
        };
        case CLEAR_MESSAGES: {
            const newBank = action.payload ?
                [...state.bank].filter(message => message.source !== action.payload) :
                [];
            return {
                ...state,
                bank: newBank,
            };
        };
        default: {
            return state;
        };
    };
};