import {
    APPEND_NOTIFICATIONS,
    POP_NOTIFICATION,
    UNSHIFT_NOTIFICATION,
    REMOVE_NOTIFICATION,
    CLEAR_NOTIFICATIONS
} from '../constants/ActionTypes'

const notificationsInitialState = {
    bank: []
};

const uuid = require('uuid/v4')

export default function notificationsReducer(state = notificationsInitialState, action) {
    switch (action.type) {
        case APPEND_NOTIFICATIONS: {
            action.payload.id = uuid()
            return {
                ...state,
                bank: [
                    ...state.bank,
                    action.payload
                ]
            }
        }
        case POP_NOTIFICATION: {
            let new_bank = [...state.bank]
            new_bank.pop()
            return {
                ...state,
                bank: new_bank
            }
        }    
        case UNSHIFT_NOTIFICATION: {
            let new_bank = [...state.bank]
            new_bank.unshift()
            return {
                ...state,
                bank: new_bank
            }
        }  
        case REMOVE_NOTIFICATION:{
            let new_bank = [...state.bank].filter(item => item.id !== action.payload)
            return {
                ...state,
                bank: new_bank
            }
        }

        case CLEAR_NOTIFICATIONS: {
            return {
                ...state,
                bank: []
            }
        }
        
        default:
            return state;
    }
}