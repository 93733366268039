/* eslint-disable complexity */
import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

import "./styles.scss";
import { withTranslation } from "react-i18next";

class TextField extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			focus: false,
			password_visible: false,
		};

		if (props.autoFocus) {
			this.setState({ focus: true });
		}
	}

	componentDidMount() {
		const { autoFocus, inputRef } = this.props;
		if (autoFocus) {
			setTimeout(() => inputRef?.current.focus(), 10);
		}
	}

	blur() {
		this.setState({ focus: false }, () => {
			const { onBlur } = this.props;
			if (onBlur) {
				onBlur();
			}
		});
	}

	focus() {
		const { onFocus } = this.props;
		this.setState({ focus: true }, () => {
			if (onFocus) {
				onFocus();
			}
		});
	}

	togglePasswordVisibility() {
		const { password_visible } = this.state;
		this.setState({
			password_visible: !password_visible,
		});
	}

	renderPasswordEye(type) {
		const { password_visible } = this.state;
		const { t } = this.props;

		if (type === "password" || (type === "text" && password_visible === true)) {
			return !password_visible ? (
				<button
					type="button"
					className="visibility-icon"
					tabIndex={-1}
					title={t("formFields:togglePasswordVisibility")}
					onClick={() => this.togglePasswordVisibility()}
				>
					<img
						src={require("../../svg/visibility-icon.svg")}
						alt={t("formFields:togglePasswordVisibility")}
					/>
				</button>
			) : (
				<button
					type="button"
					className="visibility-icon"
					tabIndex={-1}
					title={t("formFields:togglePasswordVisibility")}
					onClick={() => this.togglePasswordVisibility()}
				>
					<img
						src={require("../../svg/visibility-off-icon.svg")}
						alt={t("formFields:togglePasswordVisibility")}
					/>
				</button>
			);
		} else {
			return null;
		}
	}

	render() {
		let newValue, field;
		const { label, id, fieldId, className, fieldClassName, value, edit, inputRef, maxLength } =
			this.props;
		let { type } = this.props;
		const { password_visible, focus } = this.state;

		if (!type) {
			type = "text";
		}

		if (type === "password" && password_visible === true) {
			type = "text";
		}

		if (edit === "false") {
			newValue = value;
			if (type.toLowerCase() === "password") {
				newValue = "";
				for (var i = 0; i < value.length; ++i) {
					newValue += "*";
				}
			}

			field = <span className="noEdit">{newValue}</span>;
		} else if (type === "number-format") {
			field = (
				<NumberFormat
					{...this.props}
					ref={inputRef}
					id={fieldId}
					className={`field ${fieldClassName ? fieldClassName : ""}`}
					onFocus={() => this.focus()}
					onBlur={() => this.blur()}
				/>
			);
		} else {
			field = (
				<input
					{...this.props}
					type={type}
					ref={inputRef}
					id={fieldId}
					className={
						"field " +
						(type === "password" || (type === "text" && password_visible === true)
							? "field--with-control "
							: "") +
						(fieldClassName ? fieldClassName : "")
					}
					onFocus={() => this.focus()}
					onBlur={() => this.blur()}
					maxLength={maxLength || (type.toLowerCase() === "password" ? 40 : maxLength)}
				/>
			);
		}

		return (
			<div id={id} className={`TextField container-fluid ${className ? className : ""}`}>
				<label htmlFor={fieldId} className={`label ${focus ? "focus" : ""}`}>
					{label}
				</label>
				{field}
				{this.renderPasswordEye(type)}
			</div>
		);
	}
}

TextField.propTypes = {
	type: PropTypes.string,
	label: PropTypes.string,
	id: PropTypes.string,
	fieldId: PropTypes.string,
	className: PropTypes.string,
	fieldClassName: PropTypes.string,
	value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
	edit: PropTypes.bool,
	inputRef: PropTypes.element,
	maxLength: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
	autoFocus: PropTypes.bool,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	t: PropTypes.func.isRequired,
};

export default withTranslation()(TextField);
