import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { FormFieldSelect } from '../../../../components/FormFields';
import { updateEvent } from "../../../../actions/Events";
import "../styles.scss";

class ChangeAchievementTypeModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            achievement_id: "",
        }
    }

    componentWillMount() {
        this.setState({
            achievement_id: this.props.achievement_id
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.achievement_id !== nextProps.achievement_id) {
            this.setState({
                achievement_id: nextProps.achievement_id
            })
        }
    }

    renderAchievements() {
        let options = [
            { "label": "** None **", "value": "" },
        ];
        this.props.achievements.map(data => {
            options.push({ "label": data.name, "value": data.id });
        });

        return options;
    }


    getAchievementId() {
        let achievement = this.props.achievements.find(item => item.id == this.state.achievement_id);
        if (achievement) {
            return achievement.id;
        } else {
            return "";
        }
    }

    handleSubmit = () => {
        this.props.updateEvent({
            achievement_id: this.state.achievement_id
        }, this.props.eventId);
    };

    render() {
        return (

            <Modal className="admin"
                triggerBtnText="Change"
                triggerBtnClassName="button button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Save"
                submitDisabled={this.state.achievement_id === this.props.achievement_id}
                onSubmit={() => this.handleSubmit()}
                onClose={(event) => this.setState({ achievement_id: this.props.achievement_id })}
            >

                <FormFieldSelect
                    intro={"Enter the achievement name of the event"}
                    label={"Achievement Name"}
                    name={"achievement_id"}
                    value={this.getAchievementId()}
                    options={this.renderAchievements()}
                    onChange={(event) => this.setState({ achievement_id: event.target.value })}
                />
            </Modal>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        updateEvent: (params, id) => dispatch(updateEvent(params, id)),
    }
}

const mapStateToProps = state => {
    return {
        achievements: state.achievements.valid_achievements
    }
}

ChangeAchievementTypeModal = connect(mapStateToProps, mapDispatchToProps)(ChangeAchievementTypeModal);

export { ChangeAchievementTypeModal }
