import {
	FETCH_POINT_HISTORIES_WATCHER,
	SET_POINT_HISTORIES,
	CLEAR_POINT_HISTORIES,
} from "../constants/ActionTypes";

export const fetchPointHistories = (userId, seasonName, options = {}) => ({
	type: FETCH_POINT_HISTORIES_WATCHER,
	payload: {
		userId,
		seasonName,
	},
	options,
});

export const setPointHistories = (pointHistories) => {
	return {
		type: SET_POINT_HISTORIES,
		payload: pointHistories,
	};
};

export const clearPointHistories = () => {
	return {
		type: CLEAR_POINT_HISTORIES,
	};
};
