import { setUserProfile } from "../../actions/User";
import { takeLatest, call, put, select } from "redux-saga/effects";
import { UPDATE_USER_WATCHER } from "../../constants/ActionTypes";
import { USER_SERVICE_UPDATE_URL } from "../../constants/Url";
import { addMessage } from "../../actions/Messages";
import { appendNotifications } from "../../actions/Notifications";
import { USER_UPDATE, ERROR, SUCCESS } from "../../constants/Notifications";
import api from "../../util/api";
import { getActiveLanguage } from "../../util/Language";

import { onUserLanguageChangedWorker } from "./ChangeLanguageSaga";

const fetchUserProfile = (state) => state.user.profile;

function userUpdateApi(payload, id) {
	let token = window.browserStorage.getJWT();
	return api
		.post(
			USER_SERVICE_UPDATE_URL(id),
			{
				...payload.params,
				id,
			},
			{
				token,
			}
		)
		.catch((err) => {
			return err.response.data;
		});
}

export function* userUpdateWatcher() {
	yield takeLatest(UPDATE_USER_WATCHER, userUpdateWorker);
}

function* userUpdateWorker(action) {
	try {
		let user = yield select(fetchUserProfile);

		let response = yield call(userUpdateApi, action.payload, user.id);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (isSuccess) {
			const userProfile = response.data.data;
			if (userProfile?.lang !== getActiveLanguage()) {
				yield call(onUserLanguageChangedWorker, { payload: { lang: userProfile.lang } });
			}
			yield put(setUserProfile(userProfile));
		}

		if (options?.message) {
			yield put(
				addMessage({
					source: UPDATE_USER_WATCHER,
					message: response?.msg,
					isError: !isSuccess,
					status: response.status,
				})
			);
		}

		if (options?.notification) {
			yield put(
				appendNotifications(USER_UPDATE(isSuccess ? SUCCESS : ERROR, options?.notification))
			);
		}
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		}
	} catch (e) {
		console.log(e);
	}
}
