import {
    SET_CAMPAIGNS_BANK,
    SET_PUBLIC_CAMPAIGN,
    SET_UNAUTHORIZED_CAMPAIGN
} from "../constants/ActionTypes";

const campaignsInitialState = {
    bank: [],
    campaign: null,
    unauthorizedCampaign: {}
};

export default function campaignsReducer(state = campaignsInitialState, action) {
    switch (action.type) {
        case SET_CAMPAIGNS_BANK:
            return {
                ...state,
                ...action.payload,
            };
        case SET_PUBLIC_CAMPAIGN:
            return {
                ...state,
                ...action.payload,
            };
        case SET_UNAUTHORIZED_CAMPAIGN:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    };
};