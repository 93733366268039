import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./styles.scss";
import { fetchUserOrders } from "../../../actions/Orders";
import LpOrder from "../../../components/LpOrder";

class OrderDetails extends Component {
	constructor(props) {
		super(props);
	}

	componentDidUpdate() {
		if (!this.props.orders) {
			this.props.fetchUserOrders(this.props.match.params.id);
		}
	}

	render() {
		const order = this.props.orders?.find(
			(order) => order.id === this.props.match.params.id
		);

		return (
			<div className="OrderDetails admin">
				<div className="options-bar">
					<Link
						className="options-back"
						to={`/app/fulfillment/orders/`}
					>
						<button className="button button--borderless">
							<span className="button__icon zmdi zmdi-chevron-left zmdi-hc-lg" />{" "}
							Back
						</button>
					</Link>
				</div>
				{order ? <LpOrder order={order} env={"admin"} /> : null}
			</div>
		);
	}
}

OrderDetails.propTypes = {
	orders: PropTypes.array,
	fetchUserOrders: PropTypes.func,
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string,
		}),
	}),
	history: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchUserOrders: (payload) => dispatch(fetchUserOrders(payload)),
	};
};

const mapStateToProps = (state) => {
	return {
		orders: state.orders.bank,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
