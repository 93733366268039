import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import { SIGNUP_NOTIFY } from "../../constants/ActionTypes";
import { SIGNUP_NOTIFY_URL } from "../../constants/Url";
import { SUCCESS, ERROR } from "../../constants/Notifications";
import { appendNotifications } from "../../actions/Notifications";
import { addMessage } from "../../actions/Messages";

const Axios = require("axios");

export function* signupNotifyWatcher() {
	yield takeLatest(SIGNUP_NOTIFY, signupNotifyWorker);
}

/**
 * @param {String} action.params.email The email of the user to send to the notification mailing list.
 *
 * @param {Object} [action.options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [action.options.success] Behavior to execute when API call and action is successful.
 * @param {String} [action.options.success.redirect] Redirect to this URL on success.
 * @param {String} [action.options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [action.options.success.notification] Display notification with passed text on success.
 * @param {Function} [action.options.success.callback] Callback function on success.
 *
 * @param {Object} [action.options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [action.options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [action.options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [action.options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [action.options.failure.callback] Callback function on failure.
 */
function* signupNotifyWorker(action) {
	try {
		const response = yield call(signupNotifyApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;

		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.message) {
			yield put(
				addMessage({
					source: SIGNUP_NOTIFY,
					message: options.message,
					isError: isSuccess,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, response);
		}
		if (options?.redirect) {
			yield put(push(options.redirect));
		}
	} catch (e) {
		console.error(e);
	}
}

/**
 * Sends an API request to add the users email to a mailing list to be notified later when registration enables.
 * @param {String} params.email The email of the user to send.
 * @return {Object} The response object from the API call.
 */
function signupNotifyApi(params) {
	Axios.defaults.headers.common["Authorization"] = window.browserStorage.getJWT();
	return Axios.post(SIGNUP_NOTIFY_URL, { ...params })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
