import React from "react";
import PropTypes from "prop-types";
import LpIconPencil from "../../../../../icons/LpIconPencil";
import LpTooltip from "../../../../../LpTooltip";

export default function EditLinkButton({ onClick }) {
	return (
		<button onClick={onClick} className="lp-rich-text-editor__button">
			<LpTooltip text="Edit Link">
				<LpIconPencil alt="Pencil icon" title="Edit link" />
			</LpTooltip>
		</button>
	);
}

EditLinkButton.propTypes = {
	onClick: PropTypes.func,
};
