import { all } from "redux-saga/effects";

import { linkShopifyAccountWatcher, verifyShopifyAccountWatcher } from "./ShopifyLinkingSagas";
import { setSocialPlatformVisibilityWatcher } from "./setSocialVisibilitySagas";

export function* socialLinkingSagas() {
	yield all([
		linkShopifyAccountWatcher(),
		verifyShopifyAccountWatcher(),
		setSocialPlatformVisibilityWatcher(),
	]);
}
