import {
	FETCH_QUEST_TRANSLATIONS_STATUS_WATCHER,
	SET_QUEST_TRANSLATIONS_STATUS,
	FETCH_QUEST_TRANSLATIONS_WATCHER,
	SET_QUEST_TRANSLATIONS,
	PATCH_QUEST_TRANSLATIONS_WATCHER,
	PATCH_QUEST_TRANSLATIONS_PUBLISHED_STATUS_WATCHER,
	UPDATE_QUEST_TRANSLATIONS_PUBLISHED_STATUS,
} from "../constants/ActionTypes";

export const fetchQuestTranslations = (payload) => {
	return {
		type: FETCH_QUEST_TRANSLATIONS_WATCHER,
		payload,
	};
};

export const setQuestTranslation = (payload) => {
	return {
		type: SET_QUEST_TRANSLATIONS,
		payload,
	};
};

export const fetchQuestTranslationsStatus = (payload) => {
	return {
		type: FETCH_QUEST_TRANSLATIONS_STATUS_WATCHER,
		payload,
	};
};

export const setQuestTranslationStatus = (payload) => {
	return {
		type: SET_QUEST_TRANSLATIONS_STATUS,
		payload,
	};
};

export const patchQuestTranslations = (payload) => {
	return {
		type: PATCH_QUEST_TRANSLATIONS_WATCHER,
		payload,
	};
};

export const patchQuestTranslationsPublishedStatus = (payload) => {
	return {
		type: PATCH_QUEST_TRANSLATIONS_PUBLISHED_STATUS_WATCHER,
		payload,
	};
};

export const updateQuestTranslationPublishedStatus = (payload) => {
	return {
		type: UPDATE_QUEST_TRANSLATIONS_PUBLISHED_STATUS,
		payload,
	};
};