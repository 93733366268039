import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Packages
import QRCode from "qrcode.react";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";

// Structure
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

//Constants
import { EVENT_TYPES, EVENT_SUBTYPES } from "../../../constants/Events";

//Custom component
import Modal from "../../../components/Modal";
import LpDropdown from "../../../components/LpDropdown";
import "./styles.scss";

// Static data
import { MockSerializedEvents } from "./MockData";

// Dialogs
import ChangeEventModal from "./modals/event-change";
import { ChangeSubTypeModal } from "./modals/subtype-change";
import { ChangeDateModal } from "./modals/date-change";
import { ChangeSerialEventModal } from "./modals/serial-event-change";
import { ChangeLocationModal } from "./modals/location-change";
import { ChangePointsModal } from "./modals/points-change";
import { ChangeAchievementTypeModal } from "./modals/achievement-type-change";
import ChangeLinkTypeModal from "./modals/link-type-change";
import ChangeRedirectUrlModal from "./modals/redirect-url-change";
import ChangeUrlButtonTextModal from "./modals/url-button-text-change";
import EmailQRCodeModal from "./modals/email-qr-code";

// Actions
import { fetchValidAchievements } from "../../../actions/Achievements";
import { fetchEvent, updateEvent, deleteEvent } from "../../../actions/Events";

// Utils
import { formatDateTimeInternational, dateCompare, utcToLocale } from "../../../util/DateUtil";

class EventView extends React.Component {
	constructor(props) {
		super(props);

		this.serializedEvents = MockSerializedEvents;

		this.state = {
			serial_event: 24,
			signing_link: "",
			signin_enabled: true,
			event: {
				id: null,
				name: null,
				icon_url: null,
				event_type: null,
				sub_type: null,
				start_date: null,
				end_date: null,
				location: null,
				points: null,
				achievement_id: null,
				achievement_name: null,
				serial_event_field: null,
				redirect_url: null,
				is_external_url: null,
				url_button_text: null,
				createdAt: null,
				qr_code: null,
			},
		};
	}

	componentDidMount() {
		const { match, fetchEvent, fetchValidAchievements } = this.props;
		const eventId = match.params.id;

		fetchEvent(eventId);
		fetchValidAchievements();
	}

	componentWillReceiveProps(nextProps) {
		let { event } = nextProps;
		let { start_date } = event;
		let { locked } = this.state;
		let new_locked = false;

		if (dateCompare(Date.now(), start_date) > 0 && !(locked === false)) {
			new_locked = true;
		}

		this.setState({ locked: new_locked });
	}

	componentDidUpdate(prevProps) {
		let { event } = this.props;
		if (event !== prevProps.event) {
			this.setState({ event: event });
		}
	}

	handleCheckInToggle() {
		let { updateEvent, event } = this.props;
		updateEvent(
			{
				is_active: !event.is_active,
			},
			event.id
		);
	}

	handleInputChange(event) {
		const target = event.target;
		let value;
		const name = target.name;

		switch (target.type) {
			case "number":
				value =
					this.validateNumeric(target.value) || target.value === "" ? target.value : 0;
				break;
			case "checkbox":
				value = target.checked;
				break;
			default:
				value = this.validateNumeric(target.value) ? Number(target.value) : target.value;
				value = value === "true" ? true : value;
				value = value === "false" ? false : value;
				break;
		}

		this.setState({
			[name]: value,
		});
	}

	validateNumeric(value) {
		const regex = /^\d+$/;
		return regex.test(value);
	}

	loadSerializedEvent(id) {
		if (id > 0) {
			let event = this.serializedEvents.find((item) => item.value === id);
			if (event) {
				return event.value;
			}
		}
	}

	getDigitalLink(digital_link) {
		return digital_link || "";
	}

	getImageUrl() {
		return (
			<img
				className="icon-locked"
				src={require("../../../svg/slashed-circle.svg")}
				title="Event is running and therefore locked"
				alt="Locked"
			/>
		);
	}

	routeToEventQRPage() {
		let { history, event } = this.props;
		history.push(`/app/events/qr/${event.id}`);
	}

	generateQRCode() {
		let { updateEvent, event } = this.props;
		updateEvent(
			{
				changeQRCode: true,
			},
			event.id
		);
	}

	generateLink() {
		let { updateEvent, event } = this.props;
		updateEvent(
			{
				changeQRCode: true,
			},
			event.id
		);
	}

	handleEventDelete() {
		let { deleteEvent, event } = this.props;
		deleteEvent(event.id);
	}

	renderDigital() {
		let { event } = this.props;
		let { locked } = this.state;
		const { digital_link, is_external_url, url_button_text, redirect_url, id } = event;

		return (
			<React.Fragment>
				<TableRow>
					<TableCell class="data-table__data-cell" width="30%">
						Link type
					</TableCell>
					<TableCell class="data-table__data-cell" width="65%">
						{is_external_url ? "External URL" : "Team Liquid URL"}
					</TableCell>
					<TableCell class="data-table__data-cell" width="5%">
						{locked === false ? (
							<ChangeLinkTypeModal
								eventId={id}
								is_external_url={is_external_url}
								form="chanage-dates-form"
							/>
						) : (
							this.getImageUrl()
						)}
					</TableCell>
				</TableRow>
				{is_external_url ? (
					<React.Fragment>
						<TableRow>
							<TableCell class="data-table__data-cell" width="30%">
								External URL
							</TableCell>
							<TableCell class="data-table__data-cell" width="65%">
								{redirect_url}
							</TableCell>
							<TableCell class="data-table__data-cell" width="5%">
								{locked === false ? (
									<ChangeRedirectUrlModal
										eventId={id}
										redirect_url={redirect_url}
										form="chanage-dates-form"
									/>
								) : (
									this.getImageUrl()
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell class="data-table__data-cell" width="30%">
								Button Text
							</TableCell>
							<TableCell class="data-table__data-cell" width="65%">
								{url_button_text
									? url_button_text.toLowerCase().charAt(0).toUpperCase() +
									  url_button_text.toLowerCase().slice(1)
									: null}
							</TableCell>
							<TableCell class="data-table__data-cell" width="5%">
								{locked === false ? (
									<ChangeUrlButtonTextModal
										eventId={id}
										url_button_text={url_button_text}
										form="chanage-dates-form"
									/>
								) : (
									this.getImageUrl()
								)}
							</TableCell>
						</TableRow>
					</React.Fragment>
				) : (
					<TableRow>
						<TableCell class="data-table__data-cell" width="30%">
							Signing Link
						</TableCell>
						<TableCell class="data-table__data-cell" width="65%">
							{digital_link && this.getDigitalLink(digital_link)}
						</TableCell>
						<TableCell
							class="data-table__data-cell data-table__data-cell--long"
							width="36px"
						>
							<LpDropdown position={"bottom-left"}>
								<CopyToClipboard text={this.getDigitalLink(digital_link)}>
									<button className="button button--borderless dropdown_option__button">
										Copy Link
									</button>
								</CopyToClipboard>

								<Modal
									className="admin"
									triggerBtnText="Generate New Link"
									triggerBtnClassName="button button--borderless dropdown_option__button"
									cancelBtnText="Cancel"
									submitBtnText="Generate New Link"
									onSubmit={() => this.generateLink()}
								>
									Are you sure you want to generate a new check-in Link? You
									should only proceed in abuse cases.
								</Modal>
							</LpDropdown>
						</TableCell>
					</TableRow>
				)}
			</React.Fragment>
		);
	}

	renderOffline() {
		let { event } = this.state;
		const { id, qr_code } = event;

		return (
			<TableRow>
				<TableCell class="data-table__data-cell data-table__data-cell--long" width="30%">
					QR Code
				</TableCell>
				<TableCell class="data-table__data-cell data-table__data-cell--long" width="65%">
					{qr_code ? (
						<QRCode
							value={qr_code}
							size={128}
							bgColor={"#ffffff"}
							fgColor={"#000000"}
							level={"L"}
							includeMargin={false}
							renderAs={"svg"}
						/>
					) : null}
				</TableCell>
				<TableCell class="data-table__data-cell data-table__data-cell--long" width="36px">
					<LpDropdown position={"bottom-left"}>
						<EmailQRCodeModal eventId={id} />
						<button
							className="button dropdown_option__button"
							onClick={() => {
								this.routeToEventQRPage();
							}}
						>
							Display Event Page
						</button>
						<Modal
							className="admin"
							triggerBtnText="Generate new Code"
							triggerBtnClassName="button dropdown_option__button"
							cancelBtnText="Cancel"
							submitBtnText="Generate New QR Code"
							onSubmit={() => this.generateQRCode()}
						>
							Are you sure you want to generate a new check-in QR code? You should
							only proceed in abuse cases.
						</Modal>
					</LpDropdown>
				</TableCell>
			</TableRow>
		);
	}

	render() {
		let { event, locked } = this.state;

		let {
			id,
			name,
			icon_url,
			event_type,
			sub_type,
			start_date,
			location,
			points,
			achievement_id,
			achievement_name,
			serial_event_field,
			createdAt,
			is_active,
			end_date,
		} = event;

		return locked !== undefined ? (
			<div className="m-3 EventView admin">
				<div className="options-bar">
					<Link className="options-back" to={`/app/events/`}>
						<button className="button button--borderless">
							<i className="button__icon zmdi zmdi-chevron-left zmdi-hc-lg" /> Back
						</button>
					</Link>
				</div>

				<Table className="data-table data-table--page-part">
					<TableBody>
						<TableRow>
							<TableCell
								className="data-table__data-cell data-table__data-cell--main"
								width="90%"
							>
								<div className="data-table__multi-line">
									{icon_url ? (
										<img
											className="data-table__inline-image"
											src={icon_url}
											alt={name}
										/>
									) : (
										<div className="data-table__inline-image icon--colored"></div>
									)}
									<div>
										<span className="data-table__item-title">{name}</span>
										<br />
										<span className="data-table__item-sub-title">
											{event_type === "OFFLINE" ? "Offline" : "Digital"} event
										</span>
									</div>
								</div>
							</TableCell>
							<TableCell className="data-table__data-cell" width="5%">
								{locked === false ? (
									<ChangeEventModal
										eventId={id}
										icon_url={icon_url}
										name={name}
										event_type={event_type}
										form="chanage-event-form"
									/>
								) : (
									this.getImageUrl()
								)}
							</TableCell>
							<TableCell className="data-table__data-cell" width="5%">
								<LpDropdown className="context" position={"bottom-left"}>
									<button
										className="button dropdown_option__button"
										onClick={() => this.handleCheckInToggle()}
									>
										{(is_active ? "Disable" : "Enable") + " Check-in"}
									</button>
									<Modal
										className="admin delete-event-modal"
										triggerBtnText="Delete Event"
										triggerBtnClassName="button dropdown_option__button"
										cancelBtnText="Cancel"
										submitBtnText="Delete"
										onSubmit={() => this.handleEventDelete()}
									>
										Are you sure you want to delete this Event?
									</Modal>
									{locked === true ? (
										<Modal
											className="admin unlock-event-modal"
											triggerBtnText="Unlock Event"
											triggerBtnClassName="button dropdown_option__button"
											cancelBtnText="Cancel"
											submitBtnText="Unlock"
											onSubmit={() =>
												this.setState({
													locked: false,
												})
											}
										>
											<span className="unlock-event-modal__warning">
												Warning!{" "}
											</span>
											This event is already running. <br />
											Changing an event that has already started may lead to
											unexpected behavior. Do you really want to unlock this
											event?
										</Modal>
									) : null}
								</LpDropdown>
								{/* )} */}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
				<span class="data-table__note">
					Added on {moment(createdAt).format("MMM DD,YYYY")}
				</span>

				{/* Subtype and Serial Event */}
				<Table className="data-table data-table--page-part">
					<TableBody>
						<TableRow>
							<TableCell class="data-table__data-cell" width="30%">
								Event Subtype
							</TableCell>
							<TableCell class="data-table__data-cell" width="65%">
								{sub_type && sub_type === "STANDALONE"
									? "Standalone"
									: "Serialized"}
							</TableCell>
							<TableCell class="data-table__data-cell" width="5%">
								{locked === false ? (
									<ChangeSubTypeModal
										eventId={id}
										sub_type={sub_type}
										form="chanage-subtype-form"
									/>
								) : (
									this.getImageUrl()
								)}
							</TableCell>
						</TableRow>
						{sub_type && sub_type === "SERIALIZED" ? (
							<TableRow>
								<TableCell class="data-table__data-cell" width="30%">
									Serial Event
								</TableCell>
								<TableCell class="data-table__data-cell" width="65%">
									{serial_event_field > 0 &&
										this.loadSerializedEvent(serial_event_field)}
								</TableCell>
								<TableCell class="data-table__data-cell" width="5%">
									{locked === false ? (
										<ChangeSerialEventModal
											eventId={id}
											serial_event_field={serial_event_field}
											form="chanage-serial-form"
										/>
									) : (
										this.getImageUrl()
									)}
								</TableCell>
							</TableRow>
						) : null}
					</TableBody>
				</Table>

				{/* Points */}
				<Table className="data-table data-table--page-part">
					<TableBody>
						<TableRow>
							<TableCell class="data-table__data-cell" width="30%">
								Points
							</TableCell>
							<TableCell class="data-table__data-cell" width="65%">
								{points ? points : ""}
							</TableCell>
							<TableCell class="data-table__data-cell" width="5%">
								{locked === false ? (
									<ChangePointsModal
										eventId={id}
										points={points}
										form="chanage-points-form"
									/>
								) : (
									this.getImageUrl()
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell class="data-table__data-cell" width="30%">
								Achievement
							</TableCell>
							<TableCell class="data-table__data-cell" width="65%">
								{achievement_name ? achievement_name : ""}
							</TableCell>
							<TableCell class="data-table__data-cell" width="5%">
								{locked === false ? (
									<ChangeAchievementTypeModal
										eventId={id}
										achievement_id={achievement_id}
										form="chanage-achievement-form"
									/>
								) : (
									this.getImageUrl()
								)}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>

				{/* Dates and Location */}
				<Table className="data-table data-table--page-part">
					<TableBody>
						<TableRow>
							<TableCell class="data-table__data-cell" width="30%">
								Dates
							</TableCell>
							<TableCell class="data-table__data-cell" width="65%">
								{formatDateTimeInternational(utcToLocale(start_date))} -{" "}
								{formatDateTimeInternational(utcToLocale(end_date))}
							</TableCell>
							<TableCell class="data-table__data-cell" width="5%">
								{locked === false ? (
									<ChangeDateModal
										eventId={id}
										start_date={start_date}
										end_date={end_date}
										form="chanage-dates-form"
									/>
								) : (
									this.getImageUrl()
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell class="data-table__data-cell" width="30%">
								Location
							</TableCell>
							<TableCell class="data-table__data-cell" width="65%">
								{location ? location : ""}
							</TableCell>
							<TableCell class="data-table__data-cell" width="5%">
								{locked === false ? (
									<ChangeLocationModal
										eventId={id}
										location={location}
										form="chanage-location-form"
									/>
								) : (
									this.getImageUrl()
								)}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>

				{/* Digital OR Offline */}
				<Table className="data-table data-table--page-part">
					<TableBody>
						{event_type && event_type === "DIGITAL"
							? this.renderDigital()
							: this.renderOffline()}
					</TableBody>
				</Table>
			</div>
		) : null;
	}
}

EventView.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.string.isRequired,
		}),
	}),
	fetchEvent: PropTypes.func.isRequired,
	fetchValidAchievements: PropTypes.func.isRequired,
	event: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		icon_url: PropTypes.string.isRequired,
		event_type: PropTypes.oneOf([EVENT_TYPES.DIGITAL, EVENT_TYPES.OFFLINE]).isRequired,
		sub_type: PropTypes.oneOf([EVENT_SUBTYPES.SERIALIZED, EVENT_SUBTYPES.STANDALONE])
			.isRequired,
		start_date: PropTypes.instanceOf(Date).isRequired,
		end_date: PropTypes.instanceOf(Date).isRequired,
		location: PropTypes.string,
		points: PropTypes.number,
		achievement_id: PropTypes.string,
		achievement_name: PropTypes.string,
		serial_event_field: PropTypes.string,
		is_external_url: PropTypes.bool,
		url_button_text: PropTypes.string,
		redirect_url: PropTypes.string,
		createdAt: PropTypes.instanceOf(Date).isRequired,
		is_active: PropTypes.bool,
		digital_link: PropTypes.string,
	}),
	updateEvent: PropTypes.func.isRequired,
	deleteEvent: PropTypes.func.isRequired,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
};

const mapStateToProps = (state) => {
	return {
		event: state.events.event,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchValidAchievements: () => dispatch(fetchValidAchievements()),
		fetchEvent: (eventId) => dispatch(fetchEvent(eventId)),
		updateEvent: (params, id) => dispatch(updateEvent(params, id)),
		deleteEvent: (eventId) => dispatch(deleteEvent(eventId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EventView);
