import { SET_GAMES_PUBLIC_INFO_WATCHER } from "../constants/ActionTypes";

const sandboxInitialState = {
	gamesPublicInfo: [],
};

export default function GamesReducer(state = sandboxInitialState, action) {
	switch (action.type) {
		case SET_GAMES_PUBLIC_INFO_WATCHER: {
			return {
				...state,
				gamesPublicInfo: action.payload,
			};
		}
		default: {
			return state;
		}
	}
}
