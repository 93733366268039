import {
    all
} from 'redux-saga/effects';

import {
    fetchTiersWatcher
} from './FetchTiersSagas'

import {
    updateTierWatcher
} from './UpdateTierSaga'


export function* tiersSagas() {
    yield all([
        fetchTiersWatcher(),
        updateTierWatcher()
    ]);
}