import React from "react";
import PropTypes from "prop-types";
import { formatNumber } from "../../../../util/stringUtils";
import "./styles.scss";

function OrderItem({ reward, order }) {
	return (
		<div className="order_item">
			{reward && (
				<img
					title={reward.name}
					src={reward.images.all[0]}
					className={"order_item__image"}
					alt="reward_image"
				/>
			)}

			<div className="order_item__description">
				<div className="order_item__name">
					{order.items.length && order.items[0].name}
				</div>
				<div className="order_item__points">
					{order.items.length
						? formatNumber(order.items[0].point_count)
						: 0}{" "}
					points
				</div>
			</div>
			<span className={"order_item__count"}>
				{order.items[0].quantity} Item
				{order.items[0].quantity > 1 && "s"}
			</span>
		</div>
	);
}

OrderItem.propTypes = {
	order: PropTypes.shape({
		items: PropTypes.array,
	}),
	reward: PropTypes.shape({
		name: PropTypes.string,
		images: PropTypes.array,
	}),
};

export default OrderItem;
