import {
    showLoader,
    hideLoader
} from '../../actions/GlobalAction'
import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';
import {
    appendNotifications
} from '../../actions/Notifications'
import {
    LOYALTY_EVENTS,
    ERROR,
    SUCCESS
} from '../../constants/Notifications'
import {
    INSTANT_PROCESS_POINTS_WATCHER
} from '../../constants/ActionTypes'
import {
    LOYALTY_PROCESS_EVENTS
} from '../../constants/Url'

import api from '../../util/api';

function processEventsApi(services) {
    return (
        api
        .post(LOYALTY_PROCESS_EVENTS, {
            services
        })

    )
}

export function* processEventsWatcher() {
    yield takeLatest(INSTANT_PROCESS_POINTS_WATCHER, processEventsWorker);
}

function* processEventsWorker(action) {
    try {
        yield put(showLoader());
        // data is obtained after axios call is resolved
        let response = yield call(processEventsApi, action.payload.map(e => e.raw));
        if (response.data.status >= 200 && response.data.status < 300) {
            const display_events = rawServicesToDisplay(action.payload, response.data.data)
            yield put(appendNotifications(LOYALTY_EVENTS(SUCCESS, display_events)))
        } else {
            throw (response)
        }
        yield put(hideLoader());
    } catch (e) {
        console.log(e)
        yield put(hideLoader());
        yield put(appendNotifications(LOYALTY_EVENTS(ERROR)))
    }
}

const rawServicesToDisplay = (payload, success) => {
    return payload
        .filter(event => success.includes(event.raw))
        .map(event => event.display)
}