import {
	SET_REWARDS_BANK,
	SELECT_REWARD,
	CREATE_REWARD_WATCHER,
	FETCH_REWARDS_WATCHER,
	FETCH_REWARD_DETAILS_WATCHER,
	FETCH_SHOPIFY_ITEMS_WATCHER,
	SET_POTENTIAL_REWARDS,
	APPEND_POTENTIAL_REWARDS,
	SET_NEW_REWARD,
	PUSH_NEW_REWARD,
	REPLACE_NEW_REWARD,
	PURCHASE_REWARD_WATCHER,
	SET_SUCCESFUL_ORDER,
	REMOVE_TEMPORARY_CONFIRMATION,
	SEARCH_REWARDS_BY_ITEM,
	UPDATE_REWARDS_WATCHER,
	FETCH_RELATED_REWARDS_WATCHER,
} from "../constants/ActionTypes";

export const setNewReward = (arg, value) => {
	return {
		type: SET_NEW_REWARD,
		payload: {
			arg,
			value,
		},
	};
};

export const pushNewReward = (arg, value) => {
	return {
		type: PUSH_NEW_REWARD,
		payload: {
			arg,
			value,
		},
	};
};

export const replaceNewReward = (form) => {
	return {
		type: REPLACE_NEW_REWARD,
		payload: form,
	};
};

export const selectReward = (reward) => {
	return {
		type: SELECT_REWARD,
		payload: reward,
	};
};

export const createReward = (type, params) => {
	return {
		type: CREATE_REWARD_WATCHER,
		payload: {
			type,
			params,
		},
	};
};

export const fetchRewards = (params, options) => {
	return {
		type: FETCH_REWARDS_WATCHER,
		params,
		options,
	};
};

export const fetchRewardDetails = (params, options) => {
	return {
		type: FETCH_REWARD_DETAILS_WATCHER,
		params,
		options,
	};
};

export const fetchRelatedRewards = (params, options) => {
	return {
		type: FETCH_RELATED_REWARDS_WATCHER,
		params,
		options,
	};
};

export const setRewardsBank = (rewards) => {
	return {
		type: SET_REWARDS_BANK,
		payload: rewards,
	};
};

export const updateRewards = (params, options) => {
	return {
		type: UPDATE_REWARDS_WATCHER,
		payload: params,
		options,
	};
};

export const fetchShopifyItems = (conditions) => {
	return {
		type: FETCH_SHOPIFY_ITEMS_WATCHER,
		payload: conditions,
	};
};

export const setPotentialRewards = (rewards) => {
	return {
		type: SET_POTENTIAL_REWARDS,
		payload: rewards,
	};
};

export const appendPotentialRewards = (rewards) => {
	return {
		type: APPEND_POTENTIAL_REWARDS,
		payload: rewards,
	};
};

export const purchaseReward = (reward, additionalParams, options) => {
	return {
		type: PURCHASE_REWARD_WATCHER,
		payload: { reward, additionalParams },
		options,
	};
};

export const setSuccessfulOrder = (order) => {
	return {
		type: SET_SUCCESFUL_ORDER,
		order,
	};
};

export const removeTemporaryConfirmation = () => {
	return {
		type: REMOVE_TEMPORARY_CONFIRMATION,
	};
};

export const searchRewards = (items) => {
	return {
		type: SEARCH_REWARDS_BY_ITEM,
		payload: items,
	};
};
