import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { FormFieldRadio } from '../../../../components/FormFields';
import { AGE_RESTRICTION_TYPE } from "../../../../constants/Rewards";
import {
    updateRewards
} from '../../../../actions/Rewards';

import "../styles.scss";

class AgeRestrictionDialog extends React.Component {
    constructor(props) {
        super(props);
    }   
    
    componentWillMount() {
        this.setState({
            age_registration: this.props.age_registration
        });
    }
    
    componentWillReceiveProps(nextProps) {
        this.setState({
            age_registration: nextProps.age_registration,
        })
    }

    handleSubmit = () => {
        this.props.updateRewards({
            id: this.props._id,
            params: {
                age_registration: this.state.age_registration
            }
        });
    };

    handleClose() {
        this.setState({
            age_registration: this.props.age_registration
        });
    }

    checkValidation() {
        return (
            this.state.age_registration == this.props.age_registration
        )
    }

    handleInputChange(event) {
        this.setState({ age_registration: event.target.value });
    }

    render() {
        return (
            <Modal
                className="admin"
                triggerBtnText="Change"
                triggerBtnClassName="button button--secondary button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Save"
                submitDisabled={this.checkValidation()}
                onSubmit={() => this.handleSubmit()}
                onClose={() => this.handleClose()}
            >
                <FormFieldRadio
                    label={"Age Restriction"}                    
                    name={"age_registration"}
                    value={this.state.age_registration}
                    options={AGE_RESTRICTION_TYPE}
                    onChange={(event) => this.handleInputChange(event)}
                /> 
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateRewards: (payload) => dispatch(updateRewards(payload))
    }
}

const mapStateToProps = state => {
    return {

    }
}

AgeRestrictionDialog = connect(mapStateToProps, mapDispatchToProps)(AgeRestrictionDialog);

export default AgeRestrictionDialog