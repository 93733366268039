import * as React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import SelectField from "../SelectField";
import "./styles.scss";

// Actions
import { fetchCountries } from "../../actions/Countries";

// Translations
import "./translations";

/* Example Usage:
    <CountrySelect
        required
        clearable={this.state.country?.length > 0 ? true : false}
        values={this.state.country}
        onChange={(values) => this.setState({ country: values })}
    />
*/

class CountrySelect extends React.Component {
	componentDidMount() {
		const { fetchCountries } = this.props;
		fetchCountries();
	}
	render() {
		const { label, name, countries, t } = this.props;
		const countriesLabel = countries.map((country) => ({
			label: country.label,
			value: country.code,
		}));

		return (
			<div className="CountrySelect">
				{countriesLabel.length && (
					<SelectField
						{...this.props}
						label={label || t("formFields:country.label")}
						name={name || "Country"}
						options={countriesLabel}
						searchable={true}
						noDataRenderer={() => (
							<span className="react-dropdown-select-item">{t("noData")}</span>
						)}
					/>
				)}
			</div>
		);
	}
}

CountrySelect.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	countries: PropTypes.arrayOf(
		PropTypes.shape({
			code: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})
	),
	fetchCountries: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		countries: state.countries,
	};
};
const mapDispatchToProps = {
	fetchCountries,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation("countrySelect")
)(CountrySelect);
