import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import { FETCH_TWITTER_CHANNEL_COUNT } from "../../../constants/ActionTypes";
import { GET_TWITTER_CHANNEL_COUNT } from "../../../constants/Url";
import { SUCCESS, ERROR } from "../../../constants/Notifications";
import { appendNotifications } from "../../../actions/Notifications";
import { addMessage } from "../../../actions/Messages";
import { setTwitterChannelCount } from "../../../actions/Channel";

import api from "../../../util/api";

export function* fetchTwitterChannelCountWatcher() {
	yield takeLatest(FETCH_TWITTER_CHANNEL_COUNT, fetchTwitterCountWorker);
}

function* fetchTwitterCountWorker(action) {
	try {
		const response = yield call(fetchTwitterChannelCountApi);
		const isSuccess = response.status >= 200 && response.status < 300;

		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.message) {
			yield put(
				addMessage({
					source: FETCH_TWITTER_CHANNEL_COUNT,
					message:
						typeof options.message === "string"
							? options.message
							: isSuccess
							? "Success."
							: response?.data?.error || "Error.",
					isError: !isSuccess,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		}
		if (options?.redirect) {
			yield put(push(options.redirect));
		}
		if (isSuccess) {
			yield put(
				setTwitterChannelCount({
					profileCount: response.data.accounts,
					hashTagCount: response.data.hashtags,
				})
			);
		}
	} catch (e) {
		console.error(e);
	}
}

function fetchTwitterChannelCountApi() {
	return api
		.get(GET_TWITTER_CHANNEL_COUNT)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
