import { setUserProfile, updateUserProfile } from "../actions/User";
import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import pick from "lodash/pick";

import { REFRESH_PROFILE_WATCHER } from "../constants/ActionTypes";
import { appendNotifications } from "../actions/Notifications";
import { USER_REFRESH, ERROR } from "../constants/Notifications";
import { USER_SERVICE_USER_URL } from "../constants/Url";
import { SIGNIN } from "../constants/RoutesURLs";
import api from "../util/api";
import { getActiveLanguage } from "../util/Language";

import { onUserLanguageChangedWorker } from "./UserSagas/ChangeLanguageSaga";

export function* refreshUserWatcher() {
	yield takeLatest(REFRESH_PROFILE_WATCHER, refreshUserWorker);
}

function* refreshUserWorker(action) {
	try {
		const response = yield call(refreshUserApi);
		const isSuccess = response.status >= 200 && response.status < 300;

		if (isSuccess) {
			const userProfile = response.data.data;
			if (userProfile?.lang !== getActiveLanguage()) {
				yield call(onUserLanguageChangedWorker, { payload: { lang: userProfile.lang } });
			}
			if (action?.params) {
				const propertiesToRefresh = pick(userProfile, action.params);
				yield put(updateUserProfile(propertiesToRefresh));
			} else {
				yield put(setUserProfile(userProfile)); // Legacy behavior.
			}
		} else {
			throw response;
		}

		if (action?.options?.callback) {
			yield call(action.options.callback, action.options.callbackArgs);
		}
		if (action?.options?.redirect) {
			yield put(push(action.options.redirect));
		}
	} catch (e) {
		console.error(e);
		window.browserStorage.removeJWT();
		yield put(appendNotifications(USER_REFRESH(ERROR)));
		yield put(push(SIGNIN));
	}
}

function refreshUserApi() {
	return api
		.get(USER_SERVICE_USER_URL)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
