import {
	ON_HIDE_LOADER,
	ON_SHOW_LOADER,
	USER_RIGHTS,
	USER_INFO_STATE,
} from "../constants/ActionTypes";

export const showLoader = () => {
	return {
		type: ON_SHOW_LOADER,
	};
};

export const hideLoader = () => {
	return {
		type: ON_HIDE_LOADER,
	};
};

export function setRights(rights) {
	return {
		type: USER_RIGHTS,
		payload: rights,
	};
}

export function unsetRights() {
	return {
		type: USER_RIGHTS,
		payload: [],
	};
}

export function setUserInfo(userInfo) {
	return {
		type: USER_INFO_STATE,
		payload: userInfo,
	};
}

export function unsetUserInfo() {
	return {
		type: USER_INFO_STATE,
		payload: {},
	};
}
