import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Paper from "@material-ui/core/Paper";

/*
	DOCUMENT INTRODUCTION
 	Custom form field components
	Author: Luuk Barten, Max Martyn
*/

/*
 	> FormFieldInput		//  Default single line input field, type can be "text" or "number".
*/

/*
    USAGE

    Component must reside in an (grand)parent element with class "admin".

    Most components take the following arguments
    > intro                 //  Introduction text rendered before the label.
    > label                 //  Label above the FormField.
    > value                 //  The current value (usually set to the value in state).
    > id                    //  Will be rendered as the label "for" attribute and the input element's "id".
    > placeholder           //  Placeholder with example value.
    > pattern               //  HTML patter attribute to make user of pattern detection.
    > maxlength             //  Max number of characters that can be entered.
    > onChange              //  Will be called with an event object as payload.
*/

/*
  HANDLING ONCHANGE
  See /src/app/routes/AdminQuests/QuestCreate or /src/app/routes/AdminQuests/QuestView pages for examples how to handle onChange.
*/

function FormFieldInput({
	className,
	wide,
	intro,
	name,
	label,
	required,
	form,
	type,
	value,
	placeholder,
	pattern,
	maxlength,
	onChange,
	onKeyPress,
	help,
	hint,
	fluid,
	disable,
}) {
	return (
		<div
			className={classnames("form-field", className, {
				"form-field--wide": wide,
				"form-field--fluid": fluid,
			})}
		>
			{intro && <div className="form-field__intro">{intro}</div>}

			<label className="form-field__label" htmlFor={name}>
				{label}
			</label>
			<input
				required={required}
				className="form-field__input"
				form={form}
				type={type}
				name={name}
				value={value === null || value === undefined ? "" : value}
				id={name}
				placeholder={placeholder}
				pattern={pattern}
				maxLength={maxlength}
				onChange={onChange}
				onKeyPress={onKeyPress}
				disabled={disable || false}
			/>

			{hint && <span className="form-field__hint">{hint}</span>}

			{help && (
				<span className="form-field__help">
					<Paper className="form-field__help-message">{help}</Paper>
				</span>
			)}
		</div>
	);
}

FormFieldInput.propTypes = {
	className: PropTypes.string,
	wide: PropTypes.bool,
	intro: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onKeyPress: PropTypes.func,
	disable: PropTypes.bool,
	required: PropTypes.bool,
	form: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	maxlength: PropTypes.number,
	hint: PropTypes.string,
	type: PropTypes.oneOf(["text", "number"]),
	pattern: PropTypes.string,
	help: PropTypes.string,
	fluid: PropTypes.bool,
};

export default FormFieldInput;
