import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { FormFieldSelect } from '../../../../components/FormFields';
import { MARKET_PLACE_TYPE } from "../../../../constants/Rewards";
import { updateRewards } from '../../../../actions/Rewards';
import "../styles.scss";

class MarketPlaceDialog extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.setState({
            market_place: this.props.market_place
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            market_place: nextProps.market_place,
        })
    }

    handleSubmit = () => {
        this.props.updateRewards({
            id: this.props._id,
            params: {
                market_place: this.state.market_place
            }
        });
    };

    handleClose() {
        this.setState({
            market_place: this.props.market_place
        });
    }

    checkValidation() {
        return (
            this.state.market_place == this.props.market_place
        )
    }

    handleInputChange(event) {
        this.setState({ market_place: event.target.value });
    }

    render() {
        return (
            <Modal
                className="admin"
                triggerBtnText="Change"
                triggerBtnClassName="button button--secondary button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Save"
                submitDisabled={this.checkValidation()}
                onSubmit={() => this.handleSubmit()}
                onClose={() => this.handleClose()}
            >
                <FormFieldSelect
                    label={"Marketplace"}
                    name={"market_place"}
                    value={this.state.market_place}
                    options={MARKET_PLACE_TYPE}
                    onChange={(event) => this.handleInputChange(event)}
                />
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateRewards: (payload) => dispatch(updateRewards(payload))
    }
}

const mapStateToProps = state => {
    return {

    }
}

MarketPlaceDialog = connect(mapStateToProps, mapDispatchToProps)(MarketPlaceDialog);

export default MarketPlaceDialog