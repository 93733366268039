import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";

import CustomScrollbars from "../../util/CustomScrollbars";
import Authorize from "../../auth/Authorize";
import {
	ADMIN_USERS_COUNT,
	ADMIN_USERS_SEARCH,
	ADMIN_STAFF_ALL,
	ADMIN_USER_ACCOUNT,
	ADMIN_BANS_ALL,
	ADMIN_BAN_USER,
	ADMIN_UNBAN_USER,
} from "../../constants/Api";
import {
	ADMIN,
	BASIC_CUSTOMER_SERVICE,
	DIGITAL_MARKETING,
	ESSENTIAL_CUSTOMER_SERVICE,
	MASTER_CUSTOMER_SERVICE,
} from "../../constants/UserRoles";

import { refreshProfile } from "../../actions/User";
import { fetchOrders } from "../../actions/Orders";
import { ORDER_TYPES } from "../../constants/Rewards";

import "./styles.scss";

const openSubMenu = (el) => {
	let parentEl = el.currentTarget.parentNode;
	parentEl.classList.toggle("open");
};

class SidenavContent extends Component {
	componentDidMount() {
		const { refreshProfile, fetchOrders } = this.props;
		refreshProfile();
		fetchOrders(ORDER_TYPES.PENDING, 1, 10, "createDate", "desc");
	}

	renderParentNotification(child, condition) {
		return <div className={condition ? "notify" : null}>{child}</div>;
	}

	renderChildNotification(child, condition) {
		return <div className={condition ? "dot" : null}>{child}</div>;
	}

	render() {
		const { orders, user } = this.props;

		let pending_custom_orders = orders?.filter(
			(order) =>
				order.type === "CUSTOM" && (!order.has_been_contacted || !order.has_been_fulfilled)
		).length;

		if (user?.role) {
			const user_role = user.role.type;
			return (
				<CustomScrollbars className="scrollbar">
					<ul className="nav-menu">
						<li className="nav-header">SERVICE</li>
						<li className="menu no-arrow">
							<NavLink to="/app/admin">
								<i className="zmdi zmdi-home zmdi-hc-fw" />
								<span className="nav-text">Home </span>
							</NavLink>
						</li>

						{/* Content */}
						{[ADMIN, MASTER_CUSTOMER_SERVICE, DIGITAL_MARKETING].includes(user_role) ? (
							<li className="menu collapse-box">
								<Button href="javascript:void(0)" onClick={openSubMenu}>
									<i className="zmdi zmdi-globe zmdi-hc-fw" />
									<span className="nav-text">Content</span>
								</Button>
								<ul className="sub-menu">
									<li>
										<NavLink to="/app/servicesettings/rewards">
											<span className="nav-text">Rewards</span>
										</NavLink>
									</li>
									<li>
										<NavLink to="/app/servicesettings/experiences">
											<span className="nav-text">Experience</span>
										</NavLink>
									</li>
									<li>
										<NavLink to="/app/servicesettings/quests">
											<span className="nav-text">Quests</span>
										</NavLink>
									</li>
									<li>
										<NavLink to="/app/servicesettings/achievements">
											<span className="nav-text">Achievements</span>
										</NavLink>
									</li>
								</ul>
							</li>
						) : null}

						{/* Events */}
						{[
							ADMIN,
							DIGITAL_MARKETING,
							BASIC_CUSTOMER_SERVICE,
							ESSENTIAL_CUSTOMER_SERVICE,
							MASTER_CUSTOMER_SERVICE,
						].includes(user_role) ? (
							<li className="menu no-arrow">
								<NavLink to="/app/events">
									<i className="zmdi zmdi-ticket-star zmdi-hc-fw" />
									<span className="nav-text">Events</span>
								</NavLink>
							</li>
						) : null}

						{/* Orders */}
						{[
							ADMIN,
							BASIC_CUSTOMER_SERVICE,
							ESSENTIAL_CUSTOMER_SERVICE,
							MASTER_CUSTOMER_SERVICE,
						].includes(user_role) ? (
							<li className="menu no-arrow">
								<NavLink to="/app/fulfillment/orders">
									<i
										className={
											"zmdi zmdi-card-giftcard zmdi-hc-fw" +
											(pending_custom_orders > 0 ? " attention" : "")
										}
									/>
									<span className="nav-text">Orders</span>
								</NavLink>
							</li>
						) : null}

						{/* Accounts */}
						{[
							ADMIN,
							BASIC_CUSTOMER_SERVICE,
							ESSENTIAL_CUSTOMER_SERVICE,
							MASTER_CUSTOMER_SERVICE,
						].includes(user_role) ? (
							<li className="menu collapse-box">
								<Button href="javascript:void(0)" onClick={openSubMenu}>
									<i className="zmdi zmdi-accounts zmdi-hc-fw" />

									<span className="nav-text">Accounts</span>
								</Button>
								<ul className="sub-menu">
									<Authorize
										allow={[
											ADMIN_USERS_COUNT,
											ADMIN_USERS_SEARCH,
											ADMIN_USER_ACCOUNT,
											ADMIN_BANS_ALL,
											ADMIN_BAN_USER,
											ADMIN_UNBAN_USER,
										]}
									>
										<li>
											<NavLink to="/app/accounts/users">
												<span className="nav-text">Users</span>
											</NavLink>
										</li>
									</Authorize>
									<Authorize allow={[ADMIN_STAFF_ALL]}>
										<li>
											<NavLink to="/app/accounts/staff">
												<span className="nav-text">Staff</span>
											</NavLink>
										</li>
									</Authorize>
									<Authorize allow={[ADMIN_BANS_ALL]}>
										<li>
											<NavLink to="/app/accounts/permissions">
												<span className="nav-text">Permissions</span>
											</NavLink>
										</li>
									</Authorize>
								</ul>
							</li>
						) : null}

						{/* Settings */}
						{[
							ADMIN,
							BASIC_CUSTOMER_SERVICE,
							ESSENTIAL_CUSTOMER_SERVICE,
							MASTER_CUSTOMER_SERVICE,
						].includes(user_role) ? (
							<li className="menu collapse-box">
								<Button href="javascript:void(0)" onClick={openSubMenu}>
									<i className="zmdi zmdi-tune zmdi-hc-fw" />
									<span className="nav-text">Settings</span>
								</Button>
								<ul className="sub-menu">
									<li>
										<NavLink to="/app/settings/tiers">
											<span className="nav-text">Tiers</span>
										</NavLink>
									</li>
									<li>
										<NavLink to="/app/servicesettings/interactions">
											<span className="nav-text">Interactions</span>
										</NavLink>
									</li>
									<li>
										<NavLink to="/app/servicesettings/channels">
											<span className="nav-text">Channels</span>
										</NavLink>
									</li>
									<li>
										<NavLink to="/app/servicesettings/liquipedia/keywords">
											<span className="nav-text">Liquipedia</span>
										</NavLink>
									</li>
								</ul>
							</li>
						) : null}

						{/* Entities */}
						{[
							ADMIN,
							BASIC_CUSTOMER_SERVICE,
							ESSENTIAL_CUSTOMER_SERVICE,
							MASTER_CUSTOMER_SERVICE,
						].includes(user_role) ? (
							<li className="menu collapse-box">
								<Button href="javascript:void(0)" onClick={openSubMenu}>
									<span className="entities">
										<i className="zmdi entities-icon zmdi-hc-fw" />
										<span className="nav-text">Entities</span>
									</span>
								</Button>
								<ul className="sub-menu">
									<li>
										<NavLink to="/app/entities/players">
											<span className="nav-text">Players</span>
										</NavLink>
									</li>
									<li>
										<NavLink to="/app/entities/games">
											<span className="nav-text">Games</span>
										</NavLink>
									</li>
								</ul>
							</li>
						) : null}
						{[ADMIN].includes(user_role) ? (
							<li className="nav-header">MAINTENANCE</li>
						) : null}

						{/* Logs */}
						{[ADMIN].includes(user_role) ? (
							<Authorize allow={[ADMIN_BANS_ALL]}>
								<li className="menu collapse-box">
									<Button
										className="logs-tab"
										href="javascript:void(0)"
										onClick={openSubMenu}
									>
										<i>
											<img
												className="logs-icon"
												src={require("../../svg/list-bullets.svg")}
												alt=""
											/>
										</i>
										<span className="nav-text logs-text">Logs</span>
									</Button>
									<ul className="sub-menu">
										<li>
											<NavLink to="/app/logs/points">
												<span className="nav-text">Points</span>
											</NavLink>
										</li>
										<li>
											<NavLink to="/app/logs/bans">
												<span className="nav-text">Bans</span>
											</NavLink>
										</li>
									</ul>
								</li>
							</Authorize>
						) : null}

						{/* Service Status */}
						{[ADMIN].includes(user_role) ? (
							<li className="menu no-arrow">
								<NavLink to="/app/ServiceStatus">
									<i className="zmdi zmdi-dns zmdi-hc-fw" />
									<span className="nav-text">Service Status </span>
								</NavLink>
							</li>
						) : null}
					</ul>
				</CustomScrollbars>
			);
		} else {
			return null;
		}
	}
}

SidenavContent.propTypes = {
	orders: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string.isRequired,
			has_been_contacted: PropTypes.bool.isRequired,
			has_been_fulfilled: PropTypes.bool.isRequired,
		})
	),
	user: PropTypes.shape({
		role: PropTypes.shape({
			name: PropTypes.string.isRequired,
			type: PropTypes.string.isRequired,
		}),
	}).isRequired,
	fetchOrders: PropTypes.func.isRequired,
	refreshProfile: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		orders: state.orders.bank,
		user: state.user.profile,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchOrders: (orderState, page, limit) =>
			dispatch(fetchOrders(orderState, page, limit, "createDate", "desc")),
		refreshProfile: (params) => dispatch(refreshProfile(params)),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidenavContent));
