import {
	SET_ORDERS_BANK,
	APPEND_ORDERS_BANK,
	SET_USER_ORDERS_BANK,
} from "../constants/ActionTypes";

const ordersInitialState = {
	bank: null,
	orderCount: 0,
	userOrderBank: null,
	userOrderCount: 0,
};

export default function ordersReducer(state = ordersInitialState, action) {
	switch (action.type) {
		case SET_ORDERS_BANK:
			return {
				...state,
				bank: action.payload.orderList,
				orderCount: action.payload.orderCount,
			};
		case SET_USER_ORDERS_BANK:
			return {
				...state,
				userOrderBank: action.payload,
				userOrderCount: action.payload.length,
			};
		case APPEND_ORDERS_BANK:
			return {
				...state,
				bank: [...state.bank, action.payload],
			};

		default:
			return state;
	}
}
