import { useEffect, useState } from "react";

const useDocumentTitle = (documentTitle) => {
	const [title, setTitle] = useState(documentTitle);

	useEffect(() => {
		document.title = title;
	}, [title]);

	return [title, setTitle];
};

export default useDocumentTitle;
