import api from "../../util/api.js";
import { takeLatest, call, put } from "redux-saga/effects";

import { appendNotifications } from "../../actions/Notifications.js";
import { addMessage } from "../../actions/Messages.js";
import { SUCCESS, ERROR } from "../../constants/Notifications.js";
import { PASSWORD_AUTHENTICATION } from "../../constants/ActionTypes.js";
import { PASSWORD_AUTHENTICATION_URL } from "../../constants/Url.js";

export function* passwordAuthenticationWatcher() {
	yield takeLatest(PASSWORD_AUTHENTICATION, passwordAuthenticationWorker);
}

function* passwordAuthenticationWorker(action) {
	try {
		const response = yield call(passwordAuthenticationApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.message) {
			yield put(
				addMessage({
					source: PASSWORD_AUTHENTICATION,
					message: response?.data?.error || "Password Verification failed",
					isError: !isSuccess,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		}
	} catch (e) {
		console.error(e);
	}
}

function passwordAuthenticationApi(params) {
	return api
		.post(PASSWORD_AUTHENTICATION_URL, {
			email: params.email,
			password: params.password,
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
