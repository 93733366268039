import {
    setAccountLinkerInfo,
} from // actions location
'../actions/AccountLinkPlatform'; // sample for syntax correctness

import {
    refreshProfile
} from '../actions/User';

import {
    showLoader,
    hideLoader
} from '../actions/GlobalAction'

import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';

import { // if you want to redirect post saga action
    push
} from 'react-router-redux';

import {
    DISCONNECT_ACCOUNT_LINK_WATCHER
} from '../constants/ActionTypes'
import {
    DISCONNECT_PLATFORM_URL
} from '../constants/Url'

import api from '../util/api';

function disconnectAccountLinkerAPI(params) {
    return (
        api
        .put(DISCONNECT_PLATFORM_URL, {
            platform: params
        })
    )
}

export function* disconnectAccountLinkerWatcher() {
    yield takeLatest(DISCONNECT_ACCOUNT_LINK_WATCHER, discconectAccountLinkerWorker);
}

function* discconectAccountLinkerWorker(action) {
    try {
        // data is obtained after axios call is resolved
        let response = yield call(disconnectAccountLinkerAPI, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(refreshProfile());
        } else {
            throw (response)
        }

    } catch (e) {
        console.log(e)
    }
}