import { setForgotPasswordEmailStatus } from "../actions/User";

import { showLoader, hideLoader } from "../actions/GlobalAction";

import { takeLatest, call, put } from "redux-saga/effects";

import {
	// if you want to redirect post saga action
	push,
} from "react-router-redux";

import { SEND_FORGOT_PASSWORD } from "../constants/ActionTypes";
import { FORGOT_PASSWORD_URL } from "../constants/Url";
import { SUCCESS, ERROR } from "../constants/Notifications";
import { appendNotifications } from "../actions/Notifications";
import { addMessage } from "../actions/Messages";

import api from "../util/api";

function sendForgotPasswordApi({ email, captchaToken }) {
	return api
		.post(FORGOT_PASSWORD_URL, {
			email,
			captchaToken,
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}

export function* forgotPasswordWatcher() {
	yield takeLatest(SEND_FORGOT_PASSWORD, forgotPasswordWorker);
}

const getResponseStatus = (status) => status >= 200 && status < 300;

const getActionOptions = (action, isSuccess) =>
	isSuccess ? action?.options?.success : action?.options?.failure;

const getDisplayMessage = (isSuccess, errorMessage) => (isSuccess ? "Success" : errorMessage);

function* forgotPasswordWorker(action) {
	try {
		yield put(showLoader());
		// data is obtained after axios call is resolved
		let response = yield call(sendForgotPasswordApi, action.payload);

		const isSuccess = getResponseStatus(response.status);
		const options = getActionOptions(action, isSuccess);
		const displayMessage = getDisplayMessage(isSuccess, response?.data?.error);
		if (isSuccess) {
			yield put(setForgotPasswordEmailStatus(true));
		} else {
			if (response?.data?.error) {
				yield put(
					appendNotifications({
						type: ERROR,
						message: response.data.error,
					})
				);
			} else {
				throw response;
			}
		}
		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.message) {
			yield put(
				addMessage({
					source: SEND_FORGOT_PASSWORD,
					message: typeof options.message === "string" ? options.message : displayMessage,
					isError: isSuccess,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, response);
		}
		if (options?.redirect) {
			yield put(push(options.redirect));
		}

		yield put(hideLoader());
	} catch (e) {
		console.log(e);
		yield put(hideLoader());
	}
}
