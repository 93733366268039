import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { FormFieldInput } from '../../../../components/FormFields';
import { updateEvent } from "../../../../actions/Events";
import "../styles.scss";

class ChangeLocationModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            location: "",
        }
    }

    componentWillMount() {
        this.setState({
            location: this.props.location
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.location !== nextProps.location) {
            this.setState({
                location: nextProps.location
            })
        }
    }

    handleSubmit = () => {
        if (this.state.location) {
            this.props.updateEvent({
                location: this.state.location
            }, this.props.eventId);
        }
    };

    render() {
        return (

            <Modal className="admin"
                triggerBtnText="Change"
                triggerBtnClassName="button button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Save"
                submitDisabled={this.state.location === this.props.location}
                onSubmit={() => this.handleSubmit()}
                onClose={(event) => this.setState({ location: this.props.location })}
            >
                <FormFieldInput
                    intro={"Enter the location of the event"}
                    label={"Location"}
                    type={"text"}
                    name={"location"}
                    value={this.state.location}
                    placeholder={"Location name"}
                    onChange={(event) => this.setState({ location: event.target.value })}
                />
            </Modal>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        updateEvent: (params, id) => dispatch(updateEvent(params, id)),
    }
}

const mapStateToProps = state => {
    return {

    }
}

ChangeLocationModal = connect(mapStateToProps, mapDispatchToProps)(ChangeLocationModal);

export { ChangeLocationModal }
