import {
	SET_QUEST_TRANSLATIONS_STATUS,
	SET_QUEST_TRANSLATIONS,
	UPDATE_QUEST_TRANSLATIONS_PUBLISHED_STATUS,
} from "../constants/ActionTypes";

const questTranslationsInitialState = {
	status: {
		bank: [],
	},
	translations: [],
};

export default function questTranslationsReducer(state = questTranslationsInitialState, action) {
	switch (action.type) {
		case SET_QUEST_TRANSLATIONS_STATUS:
			return {
				...state,
				status: [...action.payload],
			};
		case UPDATE_QUEST_TRANSLATIONS_PUBLISHED_STATUS: {
			const { params } = action.payload;

			return {
				...state,
				status: state.status.map((item) =>
					item.languageCode === params.languageCode
						? { ...item, isTranslationPublished: params.publishedStatus }
						: item
				),
			};
		}
		case SET_QUEST_TRANSLATIONS:
			return {
				...state,
				translations: [...action.payload],
			};
		default:
			return state;
	}
}
