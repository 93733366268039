import {
    setUserReferrals
} from "../../actions/UserAdminPanel";
import {
	appendNotifications,
} from "../../actions/Notifications";
import {
	addMessage,
} from "../../actions/Messages";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import {
    push,
} from "react-router-redux";
import {
	SUCCESS,
	ERROR,
} from "../../constants/Notifications";
import {
    FETCH_SELECTED_USER_REFERRALS_WATCHER
} from "../../constants/ActionTypes";
import {
    FETCH_USER_FRIEND_REFERRAL_URL
} from "../../constants/Url";

import api from '../../util/api'; 

export function* fetchUserFriendReferralsWatcher() {
    yield takeLatest(FETCH_SELECTED_USER_REFERRALS_WATCHER, fetchUserFriendReferralsWorker);
};

function* fetchUserFriendReferralsWorker(action) {
    try {
        let response = yield call(fetchUserFriendReferralsApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		
		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (options?.notification) {
			yield put(appendNotifications({
				type: isSuccess ? SUCCESS : ERROR,
				message: options.notification,
			}));
		};
		if (options?.message) {
			yield put(addMessage({
				source: FETCH_SELECTED_USER_REFERRALS_WATCHER,
				message: typeof options.message === "string" ? options.message : response?.data,
				isError: isSuccess,
			}));
		};
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		};
		if (options?.redirect) {
			yield put(push(options.redirect));
        };
        if (isSuccess) {
            yield put(setUserReferrals(response.data));
        };
    } catch (e) {
        console.error(e);
    };
};

function fetchUserFriendReferralsApi(payload) {
	return api
	    .get(FETCH_USER_FRIEND_REFERRAL_URL, {
            params: payload,
        })
        .then(response => { return response })
        .catch(error => { return error.response });
};
