import { takeLatest, call, put } from "redux-saga/effects";

import { FETCH_PLAYERS } from "../../constants/ActionTypes";
import { ALL_PLAYERS_URL } from "../../constants/Url";
import { setPlayers } from "../../actions/Players";
import api from "../../util/api";

export function* fetchPlayersWatcher() {
	yield takeLatest(FETCH_PLAYERS, fetchPlayersWorker);
}

function* fetchPlayersWorker(action) {
	try {
        const response = yield call(fetchPlayersApi, action.payload);
		if (response.status >= 200 && response.status < 300) {
			yield put(setPlayers(response.data.data));
		} else {
			throw response;
		}
	} catch (error) {
		console.error(error);
	}
}

function fetchPlayersApi(payload) {
    return (
        api
        .get(ALL_PLAYERS_URL, {
            params: {
                is_active: payload.is_active
            }
        })
    );
};

