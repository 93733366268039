import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import DeleteDialog from "./atoms/DeleteDialog";

function StaticChannelRow({ channel, onDelete }) {
	const handleDelete = () => onDelete(channel.name);

	return (
		<TableRow>
			<TableCell>
				<span>{channel.name} | </span>
				<span className="data-table__item-sub-title">
					{channel.channel_id}
				</span>
			</TableCell>
			<TableCell style={{ textAlign: "right" }}>
				<DeleteDialog handleDelete={handleDelete} />
			</TableCell>
		</TableRow>
	);
}

StaticChannelRow.propTypes = {
	channel: PropTypes.shape({
		name: PropTypes.string.isRequired,
	}).isRequired,
	onDelete: PropTypes.func.isRequired,
};

export default StaticChannelRow;
