import { all } from "redux-saga/effects";

import { fetchQuestTranslationsWatcher } from "./FetchQuestTranslations";
import { fetchQuestTranslationsStatusWatcher } from "./FetchQuestTranslationsStatusSagas";
import { patchQuestTranslationsWatcher } from "./PatchQuestTranslations";
import { patchQuestTranslationsPublishedStatusWatcher } from "./PatchQuestTranslationsPublishedStatus";

export function* questTranslationsSagas() {
	yield all([
		fetchQuestTranslationsStatusWatcher(),
		fetchQuestTranslationsWatcher(),
		patchQuestTranslationsWatcher(),
		patchQuestTranslationsPublishedStatusWatcher(),
	]);
}
