import { call, put, takeLatest } from "redux-saga/effects";
import { setGenders } from "../../actions/Genders";

import { FETCH_GENDERS_WATCHER } from "../../constants/ActionTypes";
import { GENDER_OPTIONS } from "../../constants/SelectionOptions";
import { FETCH_GENDERS_URL } from "../../constants/Url";

import api from "../../util/api";

export function* fetchGendersWatcher() {
	yield takeLatest(FETCH_GENDERS_WATCHER, fetchGendersWorker);
}

function* fetchGendersWorker() {
	try {
		const response = yield call(fetchGendersApi);
		const isSuccess = response.status >= 200 && response.status < 300;
		if (isSuccess) {
			yield put(setGenders(response.data.data));
		} else {
			// genders fallback
			yield put(setGenders(GENDER_OPTIONS));
		}
	} catch (e) {
		console.error(e);
		// genders fallback with our own gender constant data that hopefully align with what we have on backend
		yield put(setGenders(GENDER_OPTIONS));
	}
}

async function fetchGendersApi() {
	try {
		return await api.get(FETCH_GENDERS_URL);
	} catch (error) {
		return error.response;
	}
}
