import React, { useState } from "react";
import PropTypes from "prop-types";
import "../styles.scss";

// Redux
import { connect } from "react-redux";
import { updateRewards } from "../../../../actions/Rewards";

// Components
import LpModal from "../../../../components/LpModal";
import { FormFieldRadio } from "../../../../components/FormFields";

function CategoryModal({ currentCategory, rewardId, rewardType, updateRewards }) {
	const [category, setCategory] = useState(currentCategory);

	const handleSubmit = () => {
		updateRewards({
			id: rewardId,
			type: rewardType,
			params: {
				category,
			},
		});
	};

	return (
		<LpModal
			className="admin"
			triggerBtnText="Change"
			triggerBtnClassName="button button--secondary button--borderless"
			cancelBtnText="Cancel"
			submitBtnText="Save"
			submitDisabled={category === currentCategory}
			onOpen={() => setCategory(currentCategory)}
			onSubmit={handleSubmit}
		>
			<FormFieldRadio
				label="Item Type"
				name="category"
				value={category}
				options={[
					{ label: "Digital", value: "DIGITAL" },
					{ label: "Physical", value: "PHYSICAL" },
				]}
				onChange={(event) => setCategory(event.target.value)}
			/>
		</LpModal>
	);
}

CategoryModal.propTypes = {
	currentCategory: PropTypes.string,
	rewardId: PropTypes.string.isRequired,
	rewardType: PropTypes.string.isRequired,
	updateRewards: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
	updateRewards: (payload, options) => dispatch(updateRewards(payload, options)),
});

export default connect(null, mapDispatchToProps)(CategoryModal);
