import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import LpList from "../LpList";

import LpDraggableLoadedItem from "./atoms/LpDraggableItem";
import {
	DEFAULT_VARIANT,
	LP_LIST_VARIANT,
	LP_BLOCK_VARIANT,
} from "./constants";

function useDragAndDrop(items, onChange, selected, variant) {
	const initialDnDState = {
		draggedFrom: null,
		draggedTo: null,
		isDragging: false,
		originalOrder: [],
		updatedOrder: [],
	};

	const [list, setList] = React.useState([]);
	const [dragAndDrop, setDragAndDrop] = React.useState(initialDnDState);

	React.useEffect(() => setList(items), [items]);

	const onDragStart = (event) => {
		const initialPosition = Number(event.currentTarget.dataset.position);

		setDragAndDrop({
			...dragAndDrop,
			draggedFrom: initialPosition,
			isDragging: true,
			originalOrder: list,
		});

		// Note: this is only for Firefox. Without it, the DnD won't work.
		event.dataTransfer.setData("text/html", "");
	};

	const onDragOver = (event) => {
		event.preventDefault();

		let newList = dragAndDrop.originalOrder;

		const draggedFrom = dragAndDrop.draggedFrom;
		const draggedTo = Number(event.currentTarget.dataset.position);

		const itemDragged = newList[draggedFrom];
		const remainingItems = newList.filter(
			(item, index) => index !== draggedFrom
		);

		newList = [
			...remainingItems.slice(0, draggedTo),
			itemDragged,
			...remainingItems.slice(draggedTo),
		];

		if (draggedTo !== dragAndDrop.draggedTo) {
			setDragAndDrop({
				...dragAndDrop,
				updatedOrder: newList,
				draggedTo: draggedTo,
			});
		}
	};

	const onDrop = () => {
		const { draggedFrom, draggedTo } = dragAndDrop;
		setList(dragAndDrop.updatedOrder);
		onChange(
			dragAndDrop.updatedOrder,
			draggedFrom === selected ? draggedTo : selected
		);

		setDragAndDrop({
			...dragAndDrop,
			draggedFrom: null,
			draggedTo: null,
			isDragging: false,
		});
	};

	const onDragLeave = () => {
		setDragAndDrop({
			...dragAndDrop,
			draggedTo: null,
		});
	};

	return {
		list,
		dragAndDrop,
		onDragStart,
		onDragOver,
		onDrop,
		onDragLeave,
	};
}

const LpDragAndDropList = ({
	items = [],
	itemKey,
	renderItem,
	onChange,
	onClickItem,
	selected,
	variant,
	className,
	itemClassName,
}) => {
	const {
		list,
		dragAndDrop,
		onDragStart,
		onDragOver,
		onDrop,
		onDragLeave,
	} = useDragAndDrop(items, onChange, selected);

	const Wrapper = variant === LP_LIST_VARIANT ? LpList : "div";

	return (
		<Wrapper className={classnames("lp_drag_and_drop_list", className)}>
			{list.map((item, index) => {
				return (
					<LpDraggableLoadedItem
						key={item[itemKey]}
						dataPosition={index}
						draggable
						onDragStart={onDragStart}
						onDragOver={onDragOver}
						onDrop={onDrop}
						onDragLeave={onDragLeave}
						showDropArea={
							dragAndDrop &&
							dragAndDrop.draggedTo === Number(index)
						}
						onClick={onClickItem ? () => onClickItem(index) : null}
						selected={selected === index}
						variant={
							variant === LP_LIST_VARIANT
								? LP_BLOCK_VARIANT
								: DEFAULT_VARIANT
						}
						className={itemClassName}
					>
						{renderItem(item)}
					</LpDraggableLoadedItem>
				);
			})}
		</Wrapper>
	);
};

LpDragAndDropList.propTypes = {
	onChange: PropTypes.func,
	items: PropTypes.arrayOf(PropTypes.object),
	itemKey: PropTypes.string.isRequired,
	renderItem: PropTypes.func.isRequired,
	onClickItem: PropTypes.func,
	selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	variant: PropTypes.oneOf([DEFAULT_VARIANT, LP_LIST_VARIANT]),
	className: PropTypes.string,
	itemClassName: PropTypes.string,
};

LpDragAndDropList.defaultProps = {
	variant: DEFAULT_VARIANT,
};

export default LpDragAndDropList;
