import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

class Modal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			visible: false,
			mounted: false,
		};
	}

	componentDidMount() {
		const { onOpen } = this.props;
		this.setState({ mounted: true }, onOpen);
	}

	static getDerivedStateFromProps(props, state) {
		const { fadeOut, hidden } = props;

		// Handles fade effect
		if (state.mounted) return { visible: !fadeOut && !hidden ? true : false };

		return state;
	}

	render() {
		const {
			id,
			className,
			containerClassName,
			hidden,
			onClose,
			backdrop,
			backdropClassName,
			children,
		} = this.props;
		const { visible } = this.state;

		return (
			<div
				className={classnames(
					"lp_modal",
					{ "lp_modal--visible": visible },
					{ "lp_modal--hidden": hidden },
					{
						[className]: className,
					}
				)}
				role="dialog"
				aria-modal
				tabIndex="-1"
			>
				{backdrop !== false && (
					<div
						className={classnames("lp_modal__backdrop", {
							[backdropClassName]: backdropClassName,
						})}
						onClick={onClose}
					/>
				)}
				<div
					id={id}
					className={classnames("lp_modal__container", {
						[containerClassName]: containerClassName,
					})}
					role="document"
				>
					{children}
				</div>
			</div>
		);
	}
}

Modal.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	containerClassName: PropTypes.string,
	backdrop: PropTypes.bool,
	backdropClassName: PropTypes.string,
	onOpen: PropTypes.func,
	fadeOut: PropTypes.bool,
	hidden: PropTypes.bool,
	onClose: PropTypes.func,
	children: PropTypes.any,
};

export default Modal;
