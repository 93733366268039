import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import { CLAIM_REWARDS_FROM_QUEST_PROGRESSION } from "../../constants/ActionTypes";
import { CLAIM_REWARDS_FROM_QUEST_PROGRESSION_URL } from "../../constants/Url";
import { SUCCESS, ERROR } from "../../constants/Notifications";
import { appendNotifications } from "../../actions/Notifications";
import { addMessage } from "../../actions/Messages";

import { fetchQuestProgressions } from "../../actions/QuestProgressions";

import api from "../../util/api";

export function* claimRewardsFromQuestProgressionWatcher() {
	yield takeLatest(CLAIM_REWARDS_FROM_QUEST_PROGRESSION, claimRewardsFromQuestProgressionWorker);
}

/**
 * @param {String} action.params.progression The ID of the quest progression to claim rewards from.
 * @param {[{tier: Number, reward_id: String, reward_type: String}]} action.params.rewards The rewards to claim from the quest progression, eg. [{tier: 1, reward_id: "abc-123", reward_type: "CUSTOM"}]
 *
 * @param {Object} [action.options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [action.options.success] Behavior to execute when API call and action is successful.
 * @param {String} [action.options.success.redirect] Redirect to this URL on success.
 * @param {String | Boolean} [action.options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [action.options.success.notification] Display notification with passed text on success. If ```message``` is ```true```, push API response message instead of passed string.
 * @param {Function} [action.options.success.callback] Callback function on success.
 * @param {*} [action.options.success.callbackArgs] Callback function args on success.
 *
 * @param {Object} [action.options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [action.options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [action.options.failure.message] Push message with passed text to ```state.messages.bank``` on failure. If ```message``` is ```true```, push API response message instead of passed string.
 * @param {String} [action.options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [action.options.failure.callback] Callback function on failure.
 * @param {*} [action.options.failure.callbackArgs] Callback function args on failure.
 */
function* claimRewardsFromQuestProgressionWorker(action) {
	try {
		const response = yield call(claimRewardsFromQuestProgressionApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		const notificationMessage = isSuccess ? "Success" : response.data.error;
		if (isSuccess) {
			yield put(fetchQuestProgressions());
		}
		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message:
						typeof options.notification === "string"
							? options.notification
							: notificationMessage,
				})
			);
		}
		if (options?.message) {
			yield put(
				addMessage({
					source: CLAIM_REWARDS_FROM_QUEST_PROGRESSION,
					message: options.message,
					isError: !isSuccess,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		}
		if (options?.redirect) {
			yield put(push(options.redirect));
		}
	} catch (e) {
		console.error(e);
	}
}

/**
 * Sends an API request to claim rewards from a quest progression object.
 * @param {String} params.progression The ID of the quest progression to claim rewards from.
 * @param {[{tier: Number, reward_id: String, reward_type: String}]} params.rewards The rewards to claim from the quest progression, eg. [{tier: 1, reward_id: "abc-123", reward_type: "CUSTOM"}]
 *
 * @return {Object} The response object from the API call.
 */
function claimRewardsFromQuestProgressionApi(params) {
	return api
		.post(CLAIM_REWARDS_FROM_QUEST_PROGRESSION_URL(params.progression), {
			rewards: params.rewards,
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
