import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import LpRichTextEditor from "../LpRichTextEditor";

// Utils
import { sanitizeHtml } from "../../util/stringUtils";

/*
	DOCUMENT INTRODUCTION
 	Custom Textarea components
	Author: Luuk Barten, Max Martyn
*/
/*
    > FormFieldTextarea		//  Textarea input. Set "allow_formatting" to "true" to enable the SunEditor.
                                Examples when "allow_formatting" is "true":
                                    onChange={(content) => console.log(content);}
									allow_formatting_fullheight // This will enable the HTML editor
									to try to take up as much vertical space as possible.
                                Note: When HTML editor is used:
                                    Changing the state will not change the contents.
                                    The "value" prop is only used to set the initial contents.  After that,
                                    the editor will pass the contents to the onChange prop but will not
                                    change the contents if the value prop changes.
*/
/*
    USAGE
    Component must reside in an (grand)parent element with class "admin".
    Most components take the following arguments
    > intro                 //  Introduction text rendered before the label.
    > label                 //  Label above the FormField.
    > value                 //  The current value (usually set to the value in state).
    > id                    //  Will be rendered as the label "for" attribute and the input element's "id".
    > placeholder           //  Placeholder with example value.
    > pattern               //  HTML patter attribute to make user of pattern detection.
    > maxlength             //  Max number of characters that can be entered.
    > onChange              //  Will be called with an event object as payload.
*/
/*
  HANDLING ONCHANGE
  See /src/app/routes/AdminQuests/QuestCreate or /src/app/routes/AdminQuests/QuestView pages for examples how to handle onChange.
*/
class FormFieldTextarea extends Component {
	onChangeValue = (e) => {
		const { allow_formatting, onChange } = this.props;
		onChange(allow_formatting ? sanitizeHtml(e) : e);
	};

	render() {
		const {
			wide,
			intro,
			name,
			label,
			allow_formatting,
			allow_formatting_fullheight,
			required,
			form,
			value,
			placeholder,
			maxlength,
			hint,
			disabled,
		} = this.props;

		return (
			<div
				className={classnames("form-field", {
					"form-field--wide": wide || allow_formatting,
				})}
			>
				{intro && <div className="form-field__intro">{intro}</div>}
				<label className="form-field__label" htmlFor={name}>
					{label}
				</label>
				{allow_formatting ? (
					<LpRichTextEditor
						disabled={disabled}
						allow_formatting_fullheight={allow_formatting_fullheight}
						initialValue={value}
						onChange={this.onChangeValue}
					/>
				) : (
					<textarea
						disabled={disabled}
						required={required}
						form={form}
						className="form-field__textarea"
						name={name}
						value={value}
						id={name}
						placeholder={placeholder}
						maxLength={maxlength}
						onChange={this.onChangeValue}
					/>
				)}
				{hint && <span className="form-field__hint">{hint}</span>}
			</div>
		);
	}
}

FormFieldTextarea.propTypes = {
	disabled: PropTypes.bool,
	wide: PropTypes.bool,
	intro: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	allow_formatting: PropTypes.bool,
	allow_formatting_fullheight: PropTypes.bool,
	height: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	form: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	hint: PropTypes.string,
	maxlength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

FormFieldTextarea.defaultProps = {
	value: "",
	disabled: false,
	allow_formatting_fullheight: false,
};
export default FormFieldTextarea;
