import { setUserOrdersBank } from "../../actions/Orders";
import { takeLatest, call, put } from "redux-saga/effects";
import { FETCH_USER_ORDERS_WATCHER } from "../../constants/ActionTypes";
import { REWARD_SERVICE_USER_ORDERS } from "../../constants/Url";

import api from "../../util/api";

export function* fetchUserOrdersWatcher() {
	yield takeLatest(FETCH_USER_ORDERS_WATCHER, fetchUserOrdersWorker);
}

function* fetchUserOrdersWorker(action) {
	try {
		const options = action.options;
		const response = yield call(fetchUserOrdersApi, options.userId, options?.params);
		const isSuccess = response.status >= 200 && response.status < 300;
		const successOrFailuredHandler = isSuccess
			? action.options?.success
			: action.options?.failure;

		const responseData = response.data.data;
		if (isSuccess) {
			yield put(setUserOrdersBank(responseData));
		}

		if (successOrFailuredHandler?.callback)
			yield call(successOrFailuredHandler.callback, responseData);
	} catch (e) {
		console.log(e);
	}
}

async function fetchUserOrdersApi(userId, params) {
	return api
		.get(REWARD_SERVICE_USER_ORDERS + "/" + userId, { params })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
