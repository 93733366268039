import React from "react";
import PropTypes from "prop-types";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DeleteDialog from "../DeleteDialog";

function KeywordRow({ keyword, onDelete }) {
	return (
		<TableRow>
			<TableCell>
				<span>{keyword.keyword}</span>
			</TableCell>
			<TableCell style={{ textAlign: "right" }}>
				<DeleteDialog handleDelete={() => onDelete(keyword._id)} />
			</TableCell>
		</TableRow>
	);
}

KeywordRow.propTypes = {
	keyword: PropTypes.shape({
		keyword: PropTypes.string.isRequired,
		_id: PropTypes.string.isRequired,
	}).isRequired,
	onDelete: PropTypes.func.isRequired,
};

export default KeywordRow;
