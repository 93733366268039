import { setPublicCampaign } from "../../actions/Campaigns";

import { takeLatest, call, put } from "redux-saga/effects";

import { push } from "react-router-redux";

import { FETCH_PUBLIC_CAMPAIGN_WATCHER } from "../../constants/ActionTypes";

import { PUBLIC_FETCH_CAMPAIGN_URL } from "../../constants/Url";

import api from "../../util/api";

export function* fetchPublicCampaignWatcher() {
	yield takeLatest(FETCH_PUBLIC_CAMPAIGN_WATCHER, fetchPublicCampaignWorker);
}

function* fetchPublicCampaignWorker(action) {
	try {
		let response = yield call(fetchPublicCampaignApi, action.payload);
		if (response.status >= 200 && response.status < 300) {
			yield put(
				setPublicCampaign({
					campaign: response.data.data,
				})
			);
		} else if (response.status === 404) {
			yield put(push("/error-404"));
		} else {
			throw response;
		}
	} catch (e) {
		console.error(e);
	}
}

function fetchPublicCampaignApi(payload) {
	return api
		.get(PUBLIC_FETCH_CAMPAIGN_URL(payload))
		.then((response) => response)
		.catch((error) => error.response);
}
