import { takeLatest, call, put, select } from "redux-saga/effects";

// Actions
import { setV2QuestsBank } from "../../actions/V2Quests";

// Util
import api from "../../util/api";

// Constants
import { FETCH_V2_QUESTS_COMPLETED_WATCHER } from "../../constants/ActionTypes";
import { V2_QUESTS_COMPLETED } from "../../constants/Url";

export function* fetchQuestsCompletedWatcher() {
	yield takeLatest(FETCH_V2_QUESTS_COMPLETED_WATCHER, fetchQuestsCompletedWorker);
}

function* fetchQuestsCompletedWorker(action) {
	try {
		let options = action.options;
		const response = yield call(fetchQuestsCompletedApi, {
			page: options?.page,
			limit: options?.limit,
		});
		const isSuccess = response.status >= 200 && response.status < 300;

		if (isSuccess) {
			if (options.append) {
				const current = yield select((state) => state.v2Quests.completed);
				yield put(
					setV2QuestsBank({
						completed: [...(current || []), ...response.data.data],
					})
				);
			} else {
				yield put(setV2QuestsBank({ completed: response.data.data }));
			}
		}

		options = isSuccess ? action.options?.success : action.options?.failure;
		if (options?.callback) yield call(options.callback, response.data);
	} catch (e) {
		console.error(e);
	}
}

function fetchQuestsCompletedApi(params) {
	return api
		.get(V2_QUESTS_COMPLETED, { params })
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
