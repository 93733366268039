import {
    setEventCheckInMessage
} from "../../actions/User";
import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';
import {
    push
} from 'react-router-redux';

import {
    appendNotifications
} from '../../actions/Notifications'

import {
    CHECK_IN_EVENT_WATCHER
} from '../../constants/ActionTypes'
import {
    EVENT_CHECK_IN_API_LINK,
} from '../../constants/Url'

import api from '../../util/api';

function checkInEventApi(payload) {
    return (
        api
        .post(EVENT_CHECK_IN_API_LINK, {
            ...payload
        })
    ).catch(err => {
        return err.response.data
    })
}

export function* checkInEventWatcher() {
    yield takeLatest(CHECK_IN_EVENT_WATCHER, checkInEventWorker);
}

function* checkInEventWorker(action) {
    let response;
    try {
        /// data is obtained after axios call is resolved
        response = yield call(checkInEventApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(setEventCheckInMessage(response.data.data, true))
        } else {
            yield put(setEventCheckInMessage(response.err, false))
        }
        window.browserStorage.removeCheckInCode();
    } catch (e) {
        window.browserStorage.removeCheckInCode();
        yield put(setEventCheckInMessage(response.data.data, null))
    }
}