import { takeLatest, call } from "redux-saga/effects";

import { CREATE_CAMPAIGNS_WATCHER } from "../../constants/ActionTypes";

import { CREATE_CAMPAIGNS_URL } from "../../constants/Url";

import api from "../../util/api";

export function* createCampaignsWatcher() {
	yield takeLatest(CREATE_CAMPAIGNS_WATCHER, createCampaignsWorker);
}

function* createCampaignsWorker(action) {
	try {
		let response = yield call(createCampaignsApi, action.payload);
		if (!(response.status >= 200 && response.status < 300)) {
			throw response;
		}
	} catch (e) {
		console.error(e);
	}
}

function createCampaignsApi(payload) {
	return api.post(CREATE_CAMPAIGNS_URL, payload);
}
