import {
    showLoader,
    hideLoader
} from '../../actions/GlobalAction';

import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';
import {
    push
} from 'react-router-redux';
import {
    CREATE_REWARD_WATCHER
} from '../../constants/ActionTypes'
import {
    REWARD_SERVICE_CREATE,
    ADMIN_REWARDS
} from '../../constants/Url'

import api from '../../util/api';

function createRewardApi(payload) {
    return (
        api
        .post(REWARD_SERVICE_CREATE, {
            type: payload.type,
            params: {
                method: 'create',
                new_params: payload.params
            }
        })

    )
}

export function* createRewardWatcher() {
    yield takeLatest(CREATE_REWARD_WATCHER, createRewardWorker);
}

function* createRewardWorker(action) {
    try {
        yield put(showLoader());
        // data is obtained after axios call is resolved
        let response = yield call(createRewardApi, action.payload);
        if (!(response.status >= 200 && response.status < 300)) {
            throw (response)
        }

        yield put(hideLoader());
        yield put(push(ADMIN_REWARDS))
    } catch (e) {
        console.log(e)
        yield put(hideLoader());
        yield put(push(ADMIN_REWARDS))
    }
}