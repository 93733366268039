import {
    setAchievements
} from "../../actions/Achievements";
import {
	appendNotifications,
} from "../../actions/Notifications";
import {
	addMessage,
} from "../../actions/Messages";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import {
    push,
} from "react-router-redux";
import {
	SUCCESS,
	ERROR,
} from "../../constants/Notifications";
import {
    FETCH_ACHIEVEMENTS_WATCHER
} from "../../constants/ActionTypes";
import {
    FETCH_ACHIEVEMENTS_URL
} from "../../constants/Url";
import api from '../../util/api';

export function* fetchAchievementsWatcher() {
    yield takeLatest(FETCH_ACHIEVEMENTS_WATCHER, fetchAchievementsWorker);
};

function* fetchAchievementsWorker(action) {
    try {
		const response = yield call(fetchAchievementsApi);
		const isSuccess = response.status >= 200 && response.status < 300;
		
		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (options?.notification) {
			yield put(appendNotifications({
				type: isSuccess ? SUCCESS : ERROR,
				message: options.notification,
			}));
		};
		if (options?.message) {
			yield put(addMessage({
				source: FETCH_ACHIEVEMENTS_WATCHER,
				message: typeof options.message === "string" ? options.message : response?.data,
				isError: isSuccess,
			}));
		};
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		};
		if (options?.redirect) {
			yield put(push(options.redirect));
        };
        if (isSuccess) {
            yield put(setAchievements(response.data.data));
        };
    } catch (e) {
        console.error(e);
    };
};

function fetchAchievementsApi() {
    return api
        .get(FETCH_ACHIEVEMENTS_URL)
        .then(response => { return response })
        .catch(error => { return error.response });
};
