import React from "react";
import { ConnectedRouter } from "react-router-redux";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import configureStore, { history } from "./store";
import { wrapHistory } from "oaf-react-router";
import App from "./containers/App";
import NotificationHub from "./containers/NotificationHub";

wrapHistory(history, {
	primaryFocusTarget: "body",
});

export const store = configureStore();

const MainApp = () => (
	<Provider store={store}>
		<React.Fragment>
			<NotificationHub />
			<ConnectedRouter history={history}>
				<Switch>
					<Route path="/app" component={App} />
				</Switch>
			</ConnectedRouter>
		</React.Fragment>
	</Provider>
);

export default MainApp;