import {
	FETCH_USER_WATCHER,
	FETCH_USER_PROFILE_PARAM_WATCHER,
	SET_USER_PROFILE_PARAM,
	SET_USER_PROFILE,
	UPDATE_USER_PROFILE,
	CLEAR_USER_PROFILE,
	REFRESH_PROFILE_WATCHER,
	SEND_FORGOT_PASSWORD,
	SET_FORGOT_PASSWORD_EMAIL_STATUS,
	SEND_RESET_PASSWORD,
	RESET_PASSWORD_STATUS,
	LOGOUT_USER_WATCHER,
	UPDATE_USER_WATCHER,
	UPDATE_USER_WITHOUT_FETCH_WATCHER,
	VERIFY_USER,
	SET_USER_EMAIL,
	GENERATE_TOKEN,
	GET_REFERRAL_LINK,
	SET_REFERRAL_LINK,
	SIGNUP_USER,
	SIGNUP_NOTIFY,
	RESEND_REGISTRATION_EMAIL,
	GET_USER_AVATARS,
	SET_USER_AVATARS,
	GET_USER_LIFETIME_POINTS,
	SET_USER_LIFETIME_POINTS,
	GET_USER_MEMBERSHIP_POINTS,
	SET_USER_MEMBERSHIP_POINTS,
	GET_USER_SPENDING_POINTS,
	SET_USER_SPENDING_POINTS,
	CLAIM_PARTNER_BONUS,
	SET_EVENT_CHECK_IN_MESSAGE,
	CHECK_DUPLICATE_USER_NAME,
	SET_USERNAME_UPDATE_STATUS,
	SEND_EMAIL_HELP_REQUEST,
	GET_PROFILE_OVERVIEW,
	SET_PROFILE_OVERVIEW,
	GET_USER_RANK_UP_PROGRESS,
	SET_USER_RANK_UP_PROGRESS,
	REQUEST_DELETE_ACCOUNT,
	DELETE_ACCOUNT,
	VERIFY_AND_UPDATE_EMAIL_ID_CHANGE_REQUEST,
	FETCH_USERNAMES_AND_AVATARS_WATCHER,
	SET_USERNAMES_AND_AVATARS,
	CHANGE_LANGUAGE,
} from "../constants/ActionTypes";

export const registerUser = (
	email,
	password,
	password2,
	birthday,
	country,
	code,
	accesstoken,
	captchaToken,
	lang,
	newslettersubscribed,
	username,
	parentalConsent,
	options
) => {
	return {
		type: SIGNUP_USER,
		payload: {
			email,
			password,
			password2,
			birthday,
			country,
			code,
			accesstoken,
			captchaToken,
			lang,
			newslettersubscribed,
			username,
			parentalConsent,
		},
		options: options,
	};
};

export const resendRegistrationEmail = ({ email, captchaToken }, options) => {
	return {
		type: RESEND_REGISTRATION_EMAIL,
		params: {
			email,
			captchaToken,
		},
		options: options,
	};
};

export const notifySignUp = (email, options) => {
	return {
		type: SIGNUP_NOTIFY,
		params: {
			email,
		},
		options: options,
	};
};

export const fetchUser = (email, password, options) => {
	return {
		type: FETCH_USER_WATCHER,
		payload: {
			email,
			password,
		},
		options,
	};
};

/**
 * @param {String | String[]} params Properties of state.user.profile to update with fetched data.
 * @param {Boolean} [options.disableLoader] Disable loading icon/overlay.
 *
 * @example
 * refreshProfile() // Replace the entire ```state.user.profile``` object. Note that this causes the entirety of ```state.user.profile``` to mutate.
 * refreshProfile("userInfo.username") // Only update the user's username in the Redux store.
 * refreshProfile(["userInfo", "email"]) // Update all userInfo properties (children of userInfo) and the user's email in the Redux store.
 * refreshProfile(null, { disableLoader: true }) // Do not display a loading icon.
 */
export const refreshProfile = (params, options) => {
	return {
		type: REFRESH_PROFILE_WATCHER,
		params: params,
		options: options,
	};
};

export const setUserProfile = (user) => {
	return {
		type: SET_USER_PROFILE,
		payload: user,
	};
};

export const updateUserProfile = (update) => {
	return {
		type: UPDATE_USER_PROFILE,
		payload: update,
	};
};

export const clearUserProfile = () => {
	return {
		type: CLEAR_USER_PROFILE,
	};
};

export const setUserProfileParam = (arg, value) => {
	return {
		type: SET_USER_PROFILE_PARAM,
		payload: {
			arg,
			value,
		},
	};
};

export const fetchUserProfileParam = (arg) => {
	return {
		type: FETCH_USER_PROFILE_PARAM_WATCHER,
		payload: arg,
	};
};

export const sendForgotPassword = ({ email, captchaToken }, options) => {
	return {
		type: SEND_FORGOT_PASSWORD,
		payload: { email, captchaToken },
		options: options,
	};
};

export const setForgotPasswordEmailStatus = (status) => {
	return {
		type: SET_FORGOT_PASSWORD_EMAIL_STATUS,
		payload: status,
	};
};

export const resetPassword = (payload, options) => {
	return {
		type: SEND_RESET_PASSWORD,
		payload: payload,
		options,
	};
};

export const setResetPasswordStatus = (status) => {
	return {
		type: RESET_PASSWORD_STATUS,
		payload: status,
	};
};

export const setUserEmail = (payload) => {
	return {
		type: SET_USER_EMAIL,
		payload: payload,
	};
};

export const logoutUser = (params) => {
	return {
		type: LOGOUT_USER_WATCHER,
		params: params,
	};
};

export const updateUser = (params, options, value = null, property = "user info") => {
	return {
		type: UPDATE_USER_WATCHER,
		payload: {
			params,
			value,
		},
		property: property,
		options: options,
	};
};

export const updateUserWithoutFetch = (params, value = null, property = "user info") => {
	return {
		type: UPDATE_USER_WITHOUT_FETCH_WATCHER,
		payload: {
			params,
			value,
		},
		property: property,
	};
};

export const updateLanguage = (payload) => {
	return {
		type: CHANGE_LANGUAGE,
		payload,
	};
};

export const verifyUser = (payload) => {
	return {
		type: VERIFY_USER,
		payload: payload,
	};
};

export const generateToken = (payload) => {
	return {
		type: GENERATE_TOKEN,
		payload: payload,
	};
};

export const getReferralLink = () => {
	return {
		type: GET_REFERRAL_LINK,
	};
};

export const setReferralLink = (code) => {
	return {
		type: SET_REFERRAL_LINK,
		payload: code,
	};
};

export const getUserAvatars = () => {
	return {
		type: GET_USER_AVATARS,
	};
};

export const setUserAvatars = (avatars) => {
	return {
		type: SET_USER_AVATARS,
		payload: avatars,
	};
};

export const getUserLifetimePoints = (userId) => {
	return {
		type: GET_USER_LIFETIME_POINTS,
		payload: userId,
	};
};

export const setUserLifetimePoints = (lifetimePoints) => {
	return {
		type: SET_USER_LIFETIME_POINTS,
		payload: lifetimePoints,
	};
};

export const getUserMembershipPoints = (userId) => {
	return {
		type: GET_USER_MEMBERSHIP_POINTS,
		payload: userId,
	};
};

export const setUserMembershipPoints = (membershipPoints) => {
	return {
		type: SET_USER_MEMBERSHIP_POINTS,
		payload: membershipPoints,
	};
};

export const getUserSpendingPoints = (userId) => {
	return {
		type: GET_USER_SPENDING_POINTS,
		payload: userId,
	};
};

export const setUserSpendingPoints = (spendingPoints) => {
	return {
		type: SET_USER_SPENDING_POINTS,
		payload: spendingPoints,
	};
};

export const claimPartnerBonus = (payload, options) => {
	return {
		type: CLAIM_PARTNER_BONUS,
		payload: payload,
		options: options,
	};
};

export const getUserRankUpProgress = (userId) => {
	return {
		type: GET_USER_RANK_UP_PROGRESS,
		payload: userId,
	};
};

export const setUserRankUpProgress = (progress) => {
	return {
		type: SET_USER_RANK_UP_PROGRESS,
		payload: progress,
	};
};

export const setEventCheckInMessage = (eventDetails, status) => {
	let response = {
		eventDetails,
		status,
	};
	if (!status) {
		response.eventDetails = eventDetails.data;
		response.errorMsg = eventDetails.msg;
	}
	return {
		type: SET_EVENT_CHECK_IN_MESSAGE,
		payload: response,
	};
};

export const checkDuplicateUsername = (name) => {
	return {
		type: CHECK_DUPLICATE_USER_NAME,
		payload: name,
	};
};

export const setUsernameUpdateStatus = (data) => {
	return {
		type: SET_USERNAME_UPDATE_STATUS,
		payload: data,
	};
};

/**
 *
 * @function sendEmailHelpRequest
 *
 * @param {Object} helpRequestForm Form containing message data
 * @param {String} helpRequestForm.userIdentifier User email
 * @param {String} helpRequestForm.topic Issue topic
 * @param {String} helpRequestForm.message Body of message
 * @param {String} [helpRequestForm.userId] Id of user
 *
 * @param {Object} options
 *
 * @param {Object} [options.success] Options upon succesfull message delivery
 * @param {String} [options.success.notification] Notification to be shown upon succesfull message delivery
 * @param {Function} [options.success.callback] Callback for cleaning up form upon successful API call
 *
 * @param {Object} [options.failure] Options upon failed message delivery
 * @param {String} [options.failure.notification] Notification to be shown upon succesfull message delivery
 */

export const sendEmailHelpRequest = (helpRequestForm, options) => {
	return {
		type: SEND_EMAIL_HELP_REQUEST,
		payload: helpRequestForm,
		options,
	};
};

export const getProfileOverview = (shareId) => {
	return {
		type: GET_PROFILE_OVERVIEW,
		payload: shareId,
	};
};

export const setProfileOverview = (profile) => {
	return {
		type: SET_PROFILE_OVERVIEW,
		payload: profile,
	};
};

export const requestDeleteAccount = (params, options) => {
	return {
		type: REQUEST_DELETE_ACCOUNT,
		params: params,
		options: options,
	};
};

export const deleteAccount = (params, options) => {
	return {
		type: DELETE_ACCOUNT,
		params: params,
		options: options,
	};
};

export const verifyAndUpdateEmailIdChangeRequest = (data, options) => {
	return {
		type: VERIFY_AND_UPDATE_EMAIL_ID_CHANGE_REQUEST,
		payload: data,
		options: options,
	};
};

export const fetchUsernamesAndAvatars = (payload) => {
	return {
		type: FETCH_USERNAMES_AND_AVATARS_WATCHER,
		payload: payload,
	};
};

export const setUsernamesAndAvatarsBank = (payload, options) => {
	return {
		type: SET_USERNAMES_AND_AVATARS,
		payload: payload,
		options: options,
	};
};
