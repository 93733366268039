import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { updateRewards } from '../../../../actions/Rewards';
import "../styles.scss";

class ArchiveDialog extends React.Component {
    constructor(props) {
        super(props);
    }

    handleSubmit = () => {
        this.props.updateRewards({
            id: this.props._id,
            params: {
                is_active: false,
                archive_date: new Date()
            }
        });
    };

    render() {
        return (
            <Modal className="admin"
                triggerBtnText="Archive Reward"
                triggerBtnClassName="button button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Archive"
                onSubmit={() => this.handleSubmit()}
            >
                Do you really want to archive this reward?
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateRewards: (payload) => dispatch(updateRewards(payload))
    }
}

const mapStateToProps = state => {
    return {

    }
}

ArchiveDialog = connect(mapStateToProps, mapDispatchToProps)(ArchiveDialog);

export default ArchiveDialog