import {
  getUserLifetimePoints,
  getUserSpendingPoints,
  getUserMembershipPoints,
} from "../../actions/UserAdminPanel";

import {
  showLoader,
  hideLoader
} from '../../actions/GlobalAction'

import {
  takeLatest,
  call,
  put
} from 'redux-saga/effects';

import {
  ADMIN_POINTS_CHANGE_WATCHER,
} from '../../constants/ActionTypes'

import {
  ADMIN_POINTS_CHANGE_URL,
} from '../../constants/Url'

import api from '../../util/api'; 

export function* adminPointsChangeWatcher() {
  yield takeLatest(ADMIN_POINTS_CHANGE_WATCHER, adminPointsChangeWorker);
}

function* adminPointsChangeWorker(action) {
  try {
    yield put(showLoader());
    // data is obtained after axios call is resolved
    let response = yield call(adminPointsChangeAPI, action.payload);
    if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
      yield put(getUserLifetimePoints(response.data.data.userId));
      yield put(getUserSpendingPoints(response.data.data.userId));
      yield put(getUserMembershipPoints(response.data.data.userId));

    } else {
      throw (response);
    }
    yield put(hideLoader());
  } catch (e) {
    console.log(e)
    yield put(hideLoader());
  }
}



function adminPointsChangeAPI(payload) {
  const {
    userId
  } = payload;

  return (
    api
    .put(ADMIN_POINTS_CHANGE_URL(userId), {
      ...payload
    })
    .catch(err => {
      return err.response.data
    })
  );
};
