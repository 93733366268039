import React from "react";
import PropTypes from "prop-types";

// Components
import OrderItem from "../OrderItem";

function OrderContent({ order, reward }) {
	return (
		<section className="lp_order__content">
			<OrderItem reward={reward} order={order} />
		</section>
	);
}

OrderContent.propTypes = {
	order: PropTypes.object,
	reward: PropTypes.object,
};

export default OrderContent;
