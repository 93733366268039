import {
    SET_TOTAL_USERS,
    SET_SEARCHED_USERS,
    SET_ALL_STAFF,
    SET_ROLES,
    SET_USER_ACCOUNT_INFO,
    SET_SELECTED_USER_LIFETIME_POINTS,
    SET_SELECTED_USER_MEMBERSHIP_POINTS,
    SET_SELECTED_USER_SPENDING_POINTS,
    SET_SELECTED_USER_INTERACTIONS,
    SET_SELECTED_USER_REFERRALS,
    SET_ADMIN_USER_ORDERS,
    SET_INTERACTIONS,
    SET_POINTS_HISTORY,
    SET_BAN_USERS,
    SET_PERMISSION_LIST
} from "../constants/ActionTypes";

const INIT_STATE = {
    users: [],
    staff: [],
    roles: [],
    user_account_info: {},
    user_interactions: [],
    user_interactions_count: 0,
    userOrders: null,
    userOrdersCount: 0,
    referrals: null,
    referralsCount: 0,
    ban_history: [],
    banCount: 0,
    points_history: [],
    points_history_count:0,
    service_logs: [],
    permissions: []
};

export default function UserAdminReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case SET_TOTAL_USERS: {
            return {
                ...state,
                count_users: action.payload
            }
        }
        case SET_SEARCHED_USERS: {
            return {
                ...state,
                users: action.payload
            }
        }
        case SET_ALL_STAFF: {
            return {
                ...state,
                staff: action.payload
            }
        }
        case SET_ROLES: {
            return {
                ...state,
                roles: action.payload
            }
        }
        case SET_USER_ACCOUNT_INFO: {
            return {
                ...state,
                user_account_info: action.payload
            }
        }
        case SET_SELECTED_USER_LIFETIME_POINTS: {
            return {
                ...state,
                lifetime_points: action.payload,
            }
        }
        case SET_SELECTED_USER_MEMBERSHIP_POINTS: {
            return {
                ...state,
                membership_points: action.payload,
            }
        }
        case SET_SELECTED_USER_SPENDING_POINTS: {
            return {
                ...state,
                spending_points: action.payload,
            }
        }
        case SET_SELECTED_USER_INTERACTIONS: {
            return {
                ...state,
                user_interactions: action.payload.data,
                user_interactions_count: action.payload.count
            }
        }
        case SET_SELECTED_USER_REFERRALS: {
            return {
                ...state,
                referrals: action.payload.data,
                referralsCount: action.payload.count
            }
        }
        case SET_ADMIN_USER_ORDERS: {
            return {
                ...state,
                userOrders: action.payload,
                userOrdersCount: action.payload.length
            }
        }
        case SET_INTERACTIONS: {
            return {
                ...state,
                interactions: action.payload
            }
        }
        case SET_POINTS_HISTORY: {
            return {
                ...state,
                points_history: action.payload.data,
                points_history_count: action.payload.count
            }
        }
        case SET_BAN_USERS: {
            return {
                ...state,
                ban_history: action.payload.details,
                banCount: action.payload.count
            }
        }
        case SET_PERMISSION_LIST: {
            return {
                ...state,
                permissions: action.payload
            }
        }
        default:
            return state;
    }
}