import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import "./styles/index.scss";

// Components
import Input from "./atoms/Input";
import LpInputValidationError from "../LpInputValidationError";

// Constants
import { MIN_DATE } from "../../constants/DefaultBirthday";

// Utils
import { utcToLocale } from "../../util/DateUtil";
import { inputNumberLimit, trimNumber } from "../../util/stringUtils";

class LpBirthDateInput extends Component {
	input_year = createRef();
	input_month = createRef();
	input_day = createRef();

	state = {
		birth_year: "",
		birth_month: "",
		birth_day: "",
	};

	componentDidMount = () => {
		const { initial_value } = this.props;

		if (initial_value) {
			this.setInitialBirthday();
		}
	};

	setInitialBirthday = () => {
		const { initial_value, handleChangeDay, handleChangeMonth, handleChangeYear } = this.props;
		const birth_date = utcToLocale(new Date(initial_value));
		this.setState(
			{
				birth_year: String(birth_date.getFullYear()),
				birth_month: String(birth_date.getMonth() + 1).padStart(2, "0"),
				birth_day: String(birth_date.getDate()).padStart(2, "0"),
			},
			() => {
				const { birth_year, birth_month, birth_day } = this.state;
				handleChangeYear(birth_year);
				handleChangeMonth(birth_month);
				handleChangeDay(birth_day);
			}
		);
	};

	focusNextInput = (input) => {
		input.current.focus();
	};

	getDaysInMonth = () => {
		const { birth_month, birth_year } = this.state;

		return birth_month && birth_year ? new Date(birth_year, birth_month, 0).getDate() : 31;
	};

	getMinYear = () => {
		const { min_date } = this.props;
		return min_date.getFullYear();
	};

	getMaxYear = () => {
		const { max_date } = this.props;
		return max_date.getFullYear();
	};

	getMinMonth = () => {
		const { min_date } = this.props;
		const { birth_year } = this.state;
		const min_year = this.getMinYear();
		return min_year === Number(birth_year) ? min_date.getMonth() + 1 : 1;
	};

	getMaxMonth = () => {
		const { max_date } = this.props;
		const { birth_year } = this.state;
		const max_year = this.getMaxYear();
		return max_year === Number(birth_year) ? max_date.getMonth() + 1 : 12;
	};

	getMinDay = () => {
		const { min_date } = this.props;
		const { birth_year, birth_month } = this.state;
		const min_year = this.getMinYear();
		const min_month = this.getMinMonth();
		return min_year === Number(birth_year) && min_month === Number(birth_month)
			? min_date.getDate()
			: 1;
	};

	getMaxDay = () => {
		const { max_date } = this.props;
		const { birth_year, birth_month } = this.state;
		const max_year = this.getMaxYear();
		const max_month = this.getMaxMonth();
		return max_year === Number(birth_year) && max_month === Number(birth_month)
			? max_date.getDate()
			: this.getDaysInMonth();
	};

	checkMaxDay = () => {
		const { birth_day } = this.state;
		const { handleChangeDay } = this.props;
		const max_day = this.getMaxDay();

		if (birth_day && birth_day > max_day) {
			this.setState({ birth_day: max_day.toString() }, () => handleChangeDay(birth_day));
		} else {
			return;
		}
	};

	onChangeYear = (event) => {
		const { value } = event.target;
		const { handleChangeYear } = this.props;
		if (value.length > 4) {
			return;
		}
		this.setState(
			{
				birth_year: trimNumber(value),
			},
			() => {
				const { birth_year } = this.state;
				handleChangeYear(birth_year);
				if (birth_year.length === 4) {
					this.focusNextInput(this.input_month);
				}
			}
		);
	};

	onChangeMonth = (event) => {
		const { value } = event.target;
		const { handleChangeMonth } = this.props;
		if (value.length > 2) {
			return;
		}
		this.setState(
			{
				birth_month: trimNumber(value),
			},
			() => {
				const { birth_month } = this.state;
				handleChangeMonth(birth_month);
				this.checkMaxDay();
				if (birth_month.length === 2) {
					this.focusNextInput(this.input_day);
				} else if (birth_month.length === 0) {
					this.focusNextInput(this.input_year);
				}
			}
		);
	};

	onChangeDay = (event) => {
		const { value } = event.target;
		const { handleChangeDay } = this.props;
		if (value.length > 2) {
			return;
		}
		const day = inputNumberLimit(trimNumber(value), this.getDaysInMonth());
		this.setState(
			{
				birth_day: day.toString(),
			},
			() => {
				const { birth_day } = this.state;
				if (birth_day.length === 2) {
					const min_day = this.getMinDay();
					const max_day = this.getMaxDay();
					this.setState(
						{
							birth_day:
								birth_day < min_day
									? min_day.toString().padStart(2, "0")
									: birth_day > max_day
									? max_day.toString().padStart(2, "0")
									: birth_day,
						},
						() => handleChangeDay(birth_day)
					);
					return;
				}
				handleChangeDay(birth_day);
				if (birth_day.length === 0) {
					this.focusNextInput(this.input_month);
				}
			}
		);
	};

	handleBlurYear = (event) => {
		const { value } = event?.target;
		const { handleChangeYear } = this.props;

		const input_year = parseInt(value);
		const min_year = this.getMinYear();
		const max_year = this.getMaxYear();
		const filedYear =
			input_year < min_year
				? min_year.toString()
				: input_year > max_year
				? max_year.toString()
				: input_year.toString();
		return value
			? this.setState(
					{
						birth_year: filedYear,
					},
					() => handleChangeYear(filedYear)
			  )
			: null;
	};

	handleBlurMonth = (event) => {
		const { value } = event.target;
		const { handleChangeMonth } = this.props;
		const { birth_month } = this.state;
		const input_month = parseInt(value);
		const min_month = this.getMinMonth();
		const max_month = this.getMaxMonth();
		return value
			? this.setState(
					{
						birth_month:
							input_month < min_month
								? min_month.toString().padStart(2, "0")
								: input_month > max_month
								? max_month.toString().padStart(2, "0")
								: input_month.toString().padStart(2, "0"),
					},
					() => handleChangeMonth(birth_month)
			  )
			: null;
	};

	handleBlurDay = (event) => {
		const { value } = event.target;
		const { handleChangeDay } = this.props;
		const { birth_day } = this.state;
		const input_day = parseInt(value);
		const min_day = this.getMinDay();
		const max_day = this.getMaxDay();
		return value.length === 1
			? this.setState(
					{
						birth_day:
							input_day < min_day
								? min_day.toString().padStart(2, "0")
								: input_day > max_day
								? max_day.toString().padStart(2, "0")
								: `0${value}`,
					},
					() => handleChangeDay(birth_day)
			  )
			: null;
	};

	handleFocus = (event) => {
		const { onFocus } = this.props;
		if (onFocus) {
			onFocus();
		}
		event.target.select();
	};

	render() {
		const { validation_error, className, t } = this.props;
		const { birth_month, birth_day, birth_year } = this.state;

		return (
			<div className={classnames("lp_birth_date_input", className)}>
				<span>{t("dateOfBirth.label")}</span>
				<Input
					className="lp_birth_date_input__year"
					ref={this.input_year}
					label={t("dateOfBirth.year")}
					name="input_year"
					value={birth_year}
					onChange={this.onChangeYear}
					onBlur={this.handleBlurYear}
					onFocus={this.handleFocus}
				/>
				<Input
					className="lp_birth_date_input__month"
					ref={this.input_month}
					label={t("dateOfBirth.month")}
					name="input_month"
					value={birth_month}
					onChange={this.onChangeMonth}
					onBlur={this.handleBlurMonth}
					onFocus={this.handleFocus}
				/>
				<Input
					className="lp_birth_date_input__day"
					ref={this.input_day}
					label={t("dateOfBirth.day")}
					name="input_day"
					value={birth_day}
					onChange={this.onChangeDay}
					onBlur={this.handleBlurDay}
					onFocus={this.handleFocus}
				/>
				{validation_error ? (
					<LpInputValidationError
						message={t("dateOfBirth.enterError")}
						className="lp_birth_date_input__error"
					/>
				) : null}
			</div>
		);
	}
}

LpBirthDateInput.defaultProps = {
	min_date: new Date(MIN_DATE.birth_year, MIN_DATE.birth_month - 1, MIN_DATE.birth_day),
	max_date: new Date(),
};

LpBirthDateInput.propTypes = {
	min_date: PropTypes.instanceOf(Date),
	max_date: PropTypes.instanceOf(Date),
	initial_value: PropTypes.string,
	validation_error: PropTypes.string,
	handleChangeYear: PropTypes.func.isRequired,
	handleChangeMonth: PropTypes.func.isRequired,
	handleChangeDay: PropTypes.func.isRequired,
	className: PropTypes.string,
	onFocus: PropTypes.func,
	t: PropTypes.func.isRequired,
};

export default withTranslation("formFields")(LpBirthDateInput);
