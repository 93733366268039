import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import LpHeading from "../../../components/LpHeading";
import { Link } from "react-router-dom";
import { fetchTiers } from "../../../actions/Tiers";
import "./styles.scss";

class Tiers extends Component {
	constructor(props) {
		super(props);
	}

	componentWillMount() {
		this.props.fetchTiers();
	}

	getTierName(name) {
		return name || "<NO NAME>";
	}

	getThreshold(threshold_description, points_required) {
		return threshold_description || points_required + " Points";
	}

	mapTiers() {
		if (this.props.tiers) {
			return this.props.tiers.map((tier, id) => {
				return (
					<TableRow key={id}>
						<TableCell
							className="data-table__data-cell"
							width="95%"
						>
							<div class="data-table__multi-line">
								<figure
									class={
										"data-table__inline-image tier-color tier-color--tier-" +
										tier.rank
									}
								>
									<span className="tier-color-primary"></span>
									<span className="tier-color-secondary"></span>
								</figure>
								<div>
									<span class="data-table__item-title">
										{this.getTierName(tier.name)}
									</span>
									<br />
									<span class="data-table__item-sub-title">
										{this.getThreshold(
											tier.threshold_description,
											tier.points_required
										)}
									</span>
								</div>
							</div>
						</TableCell>

						<TableCell className="data-table__data-cell" width="5%">
							<Link to={`/app/settings/tiers/${tier.id}`}>
								<button class="button button--circle button--borderless">
									<span>
										<i className="zmdi zmdi-chevron-right zmdi-hc-lg" />
									</span>
								</button>
							</Link>
						</TableCell>
					</TableRow>
				);
			});
		}
	}

	render() {
		return (
			<div className="m-3 p-3 admin">
				<div className="col-12 pt-2">
					<LpHeading>Tiers</LpHeading>
				</div>
				<Table className="data-table">
					<TableBody>{this.mapTiers()}</TableBody>
				</Table>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchTiers: () => dispatch(fetchTiers()),
	};
};

const mapStateToProps = (state) => {
	return {
		tiers: state.tiers.bank,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Tiers);
