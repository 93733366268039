import React, { Component } from "react";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import classnames from "classnames";
import LpLabel from "../LpLabel";
import Options from "./atoms/Options";
import "./styles.scss";

class MultiSelectInput extends Component {
	constructor(props) {
		super(props);

		this.select = React.createRef();
		this.input = React.createRef();

		this.state = {
			is_open: false,
			search: "",
		};
	}

	componentDidMount = () => {
		document.addEventListener("mousedown", this.handleClickOutside);
	};

	componentWillUnmount = () => {
		document.removeEventListener("mousedown", this.handleClickOutside);
	};

	handleClickOutside = (event) => {
		if (!this.select?.current.contains(event.target)) {
			this.setState({
				is_open: false,
			});
		}
	};

	toggleOpen = () => {
		this.setState(
			(state) => ({
				is_open: !state.is_open,
				search: "",
			}),
			() => {
				if (this.state.is_open) {
					this.input.current.focus();
				}
			}
		);
	};

	getVisibleOptions = () => {
		const { selected_values, options } = this.props;
		let visible_options = [...options];
		if (selected_values.length > 0) {
			selected_values.map((selected_value) => {
				visible_options = visible_options.filter(
					(option) => !isEqual(option.value, selected_value)
				);
			});
		}

		return visible_options;
	};

	search = (event) => {
		this.setState({
			search: event?.target?.value,
		});
	};

	render() {
		const {
			className,
			intro,
			name,
			label,
			placeholder,
			selected_values,
			options,
			onRemove,
			onChange,
			required,
		} = this.props;
		const { is_open, search } = this.state;
		const visible_options = this.getVisibleOptions();

		return (
			<div className={classnames("multi-select", className)}>
				{intro ? <div className="multi-select__intro">{intro}</div> : null}
				<label className="multi-select__label" htmlFor={name}>
					{label}
				</label>
				<div
					className={classnames("multi-select__input", {
						"multi-select__input--open": is_open,
					})}
					ref={this.select}
					onClick={this.toggleOpen}
				>
					<input
						className="multi-select__hidden_input"
						required={required}
						value={selected_values}
					/>
					<div className="multi-select__selection">
						{options && selected_values.length > 0 && is_open
							? selected_values.map((selected_value) =>
									options.map(
										(option) =>
											isEqual(selected_value, option.value) && (
												<LpLabel
													key={option.label}
													className="multi-select__selected-value--open"
													value={option.value}
													onRemove={onRemove}
												>
													{option.label}
												</LpLabel>
											)
									)
							  )
							: null}

						{selected_values.length > 0 && !is_open && (
							<span className="multi-select__selected-value--closed">
								{selected_values.map((selected_value, index) =>
									options.map(
										(option) =>
											isEqual(selected_value, option.value) &&
											option.label +
												(index !== selected_values.length - 1 ? ", " : "")
									)
								)}
							</span>
						)}
					</div>
					{is_open ? (
						<input
							className="multi-select__search"
							type="text"
							ref={this.input}
							onClick={(event) => event.stopPropagation()}
							onChange={this.search}
							placeholder={placeholder}
						/>
					) : null}
					<span className="multi-select__icon"></span>
					{is_open ? (
						<ul className="multi-select__options">
							{options?.length > 0 ? (
								<Options
									options={visible_options}
									search={search}
									onChange={onChange}
								/>
							) : (
								<li className="multi-select__placeholder">No options available</li>
							)}
						</ul>
					) : null}
				</div>
			</div>
		);
	}
}

MultiSelectInput.propTypes = {
	className: PropTypes.string,
	theme: PropTypes.string,
	intro: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	selected_values: PropTypes.array.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.any.isRequired,
		})
	).isRequired,
	onChange: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
	required: PropTypes.bool,
};

MultiSelectInput.defaultProps = {
	selected_values: [],
};

export default MultiSelectInput;
