export const FULFILMENT_OPTIONS = {
	SHOPIFY: "SHOPIFY",
	MANUAL: "MANUAL",
};

export const ORDER_TYPES = {
	CUSTOM: "CUSTOM",
	PENDING: "PENDING",
	FULFILLED: "FULFILLED",
	MISC: "MISC",
	MARK_AS_CONTACTED: "Mark as Contacted",
	MARK_AS_NOT_CONTACTED: "Mark as not Contacted",
	MARK_AS_FULFILLED: "Mark as Fulfilled",
	MARK_AS_NOT_FULFILLED: "Mark as not Fulfilled",
};

export const AGE_RESTRICTION_TYPE = [
	{ label: "None", value: "NONE" },
	{ label: "18+", value: "18+" },
];

export const CUSTOMER_SERVICE_TYPE = [
	{ label: "Automatic Fulfillment", value: "SHOPIFY" },
	{ label: "Manual Fulfillment", value: "MANUAL" },
];

export const MARKETPLACES = {
	TEAM_LIQUID: "teamLiquid",
	STORE: "store",
	EPICGAMES: "epicGames",
	DISCORD: "discord",
	RIOT: "riot",
};

export const MARKET_PLACE_TYPE = [
	{ label: "Team Liquid", value: "TEAM_LIQUID" },
	{ label: "Store", value: "STORE" },
	{ label: "Epic Games", value: "EPICGAMES" },
	{ label: "Discord", value: "DISCORD" },
	{ label: "Riot", value: "RIOT" },
];

export const ACTIVE_PERIOD = {
	"24H": "24 Hours",
	"1WK": "1 Week",
	"1MO": "1 Month",
	"3MO": "3 Months",
	INFINITE: "Infinite",
};

export const ACTIVE_PERIODS = [
	{ label: "24 Hours", value: "24H" },
	{ label: "1 Week", value: "1WK" },
	{ label: "1 Month", value: "1MO" },
	{ label: "3 Months", value: "3MO" },
	{ label: "Infinite", value: "INFINITE" },
];

export const ACTIVE_STATUS = [
	{ label: "Active", value: true },
	{ label: "Inactive", value: false },
];

export const ACQUISITION_TYPE = [{ label: "Purchasable", value: "PURCHASABLE" }];

export const TRIGGER_TYPE = [
	{ label: "None", value: "NONE" },
	{ label: "Trigger 1", value: "TRIG1" },
	{ label: "Trigger 2", value: "TRIG2" },
	{ label: "Trigger 3", value: "TRIG3" },
	{ label: "Trigger 4", value: "TRIG4" },
];

export const ALL = "all";
export const DIGITAL = "DIGITAL";
export const PHYSICAL = "PHYSICAL";
export const ASCENDING = "asc";
export const DESCENDING = "desc";
