import {
	FETCH_REWARD_FAQS,
	CREATE_REWARD_FAQS,
	UPDATE_REWARD_FAQS,
} from "../constants/ActionTypes";

export const fetchRewardFaqs = (params, options) => {
	return {
		type: FETCH_REWARD_FAQS,
		params,
		options,
	};
};

export const createRewardFaqs = (params, options) => {
	return {
		type: CREATE_REWARD_FAQS,
		payload: {
			params,
		},
		options,
	};
};

export const updateRewardFaqs = (params, options) => {
	return {
		type: UPDATE_REWARD_FAQS,
		payload: {
			params,
		},
		options,
	};
};
