import {
    SET_ADMIN_REWARD_TAB,
    SET_POINTS_PROCESSING_EVENTS,
    FETCH_POINTS_PROCESSING_EVENTS_WATCHER,
    INSTANT_PROCESS_POINTS_WATCHER,
} from "../constants/ActionTypes";

export const setRewardTab = (tab) => {
    return {
        type: SET_ADMIN_REWARD_TAB,
        payload: tab
    }
}

export const setPointsProcessingEvents = (events) => {
    return {
        type: SET_POINTS_PROCESSING_EVENTS,
        payload: events
    }
}

export const fetchPointsProcessingEvents = () => {
    return {
        type: FETCH_POINTS_PROCESSING_EVENTS_WATCHER
    }
}

export const instantProcessPoints = (events) => {
    return {
        type: INSTANT_PROCESS_POINTS_WATCHER,
        payload: events
    }
}