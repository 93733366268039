import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { FormFieldInput } from '../../../../components/FormFields';
import { updateRewards } from '../../../../actions/Rewards';
import "../styles.scss";

class PuchaseCapDialog extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.setState({
            purchase_cap: this.props.purchase_cap
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            purchase_cap: nextProps.purchase_cap,
        })
    }

    handleSubmit = () => {
        this.props.updateRewards({
            id: this.props._id,
            params: {
                purchase_cap: this.state.purchase_cap
            }
        });
    };

    handleClose() {
        this.setState({
            purchase_cap: this.props.purchase_cap
        });
    }

    checkValidation() {
        return (
            this.state.purchase_cap == this.props.purchase_cap
        )
    }

    handleInputChange(event) {
        this.setState({ purchase_cap: event.target.value });
    }

    render() {
        return (
            <Modal
                className="admin"
                triggerBtnText="Change"
                triggerBtnClassName="button button--secondary button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Save"
                submitDisabled={this.checkValidation()}
                onSubmit={() => this.handleSubmit()}
                onClose={() => this.handleClose()}
            >
                <FormFieldInput
                    intro={"Set a puchase cap on this item. This is the number of items that someone is allowed to redeem while the item is still ‘active’."}
                    type={"number"}
                    name={"purchase_cap"}
                    value={this.state.purchase_cap}
                    maxLength={"95"}
                    onChange={(event) => this.handleInputChange(event)}
                />
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateRewards: (payload) => dispatch(updateRewards(payload))
    }
}

const mapStateToProps = state => {
    return {

    }
}

PuchaseCapDialog = connect(mapStateToProps, mapDispatchToProps)(PuchaseCapDialog);

export default PuchaseCapDialog