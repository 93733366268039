import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./styles.scss";

/* eslint-disable complexity */
const TLButton = ({
	label,
	className,
	variant,
	icon,
	size,
	onClick,
	ariaLabel,
	inverse,
	fullWidth,
	disabled,
	type,
}) => {
	return (
		<button
			className={classnames(
				"tl_button",
				`tl_button--${variant}`,
				{
					"tl_button--large": size === "large" && variant !== "icon",
					"tl_button--full_width": fullWidth && variant !== "icon",
					"tl_button--inverse": inverse,
				},
				className
			)}
			onClick={onClick}
			disabled={disabled}
			aria-label={ariaLabel || label}
			type={type}
		>
			{variant !== "icon" && <span className="tl_button__label">{label}</span>}
			{icon && variant !== "tertiary" ? (
				<span className="tl_button__icon">{icon}</span>
			) : null}
		</button>
	);
};

TLButton.defaultProps = {
	variant: "primary",
	type: "button",
};

TLButton.propTypes = {
	className: PropTypes.string,
	label: function (props, propName, componentName) {
		// Requires prop if `variant` is not "icon"
		if (props["variant"] !== "icon" && props[propName] === undefined) {
			return new Error(
				"The prop `" +
					propName +
					"` is marked as required in `" +
					componentName +
					"`, but its value is `undefined`."
			);
		}

		// Requires prop is of type `string` when used
		if (props[propName] !== undefined && typeof props[propName] !== "string") {
			return new Error(
				"Invalid prop `disabledIcon` of type `" +
					typeof props[propName] +
					"` supplied to `" +
					componentName +
					"`, expected `string`"
			);
		}
	},
	variant: PropTypes.oneOf(["primary", "secondary", "tertiary", "icon", "negative"]).isRequired,
	icon: function (props, propName, componentName) {
		// Requires prop if `variant` is "icon"
		if (props["variant"] === "icon" && props[propName] === undefined) {
			return new Error(
				"The prop `" +
					propName +
					"` is marked as required in `" +
					componentName +
					"`, but its value is `undefined`."
			);
		}

		// Requires prop is of type `object` when used
		if (props[propName] !== undefined && typeof props[propName] !== "object") {
			return new Error(
				"Invalid prop `disabledIcon` of type `" +
					typeof props[propName] +
					"` supplied to `" +
					componentName +
					"`, expected `object`"
			);
		}
	},
	onClick: PropTypes.func,
	ariaLabel: PropTypes.string,
	size: PropTypes.oneOf(["large"]),
	inverse: PropTypes.bool,
	fullWidth: PropTypes.bool,
	disabled: PropTypes.bool,
	type: PropTypes.string,
};

export default TLButton;
