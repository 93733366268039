import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "../styles.scss";

// Components
import TLButton from "../../../../components/TLButton";
import TLHeading from "../../../../components/TLHeading";
import TLParagraph from "../../../../components/TlParagraph";

// Translations
import "./translations";

// Utils
import useDocumentTitle from "../../../../util/hooks/useDocumentTitle";

function Error404() {
	const { t } = useTranslation("error404");

	useDocumentTitle(t("pageTitle"));

	return (
		<div className="error_page">
			<div className="error_page__content">
				<figure className="error_page__illustration">
					<img
						className="error_page__image"
						src={require("../../../../svg/error-404.svg")}
						title={t("blueIconAltText")}
						alt={t("blueIconAltText")}
					/>
				</figure>
				<div className="error_page__message">
					<TLHeading variant="main-page" className="error_page__title">
						{t("title")}
					</TLHeading>
					<TLParagraph responsive>{t("copy")}</TLParagraph>
					<Link to="/">
						<TLButton
							label={t("buttons:backToLiquidPlus")}
							variant="primary"
							type="button"
							fullWidth
						/>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default Error404;
