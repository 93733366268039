import {
	setUserAccountInfo,
	getUserLifetimePoints,
	getUserSpendingPoints,
	getUserMembershipPoints,
} from "../../actions/UserAdminPanel.js";
import { takeLatest, call, put } from "redux-saga/effects";
import { appendNotifications } from "../../actions/Notifications.js";
import { SUCCESS, ERROR } from "../../constants/Notifications.js";
import { FETCH_USER_ACCOUNT_INFO_WATCHER } from "../../constants/ActionTypes.js";
import { USER_ACCOUNT_INFO_URL } from "../../constants/Url.js";
import api from "../../util/api.js";

export function* fetchUserAccountInfoWatcher() {
	yield takeLatest(
		FETCH_USER_ACCOUNT_INFO_WATCHER,
		fetchUserAccountInfoWorker
	);
}

function* fetchUserAccountInfoWorker(action) {
	try {
		const response = yield call(fetchUserAccountInfoApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess
			? action?.options?.success
			: action?.options?.failure;

		if (isSuccess) {
			yield put(setUserAccountInfo(response.data.data));
		}
		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.refreshPoints) {
			yield put(getUserLifetimePoints(response.data.data.id));
			yield put(getUserSpendingPoints(response.data.data.id));
			yield put(getUserMembershipPoints(response.data.data.id));
		}

		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		}
	} catch (e) {
		console.error(e);
	}
}

function fetchUserAccountInfoApi(payload) {
	return api.get(USER_ACCOUNT_INFO_URL(payload));
}
