import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Option from "../Option";

const MAX_OPTIONS = 3;

function Options({ options, onChange, search }) {
	if (search.length > 0) {
		const filtered_options = options.filter((option) =>
			option.label.toLowerCase().includes(search.toLowerCase())
		);

		if (filtered_options.length === 0) {
			return (
				<li className="multi-select__option multi-select__option--inactive">
					No options available
				</li>
			);
		}

		return (
			<Fragment>
				{filtered_options.map((option) => (
					<Option key={option.label} option={option} onChange={onChange} />
				))}
			</Fragment>
		);
	} else if (options.length > MAX_OPTIONS + 1) {
		// displays 1 extra element instead of message saying there is 1 extra option
		const optionsLeft = options.length - MAX_OPTIONS;

		return (
			<Fragment>
				{options.slice(0, MAX_OPTIONS).map((option) => (
					<Option key={option.label} option={option} onChange={onChange} />
				))}
				<li className="multi-select__option multi-select__option--inactive">
					{`${optionsLeft} More Entr${optionsLeft > 1 ? "ies" : "y"}...`}
				</li>
			</Fragment>
		);
	} else {
		return (
			<Fragment>
				{options.map((option) => (
					<Option key={option.label} option={option} onChange={onChange} />
				))}
			</Fragment>
		);
	}
}

Options.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.any.isRequired,
		})
	).isRequired,
	onChange: PropTypes.func.isRequired,
	search: PropTypes.string.isRequired,
};

export default Options;
