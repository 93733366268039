import { call, put, takeLatest } from "redux-saga/effects";

import { UPDATE_MULTIPLE_QUESTS_WATCHER } from "../../constants/ActionTypes";
import { adminFetchQuests } from "../../actions/Quests";
import api from "../../util/api";
import { UPDATE_MULTIPLE_QUESTS_URL } from "../../constants/Url";

export function* updateMultipleQuestsWatcher() {
	yield takeLatest(UPDATE_MULTIPLE_QUESTS_WATCHER, updateMultipleQuestsWorker);
}

export function* updateMultipleQuestsWorker(action) {
	try {
		const response = yield call(updateMultipleQuestsAPI, action.payload);

		if (response.status >= 200 && response.status < 300) {
			yield put(adminFetchQuests());
		} else {
			throw response;
		}
	} catch (error) {
		console.error(error);
	}
}

function updateMultipleQuestsAPI(quests) {
	return api.put(UPDATE_MULTIPLE_QUESTS_URL, quests);
}
