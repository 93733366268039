import {
    takeLatest,
    call,
    put,
} from "redux-saga/effects";
import {
    push,
} from "react-router-redux";
import {
	ADD_TO_CLAIMED_PERKS,
} from "../../../constants/ActionTypes";
import {
	USER_CLAIMED_PERK_URL,
} from "../../../constants/Url";
import {
	SUCCESS,
	ERROR,
} from "../../../constants/Notifications";
import {
	appendNotifications,
} from "../../../actions/Notifications";
import {
    fetchUserPerks,
 } from '../../../actions/Perks';

import api from '../../../util/api';

export function* addToClaimedPerksWatcher() {
	yield takeLatest(ADD_TO_CLAIMED_PERKS, addToClaimedPerksWorker);
};

/**
 * @param {String} action.params.user_id The ID of the user to update.
 * @param {Enum} action.params.status The status of the claimed perk
 * @param {String} action.params.perk_id The ID of the perk
 *
 * @param {Object} [action.options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [action.options.success] Behavior to execute when API call and action is successful.
 * @param {String} [action.options.success.redirect] Redirect to this URL on success.
 * @param {String} [action.options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [action.options.success.notification] Display notification with passed text on success.
 * @param {Function} [action.options.success.callback] Callback function on success.
 * @param {*} [action.options.success.callbackArgs] Callback function args on success.
 * @param {Boolean} [action.options.success.refreshPerks] Update the Redux store with the user's updated perks.
 *
 * @param {Object} [action.options.failure] Behavior to execute when API call and action is failed.
 * @param {String} [action.options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [action.options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [action.options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [action.options.failure.callback] Callback function on failure.
 * @param {*} [action.options.failure.callbackArgs] Callback function args on failure.
 */
function* addToClaimedPerksWorker(action) {
	try {
		const response = yield call(addToClaimedPerksApi, action.params);
        const isSuccess = response.status >= 200 && response.status < 300;

		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (options?.notification) {
			yield put(appendNotifications({
				type: isSuccess ? SUCCESS : ERROR,
				message: options.notification,
			}));
		};
		if (options?.refreshPerks && isSuccess) {
			yield put(fetchUserPerks());
		};
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		};
		if (options?.redirect) {
			yield put(push(options.redirect));
		};
    } catch (e) {
        console.error(e);
    };
};

/**
 * Sends an API request to add an entry to ```claimed_perks``` tabke.
 * @param {String} params.user_id The ID of the user to update.
 * @param {Enum} params.status The status of the claimed perk
 * @param {String} params.perk_id The ID of the perk
 * @return {Object} The response object from the API call.
 */
function addToClaimedPerksApi(params) {
    return api
        .post(USER_CLAIMED_PERK_URL(params.user_id), {
			status: params.status,
			perk_id: params.perk_id,
		})
        .then(response => {
			return response
		})
        .catch(error => {
			return error.response
		});
};