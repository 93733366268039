import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import FormFieldInput from "./FormFieldInput";
import FormFieldTextarea from "./FormFieldTextarea";
import PasswordInput from "./PasswordInput";

/*
	DOCUMENT INTRODUCTION
 	Custom form field components
	Author: Luuk Barten
*/

/*
	COMPONENTS
 	> FormFieldInput		//  Default single line input field, type can be "text" or "number".
    > FormFieldTextarea		//  Textarea input. Set "allow_formatting" to "true" to enable the SunEditor.
                                Examples when "allow_formatting" is "true":
                                    onChange={(content) => console.log(content);}
                                    height={400}    //height of content box in pixels
                                Note: When SunEditor is used:
                                    Changing the state will not change the contents.
                                    The "value" prop is only used to set the initial contents.  After that,
                                    the editor will pass the contents to the onChange prop but will not
                                    change the contents if the value prop changes.
    > FormFieldRadio        //  Renders a set of button elements (styled as buttons) that act as radio controls.
                                Takes options with "label" and "value".
    > FormFieldSelect       //  Renders custom drop down select with a set of options to choose from.
                                Takes options with "label" and "value".
    > FormFieldMultiInput   //  Renders a set of labels of currently selected items. Current items can be removed with the [x].
                                When the icon in the right is clicked more items can be added.
                                Takes options with "label" and "value" for all the possible options.
                                Takes values with "label" and "value" for currently selected values.
*/

/*
    USAGE

    Components must reside in an (grand)parent element with class "admin".

    Most components take the following arguments
    > intro                 //  Introduction text rendered before the label.
    > label                 //  Label above the FormField.
    > value                 //  The current value (usually set to the value in state).
    > id                    //  Will be rendered as the label "for" attribute and the input element's "id".
    > placeholder           //  Placeholder with example value.
    > pattern               //  HTML patter attribute to make user of pattern detection.
    > maxlength             //  Max number of characters that can be entered.
    > onChange              //  Will be called with an event object as payload.
*/

/*
  HANDLING ONCHANGE
  See /src/app/routes/AdminQuests/QuestCreate or /src/app/routes/AdminQuests/QuestView pages for examples how to handle onChange.
*/

export { FormFieldInput, FormFieldTextarea, PasswordInput };

class FormFieldRadio extends React.Component {
	render() {
		const {
			wide,
			fluid,
			className,
			intro,
			label,
			options,
			value,
			name,
			onChange,
		} = this.props;
		return (
			<div
				className={classnames(
					"form-field",
					{ "form-field-wide": wide, "form-field--fluid": fluid },
					className
				)}
			>
				<div className="form-field__intro">{intro}</div>
				{label ? (
					<label className="form-field__label">{label}</label>
				) : null}
				<div className="form-field__radio">
					{options?.map((option) => {
						return (
							<label
								key={option.value}
								className={classnames("button button--radio", {
									"button--active": value === option.value,
								})}
							>
								<input
									type="radio"
									name={name}
									value={option.value}
									checked={value === option.value}
									onChange={onChange}
								/>
								{option.label}
							</label>
						);
					})}
				</div>
			</div>
		);
	}
}

FormFieldRadio.propTypes = {
	className: PropTypes.string,
	wide: PropTypes.bool,
	intro: PropTypes.string,
	label: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.node,
		})
	),
	value: PropTypes.any,
	name: PropTypes.string,
	onChange: PropTypes.func,
};

export { FormFieldRadio };

class FormFieldSelect extends React.Component {
	constructor(props) {
		super(props);

		this.selectRef = React.createRef();
		this.state = {
			hideOptions: true,
		};
	}

	setOutsideClickListener(newstate) {
		if (newstate == true) {
			document.addEventListener("click", this.catchOutsideClick, false);
		} else if (newstate == false) {
			document.removeEventListener(
				"click",
				this.catchOutsideClick,
				false
			);
		}
	}

	catchOutsideClick = (e) => {
		// If the user clicks outside of the select, close it if it still exists.
		if (!this.selectRef.current.contains(e.target)) {
			this.setState({
				hideOptions: true,
			});

			this.setOutsideClickListener(false);
		}
	};

	toggleOptions() {
		// Start or stop listening for clicks outside of the modal.
		if (this.state.hideOptions) {
			this.setOutsideClickListener(true);
		} else {
			this.setOutsideClickListener(false);
		}

		this.setState({
			hideOptions: !this.state.hideOptions,
		});
	}

	handleOptionClick(newValue) {
		let event = {
			target: {
				name: this.props.name,
				value: newValue,
			},
		};

		this.setState({
			hideOptions: true,
		});

		this.props.onChange(event);
	}

	render() {
		return (
			<div
				className={
					this.props.wide
						? "form-field form-field--wide"
						: "form-field"
				}
			>
				{this.props.intro ? (
					<div className="form-field__intro">{this.props.intro}</div>
				) : null}
				<label className="form-field__label" htmlFor={this.props.name}>
					{this.props.label}
				</label>
				<div className="form-field__select" ref={this.selectRef}>
					<div
						className={
							this.state.hideOptions
								? "form-field__select-selected"
								: "form-field__select-selected form-field__select-selected--open"
						}
						onClick={() => this.toggleOptions()}
					>
						{this.props.options.map((option) => {
							if (
								JSON.stringify(this.props.value) ===
									JSON.stringify(option.value) &&
								option.value !== undefined
							)
								return option.label;
						})}
					</div>
					{!this.state.hideOptions ? (
						<div className="form-field__select-options">
							{this.props.options.map((option, i) => {
								return (
									<div
										key={i}
										className="form-field__select-option"
										onClick={() =>
											this.handleOptionClick(option.value)
										}
									>
										{option.label}
									</div>
								);
							})}
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export { FormFieldSelect };

class FormFieldMultiInput extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hideOptions: true,
		};

		this.handleOptionToggle = this.handleOptionToggle.bind(this);
	}

	// Filter the possible new options by removing the chose values
	filterOptions() {
		let filteredOptions = this.props.options;
		this.props.values.map((value) => {
			filteredOptions = filteredOptions.filter(function (option) {
				return option.value != value;
			});
		});

		return filteredOptions;
	}

	handleOptionToggle() {
		this.setState({
			hideOptions: !this.state.hideOptions,
		});
	}

	handleRemoveItem(item) {
		let newValues = this.props.values.filter(function (value) {
			return value != item.value;
		});

		this.props.onChange({
			target: { name: this.props.name, value: newValues },
		});
	}

	handleAddItem(item) {
		let newValues = this.props.values;
		newValues.push(item.value);

		this.props.onChange({
			target: { name: this.props.name, value: newValues },
		});
	}

	renderMultiInputItem(item) {
		return (
			<span className="form-field__multi-input__item">
				{item.label}
				<button
					className="form-field__multi-input__item-button"
					onClick={() => this.handleRemoveItem(item)}
				>
					<img src={require("../../svg/close.svg")} />
				</button>
			</span>
		);
	}

	renderMultiInputOption(option) {
		return (
			<span
				className="form-field__multi-input__option"
				onClick={() => this.handleAddItem(option)}
			>
				{option.label}
			</span>
		);
	}

	render() {
		return (
			<div disabled className="form-field">
				{this.props.intro ? (
					<div className="form-field__intro">{this.props.intro}</div>
				) : null}
				<label className="form-field__label" for={this.props.name}>
					{this.props.label}
				</label>
				<div
					className={
						this.state.hideOptions
							? "form-field__multi-input"
							: "form-field__multi-input form-field__multi-input--open"
					}
				>
					<div className="form-field__multi-input__selection">
						{this.props.values.length > 0 ? (
							this.props.values.map((value) => {
								return this.props.options.map((option) => {
									if (value == option.value) {
										return this.renderMultiInputItem(
											option
										);
									}
								});
							})
						) : (
							<span
								className="form-field__multi-input__placeholder"
								onClick={this.handleOptionToggle}
							>
								{this.props.placeholder}
							</span>
						)}
						<img
							class="form-field__multi-input__icon"
							src={require("../../svg/icon-multiselect.svg")}
							onClick={this.handleOptionToggle}
						/>
					</div>
					{!this.state.hideOptions ? (
						<div className="form-field__multi-input__options">
							{this.props.options.length > 0 ? (
								this.filterOptions().map((option) => {
									return this.renderMultiInputOption(option);
								})
							) : (
								<span className="form-field__multi-input__placeholder">
									No options available
								</span>
							)}
						</div>
					) : null}
				</div>
			</div>
		);
	}
}

export { FormFieldMultiInput };
