import { takeLatest, call, put, select } from "redux-saga/effects";

// Actions
import { setPointHistories } from "../../actions/PointHistories";
import { appendNotifications } from "../../actions/Notifications";

// Utils
import api from "../../util/api";

// Constants
import { FETCH_POINT_HISTORIES_WATCHER } from "../../constants/ActionTypes";
import { FETCH_POINT_HISTORIES_URL } from "../../constants/Url";
import { ERROR, SUCCESS } from "../../constants/Notifications";

const DEFAULT_PAGE_LIMIT = 5;

function fetchPointHistoriesApi(userId, seasonName, query) {
	return api
		.get(FETCH_POINT_HISTORIES_URL(userId, seasonName, query))
		.then((response) => response)
		.catch((error) => error.response);
}

// eslint-disable-next-line complexity
function* fetchPointHistoriesWorker(action) {
	try {
		const {
			payload: { userId, seasonName },
			options,
		} = action;
		const currentHistories = yield select((state) => state.user.point_histories);
		const seasonHistories = currentHistories?.[seasonName];

		let page = 1;
		if (seasonHistories?.page) page = seasonHistories.page + 1;

		const response = yield call(fetchPointHistoriesApi, userId, seasonName, {
			page: options?.page || page,
			limit: options?.limit || DEFAULT_PAGE_LIMIT,
		});
		const isSuccess = response.status >= 200 && response.status < 300;

		if (isSuccess) {
			const seasonData = seasonHistories?.data;
			let newData = response.data.data;

			if (options.append) newData = [...(seasonData || []), ...response.data.data];

			yield put(
				setPointHistories({
					...currentHistories,
					[action.payload.seasonName]: {
						data: newData,
						count: response.data.count,
						page: options?.page || page,
					},
				})
			);
		}

		const opts = isSuccess ? options?.success : options?.failure;
		const { callback, notification } = opts;

		if (notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: notification,
				})
			);
		}

		if (callback) yield call(callback, response.data);
	} catch (e) {
		console.error(e);
	}
}

export function* fetchPointHistoriesWatcher() {
	yield takeLatest(FETCH_POINT_HISTORIES_WATCHER, fetchPointHistoriesWorker);
}
