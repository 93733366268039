import {
    FETCH_DISCORD_CHANNEL_DATA,
    SET_DISCORD_CHANNEL,
    CREATE_DISCORD_SERVER,
    DELETE_DISCORD_SERVER,
    FETCH_TWITCH_CHANNEL_DATA,
    SET_TWITCH_CHANNEL,
    CREATE_TWITCH_SERVER,
    DELETE_TWITCH_SERVER,
    FETCH_TWITCH_BROADCASTS,
    SET_TWITCH_BROADCASTS,
    FETCH_TWITCH_EVENTS_SCHEDULE,
    SET_TWITCH_EVENTS_SCHEDULE,
    FETCH_TWITCH_LIVE_STREAMERS,
    SET_TWITCH_LIVE_STREAMERS,
    CREATE_TWITCH_BROADCAST,
    DELETE_TWITCH_BROADCAST,
    UPDATE_TWITCH_BROADCAST,
    FETCH_TWITTER_CHANNEL_DATA,
    SET_TWITTER_CHANNEL,
    CREATE_TWITTER_SERVER,
    DELETE_TWITTER_SERVER,
    FETCH_REDDIT_CHANNEL_DATA,
    SET_REDDIT_CHANNEL,
    CREATE_REDDIT_SERVER,
    DELETE_REDDIT_SERVER,
    FETCH_DISCORD_CHANNEL_COUNT,
    SET_DISCORD_CHANNEL_COUNT,
    FETCH_TWITCH_CHANNEL_COUNT,
    SET_TWITCH_CHANNEL_COUNT,
    FETCH_SHOPIFY_CHANNEL_COUNT,
    SET_SHOPIFY_CHANNEL_COUNT,
    FETCH_TWITTER_CHANNEL_COUNT,
    SET_TWITTER_CHANNEL_COUNT,
    FETCH_REDDIT_CHANNEL_COUNT,
    SET_REDDIT_CHANNEL_COUNT,
	AUTHORIZE_TWITCH_APP,
    FETCH_TWITCH_APP_MODERATORS,
    SET_TWITCH_APP_MODERATORS,
    DELETE_TWITCH_APP_MODERATOR,
    UPDATE_TWITCH_APP_MODERATOR,
    UPDATE_TWITCH_BROADCASTS_MODERATOR,
} from '../constants/ActionTypes'

export const fetchDiscordData = () => {
    return {
        type: FETCH_DISCORD_CHANNEL_DATA
    }
}

export const createDiscordServer = ({params, options}) => {
    return {
        type: CREATE_DISCORD_SERVER,
        params,
        options,
    }
}

export const deleteDiscordServer = (id) => {
    return {
        type: DELETE_DISCORD_SERVER,
        payload: id
    }
}

export const setDiscordData = (data) => {
    return {
        type: SET_DISCORD_CHANNEL,
        payload: data
    }
}


export const fetchTwitchData = () => {
    return {
        type: FETCH_TWITCH_CHANNEL_DATA
    }
}

export const createTwitchServer = (payload) => {
    return {
        type: CREATE_TWITCH_SERVER,
        payload: payload
    }
}

export const deleteTwitchServer = (id) => {
    return {
        type: DELETE_TWITCH_SERVER,
        payload: id
    }
}

/**
 * @param {Object} [options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [options.success] Behavior to execute when API call and action is successful. (2xx code)
 * @param {String} [options.success.redirect] Redirect to this URL on success.
 * @param {String} [options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [options.success.notification] Display notification with passed text on success.
 * @param {Function} [options.success.callback] Callback function on success.
 * @param {*} [options.success.callbackArgs] Callback function args on success.
 *
 * @param {Object} [options.failure] Behavior to execute when API call and action is failed. (non-2xx and non-4xx code)
 * @param {String} [options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [options.failure.callback] Callback function on failure.
 * @param {*} [options.failure.callbackArgs] Callback function args on failure.
 * 
 * @param {Object} [options.bad_request] Behavior to execute when API call and action is failed due to bad request. (4xx code)
 * @param {String} [options.bad_request.redirect] Redirect to this URL on bad request.
 * @param {String} [options.bad_request.message] Push message with passed text to ```state.messages.bank``` on bad request.
 * @param {String} [options.bad_request.notification] Display notification with passed text on bad request.
 * @param {Function} [options.bad_request.callback] Callback function on bad request.
 * @param {*} [options.bad_request.callbackArgs] Callback function args on bad request.
 */
export const fetchTwitchBroadcasts = (options) => {
    return {
        type: FETCH_TWITCH_BROADCASTS,
        options: options,
    }
}

export const setTwitchBroadcasts = (broadcasts) => {
    return {
        type: SET_TWITCH_BROADCASTS,
        broadcasts: broadcasts,
    }
}

export const fetchTwitchEventsSchedule = (options) => {
    return {
        type: FETCH_TWITCH_EVENTS_SCHEDULE,
        options: options,
    }
}

export const setTwitchEventsSchedule = (broadcasts) => {
    return {
        type: SET_TWITCH_EVENTS_SCHEDULE,
        broadcasts: broadcasts,
    }
}

export const fetchTwitchLiveStreamers = (options) => {
    return {
        type: FETCH_TWITCH_LIVE_STREAMERS,
        options: options,
    }
}

export const setTwitchLiveStreamers = (streamers) => {
    return {
        type: SET_TWITCH_LIVE_STREAMERS,
        broadcasts: streamers,
    }
}

/**
 * @param {Object} [options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [options.success] Behavior to execute when API call and action is successful. (2xx code)
 * @param {String} [options.success.redirect] Redirect to this URL on success.
 * @param {String} [options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [options.success.notification] Display notification with passed text on success.
 * @param {Function} [options.success.callback] Callback function on success.
 * @param {*} [options.success.callbackArgs] Callback function args on success.
 *
 * @param {Object} [options.failure] Behavior to execute when API call and action is failed. (non-2xx and non-4xx code)
 * @param {String} [options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [options.failure.callback] Callback function on failure.
 * @param {*} [options.failure.callbackArgs] Callback function args on failure.
 * 
 * @param {Object} [options.bad_request] Behavior to execute when API call and action is failed due to bad request. (4xx code)
 * @param {String} [options.bad_request.redirect] Redirect to this URL on bad request.
 * @param {String} [options.bad_request.message] Push message with passed text to ```state.messages.bank``` on bad request.
 * @param {String} [options.bad_request.notification] Display notification with passed text on bad request.
 * @param {Function} [options.bad_request.callback] Callback function on bad request.
 * @param {*} [options.bad_request.callbackArgs] Callback function args on bad request.
 */
export const createTwitchBroadcast = (params, options) => {
    return {
        type: CREATE_TWITCH_BROADCAST,
        params: params,
        options: options,
    }
}

/**
 * @param {Object} [options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [options.success] Behavior to execute when API call and action is successful. (2xx code)
 * @param {String} [options.success.redirect] Redirect to this URL on success.
 * @param {String} [options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [options.success.notification] Display notification with passed text on success.
 * @param {Function} [options.success.callback] Callback function on success.
 * @param {*} [options.success.callbackArgs] Callback function args on success.
 *
 * @param {Object} [options.failure] Behavior to execute when API call and action is failed. (non-2xx and non-4xx code)
 * @param {String} [options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [options.failure.callback] Callback function on failure.
 * @param {*} [options.failure.callbackArgs] Callback function args on failure.
 * 
 * @param {Object} [options.bad_request] Behavior to execute when API call and action is failed due to bad request. (4xx code)
 * @param {String} [options.bad_request.redirect] Redirect to this URL on bad request.
 * @param {String} [options.bad_request.message] Push message with passed text to ```state.messages.bank``` on bad request.
 * @param {String} [options.bad_request.notification] Display notification with passed text on bad request.
 * @param {Function} [options.bad_request.callback] Callback function on bad request.
 * @param {*} [options.bad_request.callbackArgs] Callback function args on bad request.
 */
export const deleteTwitchBroadcast = (id, options) => {
    return {
        type: DELETE_TWITCH_BROADCAST,
        params: { id: id },
        options: options,
    }
}

/**
 * @param {Object} [options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [options.success] Behavior to execute when API call and action is successful. (2xx code)
 * @param {String} [options.success.redirect] Redirect to this URL on success.
 * @param {String} [options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [options.success.notification] Display notification with passed text on success.
 * @param {Function} [options.success.callback] Callback function on success.
 * @param {*} [options.success.callbackArgs] Callback function args on success.
 *
 * @param {Object} [options.failure] Behavior to execute when API call and action is failed. (non-2xx and non-4xx code)
 * @param {String} [options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [options.failure.callback] Callback function on failure.
 * @param {*} [options.failure.callbackArgs] Callback function args on failure.
 * 
 * @param {Object} [options.bad_request] Behavior to execute when API call and action is failed due to bad request. (4xx code)
 * @param {String} [options.bad_request.redirect] Redirect to this URL on bad request.
 * @param {String} [options.bad_request.message] Push message with passed text to ```state.messages.bank``` on bad request.
 * @param {String} [options.bad_request.notification] Display notification with passed text on bad request.
 * @param {Function} [options.bad_request.callback] Callback function on bad request.
 * @param {*} [options.bad_request.callbackArgs] Callback function args on bad request.
 */
export const updateTwitchBroadcast = (id, update, options) => {
    return {
        type: UPDATE_TWITCH_BROADCAST,
        params: {
            id: id,
            update: update,
        },
        options: options,
    }
}

export const setTwitchData = (data) => {
    return {
        type: SET_TWITCH_CHANNEL,
        payload: data
    }
}

// twitter actions

export const fetchTwitterData = (params, options) => {
    return {
        type: FETCH_TWITTER_CHANNEL_DATA,
        params: params,
        options: options,
    }
}

export const createTwitterServer = (params, options) => {
    return {
        type: CREATE_TWITTER_SERVER,
        params: params,
        options: options,
    }
}

export const deleteTwitterServer = (params, options) => {
    return {
        type: DELETE_TWITTER_SERVER,
        params: params,
        options: options,
    }
}

export const setTwitterData = (data) => {
    return {
        type: SET_TWITTER_CHANNEL,
        payload: data
    }
}

export const fetchRedditData = () => {
    return {
        type: FETCH_REDDIT_CHANNEL_DATA
    }
}

export const setRedditData = (data) => {
    return {
        type: SET_REDDIT_CHANNEL,
        payload: data
    }
}

export const createRedditServer = (data) => {
    return {
        type: CREATE_REDDIT_SERVER,
        payload: data
    }
}

export const deleteRedditServer = (data) => {
    return {
        type: DELETE_REDDIT_SERVER,
        payload: data
    }
}
// channel count related actions

export const fetchDiscordChannelCount = () => {
    return {
        type: FETCH_DISCORD_CHANNEL_COUNT,
    }
}

export const setDiscordChannelCount = (payload) => {
    return {
        type: SET_DISCORD_CHANNEL_COUNT,
        payload: payload
    }
}

export const fetchTwitchChannelCount = () => {
    return {
        type: FETCH_TWITCH_CHANNEL_COUNT,
    }
}

export const setTwitchChannelCount = (payload) => {
    return {
        type: SET_TWITCH_CHANNEL_COUNT,
        payload: payload
    }
}

export const fetchShopifyChannelCount = () => {
    return {
        type: FETCH_SHOPIFY_CHANNEL_COUNT,
    }
}

export const setShopifyChannelCount = (payload) => {
    return {
        type: SET_SHOPIFY_CHANNEL_COUNT,
        payload: payload
    }
}

export const fetchTwitterChannelCount = (params, options) => {
    return {
        type: FETCH_TWITTER_CHANNEL_COUNT,
        params: params,
        options: options,
    }
}

export const setTwitterChannelCount = (payload) => {
    return {
        type: SET_TWITTER_CHANNEL_COUNT,
        payload: payload
    }
}

export const fetchRedditChannelCount = () => {
    return {
        type: FETCH_REDDIT_CHANNEL_COUNT,
    }
}

export const setRedditChannelCount = (counts) => {
    return {
        type: SET_REDDIT_CHANNEL_COUNT,
        payload: counts
    }
}

export const authorizeTwitchApp = (params, options) => {
	return {
		type: AUTHORIZE_TWITCH_APP,
		params: params,
		options: options,
	};
};

export const fetchTwitchAppModerators = () => {
	return {
		type: FETCH_TWITCH_APP_MODERATORS,
	};
};

export const setTwitchAppModerators = (params, options) => {
	return {
		type: SET_TWITCH_APP_MODERATORS,
		params: params,
		options: options,
	};
};

export const deleteTwitchAppModerator = (params, options) => {
	return {
		type: DELETE_TWITCH_APP_MODERATOR,
		params: params,
		options: options,
	};
};

export const updateTwitchAppModerator = (id, payload, options) => {
	return {
		type: UPDATE_TWITCH_APP_MODERATOR,
		options: options,
		id,
		payload,
	};
};

export const updateTwitchBroadcastsModerators = (payload, options) => {
	return {
		type: UPDATE_TWITCH_BROADCASTS_MODERATOR,
		options: options,
		payload,
	};
};
