import React, { Component } from "react";
import propTypes from "prop-types";
import isEqual from "lodash/isEqual";
import "./styles.scss";
import classnames from "classnames";
import { Paper } from "@material-ui/core";
import Modal from "../../../../../components/Modal/Modal";
import TwitterDialogContent from "./TwitterDialogContent";

class TwitterCreateDialog extends Component {
	state = {
		type: null,
		identifier: null,
		esport_entities: [],
	};

	handleClose = () => {
		this.setInitialData();
	};

	changeUrl = (event) => {
		const { value } = event.target;
		this.setState({
			identifier: value,
		});
	};

	changeEntity = (value) => {
		this.setState((prevState) => ({
			esport_entities: [...prevState.esport_entities, value],
		}));
	};

	removeEntity = (value) => {
		const { esport_entities } = this.state;
		const new_esport_entities = esport_entities.filter(
			(entity) => !isEqual(value, entity)
		);
		this.setState({
			esport_entities: new_esport_entities,
		});
	};

	setInitialData = () => {
		this.setState({
			identifier: null,
			esport_entities: [],
			type: null,
		});
	};

	handleSubmit = () => {
		const params = {
			type: this.props.type,
			identifier: this.state.identifier,
			esport_entities: this.state.esport_entities,
		};
		this.props.handleSubmit(params);
		this.setInitialData();
		this.handleClose();
	};

	render() {
		return (
			<Modal
				className="admin channel-modal"
				triggerBtnText= {this.props.triggerBtnText}
				triggerBtnClassName="button button--borderless"
				cancelBtnText="Cancel"
				onClose={this.handleClose}
				submitBtnText="Save"
				onSubmit={this.handleSubmit}
			>
				<TwitterDialogContent
					type={this.props.type}
					identifier={this.state.identifier}
					entities={this.state.esport_entities}
					changeUrl={this.changeUrl}
					changeEntity={this.changeEntity}
					removeEntity={this.removeEntity}
				/>
			</Modal>
		);
	}
}

TwitterCreateDialog.propTypes = {
	handleSubmit: propTypes.func.isRequired,
};

export default TwitterCreateDialog;
