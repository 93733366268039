export const Forgot_Password_Default_Message = `Please enter the email address associated with your account. We'll send you a link to reset your password.`;
export const Forgot_Password_Status_Success_Display_Message = `A link to reset your password has been to your email. It may take a minute to get there, but if you do not receive it, you can re-send it again`;
export const Forgot_Password_Default_Button = `Reset Password`;
export const Forgot_Password_Resend_Email_Button = `Resend Link`;

export const NEW_EMAIL_CONFIRMED_TEXT = `Thanks for confirming your email address with us!`;
export const NEW_EMAIL_CONFIRMED_BUTTON = `Back to Team Liquid`;

export const Reset_Password_Status_Success_Message = `Success! You can sign in to Team Liquid normally.`;

export const NO_CHANNEL_TO_SHOW = `There is no channel available. Please click on the 'Add Channel' button to add new channel`;
export const NO_BOT_TO_SHOW = `There is no bot available. Please click on the 'Add Bot' button to add new channel`;
export const NO_SHOPIFY_STORE_TO_SHOW = `There is no Team Liquid store available. Please click on the 'Add Team Liquid store' button to add new store`;

export const DISCORD_DELETE_DIALOG_TEXT = `Are you sure you want to delete this bot? Any further interactions will no longer be tracked from this node.`;
export const DISCORD_CREATE_DIALOG_TEXT = `Enter the URL for this server. If it is a Team Liquid server, please use the short link.`;

export const TWITCH_DELETE_DIALOG_TEXT = `Are you sure you want to delete this channel? Any further interactions will no longer be tracked from this node.`;
export const TWITCH_CREATE_DIALOG_TEXT = `Enter the channel name that you want to add to our watch list on Team Liquid`;

export const NO_TWITTER_CHANNEL_TO_SHOW = `There are no profiles or tags being monitored, click the 'Add Profile/Tag' button to add a profile or tag for the service to monitor`;
export const TWITTER_DELETE_DIALOG_TEXT = `Are you sure you want to delete this channel? Any further interactions will no longer be tracked from this node.`;

export const TWITTER_CREATE_PROFILE_DIALOG_TEXT = `Enter the profile name to add to our watch list on Team Liquid`;
export const TWITTER_CREATE_TAG_DIALOG_TEXT = `Enter the tag name to add to our watch list on Team Liquid`;

export const REDDIT_DELETE_DIALOG_TEXT = `Are you sure you want to delete this channel? Any further interactions will no longer be tracked from this node.`;
export const REDDIT_ADD_THREAD_DIALOG_TEXT = `Enter the thread name that you want to add to out watch list on Team Liquid`;
export const REDDIT_ADD_SUB_REDDIT_DIALOG_TEXT = `Enter the sub-reddit name that you want to add to out watch list on Team Liquid`;

export const GENERATING_REFERRAL_LINK = "Generating Referral Link. Please wait";
export const FAILED_GENERATING_REFERRAL_LINK = "An Error occurred. Please try again";

export const EMAIL_EXISTS_WARNING = "Sorry, this email is already in use.";

export const INVALID_USERNAME_WARNING =
	"Usernames must be: \n" +
	"- At least two characters long \n" +
	"- Start and end with a letter \n" +
	"- Contain only letters, numbers, underscores, or hyphens";

export const INVALID_PASSWORD_WARNING = "Password is too short.";
// "- A lowercase letter \n" +
// "- An uppercase letter \n" +
// "- A number \n" +
// "- A special character"

export const INVALID_BIRTHDATE = "Please enter a valid birthday.";
export const COUNTRY_IS_REQUIRED = "Enter a Country/Region of Residence.";
export const ENTER_PASSWORD = "Enter a password.";
export const ENTER_EMAIL = "Enter an email address.";
export const OPERATION_FAILED = "The operation cannot be performed";
export const EMAIL_NOT_ALLOWED = "This email address is not allowed";
export const INVALID_EMAIL_WARNING = "The address is not in the correct format.";
export const UNMATCHED_PASSWORD = "Passwords do not match";
export const POINTS_ERROR = "Please provide +/-, then a numeric value";
