import {
    all
} from 'redux-saga/effects';

import {
    createEventWatcher
} from './CreateEventSagas'

import {
    recentEventsWatcher
} from './RecentEventsSaga'

import {
    fetchEventWatcher
} from './FetchEventSagas'

import {
    updateEventWatcher
} from './UpdateEventSaga'

import {
    checkInEventWatcher
} from './CheckInEventSagas'
import {
    deleteEventWatcher
} from './DeleteEventSagas'
import {
    emailEventWatcher
} from './EmailEventSagas'

export function* eventsSagas() {
    yield all([
        createEventWatcher(),
        recentEventsWatcher(),
        fetchEventWatcher(),
        updateEventWatcher(),
        checkInEventWatcher(),
        deleteEventWatcher(),
        emailEventWatcher()
    ]);
}