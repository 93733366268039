import {
    setPerks
} from "../../actions/Perks";
// import {
//     showLoader,
//     hideLoader
// } from "../../actions/GlobalAction";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
// import { // if you want to redirect post saga action
//     push
// } from "react-router-redux";
import {
    FETCH_PERKS_WATCHER
} from "../../constants/ActionTypes";
import {
    FETCH_ALL_PERKS_URL
} from "../../constants/Url";

import api from '../../util/api';

function fetchPerksApi() {
    return (
        api
        .get(FETCH_ALL_PERKS_URL)
    );
};

export function* fetchPerksWatcher() {
    yield takeLatest(FETCH_PERKS_WATCHER, fetchPerksWorker);
};

function* fetchPerksWorker(action) {
    try {
        // yield put(showLoader());
        let response = yield call(fetchPerksApi);
        if (response.status >= 200 && response.status < 300) {
            yield put(setPerks(response.data.data));
        } else {
            throw (response);
        };
        // yield put(hideLoader());
    } catch (e) {
        // yield put(hideLoader());
    };
};