import { takeLatest, call, put } from "redux-saga/effects";
import i18n from "i18next";

// Utils
import api from "../../util/api";

// Constants
import { SET_SOCIAL_PLATFORM_VISIBILITY } from "../../constants/ActionTypes";
import { SOCIAL_PLATFORM_VISIBILITY_URL } from "../../constants/Url";
import { ERROR, SUCCESS } from "../../constants/Notifications";

// Actions
import { appendNotifications } from "../../actions/Notifications";

export function* setSocialPlatformVisibilityWatcher() {
	yield takeLatest(SET_SOCIAL_PLATFORM_VISIBILITY, setSocialPlatformVisibilityWorker);
}

function* setSocialPlatformVisibilityWorker(action) {
	try {
		const { platform, social_id, visibility } = action.payload;
		const response = yield call(setSocialPlatformVisibilityAPI, {
			platform,
			social_id,
			visibility,
		});

		const isSuccess = response.status >= 200 && response.status < 300;

		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		console.log({ isSuccess });
		yield put(
			appendNotifications({
				type: isSuccess ? SUCCESS : ERROR,
				message: isSuccess
					? i18n.t("accountSettings:profileUpdatedNotification")
					: i18n.t("notifications:userUpdateError"),
			})
		);

		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		}
	} catch (error) {
		console.error(error);
	}
}

const setSocialPlatformVisibilityAPI = (platformInformation) =>
	api
		.put(SOCIAL_PLATFORM_VISIBILITY_URL, platformInformation)
		.then((response) => response)
		.catch((error) => error.response);
