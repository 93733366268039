import {
    setSearchedAchievements
} from "../../actions/Achievements";
import {
    showLoader,
    hideLoader
} from "../../actions/GlobalAction";
import {
    takeLatest,
    call,
    put
} from "redux-saga/effects";
import { // if you want to redirect post saga action
    push
} from "react-router-redux";
import {
    SEARCH_ACHIEVEMENTS_WATCHER
} from "../../constants/ActionTypes";
import {
    SEARCH_ACHIEVEMENTS_URL
} from "../../constants/Url";
import api from '../../util/api';

export function* searchAchievementsWatcher() {
    yield takeLatest(SEARCH_ACHIEVEMENTS_WATCHER, searchAchievementsWorker);
};

function* searchAchievementsWorker(action) {
    try {
        let response = yield call(searchAchievementsApi, action.payload);
        if (response.status >= 200 && response.status < 300) {
            yield put(setSearchedAchievements(response.data.data));
        } else {
            throw (response);
        }
    } catch (e) {
        console.log(e)
    };
};

function searchAchievementsApi(payload) {
    return (
        api
        .get(SEARCH_ACHIEVEMENTS_URL, {
            params: {
                searchField: payload
            }
        })
    );
};
