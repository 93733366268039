import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { FormFieldTextarea } from '../../../../components/FormFields';
import { updateRewards } from '../../../../actions/Rewards';
import "../styles.scss";

class DescriptionDialog extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.setState({
            description: this.props.description
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            description: nextProps.description,
        })
    }

    handleSubmit = () => {
        this.props.updateRewards({
            id: this.props._id,
            params: {
                description: this.state.description
            }
        });
    };


    handleClose() {
        this.setState({
            description: this.props.description
        });
    }

    checkValidation() {
        return (
            this.state.description == this.props.description
        )
    }

    handleInputChange(htmlValue) {
        this.setState({ description: htmlValue });
    }

    render() {
        return (
            <Modal
				wide
                className="admin"
                triggerBtnText="Change"
                triggerBtnClassName="button button--secondary button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Save"
                submitDisabled={null}
                onSubmit={() => this.handleSubmit()}
                onClose={() => this.handleClose()}
            >
                <FormFieldTextarea
					allow_formatting
                    label={"Description"}
                    type={"text"}
                    name={"description"}
                    value={this.state.description}
                    onChange={(event) => this.handleInputChange(event)}
                />
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateRewards: (payload) => dispatch(updateRewards(payload))
    }
}

const mapStateToProps = state => {
    return {

    }
}

DescriptionDialog = connect(mapStateToProps, mapDispatchToProps)(DescriptionDialog);

export default DescriptionDialog