import {
	SET_ACHIEVEMENTS,
	SET_ACHIEVEMENT,
	SET_VALID_ACHIEVEMENTS,
	SET_SEARCHED_ACHIEVEMENTS,
	SET_ACHIEVEMENTS_DISPLAY_BANK,
} from "../constants/ActionTypes";

const INIT_STATE = {
	all: [],
	achievement: {},
	valid_achievements: [],
	searched_achievements: [],
	achievementsDisplay: [],
};

export default function AchievementsReducer(state = INIT_STATE, action) {
	switch (action.type) {
		case SET_ACHIEVEMENTS: {
			return {
				...state,
				all: action.payload,
			};
		}
		case SET_ACHIEVEMENT: {
			return {
				...state,
				achievement: action.payload,
			};
		}
		case SET_VALID_ACHIEVEMENTS: {
			return {
				...state,
				valid_achievements: action.payload,
			};
		}
		case SET_SEARCHED_ACHIEVEMENTS: {
			return {
				...state,
				searched_achievements: action.payload,
			};
		}
		case SET_ACHIEVEMENTS_DISPLAY_BANK: {
			return {
				...state,
				achievementsDisplay: action.payload,
			};
		}
		default:
			return state;
	}
}
