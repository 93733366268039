/**
 * @class Mutex
 * @description A simple mutual exclusion locking mechanism
 *
 * @example const Locker = new Mutex(); to create a new instance
 * @example Locker.lock(); to activate the lock
 * @example Locker.releaseLock(); to release the lock
 * @example Locker.isLocked(); to check if the lock is active
 */
export default class Mutex {
	constructor() {
		this.locked = false;
	}

	lock() {
		this.locked = true;
	}

	releaseLock() {
		this.locked = false;
	}

	isLocked() {
		return this.locked;
	}
}
