import i18n from "i18next";
import moment from "moment";

// Constants
import { LANGUAGES } from "../constants/Translations";

export const loadTranslations = (language, namespace, translations) => {
	i18n.addResources(language, namespace, translations);
};

// namespace = Desired namespace to load into i18next
// translationsRoot = Path to the translations folder considering `src` as root dir
// e.g. loadNamespace("featureTour", "containers/pages/FeatureTour/translations", "FeatureTour");
export const loadNamespace = (namespace, translationsRoot) => {
	LANGUAGES.forEach(({ value: language }) => {
		const translations = require(`../${translationsRoot}/${language}/${namespace}.json`);
		loadTranslations(language, namespace, translations);
	});
};

// Get initial language from LocalStorage or Browser's language

export const getInitialLanguage = () => {
	const language = localStorage.getItem("language") || navigator.language;
	return getActiveLanguageValue(language);
};

// Language options
// e.g. { label: 'English', value: 'en-US' }

export const getDefaultLanguageOption = () => {
	return LANGUAGES.find(({ defaultLanguage }) => defaultLanguage === true);
};

export const getActiveLanguageOption = (language) => {
	if (language) return LANGUAGES.find(({ value }) => value === language);
	return getDefaultLanguageOption();
};

// Language labels
// e.g. 'English'

export const getDefaultLanguageLabel = () => {
	return LANGUAGES.find(({ defaultLanguage }) => defaultLanguage === true).label;
};

export const getActiveLanguageLabel = (language) => {
	if (language) return LANGUAGES.find(({ value }) => value === language).label;
	return getDefaultLanguageLabel();
};

export const getActiveLanguageAbbrLabel = (language) => {
	if (language) return LANGUAGES.find(({ value }) => value === language).abbrLabel;
	return LANGUAGES.find(({ defaultLanguage }) => defaultLanguage === true).abbrLabel;
};

export const getLanguageEnglishLabelByCode = (lngCode) => {
	return LANGUAGES.find(({ value }) => lngCode === value).englishLabel;
};

// Language values
// e.g. 'en-US'

export const getDefaultLanguageValue = () => {
	return LANGUAGES.find(({ defaultLanguage }) => defaultLanguage === true)?.value;
};

export const getActiveLanguageValue = (language) => {
	if (language)
		return (
			LANGUAGES.find(({ value }) => value === language)?.value || getDefaultLanguageValue()
		);
	return getDefaultLanguageValue();
};

export const getAllLanguagesValue = () => {
	const allLanguages = [];
	LANGUAGES.forEach(({ value }) => allLanguages.push(value));
	return allLanguages;
};

export const isValidLanguage = (language) => {
	return Boolean(LANGUAGES.find(({ value }) => value === language));
};

// Change moment locale to match the language on change
// e.g. 'en-US'
export const updateMomentLocale = (language) => moment.locale(language);

// Language values
// e.g. 'en-US'
export const loadLocales = () =>
	new Promise((resolve) => {
		let laguagesProcessed = 0;
		LANGUAGES.forEach(({ value }) => {
			// we should avoid en-US since there's no locale file for it in moment
			if (value !== "en-US") {
				import(`moment/locale/${value.toLocaleLowerCase()}`).then(() => {
					laguagesProcessed += 1;
					if (laguagesProcessed === LANGUAGES.length - 1) resolve();
				});
			}
		});
	});

export const isUsedDefaultLanguage = () => {
	const language = localStorage.getItem("language");
	return language === getDefaultLanguageValue();
};

export const setLanguage = (lang) => {
	if (isValidLanguage(lang)) {
		i18n.changeLanguage(lang);
		localStorage.setItem("language", lang);
		updateMomentLocale(lang);
	}
};

export const getActiveLanguage = () => {
	return i18n.language;
};
