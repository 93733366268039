import { call, put, takeLatest } from "redux-saga/effects";
import { appendNotifications } from "../../actions/Notifications";
import { DELETE_LIQUIPEDIA_KEYWORD } from "../../constants/ActionTypes";
import { ERROR, SUCCESS } from "../../constants/Notifications";
import { DELETE_LIQUIPEDIA_KEYWORD as deleteUrl } from "../../constants/Url";

import api from "../../util/api";

export function* deleteKeywordWatcher() {
	yield takeLatest(DELETE_LIQUIPEDIA_KEYWORD, deleteKeywordWorker);
}

function* deleteKeywordWorker(action) {
	try {
		const response = yield call(deleteKeywordApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, response.data);
		}
	} catch (err) {
		console.error(err);
	}
}

async function deleteKeywordApi(id) {
	try {
		return await api.delete(deleteUrl(id));
	} catch (error) {
		return error.response;
	}
}
