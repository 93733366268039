
import {all} from 'redux-saga/effects';

import {
    fetchOrdersWatcher
} from './FetchOrdersSagas'
import {
    updateOrderWatcher
} from './UpdateOrderSagas'
import {
    fetchUserOrdersWatcher
} from './FetchUserOrdersSagas'

export function *ordersSagas() {
    yield all([
        fetchOrdersWatcher(),
        updateOrderWatcher(),
        fetchUserOrdersWatcher()
    ]);
  }