import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import { ADMIN_FETCH_QUESTS_WATCHER } from "../../constants/ActionTypes";
import { ADMIN_FETCH_QUESTS_URL } from "../../constants/Url";
import { SUCCESS, ERROR } from "../../constants/Notifications";
import { appendNotifications } from "../../actions/Notifications";
import { addMessage } from "../../actions/Messages";
import { setQuestsBank } from "../../actions/Quests";

import api from "../../util/api";

export function* adminFetchQuestsWatcher() {
	yield takeLatest(ADMIN_FETCH_QUESTS_WATCHER, adminFetchQuestsWorker);
}

function* adminFetchQuestsWorker(action) {
	try {
		const response = yield call(adminFetchQuestsApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;

		const options = isSuccess ? action?.options?.success : action?.options?.failure;
		if (isSuccess) {
			yield put(setQuestsBank(response.data.data));
		}
		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message:
						typeof options.notification === "string"
							? options.notification
							: isSuccess
							? "Success."
							: response?.data?.error || "Error.",
				})
			);
		}
		if (options?.message) {
			yield put(
				addMessage({
					source: ADMIN_FETCH_QUESTS_WATCHER,
					message:
						typeof options.message === "string"
							? options.message
							: isSuccess
							? "Success."
							: response?.data?.error || "Error.",
					isError: !isSuccess,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, response);
		}
		if (options?.redirect) {
			yield put(push(options.redirect));
		}
	} catch (e) {
		console.error(e);
	}
}

export function adminFetchQuestsApi(params) {
	return api.get(ADMIN_FETCH_QUESTS_URL, { params: params }).catch((error) => {
		return error.response;
	});
}
