export const LIST_TYPES = ["ordered-list", "unordered-list"];
export const HEADING_TYPES = ["heading-one", "heading-two", "heading-three"];
export const INITIAL_VALUE = "<p></p>";

export const ELEMENT_TAGS = {
	A: (el) => ({ type: "link", url: el.getAttribute("href"), target: el.getAttribute("target") }),
	BLOCKQUOTE: () => ({ type: "quote" }),
	H1: () => ({ type: "heading-one" }),
	H2: () => ({ type: "heading-two" }),
	H3: () => ({ type: "heading-three" }),
	IMG: (el) => ({ type: "image", url: el.getAttribute("src") }),
	LI: () => ({ type: "list-item" }),
	OL: () => ({ type: "ordered-list" }),
	UL: () => ({ type: "unordered-list" }),
	P: () => ({ type: "paragraph" }),
	MAIN: () => ({ type: "paragraph" }),
	ARTICLE: () => ({ type: "paragraph" }),
	ASIDE: () => ({ type: "paragraph" }),
	SECTION: () => ({ type: "paragraph" }),
	DIV: () => ({ type: "paragraph" }),
	SPAN: () => ({ type: "paragraph" }),
	PRE: () => ({ type: "code" }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
export const TEXT_TAGS = {
	CODE: () => ({ code: true }),
	DEL: () => ({ strikethrough: true }),
	EM: () => ({ italic: true }),
	I: () => ({ italic: true }),
	S: () => ({ strikethrough: true }),
	STRONG: () => ({ bold: true }),
	U: () => ({ underline: true }),
};
