import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { FormFieldInput } from '../../../../components/FormFields';
import { updateRewards } from '../../../../actions/Rewards';
import "../styles.scss";

class StockDialog extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.setState({
            stock: this.props.stock
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            stock: nextProps.stock
        })
    }

    handleSubmit = () => {
        this.props.updateRewards({
            id: this.props._id,
            params: {
                stock: this.state.stock
            }
        });
    };

    handleClose() {
        this.setState({
            stock: this.props.stock
        });
    }
    checkValidation() {
        return this.state.stock === this.props.stock
    }

    handleInputChange(event) {
        if (event.target.value) {
            this.setState({ stock: parseInt(event.target.value) });
        } else {
            this.setState({ stock: event.target.value });
        }
    }

    render() {
        return (
            <Modal
                className="admin"
                triggerBtnText="Change"
                triggerBtnClassName="button button--secondary button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Save"
                submitDisabled={this.checkValidation()}
                onSubmit={() => this.handleSubmit()}
                onClose={() => this.handleClose()}
            >
                <FormFieldInput
                    intro={"Set the available stock on this item. If stock is infinite leave it empty."}
                    type={"number"}
                    name={"stock"}
                    value={this.state.stock}
                    placeholder={"placeholder"}
                    maxLength={"95"}
                    minValue={0}
                    onChange={(event) => this.handleInputChange(event)}
                />
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateRewards: (payload) => dispatch(updateRewards(payload))
    }
}

const mapStateToProps = state => {
    return {

    }
}

StockDialog = connect(mapStateToProps, mapDispatchToProps)(StockDialog);

export default StockDialog