import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";

import { Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import {
	fetchTwitchData,
	createTwitchServer,
	deleteTwitchServer,
	fetchTwitchBroadcasts,
	createTwitchBroadcast,
	updateTwitchBroadcast,
	deleteTwitchBroadcast,
	authorizeTwitchApp,
	fetchTwitchAppModerators,
	deleteTwitchAppModerator,
	updateTwitchAppModerator,
	updateTwitchBroadcastsModerators,
} from "../../../../actions/Channel";
import { fetchGames } from "../../../../actions/Games";
import { SuccessNotification, ErrorNotification } from "../../../../containers/NotificationWrapper";

import LpHeading from "../../../../components/LpHeading";
import StaticChannelRow from "./StaticChannelRow";
import BroadcastChannelRow from "./BroadcastChannelRow";
import CreateStaticDialog from "./atoms/CreateStaticChannel";
import CreateBroadcastDialog from "./atoms/CreateBroadcast";
import StartOauthDialog from "./atoms/StartOauth";
import TwitchAppModeratorRow from "./TwitchAppModeratorRow";
import TableActions from "./atoms/TableActions";

import { TAB_STATE } from "./constants/Tabs";
import "./styles.scss";

class TwitchChannels extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hideContext: true,
			component: 0,
			currentTwitchPage: 1,
			completedTwitchPage: 1,
			rowsPerPageOptions: [6, 12, 24],
			rowsPerPage: 24,
			channelsPage: 0,
			broadcastsPage: 0,
			moderatorsPage: 0,
			selected: [],
		};

		this.props.fetchTwitchData();
		this.props.fetchTwitchBroadcasts();
		this.props.fetchGames({
			is_active: 1,
		});
		this.props.fetchTwitchAppModerators();
		this.handleOauthRedirect();
	}

	handleOauthRedirect = () => {
		const query = new URLSearchParams(window.location.search);
		const connected = query.get("connected");
		if (!connected) return; // Not a callback

		if (connected === "true") {
			SuccessNotification("Twitch App has been authorized successfully.");
		} else {
			const errorMessage = query.get("message");
			const status = query.get("status");
			console.error(errorMessage, {
				status,
				message: errorMessage,
			});
			ErrorNotification("Twitch App Authorization Failed.\n" + errorMessage);
		}

		window.history.replaceState({}, document.title, window.location.pathname);
	};

	handleTabChange = (_, component) => {
		const { fetchTwitchData, fetchTwitchBroadcasts, fetchTwitchAppModerators } = this.props;
		if (component === TAB_STATE.CHANNELS) {
			fetchTwitchData();
		}
		if (component === TAB_STATE.BROADCASTS) {
			fetchTwitchBroadcasts();
		}
		if (component === TAB_STATE.MODERATORS) {
			fetchTwitchAppModerators();
		}
		this.setState({ component });
	};

	backToChannels = () => {
		this.props.history.push("/app/servicesettings/channels");
	};

	handleDeleteStaticChannel = (channel_name) => {
		const payload = {
			serverName: channel_name,
		};
		this.props.deleteTwitchServer(payload);
	};

	handleDeleteBroadcast = (id) => {
		this.props.deleteTwitchBroadcast(id, {
			success: {
				callback: () => {
					this.setState({ selected: [] });
					this.props.fetchTwitchBroadcasts();
				},
			},
		});
	};

	handleAppOauth = (defaultMod, name) => {
		this.props.authorizeTwitchApp(
			{ defaultMod, name },
			{
				success: {
					callback: (response) => {
						window.location.assign(response.data.oauth_url);
					},
				},
				failure: {
					notification: "Authorization Failed. Verify your credentials.",
				},
			}
		);
	};

	createNewBroadcastChannel = (payload) => {
		this.props.createTwitchBroadcast(payload, {
			success: {
				callback: () => this.props.fetchTwitchBroadcasts(),
			},
		});
	};

	handleUpdateBroadcast = (formvalues) => {
		const payload = {
			event_name: formvalues.event_name,
			start_date: formvalues.start_date,
			end_date: formvalues.end_date,
			channel_name: formvalues.channel_name,
		};
		this.props.updateTwitchBroadcast(formvalues.id, payload, {
			success: {
				callback: () => this.props.fetchTwitchBroadcasts(),
			},
		});
	};

	channelsPagination(channels) {
		return (
			<TablePagination
				className={`pagination`}
				component="div"
				count={channels.length}
				rowsPerPage={this.state.rowsPerPage}
				rowsPerPageOptions={this.state.rowsPerPageOptions}
				onChangeRowsPerPage={(e) => this.setState({ rowsPerPage: e.target.value })}
				page={this.state.channelsPage}
				onChangePage={(e, page) => this.setState({ channelsPage: page })}
				classes={{
					select: "pagination__select",
				}}
			/>
		);
	}

	broadcastsPagination(broadcasts) {
		return (
			<TablePagination
				className={`pagination`}
				component="div"
				count={broadcasts.length}
				rowsPerPage={this.state.rowsPerPage}
				rowsPerPageOptions={this.state.rowsPerPageOptions}
				onChangeRowsPerPage={(e) => this.setState({ rowsPerPage: e.target.value })}
				page={this.state.broadcastsPage}
				onChangePage={(e, page) => this.setState({ broadcastsPage: page })}
				classes={{
					select: "pagination__select",
				}}
			/>
		);
	}

	moderatorsPagination(moderators) {
		return (
			<TablePagination
				className={`pagination`}
				component="div"
				count={moderators.length}
				rowsPerPage={this.state.rowsPerPage}
				rowsPerPageOptions={this.state.rowsPerPageOptions}
				onChangeRowsPerPage={(e) => this.setState({ rowsPerPage: e.target.value })}
				page={this.state.moderatorsPage}
				onChangePage={(e, page) => this.setState({ moderatorsPage: page })}
				classes={{
					select: "pagination__select",
				}}
			/>
		);
	}

	handleDeleteTwitchAppModerator = (id) => {
		const { fetchTwitchAppModerators, deleteTwitchAppModerator } = this.props;
		deleteTwitchAppModerator(id, {
			success: {
				callback: () => fetchTwitchAppModerators(),
				notification: true,
			},
			failure: {
				notification: true,
			},
		});
	};

	handleUpdateTwitchAppModerator = (id) => {
		const { fetchTwitchAppModerators, updateTwitchAppModerator } = this.props;
		updateTwitchAppModerator(
			id,
			{ default_mod: true },
			{
				success: {
					callback: () => fetchTwitchAppModerators(),
					notification: true,
				},
				failure: {
					notification: true,
				},
			}
		);
	};

	isSelected = (id) => this.state.selected.indexOf(id) !== -1;

	handleBroadcastRowClick = (event, id) => {
		const { selected } = this.state;
		const selectedIndex = selected.indexOf(id);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		this.setState({ selected: newSelected });
	};

	handleAssignModerators = ({ moderator, broadcasts }) => {
		const { updateTwitchBroadcastsModerators, fetchTwitchBroadcasts } = this.props;
		const payload = broadcasts.map((broadcast) => ({
			moderator_id: moderator,
			id: broadcast._id,
		}));

		updateTwitchBroadcastsModerators(payload, {
			success: {
				callback: () => {
					fetchTwitchBroadcasts();
					this.setState({ selected: [] });
				},
			},
		});
	};

	getSelectedBroadcasts = () => {
		const { selected } = this.state;
		const { broadcasts } = this.props;
		return selected
			.map((id) => broadcasts.find((broadcast) => broadcast._id === id))
			.filter(Boolean);
	};

	renderTab(channels, broadcasts, moderators = []) {
		let { games } = this.props;
		channels = channels?.sort((a, b) => (a.name > b.name ? 1 : -1));
		broadcasts = broadcasts?.sort((a, b) => (a.start_date > b.start_date ? 1 : -1));

		let live_broadcasts = broadcasts.filter((broadcast) => {
			return broadcast.started === true && broadcast.finished === false;
		});
		let planned_broadcasts = broadcasts.filter((broadcast) => {
			return broadcast.started === false && broadcast.finished === false;
		});
		broadcasts = live_broadcasts.concat(planned_broadcasts);

		channels = channels?.slice(
			this.state.channelsPage * this.state.rowsPerPage,
			this.state.channelsPage * this.state.rowsPerPage + this.state.rowsPerPage
		);
		broadcasts = broadcasts?.slice(
			this.state.broadcastsPage * this.state.rowsPerPage,
			this.state.broadcastsPage * this.state.rowsPerPage + this.state.rowsPerPage
		);
		const pagedModerators = moderators.slice(
			this.state.moderatorsPage * this.state.rowsPerPage,
			this.state.moderatorsPage * this.state.rowsPerPage + this.state.rowsPerPage
		);

		broadcasts = broadcasts.map((broadcast) => {
			broadcast.game_icon = games.find((game) => game.name === broadcast.game)?.icon_path;
			return broadcast;
		});

		switch (this.state.component) {
			case TAB_STATE.CHANNELS:
				return (
					<Table className="data-table">
						<TableBody>
							{channels?.length > 0 ? (
								channels.map((channel) => {
									return (
										<StaticChannelRow
											key={channel.name}
											channel={channel}
											onDelete={this.handleDeleteStaticChannel}
										/>
									);
								})
							) : (
								<TableRow>
									<TableCell>
										There are currently no Static channels being tracked.
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				);

			case TAB_STATE.BROADCASTS:
				return (
					<Table className="data-table">
						<TableBody>
							<TableRow>
								<TableCell className="data-table__header" />
								<TableCell className="data-table__header">Event</TableCell>
								<TableCell className="data-table__header">Channel Name</TableCell>
								<TableCell className="data-table__header">Participants</TableCell>
								<TableCell className="data-table__header">
									Twitch Moderator
								</TableCell>
								<TableCell className="data-table__header" />
							</TableRow>
							{broadcasts?.length > 0 ? (
								broadcasts.map((entity, index) => {
									const isSelected = this.isSelected(entity._id);
									return (
										<BroadcastChannelRow
											key={index}
											id={entity._id}
											game_icon={entity.game_icon}
											participants={entity.participants}
											event_name={entity.event_name}
											start_date={entity.start_date}
											end_date={entity.end_date}
											started={entity.started}
											finished={entity.finished}
											channel_name={entity.channel_name}
											managed={entity.manually_created}
											broadcastPlatform={entity.broadcast_platform}
											onDelete={this.handleDeleteBroadcast}
											onUpdate={this.handleUpdateBroadcast}
											isSelected={isSelected}
											onClick={(event) =>
												this.handleBroadcastRowClick(event, entity._id)
											}
											moderator={entity.moderator}
										/>
									);
								})
							) : (
								<TableRow>
									<TableCell>
										There are currently no Broadcast channels being tracked.
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				);
			case TAB_STATE.MODERATORS:
				return (
					<Table className="data-table moderators">
						<TableBody>
							<TableRow>
								<TableCell className="data-table__header">Twitch ID</TableCell>
								<TableCell className="data-table__header">Name / Label</TableCell>
								<TableCell className="data-table__header">
									Default Moderator
								</TableCell>
								<TableCell className="data-table__header" />
							</TableRow>
							{pagedModerators?.length > 0 ? (
								pagedModerators.map((mod) => {
									return (
										<TwitchAppModeratorRow
											key={mod.moderator_id}
											id={mod._id}
											twitchId={mod.moderator_id}
											name={mod.name}
											defaultMod={mod.default_mod}
											onDelete={this.handleDeleteTwitchAppModerator}
											onSetDefault={this.handleUpdateTwitchAppModerator}
										/>
									);
								})
							) : (
								<TableRow>
									<TableCell>
										There are no Twitch App Moderator connected. Click the
										button "Add New" to connect one.
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				);
			default:
				return null;
		}
	}

	/**
	 * TODO: deprecate this anti-pattern when this page is refactored
	 */
	renderPagination(component, channels, broadcasts, moderators) {
		if (component === TAB_STATE.CHANNELS) {
			return this.channelsPagination(channels);
		}
		if (component === TAB_STATE.BROADCASTS) {
			return this.broadcastsPagination(broadcasts);
		}
		if (component === TAB_STATE.MODERATORS) {
			return this.moderatorsPagination(moderators);
		}
	}

	render() {
		let channels = this.props.staticChannels.filter(
			(channel) => channel.manage_type === "MANUAL" || !channel.manage_type
		);
		const { selected } = this.state;
		const { broadcasts, moderators } = this.props;

		return (
			<div className="channel">
				<div className="options-header">
					<div className="options options--left">
						<button className="button button--borderless" onClick={this.backToChannels}>
							<span className="zmdi zmdi-chevron-left zmdi-hc-lg pr-1"></span>
							&nbsp;Channels
						</button>
					</div>
					<div className="options options--right">
						<button
							className="button"
							onClick={() =>
								this.setState({
									hideContext: !this.state.hideContext,
								})
							}
						>
							Add New
						</button>
						<Paper
							className={classnames("context", {
								"context--hidden": this.state.hideContext,
							})}
							onClick={() =>
								this.setState({
									hideContext: true,
								})
							}
						>
							<CreateStaticDialog handleCreate={this.props.createTwitchServer} />

							<CreateBroadcastDialog handleSubmit={this.createNewBroadcastChannel} />

							<StartOauthDialog handleSubmit={this.handleAppOauth} />
						</Paper>
					</div>
				</div>
				<div className="m-3 p-3 admin twitch-channels-page">
					<div>
						<LpHeading>Twitch Channels</LpHeading>
					</div>

					<div className="mt-4">
						<Tabs
							className="tabs"
							value={this.state.component}
							onChange={this.handleTabChange}
							indicatorColor="primary"
							textColor="primary"
						>
							<Tab className="tab" label="Static" />
							<Tab className="tab" label={"Broadcasts (" + broadcasts.length + ")"} />
							<Tab className="tab" label="Moderators" />
						</Tabs>
						<div className="twitch-channels-page__toolbar">
							<TableActions
								disabled={selected.length === 0}
								tabState={this.state.component}
								onAssignModerators={this.handleAssignModerators}
								selectedBroadcasts={this.getSelectedBroadcasts()}
								moderators={moderators}
							/>
							{this.renderPagination(
								this.state.component,
								channels,
								broadcasts,
								moderators
							)}
						</div>
					</div>
					{this.renderTab(channels, broadcasts, moderators)}
					{this.renderPagination(this.state.component, channels, broadcasts, moderators)}
				</div>
			</div>
		);
	}
}

TwitchChannels.propTypes = {
	staticChannels: PropTypes.array.isRequired,
	broadcasts: PropTypes.array.isRequired,
	fetchGames: PropTypes.func.isRequired,
	fetchTwitchData: PropTypes.func.isRequired,
	createTwitchServer: PropTypes.func.isRequired,
	deleteTwitchServer: PropTypes.func.isRequired,
	fetchTwitchBroadcasts: PropTypes.func.isRequired,
	createTwitchBroadcast: PropTypes.func.isRequired,
	updateTwitchBroadcast: PropTypes.func.isRequired,
	deleteTwitchBroadcast: PropTypes.func.isRequired,
	games: PropTypes.arrayOf(PropTypes.object),
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}).isRequired,
	authorizeTwitchApp: PropTypes.func.isRequired,
	fetchTwitchAppModerators: PropTypes.func.isRequired,
	deleteTwitchAppModerator: PropTypes.func.isRequired,
	updateTwitchAppModerator: PropTypes.func.isRequired,
	moderators: PropTypes.array.isRequired,
	updateTwitchBroadcastsModerators: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {
		staticChannels: state.channel.twitchData,
		broadcasts: state.channel.twitchBroadcasts,
		games: state.global.games,
		moderators: state.twitchApp.moderators,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchGames: (props) => dispatch(fetchGames(props)),
		fetchTwitchData: () => dispatch(fetchTwitchData()),
		createTwitchServer: (payload) => dispatch(createTwitchServer(payload)),
		deleteTwitchServer: (payload) => dispatch(deleteTwitchServer(payload)),
		fetchTwitchBroadcasts: (options) => dispatch(fetchTwitchBroadcasts(options)),
		createTwitchBroadcast: (broadcast, options) =>
			dispatch(createTwitchBroadcast(broadcast, options)),
		updateTwitchBroadcast: (id, update, options) =>
			dispatch(updateTwitchBroadcast(id, update, options)),
		deleteTwitchBroadcast: (id, options) => dispatch(deleteTwitchBroadcast(id, options)),
		authorizeTwitchApp: (params, options) => dispatch(authorizeTwitchApp(params, options)),
		fetchTwitchAppModerators: () => dispatch(fetchTwitchAppModerators()),
		deleteTwitchAppModerator: (id, options) => dispatch(deleteTwitchAppModerator(id, options)),
		updateTwitchAppModerator: (id, payload, options) =>
			dispatch(updateTwitchAppModerator(id, payload, options)),
		updateTwitchBroadcastsModerators: (payload, options) =>
			dispatch(updateTwitchBroadcastsModerators(payload, options)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TwitchChannels);
