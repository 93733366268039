import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";

import { appendNotifications } from "../../actions/Notifications";
import { addMessage } from "../../actions/Messages";
import { SUCCESS, ERROR } from "../../constants/Notifications";

import { DELETE_GAME_WATCHER } from "../../constants/ActionTypes";
import { DELETE_GAME_URL } from "../../constants/Url";
import { setGames } from "../../actions/Games";

import api from "../../util/api";

export function* deleteGameWatcher() {
	yield takeLatest(DELETE_GAME_WATCHER, deleteGameWorker);
}

function* deleteGameWorker(action) {
	try {
		let response = yield call(deleteGameApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess
			? action?.options?.success
			: action?.options?.failure;

		if (isSuccess) {
			yield put(setGames(response.data.data));
		}
		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.message) {
			yield put(
				addMessage({
					source: DELETE_GAME_WATCHER,
					message: response?.data?.error,
					isError: true,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		}
		if (options?.redirect) {
			yield put(push(options.redirect));
		}
	} catch (e) {
		console.error(e);
	}
}

function deleteGameApi(payload) {
	return api
		.delete(DELETE_GAME_URL(payload.name))
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
