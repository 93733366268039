import React from "react";
import "./styles/index.scss";

const Spinner = (props) => {
	return (
		<div className="spinner--wrapper">
			<svg viewBox="0 0 64 64">
				<defs>
					<linearGradient id="load-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
						<stop className="svg-first-stop" offset="0%" />
						<stop className="svg-second-stop" offset="100%" />
					</linearGradient>
				</defs>
				<circle cx="32" cy="32" r="28" stroke="url(#load-gradient)"></circle>
			</svg>
		</div>
	);
};

export default Spinner;
