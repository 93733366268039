import {
    all,
} from "redux-saga/effects";

import {
    addToClaimedPerksWatcher,
} from "./AddToClaimedPerksSagas";

export function* claimedPerksSagas() {
    yield all([
        addToClaimedPerksWatcher(),
    ]);
};