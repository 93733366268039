import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { FormFieldInput } from "../../../components/FormFields";
import PropTypes from "prop-types";

function RewardsCurrent({ rewards, rowsPerPage, rowsPerPageOptions, onChangeRowsPerPage }) {
	const [query, setQuery] = useState("");
	const [page, setPage] = useState(1);
	const [currentRewards, setCurrentRewards] = useState([]);
	const [items, setItems] = useState([]);

	const handleChangePage = (event, newPage) => {
		if (page !== newPage) {
			setPage(newPage);
		}
	};

	const handleSearch = ({ target: { value } }) => setQuery(value);

	useEffect(() => {
		if (rewards) {
			setCurrentRewards(rewards.filter((reward) => reward.archive_date === null));
		}
	}, [rewards]);

	useEffect(() => {
		if (query === "") {
			setItems(currentRewards);
		} else {
			setItems(
				currentRewards.filter((reward) =>
					reward.name.replace(" ", "").toLowerCase().includes(query.toLowerCase())
				)
			);
		}
	}, [rewards, query, currentRewards]);

	return (
		<React.Fragment>
			<div className="d-flex">
				<div className="col-7 pl-0 d-flex">
					<div>
						<FormFieldInput
							label={"Search rewards by"}
							type="text"
							name={"query"}
							help={"You can search for specific rewards by entering a query."}
							placeholder={"Name"}
							onChange={handleSearch}
							value={query}
						/>
					</div>
				</div>
				<div className="col-5">
					<TablePagination
						className={`pagination`}
						component="div"
						count={items.length}
						rowsPerPage={rowsPerPage}
						rowsPerPageOptions={rowsPerPageOptions}
						onChangeRowsPerPage={onChangeRowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						classes={{
							select: "pagination__select",
						}}
					/>
				</div>
			</div>
			<Table className="data-table">
				<TableHead>
					<TableRow>
						<TableCell class="data-table__header" width="90%">
							Rewards item
						</TableCell>
						<TableCell class="data-table__header" width="5%">
							Status
						</TableCell>
						<TableCell class="data-table__header" width="5%" />
					</TableRow>
				</TableHead>
				<TableBody>
					{items
						.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						.map((reward) => (
							<TableRow key={reward.item_id}>
								<TableCell className="data-table__data-cell" width="90%">
									<div className="data-table__multi-line">
										<img
											className="data-table__inline-image"
											src={reward.images.cover}
											alt="reward"
										/>
										<div>
											<span className="data-table__item-title">
												{reward.name}
											</span>
											<br />
											<span className="data-table__item-sub-title">
												{reward.point_count} points
											</span>
										</div>
									</div>
								</TableCell>

								<TableCell
									className="data-table__data-cell data-table__status-column"
									width="5%"
								>
									{reward.is_active
										? reward.is_expired
											? "Inactive (Expired)"
											: "Active"
										: "Inactive (Disabled)"}
								</TableCell>

								<TableCell className="data-table__data-cell" width="5%">
									<Link to={`/app/servicesettings/rewards/${reward._id}`}>
										<button className="button button--circle button--borderless">
											<span>
												<i className="zmdi zmdi-chevron-right zmdi-hc-lg" />
											</span>
										</button>
									</Link>
								</TableCell>
							</TableRow>
						))}
				</TableBody>
			</Table>
			<div>
				<TablePagination
					className={`pagination`}
					component="div"
					count={items.length}
					rowsPerPage={rowsPerPage}
					rowsPerPageOptions={rowsPerPageOptions}
					onChangeRowsPerPage={onChangeRowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					classes={{
						select: "pagination__select",
					}}
				/>
			</div>
		</React.Fragment>
	);
}

RewardsCurrent.propTypes = {
	rewards: PropTypes.array,
	page: PropTypes.number,
	rowsPerPage: PropTypes.number,
	rowsPerPageOptions: PropTypes.array,
	onChangeRowsPerPage: PropTypes.func,
	onChangePage: PropTypes.func,
};

const mapStateToProps = (state) => ({
	loading: state.global.loader,
	rewards: state.rewards.bank,
});

export default connect(mapStateToProps)(RewardsCurrent);
