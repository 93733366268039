import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// Redux
import {
	fetchLiquipediaKeywords,
	createLiquipediaKeyword,
	deleteLiquipediaKeyword,
} from "../../../../actions/Liquipedia";

// Components
import { Table, TableBody, TableRow, TableCell, TablePagination } from "@material-ui/core";
import LpHeading from "../../../../components/LpHeading";
import KeywordRow from "./atoms/KeywordRow";
import CreateDialog from "./atoms/CreateDialog";

function LiquipediaTeamKeywords({
	keywords = [],
	fetchLiquipediaKeywords,
	createLiquipediaKeyword,
	deleteLiquipediaKeyword,
}) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(24);
	const rowsPerPageOptions = [6, 12, 24];

	useEffect(() => {
		fetchLiquipediaKeywords();
	}, [fetchLiquipediaKeywords]);

	const onCreateKeyword = (keyword) => {
		createLiquipediaKeyword(
			{
				keyword,
			},
			{
				success: {
					callback: () => {
						fetchLiquipediaKeywords();
					},
				},
				failure: {
					notification: true,
				},
			}
		);
	};

	const onDeleteKeyword = (id) => {
		deleteLiquipediaKeyword(id, {
			success: {
				callback: () => {
					fetchLiquipediaKeywords();
				},
			},
		});
	};

	return (
		<div className="channel">
			<div className="options-header">
				<div className="options options--left">
					<button className="button button--borderless" onClick={() => {}} />
				</div>
				<div className="options options--right">
					<CreateDialog handleCreate={onCreateKeyword} />
				</div>
			</div>
			<div className="m-3 p-3 admin">
				<div>
					<LpHeading>Liquipedia - Team Keywords</LpHeading>
				</div>
				<div className="mt-4">
					<TablePagination
						className={`pagination`}
						component="div"
						count={keywords.length}
						rowsPerPage={rowsPerPage}
						rowsPerPageOptions={rowsPerPageOptions}
						onChangeRowsPerPage={(e) => setRowsPerPage(e.target.value)}
						page={page}
						onChangePage={(e, page) => setPage(page)}
						classes={{
							select: "pagination__select",
						}}
					/>
					<Table className="data-table">
						<TableBody>
							{keywords.length > 0 ? (
								keywords
									?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((k) => {
										return (
											<KeywordRow
												key={k.name}
												keyword={k}
												onDelete={onDeleteKeyword}
											/>
										);
									})
							) : (
								<TableRow>
									<TableCell>
										No "Team Liquid" team keywords configured for Liquipedia
										API.
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</div>
			</div>
		</div>
	);
}

LiquipediaTeamKeywords.propTypes = {
	keywords: PropTypes.array,
	fetchLiquipediaKeywords: PropTypes.func.isRequired,
	createLiquipediaKeyword: PropTypes.func.isRequired,
	deleteLiquipediaKeyword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	keywords: state.liquipedia.keywords,
});

const mapDispatchToProps = {
	fetchLiquipediaKeywords,
	createLiquipediaKeyword,
	deleteLiquipediaKeyword,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiquipediaTeamKeywords);
