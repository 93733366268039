import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.scss";

// Components
import Modal from "../../../../../components/Modal/Modal";
import LpHeading from "../../../../../components/LpHeading";
import CheckBox from "../../../../../components/CheckBox/CheckBox";
import TextField from "../../../../../components/TextField/TextField";

class StartOauthDialog extends Component {
	constructor(props) {
		super(props);

		this.state = {
			defaultMod: false,
			name: null,
		};
	}

	render() {
		const { defaultMod, name } = this.state;
		const { handleSubmit } = this.props;

		return (
			<Modal
				className="admin TwitchDialog channel-modal"
				triggerBtnText="Moderator"
				triggerBtnClassName="button button--borderless"
				cancelBtnText="Cancel"
				submitBtnText="Continue"
				onSubmit={() => handleSubmit(defaultMod, name)}
			>
				<LpHeading>Authorize Liquidplus App</LpHeading>

				<p>
					By clicking the button below, you will be redirected to Twitch to authorize the
					Liquidplus App to retrieve the chatters in a stream. Please note that you will
					need to be a moderator in the channels you want to retrieve the chatters from.
					Liquidplus will use the chatters to reward viewers in the stream.
				</p>
				<p>
					<b>
						Proceeding without the moderator permissions will block the Liquidplus App
						from rewarding viewers.
					</b>
				</p>
				<div className="experience_page__dialog-checkbox">
					<TextField
						type="text"
						label="Moderator Name or Label"
						onChange={(e) => this.setState({ name: e.target.value })}
						value={name}
					/>
					<CheckBox
						id="default_mod"
						onChange={() => {
							this.setState((prevState) => ({
								defaultMod: !prevState.defaultMod,
							}));
						}}
						checked={defaultMod}
						label={"Default moderator"}
					/>
				</div>
			</Modal>
		);
	}
}

StartOauthDialog.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
};

export default StartOauthDialog;
