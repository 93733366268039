import { setOrdersBank } from "../../actions/Orders";

import { showLoader, hideLoader } from "../../actions/GlobalAction";

import { takeLatest, call, put } from "redux-saga/effects";

import { FETCH_ORDERS_WATCHER } from "../../constants/ActionTypes";
import { REWARD_SERVICE_ORDERS } from "../../constants/Url";

import api from "../../util/api";

function fetchOrdersApi(payload) {
	return api.get(
		REWARD_SERVICE_ORDERS +
			`/${payload.orderState}?page=${payload.page}&limit=${payload.limit}${
				payload.sortAttibute
					? `&sort[${payload.sortAttibute}]=${payload.sortDirection}`
					: ""
			}`
	);
}

export function* fetchOrdersWatcher() {
	yield takeLatest(FETCH_ORDERS_WATCHER, fetchOrdersWorker);
}

function* fetchOrdersWorker(action) {
	try {
		yield put(showLoader());
		let response = yield call(fetchOrdersApi, action.payload);
		if (response.status >= 200 && response.status < 300) {
			yield put(setOrdersBank(response.data));
		} else {
			throw response;
		}

		yield put(hideLoader());
	} catch (e) {
		console.log(e);
		yield put(hideLoader());
	}
}
