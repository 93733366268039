import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import Global from "./GlobalReducer";
import Settings from "./Settings";
import UserReducer from "./UserReducer";
import RewardsReducer from "./RewardsReducer";
import OrdersReducer from "./OrdersReducer";
import AdminPanelReducer from "./AdminPanelRreducer";
import NotificationReducer from "./Notifications";
import MessagesReducer from "./Messages";
import ChannelReducer from "./ChannelReducer";
import eventsReducer from "./EventReducer";
import achievementsReducer from "./AchievementsReducer";
import UserAdminReducer from "./UserAdminReducer";
import QuestsReducer from "./QuestsReducer";
import QuestTranslationsReducer from "./QuestTranslations";
import CampaignTranslationsReducer from "./CampaignTranslations";
import CampaignsReducer from "./CampaignsReducer";
import TiersReducer from "./TiersReducer";
import PerksReducer from "./PerksReducer";
import EndpointCacheReducer from "./EndpointCacheReducer";
import CountriesReducer from "./CountriesReducer";
import GendersReducer from "./GendersReducer";
import GamesReducer from "./GamesReducer";
import V2Quests from "./V2QuestsReducer";
import LiquipediaReducer from "./LiquipediaReducer";
import TwitchAppReducer from "./TwitchAppReducer";

const reducers = combineReducers({
	routing: routerReducer,
	settings: Settings,
	global: Global,
	user: UserReducer,
	rewards: RewardsReducer,
	orders: OrdersReducer,
	channel: ChannelReducer,
	admin_panel: AdminPanelReducer,
	notifications: NotificationReducer,
	messages: MessagesReducer,
	events: eventsReducer,
	achievements: achievementsReducer,
	user_admin: UserAdminReducer,
	quests: QuestsReducer,
	campaigns: CampaignsReducer,
	tiers: TiersReducer,
	perks: PerksReducer,
	endpoint_cache: EndpointCacheReducer,
	quest_translations: QuestTranslationsReducer,
	campaign_translations: CampaignTranslationsReducer,
	countries: CountriesReducer,
	genders: GendersReducer,
	games: GamesReducer,
	v2Quests: V2Quests,
	liquipedia: LiquipediaReducer,
	twitchApp: TwitchAppReducer,
});

export default reducers;
