import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import isEqual from "lodash/isEqual";

import DatalistOptions from "./Options";

class DatalistInput extends React.Component {
	constructor(props) {
		super(props);

		this.selectRef = React.createRef();
		this.inputRef = React.createRef();

		this.state = {
			search: "",
			hideOptions: true,
		};
	}

	componentDidMount() {
		window.addEventListener("click", this.catchOutsideClick);
	}

	componentWillUnmount() {
		window.removeEventListener("click", this.catchOutsideClick);
	}

	catchOutsideClick = (event) => {
		if (
			!this.selectRef.current.contains(event.target) &&
			!this.state.hideOptions
		) {
			this.setState({
				hideOptions: true,
				search: "",
			});
		}
	};

	toggleOptions = () => {
		this.setState((state) => ({
			hideOptions: !state.hideOptions,
			search: "",
		}));
	};

	handleOptionClick = (newValue) => {
		let event = {
			target: {
				name: this.props.name,
				value: newValue,
			},
		};

		this.props.onChange(event);
		this.toggleOptions();
	};

	onSearch = (event) => {
		this.setState({
			search: event?.target?.value,
		});
	};

	clearSearch = (event) => {
		event.stopPropagation();
		this.inputRef.current.focus();

		this.setState({
			search: "",
		});
	};

	render() {
		const { wide, intro, name, label, options, value } = this.props;
		const { hideOptions, search } = this.state;

		const selected_option = options.find((option) =>
			isEqual(option.value, value)
		);

		return (
			<div
				className={classnames("form-field", {
					"form-field--wide": wide,
				})}
				ref={this.selectRef}
			>
				{intro ? (
					<div className="form-field__intro">{intro}</div>
				) : null}
				<label className="form-field__label" htmlFor={name}>
					{label}
				</label>
				<div className="form-field__select form-field__datalist">
					<input
						ref={this.inputRef}
						className={classnames("form-field__input", {
							"form-field__select-selected--open": !hideOptions,
						})}
						type="text"
						name={name}
						value={
							hideOptions ? selected_option?.label || "" : search
						}
						id={name}
						onChange={this.onSearch}
						onClick={this.toggleOptions}
					/>

					{!hideOptions && (
						<span
							className="form-field__datalist-clear"
							onClick={this.clearSearch}
						></span>
					)}

					{!hideOptions ? (
						<DatalistOptions
							options={options}
							search={search}
							onClick={this.handleOptionClick}
						/>
					) : null}
				</div>
			</div>
		);
	}
}

DatalistInput.propTypes = {
	wide: PropTypes.bool,
	name: PropTypes.string,
	intro: PropTypes.string,
	label: PropTypes.string.isRequired,
	value: PropTypes.any,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.any.isRequired,
		})
	),
	onChange: PropTypes.func.isRequired,
};

export default DatalistInput;
