import { appendNotifications } from "../../actions/Notifications.js";
import { takeLatest, call, put, select } from "redux-saga/effects";
import { SEND_EMAIL_HELP_REQUEST } from "../../constants/ActionTypes.js";
import {
	USER_SERVICE_SEND_HELP_REQUEST,
} from "../../constants/Url.js";
import {
	SEND_HELP_REQUEST,
	SUCCESS,
	ERROR,
} from "../../constants/Notifications.js";
import api from "../../util/api.js";

export function* sendHelpRequestWatcher() {
	yield takeLatest(SEND_EMAIL_HELP_REQUEST, sendHelpRequestWorker);
}


function* sendHelpRequestWorker(action) {
	try {
		const response = yield call(sendHelpRequestApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;

		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (options?.notification) {
			yield put(
				appendNotifications(
					SEND_HELP_REQUEST(isSuccess ? SUCCESS : ERROR, options.notification)
				)
			);
		}

		if (options?.callback) {
			yield call(options.callback, response);
		}
	} catch (e) {
		console.error(e);
	}
}

function sendHelpRequestApi(params) {
	return api
		.post(USER_SERVICE_SEND_HELP_REQUEST, params)
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
