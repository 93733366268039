import React from "react";
import PropTypes from "prop-types";
import LpIconHtmlTextStyle from "../../../../../icons/LpIconHtmlTextStyle";
import LpTooltip from "../../../../../LpTooltip";

export default function TextStyleButton(props) {
	const { onClick } = props;

	const handleClick = (e) => {
		e.preventDefault();
		onClick(e);
	};

	return (
		<LpTooltip text="Text Formatting">
			<button
				type="button"
				onClick={(e) => handleClick(e)}
				className="lp-rich-text-editor__button"
			>
				<LpIconHtmlTextStyle alt="Font sizes icon" title="Select text formatting" />
			</button>
		</LpTooltip>
	);
}

TextStyleButton.propTypes = {
	onClick: PropTypes.func,
};
