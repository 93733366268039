import {
    CREATE_EVENT_WATCHER,
    RECENT_EVENT_WATCHER,
    SET_RECENT_EVENTS,
    FETCH_EVENT_WATCHER,
    SET_EVENT,
    UPDATE_EVENT_WATCHER,
    CHECK_IN_EVENT_WATCHER,
    DELETE_EVENT_WATCHER,
    EMAIL_EVENT_DATA
} from "../constants/ActionTypes";

export const createEvent = (event, options) => {
    return {
        type: CREATE_EVENT_WATCHER,
        params: event,
        options: options,
    };
};

export const recentEvents = (params) => {
    return {
        type: RECENT_EVENT_WATCHER,
        payload: params
    }
}

export const setRecentEvents = (events) => {
    return {
        type: SET_RECENT_EVENTS,
        payload: events
    }
};

export const fetchEvent = (eventId) => {
    if (eventId) {
        return {
            type: FETCH_EVENT_WATCHER,
            payload: eventId
        };
    }
};

export const setEvent = (event) => {
    return {
        type: SET_EVENT,
        payload: event
    };
};

export const updateEvent = (params, id) => {
    if (id) {
        return {
            type: UPDATE_EVENT_WATCHER,
            payload: {
                params,
                id
            }
        };
    }
};

export const checkInEvent = (data) => {
    return {
        type: CHECK_IN_EVENT_WATCHER,
        payload: data
    };
};

export const deleteEvent = (eventId) => {
    return {
        type: DELETE_EVENT_WATCHER,
        payload: eventId
    }
};

export const emailEvent = (params, id) => {
    if(id) {
        return {
            type: EMAIL_EVENT_DATA,
            payload: {
                params,
                id
            }
        }
    }
}