import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { FormFieldSelect } from '../../../../components/FormFields';
import { ACQUISITION_TYPE } from "../../../../constants/Rewards";
import { updateRewards } from '../../../../actions/Rewards';
import "../styles.scss";

class AcquisitionMethodDialog extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.setState({
            acquisition_method: this.props.acquisition_method
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            acquisition_method: nextProps.acquisition_method,
        })
    }

    handleSubmit = () => {
        this.props.updateRewards({
            id: this.props._id,
            params: {
                acquisition_method: this.state.acquisition_method
            }
        });
    };

    handleClose() {
        this.setState({
            acquisition_method: this.props.acquisition_method
        });
    }

    checkValidation() {
        return (
            this.state.acquisition_method == this.props.acquisition_method
        )
    }

    handleInputChange(event) {
        this.setState({ acquisition_method: event.target.value });
    }

    render() {
        return (
            <Modal
                className="admin"
                triggerBtnText="Change"
                triggerBtnClassName="button button--secondary button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Save"
                submitDisabled={this.checkValidation()}
                onSubmit={() => this.handleSubmit()}
                onClose={() => this.handleClose()}
            >
                <FormFieldSelect
                    label={"Acquisition Method"}
                    name={"acquisition_method"}
                    value={this.state.acquisition_method}
                    options={ACQUISITION_TYPE}
                    onChange={(event) => this.handleInputChange(event)}
                />
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateRewards: (payload) => dispatch(updateRewards(payload))
    }
}

const mapStateToProps = state => {
    return {

    }
}

AcquisitionMethodDialog = connect(mapStateToProps, mapDispatchToProps)(AcquisitionMethodDialog);

export default AcquisitionMethodDialog