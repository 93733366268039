import { takeLatest, call, put, select } from "redux-saga/effects";
import { PURCHASE_REWARD_WATCHER } from "../../constants/ActionTypes";
import { REWARD_SERVICE_CREATE } from "../../constants/Url";
import { appendNotifications } from "../../actions/Notifications";
import { ERROR, REWARDS_PURCHASE } from "../../constants/Notifications";

import api from "../../util/api";

const fetchUserProfile = (state) => state.user.profile;

function purchaseRewardApi(payload, user_id) {
	return api
		.post(REWARD_SERVICE_CREATE, {
			type: payload.reward.type,
			params: {
				method: "purchase",
				item_id: payload.reward.item_id,
				user: user_id,
				...payload.additionalParams,
			},
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}

export function* purchaseRewardWatcher() {
	yield takeLatest(PURCHASE_REWARD_WATCHER, purchaseRewardWorker);
}

function* purchaseRewardWorker(action) {
	try {
		const user = yield select(fetchUserProfile);
		const response = yield call(purchaseRewardApi, action.payload, user.id);
		const isSuccess = response.status >= 200 && response.status < 300;
		const options = isSuccess ? action?.options?.success : action?.options?.failure;

		if (options?.callback) {
			yield call(options.callback, response.data?.msg?.order);
		}

		if (!isSuccess) {
			throw response.data;
		}
	} catch (e) {
		console.error(e);
		yield put(appendNotifications(REWARDS_PURCHASE(ERROR)));
	}
}
