import { takeLatest, call, put } from "redux-saga/effects";

// Actions
import { appendNotifications } from "../../actions/Notifications";

// // Util
import api from "../../util/api";

// Constants
import { ERROR, SUCCESS } from "../../constants/Notifications";
import { PATCH_QUEST_TRANSLATIONS_WATCHER } from "../../constants/ActionTypes";
import { PATCH_QUEST_TRANSLATIONS_URL } from "../../constants/Url";

export function* patchQuestTranslationsWatcher() {
	yield takeLatest(PATCH_QUEST_TRANSLATIONS_WATCHER, patchQuestTranslationsWorker);
}

function* patchQuestTranslationsWorker(action) {
	try {
		const response = yield call(patchQuestTranslationsApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;

		if (isSuccess) {
			if (response.data && response.data.code === "SOME_FIELDS_WERE_IGNORED") {
				yield put(
					appendNotifications({
						type: SUCCESS,
						message: response.data.message,
					})
				);
			} else {
				yield put(
					appendNotifications({
						type: SUCCESS,
						message: "Successfully saved",
					})
				);
			}
		} else {
			throw response;
		}
	} catch (e) {
		const { error } = e.response.data;
		yield put(
			appendNotifications({
				type: ERROR,
				message: error,
			})
		);
	}
}

function patchQuestTranslationsApi({ questId, payload }) {
	return api.patch(PATCH_QUEST_TRANSLATIONS_URL(questId), payload);
}
