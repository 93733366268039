import {
	SET_ENDPOINT_CACHE,
	CLEAR_ENDPOINT_CACHE,
} from "../constants/ActionTypes";

export const setEndPointCache = (endpointCacheData) => {
	return {
		type: SET_ENDPOINT_CACHE,
		payload: endpointCacheData,
	};
};

export const clearEndPointCache = () => {
	return {
		type: CLEAR_ENDPOINT_CACHE,
	};
};
