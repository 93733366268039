import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import TLHeading from "../TLHeading";
import TlParagraph from "../TlParagraph";
import TLIconExclamation from "../icons/LPIconExclamation";

const TLToast = ({ title, message }) => {
	return (
		<div className="tl_toast">
			<TLIconExclamation className="tl_toast__icon" />
			<div className="tl_toast__message">
				<TLHeading className="tl_toast__heading" type="h6" variant="title" size="xsmall">
					{title}
				</TLHeading>
				<TlParagraph className="tl_toast__paragraph">{message}</TlParagraph>
			</div>
		</div>
	);
};

TLToast.propTypes = {
	title: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
};

export default TLToast;
