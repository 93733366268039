import { takeLatest, call, put } from "redux-saga/effects";
import { push } from "react-router-redux";
import { CREATE_TWITCH_BROADCAST } from "../../../constants/ActionTypes";
import { TWITCH_BROADCASTS } from "../../../constants/Url";
import { SUCCESS, ERROR } from "../../../constants/Notifications";
import { appendNotifications } from "../../../actions/Notifications";
import { addMessage } from "../../../actions/Messages";

import api from "../../../util/api";

export function* createTwitchBroadcastWatcher() {
	yield takeLatest(CREATE_TWITCH_BROADCAST, createTwitchBroadcastWorker);
}

/**
 * @param {Object} [action.options] Adds additional behavior of the action on success/failure including redirects, notifications, messages, and callbacks.
 *
 * @param {Object} [action.options.success] Behavior to execute when API call and action is successful. (2xx code)
 * @param {String} [action.options.success.redirect] Redirect to this URL on success.
 * @param {String} [action.options.success.message] Push message with passed text to ```state.messages.bank``` on success.
 * @param {String} [action.options.success.notification] Display notification with passed text on success.
 * @param {Function} [action.options.success.callback] Callback function on success.
 * @param {*} [action.options.success.callbackArgs] Callback function args on success.
 *
 * @param {Object} [action.options.failure] Behavior to execute when API call and action is failed. (non-2xx and non-4xx code)
 * @param {String} [action.options.failure.redirect] Redirect to this URL on failure.
 * @param {String} [action.options.failure.message] Push message with passed text to ```state.messages.bank``` on failure.
 * @param {String} [action.options.failure.notification] Display notification with passed text on failure.
 * @param {Function} [action.options.failure.callback] Callback function on failure.
 * @param {*} [action.options.failure.callbackArgs] Callback function args on failure.
 *
 * @param {Object} [action.options.bad_request] Behavior to execute when API call and action is failed due to bad request. (4xx code)
 * @param {String} [action.options.bad_request.redirect] Redirect to this URL on bad request.
 * @param {String} [action.options.bad_request.message] Push message with passed text to ```state.messages.bank``` on bad request.
 * @param {String} [action.options.bad_request.notification] Display notification with passed text on bad request.
 * @param {Function} [action.options.bad_request.callback] Callback function on bad request.
 * @param {*} [action.options.bad_request.callbackArgs] Callback function args on bad request.
 */
function* createTwitchBroadcastWorker(action) {
	try {
		const response = yield call(createTwitchBroadcastApi, action.params);
		const isSuccess = response.status >= 200 && response.status < 300;

		let options;
		switch (true) {
			case response.status >= 200 && response.status < 300: {
				options = action?.options?.success;
				break;
			}
			case response.status >= 400 && response.status < 500: {
				options = action?.options?.bad_request;
				break;
			}
			default: {
				options = action?.options?.failure;
				break;
			}
		}
		if (options?.notification) {
			yield put(
				appendNotifications({
					type: isSuccess ? SUCCESS : ERROR,
					message: options.notification,
				})
			);
		}
		if (options?.message) {
			yield put(
				addMessage({
					source: CREATE_TWITCH_BROADCAST,
					message: options.message,
					isError: !isSuccess,
				})
			);
		}
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
		}
		if (options?.redirect) {
			yield put(push(options.redirect));
		}
	} catch (e) {
		console.error(e);
	}
}

/**
 * Sends an API request to create a Twitch broadcast.
 * @return {Object} The response object from the API call.
 */
function createTwitchBroadcastApi(params) {
	return api
		.post(TWITCH_BROADCASTS, {
			...params,
		})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});
}
