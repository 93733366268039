import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../styles.scss";

// Actions
import { emailEvent } from "../../../../actions/Events";

// Components
import Modal from "../../../../components/Modal";
import { FormFieldInput } from "../../../../components/FormFields";

class EmailQRCodeModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			email: "",
		};
	}

	handleSubmit = () => {
		const { email } = this.state;
		const { emailEvent, eventId } = this.props;
		if (email) emailEvent({ email }, eventId);
	};

	render() {
		const { email } = this.state;
		return (
			<Modal
				className="admin"
				triggerBtnText="Email code"
				triggerBtnClassName="button dropdown_option__button"
				cancelBtnText="Cancel"
				submitBtnText="Send"
				submitDisabled={email === ""}
				onSubmit={() => this.handleSubmit()}
				onClose={() => this.setState({ email })}
			>
				<FormFieldInput
					intro={"Enter the email address"}
					label={"email"}
					type={"text"}
					name={"email"}
					value={email}
					placeholder={"email"}
					onChange={({ target }) =>
						this.setState({ email: target.value })
					}
				/>
			</Modal>
		);
	}
}

EmailQRCodeModal.propTypes = {
	emailEvent: PropTypes.func.isRequired,
	eventId: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => {
	return {
		emailEvent: (params, id) => dispatch(emailEvent(params, id)),
	};
};

export default connect(null, mapDispatchToProps)(EmailQRCodeModal);
