import { SET_TWITCH_APP_MODERATORS } from "../constants/ActionTypes";

const initialState = {
	moderators: [],
};

export default function twitchApp(state = initialState, action) {
	switch (action.type) {
		case SET_TWITCH_APP_MODERATORS:
			return {
				...state,
				moderators: action.params,
			};
		default:
			return state;
	}
}
