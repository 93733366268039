import { MINUTE } from "../../constants/Time";

/*
    --- Browser Storage ---
    Wraps the functionality of localStorage and sessionStorage compactly.
    Can access the single instance using 'window.browserStorage.[method]'
*/

class BrowserStorage {
	constructor() {
		this.interval = MINUTE;
		this.cache = {};
	}

	set(key, value) {
		this._setLocalStorage(key, value);
	}

	setTemporary(key, value) {
		this._setSessionStorage(key, value);
	}

	get(key) {
		return sessionStorage.getItem(key) || localStorage.getItem(key);
	}

	remove(key) {
		localStorage.removeItem(key);
		sessionStorage.removeItem(key);
	}

	empty() {
		localStorage.clear();
		sessionStorage.clear();
	}

	removeTemporary(key) {
		sessionStorage.removeItem(key);
	}

	configureInterval(interval) {
		this.interval = interval;
	}

	/*
        --- JWT Storage ---
    */

	storePermission(permissions) {
		this.set("permission", JSON.stringify(permissions));
	}

	removePermission() {
		this.remove("permission");
	}

	getPermission() {
		return JSON.parse(this.get("permission"));
	}

	storeJWT(jwt) {
		this.set("jwt", jwt);
	}

	storeRefreshToken(refreshToken) {
		this.set("refresh_token", refreshToken);
	}

	removeJWT() {
		this.remove("jwt");
	}

	getJWT() {
		return this.get("jwt");
	}

	removecode() {
		this.remove("code");
	}

	removeCheckInCode() {
		this.remove("checkInCode");
	}

	/* 
        -----------------------------------------------------------------
        NOT suggested for continuous use, can be useful for quick storage.
        -----------------------------------------------------------------
    */

	cache(key, value) {
		this.cache[key] = value;
	}

	getCache(key) {
		return this.cache[key];
	}

	clearCache() {
		this.cache = {};
	}

	/*  ----------------------------------------------------------------- */

	_setLocalStorage(key, value) {
		localStorage.setItem(key, value);
	}

	_setSessionStorage(key, value) {
		sessionStorage.setItem(key, value);
	}

	/* ------------------------------------------------------------------- */

	setRecentPath(key, value) {
		this._setSessionStorage(key, value);
	}
}

export default new BrowserStorage();
