import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { updateRewards } from '../../../../actions/Rewards';
import "../styles.scss";

class RestoreDialog extends React.Component {
    constructor(props) {
        super(props);
    }

    handleSubmit = () => {
        this.props.updateRewards({
            id: this.props._id,
            params: {
                is_active: false,
                archive_date: null
            }
        });
    };

    render() {
        return (
            <Modal className="admin"
                triggerBtnText="Restore Reward"
                triggerBtnClassName="button button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Restore"
                onSubmit={() => this.handleSubmit()}
            >
                Restore this reward?
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateRewards: (payload) => dispatch(updateRewards(payload))
    }
}

const mapStateToProps = state => {
    return {

    }
}

RestoreDialog = connect(mapStateToProps, mapDispatchToProps)(RestoreDialog);

export default RestoreDialog