import { SET_V2_QUESTS_BANK } from "../constants/ActionTypes";

export default function questsReducer(state = {}, action) {
	switch (action.type) {
		case SET_V2_QUESTS_BANK:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}
