import {
    all
} from 'redux-saga/effects';

import {
    fetchPerksWatcher,
} from './FetchPerksSagas'

import {
    fetchUserPerksWatcher,
} from './FetchUserPerksSagas'


export function* perksSagas() {
    yield all([        
        fetchPerksWatcher(),
        fetchUserPerksWatcher(),
    ]);
}