import {
	ON_SHOW_LOADER,
	ON_HIDE_LOADER,
	USER_RIGHTS,
	USER_INFO_STATE,
	SET_GAMES,
	SET_PLAYERS,
	SET_SEASONS,
	SET_RUM_STATUS,
} from "../constants/ActionTypes";

const INIT_STATE = {
	loader: false,
	rights: JSON.parse(window.browserStorage.get("rights")) || [],
	userInfo: JSON.parse(window.browserStorage.get("user-info")) || {},
	games: [],
	players: [],
	seasons: [],
	rumStatus: false,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		case ON_SHOW_LOADER: {
			return {
				...state,
				loader: true,
			};
		}
		case ON_HIDE_LOADER: {
			return {
				...state,
				loader: false,
			};
		}
		case USER_RIGHTS: {
			return {
				...state,
				rights: action.payload,
			};
		}
		case USER_INFO_STATE: {
			return {
				...state,
				userInfo: action.payload,
			};
		}
		case SET_GAMES: {
			return {
				...state,
				games: action.payload,
			};
		}
		case SET_PLAYERS: {
			return {
				...state,
				players: action.payload,
			};
		}
		case SET_SEASONS: {
			return {
				...state,
				seasons: action.payload,
			};
		}
		case SET_RUM_STATUS: {
			return {
				...state,
				rumStatus: action.payload,
			};
		}
		default:
			return state;
	}
};
