import { SET_LIQUIPEDIA_KEYWORDS } from "../constants/ActionTypes";

const initialState = {
	keywords: [],
};

export default function liquipediaReducer(state = initialState, action) {
	switch (action.type) {
		case SET_LIQUIPEDIA_KEYWORDS:
			return {
				...state,
				keywords: action.payload,
			};
		default:
			return state;
	}
}
