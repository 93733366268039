import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./styles.scss";

function TLIcon({ alt, ariaLabel, className, role, size, src, title }) {
	return (
		<img
			alt={alt}
			aria-label={ariaLabel}
			className={classnames("tl_icon", "tl_icon--" + size, className)}
			role={role}
			src={src}
			title={title}
		/>
	);
}

TLIcon.defaultProps = {
	size: "medium",
	role: "presentation",
};

TLIcon.propTypes = {
	alt: PropTypes.string.isRequired,
	ariaLabel: PropTypes.string,
	className: PropTypes.string,
	role: PropTypes.string,
	size: PropTypes.oneOf(["small", "medium"]),
	src: PropTypes.string,
	title: PropTypes.string,
};

export default TLIcon;
