import React from "react";
import { connect } from "react-redux";
import Modal from '../../../../components/Modal';
import { FormFieldSelect } from '../../../../components/FormFields';
import { MockSerializedEvents } from "../MockData";
import { updateEvent } from "../../../../actions/Events";
import "../styles.scss";

class ChangeSerialEventModal extends React.Component {
    constructor(props) {
        super(props)
        this.serializedEvents = MockSerializedEvents;

        this.state = {
            serial_event_field: "",
        }
    }

    componentWillMount() {
        this.setState({
            serial_event_field: this.props.serial_event_field
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            serial_event_field: nextProps.serial_event_field
        })
    }

    handleSubmit = () => {
        if (this.state.serial_event_field) {
            this.props.updateEvent({
                serial_event_field: this.state.serial_event_field
            }, this.props.eventId);
        }
    };

    renderSerializedEvents() {
        let options = [];
        this.serializedEvents.map(event => {
            options.push(event);
        });
        return options;
    }

    render() {
        return (
            <Modal className="admin"
                triggerBtnText="Change"
                triggerBtnClassName="button button--borderless"
                cancelBtnText="Cancel"
                submitBtnText="Save"
                submitDisabled={this.state.serial_event_field === this.props.serial_event_field}
                onSubmit={() => this.handleSubmit()}
                onClose={(event) => this.setState({ serial_event_field: this.props.serial_event_field })}
            >
                <FormFieldSelect
                    label={"Serial event Name"}
                    name={"serial_event_field"}
                    value={this.state.serial_event_field}
                    options={this.renderSerializedEvents()}
                    onChange={(event) => this.setState({ serial_event_field: event.target.value })}
                />
            </Modal>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateEvent: (params, id) => dispatch(updateEvent(params, id)),
    }
}

const mapStateToProps = state => {
    return {

    }
}

ChangeSerialEventModal = connect(mapStateToProps, mapDispatchToProps)(ChangeSerialEventModal);

export { ChangeSerialEventModal }
