import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import LpHeading from "../../../../components/LpHeading";
import { NO_SHOPIFY_STORE_TO_SHOW } from "../../../../constants/Label";

class liquidStore extends Component {
	backToChannels = () => {
		this.props.history.push("/app/servicesettings/channels");
	};

	createNewStore = (payload) => {
		console.log("createNewStore");
	};

	renderStoreList = (List) => {
		if (List.length) {
			return List.map((item) => {
				return (
					<TableRow key={item.id}>
						<TableCell>
							<div className="user-profile d-flex flex-row align-items-center">
								<div className="user-detail">
									<span>{item.name}</span>
								</div>
							</div>
						</TableCell>
						<TableCell className={`align-right`}>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								onClick={(event) => this.createNewStore(event)}
								className="jr-btn"
							>
								Add
							</Button>
						</TableCell>
					</TableRow>
				);
			});
		} else {
			return (
				<TableRow>
					<TableCell>{NO_SHOPIFY_STORE_TO_SHOW}</TableCell>
				</TableRow>
			);
		}
	};

	render() {
		return (
			<div className="channel">
				<div className="options-header">
					<div className="options options--left">
						<button
							className="button button--borderless"
							onClick={() => this.backToChannels()}
						>
							<span>
								<i className="zmdi zmdi-chevron-left zmdi-hc-lg pr-1" />
							</span>
							&nbsp;Channels
						</button>
					</div>
					<div className="options options--right">
						<button
							className="button"
							onClick={(event) => this.createNewStore(event)}
						>
							Add Team Liquid store
						</button>
					</div>
				</div>
				<div className="m-3 p-3">
					<div>
						<LpHeading>Team Liquid store</LpHeading>
					</div>
					<div className="table-responsive-material shadow border-0 card">
						<Table>
							<TableBody>
								{this.renderStoreList(this.props.data)}
							</TableBody>
						</Table>
					</div>
				</div>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {};
};

const mapStateToProps = ({ channel }) => {
	return {
		data: [],
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(liquidStore);
