import {
    takeLatest,
    call,
    put,
    select,
} from "redux-saga/effects";
import {
    UPDATE_USER_WITHOUT_FETCH_WATCHER,
} from "../../constants/ActionTypes";
import {
    USER_SERVICE_UPDATE_URL,
} from "../../constants/Url";
import {
    appendNotifications
} from "../../actions/Notifications";
import {
    addMessage,
} from "../../actions/Messages";
import {
    USER_UPDATE,
    ERROR,
} from "../../constants/Notifications";
import {
    setNestedObjectParam,
} from "../../util/StateUtils";
import api from '../../util/api'; 

const fetchUserProfile = (state) => state.user.profile

export function* userUpdateWithoutFetchWatcher() {
    yield takeLatest(UPDATE_USER_WITHOUT_FETCH_WATCHER, userUpdateWithoutFetchWorker);
};

function* userUpdateWithoutFetchWorker(action) {
    try {
        let user = yield select(fetchUserProfile);

        switch(typeof action.payload.params){
            case "string": {
                action.payload.params = setNestedObjectParam(user, action.payload.params, action.payload.value);
                break;
            };
            case "object":
            default:
                break;
        };

        let response = yield call(userUpdateApi, action.payload, user.id);
        if (response.status >= 200 && response.status < 300) {
            yield put(addMessage({
                source: UPDATE_USER_WITHOUT_FETCH_WATCHER,
                message: response.msg || `Success.`,
                isError: false,
            }));
        } else {
            yield put(addMessage({
                source: UPDATE_USER_WITHOUT_FETCH_WATCHER,
                message: response.msg || `Unknown error.`,
                isError: true,
            }));
        };
    } catch (e) {
        yield put(appendNotifications(USER_UPDATE(ERROR, e.msg ? e.msg : action.property || "")));
    };
};

function userUpdateApi(payload, id) {
    return (
        api
        .post(USER_SERVICE_UPDATE_URL(id), {
            ...payload.params,
            id,
        }).then(response => {
            return response.data;
        }).catch(err =>{
            return err.response.data;
        })
    );
};