import * as React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./styles.scss";

function LpTooltip({ wrapperClassName, className, variant, text, children }) {
	return (
		<div
			className={classnames("lp_tooltip", `lp_tooltip--${variant}`, wrapperClassName)}
			tabIndex={0}
		>
			{children}
			<span className={classnames("lp_tooltip__text", className)}>{text}</span>
		</div>
	);
}

LpTooltip.defaultProps = {
	variant: "default",
};

LpTooltip.propTypes = {
	wrapperClassName: PropTypes.string,
	className: PropTypes.string,
	variant: PropTypes.string,
	text: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default LpTooltip;
