/* eslint-disable complexity */
import { jsx } from "slate-hyperscript";
import { ELEMENT_TAGS, TEXT_TAGS, LIST_TYPES, HEADING_TYPES } from "../constants";
import { Transforms, Editor, Element, Text, Node } from "slate";
import { ReactEditor } from "slate-react";
import escapeHtml from "escape-html";

export function getSelectedNodes(editor) {
	const selection = editor.selection;
	if (selection !== null && selection.anchor !== null) {
		return editor.children[selection.anchor.path[0]];
	} else {
		return [];
	}
}

export const deserialize = (el) => {
	if (el.textContent === "null") {
		return null;
	}

	if (el.nextSibling?.tagName === "BR" && el.textContent !== "" && el.nodeType === 3) {
		el.textContent += "\n";
	}

	if (el.nodeType === 3 && el.parentNode.nodeName === "BODY") {
		return jsx("element", { type: "paragraph" }, [{ text: el.textContent }]);
	}

	if (el.nodeType === 3) {
		return el.textContent;
	} else if (el.nodeType !== 1) {
		return null;
	}

	const { nodeName } = el;
	let parent = el;

	if (nodeName === "PRE" && el.childNodes[0] && el.childNodes[0].nodeName === "CODE") {
		parent = el.childNodes[0];
	}
	let children = Array.from(parent.childNodes).map(deserialize).flat();

	if (children.length === 0) {
		children = [{ text: "" }];
	}

	if (el.nodeName === "BODY") {
		return jsx("fragment", {}, children);
	}

	if (el.nodeName === "SPAN" && el.parentNode.nodeName !== "BODY") {
		return el.textContent;
	}

	if (ELEMENT_TAGS[nodeName]) {
		const attrs = ELEMENT_TAGS[nodeName](el);
		return jsx("element", attrs, children);
	}

	if (TEXT_TAGS[nodeName]) {
		const attrs = TEXT_TAGS[nodeName](el);
		return children.map((child) => jsx("text", attrs, child));
	}

	return children;
};

const serialize = (node) => {
	if (Text.isText(node)) {
		let htmlString = escapeHtml(node.text);
		let onlyWhitespace = htmlString.trim() === "";
		htmlString = htmlString.replace(/(?:\r\n|\r|\n)/g, "<br/>");

		if (node.bold && !onlyWhitespace) {
			htmlString = "<strong>" + htmlString + "</strong>";
		}

		if (node.italic && !onlyWhitespace) {
			htmlString = "<em>" + htmlString + "</em>";
		}

		return htmlString;
	}
	let children = null;

	if (node.children?.length > 0) {
		children = node.children.map((n) => serialize(n)).join("");
	}

	switch (node.type) {
		case "heading-one":
			return `<h1 class="lpheading lpheading--main-page">${children}</h1>`;
		case "heading-two":
			return `<h2 class="lpheading lpheading--section">${children}</h2>`;
		case "heading-three":
			return `<h3 class="lpheading lpheading--sub-section">${children}</h3>`;
		case "paragraph":
			return `<p>${children}</p>`;
		case "ordered-list":
			return `<ol>${children}</ol>`;
		case "unordered-list":
			return `<ul>${children}</ul>`;
		case "list-item":
			return `<li>${children}</li>`;
		case "link":
			return `<a target="${node.target}" href="${escapeHtml(node.url)}">${children}</a>`;
		default:
			return children;
	}
};

export const serializeSlateToHtml = (node) => {
	let htmlOutput = "";
	node.forEach((subnode) => {
		htmlOutput += serialize(subnode);
	});
	return htmlOutput;
};

export const customEditor = {
	isBoldMarkActive(editor) {
		return Editor.marks(editor)?.bold === true;
	},

	isItalicMarkActive(editor) {
		return Editor.marks(editor)?.italic === true;
	},

	toggleBoldMark(editor) {
		const isActive = customEditor.isBoldMarkActive(editor);
		if (isActive) {
			Editor.removeMark(editor, "bold");
		} else {
			Editor.addMark(editor, "bold", true);
		}
	},

	toggleItalicMark(editor) {
		const isActive = customEditor.isItalicMarkActive(editor);
		if (isActive) {
			Editor.removeMark(editor, "italic");
		} else {
			Editor.addMark(editor, "italic", true);
		}
	},

	removeLink(editor, opts = {}) {
		Transforms.unwrapNodes(editor, {
			...opts,
			match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
		});
	},

	cleanFormatting(editor) {
		customEditor.removeLink(editor);
		Transforms.setNodes(
			editor,
			{ italic: null, bold: null },
			{ match: (n) => Text.isText(n), split: true }
		);
		customEditor.toggleBlock(editor, "paragraph");
	},

	applyLink(editor, payload) {
		const { stateLinkUrl, stateLinkText, stateLinkExternal } = payload;
		const link = {
			type: "link",
			url: stateLinkUrl,
			target: stateLinkExternal ? "_blank" : "_self",
			children: [{ text: stateLinkText }],
		};
		const blankSpace = {
			text: " ",
		};

		Transforms.delete(editor);
		Transforms.insertNodes(editor, [link, blankSpace]);
		this.focusEditor(editor);
	},

	editLink(editor, payload, path) {
		const { stateLinkUrl, stateLinkText, stateLinkExternal } = payload;
		const newProps = {
			type: "link",
			url: stateLinkUrl,
			target: stateLinkExternal ? "_blank" : "_self",
			children: [{ text: stateLinkText }],
		};
		Transforms.delete(editor, { at: path });
		Transforms.insertNodes(editor, newProps);
		this.focusEditor(editor);
	},

	toggleBlock(editor, format, editorRef) {
		const isActive = customEditor.isBlockActive(editor, format);
		const isList = LIST_TYPES.includes(format);

		Editor.withoutNormalizing(editor, () => {
			Transforms.unwrapNodes(editor, {
				match: (n) =>
					!Editor.isEditor(n) && Element.isElement(n) && LIST_TYPES.includes(n.type),
				split: true,
			});

			let newProperties = {
				type: isActive ? "paragraph" : isList ? "list-item" : format,
			};
			Transforms.setNodes(editor, newProperties);

			if (!isActive && isList) {
				Transforms.wrapNodes(editor, { type: format });
			}
			this.focusEditor(editor);
		});
	},

	isBlockActive(editor, format) {
		const { selection } = editor;
		if (!selection) return false;

		const [match] = Array.from(
			Editor.nodes(editor, {
				at: Editor.unhangRange(editor, selection),
				match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n["type"] === format,
			})
		);

		return !!match;
	},

	focusEditor(editor) {
		Transforms.collapse(editor, { edge: "end" });
		ReactEditor.focus(editor);
	},
};

export const customNormalize = {
	paragraphContainsValidChildren(node, path, editor) {
		// // If the element is a paragraph, ensure its children are valid.
		if (Element.isElement(node) && node.type === "paragraph") {
			for (const [child, childPath] of Node.children(editor, path)) {
				if (Element.isElement(child) && !editor.isInline(child)) {
					Transforms.unwrapNodes(editor, { at: childPath });
					return;
				}
			}
		}
	},

	unorderedListHasOnlyListItems(node, path, editor) {
		// Only list-items are allowed in unordered lists.
		if (Element.isElement(node) && LIST_TYPES.includes(node.type)) {
			for (const [child, childPath] of Node.children(editor, path)) {
				if (Element.isElement(child) && child.type !== "list-item") {
					Transforms.liftNodes(editor, { at: childPath });
					return;
				}
			}
		}
	},

	exitListItemWhenEmptyOnReturn(node, path, editor) {
		// If there are 2 consequtive empty list items transform the last one in paragraph
		if (Element.isElement(node) && LIST_TYPES.includes(node.type)) {
			const totalChildren = node.children.length;
			let childCount = 0;
			let emptyListItemCount = 0;
			let childPaths = [];
			for (const [child, childPath] of Node.children(editor, path)) {
				childCount++;
				if (
					Element.isElement(child) &&
					child.type === "list-item" &&
					child.children[0].text === ""
				) {
					childPaths[emptyListItemCount] = childPath;
					if (emptyListItemCount > 0 && childCount === totalChildren) {
						Transforms.setNodes(editor, { type: "paragraph" }, { at: childPath });
						Transforms.removeNodes(editor, { at: childPaths[0] });
					} else {
						emptyListItemCount = 0;
					}
					emptyListItemCount++;
				} else {
					emptyListItemCount = 0;
				}
			}
			return;
		}
	},

	dontAllowParagraphsInListItems(node, path, editor) {
		// Paragraphs are not are allowed in list items.
		if (Element.isElement(node) && node.type === "list-item") {
			for (const [child, childPath] of Node.children(editor, path)) {
				if (Element.isElement(child) && child.type === "paragraph") {
					Transforms.unwrapNodes(editor, { at: childPath });
					return;
				}
			}
		}
	},

	dontAllowParagraphsInHeadings(node, path, editor) {
		// Paragraphs are not are allowed in heading items.
		if (Element.isElement(node) && HEADING_TYPES.includes(node.type)) {
			for (const [child, childPath] of Node.children(editor, path)) {
				if (Element.isElement(child) && child.type === "paragraph") {
					Transforms.unwrapNodes(editor, { at: childPath });
					return;
				}
			}
		}
	},

	dontAllowListItemsInroot(node, path, editor) {
		// List items are not allowed as direct elements in the root.
		if (Element.isElement(node) && node.type === "list-item") {
			if (!LIST_TYPES.includes(Node.parent(editor, path).type)) {
				// Transforms.unwrapNodes(editor, { at: path });
				Transforms.setNodes(editor, { type: "paragraph" }, { at: path });
				return;
			}
		}
	},
};
