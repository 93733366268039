import {all} from 'redux-saga/effects';

import {
    fetchTwitchDataWatcher
} from './FetchTwitchSagas'
import {
    createTwitchDataWatcher
} from './CreateTwitchChannelSagas'
import {
    removeTwitchDataWatcher
} from './RemoveTwitchChannelSagas'
import {
    fetchTwitchEventsScheduleWatcher
} from './FetchTwitchEventsScheduleSagas'
import {
    fetchTwitchLiveStreamersWatcher
} from './FetchTwitchLiveStreamersSagas'
import {
    fetchTwitchBroadcastsWatcher
} from './FetchTwitchBroadcastsSagas'
import {
    createTwitchBroadcastWatcher
} from './CreateTwitchBroadcastSagas'
import {
    updateTwitchBroadcastWatcher
} from './UpdateTwitchBroadcastSagas'
import {
    deleteTwitchBroadcastWatcher
} from './DeleteTwitchBroadcastSagas'
import {
    authorizeTwitchOauthWatcher,
} from './AuthorizeTwitchOauthSagas'
import { fetchModeratorsWatcher } from "./FetchTwitchAppModeratorSagas";
import { deleteModeratorWatcher } from "./DeleteTwitchAppModeratorSagas";
import { updateModeratorWatcher } from "./UpdateTwitchAppModeratorSagas";
import { updateTwitchBroadcastsModeratorWatcher } from "./UpdateTwitchBroadcastsModeratorSagas";

export function *twitchSagas() {
    yield all([
        fetchTwitchDataWatcher(),
        createTwitchDataWatcher(),
        removeTwitchDataWatcher(),
        fetchTwitchEventsScheduleWatcher(),
        fetchTwitchLiveStreamersWatcher(),
        fetchTwitchBroadcastsWatcher(),
        createTwitchBroadcastWatcher(),
        updateTwitchBroadcastWatcher(),
        deleteTwitchBroadcastWatcher(),
        authorizeTwitchOauthWatcher(),
        fetchModeratorsWatcher(),
        deleteModeratorWatcher(),
        updateModeratorWatcher(),
        updateTwitchBroadcastsModeratorWatcher(),
    ]);
}
