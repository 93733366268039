import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./styles/styles.scss";

// Components
import LpHeading from "../LpHeading";
import OrderHeader from "./atoms/OrderHeader";
import OrderContent from "./atoms/OrderContent";

// Constants
import { ADMIN } from "../../constants/Environments";

// Utils
import { formatNumber } from "../../util/stringUtils";
import { formatDateInternational, formatDateTimeInternational } from "../../util/DateUtil";

class LpOrder extends PureComponent {
	render() {
		const { order, env, preview, className } = this.props;

		if (order) {
			const rebateCodes = order.items[0].payload?.value;
			const isAdmin = env === ADMIN;

			return (
				<div
					className={classnames("lp_order", className, {
						"lp_order--preview": preview,
						"lp_order--admin": isAdmin,
					})}
				>
					{!preview && (
						<section className="lp_order__summary">
							<div className="lp_order__description">
								<LpHeading type="h3" variant="section" className="lp_order__title">
									Order #{order.id}
								</LpHeading>
								<span className="lp_order__date">
									{isAdmin
										? `${formatDateTimeInternational(order.createDate)} | by ${
												order.user_username
										  }`
										: `Placed on ${formatDateInternational(order.createDate)}`}
								</span>
							</div>
							<OrderHeader isAdmin={isAdmin} order={order} />
						</section>
					)}
					<OrderContent reward={order.items[0]} order={order} rebateCodes={rebateCodes} />
					{order.address && order.address.country && (
						<section className="lp_order__shipping">
							<LpHeading type="h4" variant="section" className="lp_order__title">
								Shipping Address
							</LpHeading>
							<p className="lp_order__address">
								{order.address.name}
								<br />
								{order.address.street}, {order.address.secondary_street}
								<br />
								{order.address.city}, {order.address.state}, {order.address.zip}
								<br />
								{order.address.country}
								<br />
								{order.address.phone_country_code} {order.address.phone_number}
							</p>
						</section>
					)}
					<section className="lp_order__total">
						Total:{" "}
						{formatNumber(
							parseInt(order.items[0].quantity) * parseInt(order.items[0].point_count)
						)}{" "}
						Points
					</section>
				</div>
			);
		}
		return <span>Loading</span>;
	}
}

LpOrder.propTypes = {
	order: PropTypes.shape({
		id: PropTypes.string,
		createDate: PropTypes.instanceOf(Date),
		user_username: PropTypes.string,
		items: PropTypes.array,
		type: PropTypes.string,
		has_been_fulfilled: PropTypes.bool,
		address: PropTypes.shape({
			name: PropTypes.string,
			street: PropTypes.string,
			secondary_street: PropTypes.string,
			zip: PropTypes.string,
			city: PropTypes.string,
			state: PropTypes.string,
			country: PropTypes.string,
			phone_country_code: PropTypes.string,
			phone_number: PropTypes.string,
		}),
	}),
	env: PropTypes.string,
	preview: PropTypes.bool,
	className: PropTypes.string,
};

export default LpOrder;
