import { takeLatest, call, put } from "redux-saga/effects";
import i18n from "i18next";

// Actions
import { addMessage } from "../../actions/Messages";
import { appendNotifications } from "../../actions/Notifications";
import { setUserProfileParam } from "../../actions/User";

// Constants
import { LINK_SHOPIFY_ACCOUNT, VERIFY_SHOPIFY_ACCOUNT } from "../../constants/ActionTypes";
import { LINK_SHOPIFY_ACCOUNT_URL, VERIFY_SHOPIFY_ACCOUNT_URL } from "../../constants/Url";
import { ERROR, SUCCESS } from "../../constants/Notifications";

// Utils
import api from "../../util/api";
import { push } from "react-router-redux";
import { CONNECTED_PLATFORMS } from "../../constants/RoutesURLs";

export function* linkShopifyAccountWatcher() {
	yield takeLatest(LINK_SHOPIFY_ACCOUNT, linkShopifyAccountWorker);
}

export function* verifyShopifyAccountWatcher() {
	yield takeLatest(VERIFY_SHOPIFY_ACCOUNT, verifyShopifyAccountWorker);
}

function* linkShopifyAccountWorker(action) {
	try {
		const email = action.payload;
		const response = yield call(linkShopifyAccountAPI, email);
		const callback = action?.options?.success?.callback;
		const showNotification = action?.options?.showNotification;

		if (response.success) {
			yield put(
				addMessage({
					source: LINK_SHOPIFY_ACCOUNT,
					message: response.message || `Success`,
					isError: false,
				})
			);
			if (showNotification) {
				yield put(
					appendNotifications({
						type: SUCCESS,
						message: i18n.t("platformConnections:linkResentSuccessfully"),
					})
				);
			}
			if (callback) yield call(callback);
		} else {
			yield put(appendNotifications({ type: ERROR, message: response.message || "Error" }));
		}
	} catch (e) {
		console.error(e);
	}
}

function* verifyShopifyAccountWorker(action) {
	try {
		let response = yield call(verifyShopifyAccountAPI, action.payload);
		if (response.success) {
			yield put(setUserProfileParam("socialCredentials", response?.data));
			yield put(
				appendNotifications({
					message: response.message || "Success",
					title: i18n.t("notifications:platformConnected"),
					type: SUCCESS,
				})
			);
		} else {
			yield put(
				appendNotifications({
					message: response.message || "Error",
					type: ERROR,
				})
			);
		}
		yield put(push(CONNECTED_PLATFORMS));
	} catch (e) {
		console.error(e);
	}
}

function linkShopifyAccountAPI(shopifyEmail) {
	return api
		.post(LINK_SHOPIFY_ACCOUNT_URL, {
			shopify_email: shopifyEmail,
		})
		.then((response) => {
			return {
				success: true,
				message: response.data.msg,
			};
		})
		.catch((error) => {
			return {
				success: false,
				message: error.response.data.msg,
			};
		});
}
function verifyShopifyAccountAPI(verificationToken) {
	return api
		.post(VERIFY_SHOPIFY_ACCOUNT_URL, {
			verification_token: verificationToken,
		})
		.then((response) => {
			return {
				success: true,
				message: response.data.msg,
				data: response.data.data,
			};
		})
		.catch((error) => {
			return {
				success: false,
				message: error.response.data.msg,
			};
		});
}
