import React from "react";
import PropTypes from "prop-types";

const MAX_VISIBLE_OPTIONS = 3;
const MAX_VISIBLE_FILTERED_OPTIONS = 8;

function DatalistOptions({ options, search, onClick }) {

	if (search.length > 0) {
		const filtered_options = options.filter((option) =>
			option.label.toLowerCase().startsWith(search.toLowerCase())
		);

		if (filtered_options.length === 0) {
			return (
				<ul className="form-field__select-options form-field__datalist-options">
					<li className="form-field__select-option form-field__select-option--inactive">
						No Entries
					</li>
				</ul>
			);
		}

		if (filtered_options.length > MAX_VISIBLE_FILTERED_OPTIONS) {
			return (
				<ul className="form-field__select-options form-field__datalist-options">
					{options
						.slice(0, MAX_VISIBLE_FILTERED_OPTIONS)
						.map((option) => {
							return (
								<li
									key={option.label}
									className="form-field__select-option"
									onClick={() => onClick(option.value)}
								>
									{option.label}
								</li>
							);
						})}
					<li
						key="more-data-list-items"
						className="form-field__select-option form-field__select-option--inactive"
					>
						{`${
							options.length - MAX_VISIBLE_FILTERED_OPTIONS
						} More...`}
					</li>
				</ul>
			);
		}

		return (
			<ul className="form-field__select-options form-field__datalist-options">
				{filtered_options.map((option) => {
					return (
						<li
							key={option.label}
							className="form-field__select-option"
							onClick={() => onClick(option.value)}
						>
							{option.label}
						</li>
					);
				})}
			</ul>
		);
	} else if (options.length > MAX_VISIBLE_OPTIONS) {
		return (
			<ul className="form-field__select-options form-field__datalist-options">
				{options.slice(0, MAX_VISIBLE_OPTIONS).map((option) => {
					return (
						<li
							key={option.label}
							className="form-field__select-option"
							onClick={() => onClick(option.value)}
						>
							{option.label}
						</li>
					);
				})}
				<li
					key="more-data-list-items"
					className="form-field__select-option form-field__select-option--inactive"
				>
					{`${options.length - MAX_VISIBLE_OPTIONS} More Entries...`}
				</li>
			</ul>
		);
	} else {
		return (
			<ul className="form-field__select-options form-field__datalist-options">
				{options.map((option) => {
					return (
						<li
							key={option.label}
							className="form-field__select-option"
							onClick={() => onClick(option.value)}
						>
							{option.label}
						</li>
					);
				})}
			</ul>
		);
	}
}

DatalistOptions.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.any.isRequired,
		})
	),
	search: PropTypes.string,
	onClick: PropTypes.func.isRequired,
};

export default DatalistOptions;
