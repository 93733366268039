import {
    APPEND_NOTIFICATIONS,
    POP_NOTIFICATION,
    UNSHIFT_NOTIFICATION,
    REMOVE_NOTIFICATION,
    CLEAR_NOTIFICATIONS
} from '../constants/ActionTypes'

export const appendNotifications = (items) => {
    return {
        type: APPEND_NOTIFICATIONS,
        payload: items
    }
}


export const popNotification = () => {
    return {
        type: POP_NOTIFICATION
    }
}

export const unshiftNotification = () => {
    return {
        type: UNSHIFT_NOTIFICATION
    }
}

export const removeNotification = (id) => {
    return {
        type: REMOVE_NOTIFICATION,
        payload: id
    }
}

export const clearNotifications = () => {
    return {
        type: CLEAR_NOTIFICATIONS
    }
}