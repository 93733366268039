import { SET_COUNTRIES } from "../constants/ActionTypes";

const countriesInitialState = [];

export default function countriesReducer(state = countriesInitialState, action) {
	switch (action.type) {
		case SET_COUNTRIES:
			return action.payload;
		default:
			return state;
	}
}
