import React from "react";
import PropTypes from "prop-types";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import DeleteModeratorDialog from "./atoms/DeleteModeratorDialog";
import SetDefaultModeratorDialog from "./atoms/SetDefaultModeratorDialog";

function TwitchAppModeratorRow({ id, twitchId, name = "", defaultMod, onDelete, onSetDefault }) {
	return (
		<TableRow>
			<TableCell>
				<span>{twitchId}</span>
			</TableCell>
			<TableCell>
				<span>{name}</span>
			</TableCell>
			<TableCell>
				<span>{`${defaultMod}`}</span>
			</TableCell>
			<TableCell className="actions">
				{!defaultMod && (
					<>
						<SetDefaultModeratorDialog handleAccept={() => onSetDefault(id)} />
						<DeleteModeratorDialog handleDelete={() => onDelete(id)} />
					</>
				)}
			</TableCell>
		</TableRow>
	);
}

TwitchAppModeratorRow.propTypes = {
	id: PropTypes.string.isRequired,
	twitchId: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	defaultMod: PropTypes.bool.isRequired,
	onDelete: PropTypes.func.isRequired,
	onSetDefault: PropTypes.func.isRequired,
};

export default TwitchAppModeratorRow;
