import React from "react";
import PropTypes from "prop-types";
import LpIcon from "../../LpIcon";

const LpIconEllipsis = (allprops) => {
	let { variant, ...props } = allprops;

	return (
		<LpIcon
			src={require("./svg/icon-ellipsis" + (variant ? "-" + variant : "") + ".svg")}
			{...props}
		/>
	);
};

LpIconEllipsis.defaultProps = {
	variant: "black",
	title: "Options",
	alt: "Options icon",
	arialabel: "Options",
	size: "small",
};

LpIconEllipsis.propTypes = {
	className: PropTypes.string,
	variant: PropTypes.string,
	title: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	arialabel: PropTypes.string.isRequired,
	size: PropTypes.oneOf(["small", "medium"]),
};

export default LpIconEllipsis;
