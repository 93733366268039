import React from "react";
import PropTypes from "prop-types";

// Components
import LpIcon from "../../../LpIcon";
import OrderOptions from "../OrderOptions";

function OrderHeader({ order, isAdmin }) {
	return (
		<div className="lp_order__options">
			{order.has_been_fulfilled && (
				<span className="lp_order__status">
					<LpIcon
						title={"Order has been fulfilled"}
						src={require("../../../../svg/checkmark-circle.svg")}
						className={"lp_order__icon"}
						alt="fulfilled"
					/>
					<span className="lp_order__status_text">Fulfilled</span>
				</span>
			)}
			{isAdmin && (
				<span className="lp_order__dropdown">
					<OrderOptions order={order} />
				</span>
			)}
		</div>
	);
}

OrderHeader.propTypes = {
	order: PropTypes.object,
	isAdmin: PropTypes.bool,
};

export default OrderHeader;
