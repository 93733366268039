import { takeLatest, call, put } from "redux-saga/effects";
import { setObjectiveAlgorithmsBank } from "../../actions/Quests";
import { FETCH_OBJECTIVE_ALGORITHMS_WATCHER } from "../../constants/ActionTypes";
import { FETCH_OBJECTIVE_ALGORITHMS_URL } from "../../constants/Url";
import api from "../../util/api";
import mapValues from "lodash/mapValues";
import pick from "lodash/pick";

export function* fetchObjectiveAlgorithmsWatcher() {
	yield takeLatest(FETCH_OBJECTIVE_ALGORITHMS_WATCHER, fetchObjectiveAlgorithmsWorker);
}

function* fetchObjectiveAlgorithmsWorker(action) {
	try {
		let response = yield call(fetchQuestsApi, action.payload);
		if (response.status >= 200 && response.status < 300) {
			const algorithms = mapAlgorithms(response.data.data);
			yield put(setObjectiveAlgorithmsBank(algorithms));
		} else {
			throw response;
		}
	} catch (e) {
		console.error(e);
	}
}

function mapAlgorithms(algorithms) {
	return mapValues(algorithms, (algorithms) => {
		return mapValues(algorithms, (algorithm) => {
			return pick(algorithm, ["pretty_name", "service", "allowed_whitelist_algorithms"]);
		});
	});
}

function fetchQuestsApi() {
	return api.get(FETCH_OBJECTIVE_ALGORITHMS_URL);
}
