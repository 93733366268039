
import {
    setRewardsBank
} from 
'../../actions/Rewards'

import{
    showLoader,
    hideLoader
  } from '../../actions/GlobalAction'

import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';

import {
    SEARCH_REWARDS_BY_ITEM
} from '../../constants/ActionTypes'
import {
    REWARD_SERVICE_SEARCH_URL
} from '../../constants/Url'
import api from '../../util/api';
const qs = require('qs')


function searchRewardsApi(payload) {
    return (        
        api.get(REWARD_SERVICE_SEARCH_URL,
            {
                params: {
                    search_item: ['name', 'type', 'item_id'],
                    search_string: payload
                },
                paramsSerializer: function(params){
                    return qs.stringify(params, {arratFormat:'repeat'})
                }
            }).catch(err=>{
                return err
            })
    )
}

export function* searchRewardsWatcher() {
    yield takeLatest(SEARCH_REWARDS_BY_ITEM, searchRewardsWorker);
}

function* searchRewardsWorker(action) {
    let response
    try {
        yield put(showLoader());
        // data is obtained after axios call is resolved
        response = yield call(searchRewardsApi,action.payload);
        if (response.status >= 200 && response.status < 300){
            yield put(setRewardsBank(response.data))
        } else {
            throw(response)
        }
        
        yield put(hideLoader());
    } catch (e) {        
        yield put(hideLoader());
    }
}