export const TYPES = {
    OFFLINE: 'OFFLINE',
    DIGITAL: 'DIGITAL'
}
export const EVENT_STATUS = {
    COMPLETED: 'COMPLETED',
    PENDING: 'PENDING'
}
export const MockSerializedEvents = [
    { "label": "An event that is happening", "value": 24 },
    { "label": "Another event that is happening", "value": 27 }
];

export const EventType = [
    { "label": "Offline", "value": "OFFLINE" },
    { "label": "Digital", "value": "DIGITAL" }
];

export const EventSubType = [
    { "label": "Standalone", "value": "STANDALONE" },
    { "label": "Serialized", "value": "SERIALIZED" }
];