// formats a javascript date from something like Fri July 16 2019 to the way Team Liquid wants it, July 16, 2019
// parameter that is passed should be a javascript date string

import i18next from "i18next";
import { capitalize, padStart } from "./stringUtils";
import { MIN_BIRTH_DATE } from "../constants/DefaultBirthday";
const moment = require("moment");

export const formatDate = (dateString, format = "MMM DD, YYYY") => {
	const date = new Date(dateString).toDateString();
	if (!dateString) return date;
	const dateFormatted = moment(dateString).format(format);
	return capitalize(dateFormatted);
};

export const formatDateNoTimezone = (dateString, format = "MMM DD, YYYY") => {
	const date = new Date(dateString.replace(/-/g, "/").replace(/T.+/, "")).toDateString();
	if (!dateString) return date;
	const dateFormatted = moment(date).utc(dateString).format(format);
	return capitalize(dateFormatted);
};

// formats a javascript date from something like Fri July 16 2019 to the way Team Liquid wants it but short, July 16
// parameter that is passed should be a javascript date string
// ex: dateString = this.props.user.date

export const formatDateShort = (dateString, format = "MMM DD") => {
	const date = new Date(dateString).toDateString();
	if (!dateString) return date;
	const dateFormatted = moment(dateString).format(format);
	return capitalize(dateFormatted);
};

// formats a javascript date from something like "2019-09-11T10:10:33.166Z" to just the year: 2019
// parameter that is passed should be a javascript date string
// ex: dateString = this.props.user.personalInfo.fanSince
export const formatDateYear = (dateString) => {
	let date = new Date(dateString);
	return date.getFullYear();
};

// Formats a javascript date to show only the year regardless of the timezone
export const formatDateYearNoTimezone = (dateString) => {
	try {
		// For date formats as: 2019-01-01T00:00:00.000Z
		const date = new Date(dateString.replace(/-/g, "/").replace(/T.+/, "")).getFullYear();
		return date;
	} catch (e) {
		// For date formats as: Tue Jan 01 2019 00:00:00 GMT-0200
		let date = new Date(dateString).toUTCString();
		date = date.split(" ").slice(0, 4).join(" ");
		return date.split(" ")[3];
	}
};

export const getMonthName = (dateString) => {
	const month_names = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	const date = new Date(dateString);
	return month_names[date.getMonth()];
};

// formats a javascript date from something like "2019-09-11T10:10:33.166Z" to
// an international format YYYY.MM.DD
// parameter that is passed should be a javascript date string
// ex: dateString = this.props.user.personalInfo.fanSince
export const formatDateInternational = (dateString) => {
	let date = new Date(dateString);
	if (dateString) {
		let month = "" + (date.getMonth() + 1);
		let day = "" + date.getDate();
		let year = date.getFullYear();

		if (month.length < 2) month = "0" + month;

		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("/");
	} else return date.toString(); // returns invalid date message
};

export const formatDateTimeInternational = (dateTimeString) => {
	const date = new Date(dateTimeString);

	if (dateTimeString) {
		let year = date.getFullYear();
		let month = "" + (date.getMonth() + 1);
		let day = "" + date.getDate();
		let hour = "" + (date.getHours() % 12 === 0 ? 12 : date.getHours() % 12);
		let minutes = "" + date.getMinutes();
		let ampm = "" + (date.getHours() / 12 >= 1 ? "PM" : "AM");

		if (month.length < 2) month = "0" + month;

		if (day.length < 2) day = "0" + day;

		if (hour.length < 2) {
			hour = "0" + hour;
		}

		if (minutes.length < 2) {
			minutes = "0" + minutes;
		}

		return [year, month, day].join(".") + " " + [hour, minutes].join(":") + " " + ampm;
	} else return date.toString(); // returns invalid date message
};

export const getUserAge = (dob) => {
	const today = new Date();
	const birth_date = new Date(dob);
	let age = today.getFullYear() - birth_date.getFullYear();
	const months_diff = today.getMonth() - birth_date.getMonth();
	if (months_diff < 0 || (months_diff === 0 && today.getDate() < birth_date.getDate())) {
		age--;
	}
	return age;
};

export const localeToUTC = (dateTimeString) => {
	const date = new Date(dateTimeString);
	return new Date(
		Date.UTC(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			date.getHours(),
			date.getMinutes(),
			date.getSeconds()
		)
	);
};

export const utcToLocale = (dateTimeStringUTC) => {
	const date = new Date(dateTimeStringUTC);
	return new Date(
		date.getUTCFullYear(),
		date.getUTCMonth(),
		date.getUTCDate(),
		date.getUTCHours(),
		date.getUTCMinutes(),
		date.getUTCSeconds()
	);
};

// returns the difference in days between the two javascript date objects
// positive if a < b, negetive if b > a
const _MS_PER_DAY = 1000 * 60 * 60 * 24;
export function dateDiffInDays(a, b) {
	a = new Date(a);
	b = new Date(b);

	// Discard the time and time-zone information.
	const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
	const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

	return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

// constructs date objects using a and b
// then returns the time difference of a - b
export function dateCompare(a, b) {
	a = new Date(a);
	b = new Date(b);

	return a.getTime() - b.getTime();
}

// returns the number of days left between now and endDate
// return 0 if endDate is less than or equal to endDate
export function getDaysLeft(endDate) {
	let now = new Date();
	endDate = new Date(endDate);

	let daysLeft = dateDiffInDays(now, endDate);
	if (daysLeft < 0) daysLeft = 0;

	return daysLeft;
}

export function getSecondsLeft(endDate) {
	let now = new Date();
	endDate = new Date(endDate);

	return Math.floor((endDate - now) / 1000);
}

export function getTimeLeft(endDate, customSecondsLeft, short) {
	let secondsLeft = customSecondsLeft;

	if (!secondsLeft) {
		secondsLeft = getSecondsLeft(endDate);
	}

	const endDateLocale = moment(endDate).locale(i18next.language).format("ll");

	const minutesLeft = Number(Math.floor(secondsLeft / 60));
	const hoursLeft = Number(Math.floor(minutesLeft / 60));
	const daysLeft = Number(Math.floor(hoursLeft / 24));

	if (secondsLeft < 0) {
		return i18next.t("dateTime:getTimeLeft.ended", { date: endDateLocale });
	} else if (hoursLeft >= 24) {
		return i18next.t(`dateTime:getTimeLeft.day${short ? "-short" : ""}`, { count: daysLeft });
	} else if (minutesLeft >= 60) {
		return i18next.t(`dateTime:getTimeLeft.hour${short ? "-short" : ""}`, { count: hoursLeft });
	} else {
		return i18next.t(`dateTime:getTimeLeft.minute${short ? "-short" : ""}`, {
			count: minutesLeft,
		});
	}
}

export function getElapsedTime(final) {
	const currentDate = moment(new Date());
	const momentDate = moment(final);

	const minutesDiff = currentDate.diff(momentDate, "minutes");
	const hoursDiff = currentDate.diff(momentDate, "hours");
	const daysDiff = currentDate.diff(momentDate, "days");
	const monthsDiff = currentDate.diff(momentDate, "months");

	return minutesDiff < 1 // Less than a minute
		? i18next.t("dateTime:aFewSecondsAgo")
		: minutesDiff < 60 // Less than an hour
		? i18next.t("dateTime:minute-abbr", {
				count: minutesDiff,
		  })
		: minutesDiff < 1440 // Less than a day
		? i18next.t("dateTime:hour-abbr", {
				count: hoursDiff,
		  })
		: monthsDiff < 1 // Less than a month
		? i18next.t("dateTime:day-abbr", {
				count: daysDiff,
		  })
		: momentDate.locale(i18next.language).format("MMM DD"); // Everything older than a month
}

export function getElapsedTimeAgo(final) {
	const currentDate = moment(new Date());
	const momentDate = moment(final);

	const minutesDiff = currentDate.diff(momentDate, "minutes");
	const hoursDiff = currentDate.diff(momentDate, "hours");
	const daysDiff = currentDate.diff(momentDate, "days");
	const monthsDiff = currentDate.diff(momentDate, "months");

	return minutesDiff < 1 // Less than a minute
		? i18next.t("dateTime:aFewSecondsAgo")
		: minutesDiff < 60 // Less than an hour
		? i18next.t("dateTime:ago", {
				time: i18next.t("dateTime:minute", {
					count: minutesDiff,
				}),
		  })
		: minutesDiff < 1440 // Less than a day
		? i18next.t("dateTime:ago", {
				time: i18next.t("dateTime:hour", {
					count: hoursDiff,
				}),
		  })
		: monthsDiff < 1 // Less than a month
		? i18next.t("dateTime:ago", {
				time: i18next.t("dateTime:day", {
					count: daysDiff,
				}),
		  })
		: momentDate.locale(i18next.language).format("MMM DD"); // Everything older than a month
}

export const formatDateTime = (dateString) => {
	return moment(dateString).format("MMMM Do YYYY, h:mm:ss a");
};

export const generateYears = () => {
	const years = [],
		now = new Date();
	const currentYear = now.getFullYear();

	for (let i = currentYear; i >= 2000; --i) {
		years.push(i.toString());
	}

	return years;
};

export const formatDateString = (dateString) => {
	const isValidDateString = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/;

	if (isValidDateString.test(dateString)) {
		const splitString = dateString.split("-");
		return (
			splitString[0] + "-" + padStart(splitString[1], 2) + "-" + padStart(splitString[2], 2)
		);
	}

	return undefined;
};

export const isValidDate = (date) => {
	if (typeof date === typeof "") {
		return !isNaN(new Date(formatDateString(date)));
	} else {
		return date instanceof Date && !isNaN(date);
	}
};

export const isValidBirthDate = (birthDate) => {
	birthDate = formatDateString(birthDate);
	return (
		isValidDate(birthDate) &&
		new Date(birthDate) >= new Date(MIN_BIRTH_DATE) &&
		new Date(birthDate) < localeToUTC(new Date())
	);
};
