import {
    takeLatest,
    call,
    put
} from 'redux-saga/effects';
import {
    push
} from 'react-router-redux';

import {
	appendNotifications,
} from "../../actions/Notifications";
import {
	addMessage,
} from "../../actions/Messages";
import {
	SUCCESS,
	ERROR,
} from "../../constants/Notifications";

import {
    ARCHIVE_RESTORE_PLAYER_WATCHER
} from '../../constants/ActionTypes'
import {
    ARCHIVE_RESTORE_PLAYER_URL,
} from '../../constants/Url'
import { setPlayers } from "../../actions/Players";

import api from '../../util/api';

export function* archieveOrRestorePlayerWatcher() {
    yield takeLatest(ARCHIVE_RESTORE_PLAYER_WATCHER, archieveOrRestorePlayerWorker);
}

function* archieveOrRestorePlayerWorker(action) {
	try {
		let response = yield call(archieveOrRestorePlayerApi, action.payload);
		const isSuccess = response.status >= 200 && response.status < 300;
        const options = isSuccess ? action?.options?.success : action?.options?.failure;

        if (isSuccess) {
            yield put(setPlayers(response.data.data));
		};
		if (options?.notification) {
			yield put(appendNotifications({
				type: isSuccess ? SUCCESS : ERROR,
				message: options.notification,
			}));
		};
		if (options?.message) {
			yield put(addMessage({
				source: ARCHIVE_RESTORE_PLAYER_URL,
				message: response?.data?.error,
				isError: true,
			}));
		};
		if (options?.callback) {
			yield call(options.callback, options.callbackArgs);
        };
        if (options?.redirect) {
			yield put(push(options.redirect));
		};
	} catch (e) {
		console.error(e);
	};
};

function archieveOrRestorePlayerApi(payload) {
	return api
		.put(ARCHIVE_RESTORE_PLAYER_URL(payload.name), payload)
		.then(response => {return response})
		.catch(error => {return error.response});
};

